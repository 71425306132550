import { t, Trans } from '@lingui/macro';
import { DiamondIcon, Flexbox, Tag, Text } from '@luminovo/design-system';
import {
    CandidateCpn,
    GenericFullPart,
    GenericPartTypes,
    IpnWithMatchesFullPart,
    OtsFullPart,
    PackageDTO,
    PartMatchTypeFullResponse,
    RfqContext,
    StandardPartTypes,
} from '@luminovo/http-client';
import { Typography } from '@mui/material';
import React from 'react';
import { genericPartTypeTranslations } from '../../../resources/part/partBackendTypes';
import { usePartPackages } from '../../../resources/part/partHandler';
import { colorSystem } from '../../../themes';
import {
    formatCapacitance,
    formatDielectricMaterial,
    formatPowerRating,
    formatResistance,
    formatTemperatureCoefficient,
    formatTolerance,
    formatVoltageRating,
} from '../../../utils/converterUtils';
import { highlightCpnRevisionMatches } from '../../../utils/highlighting/cpnHighlighting';
import { HighlightableString } from '../../../utils/highlighting/highlightableString';
import { highlightIPNMatches } from '../../../utils/highlighting/ipnHighlighting';
import { assertUnreachable } from '../../../utils/typingUtils';
import { TransEnum } from '../../localization/TransEnum';
import { TechParamsMultiChip } from '../../TechParamsMultiChip';
import { PartCardLayout } from '../PartCardLayout';
import { DetailsButton, HighlightedValue } from '../PartSpecificationCardFields';
import { LightTypography, useIpnTooltip } from './IpnCardFunctions';
import { PreferredTags } from './PreferredTags';
import { useIpnDetailsDrawer } from './useIpnDetailsDrawer';

interface IpnCardProps {
    part: IpnWithMatchesFullPart;
    cardStyle?: React.CSSProperties;
    /**
     *  If true, hovering on the card will not show the view details and datasheet buttons.
     */
    disableOnHover?: boolean;
    searchQuery?: string;
    collapsed?: boolean;
    isFuzzyResult?: boolean;
    candidateCpns?: CandidateCpn[];
    rfqContext: RfqContext;
}

const TechParamTypography = ({ children }: { children: React.ReactNode }): JSX.Element => {
    return (
        <Typography variant="subtitle1" color={'textPrimary'}>
            {children}
        </Typography>
    );
};

const OtsLinkedPart = ({ part, query }: { part: OtsFullPart; query: string }): JSX.Element => {
    const { manufacturer } = part;
    const manufacturerName = manufacturer.name;
    const mpn = part.mpn;

    if (manufacturerName && mpn) {
        return (
            <Typography variant="subtitle1" color={'textPrimary'}>
                <HighlightViaIpn text={manufacturerName} query={query} />, <HighlightViaIpn text={mpn} query={query} />
            </Typography>
        );
    }

    return (
        <Typography variant="subtitle1" color={'textPrimary'}>
            <HighlightViaIpn text={manufacturerName ?? ''} query={query} />
            <HighlightViaIpn text={mpn ?? ''} query={query} />
        </Typography>
    );
};

const GenericLinkedPart = ({ part }: { part: GenericFullPart }) => {
    const { data: allPackages = [] } = usePartPackages('generic-part-creatable');

    const type = part.content.type;
    if (type === GenericPartTypes.Resistor) {
        const {
            resistance,
            tolerance,
            power_rating: powerRating,
            voltage_rating: voltageRating,
            temperature_coefficient: temperatureCoefficient,
            package_id: packageId,
        } = part.content.technical_parameters;
        const packageForResistor: PackageDTO | null = allPackages.find((p) => p.id === packageId) ?? null;
        return (
            <Flexbox gap={4}>
                <TechParamTypography>
                    <TransEnum text={type} translations={genericPartTypeTranslations} />,
                </TechParamTypography>
                {resistance && <TechParamTypography>{formatResistance(resistance)}</TechParamTypography>}
                {tolerance && <TechParamTypography>{formatTolerance(tolerance)}</TechParamTypography>}
                {powerRating && <TechParamTypography>{formatPowerRating(powerRating)}</TechParamTypography>}
                {temperatureCoefficient && (
                    <TechParamTypography>{formatTemperatureCoefficient(temperatureCoefficient)}</TechParamTypography>
                )}
                {voltageRating && <TechParamTypography>{formatVoltageRating(voltageRating)}</TechParamTypography>}
                <TechParamTypography>{packageForResistor?.name}</TechParamTypography>
            </Flexbox>
        );
    }
    if (type === GenericPartTypes.Capacitor) {
        const {
            dielectric: dielectricMaterial,
            capacitance,
            tolerance,
            voltage_rating: voltageRating,
            package_id: packageId,
        } = part.content.technical_parameters;
        const packageForCapacitor: PackageDTO | null = allPackages.find((p) => p.id === packageId) ?? null;
        return (
            <Flexbox gap={4}>
                <TechParamTypography>
                    <TransEnum text={type} translations={genericPartTypeTranslations} />,
                </TechParamTypography>
                {capacitance && <TechParamTypography>{formatCapacitance(capacitance)}</TechParamTypography>}
                {tolerance && <TechParamTypography> {formatTolerance(tolerance)}</TechParamTypography>}
                {dielectricMaterial && (
                    <TechParamTypography> {formatDielectricMaterial(dielectricMaterial)}</TechParamTypography>
                )}
                {voltageRating && <TechParamTypography> {formatVoltageRating(voltageRating)}</TechParamTypography>}
                <TechParamTypography>{packageForCapacitor?.name}</TechParamTypography>
            </Flexbox>
        );
    }
    assertUnreachable(part.content);
};

const LinkedParts = ({ part, query }: { part: PartMatchTypeFullResponse; query: string }): JSX.Element => {
    if (part.type === StandardPartTypes.OffTheShelf) {
        return <OtsLinkedPart part={part.data} query={query} />;
    }

    if (part.type === StandardPartTypes.Generic) {
        return <GenericLinkedPart part={part.data} />;
    }
    assertUnreachable(part);
};

export const IpnCardSection = ({
    title,
    icon,
    children,
}: {
    title: string;
    icon: JSX.Element;
    children: React.ReactNode;
}) => {
    return (
        <Flexbox flexDirection={'column'} gap={4}>
            <Flexbox gap="8px" alignItems="center">
                {icon}
                <Text variant="body-small-semibold" color={colorSystem.neutral[8]}>
                    {title}
                </Text>
            </Flexbox>
            <Flexbox marginLeft="16px">{children}</Flexbox>
        </Flexbox>
    );
};

const IpnCardNotMemoized = ({
    part,
    cardStyle,
    disableOnHover = false,
    searchQuery = '',
    collapsed = false,
    isFuzzyResult = false,
    candidateCpns,
    rfqContext,
}: IpnCardProps): JSX.Element => {
    const ipnTooltip = useIpnTooltip();
    const { openDrawer } = useIpnDetailsDrawer();

    const ipnFragments = highlightIPNMatches(part.id, searchQuery);

    const hasLinkedParts = part.matches.length > 0;
    const isPartActive = part.state === 'Active';

    const { cpnFragments, revisionFragments } = highlightCpnRevisionMatches({
        cpns: part.cpns,
        searchQuery,
        candidateCpns,
    });

    return (
        <PartCardLayout
            cardStyle={cardStyle}
            disableOnHover={disableOnHover}
            tooltip={ipnTooltip}
            headerSection={
                <>
                    <Flexbox alignItems="center" gap={8}>
                        <LightTypography variant="h5">IPN</LightTypography>
                        <HighlightedValue fragments={ipnFragments} />
                        {part.cpns.length > 0 && (
                            <Flexbox gap={4} alignItems="center">
                                <LightTypography variant="h5">CPN</LightTypography>
                                <HighlightedValue fragments={cpnFragments} />
                                {revisionFragments.length > 0 && <Text>•</Text>}
                                <HighlightedValue fragments={revisionFragments} />
                                {part.cpns.length > 1 && (
                                    <Text variant="body-small" style={{ marginLeft: '4px' }}>
                                        +{part.cpns.length - 1}
                                    </Text>
                                )}
                            </Flexbox>
                        )}
                    </Flexbox>
                    <Flexbox gap={8} alignItems="center">
                        {isFuzzyResult && <Tag color="yellow" attention="low" label={t`Fuzzy`} />}
                        {isPartActive ? (
                            <>
                                <Flexbox gap={4}>
                                    {part.is_restricted_to_customers && (
                                        <Tag color="violet" attention="low" label={'Restricted'} />
                                    )}
                                    <PreferredTags part={part} />
                                    {!hasLinkedParts && (
                                        <Tag color="yellow" attention="low" label={t`No linked parts`} />
                                    )}
                                </Flexbox>
                            </>
                        ) : (
                            <Tag color="red" attention="low" label={t`Removed`} />
                        )}
                    </Flexbox>
                </>
            }
            headerHover={<DetailsButton handleClick={() => openDrawer({ ipnId: part.id, rfqContext })} />}
            footerSection={
                <>
                    <Flexbox flexDirection={'column'} paddingY={'8px'} gap={16}>
                        {part.component_specification && (
                            <TechParamsMultiChip componentSpecification={part.component_specification} />
                        )}
                        {hasLinkedParts ? (
                            <IpnCardSection
                                title={t`Linked parts`}
                                icon={<DiamondIcon fontSize="small" color={colorSystem.green[5]} />}
                            >
                                <Flexbox flexDirection={'column'} gap={8}>
                                    {part.matches.map((part, i) => {
                                        return <LinkedParts part={part.part} key={i} query={searchQuery} />;
                                    })}
                                </Flexbox>
                            </IpnCardSection>
                        ) : (
                            <LightTypography variant="subtitle1">
                                <Trans>No parts could be linked automatically based on synced ERP data.</Trans>
                            </LightTypography>
                        )}
                        {!hasLinkedParts && !collapsed && part.part_specifications.length > 0 && (
                            <IpnCardSection
                                title={t`Synced ERP data`}
                                icon={<DiamondIcon fontSize="small" color={colorSystem.neutral[5]} />}
                            >
                                <Flexbox flexDirection={'column'} gap={8}>
                                    {part.part_specifications.map((part, i) => {
                                        const hasSpecsToDisplay =
                                            part.manufacturer ||
                                            part.mpn ||
                                            part.description ||
                                            part.package ||
                                            part.part_type;
                                        return (
                                            hasSpecsToDisplay && (
                                                <Flexbox flexDirection={'column'} gap={4} key={i}>
                                                    {part.manufacturer && part.mpn ? (
                                                        <Typography variant="subtitle1" color={'textPrimary'} noWrap>
                                                            <HighlightViaIpn
                                                                text={part.manufacturer}
                                                                query={searchQuery}
                                                            />
                                                            ,{' '}
                                                            <HighlightViaIpn
                                                                text={part.mpn ?? ''}
                                                                query={searchQuery}
                                                            />
                                                        </Typography>
                                                    ) : (
                                                        <Typography variant="subtitle1" color={'textPrimary'} noWrap>
                                                            <HighlightViaIpn
                                                                text={part.manufacturer ?? ''}
                                                                query={searchQuery}
                                                            />{' '}
                                                            <HighlightViaIpn
                                                                text={part.mpn ?? ''}
                                                                query={searchQuery}
                                                            />
                                                        </Typography>
                                                    )}

                                                    <Typography variant="subtitle1">
                                                        <HighlightViaIpn
                                                            text={[
                                                                part.description ?? '',
                                                                part.package ?? '',
                                                                part.part_type ?? '',
                                                            ].join(' ')}
                                                            query={searchQuery}
                                                        />
                                                    </Typography>
                                                </Flexbox>
                                            )
                                        );
                                    })}
                                </Flexbox>
                            </IpnCardSection>
                        )}
                    </Flexbox>
                </>
            }
        />
    );
};

export function HighlightViaIpn({ text, query }: { text: string; query: string }) {
    const descriptionFragments = highlightIPNMatches(text, query);

    return <HighlightedText fragments={descriptionFragments} />;
}

function HighlightedText({ fragments }: { fragments: HighlightableString }) {
    return (
        <>
            {fragments.map((fragment, i) => (
                <Typography
                    color={fragment.isHighlighted ? 'primary' : 'textPrimary'}
                    display={'inline'}
                    variant="inherit"
                    key={i}
                >
                    {fragment.fragment}
                </Typography>
            ))}
        </>
    );
}

// Memoization added because the component was re-rendering when the solution props changed and not the value https://medium.com/welldone-software/why-did-you-render-mr-big-pure-react-component-part-2-common-fixing-scenarios-667bfdec2e0f
export const IpnCard = React.memo(IpnCardNotMemoized);
