import { isPresent } from '@luminovo/commons';
import { useHistory } from 'react-router';
import { PageLayout } from '../../../../components/PageLayout';
import { SpinnerWithBackdrop } from '../../../../components/Spinners';
import { useSourcingFull, useSourcingScenario } from '../../../../resources/sourcingScenario/sourcingScenarioHandlers';
import { UrlParams, route } from '../../../../utils/routes';
import { useIsSourcingScenarioPcbOnlyType } from '../../utils/useIsSourcingScenarioPcbOnlyType';
import SolutionConfigurations from './SolutionConfigurations';

export function SolutionConfigurationPage({
    pathParams,
}: UrlParams<'/rfqs/:rfqId/sourcing/scenarios/:sourcingScenarioId'>) {
    const { rfqId, sourcingScenarioId } = pathParams;

    const { data: sourcingScenarioDTO, isLoading: isLoadingSourcingScenario } = useSourcingScenario(sourcingScenarioId);
    const { data: fullSourcingDTO } = useSourcingFull(sourcingScenarioId);

    useChangeSourcingPageIfIsPcbOnlyType({ rfqId, sourcingScenarioId });

    if (isLoadingSourcingScenario) {
        return (
            <PageLayout layout="centered">
                <SpinnerWithBackdrop noBackdrop={true} />
            </PageLayout>
        );
    }

    if (!sourcingScenarioDTO) {
        return null;
    }

    return (
        <SolutionConfigurations
            rfqId={rfqId}
            sourcingScenarioDTO={sourcingScenarioDTO}
            fullSourcingDTO={fullSourcingDTO}
        />
    );
}

const useChangeSourcingPageIfIsPcbOnlyType = ({
    sourcingScenarioId,
    rfqId,
}: {
    sourcingScenarioId: string;
    rfqId: string;
}) => {
    const { data: IsPcbOnlyType, isLoading } = useIsSourcingScenarioPcbOnlyType(sourcingScenarioId);
    const { data: fullSourcingDTO } = useSourcingFull(sourcingScenarioId);
    const history = useHistory();

    if (isLoading === false && IsPcbOnlyType && fullSourcingDTO !== undefined) {
        // If there are multiple BOM items then don't redirect
        if (fullSourcingDTO.solution_configurations_sourcing.items.length !== 1) {
            return;
        }

        const firstSolutionConfiguration = fullSourcingDTO.solution_configurations_sourcing.items[0];
        if (isPresent(firstSolutionConfiguration)) {
            history.push(
                route('/rfqs/:rfqId/sourcing/:solutionConfigurationId', {
                    rfqId,
                    solutionConfigurationId: firstSolutionConfiguration.id,
                }),
            );
        }
    }
};
