/* eslint-disable camelcase */

import { MessageDescriptor } from '@lingui/core';
import { defineMessage } from '@lingui/macro';
import {
    AggregationType,
    AttributeReference,
    ConditionValues,
    CountAggregationValue,
    DriverDetailsType,
    EnumFilterOperator,
    MountingPublic,
    MountingPublicRuntype,
    NumberFilterOperator,
    OfferTypePublic,
    OfferTypeRuntype,
    PartTypePublic,
    PartTypeRuntype,
    PnpSideDTO,
    SumAggregationValue,
} from '@luminovo/http-client';
import * as r from 'runtypes';

export const driverTypeEnumPublicTranslations: Record<DriverDetailsType, MessageDescriptor> = {
    [DriverDetailsType.Automatic]: defineMessage({ message: 'Automatic' }),
    [DriverDetailsType.Manual]: defineMessage({ message: 'Manual' }),
};

export const aggregationTypePublicTranslations: Record<AggregationType, MessageDescriptor> = {
    Count: defineMessage({ message: 'Count' }),
    Sum: defineMessage({ message: 'Sum' }),
};

export const aggregationTypeFullPublicTranslations: Record<AggregationType, MessageDescriptor> = {
    Count: defineMessage({ message: 'Count: Calculates total number of objects' }),
    Sum: defineMessage({ message: 'Sum: Calculates numerical sum of attributes' }),
};

export const countAggregationValuePublicTranslations: Record<CountAggregationValue, MessageDescriptor> = {
    UniqueParts: defineMessage({ message: 'Unique physical parts' }),
    DesignItems: defineMessage({ message: 'Design items / designators' }),
    UniqueSuppliers: defineMessage({ message: 'Suppliers' }),
};

export const sumAggregationvaluePublicTranslations: Record<SumAggregationValue, MessageDescriptor> = {
    PartNumberOfPins: defineMessage({ message: 'Number of pins' }),
    MaterialPrice: defineMessage({ message: 'Material price' }),
};

export const aggregationValuePublicTranslations: Record<
    SumAggregationValue | CountAggregationValue,
    MessageDescriptor
> = {
    ...countAggregationValuePublicTranslations,
    ...sumAggregationvaluePublicTranslations,
};

export const attributeReferencePublicTranslations: Record<AttributeReference, MessageDescriptor> = {
    Package: defineMessage({ message: 'Mounting' }),
    CustomPartType: defineMessage({ message: 'Custom Part Type' }),
    PartNumberOfPins: defineMessage({ message: 'Number of pins' }),
    OfferType: defineMessage({ message: 'Offer' }),
    MaterialPrice: defineMessage({ message: 'Material price' }),
    PnpSide: defineMessage({ message: 'Side of placement' }),
    Packaging: defineMessage({ message: 'Packaging' }),
};

export const sideOfPlacementPublicTranslations: Record<PnpSideDTO, MessageDescriptor> = {
    Top: defineMessage({ message: 'Top' }),
    Bottom: defineMessage({ message: 'Bottom' }),
};

export const enumFilterOperatorsPublicTranslations: Record<EnumFilterOperator, MessageDescriptor> = {
    Is: defineMessage({ message: 'Is' }),
    IsNot: defineMessage({ message: 'Is not' }),
};
export const numberFilterOperatorsPublicTranslations: Record<NumberFilterOperator, MessageDescriptor> = {
    Lesser: defineMessage({ message: '<' }),
    LesserOrEqual: defineMessage({ message: '=<' }),
    Equal: defineMessage({ message: '=' }),
    NotEqual: defineMessage({ message: '≠' }),
    GreaterOrEqual: defineMessage({ message: '>=' }),
    Greater: defineMessage({ message: '>' }),
};

export const mountingPublicTranslations: Record<MountingPublic, MessageDescriptor> = {
    SMT: defineMessage({ message: 'SMT' }),
    THT: defineMessage({ message: 'THT' }),
    Other: defineMessage({ message: 'Other' }),
};

export const conditionTranslations: Record<ConditionValues, MessageDescriptor> = {
    Or: defineMessage({ message: 'Or' }),
    And: defineMessage({ message: 'And' }),
};

export const partTypePublicTranslations: Record<PartTypePublic, MessageDescriptor> = {
    PCB: defineMessage({ message: 'PCB' }),
    Cable: defineMessage({ message: 'Cable' }),
    Mechanical: defineMessage({ message: 'Mechanical' }),
    Label: defineMessage({ message: 'Label' }),
    Other: defineMessage({ message: 'Other' }),
};

export const offerTypePublicTranslations: Record<OfferTypePublic, MessageDescriptor> = {
    Inventory: defineMessage({ message: 'Inventory' }),
    Consigned: defineMessage({ message: 'Consigned' }),
    CustomPart: defineMessage({ message: 'Custom part' }),
    Other: defineMessage({ message: 'Other' }),
};

export type FilterValueOptions = MountingPublic | PartTypePublic | OfferTypePublic | string;

export const FilterValueOptionRuntype = r.Union(MountingPublicRuntype, OfferTypeRuntype, PartTypeRuntype);

export const FilterValueOptionTranslations: Record<FilterValueOptions, MessageDescriptor> = {
    ...sideOfPlacementPublicTranslations,
    ...offerTypePublicTranslations,
    ...partTypePublicTranslations,
    ...mountingPublicTranslations,
};
