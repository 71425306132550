import { t, Trans } from '@lingui/macro';
import { CenteredLayout, colorSystem, Flexbox, SecondaryIconButton, Text } from '@luminovo/design-system';
import { PCBV2 } from '@luminovo/http-client';
import { Close } from '@mui/icons-material';
import { Box } from '@mui/material';
import { LayoutCard } from '../../../../components/LayoutCard';
import { PcbPdfViewer } from '../../components/PcbPdfViewer/PcbPdfViewer';
import { getFileNameFromPath } from '../../components/PcbPdfViewer/usePdfViewerDrawer';
import { useCustomStackupFileContext } from './CustomStackUpForm';
import { StackUpFilesUpload } from './StackUpFilesUpload';
import { StackUpPreviewIcon } from './StackUpPreviewIcon';

export const CustomStackupPreview = ({
    pcb,
    isEditing,
    assemblyId,
    rfqId,
}: {
    pcb: PCBV2;
    isEditing: boolean;
    rfqId: string;
    assemblyId: string;
}) => {
    const { previewFile, setPreviewFile } = useCustomStackupFileContext();
    const pdfFile =
        previewFile && previewFile.name.toLowerCase().endsWith('.pdf') && previewFile.path ? previewFile : null;

    if (!previewFile) {
        return (
            <CenteredLayout
                style={{
                    display: 'grid',
                    placeItems: 'center',
                    background: colorSystem.neutral[0],
                    height: '100%',
                }}
            >
                <LayoutCard title={t`Custom stackup`}>
                    <StackUpFilesUpload pcb={pcb} disabled={!isEditing} rfqId={rfqId} assemblyId={assemblyId} />
                </LayoutCard>
            </CenteredLayout>
        );
    }

    if (!pdfFile || pdfFile.path === undefined) {
        return (
            <Box
                style={{
                    display: 'grid',
                    placeItems: 'center',
                    background: colorSystem.neutral[1],
                    height: '100%',
                }}
            >
                <Flexbox
                    style={{
                        position: 'relative',
                        flexDirection: 'column',
                    }}
                >
                    <StackUpPreviewIcon />
                    <Flexbox
                        style={{
                            position: 'absolute',
                            flexDirection: 'column',
                            textAlign: 'center',
                            width: 'calc(100% - 24px)',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            gap: '12px',
                        }}
                    >
                        <Text variant="h1">
                            <Trans>No preview</Trans>
                        </Text>
                        <Text variant="body-small">
                            <Trans>
                                A custom stackup has been selected, and unfortunately, we are unable to display a
                                stackup preview
                            </Trans>
                        </Text>
                    </Flexbox>
                </Flexbox>
            </Box>
        );
    }

    return (
        <Flexbox
            padding={'16px'}
            flexDirection={'column'}
            gap={'20px'}
            style={{
                width: 'calc(100vw - 440px)',
                maxWidth: 'calc(100vw - 440px)',
            }}
        >
            <Flexbox alignItems={'center'} gap={'8px'}>
                <SecondaryIconButton size="small" onClick={() => setPreviewFile(null)}>
                    <Close fontSize="inherit" />
                </SecondaryIconButton>
                <Text variant="h2">{getFileNameFromPath(pdfFile.path)}</Text>
            </Flexbox>
            <Box
                style={{
                    maxHeight: 'calc(100vh - 300px)',
                    width: '100%',
                    overflow: 'auto',
                    padding: '30px',
                    boxSizing: 'border-box',
                }}
            >
                <PcbPdfViewer
                    pathToPdf={pdfFile.path}
                    regions={[]}
                    actionStyles={{
                        left: '65%',
                    }}
                />
            </Box>
        </Flexbox>
    );
};
