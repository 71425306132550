import { CenteredLayout, Dropzone } from '@luminovo/design-system';
import { useQueryClient } from '@tanstack/react-query';
import { readFile } from '../../../../model/readFile';
import { StepperProps } from '../../../Stepper';
import { useUniversalImporter } from '../../context';
import { useRunParsersQueryKey } from '../../hooks/useRunParsers';

export const StepUploadDropzone = (props: Pick<StepperProps, 'onStepIndexChange'>): JSX.Element => {
    const { dispatch, state } = useUniversalImporter();
    const queryClient = useQueryClient();

    return (
        <CenteredLayout height={'100%'}>
            <Dropzone
                overrides={{}}
                onDropAccepted={async (files) => {
                    const table = await readFile(files[0]);
                    dispatch({ type: 'setTable', table: table.data, fileName: files[0].name });
                    queryClient.invalidateQueries(useRunParsersQueryKey);
                    props.onStepIndexChange('next');
                }}
                accept={{ 'text/csv': ['.csv'] }}
                multiple={false}
                title=""
                persistentFiles={
                    state.fileName
                        ? [
                              {
                                  name: state.fileName,
                                  onDelete: () => {
                                      dispatch({ type: 'setTable', table: undefined });
                                  },
                                  onClick() {
                                      // noop
                                  },
                              },
                          ]
                        : []
                }
            />
        </CenteredLayout>
    );
};
