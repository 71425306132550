import * as r from 'runtypes';

/* eslint-disable camelcase */

export interface PCBCredentialsDTO extends r.Static<typeof PCBCredentialsDTORuntype> {}

export const PCBCredentialsDTORuntype = r.Record({
    username: r.String,
    is_valid: r.Boolean,
    error: r.String.nullable(),
});

export interface UserPasswordPCBCredentialsInputDTO extends r.Static<typeof UserPasswordCredentialsInputDTORuntype> {}

export const UserPasswordCredentialsInputDTORuntype = r.Record({
    username: r.String,
    password: r.String,
});
