import { Link as MuiLink, LinkProps as MuiLinkProps, styled } from '@mui/material';
import React from 'react';
import { body, colorSystem } from '../../theme';
import { FontVariant, Text } from '../Text';

export type LinkProps = Omit<MuiLinkProps, 'variant'> & {
    attention?: 'high' | 'low' | 'mui-default';
    startIcon?: React.ReactNode;
    endIcon?: React.ReactNode;
    variant?: FontVariant;
    textStyle?: React.CSSProperties;
};

export const Link = React.forwardRef(
    (
        { attention = 'mui-default', children, startIcon, endIcon, variant, textStyle, ...props }: LinkProps,
        ref: React.Ref<HTMLAnchorElement>,
    ): JSX.Element => {
        switch (attention) {
            case 'high':
                return (
                    <HighAttentionLink ref={ref} display="inline" {...props}>
                        <div style={{ display: 'inline-flex', alignItems: 'center', gap: '4px' }}>
                            {startIcon}
                            <HighAttentionText variant={variant} color="inherit" style={textStyle}>
                                {children}
                            </HighAttentionText>
                            {endIcon}
                        </div>
                    </HighAttentionLink>
                );
            case 'low':
                return (
                    <LowAttentionLink ref={ref} {...props}>
                        <div style={{ display: 'inline-flex', alignItems: 'center', gap: '4px' }}>
                            {startIcon}
                            <Text
                                variant={variant}
                                color="inherit"
                                style={{ textDecoration: 'underline', ...textStyle }}
                            >
                                {children}
                            </Text>
                            {endIcon}
                        </div>
                    </LowAttentionLink>
                );
            case 'mui-default':
                return (
                    <MuiLink ref={ref} {...props} style={{ cursor: 'pointer', ...props.style }}>
                        <div style={{ display: 'inline-flex', alignItems: 'center', gap: '4px' }}>
                            {startIcon}
                            <Text variant={variant} color="inherit" style={textStyle}>
                                {children}
                            </Text>
                            {endIcon}
                        </div>
                    </MuiLink>
                );
        }
    },
);

const HighAttentionLink = styled(MuiLink)({
    ...body,
    cursor: 'pointer',
    color: colorSystem.blue[6],
    '& * > svg': {
        ...body,
    },
});

const LowAttentionLink = styled(MuiLink)({
    ...body,
    cursor: 'pointer',
    color: colorSystem.neutral[6],
    '&:hover': {
        color: colorSystem.blue[6],
    },
    '& * > svg': {
        ...body,
    },
});

const HighAttentionText = styled(Text)({
    '&:hover': { textDecoration: 'underline' },
});
