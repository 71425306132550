import { TableState } from '@luminovo/design-system';
import {
    AssemblyIndustry,
    GenericFullPart,
    IpnWithMatchesFullPart,
    OtsFullPart,
    PartAlternative,
    PartAlternativeSimilarityEnum,
    UserType,
} from '@luminovo/http-client';
import { PartAlternativeContext } from '../../../modules/DesignItem/components/AddParts/PartAlternatives/partAlternativesTypes';
import { BomItem } from '../../../resources/designItem/bomItemFrontendTypes';
import { SectionOfScreen } from '../../../resources/part/partFrontendTypes';
import { useUserType } from '../../contexts/CurrentUserDetailsContext';
import { CompareItemsDialog, useComparePartsDialogState } from '../CompareItemsDialog';
import { CustomerAttributes, EmsAttributes } from './renderAttributes';
import { PartComparisonContext } from './types';

export const BomItemComparePartsDialog = ({
    isOpen,
    onClose,
    partOptions,
    partAlternatives,
    rfqId,
    assemblyId,
    bomItem,
    handleAddPartOption,
    partAlternativesTableState,
    assemblyIndustry,
}: {
    isOpen: boolean;
    onClose: () => void;
    partOptions: (OtsFullPart | IpnWithMatchesFullPart)[];
    partAlternatives: PartAlternative[];
    rfqId: string;
    assemblyId: string;
    bomItem: BomItem;
    handleAddPartOption: (
        newPart: GenericFullPart | OtsFullPart | IpnWithMatchesFullPart,
        sectionOfScreen: SectionOfScreen,
        alternativeSimilarity?: PartAlternativeSimilarityEnum,
    ) => void;
    partAlternativesTableState: TableState<PartAlternative, PartAlternativeContext>;
    assemblyIndustry?: AssemblyIndustry;
}) => {
    const userType = useUserType();
    const attributes = userType === UserType.Internal ? EmsAttributes : CustomerAttributes;
    const dialogState = useComparePartsDialogState<
        OtsFullPart | IpnWithMatchesFullPart,
        PartAlternative,
        PartComparisonContext
    >({
        comparableItems: partAlternatives,
        items: partOptions,
        rows: attributes,
        sharedContext: {
            rfqId,
            bomItem,
            assemblyId,
            handleAddPartOption,
            partAlternativesTableState,
            assemblyIndustry,
            partOptions,
        },
    });
    return <CompareItemsDialog isOpen={isOpen} onClose={onClose} dialogState={dialogState} />;
};
