import { t } from '@lingui/macro';
import { ChevronRightRounded } from '@mui/icons-material';
import { ColumnDef } from '@tanstack/react-table';
import { Checkbox } from '../Checkbox';
import { Tooltip } from '../Tooltip';
import { TertiaryIconButton } from '../buttons';

export const DEFAULT_SELECT_COLUMN_ID = 'select-col';
export const DEFAULT_EXPAND_COLUMN_ID = 'expand-col';

export const DefaultColumnSelection: ColumnDef<any, any> = {
    id: DEFAULT_SELECT_COLUMN_ID,
    size: 1,
    header: ({ table }) => (
        <Checkbox
            size="small"
            checked={table.getIsAllRowsSelected()}
            indeterminate={table.getIsSomeRowsSelected()}
            onChange={table.getToggleAllRowsSelectedHandler()}
        />
    ),
    cell: ({ row }) => (
        <Checkbox
            size="small"
            checked={row.getIsSelected()}
            disabled={!row.getCanSelect()}
            onChange={row.getToggleSelectedHandler()}
        />
    ),
    enableHiding: false,
    meta: {
        dataType: 'generic',
        enableOnRowClick: false,
        label: () => '',
    },
};

export const DefaultExpandColumn: ColumnDef<any, any> = {
    id: DEFAULT_EXPAND_COLUMN_ID,
    size: 1,
    enableHiding: false,
    meta: {
        dataType: 'generic',
        enableOnRowClick: false,
        label: () => '',
    },
    header: ({ table }) => {
        const isAllExpanded = table.getIsAllRowsExpanded();

        return (
            <Tooltip variant="white" title={isAllExpanded ? t`Collapse all rows` : t`Expand all rows`}>
                <TertiaryIconButton
                    size="small"
                    onClick={() => table.toggleAllRowsExpanded(!isAllExpanded)}
                    disableRipple
                >
                    <ChevronRightRounded
                        viewBox="1 1 20 20"
                        style={{
                            transform: isAllExpanded ? `rotate(90deg)` : `rotate(0deg)`,
                            transition: 'transform 0.1s ease-out',
                            height: '20px',
                            width: '20px',
                        }}
                    />
                </TertiaryIconButton>
            </Tooltip>
        );
    },
    cell: ({ row }) => {
        return row.getCanExpand() ? (
            <TertiaryIconButton size="small" onClick={() => row.toggleExpanded()} disableRipple>
                <ChevronRightRounded
                    viewBox="1 1 20 20"
                    style={{
                        transform: row.getIsExpanded() ? `rotate(90deg)` : `rotate(0deg)`,
                        transition: 'transform 0.1s ease-out',
                        height: '20px',
                        width: '20px',
                    }}
                />
            </TertiaryIconButton>
        ) : (
            <></>
        );
    },
};
