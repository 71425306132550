import { t } from '@lingui/macro';
import {
    compareByDate,
    compareByStringKey,
    formatMonetaryValue,
    formatToLongDate,
    isPresent,
    transEnum,
    uniq,
} from '@luminovo/commons';
import {
    TanStackTable,
    TanStackTableProps,
    Text,
    chainComparators,
    createColumnHelper,
    useTanStackTable,
} from '@luminovo/design-system';
import { SolutionStatus, SourcingPreferenceOptions } from '@luminovo/http-client';
import {
    formatAvailability,
    formatSolutionStatus,
    leadTimeDaysExtractor,
    sourcingPreferenceTranslations,
} from '@luminovo/sourcing-core';
import { Skeleton } from '@mui/material';
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { LabelScenarioOrderSize } from '../../../../components/LabelScenarioOrderSize';
import { calculateTotalOrderSize } from '../../../../resources/sourcingScenario/calculateTotalOrderSize';
import {
    useSourcingFullBulk,
    useSourcingScenarios,
} from '../../../../resources/sourcingScenario/sourcingScenarioHandlers';
import { route } from '../../../../utils/routes';
import { StatusSummaries } from '../SourcingScenarios/StatusSummaries';
import { AssemblyCentricSourcingScenarioTable } from './AssemblyCentricSourcingScenarioTable';
import {
    SourcingScenarioTableData,
    SourcingScenarioTableSharedContext,
    SourcingScenariosTableWithSubRowsType,
} from './utils/types';

const columnHelper = createColumnHelper<SourcingScenarioTableData>();

const columns = [
    columnHelper.text('sourcingScenario.name', {
        id: 'name',
        size: 160,
        label: () => t`Name`,
    }),
    columnHelper.number(({ sourcingScenario }) => calculateTotalOrderSize(sourcingScenario), {
        id: 'orderSize',
        size: 100,
        label: () => t`Order size`,
        cell: ({ row }) => <LabelScenarioOrderSize sourcingScenario={row.original.sourcingScenario} />,
    }),
    columnHelper.enum('sourcingScenario.solution_preference.lead_time_preference.type', {
        size: 120,
        label: () => t`Lead time preference`,
        options: [
            SourcingPreferenceOptions.Fastest,
            SourcingPreferenceOptions.BestPrice,
            SourcingPreferenceOptions.BestPriceBy,
        ],
        getOptionLabel: (opt) => transEnum(opt, sourcingPreferenceTranslations),
        sortingFn: chainComparators(
            compareByStringKey((row) =>
                transEnum(
                    row.original.sourcingScenario.solution_preference.lead_time_preference.type,
                    sourcingPreferenceTranslations,
                ),
            ),
            compareByDate((row) => row.original.sourcingScenario.solution_preference.lead_time_preference.target ?? ''),
        ),
        cell: ({ row }) => {
            const { type, target } = row.original.sourcingScenario.solution_preference.lead_time_preference;
            const bestDateBy = type === SourcingPreferenceOptions.BestPriceBy && target ? formatToLongDate(target) : '';

            return (
                <Text variant={'inherit'} showEllipsis={true} style={{ display: 'block' }}>
                    {`${transEnum(type, sourcingPreferenceTranslations)} ${bestDateBy}`.trim()}
                </Text>
            );
        },
    }),
    columnHelper.monetaryValue('sourcingFull.total_unit_price', {
        size: 100,
        label: () => t`Unit price`,
        cell: (item) =>
            !isPresent(item.row.original.sourcingFull) ? <Skeleton /> : formatMonetaryValue(item.getValue()),
    }),
    columnHelper.monetaryValue('sourcingFull.total_one_time_costs', {
        size: 100,
        label: () => t`One-time costs`,
        cell: (item) =>
            !isPresent(item.row.original.sourcingFull) ? <Skeleton /> : formatMonetaryValue(item.getValue()),
    }),
    columnHelper.monetaryValue('sourcingFull.total_price', {
        size: 100,
        label: () => t`Total price`,
        cell: (item) =>
            !isPresent(item.row.original.sourcingFull) ? <Skeleton /> : formatMonetaryValue(item.getValue()),
    }),
    columnHelper.monetaryValue('sourcingFull.total_excess_material', {
        size: 100,
        label: () => t`Excess material`,
        cell: (item) =>
            !isPresent(item.row.original.sourcingFull) ? <Skeleton /> : formatMonetaryValue(item.getValue()),
    }),
    columnHelper.number((row) => leadTimeDaysExtractor(row.sourcingFull?.total_availability ?? null), {
        id: 'leadTime',
        label: () => t`Lead time`,
        size: 90,
        cell: ({ row }) =>
            !isPresent(row.original.sourcingFull) ? (
                <Skeleton />
            ) : (
                formatAvailability(row.original.sourcingFull.total_availability)
            ),
    }),
    columnHelper.array(
        (row) =>
            uniq([
                ...Array(row.sourcingFull?.status_count.number_of_ok).fill(SolutionStatus.Good),
                ...Array(row.sourcingFull?.status_count.number_of_warning).fill(SolutionStatus.Warning),
                ...Array(row.sourcingFull?.status_count.number_of_error).fill(SolutionStatus.Error),
            ]),
        {
            id: 'solutionStatus',
            size: 180,
            align: 'center',
            enableSorting: false,
            options: Object.values(SolutionStatus),
            getOptionLabel: (status) => formatSolutionStatus(status),
            label: () => t`Solution status`,
            cell: ({ row }) =>
                !isPresent(row.original.sourcingFull) ? (
                    <Skeleton />
                ) : (
                    <StatusSummaries sourcingFull={row.original.sourcingFull} />
                ),
        },
    ),
    columnHelper.monetaryValue('sourcingFull.total_scrap_costs', {
        id: 'totalScrapCosts',
        size: 100,
        label: () => t`Scrap costs`,
        initialVisibility: false,
        cell: (item) =>
            !isPresent(item.row.original.sourcingFull) ? <Skeleton /> : formatMonetaryValue(item.getValue()),
    }),
];

const DefaultSourcingScenarioTable: React.FunctionComponent<{
    rfqId: string;
    sourcingScenarioIds: string[];
    columnsKey: string;
    ActionButton?: TanStackTableProps<SourcingScenarioTableData, SourcingScenarioTableSharedContext>['ActionButton'];
}> = ({ sourcingScenarioIds, columnsKey, rfqId, ActionButton }) => {
    const { data: sourcingScenariosDTOs } = useSourcingScenarios(sourcingScenarioIds);
    const { data: fullSourcingDTOs } = useSourcingFullBulk(sourcingScenarioIds);

    const data = React.useMemo(() => {
        return sourcingScenariosDTOs?.flatMap((sourcingScenario) => {
            const sourcingFull = fullSourcingDTOs?.find((full) => full.sourcing_scenario_id === sourcingScenario.id);
            return { sourcingScenario, sourcingFull };
        });
    }, [sourcingScenariosDTOs, fullSourcingDTOs]);

    const history = useHistory();

    const { table } = useTanStackTable({
        columns,
        data,
        columnsKey,
        enableColumnHiding: true,
        enableColumnOrdering: true,
        sharedContext: {
            rfqId: rfqId,
        },
        onRowClick: (row) => {
            history.push(
                route(`/rfqs/:rfqId/sourcing/scenarios/:sourcingScenarioId`, {
                    rfqId: row.original.sourcingScenario.rfq,
                    sourcingScenarioId: row.original.sourcingScenario.id,
                }),
            );
        },
    });

    return <TanStackTable table={table} size="medium" ActionButton={ActionButton} />;
};

export const SourcingScenarioTable: React.FunctionComponent<{
    rfqId: string;
    sourcingScenarioIds: string[];
    columnsKey: string;
    ActionButton?: TanStackTableProps<
        SourcingScenarioTableData | SourcingScenariosTableWithSubRowsType,
        SourcingScenarioTableSharedContext
    >['ActionButton'];
    isPcbOnlyAssemblies?: boolean;
}> = ({ sourcingScenarioIds, columnsKey, ActionButton, rfqId, isPcbOnlyAssemblies }) => {
    if (isPcbOnlyAssemblies) {
        return (
            <AssemblyCentricSourcingScenarioTable
                sourcingScenarioIds={sourcingScenarioIds}
                columnsKey={columnsKey}
                ActionButton={
                    isSourcingScenariosTableWithSubRowsTypeActionButton(ActionButton) ? ActionButton : undefined
                }
                rfqId={rfqId}
            />
        );
    }
    return (
        <DefaultSourcingScenarioTable
            sourcingScenarioIds={sourcingScenarioIds}
            columnsKey={columnsKey}
            ActionButton={isSourcingScenarioTableDataActionButton(ActionButton) ? ActionButton : undefined}
            rfqId={rfqId}
        />
    );
};

function isSourcingScenarioTableDataActionButton(
    component: any,
): component is TanStackTableProps<SourcingScenarioTableData, SourcingScenarioTableSharedContext>['ActionButton'] {
    return component !== undefined;
}
function isSourcingScenariosTableWithSubRowsTypeActionButton(
    component: any,
): component is TanStackTableProps<
    SourcingScenariosTableWithSubRowsType,
    SourcingScenarioTableSharedContext
>['ActionButton'] {
    return component !== undefined;
}
