import { useHttpQuery } from '../http/useHttpQuery';

export function useManufacturers() {
    return useHttpQuery(
        'GET /manufacturers',
        {},
        {
            select: (res) => Array.from(res.data).sort((a, b) => a.name.localeCompare(b.name)),
            staleTime: Infinity,
        },
    );
}
