import { t } from '@lingui/macro';
import { getLocale } from '@luminovo/commons';
import { MenuButton, MenuItem, Tooltip } from '@luminovo/design-system';
import { CustomerDTO } from '@luminovo/http-client';
import { Add } from '@mui/icons-material';
import { useHistory } from 'react-router';
import { useDialogContext } from '../../../components/contexts/ModalContext';
import { useHttpMutation } from '../../../resources/mutation/useHttpMutation';
import { useHasCustomers } from '../../../utils/featureFlags';
import { route } from '../../../utils/routes';
import { DialogUpsertCustomer } from './DialogUpsertCustomer';
import { convertToDto } from './types';

export const useAddCustomerDialog = ({ onSuccess }: { onSuccess?: (newCustomer: CustomerDTO) => void }) => {
    const { setDialog, closeDialog } = useDialogContext();
    const { hasCustomers } = useHasCustomers();

    const { mutateAsync } = useHttpMutation('POST /customers', {
        snackbarMessage: t`Successfully created customer`,
        onSuccess: ({ data: newCustomer }) => {
            onSuccess?.(newCustomer);
            closeDialog();
        },
    });

    return {
        openDialog: (customerName: string | undefined = '') => {
            setDialog(
                <DialogUpsertCustomer
                    customerType="Business"
                    title={hasCustomers ? t`Add new customer` : t`Add new company`}
                    /* eslint-disable camelcase */
                    initialValues={{
                        customerNumber: undefined,
                        language: getLocale(),
                        name: customerName,
                        defaultContactPerson: '',
                        customerType: {
                            type: 'Business',
                            data: {
                                vatId: undefined,
                                vatIdType: undefined,
                                commercialRegisterNumber: '',
                            },
                        },
                        billingAddress: {
                            city: undefined,
                            countryCode: undefined,
                            line1: undefined,
                            line2: undefined,
                            postalCode: undefined,
                        },
                    }}
                    /* eslint-enable camelcase */
                    open={true}
                    onClose={closeDialog}
                    onSubmit={async (requestBody) => {
                        mutateAsync({ requestBody: convertToDto(requestBody) });
                    }}
                />,
            );
        },
        closeDialog,
    };
};

export function ButtonAddCustomer() {
    const { openDialog } = useAddCustomerDialog({});
    const { hasCustomers } = useHasCustomers();
    const history = useHistory();

    return (
        <Tooltip title={hasCustomers ? t`Add new customer` : t`Add new company`}>
            <MenuButton
                appearance="secondary"
                icon={<Add fontSize={'inherit'} />}
                label={hasCustomers ? t`Add customer` : t`Add company`}
                size="medium"
            >
                <MenuItem label={t`Manually`} onClick={() => openDialog()} />
                <MenuItem
                    label={t`From .csv file`}
                    onClick={() => {
                        history.push(route('/customers/importer'));
                    }}
                />
            </MenuButton>
        </Tooltip>
    );
}
