import { t } from '@lingui/macro';
import { TanStackTable, createColumnHelper, useTanStackTable } from '@luminovo/design-system';
import { ExtractResponseBody, SupplierAndStockLocationDTO } from '@luminovo/http-client';
import { NoDemandsMessage } from '../../../../components/NoDemandsMessage';
import { useHttpQuery } from '../../../../resources/http/useHttpQuery';

type Row = {
    ipn: string;
    ipnRevision: string;
    supplierType: string;
    supplierNumber: string;
    recipientType: string;
    recipientNumber: string;
    grossQuantity: number;
    grossQuantityUnit: string;
    netQuantity: number;
    netQuantityUnit: string;
    deliveryDate: string;
};

const convertDemandExportToRow = (body: ExtractResponseBody<'POST /demand/export'>): Row[] => {
    return body.demands.map((demand) => ({
        ipn: demand.ipn.value,
        ipnRevision: demand.ipn.revision ?? '',
        supplierType: demand.supplier.kind,
        supplierNumber:
            demand.supplier.kind === 'external' ? demand.supplier.supplier_number : demand.supplier.site_number,
        recipientType: demand.recipient.kind,
        recipientNumber:
            demand.recipient.kind === 'external' ? demand.recipient.supplier_number : demand.recipient.site_number,
        grossQuantity: demand.gross_quantity.quantity,
        grossQuantityUnit: demand.gross_quantity.unit,
        netQuantity: demand.net_quantity.quantity,
        netQuantityUnit: demand.net_quantity.unit,
        deliveryDate: demand.delivery_start_date,
    }));
};

const columnHelper = createColumnHelper<Row>();

const columns = [
    columnHelper.text('ipn', {
        label: () => t`IPN`,
        size: 150,
        cell: (info) => info.getValue(),
    }),
    columnHelper.date('deliveryDate', {
        label: () => t`Delivery date`,
        size: 150,
        cell: (info) => info.getValue(),
    }),
    columnHelper.text('recipientNumber', {
        label: () => t`Recipient`,
        size: 150,
        cell: (info) => info.getValue(),
    }),
    columnHelper.number('grossQuantity', {
        label: () => t`Gross demand`,
        size: 200,
        cell: (info) => info.getValue(),
    }),
    columnHelper.number('netQuantity', {
        label: () => t`Net demand`,
        size: 200,
        cell: (info) => info.getValue(),
    }),
    columnHelper.text('supplierNumber', {
        label: () => t`Supplier number`,
        size: 150,
        cell: (info) => info.getValue(),
        initialVisibility: false,
    }),
    columnHelper.text('recipientType', {
        label: () => t`Recipient type`,
        size: 150,
        cell: (info) => info.getValue(),
        initialVisibility: false,
    }),
    columnHelper.text('grossQuantityUnit', {
        label: () => t`Gross demand unit`,
        size: 150,
        cell: (info) => info.getValue(),
        initialVisibility: false,
    }),
    columnHelper.text('netQuantityUnit', {
        label: () => t`Net demand unit`,
        size: 150,
        cell: (info) => info.getValue(),
        initialVisibility: false,
    }),

    columnHelper.text('ipnRevision', {
        label: () => t`IPN revision`,
        size: 150,
        cell: (info) => info.getValue(),
        initialVisibility: false,
    }),
    columnHelper.text('supplierType', {
        label: () => t`Supplier type`,
        size: 150,
        cell: (info) => info.getValue(),
        initialVisibility: false,
    }),
];

export const SupplierDemandsTable = ({
    supplierAndStockLocation,
}: {
    supplierAndStockLocation: SupplierAndStockLocationDTO;
}): JSX.Element => {
    const { data } = useHttpQuery(
        'POST /demand/export',
        {
            requestBody: {
                // send empty array instead of undefined as we don't want to fetch all demands if
                // supplier_number is null
                supplier_numbers:
                    supplierAndStockLocation.supplier_number !== null ? [supplierAndStockLocation.supplier_number] : [],
            },
        },
        {
            select: (data) => convertDemandExportToRow(data),
            // no point in fetching if supplier_number is null, as there can be no demands imported
            // for this case
            enabled: supplierAndStockLocation.supplier_number !== null,
        },
    );

    const { table } = useTanStackTable({
        data: data ?? [],
        columns,
        enableColumnHiding: true,
        enableColumnOrdering: true,
        enableExcelExport: true,
    });

    return (
        <TanStackTable
            table={table}
            enableMenuBar={{ exportExcelButton: true }}
            EmptyPlaceholder={() => <NoDemandsMessage />}
        />
    );
};
