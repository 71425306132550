import { Flexbox, Text } from '@luminovo/design-system';
import { Typography } from '@mui/material';
import React from 'react';

export const HeadingWithValue: React.FunctionComponent<{ heading: string; value?: string }> = ({
    heading,
    value,
    children,
}) => {
    const content = value ?? children;
    return (
        <>
            {content && (
                <Flexbox flexDirection="column" gap={4}>
                    <Text variant="h4">{heading}</Text>

                    <Typography component="div" variant="body1">
                        {content}
                    </Typography>
                </Flexbox>
            )}
        </>
    );
};
