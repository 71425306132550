import { t } from '@lingui/macro';
import { isPresent } from '../typingUtils';
import { Currency, FormattingType, formatCurrency } from './formatCurrency';

export type MonetaryValue = {
    amount: string;
    currency: Currency;
};
type ExchangedMonetaryValueBackend = {
    converted_mv: MonetaryValue;
    original_mv: MonetaryValue;
};

export function formatMonetaryValue(
    monetaryValue: MonetaryValue | ExchangedMonetaryValueBackend | null | undefined,
    formattingType: FormattingType = 'default',
    options?: { ifAbsent?: string },
): string {
    if (!monetaryValue) {
        return options?.ifAbsent ?? t`Unknown`;
    }

    const { amount, currency } = 'converted_mv' in monetaryValue ? monetaryValue.converted_mv : monetaryValue;
    return formatCurrency(amount, currency, formattingType);
}

export function extractAmountFromMonetaryValue(
    monetaryValue: MonetaryValue | null | undefined,
    options?: { ifAbsent?: number },
): number {
    if (!isPresent(monetaryValue)) {
        return options?.ifAbsent ?? 0;
    }

    return Number(monetaryValue?.amount);
}
