import { CenteredLayout } from '@luminovo/design-system';
import React from 'react';
import { Redirect, Route, Switch } from 'react-router';
import { PageLayout } from '../../components/PageLayout';
import { SpinnerWithBackdrop } from '../../components/Spinners';
import { isPcbAnalysisInProgress } from '../../resources/pcb/pcbFunctions';
import { useFindPcb } from '../../resources/pcb/pcbHandlers';
import { LumiQuoteRoute, UrlParams, route } from '../../utils/routes';
import { typed } from '../../utils/typingUtils';
import { ViewContext } from '../Bom/components/ModuleTableData';
import { ToolbarPcb } from './components/ToolbarPcb/ToolbarPcb';

export const PcbRedirectPage = ({
    pathParams,
    queryParams,
}: UrlParams<'/rfqs/:rfqId/bom/assembly/:assemblyId/pcb'>) => {
    const { assemblyId, rfqId } = pathParams;
    const { currentParentAssemblyId } = queryParams;
    const viewContext: ViewContext = React.useMemo(() => {
        return {
            type: 'WithinRfQ',
            rfqId,
        };
    }, [rfqId]);
    return (
        <PageLayout
            removeHubspotPaddingFix
            header={
                <ToolbarPcb assemblyId={assemblyId} rfqId={rfqId} currentParentAssemblyId={currentParentAssemblyId} />
            }
            layout="fragment"
        >
            <PcbRedirectTab
                assemblyId={assemblyId}
                rfqId={rfqId}
                currentParentAssemblyId={currentParentAssemblyId}
                viewContext={viewContext}
            />
        </PageLayout>
    );
};

const PCB_ROUTES = [
    typed<LumiQuoteRoute>('/rfqs/:rfqId/bom/assembly/:assemblyId/pcb'),
    typed<LumiQuoteRoute>('/assemblies/:assemblyId/pcb'),
];

export const PcbRedirectTab = ({
    rfqId,
    assemblyId,
    currentParentAssemblyId,
    viewContext,
}: {
    assemblyId: string;
    rfqId: string;
    currentParentAssemblyId: string | null | undefined;
    viewContext: ViewContext;
}) => {
    const { data: pcb, isLoading } = useFindPcb({ assemblyId });

    const cadViewerRoute: string = React.useMemo(() => {
        if (viewContext.type === 'WithinRfQ')
            return route(
                '/rfqs/:rfqId/bom/assembly/:assemblyId/pcb/cad-viewer',
                { rfqId, assemblyId },
                { currentParentAssemblyId },
            );

        return route('/assemblies/:assemblyId/pcb/cad-viewer', { assemblyId }, { rfqId });
    }, [viewContext.type, rfqId, assemblyId, currentParentAssemblyId]);

    const specificationRoute: string = React.useMemo(() => {
        if (viewContext.type === 'WithinRfQ')
            return route('/rfqs/:rfqId/bom/assembly/:assemblyId/pcb/specification', { rfqId, assemblyId });

        return route('/assemblies/:assemblyId/pcb/specification', { assemblyId }, { rfqId });
    }, [assemblyId, rfqId, viewContext.type]);

    if (isLoading) {
        return (
            <CenteredLayout>
                <SpinnerWithBackdrop />
            </CenteredLayout>
        );
    }

    const showPcbCadViewerFirst = pcb === undefined || (pcb && isPcbAnalysisInProgress(pcb));

    return (
        <Switch>
            {PCB_ROUTES.map((aRoute) => (
                <Route key={aRoute} exact path={aRoute}>
                    {showPcbCadViewerFirst ? <Redirect to={cadViewerRoute} /> : <Redirect to={specificationRoute} />}
                </Route>
            ))}
        </Switch>
    );
};
