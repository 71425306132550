import { Trans } from '@lingui/macro';
import { assertUnreachable } from '@luminovo/commons';
import { Row, SecondaryIconButton, Tooltip } from '@luminovo/design-system';
import { OtsOrCustomComponent, PartSpecificationTypes } from '@luminovo/http-client';
import { Add } from '@mui/icons-material';
import RemoveRoundedIcon from '@mui/icons-material/RemoveRounded';
import { PartSuggestionAvailabilityView } from '../../../../components/PartAvailabilityView';
import { PartComplianceView } from '../../../../components/PartComplianceView';
import { PartLifecycleView } from '../../../../components/PartLifecycleView';
import {
    CpnView,
    RenderDescription,
    RenderIpn,
    RenderManufacturer,
    RenderMpn,
    RenderPackage,
    StickyTableCell,
} from '../../../../components/partColumns';
import { StickyRightHeaderTableCell } from '../../../../components/partColumns/StickyHeadTableCell';
import { TableCell } from '../../../../components/partColumns/TableCell';
import { designatorQtyMismatchTooltip } from '../AddParts/hasDesignatorOrQtyMismatch';
import { ErpSearchTableContext } from './erpSearchTypes';

export const columnIpn = {
    id: `erp-search-ipn`,
    label: <Trans>IPN</Trans>,
    render: ({ data }: Row<OtsOrCustomComponent>): JSX.Element => {
        return <RenderIpn part={data.data} />;
    },
};

export const columnCpn = {
    id: `erp-search-cpn-rev`,
    label: <Trans>CPN • Rev</Trans>,
    render: ({ data }: Row<OtsOrCustomComponent>): JSX.Element => {
        return (
            <TableCell>
                <CpnView part={data.data} />
            </TableCell>
        );
    },
};

export const columnMpn = {
    id: `erp-search-mpn`,
    label: <Trans>MPN</Trans>,
    render: ({ data }: Row<OtsOrCustomComponent>): JSX.Element => {
        return <RenderMpn part={data.data} />;
    },
};

export const columnManufacturer = {
    id: `erp-search-manufacturer`,
    label: <Trans>Manufacturer</Trans>,
    render: ({ data }: Row<OtsOrCustomComponent>): JSX.Element => {
        return <RenderManufacturer part={data.data} />;
    },
};

export const columnDescription = {
    id: `erp-search-description`,
    label: <Trans>Description</Trans>,
    render: ({ data }: Row<OtsOrCustomComponent>): JSX.Element => {
        return <RenderDescription part={data.data} />;
    },
};

export const columnPackage = {
    id: `erp-search-package`,
    label: <Trans>Package</Trans>,
    render: ({ data }: Row<OtsOrCustomComponent>): JSX.Element => {
        return <RenderPackage part={data.data} />;
    },
};

export const columnAvailability = {
    id: `erp-search-availability`,
    label: <Trans>Availability</Trans>,
    render: (
        { data }: Row<OtsOrCustomComponent>,
        { rfqId, bomItem, assemblyId }: ErpSearchTableContext,
    ): JSX.Element => {
        const componentType = data.type;
        const isComponentRemoved = data.data.state === 'Removed';
        if (componentType === 'Custom') {
            return <TableCell isComponentRemoved={isComponentRemoved}>-</TableCell>;
        }
        if (componentType === 'OffTheShelf') {
            return (
                <TableCell>
                    <PartSuggestionAvailabilityView
                        assemblyId={assemblyId}
                        rfqId={rfqId}
                        bomItem={bomItem}
                        part={data.data}
                        disabled={isComponentRemoved}
                    />
                </TableCell>
            );
        }
        assertUnreachable(componentType);
    },
};

export const columnLifecycle = {
    id: `erp-search-lifecycle`,
    label: <Trans>Lifecycle</Trans>,
    render: ({ data }: Row<OtsOrCustomComponent>): JSX.Element => {
        const componentType = data.type;
        const isComponentRemoved = data.data.state === 'Removed';
        if (componentType === 'Custom') {
            return <TableCell isComponentRemoved={isComponentRemoved}>-</TableCell>;
        }
        if (componentType === 'OffTheShelf') {
            return (
                <TableCell>
                    <PartLifecycleView part={data.data} disabled={isComponentRemoved} />
                </TableCell>
            );
        }
        assertUnreachable(componentType);
    },
};

export const columnCompliance = {
    id: `erp-search-compliance`,
    label: <Trans>Compliance</Trans>,
    render: ({ data }: Row<OtsOrCustomComponent>, { assemblyIndustry }: ErpSearchTableContext): JSX.Element => {
        const componentType = data.type;
        const isComponentRemoved = data.data.state === 'Removed';
        if (componentType === 'Custom') {
            return <TableCell isComponentRemoved={isComponentRemoved}>-</TableCell>;
        }
        if (componentType === 'OffTheShelf') {
            return (
                <TableCell>
                    <PartComplianceView
                        part={data.data}
                        assemblyIndustry={assemblyIndustry}
                        disabled={isComponentRemoved}
                    />
                </TableCell>
            );
        }
        assertUnreachable(componentType);
    },
};

const RenderActionButtons = ({
    component,
    isDisabled,
    disabledTooltip,
    handleAddPart,
    handleRemovePart,
    existingPartIds,
    hasDesignatorQuantityMismatch,
}: {
    component: OtsOrCustomComponent;
    isDisabled: boolean;
    disabledTooltip: JSX.Element | string;
    handleAddPart: (component: OtsOrCustomComponent) => void;
    handleRemovePart: (partId: string) => void;
    existingPartIds: string[] | undefined;
    hasDesignatorQuantityMismatch: boolean;
}) => {
    return existingPartIds?.includes(component.data.id) ? (
        <Tooltip title={hasDesignatorQuantityMismatch ? designatorQtyMismatchTooltip() : ''}>
            <span>
                <SecondaryIconButton
                    disabled={isDisabled || hasDesignatorQuantityMismatch}
                    disableTouchRipple
                    onClick={(e) => {
                        e.stopPropagation();
                        handleRemovePart(component.data.id);
                    }}
                    size="small"
                >
                    <RemoveRoundedIcon fontSize="inherit" />
                </SecondaryIconButton>
            </span>
        </Tooltip>
    ) : (
        <Tooltip title={hasDesignatorQuantityMismatch ? designatorQtyMismatchTooltip() : disabledTooltip}>
            <SecondaryIconButton
                data-testid={'add-ots-component-button'}
                disabled={isDisabled || hasDesignatorQuantityMismatch}
                disableTouchRipple
                onClick={(e) => {
                    e.stopPropagation();
                    handleAddPart(component);
                }}
                size="small"
                style={{ pointerEvents: 'auto' }}
            >
                <Add fontSize="inherit" />
            </SecondaryIconButton>
        </Tooltip>
    );
};

export const columnActions = {
    id: `erp-search-actions`,
    label: <Trans>Actions</Trans>,
    overrides: {
        HeaderTableCell: StickyRightHeaderTableCell,
    },
    render: (
        { data }: Row<OtsOrCustomComponent>,
        {
            partSpecificationType,
            isSubmitting,
            existingPartIds,
            handleAddPart,
            handleRemovePart,
            hasDesignatorQuantityMismatch,
        }: ErpSearchTableContext,
    ): JSX.Element => {
        const componentType = data.type;

        if (componentType === 'Custom') {
            const isDisabled = isSubmitting || partSpecificationType === PartSpecificationTypes.OffTheShelf;
            const disabledTooltip =
                partSpecificationType === PartSpecificationTypes.OffTheShelf ? (
                    <Trans>
                        Only Off-the-shelf components can be added. {<br />}Change the component type above if you wish
                        to add this component.
                    </Trans>
                ) : (
                    ''
                );
            return (
                <StickyTableCell>
                    <RenderActionButtons
                        component={data}
                        isDisabled={isDisabled}
                        disabledTooltip={disabledTooltip}
                        existingPartIds={existingPartIds}
                        handleAddPart={handleAddPart}
                        handleRemovePart={handleRemovePart}
                        hasDesignatorQuantityMismatch={hasDesignatorQuantityMismatch}
                    />
                </StickyTableCell>
            );
        }
        if (componentType === 'OffTheShelf') {
            const isDisabled = isSubmitting || partSpecificationType === PartSpecificationTypes.Custom;
            const disabledTooltip =
                partSpecificationType === PartSpecificationTypes.Custom ? (
                    <Trans>
                        Only custom components can be added. {<br />}Change the component type above if you wish to add
                        this component.
                    </Trans>
                ) : (
                    ''
                );
            return (
                <StickyTableCell>
                    <RenderActionButtons
                        component={data}
                        isDisabled={isDisabled}
                        disabledTooltip={disabledTooltip}
                        existingPartIds={existingPartIds}
                        handleAddPart={handleAddPart}
                        handleRemovePart={handleRemovePart}
                        hasDesignatorQuantityMismatch={hasDesignatorQuantityMismatch}
                    />
                </StickyTableCell>
            );
        }
        assertUnreachable(componentType);
    },
};
