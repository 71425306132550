import { assertUnreachable, transEnum } from '@luminovo/commons';
import { CustomPartOfferStatuses, InvalidSpecificationError } from '@luminovo/http-client';
import { apiNotSetupErrorTranslations, panelErrorTranslations } from '../../../../../resources/pcb/i18n';

export const convertOfferErrorToText = (
    offer: CustomPartOfferStatuses,
): string | InvalidSpecificationError | undefined => {
    switch (offer.type) {
        case 'OffersAvailable':
            return undefined;
        case 'NoOffersReceived':
            return 'No offers received';
        case 'NeedsRefreshForOffers':
            return 'Refresh offers to get prices for this supplier';
        case 'MissingCredentials':
            return 'Missing credentials';
        case 'InvalidCredentials':
            return 'Invalid credentials';
        case 'Pending':
            return 'Offer is pending';
        case 'InvalidSpecification':
            return offer.errors;
        case 'PanelError':
            return transEnum(offer.error.type, panelErrorTranslations);
        case 'SupplierApiError':
            return 'API error';
        case 'ApiNotSetUp':
            return transEnum(offer.error.type, apiNotSetupErrorTranslations);
        default:
            assertUnreachable(offer);
    }
};
