import { Trans } from '@lingui/macro';
import { BrokenImageIcon, CenteredLayout, colorSystem, Flexbox, Link, Text } from '@luminovo/design-system';
import { PCBV2File } from '@luminovo/http-client';
import React from 'react';

function TipsAndUpdatesIcon(props: React.SVGProps<SVGSVGElement>): JSX.Element {
    return (
        <svg width="96" height="96" viewBox="0 0 96 96" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <g clipPath="url(#clip0_1726_61620)">
                <path
                    d="M28 80H44C44 84.4 40.4 88 36 88C31.6 88 28 84.4 28 80ZM20 72C20 74.2 21.8 76 24 76H48C50.2 76 52 74.2 52 72C52 69.8 50.2 68 48 68H24C21.8 68 20 69.8 20 72ZM66 38C66 53.28 55.36 61.44 50.92 64H21.08C16.64 61.44 6 53.28 6 38C6 21.44 19.44 8 36 8C52.56 8 66 21.44 66 38ZM85.48 29.48L80 32L85.48 34.52L88 40L90.52 34.52L96 32L90.52 29.48L88 24L85.48 29.48ZM76 24L79.76 15.76L88 12L79.76 8.24L76 0L72.24 8.24L64 12L72.24 15.76L76 24Z"
                    fill="#D8DEEE"
                />
            </g>
            <defs>
                <clipPath id="clip0_1726_61620">
                    <rect width="96" height="96" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}

function isPicture(file: PCBV2File): boolean {
    // eslint-disable-next-line spellcheck/spell-checker
    return file.name.endsWith('png') || file.name.endsWith('jpg') || file.name.endsWith('jpeg');
}

export const LayerAssignmentFilePreview: React.FunctionComponent<{
    file: PCBV2File | null;
}> = React.memo(({ file }) => {
    if (!file) {
        return (
            <CenteredLayout>
                <TipsAndUpdatesIcon />
                <Text color={colorSystem.neutral[6]}>Click on a file to see it’s preview</Text>
            </CenteredLayout>
        );
    }

    let previewUrl: URL | null = null;

    if (file.preview) {
        const seconds = Date.now() / 1000;
        previewUrl = new URL(file.preview, window.location.origin);
        // the random param forces the browser to reload the image
        previewUrl.searchParams.append('random', seconds.toLocaleString());
    } else if (file.path && isPicture(file)) {
        previewUrl = new URL(file.path, window.location.origin);
    }

    if (!previewUrl) {
        return (
            <>
                <Text variant="body">{file.name}</Text>
                <Link href={file.path} download={file.path} attention="low">
                    <Trans>Download</Trans>
                </Link>
                <Flexbox justifyContent={'center'} width={'100%'}>
                    <BrokenImageIcon />
                </Flexbox>
            </>
        );
    }

    return (
        <>
            <Text>{file.name}</Text>
            <img
                alt={'File preview'}
                src={previewUrl.toString()}
                style={{
                    width: '100%',
                    height: 'auto',
                }}
            />
        </>
    );
});
