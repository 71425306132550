import { t } from '@lingui/macro';
import { assertUnreachable, transEnum } from '@luminovo/commons';
import { CopyToClipboardTooltip, Flexbox, Tag, Text, colorSystem } from '@luminovo/design-system';
import {
    CustomPartTypeEnum,
    FullPart,
    isCustomComponentFull,
    isCustomFullPart,
    isGenericFullPart,
    isOtsComponentFull,
    isOtsFullPart,
} from '@luminovo/http-client';
import { formatPart } from '../../formatters';
import { partSpecificationTypeTranslations } from '../../i18n';

export const LabelPart: React.FunctionComponent<{
    part: FullPart | undefined;
    assemblyNameForCustomPart?: string;
    enableCopyToClipboard?: boolean;
}> = ({ part, enableCopyToClipboard = false, assemblyNameForCustomPart: assemblyName }): JSX.Element => {
    if (!part) {
        return <></>;
    }

    if (isOtsFullPart(part)) {
        return <>{formatPart(part)}</>;
    }

    if (isOtsComponentFull(part) || isCustomComponentFull(part)) {
        return (
            <Flexbox gap={4} alignItems={'baseline'}>
                <Tag attention={'low'} color={'blue'} label={t`IPN`} />
                <Text variant="inherit" color={'inherit'} style={{ whiteSpace: 'nowrap' }}>
                    {part.id}
                </Text>
            </Flexbox>
        );
    }

    if (isGenericFullPart(part)) {
        return (
            <Flexbox gap={4} alignItems={'baseline'}>
                <Tag attention={'low'} color={'green'} label={t`Generic`} />
                <Text variant="inherit" color={'inherit'} style={{ whiteSpace: 'nowrap' }}>
                    {transEnum(part.content.type, partSpecificationTypeTranslations)}
                </Text>
            </Flexbox>
        );
    }

    if (isCustomFullPart(part)) {
        if (part.type.name === CustomPartTypeEnum.PCB) {
            return (
                <Flexbox gap={4} alignItems={'baseline'}>
                    <Tag attention={'low'} color={'green'} label={t`PCB`} />
                    {assemblyName && (
                        <Text variant="inherit" color={'inherit'} style={{ whiteSpace: 'nowrap' }}>
                            {assemblyName}
                        </Text>
                    )}
                </Flexbox>
            );
        }

        return (
            <Flexbox gap={4} alignItems={'baseline'}>
                <Tag attention={'low'} color={'blue'} label={t`Custom`} />
                <Text variant="inherit" color={'inherit'}>{`${part.type.name}`}</Text>
                <CopyToClipboardTooltip
                    value={enableCopyToClipboard && part.description ? part.description : undefined}
                >
                    <Text variant="body-small" color={colorSystem.neutral[6]}>{`${part.description}`}</Text>
                </CopyToClipboardTooltip>
            </Flexbox>
        );
    }

    assertUnreachable(part);
};
