import { Trans } from '@lingui/macro';
import { Dialog, DialogActions, DialogContent, Flexbox, PrimaryButton, Text } from '@luminovo/design-system';
import { PCBV2 } from '@luminovo/http-client';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useHistory } from 'react-router';
import { useIsCustomer } from '../../../../components/contexts/CurrentUserDetailsContext';
import { isPcbApproved } from '../../../../resources/pcb/pcbFunctions';
import { useRfQ } from '../../../../resources/rfq/rfqHandler';
import { useHasAccessToCustomerPortal } from '../../../../utils/featureFlags';
import { useIsRfqEditable } from '../../../../utils/rfqUtils';
import { findRouteId, route } from '../../../../utils/routes';
import { usePcbAnalysisProgress } from '../../utils/usePcbAnalysisProgress';
import { PCBRoutes } from './utils/const';
import { getPcbAnalysisId } from './utils/getPcbAnalysisId';

export const PcbNavigationBlockerModal = ({
    pcb,
    assemblyId,
    rfqId,
}: {
    pcb: PCBV2;
    assemblyId: string;
    rfqId: string;
}) => {
    const [showMessageModal, setShowMessageModal] = React.useState(false);
    const { closeSnackbar } = useSnackbar();
    const history = useHistory();
    const isCustomer = useIsCustomer();
    const { data: rfq } = useRfQ(rfqId);
    const { isRfqEditable } = useIsRfqEditable(rfq?.status, rfq?.is_archived, rfq?.workflow_type);

    const { isPcbAnalysisDone } = usePcbAnalysisProgress(pcb);
    const isApproved = pcb && isPcbApproved(pcb);
    const isLeavingPcbWithoutSavingSpecification = isPcbAnalysisDone && isApproved === false;
    const { hasAccessToCustomerPortal } = useHasAccessToCustomerPortal();

    const canCustomerEditPcb = isCustomer && hasAccessToCustomerPortal === true && isRfqEditable === true;

    React.useEffect(() => {
        const unListen = history.block((location) => {
            const nextRoute = findRouteId(location.pathname);
            const isPcbRoute = nextRoute && PCBRoutes.includes(nextRoute);
            if (
                isPcbRoute === false &&
                isLeavingPcbWithoutSavingSpecification &&
                localStorage.getItem(getPcbAnalysisId(pcb.id)) !== null
            ) {
                closeSnackbar(getPcbAnalysisId(pcb.id));

                if (canCustomerEditPcb) {
                    setShowMessageModal(true);
                    return false;
                }
            }
        });

        return () => {
            unListen();
        };
    }, [canCustomerEditPcb, closeSnackbar, history, isLeavingPcbWithoutSavingSpecification, pcb.id]);

    return (
        <Dialog open={showMessageModal} maxWidth={'xs'} onClose={() => {}}>
            <DialogContent>
                <Flexbox flexDirection={'column'} gap={'8px'}>
                    <Text variant="h2" style={{ textAlign: 'center' }}>
                        <Trans>Please save your specification</Trans>
                    </Text>
                    <Text>
                        <Trans>
                            Please review the PCB specification and save it before you proceed with the next steps
                        </Trans>
                    </Text>
                </Flexbox>
            </DialogContent>
            <DialogActions style={{ justifyContent: 'center' }}>
                <PrimaryButton
                    size="medium"
                    onClick={() => {
                        history.push(
                            route('/rfqs/:rfqId/bom/assembly/:assemblyId/pcb/specification', { assemblyId, rfqId }),
                        );
                        setShowMessageModal(false);
                    }}
                >
                    <Trans>Go to specification</Trans>
                </PrimaryButton>
            </DialogActions>
        </Dialog>
    );
};
