import { MessageDescriptor } from '@lingui/core';
import { defineMessage } from '@lingui/macro';
import { assertUnreachable, transEnum } from '@luminovo/commons';
import { StatusChip } from '@luminovo/design-system';
import { LifecycleEnum } from '@luminovo/http-client';
import React from 'react';

export const lifecycleEnumPublicTranslations: Record<LifecycleEnum, MessageDescriptor> = {
    [LifecycleEnum.Acquired]: defineMessage({ message: 'Acquired' }),
    [LifecycleEnum.Aftermarket]: defineMessage({ message: 'Aftermarket' }),
    [LifecycleEnum.PreRelease]: defineMessage({ message: 'Pre-release' }),
    [LifecycleEnum.Active]: defineMessage({ message: 'Active' }),
    [LifecycleEnum.NotRecommendedForNewDesigns]: defineMessage({ message: 'Not recommended for new designs' }),
    [LifecycleEnum.EndOfLife]: defineMessage({ message: 'End of life' }),
    [LifecycleEnum.Obsolete]: defineMessage({ message: 'Obsolete' }),
    [LifecycleEnum.Unknown]: defineMessage({ message: 'Unknown' }),
};

export const LifecycleChip: React.FunctionComponent<{
    lifecycle: LifecycleEnum;
}> = ({ lifecycle }) => {
    const label = transEnum(lifecycle, lifecycleEnumPublicTranslations);
    switch (lifecycle) {
        case LifecycleEnum.PreRelease:
            return <StatusChip color="green" label={label} />;
        case LifecycleEnum.Active:
            return <StatusChip color="green" label={label} />;
        case LifecycleEnum.NotRecommendedForNewDesigns:
            return <StatusChip color="yellow" label={label} />;
        case LifecycleEnum.Aftermarket:
            return <StatusChip color="red" label={label} />;
        case LifecycleEnum.EndOfLife:
            return <StatusChip color="red" label={label} />;
        case LifecycleEnum.Obsolete:
            return <StatusChip color="red" label={label} />;
        case LifecycleEnum.Acquired:
            return <StatusChip color="neutral" label={label} />;
        case LifecycleEnum.Unknown:
            return <StatusChip color="neutral" label={label} />;
        default:
            assertUnreachable(lifecycle);
    }
};
