import { t } from '@lingui/macro';
import { id } from '@luminovo/commons';
import { PCBV2 } from '@luminovo/http-client';
import { Box } from '@mui/material';
import * as Sentry from '@sentry/react';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Prompt } from 'react-router';
import { ErrorFallback } from '../../../components/errorHandlers/ErrorBoundary';
import { useFindPcb } from '../../../resources/pcb/pcbHandlers';
import { ViewContext } from '../../Bom/components/ModuleTableData';
import { FormActionToolbar } from '../components/FormActionToolbar';
import { LayerAssignment } from '../components/LayerAssignment';
import {
    ContainersWithFiles,
    createPcbFileUpdateRequest,
    getInitialItemState,
} from '../components/LayerAssignment/utils/layerAssignmentsUtils';
import { useMutationUpdatePcbFileTypes } from '../components/LayerAssignment/utils/useMutationUpdatePcbFileTypes';
import { PcbLoading } from '../components/PcbLoading';
import { PcbSidebarLayout } from '../components/PcbSidebarLayout';

export function FileMatcherTab({
    assemblyId,
    rfqId,
    isEditable,
    viewContext,
}: {
    assemblyId: string;
    rfqId: string;
    isEditable: boolean;
    viewContext: ViewContext;
}) {
    return (
        <PcbLoading assemblyId={assemblyId} rfqId={rfqId} isEditable={isEditable} viewContext={viewContext}>
            <PcFileMatcherLayoutWrapper
                assemblyId={assemblyId}
                rfqId={rfqId}
                isEditable={isEditable}
                viewContext={viewContext}
            />
        </PcbLoading>
    );
}

function PcFileMatcherLayoutWrapper({
    assemblyId,
    rfqId,
    isEditable,
    viewContext,
}: {
    assemblyId: string;
    rfqId: string;
    isEditable: boolean;
    viewContext: ViewContext;
}) {
    const { data: pcb } = useFindPcb({ assemblyId });

    if (!pcb) {
        return <></>;
    }

    return (
        <FileMatcherLayout
            assemblyId={assemblyId}
            rfqId={rfqId}
            pcb={pcb}
            isEditable={isEditable}
            viewContext={viewContext}
        />
    );
}

function FileMatcherLayout({
    assemblyId,
    rfqId,
    pcb,
    isEditable,
    viewContext,
}: {
    assemblyId: string;
    rfqId: string;
    pcb: PCBV2;
    isEditable: boolean;
    viewContext: ViewContext;
}) {
    const [isEditing, setIsEditing] = React.useState(false);
    const [isDirty, setIsDirty] = React.useState(false);
    const pcbFiles = React.useMemo(() => pcb.files ?? [], [pcb]);
    const [items, setItems] = useState<ContainersWithFiles>(getInitialItemState(pcbFiles));
    const { enqueueSnackbar } = useSnackbar();

    // If Pcb files are updated from the server, we need to update the state
    React.useEffect(() => {
        setItems({ ...getInitialItemState(pcbFiles) });
    }, [pcbFiles]);

    const { mutateAsync, isLoading: isUpdatingFiles } = useMutationUpdatePcbFileTypes({ pcbId: pcb.id });

    const useFormReturn = useForm({});

    function handleChange(newValue: ContainersWithFiles | ((prev: ContainersWithFiles) => ContainersWithFiles)) {
        setIsDirty(true);
        if (newValue instanceof Function) {
            return setItems((items) => newValue(items));
        }

        setItems({ ...newValue });
    }

    const handleCancel = () => {
        setItems(getInitialItemState(pcbFiles));
        setIsEditing(false);
        setIsDirty(false);
    };

    const handleEdit = () => {
        setIsEditing(true);
    };

    const handleSubmit = useFormReturn.handleSubmit(async () => {
        const updateRequest = createPcbFileUpdateRequest(items, false);
        const mutateData = await mutateAsync(updateRequest);
        enqueueSnackbar(t`File matching approved. The PCB will be re-rendered now` + '...', {
            variant: 'success',
        });
        setIsEditing(false);
        setIsDirty(false);
        return mutateData;
    });

    return (
        <PcbSidebarLayout pcb={pcb} assemblyId={assemblyId} rfqId={rfqId} viewContext={viewContext}>
            <FormProvider {...useFormReturn}>
                <FormActionToolbar
                    pageTitle={t`Files`}
                    // eslint-disable-next-line spellcheck/spell-checker
                    formId={'pcb-file-matcher-form'}
                    disabled={isUpdatingFiles}
                    isDirty={isDirty}
                    isEditing={isEditing}
                    isEditable={isEditable}
                    submitButtonOptions={{
                        onClick: handleSubmit,
                        id: id('design/button_pcb_file_manager_save'),
                    }}
                    cancelButtonOptions={{
                        onClick: handleCancel,
                    }}
                    editButtonOptions={{
                        onClick: handleEdit,
                        id: id('design/button_pcb_file_manager_edit'),
                    }}
                />
                <Prompt
                    message={t`This form has unsaved changes. Are you sure you want to lose your progress?`}
                    when={isDirty}
                />
                <Box
                    style={{
                        overflow: 'scroll',
                        width: '100%',
                        height: 'calc(100vh - 155px)',
                    }}
                >
                    <Sentry.ErrorBoundary fallback={ErrorFallback}>
                        <LayerAssignment
                            pcbId={pcb.id}
                            items={items}
                            handleOnChange={handleChange}
                            isEditable={isEditing}
                            allowFileUpload={!isDirty}
                        />
                    </Sentry.ErrorBoundary>
                </Box>
            </FormProvider>
        </PcbSidebarLayout>
    );
}
