import { Trans, t } from '@lingui/macro';
import { CenteredLayout, Flexbox, StickyLayout, Text, Toolbar, colorSystem } from '@luminovo/design-system';
import { PCBV2 } from '@luminovo/http-client';
import { InfoOutlined } from '@mui/icons-material';
import { CircularProgress } from '@mui/material';
import { useFindPcb } from '../../../resources/pcb/pcbHandlers';
import { ViewContext } from '../../Bom/components/ModuleTableData';
import { useShowPcbAnalysisNotification } from '../components/PcbAnalysis';
import { PcbLoading } from '../components/PcbLoading';
import { PcbSidebarLayout } from '../components/PcbSidebarLayout';
import { CadFilesViewer } from './components/CadFilesViewer';

export function CadViewerTab({
    assemblyId,
    rfqId,
    isEditable,
    viewContext,
}: {
    assemblyId: string;
    rfqId: string;
    isEditable: boolean;
    viewContext: ViewContext;
}) {
    return (
        <PcbLoading assemblyId={assemblyId} rfqId={rfqId} isEditable={isEditable} viewContext={viewContext}>
            <CadViewerLayoutWrapper assemblyId={assemblyId} rfqId={rfqId} viewContext={viewContext} />
        </PcbLoading>
    );
}

function CadViewerLayoutWrapper({
    assemblyId,
    rfqId,
    viewContext,
}: {
    assemblyId: string;
    rfqId: string;
    viewContext: ViewContext;
}) {
    const { data: pcb } = useFindPcb({ assemblyId });

    if (!pcb) {
        return <CircularProgress />;
    }

    return <CadViewerLayout assemblyId={assemblyId} rfqId={rfqId} pcb={pcb} viewContext={viewContext} />;
}

function CadViewerLayout({
    assemblyId,
    rfqId,
    pcb,
    viewContext,
}: {
    assemblyId: string;
    rfqId: string;
    pcb: PCBV2;
    viewContext: ViewContext;
}) {
    const { showNotification } = useShowPcbAnalysisNotification(pcb);

    return (
        <PcbSidebarLayout pcb={pcb} assemblyId={assemblyId} rfqId={rfqId} viewContext={viewContext}>
            <StickyLayout style={{ maxWidth: '100vw', top: 0, zIndex: 3 }}>
                <Toolbar
                    breadcrumbs={[]}
                    left={
                        <Flexbox gap={'16px'} alignItems={'center'}>
                            <Text variant="h3">{t`CAD viewer`}</Text>
                            {showNotification && (
                                <Text
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: '8px',
                                        color: colorSystem.neutral[9],
                                    }}
                                >
                                    <InfoOutlined fontSize="small" style={{ color: colorSystem.neutral[7] }} />
                                    <Trans>Unsaved changes</Trans>
                                </Text>
                            )}
                        </Flexbox>
                    }
                />
            </StickyLayout>
            {pcb.files && pcb.files.length > 0 ? (
                <CadFilesViewer assemblyId={assemblyId} pcb={pcb} />
            ) : (
                <CenteredLayout
                    style={{
                        height: 'calc(100vh - 155px)',
                    }}
                >
                    <CircularProgress />
                </CenteredLayout>
            )}
        </PcbSidebarLayout>
    );
}
