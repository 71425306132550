import { t } from '@lingui/macro';
import { getToken } from '@luminovo/auth';
import { colorSystem, ParametricSearchInput } from '@luminovo/design-system';
import {
    ExtractArguments,
    ExtractResponseBody,
    http,
    ManufacturerDTO,
    PackageDTO,
    RemoveByValue,
    RfqContext,
} from '@luminovo/http-client';
import { styled, TablePagination, Typography } from '@mui/material';
import { useInfiniteQuery, UseInfiniteQueryResult, useQuery } from '@tanstack/react-query';
import { useRef, useState } from 'react';
import { httpQueryKey } from '../../../../resources/http/httpQueryKey';
import { useHttpQuery } from '../../../../resources/http/useHttpQuery';
import { getPackageOptions } from '../PackageFunctions';
import { buildFiltersFromSearchState } from './buildFiltersFromSearchState';
import {
    ComponentsSearchAutocompleteState,
    UseComponentsSearchStateResult,
    useCustomersById,
} from './ErpParametricSearchFunctions';

export type ComponentsSearchResultsPage = ExtractResponseBody<'POST /components/search'> & { offset?: number };

function groupManufacturersById(res: { data: ManufacturerDTO[] }) {
    return res.data.reduce((manufacturersById: Record<string, ManufacturerDTO>, manufacturer) => {
        manufacturersById[manufacturer.id] = manufacturer;
        return manufacturersById;
    }, {});
}

function groupPackagesById(data: { items: PackageDTO[] }) {
    const packages = getPackageOptions(data.items);
    return packages.reduce((packagesById: Record<string, PackageDTO>, pack) => {
        packagesById[pack.id] = pack;
        return packagesById;
    }, {});
}

const PAGE_SIZE = 50;

export const ComponentsParametricSearchInput = ({
    searchState,
    searchResultsPage,
}: {
    searchState: UseComponentsSearchStateResult;
    searchResultsPage?: ComponentsSearchResultsPage;
}): JSX.Element => {
    const { data: manufacturersById = {} } = useHttpQuery(
        'GET /manufacturers',
        {},
        {
            select: groupManufacturersById,
            staleTime: Infinity,
        },
    );

    const customersById = useCustomersById(searchState);

    const { data: packagesById = {} } = useHttpQuery(
        'GET /parts/packages',
        { queryParams: {} },
        {
            select: groupPackagesById,
            staleTime: Infinity,
        },
    );

    const autocompleteState: ComponentsSearchAutocompleteState = {
        manufacturersById,
        customersById,
        packagesById,
        aggregations: searchResultsPage?.aggregations,
    };

    return (
        <ParametricSearchInput
            overrides={{ Container }}
            {...searchState}
            autocompleteState={autocompleteState}
            placeholder={t`Search`}
        />
    );
};

const Container = styled('span')({
    display: 'inline-flex',
    padding: '4px 8px',
    alignItems: 'center',
    gap: 4,
    border: `1px solid ${colorSystem.neutral[3]}`,
    borderRadius: 4,
    flexGrow: 1,
    minWidth: '280px',
    boxSizing: 'border-box',
    minHeight: 40,
    flexWrap: 'wrap',
    background: colorSystem.neutral.white,
    '&:has(input:focus)': {
        border: `1px solid ${colorSystem.primary[5]}`,
        boxShadow: `0px 0px 0px 2px ${colorSystem.primary[3]}`,
    },
    '&:focus': {
        border: `1px solid ${colorSystem.primary[5]}`,
        boxShadow: `0px 0px 0px 2px ${colorSystem.primary[3]}`,
    },
    '&:has(input:hover)': {
        border: `1px solid ${colorSystem.primary[5]}`,
    },
});

export function useComponentsParametricSearch({
    searchState,
    rfqContext,
    onAnalytic,
    prioritisePreferredIpns = false,
    includeCustomComponents = true,
}: {
    searchState: UseComponentsSearchStateResult;
    rfqContext: RfqContext;
    onAnalytic?: (searchState: UseComponentsSearchStateResult) => void;
    prioritisePreferredIpns?: boolean;
    includeCustomComponents?: boolean;
}): UseInfiniteQueryResult<ComponentsSearchResultsPage> {
    const convertedBlocks = buildFiltersFromSearchState(searchState);

    const rfq_context =
        rfqContext.type === 'WithinRfQ'
            ? { rfq_context: rfqContext.type, rfq_id: rfqContext.rfq_id }
            : { rfq_context: rfqContext.type };
    const fuzzyTerm = convertedBlocks
        .filter((b) => b.field === '*')
        .map((b) => String(b.parameter).trim())
        .filter((x) => x.length > 0)
        .join(' ');

    const body: RemoveByValue<ExtractArguments<'POST /components/search'>, undefined> = {
        requestBody: {
            /* eslint-disable camelcase */
            filters: convertedBlocks.filter((b) => b.field !== '*'),
            fuzzy_term: fuzzyTerm,
            size: PAGE_SIZE,
            include_custom_components: includeCustomComponents,
            prioritise_preferred_ipns: prioritisePreferredIpns,
            ...rfq_context,
            /* eslint-enable camelcase */
        },
    };

    const result = useInfiniteQuery({
        // eslint-disable-next-line @tanstack/query/exhaustive-deps
        queryKey: httpQueryKey('POST /components/search', body),
        queryFn: async ({ pageParam: offset = 0 }): Promise<ComponentsSearchResultsPage> => {
            if (searchState.state.selectedBlocks.length > 0) {
                onAnalytic?.(searchState);
            }

            const result = await http(
                'POST /components/search',
                {
                    requestBody: {
                        ...body.requestBody,
                        offset,
                    },
                },
                getToken(),
            );

            return {
                ...result,
                offset,
            };
        },
        getNextPageParam: (page: ComponentsSearchResultsPage) => {
            return (page?.offset ?? 0) + PAGE_SIZE;
        },
        getPreviousPageParam: (page: ComponentsSearchResultsPage) => {
            return (page?.offset ?? 0) + PAGE_SIZE;
        },

        useErrorBoundary: true,
        suspense: false,
        // Entries can get really big really quickly, so we don't want to cache them for too long
        cacheTime: 10_000,
    });

    return result;
}

/***
 * With this hook, the table pagination controls the offset of the request body.
 * This is useful for the part library ipn table where we want to fetch the next page results when the user clicks on the next page button.
 */
export function useComponentsParamSearchWithTablePagination({
    searchState,
    rfqContext,
    onAnalytic,
    isEditedToday = false,
    prioritisePreferredIpns = false,
    includeCustomComponents = true,
}: {
    searchState: UseComponentsSearchStateResult;
    rfqContext: RfqContext;
    onAnalytic?: (searchState: UseComponentsSearchStateResult) => void;
    isEditedToday?: boolean;
    prioritisePreferredIpns?: boolean;
    includeCustomComponents?: boolean;
}) {
    const [offset, setOffset] = useState(0);

    const convertedBlocks = buildFiltersFromSearchState(searchState);

    const scrollTargetRef = useRef<HTMLDivElement>(null);

    const rfq_context =
        rfqContext.type === 'WithinRfQ'
            ? { rfq_context: rfqContext.type, rfq_id: rfqContext.rfq_id }
            : { rfq_context: rfqContext.type };
    const fuzzyTerm = convertedBlocks
        .filter((b) => b.field === '*')
        .map((b) => String(b.parameter).trim())
        .filter((x) => x.length > 0)
        .join(' ');

    const filters = isEditedToday
        ? [
              ...convertedBlocks.filter((b) => !['*', 'last-changed'].includes(b.field)),
              {
                  field: 'last-changed',
                  op: 'Is',
                  parameter: 'Today',
              },
          ]
        : convertedBlocks.filter((b) => b.field !== '*');

    const body: RemoveByValue<ExtractArguments<'POST /components/search'>, undefined> = {
        requestBody: {
            filters,
            /* eslint-disable camelcase */
            fuzzy_term: fuzzyTerm,
            size: PAGE_SIZE,
            offset,
            include_custom_components: includeCustomComponents,
            prioritise_preferred_ipns: prioritisePreferredIpns,
            /* eslint-enable camelcase */
            ...rfq_context,
        },
    };

    const result = useQuery({
        // eslint-disable-next-line @tanstack/query/exhaustive-deps
        queryKey: httpQueryKey('POST /components/search', body),
        queryFn: async (): Promise<ComponentsSearchResultsPage> => {
            if (searchState.state.selectedBlocks.length > 0) {
                onAnalytic?.(searchState);
            }

            return await http(
                'POST /components/search',
                {
                    requestBody: body.requestBody,
                },
                getToken(),
            );
        },
    });

    const { data } = result;
    const canLoadPreviousPage = offset > 0;
    const totalHits = data?.total_hits.value ?? 0;
    const canLoadNextPage = totalHits > offset + PAGE_SIZE;

    const currentPage = Math.floor(offset / PAGE_SIZE);

    const onPageChange = (newPage: number) => {
        if (newPage < currentPage) {
            setOffset(() => offset - PAGE_SIZE);
        } else {
            setOffset(() => offset + PAGE_SIZE);
        }
        if (scrollTargetRef.current) {
            scrollTargetRef.current.scrollIntoView({ behavior: 'instant' });
        }
    };

    return {
        result,
        scrollTargetRef,
        TablePagination: (
            <TablePagination
                labelDisplayedRows={({ from, to, count }) => (
                    <Typography variant={'body1'} component="span">{t`${from}-${to} of ${count}`}</Typography>
                )}
                component="div"
                rowsPerPage={PAGE_SIZE}
                rowsPerPageOptions={[PAGE_SIZE]}
                count={totalHits}
                page={currentPage}
                nextIconButtonProps={{
                    disabled: !canLoadNextPage,
                }}
                backIconButtonProps={{
                    disabled: !canLoadPreviousPage,
                }}
                onPageChange={(_, page) => {
                    onPageChange(page);
                }}
            />
        ),
    };
}
