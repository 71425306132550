import { Skeleton } from '@mui/material';
import { useHttpQuery } from '../../resources/http/useHttpQuery';
import { isPcbAnalysisSuccess } from '../../resources/pcb/pcbFunctions';
import { useFindPcb } from '../../resources/pcb/pcbHandlers';
import { EmissionsView } from './EmissionsView';

export const PcbEmissionsView = ({ assemblyId }: { assemblyId: string }) => {
    const { data: pcb } = useFindPcb({ assemblyId });
    if (!pcb) {
        return <Skeleton />;
    }
    if (!isPcbAnalysisSuccess(pcb)) {
        return <Skeleton />;
    }
    return <PcbEmissionsLabel pcbId={pcb.id} assemblyId={assemblyId} />;
};

const PcbEmissionsLabel = ({ pcbId, assemblyId }: { pcbId: string; assemblyId: string }) => {
    const pcbEmissionsData = useHttpQuery('GET /assemblies/:assemblyId/pcb/:pcbId/emissions', {
        pathParams: { assemblyId, pcbId },
    });
    if (!pcbEmissionsData.data) {
        return <>-</>;
    }

    return (
        <EmissionsView
            min={Number(pcbEmissionsData.data.product_phase_gwp_in_kg_co2e_min)}
            max={Number(pcbEmissionsData.data.product_phase_gwp_in_kg_co2e_max)}
        />
    );
};
