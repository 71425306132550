import { Trans, t } from '@lingui/macro';
import { formatDecimal, transEnum } from '@luminovo/commons';
import { Chip, Flexbox, SecondaryIconButton, Tag, Text, colorSystem } from '@luminovo/design-system';
import { AssemblyDTO, ExtractResponseBody, PCBV2, SourcingScenarioDTO } from '@luminovo/http-client';
import { formatSupplierAndStockLocationDTO } from '@luminovo/sourcing-core';
import { DescriptionOutlined, FolderZipOutlined, GetApp } from '@mui/icons-material';
import { Box, CircularProgress, Link, Skeleton, styled } from '@mui/material';
import { LayoutCard } from '../../../../../components/LayoutCard';
import { useDownloadPCBFiles, useExportPcbSpecificationInPDF } from '../../../../../resources/export/exportHandler';
import { downloadPcbFilesAnalytic, downloadPcbPDFAnalytic } from '../../../../../resources/pcb/analytics/analytic';
import { layerStackTypeTranslations } from '../../../../../resources/pcb/i18n';
import { route } from '../../../../../utils/routes';
import { usePcbOffersState } from '../../../../Pcb/components/PcbPriceRadar/utils/usePcbOfferState';
import { PcbThumbnail } from '../../../../SupplierPortal/SupplierQuotePage/components/PcbThumbnail';

const StyledLink = styled(Link)({
    color: colorSystem.neutral[8],
    '&:hover': {
        color: colorSystem.primary[6],
    },
});

function PcbPropertyChip({ label, value }: { label: string; value?: string }) {
    return <Tag color="neutral" attention="low" label={`${label}: ${value}`} />;
}

const PCBDetails = ({ pcb, assembly, rfqId }: { pcb: PCBV2; assembly: AssemblyDTO; rfqId: string }) => {
    const width = pcb.properties.board.boardWidth;
    const height = pcb.properties.board.boardHeight;
    const numLayers = pcb.properties.layerStack.layercount;
    const layerStackType = pcb.properties.layerStack.layerstackType;

    return (
        <>
            <Flexbox flexDirection={'row'} justifyContent={'space-between'}>
                <Flexbox
                    flexDirection={'column'}
                    gap="8px"
                    style={{
                        width: 'calc(100% - 100px)',
                    }}
                >
                    <StyledLink
                        variant="h2"
                        href={route('/rfqs/:rfqId/bom/assembly/:assemblyId/pcb', {
                            rfqId,
                            assemblyId: assembly.id,
                        })}
                    >
                        <Text
                            showEllipsis
                            variant="h2"
                            style={{
                                color: 'inherit',
                                maxWidth: '100%',
                                display: 'inline-block',
                            }}
                        >
                            {assembly.designator}
                        </Text>
                    </StyledLink>
                    <Flexbox flexDirection={'row'} gap="8px">
                        <Flexbox gap={'8px'} alignItems="center" flexWrap={'wrap'}>
                            {layerStackType && (
                                <PcbPropertyChip
                                    label={t`Layer stack`}
                                    value={transEnum(layerStackType, layerStackTypeTranslations)}
                                />
                            )}
                            <PcbPropertyChip label={t`Width`} value={formatDecimal(width, { suffix: ' mm' })} />
                            <PcbPropertyChip label={t`Height`} value={formatDecimal(height, { suffix: ' mm' })} />
                            <PcbPropertyChip label={t`Layers`} value={formatDecimal(numLayers)} />
                        </Flexbox>
                    </Flexbox>
                </Flexbox>
                <Box width={'100px'}>
                    <PcbThumbnail pcbId={pcb.id} size={100} />
                </Box>
            </Flexbox>
        </>
    );
};

const RequestedSolutionsDetails = ({
    pcb,
    assemblyId,
    customPartOfferSolution,
}: {
    pcb: PCBV2;
    assemblyId: string;
    customPartOfferSolution: ExtractResponseBody<'POST /solutions/custom-part/bulk'>;
}) => {
    const { data: offers, isLoading: isLoadingOfferState } = usePcbOffersState({ pcb, assemblyId });

    if (customPartOfferSolution.supplier_solutions.length === 0) {
        return null;
    }

    const suppliersRequestedFrom =
        offers === undefined
            ? []
            : offers.status.type === 'Detailed'
              ? offers.status.data.map((offer) => offer.supplier)
              : [];
    const suppliersReceivedFrom = customPartOfferSolution.supplier_solutions.map((s) => {
        return s.linked_location;
    });

    const sourcingScenariosRequested: SourcingScenarioDTO[] =
        customPartOfferSolution.supplier_solutions[0].sourcing_scenario_solutions.map((s) => s.sourcing_scenario);

    return (
        <>
            <Flexbox flexDirection={'column'} gap={8}>
                <Text
                    variant="h5"
                    style={{
                        color: colorSystem.neutral[6],
                    }}
                >
                    <Trans>Requested from</Trans>
                </Text>
                <Flexbox gap={8} flexWrap={'wrap'}>
                    {isLoadingOfferState ? (
                        <Skeleton />
                    ) : (
                        suppliersRequestedFrom.map((supplier, index) => (
                            <Chip key={`${supplier.id}${index}`} color="neutral" label={supplier.name} />
                        ))
                    )}
                </Flexbox>
            </Flexbox>
            <Flexbox flexDirection={'column'} gap={8}>
                <Text
                    variant="h5"
                    style={{
                        color: colorSystem.neutral[6],
                    }}
                >
                    <Trans>Received from</Trans>
                </Text>
                <Flexbox gap={8} flexWrap={'wrap'}>
                    {suppliersReceivedFrom.map((supplier) => (
                        <Chip key={supplier.id} color="primary" label={formatSupplierAndStockLocationDTO(supplier)} />
                    ))}
                </Flexbox>
            </Flexbox>
            <Flexbox flexDirection={'column'} gap={8}>
                <Text
                    variant="h5"
                    style={{
                        color: colorSystem.neutral[6],
                    }}
                >
                    <Trans>Requested scenarios</Trans>
                </Text>
                <Flexbox gap={8} flexWrap={'wrap'}>
                    {sourcingScenariosRequested.map((scenario) => (
                        <Chip key={scenario.id} color="neutral" label={scenario.name} />
                    ))}
                </Flexbox>
            </Flexbox>
        </>
    );
};

const AttachmentComponent = ({
    title,
    icon,
    onDownloadClick,
    isDownloading,
}: {
    title: string;
    icon: JSX.Element;
    onDownloadClick: () => void;
    isDownloading: boolean;
}) => {
    return (
        <Flexbox
            style={{
                borderRadius: '8px',
                padding: '8px',
                border: `1px solid ${colorSystem.neutral[2]}`,
                background: colorSystem.neutral[0],
            }}
            alignItems={'center'}
            justifyContent={'space-between'}
        >
            <Flexbox gap={8} alignItems="center">
                <Box
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        background: colorSystem.neutral[1],
                        borderRadius: '4px',
                    }}
                >
                    {icon}
                </Box>
                <Text variant="body-small">{title}</Text>
            </Flexbox>
            <SecondaryIconButton size="small" onClick={onDownloadClick}>
                {isDownloading ? <CircularProgress size={'16px'} /> : <GetApp fontSize="inherit" />}
            </SecondaryIconButton>
        </Flexbox>
    );
};

const PCBAttachments = ({ pcb, assembly, rfqId }: { pcb: PCBV2; assembly: AssemblyDTO; rfqId: string }) => {
    const { mutateAsync: downloadPdfSpecification, isLoading: isGeneratingPDF } = useExportPcbSpecificationInPDF(pcb, {
        onSuccess: () => {
            downloadPcbPDFAnalytic({
                pcbId: pcb.id,
                rfqId,
                assemblyId: assembly.id,
            });
        },
    });

    const { downloadPCBFiles, isLoading: isDownloadingPcb } = useDownloadPCBFiles({
        pcbId: pcb.id,
        options: {
            onSuccess: () => {
                downloadPcbFilesAnalytic({
                    pcbId: pcb.id,
                    rfqId,
                    assemblyId: assembly.id,
                });
            },
        },
    });
    return (
        <>
            <Flexbox flexDirection={'column'} gap={8}>
                <Text
                    variant="h5"
                    style={{
                        color: colorSystem.neutral[6],
                    }}
                >
                    <Trans>Attachments</Trans>
                </Text>

                <AttachmentComponent
                    title={t`Specification.pdf`}
                    icon={
                        <DescriptionOutlined
                            style={{
                                color: colorSystem.neutral[8],
                                fontSize: '16px',
                            }}
                        />
                    }
                    onDownloadClick={downloadPdfSpecification}
                    isDownloading={isGeneratingPDF}
                />

                <AttachmentComponent
                    title={t`PCB files`}
                    icon={
                        <FolderZipOutlined
                            style={{
                                color: colorSystem.neutral[8],
                                fontSize: '16px',
                            }}
                        />
                    }
                    onDownloadClick={downloadPCBFiles}
                    isDownloading={isDownloadingPcb}
                />
            </Flexbox>
        </>
    );
};

export const AssemblySourcingSidebar = ({
    assembly,
    pcb,
    rfqId,
    customPartOfferSolution,
}: {
    assembly: AssemblyDTO;
    pcb: PCBV2;
    rfqId: string;
    customPartOfferSolution: ExtractResponseBody<'POST /solutions/custom-part/bulk'>;
}) => {
    return (
        <LayoutCard title={undefined}>
            <Flexbox flexDirection={'column'} gap={24}>
                <PCBDetails pcb={pcb} assembly={assembly} rfqId={rfqId} />
                <RequestedSolutionsDetails
                    pcb={pcb}
                    assemblyId={assembly.id}
                    customPartOfferSolution={customPartOfferSolution}
                />
                <PCBAttachments pcb={pcb} assembly={assembly} rfqId={rfqId} />
            </Flexbox>
        </LayoutCard>
    );
};
