import { assertUnreachable } from '@luminovo/commons';
import {
    FullPart,
    OtsFullPart,
    isCustomComponentFull,
    isCustomFullPart,
    isGenericFullPart,
    isOtsComponentFull,
    isOtsFullPart,
} from '@luminovo/http-client';
import { EmissionsView, formatEmission } from './EmissionsView';
import { findMinMaxEmissions } from './findMinMaxEmissions';

/**
 *
 * @returns a JSX.Element with the carbon footprint of a given part which can be a single value or a range.
 *   If quantity is given, the return value will be multiplied by it.
 */
export const PartEmissionsView = ({ part, quantity }: { part: FullPart; quantity?: number }): JSX.Element => {
    if (isOtsFullPart(part)) {
        return <OtsPartEmissionsView emissionsData={part.emissions_data} quantity={quantity} />;
    }
    if (isGenericFullPart(part)) {
        return <OtsFullPartMatchesEmissionsView matches={part.matches} quantity={quantity} />;
    }
    if (isOtsComponentFull(part)) {
        const matches: OtsFullPart[] = Array.from(part.matches).flatMap((match) => {
            if (match.part.type === 'Generic') {
                return match.part.data.matches;
            }
            if (match.part.type === 'OffTheShelf') {
                return match.part.data;
            }
            return [];
        });
        return <OtsFullPartMatchesEmissionsView matches={matches} quantity={quantity} />;
    }
    if (isCustomFullPart(part)) {
        return <>-</>;
    }
    if (isCustomComponentFull(part)) {
        return <>-</>;
    }
    assertUnreachable(part);
};

const OtsPartEmissionsView = ({
    emissionsData,
    quantity,
}: {
    emissionsData: { emission_product_phase_gwp_in_kg_co2e: string } | null;
    quantity?: number;
}) => {
    if (!emissionsData) {
        return <>-</>;
    }
    const partEmissions = emissionsData.emission_product_phase_gwp_in_kg_co2e;
    const totalEmissions = quantity ? Number(partEmissions) * quantity : partEmissions;

    return <>{formatEmission(totalEmissions)}</>;
};

const OtsFullPartMatchesEmissionsView = ({ matches, quantity }: { matches: OtsFullPart[]; quantity?: number }) => {
    if (matches.length === 0) {
        return <>-</>;
    }
    if (matches.length === 1) {
        if (!matches[0].emissions_data) {
            return <>-</>;
        }
        const partEmissions = matches[0].emissions_data.emission_product_phase_gwp_in_kg_co2e;
        const totalEmissions = quantity ? Number(partEmissions) * quantity : partEmissions;
        return <>{formatEmission(totalEmissions)}</>;
    }

    const { min: minPartEmissions, max: maxPartEmissions } = findMinMaxEmissions({ parts: matches });
    if (!minPartEmissions || !maxPartEmissions) {
        return <>-</>;
    }
    const minTotalEmissions = quantity ? Number(minPartEmissions) * quantity : minPartEmissions;
    const maxTotalEmissions = quantity ? Number(maxPartEmissions) * quantity : maxPartEmissions;
    return <EmissionsView min={minTotalEmissions} max={maxTotalEmissions} />;
};
