import { useAuth0 } from '@auth0/auth0-react';
import { Trans, t } from '@lingui/macro';
import { usePermissions } from '@luminovo/auth';
import { CenteredLayout, Link, NonIdealState } from '@luminovo/design-system';
import { ExitToApp, Lock } from '@mui/icons-material';
import { Typography } from '@mui/material';
import { ORIGIN } from '../const';
import { analytics } from '../utils/analytics';

export function NotAuthorizedPage() {
    const { permissions } = usePermissions();
    const { logout } = useAuth0();

    const handleLogout = () => {
        analytics.track('logout');
        logout({ returnTo: ORIGIN });
    };

    return (
        <CenteredLayout>
            <NonIdealState
                Icon={Lock}
                title={t`Not authorized`}
                description={
                    <Typography variant={'body1'} style={{ marginTop: 16 }}>
                        <Trans>
                            It looks like you don't have access to this page. If you believe this is an error, please
                            contact us at{' '}
                            <Link href="mailto:support@luminovo.com" attention="low">
                                support@luminovo.com
                            </Link>{' '}
                            or send us a message over our chatbox.
                        </Trans>
                    </Typography>
                }
                action={{
                    onClick: handleLogout,
                    children: t`Log out`,
                    startIcon: <ExitToApp />,
                    variant: 'outlined',
                    color: 'primary',
                }}
            />
            <details style={{ height: 40, minWidth: 320, textAlign: 'center', marginBlockStart: '-32px' }}>
                <summary>
                    <Typography display="inline">
                        <Trans>View permissions</Trans>
                    </Typography>
                </summary>
                <pre style={{ marginTop: 8 }}>
                    {permissions.length === 0 ? <Trans>None</Trans> : permissions.join('\n')}
                </pre>
            </details>
        </CenteredLayout>
    );
}
