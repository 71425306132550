import { Trans } from '@lingui/macro';
import { colorSystem, Flexbox, Text } from '@luminovo/design-system';
import { Box } from '@mui/material';
import { useDemandData } from '../../../resources/demand/demandHandler';
import { DemandSummary, useButtonGroups } from '../../Demand/DemandSummary';
import { DemandTable } from '../../PartLibrary/IpnDetailsPage/components/DemandsTab/DemandTable';

export const SupplyChainTab = ({ assemblyIpn }: { assemblyIpn: string }): JSX.Element => {
    const buttonGroups = useButtonGroups();
    const { data: demandData } = useDemandData({
        assemblyId: assemblyIpn,
        quantityType: buttonGroups.quantityType,
        timeframe: buttonGroups.yearlyMonthly,
        company: buttonGroups.supplierRecipient,
    });

    return (
        <Flexbox sx={{ background: colorSystem.neutral[1] }} flexDirection={'column'} gap={24}>
            <DemandSummary buttonGroups={buttonGroups} demandData={demandData} />
            <Flexbox
                sx={{
                    backgroundColor: colorSystem.neutral.white,
                    paddingY: '24px',
                    paddingX: '20px',
                    height: '100%',
                }}
                flexDirection={'column'}
                gap={'16px'}
            >
                <Text color={colorSystem.neutral[8]} variant="h3">
                    {buttonGroups.quantityType === 'net_quantity' && <Trans>Net demand</Trans>}
                    {buttonGroups.quantityType === 'gross_quantity' && <Trans>Gross demand</Trans>}
                </Text>
                <Box
                    sx={{
                        height: '200px',
                    }}
                >
                    <DemandTable ipnId={assemblyIpn} demandType={buttonGroups.quantityType} />
                </Box>
            </Flexbox>
        </Flexbox>
    );
};
