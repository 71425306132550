import { colorSystem, Flexbox, Text } from '@luminovo/design-system';
import { HighlightOffRounded, InfoOutlined } from '@mui/icons-material';
import { BomImporterIssue, IssueNameEnum, Level } from '../../../resources/bomImporter/bomImporterIssuesEnum';
import { convertIssueToUserFacingMessage } from '../../../resources/bomImporter/convertIssueToUserFacingMessage';
import { assertUnreachable } from '../../../utils/typingUtils';

export const BomIssueItem = ({ issue }: { issue: BomImporterIssue }) => {
    if (
        issue.name === IssueNameEnum.NO_DNP_COLUMN ||
        issue.name === IssueNameEnum.NO_MPN_COLUMN ||
        issue.name === IssueNameEnum.NO_QUANTITY_COLUMN ||
        issue.name === IssueNameEnum.NO_DESIGNATOR_COLUMN ||
        issue.name === IssueNameEnum.NO_DESIGNATOR_AND_QUANTITY
    ) {
        // Skipping these as they've been added as frontend checks
        // otherwise they won't clear once fixed through the UI
        return null;
    }
    return <ValidationIssueItem level={issue.level} message={convertIssueToUserFacingMessage(issue)} />;
};

const getColor = (level: Level): string => {
    switch (level) {
        case Level.Error:
            return colorSystem.red[6];
        case Level.Notification:
            return colorSystem.neutral[6];
        case Level.Warning:
            return colorSystem.yellow[6];
        default:
            assertUnreachable(level);
    }
};

export const ValidationIssueItem = ({
    message,
    level = Level.Error,
}: {
    message: string | JSX.Element;
    level?: Level;
}): JSX.Element => {
    const Icon = level === Level.Error ? HighlightOffRounded : InfoOutlined;
    return (
        <Flexbox alignItems={'center'} gap={8}>
            <Icon style={{ color: getColor(level), alignSelf: 'flex-start' }} />
            <Text>{message}</Text>
        </Flexbox>
    );
};
