type Stringable = string | number | boolean;

type Optionality = 'optional' | 'required';

export interface RouteDefinition {
    path: Record<string, 'required'>;
    query: Record<string, Optionality>;
    page?: string;
}

/**
 * Utility function to define a route that conforms to the RouteDefinition type.
 */
export function defineRoute<T extends RouteDefinition>(r: T): T {
    return r;
}

const assemblyRoutes = {
    '/assemblies': defineRoute({
        path: {},
        query: {
            filters: 'optional',
        },
        page: 'AssemblyOverviewPage',
    }),
    '/assemblies/:assemblyId/dashboard': defineRoute({
        path: {
            assemblyId: 'required',
        },
        query: {
            rfqId: 'optional',
            tab: 'optional',
            isMonitoringOpen: 'optional',
        },
        page: 'AssemblyDashboardPage',
    }),
    '/assemblies/:assemblyId/details': defineRoute({
        path: {
            assemblyId: 'required',
        },
        query: {
            designItemId: 'optional',
            filters: 'optional',
            dashboardFilters: 'optional',
            search: 'optional',
        },
        page: 'AssemblyDetailsPage',
    }),
    '/assemblies/add-assembly': defineRoute({
        path: {},
        query: {
            assemblyId: 'required',
            rfqId: 'required',
        },
        page: 'AddAssemblyPage',
    }),
    '/assemblies/:assemblyId/edit': defineRoute({
        path: {
            assemblyId: 'required',
        },
        query: {
            rfqId: 'required',
        },
        page: 'AssemblyOverviewEditPage',
    }),
    '/assemblies/:assemblyId/dashboard/bom-importer/page-1': defineRoute({
        path: {
            assemblyId: 'required',
        },
        query: {
            rfqId: 'required',
        },
    }),
    '/assemblies/:assemblyId/dashboard/bom-importer/page-2': defineRoute({
        path: {
            assemblyId: 'required',
        },
        query: {
            rfqId: 'required',
        },
    }),
    '/assemblies/:assemblyId/pcb': defineRoute({
        path: { assemblyId: 'required' },
        query: {
            rfqId: 'required',
        },
        page: 'AssemblyOverviewPcbRedirectPage',
    }),
    '/assemblies/:assemblyId/pcb/cad-viewer': defineRoute({
        path: { assemblyId: 'required' },
        query: {
            rfqId: 'required',
        },
        page: 'AssemblyOverviewPcbCadViewerPage',
    }),
    '/assemblies/:assemblyId/pcb/specification': defineRoute({
        path: { assemblyId: 'required' },
        query: { rfqId: 'required' },
        page: 'AssemblyOverviewPcbSpecificationPage',
    }),
    '/assemblies/:assemblyId/pcb/files': defineRoute({
        path: { assemblyId: 'required' },
        query: { rfqId: 'required' },
        page: 'AssemblyOverviewPcbFilePage',
    }),
    '/assemblies/:assemblyId/pcb/drills': defineRoute({
        path: { assemblyId: 'required' },
        query: { rfqId: 'required' },
        page: 'AssemblyOverviewDrillPage',
    }),
    '/assemblies/:assemblyId/pcb/stackup': defineRoute({
        path: { assemblyId: 'required' },
        query: { rfqId: 'required' },
        page: 'AssemblyOverviewStackupPage',
    }),
    '/assemblies/:assemblyId/pcb/panelization': defineRoute({
        path: { assemblyId: 'required' },
        query: { rfqId: 'required' },
        page: 'AssemblyOverviewPanelizationPage',
    }),
};

const calculationRoutes = {
    '/calculation/templates': defineRoute({
        query: {},
        path: {},
        page: 'CalculationTemplatesViewPage',
    }),
    '/calculation/templates/add-template': defineRoute({
        query: {},
        path: {},
        page: 'CalculationAddTemplatePage',
    }),
    '/calculation/templates/:templateId/edit': defineRoute({
        path: { templateId: 'required' },
        query: {},
        page: 'CalculationEditTemplatePage',
    }),
};

const manufacturingRoutes = {
    '/manufacturing/resource': defineRoute({
        query: {},
        path: {},
        page: 'ResourcesViewPage',
    }),
    '/manufacturing/driver': defineRoute({
        path: {},
        query: {},
        page: 'DriversViewPage',
    }),
    '/manufacturing/driver/:driverId/edit': defineRoute({
        path: { driverId: 'required' },
        query: {},
        page: 'EditDriverForm',
    }),
    '/manufacturing/expense': defineRoute({
        path: {},
        query: {},
        page: 'ExpensesViewPage',
    }),
    '/manufacturing/template': defineRoute({
        path: {},
        query: {},
        page: 'ManufacturingScenarioTemplateManagementPage',
    }),
    '/manufacturing/template/:templateId': defineRoute({
        path: { templateId: 'required' },
        query: {},
        page: 'ManufacturingScenarioTemplateManagementPage',
    }),

    '/manufacturing/resource/:resourceId/edit': defineRoute({
        path: { resourceId: 'required' },
        query: {},
        page: 'EditResourceForm',
    }),
    '/manufacturing/expense/:expenseId/edit': defineRoute({
        path: { expenseId: 'required' },
        query: {},
        page: 'EditExpensePage',
    }),
    '/manufacturing/activity/:activityId/edit': defineRoute({
        path: { activityId: 'required' },
        query: {},
        page: 'EditActivityForm',
    }),
    '/manufacturing/template/edit': defineRoute({
        path: {},
        query: {},
    }),
    '/manufacturing/add-activity': defineRoute({
        path: {},
        query: {},
        page: 'AddActivityForm',
    }),
    '/manufacturing/add-expense': defineRoute({
        path: {},
        query: {},
        page: 'AddExpensePage',
    }),
    '/manufacturing/add-resource': defineRoute({
        path: {},
        query: {},
        page: 'AddResourceForm',
    }),
    '/manufacturing/add-driver': defineRoute({
        path: {},
        query: {},
        page: 'DriverFormPage',
    }),
    '/manufacturing/activity': defineRoute({
        path: {},
        query: {},
        page: 'ActivitiesViewPage',
    }),
};

const rfqCalculationRoutes = {
    '/rfqs/:rfqId/calculation/teaser': defineRoute({
        path: { rfqId: 'required' },
        query: {},
    }),
    '/rfqs/:rfqId/calculation/print': defineRoute({
        path: { rfqId: 'required' },
        query: {
            validUntil: 'required',
            customText: 'optional',
            additionalLeadTimeDays: 'required',
            quotationNumber: 'required',
            footerRight: 'optional',
            footerLeft: 'optional',
            footerCenter: 'optional',
        },
        page: 'CalculationPrintPage',
    }),
};

const rfqManufacturingRoutes = {
    '/rfqs/:rfqId/manufacturing/activity': defineRoute({
        path: { rfqId: 'required' },
        query: {},
    }),
    '/rfqs/:rfqId/manufacturing/expense': defineRoute({
        path: { rfqId: 'required' },
        query: {},
    }),
    '/rfqs/:rfqId/manufacturing': defineRoute({
        path: { rfqId: 'required' },
        query: { forwardIfSingleAssembly: 'optional' },
        page: 'AssemblyOverviewPage',
    }),
    '/rfqs/:rfqId/manufacturing/teaser': defineRoute({
        path: { rfqId: 'required' },
        query: {},
        page: 'ManufacturingTeaserPage',
    }),
    '/rfqs/:rfqId/manufacturing/:manufacturingAssemblyDetailsId': defineRoute({
        path: { rfqId: 'required', manufacturingAssemblyDetailsId: 'required' },
        query: {},
        page: 'ManufacturingAssemblyDetailsPage',
    }),
    '/rfqs/:rfqId/manufacturing/:manufacturingAssemblyDetailsId/activity': defineRoute({
        path: { rfqId: 'required', manufacturingAssemblyDetailsId: 'required' },
        query: {},
    }),
    '/rfqs/:rfqId/manufacturing/:manufacturingAssemblyDetailsId/:manufacturingScenarioId/activity': defineRoute({
        path: { rfqId: 'required', manufacturingAssemblyDetailsId: 'required', manufacturingScenarioId: 'required' },
        query: {},
        page: 'ManufacturingAssemblyScenarioDetailsPage',
    }),
    '/rfqs/:rfqId/manufacturing/:manufacturingAssemblyDetailsId/:manufacturingScenarioId/expense': defineRoute({
        path: { rfqId: 'required', manufacturingAssemblyDetailsId: 'required', manufacturingScenarioId: 'required' },
        query: {},
        page: 'ManufacturingAssemblyScenarioDetailsPage',
    }),
    '/manufacturing/activity/:activityId/duplicate': defineRoute({
        path: { activityId: 'required' },
        query: {},
        page: 'DuplicateActivityForm',
    }),
    '/manufacturing/driver/:driverId/duplicate': defineRoute({
        path: { driverId: 'required' },
        query: {},
        page: 'DuplicateDriverForm',
    }),
    '/manufacturing/resource/:resourceId/duplicate': defineRoute({
        path: { resourceId: 'required' },
        query: {},
        page: 'DuplicateResourceForm',
    }),
    '/manufacturing/expense/:expenseId/duplicate': defineRoute({
        path: { expenseId: 'required' },
        query: {},
        page: 'DuplicateExpensePage',
    }),
};

const pnpImporterRoutes = {
    '/rfqs/:rfqId/bom/assembly/:assemblyId/pnp-importer': defineRoute({
        path: { rfqId: 'required', assemblyId: 'required' },
        query: {
            currentParentAssemblyId: 'optional',
        },
        page: 'PnpImporterPage',
    }),
};

/* eslint-disable spellcheck/spell-checker */
const partLibraryRoutes = {
    '/parts': defineRoute({
        path: {},
        query: {},
        page: 'PartLibraryRouteController',
    }),
    '/parts/details/:partId': defineRoute({
        path: { partId: 'required' },
        query: { taskId: 'optional' },
    }),
    '/parts/details/:partId/inventory': defineRoute({
        path: { partId: 'required' },
        query: { taskId: 'optional' },
    }),
    '/parts/details/:partId/offers': defineRoute({
        path: { partId: 'required' },
        query: { taskId: 'optional' },
    }),
    '/parts/details/:partId/compliance': defineRoute({
        path: { partId: 'required' },
        query: { taskId: 'optional' },
    }),
    '/parts/details/:partId/used-in': defineRoute({
        path: { partId: 'required' },
        query: { taskId: 'optional' },
    }),
    '/parts/details/:partId/part-alternatives': defineRoute({
        path: { partId: 'required' },
        query: { taskId: 'optional' },
    }),
    '/parts/off-the-shelf-part-search': defineRoute({
        path: {},
        query: {},
    }),
    '/parts/components': defineRoute({
        path: {},
        query: { q: 'optional' },
    }),
    '/parts/components/ipn': defineRoute({
        path: {},
        query: { ipnId: 'required', q: 'optional' },
    }),
    '/parts/components/component-importer': defineRoute({
        path: {},
        query: {},
    }),
    '/offers/inventory-offer-importer': defineRoute({
        path: {},
        query: {},
    }),
    '/offers/standard-offer-importer': defineRoute({
        path: {},
        query: {},
    }),
    '/parts/components/ipn/inventory': defineRoute({
        path: {},
        query: { ipnId: 'required', q: 'optional' },
    }),
    '/parts/components/ipn/linking': defineRoute({
        path: {},
        query: { ipnId: 'required', q: 'optional' },
    }),
    '/parts/components/ipn/used-in': defineRoute({
        path: {},
        query: { ipnId: 'required', q: 'optional' },
    }),
    '/parts/components/ipn/cpns': defineRoute({
        path: {},
        query: { ipnId: 'required', q: 'optional' },
    }),
    '/parts/components/ipn/demand': defineRoute({
        path: {},
        query: { ipnId: 'required', q: 'optional' },
    }),
    '/parts/demand/demand-importer': defineRoute({
        path: {},
        query: {},
    }),
    '/parts/source-list-importer': defineRoute({
        path: {},
        query: {},
    }),
    '/parts/components/ipn/offers': defineRoute({
        path: {},
        query: { ipnId: 'required', q: 'optional' },
    }),
    '/parts/alerts': defineRoute({
        path: {},
        query: {},
    }),
};
/* eslint-enable spellcheck/spell-checker */

/**
 * Define all routes in LumiQuote here.
 *
 * - The key is the route template in LumiQuote
 * - The value describes the arguments that the template takes.
 */
export const routes = {
    '/home': defineRoute({
        path: {},
        query: {},
    }),

    '/notFound': defineRoute({
        path: {},
        query: {},
        page: 'NotFoundPage',
    }),

    '/3rdparty/digikey/callback': defineRoute({
        path: {},
        query: { code: 'optional' },
        page: 'DigikeyRedirectPage',
    }),
    '/rfq_creation': defineRoute({
        path: {},
        query: {},
        page: 'RfqCreationPage',
    }),

    '/rfqs': defineRoute({
        path: {},
        query: {},
        page: 'RfqOverviewPage',
    }),

    '/rfqs/:rfqId': defineRoute({
        path: { rfqId: 'required' },
        query: {},
    }),

    '/rfqs/:rfqId/edit': defineRoute({
        path: { rfqId: 'required' },
        query: {},
        page: 'RfqEditPage',
    }),

    '/rfqs/:rfqId/bom': defineRoute({
        path: { rfqId: 'required' },
        query: { hideTopLevelAssembly: 'optional' },
        page: 'BomPage',
    }),

    '/rfqs/:rfqId/bom/assembly/:assemblyId': defineRoute({
        path: { rfqId: 'required', assemblyId: 'required' },
        query: {
            tab: 'optional',
            monitoring: 'optional',
            currentParentAssemblyId: 'optional',
        },
        page: 'AssemblyPage',
    }),

    '/rfqs/:rfqId/bom/assembly/:assemblyId/edit': defineRoute({
        path: { rfqId: 'required', assemblyId: 'required' },
        query: {
            currentParentAssemblyId: 'optional',
        },
        page: 'EditAssemblyPage',
    }),

    '/rfqs/:rfqId/bom/assembly/:assemblyId/details': defineRoute({
        path: { rfqId: 'required', assemblyId: 'required' },
        query: {
            currentParentAssemblyId: 'optional',
            isReadonly: 'optional',
            designItemId: 'optional',
            filters: 'optional',
            bomTab: 'optional',
            dashboardFilters: 'optional',
            search: 'optional',
            // This is relevant only for Manufacturing,
            // when a user clicks on Resolve in Bom in the Driver, they
            // should only see the bom-items that have the issues leading to warnings.
            onlyShowItemsWithManufacturingWarnings: 'optional',
        },
        page: 'BomEditPage',
    }),

    '/rfqs/:rfqId/bom/assembly/:assemblyId/pcb': defineRoute({
        path: { rfqId: 'required', assemblyId: 'required' },
        query: {
            isReadonly: 'optional',
            designItemId: 'optional',
            currentParentAssemblyId: 'optional',
        },
        page: 'PcbRedirectPage',
    }),

    '/rfqs/:rfqId/bom/assembly/:assemblyId/pcb/cad-viewer': defineRoute({
        path: { rfqId: 'required', assemblyId: 'required' },
        query: {
            currentParentAssemblyId: 'optional',
        },
        page: 'PcbCadViewerPage',
    }),

    '/rfqs/:rfqId/bom/assembly/:assemblyId/pcb/specification': defineRoute({
        path: { rfqId: 'required', assemblyId: 'required' },
        query: {},
        page: 'PcbSpecificationPage',
    }),

    '/rfqs/:rfqId/bom/assembly/:assemblyId/pcb/files': defineRoute({
        path: { rfqId: 'required', assemblyId: 'required' },
        query: {},
        page: 'PcbLayerStackPage',
    }),

    '/rfqs/:rfqId/bom/assembly/:assemblyId/pcb/drills': defineRoute({
        path: { rfqId: 'required', assemblyId: 'required' },
        query: {},
        page: 'PcbDrillManagementPage',
    }),
    '/rfqs/:rfqId/bom/assembly/:assemblyId/pcb/stackup': defineRoute({
        path: { rfqId: 'required', assemblyId: 'required' },
        query: {},
        page: 'PcbStackUpPage',
    }),
    '/rfqs/:rfqId/bom/assembly/:assemblyId/pcb/panelization': defineRoute({
        path: { rfqId: 'required', assemblyId: 'required' },
        query: {},
        page: 'PcbPanelizationPage',
    }),
    '/rfqs/:rfqId/bom/assembly/:assemblyId/bom-importer/page-1': defineRoute({
        path: { rfqId: 'required', assemblyId: 'required' },
        query: {},
    }),

    '/rfqs/:rfqId/bom/assembly/:assemblyId/bom-importer/page-2': defineRoute({
        path: { rfqId: 'required', assemblyId: 'required' },
        query: {},
    }),

    '/rfqs/:rfqId/bom/assembly/add-assembly': defineRoute({
        path: { rfqId: 'required' },
        query: { assemblyId: 'required' },
        page: 'AddAssemblyPage',
    }),

    '/rfqs/:rfqId/dashboard': defineRoute({
        path: { rfqId: 'required' },
        query: { showOemDashboard: 'optional' },
        page: 'DashboardPage',
    }),
    '/rfqs/:rfqId/order': defineRoute({
        path: { rfqId: 'required' },
        query: {},
        page: 'OrderPage',
    }),
    '/rfqs/:rfqId/order/confirmation': defineRoute({
        path: { rfqId: 'required' },
        query: {
            // Parameters provided by Stripe
            /* eslint-disable camelcase */
            payment_intent_client_secret: 'optional',
            payment_intent_id: 'optional',
            redirect_status: 'optional',
            /* eslint-enable camelcase */
            // Parameters provided by LumiQuote
            orderSelectionOptionId: 'required',
        },
        page: 'OrderConfirmationPage',
    }),

    //TODO: this should be removed after 30/11/2024 (long enough that any links to this should be removed)
    '/rfqs/:rfqId/v2quotation': defineRoute({
        path: { rfqId: 'required' },
        query: {},
        page: 'QuotationsPageV2',
    }),
    //TODO: this should be removed after 30/11/2024 (long enough that any links to this should be removed)
    '/rfqs/:rfqId/v2quotation/:assemblyId/calculation': defineRoute({
        path: { assemblyId: 'required', rfqId: 'required' },
        query: {},
        page: 'AssemblyCalculationPage',
    }),

    '/rfqs/:rfqId/calculation': defineRoute({
        path: { rfqId: 'required' },
        query: {},
        page: 'CalculationPage',
    }),
    '/rfqs/:rfqId/calculation/:assemblyId/calculation': defineRoute({
        path: { assemblyId: 'required', rfqId: 'required' },
        query: {},
        page: 'AssemblyCalculationPage',
    }),
    '/rfqs/:rfqId/quotation': defineRoute({
        path: { rfqId: 'required' },
        query: {},
        page: 'QuotationsPage',
    }),

    '/rfqs/:rfqId/sourcing': defineRoute({
        path: { rfqId: 'required' },
        query: { sourcingScenarioId: 'optional' },
        page: 'SourcingPage',
    }),

    '/rfqs/:rfqId/sourcing/quote-tracking': defineRoute({
        path: { rfqId: 'required' },
        query: { quoteTrackingId: 'required' },
        page: 'QuoteTrackingPage',
    }),

    '/rfqs/:rfqId/sourcing/quote-importer': defineRoute({
        path: { rfqId: 'required' },
        query: {},
        page: 'ExcelQuoteImporterPage',
    }),
    '/rfqs/:rfqId/sourcing/assembly/:assemblyId': defineRoute({
        path: {
            rfqId: 'required',
            assemblyId: 'required',
        },
        query: {},
        page: 'AssemblySourcingPage',
    }),

    '/quote-portal': defineRoute({ path: {}, query: {} }),

    '/quote-portal/rfq/:rfqId': defineRoute({
        path: { rfqId: 'required' },
        query: {},
    }),
    '/quote-portal/quote-tracking/:quoteTrackingId': defineRoute({
        path: { quoteTrackingId: 'required' },
        query: {},
    }),

    '/rfqs/:rfqId/sourcing/scenarios/new': defineRoute({
        path: { rfqId: 'required' },
        query: {},
        page: 'AddSourcingScenarioPage',
    }),

    '/rfqs/:rfqId/sourcing/scenarios/:sourcingScenarioId': defineRoute({
        path: { rfqId: 'required', sourcingScenarioId: 'required' },
        query: {},
        page: 'SolutionConfigurationPage',
    }),

    '/rfqs/:rfqId/sourcing/scenarios/:sourcingScenarioId/purchase-orders': defineRoute({
        path: { rfqId: 'required', sourcingScenarioId: 'required' },
        query: {},
    }),

    '/rfqs/:rfqId/sourcing/scenarios/:sourcingScenarioId/purchase-orders/:purchaseOrderId': defineRoute({
        path: {
            rfqId: 'required',
            sourcingScenarioId: 'required',
            purchaseOrderId: 'required',
        },
        query: {},
    }),

    '/rfqs/:rfqId/sourcing/scenarios/:sourcingScenarioId/edit': defineRoute({
        path: { rfqId: 'required', sourcingScenarioId: 'required' },
        query: {},
        page: 'EditSourcingScenarioPage',
    }),

    '/rfqs/:rfqId/sourcing/:solutionConfigurationId': defineRoute({
        path: { rfqId: 'required', solutionConfigurationId: 'required' },
        query: {},
        page: 'SolutionManagerPage',
    }),

    '/rfqs/:rfqId/sourcing/offer/custom-part/add/:partId': defineRoute({
        path: { rfqId: 'required', partId: 'required' },
        query: {},
        page: 'AddCustomPartOfferPage',
    }),
    '/rfqs/:rfqId/sourcing/offer/custom-part/:offerId/edit': defineRoute({
        path: { rfqId: 'required', offerId: 'required' },
        query: {},
        page: 'EditCustomPartOfferPage',
    }),

    '/rfqs/:rfqId/sourcing/offer/custom-component/add/:partId': defineRoute({
        path: { rfqId: 'required', partId: 'required' },
        query: { solutionConfigurationId: 'required' },
        page: 'AddCustomComponentOfferPage',
    }),

    '/rfqs/:rfqId/sourcing/offer/custom-component/:offerId/edit': defineRoute({
        path: { rfqId: 'required', offerId: 'required' },
        query: {},
        page: 'EditCustomComponentOfferPage',
    }),

    '/rfqs/:rfqId/sourcing/offer/off-the-shelf/add/:partId': defineRoute({
        path: { rfqId: 'required', partId: 'required' },
        query: { solutionConfigurationId: 'optional' },
        page: 'AddOffTheShelfOfferPage',
    }),

    '/rfqs/:rfqId/sourcing/offer/off-the-shelf/:offerId/edit': defineRoute({
        path: { rfqId: 'required', offerId: 'required' },
        query: {},
        page: 'EditOffTheShelfOfferPage',
    }),

    '/rfqs/:rfqId/sourcing/offer/off-the-shelf/:offerId/duplicate': defineRoute({
        path: { rfqId: 'required', offerId: 'required' },
        query: {},
        page: 'DuplicateOffTheShelfOfferPage',
    }),

    '/rfqs/:rfqId/sourcing/offer/internal-part-number/add/:partId': defineRoute({
        path: { rfqId: 'required', partId: 'required' },
        query: { solutionConfigurationId: 'required' },
        page: 'AddInternalPartNumberOfferPage',
    }),

    '/rfqs/:rfqId/sourcing/offer/internal-part-number/:offerId/edit': defineRoute({
        path: { rfqId: 'required', offerId: 'required' },
        query: {},
        page: 'EditInternalPartNumberOfferPage',
    }),

    '/rfqs/:rfqId/sourcing/offer/internal-part-number/:offerId/duplicate': defineRoute({
        path: { rfqId: 'required', offerId: 'required' },
        query: {},
        page: 'DuplicateInternalPartNumberOfferPage',
    }),

    '/purchase-orders': defineRoute({
        path: {},
        query: {},
    }),

    '/purchase-orders/:id': defineRoute({
        path: {
            id: 'required',
        },
        query: {},
    }),

    '/settings/organization': defineRoute({
        path: {},
        query: {},
        page: 'OrganizationSettingsPage',
    }),

    '/settings/organization/customer-portal': defineRoute({
        path: {},
        query: {},
    }),

    '/settings/organization/customer-portal/additional-services': defineRoute({
        path: {},
        query: {},
    }),

    '/settings/organization/customer-portal/payment-credentials': defineRoute({
        path: {},
        query: {},
    }),

    '/settings/organization/currency': defineRoute({
        path: {},
        query: {},
    }),

    '/settings/organization/sites': defineRoute({
        path: {},
        query: {},
    }),
    '/settings/organization/supply-chain-collaboration': defineRoute({
        path: {},
        query: {},
    }),
    '/settings/organization/users': defineRoute({
        path: {},
        query: {},
    }),
    '/settings/organization/users/importer': defineRoute({
        path: {},
        query: {},
    }),
    '/settings/organization/export-templates': defineRoute({
        path: {},
        query: {},
    }),
    '/settings/organization/manufacturer-preferences': defineRoute({
        path: {},
        query: {},
    }),
    '/settings/organization/scrap-calculation': defineRoute({
        path: {},
        query: {},
    }),

    '/settings/organization/scrap-calculation/formula-builder/default-formula/edit': defineRoute({
        path: {},
        query: {},
    }),

    '/settings/organization/scrap-calculation/formula-builder/formula/:formulaIndex/edit': defineRoute({
        path: { formulaIndex: 'required' },
        query: {},
    }),

    '/settings/organization/supplier-api-integrations': defineRoute({
        path: {},
        query: {},
    }),

    '/settings/organization/supplier-portal': defineRoute({
        path: {},
        query: {},
    }),

    '/settings/organization/assembly-settings': defineRoute({
        path: {},
        query: {},
    }),

    '/settings/organization/pcb-panel-preferences': defineRoute({
        path: {},
        query: {},
    }),

    '/settings/organization/carbon-footprints': defineRoute({
        path: {},
        query: {},
    }),

    '/settings/organization/pcb-panel-preferences/add-preference': defineRoute({
        path: {},
        query: {},
    }),

    '/settings/organization/pcb-panel-preferences/:panelPreferenceId': defineRoute({
        path: { panelPreferenceId: 'required' },
        query: {},
    }),

    '/settings/organization/api-settings': defineRoute({
        path: {},
        query: {},
    }),
    '/settings/organization/automatic-rfq-number': defineRoute({
        path: {},
        query: {},
    }),

    '/settings/user/account-security': defineRoute({
        path: {},
        query: {},
    }),

    '/settings/company': defineRoute({
        path: {},
        query: {},
        page: 'CompanySettingsPage',
    }),

    '/customers': defineRoute({
        path: {},
        query: {},
    }),

    '/customers/importer': defineRoute({
        path: {},
        query: {},
    }),

    '/customers/:customerId': defineRoute({
        path: { customerId: 'required' },
        query: {},
    }),

    '/supplier': defineRoute({
        path: {},
        query: {},
        page: 'SupplierManagementPage',
    }),

    '/supplier/importer': defineRoute({
        path: {},
        query: {},
    }),

    '/supplier/:supplierAndStockLocationId': defineRoute({
        path: { supplierAndStockLocationId: 'required' },
        query: { tab: 'optional' },
        page: 'SupplierDetailsPage',
    }),

    '/supplier/:supplierId/supplier-contacts-importer': defineRoute({
        path: { supplierId: 'required' },
        query: {},
    }),

    '/manufacturer': defineRoute({
        path: {},
        query: {},
        page: 'ManufacturerManagementPage',
    }),

    '/manufacturer/:manufacturerId': defineRoute({
        path: { manufacturerId: 'required' },
        query: {},
        page: 'ManufacturerDetailsPage',
    }),

    '/auth/signup/invite': defineRoute({
        path: {},
        query: { payload: 'optional' },
        page: 'SignUpPage',
    }),
    '/auth/signup/customer': defineRoute({
        path: {},
        query: { payload: 'optional' },
        page: 'CustomerSignUpPage',
    }),

    '/snapshots/:fromRfqId/:fromSnapshotId/:fromSourcingScenarioId/compare/:toRfqId/:toSnapshotId/:toSourcingScenarioId':
        defineRoute({
            path: {
                fromSnapshotId: 'required',
                toSnapshotId: 'required',
                fromSourcingScenarioId: 'required',
                fromRfqId: 'required',
                toRfqId: 'required',
                toSourcingScenarioId: 'required',
            },
            query: {},
        }),

    '/snapshots/:fromRfqId/:fromSnapshotId/:fromSourcingScenarioId/compare/:toRfqId/:toSnapshotId/:toSourcingScenarioId/total-cost':
        defineRoute({
            path: {
                fromSnapshotId: 'required',
                toSnapshotId: 'required',
                fromSourcingScenarioId: 'required',
                fromRfqId: 'required',
                toRfqId: 'required',
                toSourcingScenarioId: 'required',
            },
            query: {},
        }),

    '/snapshots/:fromRfqId/:fromSnapshotId/:fromSourcingScenarioId/compare/:toRfqId/:toSnapshotId/:toSourcingScenarioId/lead-time':
        defineRoute({
            path: {
                fromSnapshotId: 'required',
                toSnapshotId: 'required',
                fromSourcingScenarioId: 'required',
                fromRfqId: 'required',
                toRfqId: 'required',
                toSourcingScenarioId: 'required',
            },
            query: {},
        }),

    '/snapshots/:fromRfqId/:fromSnapshotId/:fromSourcingScenarioId/compare/:toRfqId/:toSnapshotId/:toSourcingScenarioId/order-size':
        defineRoute({
            path: {
                fromSnapshotId: 'required',
                toSnapshotId: 'required',
                fromSourcingScenarioId: 'required',
                fromRfqId: 'required',
                toRfqId: 'required',
                toSourcingScenarioId: 'required',
            },
            query: {},
        }),

    '/snapshots/:fromRfqId/:fromSnapshotId/:fromSourcingScenarioId/compare/:toRfqId/:toSnapshotId/:toSourcingScenarioId/suppliers':
        defineRoute({
            path: {
                fromSnapshotId: 'required',
                toSnapshotId: 'required',
                fromSourcingScenarioId: 'required',
                fromRfqId: 'required',
                toRfqId: 'required',
                toSourcingScenarioId: 'required',
            },
            query: {},
        }),

    '/snapshots/:fromRfqId/:fromSnapshotId/:fromSourcingScenarioId/compare/:toRfqId/:toSnapshotId/:toSourcingScenarioId/unit-price':
        defineRoute({
            path: {
                fromSnapshotId: 'required',
                toSnapshotId: 'required',
                fromSourcingScenarioId: 'required',
                fromRfqId: 'required',
                toRfqId: 'required',
                toSourcingScenarioId: 'required',
            },
            query: {},
        }),

    '/snapshots/:fromRfqId/:fromSnapshotId/:fromSourcingScenarioId/compare/:toRfqId/:toSnapshotId/:toSourcingScenarioId/excess-material':
        defineRoute({
            path: {
                fromSnapshotId: 'required',
                toSnapshotId: 'required',
                fromSourcingScenarioId: 'required',
                fromRfqId: 'required',
                toRfqId: 'required',
                toSourcingScenarioId: 'required',
            },
            query: {},
        }),

    '/snapshots/:fromRfqId/:fromSnapshotId/:fromSourcingScenarioId/compare/:toRfqId/:toSnapshotId/:toSourcingScenarioId/one-time-cost':
        defineRoute({
            path: {
                fromSnapshotId: 'required',
                toSnapshotId: 'required',
                fromSourcingScenarioId: 'required',
                fromRfqId: 'required',
                toRfqId: 'required',
                toSourcingScenarioId: 'required',
            },
            query: {},
        }),

    '/snapshots/:fromRfqId/:fromSnapshotId/:fromSourcingScenarioId/compare/:toRfqId/:toSnapshotId/:toSourcingScenarioId/scrap-cost':
        defineRoute({
            path: {
                fromSnapshotId: 'required',
                toSnapshotId: 'required',
                fromSourcingScenarioId: 'required',
                fromRfqId: 'required',
                toRfqId: 'required',
                toSourcingScenarioId: 'required',
            },
            query: {},
        }),

    '/snapshots/:fromRfqId/:fromSnapshotId/:fromSourcingScenarioId/compare/:toRfqId/:toSnapshotId/:toSourcingScenarioId/currencies':
        defineRoute({
            path: {
                fromSnapshotId: 'required',
                toSnapshotId: 'required',
                fromSourcingScenarioId: 'required',
                fromRfqId: 'required',
                toRfqId: 'required',
                toSourcingScenarioId: 'required',
            },
            query: {},
        }),

    '/snapshots/:fromRfqId/:fromSnapshotId/:fromSourcingScenarioId/compare/:toRfqId/:toSnapshotId/:toSourcingScenarioId/preferences':
        defineRoute({
            path: {
                fromSnapshotId: 'required',
                toSnapshotId: 'required',
                fromSourcingScenarioId: 'required',
                fromRfqId: 'required',
                toRfqId: 'required',
                toSourcingScenarioId: 'required',
            },
            query: {},
        }),

    ...partLibraryRoutes,
    ...manufacturingRoutes,
    ...rfqManufacturingRoutes,
    ...rfqCalculationRoutes,
    ...calculationRoutes,
    ...pnpImporterRoutes,
    ...assemblyRoutes,
} as const;

/**
 * Type describing the route config object in LumiQuote
 */
export type LumiQuoteRoutes = typeof routes;

/**
 * Type describing all routes in LumiQuote
 */
// eslint-disable-next-line import/no-unused-modules
export type LumiQuoteRoute = keyof LumiQuoteRoutes;

/**
 * Use this type along with useParams() to get the types params given a route:
 *
 * Example:
 *
 * ```ts
 * const { rfqId } = useParams<PageParams<"/rfqs/:rfqId">>()
 * ```
 * The compiler will know what params are available based on the route
 * passed to PageParams
 * ```
 */
// eslint-disable-next-line import/no-unused-modules
export type PageParams<T extends LumiQuoteRoute> = { [k in keyof LumiQuoteRoutes[T]['path']]: string };

// eslint-disable-next-line import/no-unused-modules
export type QueryParams<T extends LumiQuoteRoute> = {
    [k in keyof LumiQuoteRoutes[T]['query']]: LumiQuoteRoutes[T]['query'][k] extends 'required'
        ? string
        : string | null | undefined;
};

// eslint-disable-next-line import/no-unused-modules
export type PathParams<T extends LumiQuoteRoute> = {
    [k in keyof LumiQuoteRoutes[T]['path']]: Stringable;
};

export type UrlParams<T extends LumiQuoteRoute> = {
    pathParams: PageParams<T>;
    queryParams: QueryParams<T>;
};
