import { plural, Trans } from '@lingui/macro';
import { formatDecimal } from '@luminovo/commons';
import { Divider, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import React from 'react';
import { colorSystem } from '../../../theme';
import { Highlight } from '../../Highlight';
import { Text } from '../../Text';

type InteractiveMenuItem =
    | {
          type: 'item';
          id: string | number;
          label: string;
          icon?: JSX.Element;
          count?: number;
          onSelect: () => void;
          index: number;
      }
    | {
          type: 'query';
          id: string;
          label: JSX.Element;
          onSelect: () => void;
          index: number;
      }
    | { type: 'header'; onSelect: () => void }
    | { type: 'divider'; onSelect: () => void };

interface Item<TValue> {
    id: string | number;
    icon?: JSX.Element;
    value: TValue;
    label: string;
    count?: number;
}

interface Props<TValue> {
    items: Array<Item<TValue>>;
    query: string;
    onSearch: (query: string) => void;
    onSelect: (item: TValue, label?: JSX.Element | string) => void;
    title: string | JSX.Element;
    inputRef: React.RefObject<HTMLInputElement>;
    showIcons?: boolean;
    showSearch?: boolean;
}

export function InteractiveMenu<TValue>({
    items,
    query,
    onSearch,
    onSelect,
    title,
    inputRef,
    showIcons = true,
    showSearch = false,
}: Props<TValue>): JSX.Element {
    const filtered = buildItems({ items, query, onSelect, onSearch, showSearch });

    const selectableItems = filtered.filter((item) => item.type !== 'divider' && item.type !== 'header');

    const [selectedIndex, setSelectedIndex] = React.useState<number | undefined>(undefined);
    React.useEffect(() => {
        setSelectedIndex(undefined);
    }, [query]);

    React.useEffect(() => {
        const { current } = inputRef;
        if (!current) {
            return;
        }
        const onKeyUp = (e: KeyboardEvent) => {
            if (e.key === 'Tab' && selectableItems.length > 0) {
                e.preventDefault();
                selectableItems[0]?.onSelect();
            }
            if (e.key === 'ArrowUp') {
                setSelectedIndex((index) => nextValue(index, selectableItems.length, -1));
            }
            if (e.key === 'ArrowDown') {
                setSelectedIndex((index) => nextValue(index, selectableItems.length, +1));
            }
            if (e.key === 'Enter' && selectedIndex !== undefined) {
                const selected = selectableItems[selectedIndex];
                selected?.onSelect();
            }
        };

        const onKeyDown = (e: KeyboardEvent) => {
            if (e.key === 'Tab') {
                e.preventDefault();
                if (selectableItems.length > 0) {
                    setSelectedIndex(0);
                }
            }
        };

        current.addEventListener('keyup', onKeyUp);
        current.addEventListener('keydown', onKeyDown);

        return () => {
            current.removeEventListener('keyup', onKeyUp);
            current.removeEventListener('keydown', onKeyDown);
        };
    }, [inputRef, onSelect, setSelectedIndex, selectedIndex, selectableItems]);

    const maxResults = 500;
    const hiddenResults = Math.max(0, selectableItems.length - maxResults);

    return (
        <List
            style={{ maxHeight: '50vh', overflowY: 'auto', background: colorSystem.neutral.white }}
            disablePadding
            dense
        >
            {filtered.slice(0, maxResults).map((item, i) => {
                if (item.type === 'query') {
                    return (
                        <ListItemButton
                            key={item.id}
                            style={{
                                background: item.index === selectedIndex ? colorSystem.neutral[2] : undefined,
                            }}
                            onClick={() => item.onSelect()}
                        >
                            <ListItemText>
                                <Text variant="body-small" color={colorSystem.neutral[8]} style={{ flexGrow: 1 }}>
                                    {item.label}
                                </Text>
                            </ListItemText>
                        </ListItemButton>
                    );
                }

                if (item.type === 'divider') {
                    return <Divider key={i} style={{ margin: '4px 0px' }} />;
                }
                if (item.type === 'header') {
                    return (
                        <React.Fragment key={i}>
                            <ListItem style={{ background: colorSystem.neutral.white }}>
                                <ListItemText>
                                    <Text variant="h5" color={colorSystem.neutral[8]}>
                                        {title}
                                    </Text>
                                </ListItemText>
                            </ListItem>
                        </React.Fragment>
                    );
                }

                return (
                    <ListItemButton
                        key={item.id}
                        style={{
                            background: item.index === selectedIndex ? colorSystem.neutral[2] : undefined,
                        }}
                        disableGutters={showIcons}
                        onClick={() => item.onSelect()}
                    >
                        {showIcons && <ListItemIcon>{item.icon}</ListItemIcon>}
                        <ListItemText
                            primaryTypographyProps={{
                                style: { display: 'flex', gap: 8, paddingRight: 8, alignItems: 'baseline' },
                            }}
                        >
                            <Text variant="body-small" color={colorSystem.neutral[8]} style={{ flexGrow: 1 }}>
                                <Highlight label={item.label} matcher={[query]} overrides={{ Highlighter: Bold }} />
                            </Text>
                            {(item.count ?? 0) > 1 && (
                                <Text variant="body-small">{formatDecimal(item.count ?? 0)}</Text>
                            )}
                        </ListItemText>
                    </ListItemButton>
                );
            })}
            {hiddenResults > 0 && (
                <ListItem key="interactive-menu.hidden-results">
                    <ListItemText>
                        <Text variant="body-small" color={colorSystem.neutral[6]}>
                            {plural(hiddenResults, { one: `One more result`, other: `${hiddenResults} more results` })}
                            ...
                        </Text>
                    </ListItemText>
                </ListItem>
            )}
        </List>
    );
}

function Bold(props: React.PropsWithChildren<{}>) {
    return <b {...props} />;
}

function nextValue(current: number | undefined, total: number, direction: 1 | -1) {
    if (current === undefined) {
        return direction === 1 ? 0 : total - 1;
    }
    return Math.max(0, Math.min(current + direction, total - 1));
}

function groupByLabel<T extends { label: string; count?: number }>(items: T[]): Array<T & { count: number }> {
    const groups: { [label: string]: T[] } = {};
    for (const item of items) {
        if (!groups[item.label]) {
            groups[item.label] = [];
        }
        groups[item.label].push(item);
    }
    return Object.entries(groups).map(([_, group]) => {
        const count = group.map((item) => item.count ?? 1).reduce((a, b) => a + b, 0);
        return { count, ...group[0] };
    });
}

function buildItems<TValue>({
    items,
    query,
    onSelect,
    onSearch,
    showSearch = false,
}: Pick<Props<TValue>, 'items' | 'query' | 'onSelect' | 'onSearch' | 'showSearch'>): InteractiveMenuItem[] {
    const lowercasedQuery = query.toLowerCase();
    const filtered = groupByLabel(items.filter((item) => item.label.toLowerCase().includes(lowercasedQuery)));

    const innerItems: InteractiveMenuItem[] = [];

    // Add the search query item only if there is a query
    if (query.length > 0 && showSearch) {
        innerItems.push({
            index: 0,
            type: 'query',
            id: `interactive-menu.search-query`,
            onSelect: () => onSearch(query),
            label: (
                <Trans>
                    Search for "<b>{query}</b>"
                </Trans>
            ),
        });
    }

    if (innerItems.length > 0 && filtered.length > 0) {
        innerItems.push({ type: 'divider', onSelect: noop });
    }

    // Add the divider only if there is at least one item to show
    if (filtered.length > 0) {
        innerItems.push({ type: 'header', onSelect: noop });
    }

    const addedItems: InteractiveMenuItem[] = filtered.map((item, i): InteractiveMenuItem => {
        return {
            type: 'item',
            id: item.id,
            onSelect: () => {
                onSelect(item.value, item.label);
            },
            label: item.label,
            count: item.count,
            icon: item.icon,
            // The index is adjusted depending on whether there is a query or not
            index: i + innerItems.filter((x) => x.type !== 'divider' && x.type !== 'header').length,
        };
    });

    return innerItems.concat(addedItems);
}

const noop = () => {};
