import { Currency } from '@luminovo/commons';
import {
    Availability,
    BatchDTO,
    CostCellDTO,
    ExtraCostDTO,
    FormulaDTO,
    MonetaryValueBackend,
    ProjectEntityCostCellDTO,
    ScenarioCostDTO,
    UnitCostDTO,
} from '@luminovo/http-client';
import * as r from 'runtypes';
import { assertUnreachable } from '../../../../../utils/typingUtils';
import { mustBeAPositiveNumberErrorMessage } from '../../../../../utils/yupValidationUtils';
import {
    BaseCellProperties,
    CellBreakdown,
    CellRule,
    CostSpecification,
    DynamicCostCell,
    FixedCostCell,
    FixedPercentageCell,
} from '../../types/cellTypes';
import { CalculationDetails } from '../../types/formTypes';
import {
    AdditionalOtherCostsSection,
    AdditionalProfitAndDiscountSection,
    EntityWithNameAndId,
    ExtraCost,
    ManufacturingCostSection,
    MaterialCostSection,
    PostProfitCostsSection,
    ProjectCostsSection,
    SummarySection,
    TableColumn,
} from '../../types/tableColumnTypes';

const Positive = r.Number.withConstraint((n) => n >= 0);

const checkIfPositiveNumber: CellRule = (value: string) => {
    const r = Positive.guard(value === '' ? NaN : Number(value));
    if (r === false) {
        return {
            result: 'Error',
            message: mustBeAPositiveNumberErrorMessage(),
        };
    } else {
        return {
            result: 'Ok',
        };
    }
};

type BatchInformation = {
    orderSize: number;
    sourcingTotalAvailability: Availability | undefined;
    manufacturingLeadTime: number | undefined;
    sourcingCombinationId: string;
    preferredCurrency: Currency;
};

const createMockBlankValue = (preferredCurrency: Currency): MonetaryValueBackend => {
    return {
        currency: preferredCurrency,
        amount: '0',
    };
};

const convertToFixedCostCell = (
    unitCost: MonetaryValueBackend,
    totalCost: MonetaryValueBackend,
    baseCell: BaseCellProperties,
): FixedCostCell => {
    return {
        type: 'fixed',
        unitCost,
        totalCost,
        ...baseCell,
    };
};

const convertToBreakdownCell = (
    cost: { unit_cost: MonetaryValueBackend; total_cost: MonetaryValueBackend } | undefined,
    baseCell: BaseCellProperties,
): CellBreakdown => {
    return {
        type: 'breakdown',
        cost: cost
            ? {
                  unitCost: cost.unit_cost,
                  totalCost: cost.total_cost,
              }
            : undefined,
        ...baseCell,
    };
};

const convertToFixedPercentageCostCell = (
    unitCost: MonetaryValueBackend,
    totalCost: MonetaryValueBackend,
    denominatorValue: MonetaryValueBackend,
    baseCell: BaseCellProperties,
): FixedPercentageCell => {
    return {
        type: 'fixed-percentage',
        unitCost: unitCost,
        denominatorValue,
        totalCost,
        ...baseCell,
    };
};

const convertFixedFormula = (formulaData: FormulaDTO, preferredCurrency: Currency): CostSpecification => {
    if (formulaData.formula.data.calculated.type !== 'Fixed') {
        throw new Error(`Expected fixed formula, got ${formulaData.formula.data.calculated.type}`);
    }
    const formulaType = formulaData.formula.type;

    switch (formulaType) {
        case 'Overridden':
            return {
                type: 'formula-fixed',
                script: formulaData.formula.data.calculated.data.script,
                result: formulaData.result.result,
                currency: preferredCurrency,
                isOverwritten: true,
                value: formulaData.formula.data.manually_overridden,
                statuses: formulaData.statuses,
            };
        case 'Evaluated':
            return {
                type: 'formula-fixed',
                script: formulaData.formula.data.calculated.data.script,
                result: formulaData.result.result,
                currency: preferredCurrency,
                isOverwritten: false,
                value: formulaData.result.result === 'Ok' ? formulaData.result.data : '0',
                statuses: formulaData.statuses,
            };
        default:
            assertUnreachable(formulaType);
    }
};

const convertFractionFormula = (formulaData: FormulaDTO): CostSpecification => {
    if (formulaData.formula.data.calculated.type !== 'Fraction') {
        throw new Error(`Expected fixed formula, got ${formulaData.formula.data.calculated.type}`);
    }
    const formulaType = formulaData.formula.type;
    switch (formulaType) {
        case 'Overridden':
            return {
                type: 'formula-fraction',
                script: formulaData.formula.data.calculated.data.script,
                result: formulaData.result.result,
                isOverwritten: true,
                value: formulaData.formula.data.manually_overridden,
                statuses: formulaData.statuses,
            };
        case 'Evaluated':
            return {
                type: 'formula-fraction',
                script: formulaData.formula.data.calculated.data.script,
                result: formulaData.result.result,
                isOverwritten: false,
                value: formulaData.result.result === 'Ok' ? formulaData.result.data : '0',
                statuses: formulaData.statuses,
            };
        default:
            assertUnreachable(formulaType);
    }
};

const convertFormula = (formulaData: FormulaDTO, preferredCurrency: Currency): CostSpecification => {
    const calculatedDataType = formulaData.formula.data.calculated.type;
    switch (calculatedDataType) {
        case 'Fixed':
            return convertFixedFormula(formulaData, preferredCurrency);
        case 'Fraction':
            return convertFractionFormula(formulaData);
        default:
            assertUnreachable(calculatedDataType);
    }
};

const convertBackendCostSpecificationToFrontendCostSpecification = (
    costSpecification: UnitCostDTO | undefined,
    preferredCurrency: Currency,
): CostSpecification => {
    if (costSpecification === undefined) {
        return {
            type: 'fixed',
            value: createMockBlankValue(preferredCurrency),
        };
    }
    const type = costSpecification.type;
    switch (type) {
        case 'Fixed':
            return {
                type: 'fixed',
                value: costSpecification.data,
            };
        case 'Fraction':
            return {
                type: 'fraction',
                value: costSpecification.data,
            };
        case 'Formula':
            return convertFormula(costSpecification.data, preferredCurrency);
        default:
            assertUnreachable(type);
    }
};

const parseCostFraction = (str: string | undefined): string => {
    if (str === undefined) {
        return '0';
    }
    return str;
};

export const convertDynamicCell = (
    backendCostCell: CostCellDTO | null | undefined,
    baseCell: BaseCellProperties,
    rule?: CellRule,
): DynamicCostCell => {
    return {
        type: 'dynamic',
        unitCostValue: backendCostCell?.unit_cost_value ?? createMockBlankValue(baseCell.preferredCurrency),
        costFraction: parseCostFraction(backendCostCell?.unit_cost_fraction),
        costSpecification: convertBackendCostSpecificationToFrontendCostSpecification(
            backendCostCell?.cost_specification,
            baseCell.preferredCurrency,
        ),
        totalCostValue: backendCostCell?.total_cost_value ?? createMockBlankValue(baseCell.preferredCurrency),
        rule: rule !== undefined ? rule : checkIfPositiveNumber,
        isLocked: backendCostCell?.is_locked ?? false,
        ...baseCell,
    };
};

const convertCostCellDTOToDynamicCost = (
    backendCostCell: CostCellDTO | null | undefined,
    baseCell: BaseCellProperties,
    isCalculationTemplateApplied: boolean,
    rule?: CellRule,
): DynamicCostCell => {
    return backendCostCell === null && isCalculationTemplateApplied
        ? {
              ...convertDynamicCell(backendCostCell, baseCell, rule),
              shouldHideCell: true,
          }
        : convertDynamicCell(backendCostCell, baseCell, rule);
};

const convertExtraCosts = (extraCosts: ExtraCostDTO[], baseCell: BaseCellProperties): ExtraCost[] => {
    return extraCosts.map((extraCost) => {
        return {
            type: 'extra',
            name: extraCost.name,
            cost: convertDynamicCell(extraCost.cost, baseCell),
        };
    });
};

const getMaterialCostSection = (
    body: BatchDTO,
    baseCell: BaseCellProperties,
    isCalculationTemplateApplied: boolean,
): MaterialCostSection => {
    return {
        type: 'material-cost',
        cost: convertToFixedCostCell(
            body.cost.material_cost.cost.unit_cost,
            body.cost.material_cost.cost.total_cost,
            baseCell,
        ),

        price: convertToFixedCostCell(
            body.cost.material_cost.price.unit_cost,
            body.cost.material_cost.price.total_cost,
            baseCell,
        ),
        customCosts: {
            type: 'custom',
            discount: convertCostCellDTOToDynamicCost(
                body.cost.material_cost.custom_cost.discount,
                baseCell,
                isCalculationTemplateApplied,
            ),
            profit: convertCostCellDTOToDynamicCost(
                body.cost.material_cost.custom_cost.profit,
                baseCell,
                isCalculationTemplateApplied,
            ),
            extraCosts: convertExtraCosts(body.cost.material_cost.custom_cost.extra_costs, baseCell),
        },
        subTotalCost: convertToFixedCostCell(
            body.cost.material_cost.sub_total_cost.unit_cost,
            body.cost.material_cost.sub_total_cost.total_cost,
            baseCell,
        ),
    };
};

const getManufacturingCostSection = (
    body: BatchDTO,
    baseCell: BaseCellProperties,
    isCalculationTemplateApplied: boolean,
): ManufacturingCostSection => {
    return {
        type: 'manufacturing-cost',
        cost: convertToFixedCostCell(
            body.cost.manufacturing_cost.cost.unit_cost,
            body.cost.manufacturing_cost.cost.total_cost,
            baseCell,
        ),
        subTotalCost: convertToFixedCostCell(
            body.cost.manufacturing_cost.sub_total_cost.unit_cost,
            body.cost.manufacturing_cost.sub_total_cost.total_cost,
            baseCell,
        ),
        price: convertToFixedCostCell(
            body.cost.manufacturing_cost.price.unit_cost,
            body.cost.manufacturing_cost.price.total_cost,
            baseCell,
        ),
        customCosts: {
            type: 'custom',
            discount: convertCostCellDTOToDynamicCost(
                body.cost.manufacturing_cost.custom_cost.discount,
                baseCell,
                isCalculationTemplateApplied,
            ),
            profit: convertCostCellDTOToDynamicCost(
                body.cost.manufacturing_cost.custom_cost.profit,
                baseCell,
                isCalculationTemplateApplied,
            ),
            extraCosts: convertExtraCosts(body.cost.manufacturing_cost.custom_cost.extra_costs, baseCell),
        },
    };
};

const getAdditionalOtherCostsSection = (body: BatchDTO, baseCell: BaseCellProperties): AdditionalOtherCostsSection => {
    return {
        type: 'additional-other-costs',
        value: convertExtraCosts(body.cost.additional_cost.other_costs, baseCell),
    };
};

const getAdditionalProfitAndDiscountSection = (
    body: BatchDTO,
    baseCell: BaseCellProperties,
    isCalculationTemplateApplied: boolean,
): AdditionalProfitAndDiscountSection => {
    return {
        type: 'additional-profit',
        profit: convertCostCellDTOToDynamicCost(
            body.cost.additional_cost.profit,
            baseCell,
            isCalculationTemplateApplied,
        ),
        discount: convertCostCellDTOToDynamicCost(
            body.cost.additional_cost.discount,
            baseCell,
            isCalculationTemplateApplied,
        ),
    };
};

const getAdditionalPostProfitSection = (body: BatchDTO, baseCell: BaseCellProperties): PostProfitCostsSection => {
    return {
        type: 'additional-post-profit',
        value: convertExtraCosts(body.cost.additional_cost.post_profit_costs, baseCell),
    };
};

const getProjectCostsSectionSection = (
    body: BatchDTO,
    baseCell: BaseCellProperties,
    isCalculationTemplateApplied: boolean,
): ProjectCostsSection => {
    const projectCost = body.cost.project_cost;

    const convertToEntityWithNameAndId = (costCells: ProjectEntityCostCellDTO[]): EntityWithNameAndId[] => {
        return costCells
            .sort((a, b) => a.rank - b.rank)
            .map((entityCost) => ({
                id: entityCost.id,
                name: entityCost.name,
            }));
    };

    const convertToBreakdownCells = (costCells: ProjectEntityCostCellDTO[]): Record<string, CellBreakdown> => {
        const acc: Record<string, CellBreakdown> = {};
        return costCells.reduce((acc, { id, cost }) => {
            acc[id] = convertToBreakdownCell(cost, baseCell);
            return acc;
        }, acc);
    };

    return {
        type: 'project-cost',
        cost: {
            cost: convertToFixedCostCell(projectCost.cost.unit_cost, projectCost.cost.total_cost, baseCell),
            activities: convertToEntityWithNameAndId(projectCost.activity_costs),
            expenses: convertToEntityWithNameAndId(projectCost.expense_costs),
            activityCosts: convertToBreakdownCells(projectCost.activity_costs),
            expenseCosts: convertToBreakdownCells(projectCost.expense_costs),
        },
        price: convertToFixedCostCell(projectCost.price.unit_cost, projectCost.price.total_cost, baseCell),
        profit: convertCostCellDTOToDynamicCost(body.cost.project_cost.profit, baseCell, isCalculationTemplateApplied),
    };
};

const getProjectSummaryCosts = (body: BatchDTO, baseCell: BaseCellProperties): SummarySection => {
    return {
        type: 'summary',
        totalCost: convertToFixedCostCell(
            body.cost.summarized_cost.total_cost.unit_cost,
            body.cost.summarized_cost.total_cost.total_cost,
            baseCell,
        ),
        totalProfit: convertToFixedPercentageCostCell(
            body.cost.summarized_cost.total_profit.unit_cost,
            body.cost.summarized_cost.total_profit.total_cost,
            body.cost.summarized_cost.total_cost.unit_cost,
            baseCell,
        ),
        price: convertToFixedCostCell(
            body.cost.summarized_cost.total_price.unit_cost,
            body.cost.summarized_cost.total_price.total_cost,
            baseCell,
        ),
    };
};

const convertBatchesToColumn = (
    body: BatchDTO,
    batchInformation: BatchInformation,
    calculationDetails: CalculationDetails,
): TableColumn => {
    const { orderSize, sourcingTotalAvailability } = batchInformation;
    const { isCalculationTemplateApplied } = calculationDetails;
    const baseCell: BaseCellProperties = {
        orderSize: batchInformation.orderSize,
        batchSize: body.batch_size,
        sourcingTotalAvailability: batchInformation.sourcingTotalAvailability,
        sourcingCombinationId: batchInformation.sourcingCombinationId,
        preferredCurrency: batchInformation.preferredCurrency,
        manufacturingLeadTime: batchInformation.manufacturingLeadTime,
    };
    return {
        orderSize: orderSize,
        batchSize: body.batch_size,
        manufacturingLeadTime: baseCell.manufacturingLeadTime,
        leadTime: sourcingTotalAvailability,
        sourcingCombinationId: batchInformation.sourcingCombinationId,
        sections: [
            getMaterialCostSection(body, baseCell, isCalculationTemplateApplied),
            getManufacturingCostSection(body, baseCell, isCalculationTemplateApplied),
            getAdditionalOtherCostsSection(body, baseCell),
            getAdditionalProfitAndDiscountSection(body, baseCell, isCalculationTemplateApplied),
            getAdditionalPostProfitSection(body, baseCell),
            getProjectCostsSectionSection(body, baseCell, isCalculationTemplateApplied),
            getProjectSummaryCosts(body, baseCell),
        ],
    };
};

export const convertScenarioCostsToColumns = (
    body: ScenarioCostDTO,
    preferredCurrency: Currency,
    calculationDetails: CalculationDetails,
): TableColumn[] => {
    return body.batch_size_costs.map((batch) => {
        return convertBatchesToColumn(
            batch,
            {
                orderSize: body.order_size,
                sourcingTotalAvailability: body.sourcing_total_availability ?? undefined,
                sourcingCombinationId: body.sourcing_combination_id,
                manufacturingLeadTime: body.manufacturing_lead_time ?? undefined,
                preferredCurrency,
            },
            calculationDetails,
        );
    });
};
