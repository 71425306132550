import { Parser } from '../parsers/Parser';
import { string } from '../parsers/string';

interface ManufacturerNameProps {
    manufacturers: Array<{ name: string }>;
}

export function manufacturerName({ manufacturers }: ManufacturerNameProps): Parser<string> {
    const nameSet = new Set(manufacturers.map((manufacturer) => manufacturer.name.toLowerCase()));
    return string({ minLength: 1, maxLength: 100 }).warnIf((name) => {
        if (!nameSet.has(name.toLowerCase())) {
            return `Unknown manufacturer name`;
        }
    });
}
