import { Trans } from '@lingui/macro';
import { CenteredLayout, colorSystem, Flexbox, MaxWidthLayout, SecondaryButton, Text } from '@luminovo/design-system';
import { OrderSelectionOptionDTO, RfqStatus } from '@luminovo/http-client';
import { ArrowBack } from '@mui/icons-material';
import { Box, CircularProgress } from '@mui/material';
import { useHistory } from 'react-router';
import { useCurrentUserDetailsContext } from '../../components/contexts/CurrentUserDetailsContext';
import { FormContainer } from '../../components/formLayouts/FormContainer';
import { PageLayout } from '../../components/PageLayout';
import { SpinnerWithBackdrop } from '../../components/Spinners';
import { useCustomerPortalState, useRfQ } from '../../resources/rfq/rfqHandler';
import { assertPresent } from '../../utils/assertPresent';
import { route, UrlParams } from '../../utils/routes';
import { OrderExpirationDialog } from './components/OrderExpirationDialog';
import { OrderOverviewCard } from './components/OrderOverviewCard';
import { OrderSelectionCard } from './components/OrderSelectionCard';
import { ShippingAndPaymentCard } from './components/ShippingAndPaymentCard';
import { ToolbarRfqOrder } from './ToolbarRfqOrder';
import { OrderFormState, OrderSelectionOptionWithId } from './types';
import { useOrderSelectionOptionsOfRfq } from './utils/useOrderSelectionOptionsOfRfq';

interface RfqOrderProps extends UrlParams<'/rfqs/:rfqId/order'> {}

const parseOrders = (orderDTOs: Record<string, OrderSelectionOptionDTO>): OrderSelectionOptionWithId[] => {
    return Object.entries(orderDTOs).map(([id, orderSelectionOption]) => {
        return {
            ...orderSelectionOption,
            id,
        };
    });
};

const OrderForm = ({
    orders,
    validUntil,
    rfqId,
    rfqName,
}: {
    orders: OrderSelectionOptionWithId[];
    validUntil: string;
    rfqId: string;
    rfqName: string;
}): JSX.Element => {
    const { push } = useHistory();
    const backToDashboard = () => push(route('/rfqs/:rfqId/dashboard', { rfqId }));
    const firstOrder = assertPresent(orders[0]);
    const { organization } = useCurrentUserDetailsContext();

    const defaultOrderForm: OrderFormState = {
        selectedOrderOptionId: firstOrder.id,
        areTermsChecked: false,
        areSpecsChecked: false,
        paymentMode: 'ChargeImmediately',
    };

    return (
        // "onSubmit" is handled in OrderButton.tsx
        <FormContainer onSubmit={() => {}} defaultValues={defaultOrderForm}>
            <Box
                sx={{
                    display: 'grid',
                    gridTemplateRows: 'auto auto',
                    gridTemplateColumns: '1fr 800px 512px 1fr',
                    gridTemplateAreas: `
                        "back title title ."
                        ". main order-overview ."
                    `,
                    gridColumnGap: '40px',
                    gridRowGap: '32px',
                    width: 'inherit',
                }}
            >
                <Box sx={{ gridArea: 'back', display: 'flex', justifyContent: 'end' }}>
                    <SecondaryButton size="medium" startIcon={<ArrowBack />} onClick={backToDashboard}>
                        <Trans>Back</Trans>
                    </SecondaryButton>
                </Box>
                <Flexbox style={{ gridArea: 'title' }} flexDirection="column" gap="2px">
                    <Text variant="h1" color={colorSystem.neutral[9]}>
                        {rfqName}
                    </Text>
                    <Text variant="body-small" color={colorSystem.neutral[7]}>
                        <Trans>Manufactured by</Trans> {organization.name}
                    </Text>
                </Flexbox>
                <Flexbox style={{ gridArea: 'main', flexDirection: 'column', gap: '20px' }}>
                    <OrderSelectionCard orders={orders} validUntil={validUntil} rfqId={rfqId} />
                    <ShippingAndPaymentCard rfqId={rfqId} />
                </Flexbox>
                <Box sx={{ gridArea: 'order-overview' }}>
                    <OrderOverviewCard orders={orders} rfqId={rfqId} />
                </Box>
            </Box>
        </FormContainer>
    );
};

export const OrderPage = (props: RfqOrderProps): JSX.Element => {
    const { rfqId } = props.pathParams;
    const { data: rfq, isLoading } = useRfQ(rfqId);
    const { data: customerPortalState, isLoading: isRfqStateLoading } = useCustomerPortalState(rfqId);
    const { orderSelectionOptions, isExpired, isFetching } = useOrderSelectionOptionsOfRfq(rfqId);

    if (isLoading || isRfqStateLoading || customerPortalState === undefined || orderSelectionOptions === undefined) {
        return (
            <CenteredLayout>
                <SpinnerWithBackdrop noBackdrop={true} />
            </CenteredLayout>
        );
    }

    if (rfq?.status !== RfqStatus.QuotationAvailable || customerPortalState.sourcing_state.status.type !== 'Done') {
        return (
            <PageLayout header={<ToolbarRfqOrder rfqId={rfqId} />} style={{ backgroundColor: colorSystem.neutral[1] }}>
                <MaxWidthLayout>
                    <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        justifyContent="center"
                        height={'calc(100vh + -200px)'}
                    >
                        <Box
                            display="flex"
                            flexDirection="column"
                            maxWidth={'320px'}
                            alignItems="center"
                            justifyContent="center"
                        >
                            <Text>
                                <Trans>It is not possible to place an order.</Trans>
                            </Text>
                        </Box>
                    </Box>
                </MaxWidthLayout>
            </PageLayout>
        );
    }

    const orders: OrderSelectionOptionWithId[] = parseOrders(orderSelectionOptions.data).sort(
        (orderA, orderB) => orderA.order_size - orderB.order_size,
    );

    return (
        <PageLayout
            header={<ToolbarRfqOrder rfqId={rfqId} />}
            layout="fragment"
            style={{ backgroundColor: colorSystem.neutral[1], paddingTop: '40px' }}
        >
            {isFetching ? (
                <CenteredLayout>
                    <CircularProgress />
                </CenteredLayout>
            ) : (
                <OrderForm
                    orders={orders}
                    validUntil={orderSelectionOptions.valid_until}
                    rfqId={rfqId}
                    rfqName={rfq.name}
                />
            )}
            <OrderExpirationDialog rfqId={rfqId} isOpen={isExpired} />
        </PageLayout>
    );
};
