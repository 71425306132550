import { Trans, t } from '@lingui/macro';
import { assertUnreachable, isPresent, transEnum } from '@luminovo/commons';
import {
    FieldController,
    FieldToggleButtonControlled,
    Flexbox,
    FormItem,
    SecondaryButton,
    SecondaryIconButton,
} from '@luminovo/design-system';
import { AssemblyTypePublic } from '@luminovo/http-client';
import { FieldIndustrySelect } from '@luminovo/manufacturing-core';
import { Add, Delete } from '@mui/icons-material';
import { Control, Path, useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import { TextFieldController } from '../../../../components/formLayouts/reactHookFormComponents/reactHookFormComponents';
import { assemblyTypePublicTranslations } from '../../../../resources/rfq/i18n';
import { useAssemblyTypeOptions } from '../../../AssemblyForm/utils/useAssemblyTypeOptions';
import {
    RfqCreationFormState,
    defaultAssembly,
    defaultPrototypeDemand,
    defaultSeriesDemand,
    useRfqCreationContext,
} from '../../RfqCreationContext';
import { NavigationButtons } from './NavigationButtons';

const useUpdateDemandScenarios = () => {
    const { control, setValue } = useFormContext<RfqCreationFormState>();
    const demandScenarios = useWatch({ control, name: 'demand_scenarios' });

    const addDemands = () => {
        demandScenarios.forEach((scenario, index) => {
            if (scenario.type === 'Prototype') {
                setValue(`demand_scenarios.${index}.demands`, [...scenario.demands, defaultPrototypeDemand]);
            } else if (scenario.type === 'Series') {
                setValue(`demand_scenarios.${index}.demands`, [...scenario.demands, defaultSeriesDemand]);
            } else {
                assertUnreachable(scenario);
            }
        });
    };

    const removeDemands = (removeIndex: number) => {
        demandScenarios.forEach((scenario, index) => {
            if (scenario.type === 'Prototype') {
                setValue(`demand_scenarios.${index}.demands`, [
                    ...scenario.demands.slice(0, removeIndex),
                    ...scenario.demands.slice(removeIndex + 1),
                ]);
            } else if (scenario.type === 'Series') {
                setValue(`demand_scenarios.${index}.demands`, [
                    ...scenario.demands.slice(0, removeIndex),
                    ...scenario.demands.slice(removeIndex + 1),
                ]);
            } else {
                assertUnreachable(scenario);
            }
        });
    };

    return { addDemands, removeDemands };
};

export const AssemblyForm = (): JSX.Element => {
    const {
        control,
        trigger,
        formState: { errors },
        setError,
        getValues,
    } = useFormContext<RfqCreationFormState>();
    const { setActiveStep } = useRfqCreationContext();

    const assembliesFieldName = `assembly.assemblies` as const;
    const { fields, append, remove } = useFieldArray({ control, name: assembliesFieldName });
    const assemblies = getValues(assembliesFieldName);

    const validateAssemblyNames = (): boolean => {
        const assemblyNameSet = new Set();
        for (let i = 0; i < assemblies.length; i++) {
            const name = assemblies[i].name?.trim();
            if (!isPresent(name)) {
                continue;
            }
            if (assemblyNameSet.has(name)) {
                setError(`${assembliesFieldName}.${i}.name`, { type: 'custom', message: t`Duplicate assembly name` });
                return false;
            }
            assemblyNameSet.add(name);
        }

        return true;
    };

    const onNext = async () => {
        if (!(await trigger('assembly')) || !validateAssemblyNames()) {
            return;
        }

        setActiveStep('demand');
    };

    const onPrevious = () => {
        setActiveStep('customer');
    };

    const { addDemands, removeDemands } = useUpdateDemandScenarios();

    return (
        <>
            <FormItem label={t`Industry`} required>
                <FieldController
                    name="assembly.industry"
                    control={control}
                    Field={FieldIndustrySelect}
                    validate={(x: unknown) => (Boolean(x) ? undefined : t`Required`)}
                />
            </FormItem>

            {fields.map((assemblyField, index) => (
                <Flexbox key={assemblyField.id} gap={16} alignItems="start">
                    <FormItem label={t`Assembly name`} required style={{ width: '100%' }}>
                        <TextFieldController
                            name={`${assembliesFieldName}.${index}.name`}
                            control={control}
                            rules={{
                                required: t`Required`,
                                validate: validateAssemblyName,
                            }}
                            TextFieldProps={{
                                error: errors.assembly?.assemblies?.[index]?.name !== undefined,
                                helperText: errors.assembly?.assemblies?.[index]?.name?.message,
                                placeholder: t`Enter assembly name`,
                            }}
                        />
                    </FormItem>

                    <Flexbox gap={16} alignItems="end" style={{ flexShrink: 0 }}>
                        <FormItemAssemblyType name={`${assembliesFieldName}.${index}.assemblyType`} control={control} />

                        {fields.length > 1 && (
                            <SecondaryIconButton
                                size="large"
                                onClick={() => {
                                    remove(index);
                                    removeDemands(index);
                                }}
                            >
                                <Delete fontSize="inherit" />
                            </SecondaryIconButton>
                        )}
                    </Flexbox>
                </Flexbox>
            ))}

            <Flexbox gap={16}>
                <SecondaryButton
                    onClick={() => {
                        append(defaultAssembly);
                        addDemands();
                    }}
                    startIcon={<Add />}
                >
                    <Trans>Add top-level assembly</Trans>
                </SecondaryButton>
            </Flexbox>

            <NavigationButtons
                nextLabel={t`Next`}
                showPrevious
                onNext={onNext}
                onPrevious={onPrevious}
                isDisabled={Object.keys(errors.assembly ?? {}).length > 0}
            />
        </>
    );
};

const validateAssemblyName = (assemblyName: unknown): string | undefined => {
    if (!assemblyName) {
        return t`Required`;
    }

    if (typeof assemblyName !== 'string') {
        return t`Must be a string`;
    }

    if (assemblyName.length < 2) {
        return t`Must have at least 2 characters`;
    }

    if (assemblyName.length > 50) {
        return t`Must have at most 50 characters`;
    }
};

const FormItemAssemblyType = ({
    name,
    control,
}: {
    name: Path<RfqCreationFormState>;
    control: Control<RfqCreationFormState>;
}) => {
    const { data: options, isLoading } = useAssemblyTypeOptions();

    const assemblyType = useWatch({ control, name });

    if (isLoading || !isPresent(options)) {
        return null;
    }

    // If there is only one assembly type and we can affirm that the value is selected, we don't need to show the radio buttons
    if (options.length === 1 && isPresent(assemblyType)) {
        return null;
    }

    const assemblyTypeGetOptionLabel = (type: AssemblyTypePublic) => transEnum(type, assemblyTypePublicTranslations);

    return (
        <FormItem label={t`Assembly type`} required>
            <FieldToggleButtonControlled
                name={name}
                control={control}
                FieldProps={{
                    style: { height: '40px' },
                    options: options,
                    getOptionLabel: assemblyTypeGetOptionLabel,
                }}
            />
        </FormItem>
    );
};
