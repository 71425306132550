import { Trans } from '@lingui/macro';
import {
    ActorResponseRuntypeDTO,
    ApprovalStatus,
    HistoryCustomPartDataDTO,
    HistoryOperation,
} from '@luminovo/http-client';
import { isActorDefined } from '../utils';
import {
    ActionPerformedBy,
    ApprovalStatusIcon,
    TimelineAddIcon,
    TimelineContentContainer,
    TimelineDeleteIcon,
    TimelineStatusChip,
    TimelineText,
} from './Utils';

type Props = {
    data: HistoryCustomPartDataDTO;
    actor: ActorResponseRuntypeDTO;
    operation: HistoryOperation;
};

export function CustomPartTimelineIcon({
    operation,
    approvalStatus,
}: {
    operation: HistoryOperation;
    approvalStatus: ApprovalStatus;
}) {
    if (operation === HistoryOperation.ManualAdd) return <TimelineAddIcon />;
    if (operation === HistoryOperation.ManualRemove) return <TimelineDeleteIcon />;

    return <ApprovalStatusIcon approvalStatus={approvalStatus} />;
}

function useCustomPartAction(operation: HistoryOperation, approvalStatus: ApprovalStatus) {
    switch (operation) {
        case HistoryOperation.ManualAdd:
            return <Trans>added</Trans>;
        case HistoryOperation.ManualRemove:
            return <Trans>removed</Trans>;
        case HistoryOperation.ManualUpdateApproval:
            return <TimelineStatusChip approvalStatus={approvalStatus} />;
        default:
            return null;
    }
}
export default function CustomPart({ actor, operation, data }: Props) {
    const action = useCustomPartAction(operation, data.approval);
    if (!isActorDefined(actor)) return null;
    if (action) {
        return (
            <CustomPartContent data={data} actor={actor}>
                {action}
            </CustomPartContent>
        );
    }
    return null;
}

type CustomPartActionProps = {
    data: HistoryCustomPartDataDTO;
    actor: ActorResponseRuntypeDTO;
};

function CustomPartContent({ data, actor, children }: React.PropsWithChildren<CustomPartActionProps>) {
    return (
        <TimelineContentContainer>
            <TimelineText>
                <CustomPartPartType data={data} /> {children} <ActionPerformedBy actor={actor} />
            </TimelineText>
        </TimelineContentContainer>
    );
}

function CustomPartPartType({ data }: { data: HistoryCustomPartDataDTO }) {
    return (
        <>
            <Trans>Custom part</Trans> <strong>{data.part_type.name}</strong>
        </>
    );
}
