import { Trans } from '@lingui/macro';
import { Flexbox, MaxWidthLayout, Text } from '@luminovo/design-system';
import { PageLayout } from '../../../../../../../../components/PageLayout';
import { DownloadOfferAttachmentButton } from '../../../../../../../../modules/StandardPartOfferForm.tsx/DownloadOfferAttachmentButton';
import { EditStandardPartOfferForm } from '../../../../../../../../modules/StandardPartOfferForm.tsx/components';
import { UrlParams } from '../../../../../../../../utils/routes';

const EditInternalPartNumberOfferPage = (
    urlParams: UrlParams<'/rfqs/:rfqId/sourcing/offer/internal-part-number/:offerId/edit'>,
): JSX.Element => {
    return (
        <PageLayout layout="fragment">
            <MaxWidthLayout innerMaxWidth={'65rem'}>
                <Flexbox alignItems={'center'} gap={8}>
                    <Text variant="h1">
                        <Trans>Edit IPN offer</Trans>
                    </Text>
                    <DownloadOfferAttachmentButton offerId={urlParams.pathParams.offerId} />
                </Flexbox>

                <EditStandardPartOfferForm {...urlParams} />
            </MaxWidthLayout>
        </PageLayout>
    );
};

export default EditInternalPartNumberOfferPage;
