/* eslint-disable camelcase */
import { t } from '@lingui/macro';
import { AvailabilityType, CustomPartOfferInputDTO, CustomPriceType, QuantityUnit } from '@luminovo/http-client';
import { useHttpMutation } from '../../../../resources/mutation/useHttpMutation';
import { CustomPartQuoteFormValues } from '../types';

export function useSubmitQuote({ quoteTrackingId, rfq }: { quoteTrackingId: string; rfq: string }) {
    const { mutateAsync: uploadCustomPartOffers } = useHttpMutation('POST /offers/custom-part/bulk/import', {
        snackbarMessage: t`Quote submitted`,
    });

    return async (form: CustomPartQuoteFormValues) => {
        if (form.offers.length > 0) {
            await uploadCustomPartOffers({
                requestBody: {
                    rfq_id: rfq,
                    supplier_and_stock_location_id: form.supplierAndStockLocation,
                    quote_tracking_id: quoteTrackingId,
                    custom_part_offers: form.offers.map((offer) => convertToOfferInput(offer, form)),
                    event_metadata: { type: 'SupplierPortal' },
                },
            });
        }
    };
}

function convertToOfferInput(
    offer: CustomPartQuoteFormValues['offers'][number],
    form: CustomPartQuoteFormValues,
): CustomPartOfferInputDTO {
    return {
        currency: form.currency,
        linked_part: {
            type: 'CustomPart',
            id: offer.linked_part.id,
        },
        notes: '',
        offer_number: form.offerNumber,
        one_time_costs: offer.one_time_costs.map((ots) => {
            return {
                amount: ots.price.amount,
                description: ots.description ?? undefined,
            };
        }),
        price_points: offer.price_points.map((pricePoint) => {
            return {
                amount: pricePoint.unit_price.amount,
                lead_time_days:
                    pricePoint.availability.type === AvailabilityType.LeadTime
                        ? pricePoint.availability.days
                        : undefined,
                quantity: pricePoint.quantity,
            };
        }),
        price_type: CustomPriceType.QuotePrice,
        valid_until: form.validUntil,
        supplier_and_stock_location: form.supplierAndStockLocation,
        unit_of_measurement: {
            // FIXME: This will break when we quote custom parts with different units
            unit: QuantityUnit.Pieces,
            quantity: 1,
        },
        sourcing_scenario_id: undefined,
    };
}
