import { Trans, t } from '@lingui/macro';
import {
    formatDecimal,
    formatLongDateTime,
    formatMonetaryValue,
    formatRelativeTime,
    isPresent,
} from '@luminovo/commons';
import {
    CenteredLayout,
    Flexbox,
    NonIdealState,
    Tag,
    TanStackTable,
    Text,
    colorSystem,
    createColumnHelper,
    useTanStackTable,
} from '@luminovo/design-system';
import {
    IpnWithMatchesFullPart,
    OrganizationSolutionPreferenceDTO,
    OtsFullPart,
    Packaging,
    StandardPartOfferDTO,
    isOtsComponentFull,
} from '@luminovo/http-client';
import {
    InventorySiteChip,
    LabelPart,
    SupplierAndStockLocationChip,
    formatOfferOrigin,
    formatPackaging,
    formatPart,
    formatSupplierAndStockLocationDTO,
    hasMultipleOrGenericMatches,
    isStandardPartInventoryOffer,
    isStandardPartMarketOffer,
} from '@luminovo/sourcing-core';
import { AltRouteRounded, Inventory } from '@mui/icons-material';
import { Tooltip } from '@mui/material';
import React from 'react';
import { useOrganizationSolutionPreferences } from '../../../../../resources/organizationSettings/organizationSolutionPreferenceHandler';
import { useStandardPartsFromOffers } from '../../../../../resources/part/partHandler';
import { useOfferDrawer } from '../../../../SolutionManager/components/OfferDrawer';

type InventoryOfferTableSharedContext = {
    standardParts: Array<IpnWithMatchesFullPart | OtsFullPart> | undefined;
    organizationSolutionPreferenceDTO: OrganizationSolutionPreferenceDTO | undefined;
};

type InventoryOfferTableItem = {
    offer: StandardPartOfferDTO;
    standardPart: IpnWithMatchesFullPart | OtsFullPart | undefined;
};

const EmptyPlaceholder = () => {
    return (
        <CenteredLayout>
            <NonIdealState
                Icon={Inventory}
                title={t`No inventory offers found`}
                description={t`We couldn't find any inventory offers for this part.`}
            />
        </CenteredLayout>
    );
};

const columnHelper = createColumnHelper<InventoryOfferTableItem, InventoryOfferTableSharedContext>();

const columns = [
    columnHelper.text((row) => formatPart(row.standardPart), {
        id: 'part',
        size: 200,
        label: () => t`Part`,
        cell: ({ row }) => (
            <Flexbox gap={4} alignItems={'center'}>
                <LabelPart part={row.original.standardPart} />
                {isOtsComponentFull(row.original.standardPart) &&
                    hasMultipleOrGenericMatches(row.original.standardPart) && (
                        <Tooltip title={t`Offer with ambiguous stock`}>
                            <AltRouteRounded
                                style={{ fontSize: 'small', color: colorSystem.blue[7], rotate: '90deg' }}
                            />
                        </Tooltip>
                    )}
            </Flexbox>
        ),
    }),
    columnHelper.enum(
        (row) => {
            if (isStandardPartInventoryOffer(row.offer)) {
                return row.offer.linked_location.name;
            }
            if (isStandardPartMarketOffer(row.offer)) {
                return formatSupplierAndStockLocationDTO(row.offer.linked_location);
            }
            return t`Unknown`;
        },
        {
            id: 'site',
            size: 180,
            label: () => t`Site`,
            getOptionLabel: (opt) => opt,
            cell: ({ row, sharedContext }) => {
                if (isStandardPartInventoryOffer(row.original.offer)) {
                    const linkedLocation = row.original.offer.linked_location;
                    const isPreferred =
                        sharedContext.organizationSolutionPreferenceDTO?.preferred_inventory_sites?.includes(
                            linkedLocation.id,
                        ) ?? false;
                    const isApproved =
                        sharedContext.organizationSolutionPreferenceDTO?.approved_inventory_sites?.includes(
                            linkedLocation.id,
                        ) ?? false;

                    return (
                        <InventorySiteChip
                            site={linkedLocation}
                            isPreferred={isPreferred}
                            isApproved={isApproved}
                            displaySiteName={true}
                        />
                    );
                }

                if (isStandardPartMarketOffer(row.original.offer)) {
                    const linkedLocation = row.original.offer.linked_location;
                    const isPreferred =
                        sharedContext.organizationSolutionPreferenceDTO?.preferred_suppliers_and_stock_locations?.includes(
                            linkedLocation.id,
                        ) ?? false;
                    const isApproved =
                        sharedContext.organizationSolutionPreferenceDTO?.approved_suppliers_and_stock_locations?.includes(
                            linkedLocation.id,
                        ) ?? false;

                    return (
                        <SupplierAndStockLocationChip
                            supplier={linkedLocation}
                            isPreferred={isPreferred}
                            isApproved={isApproved}
                        />
                    );
                }

                return <Trans>Unknown</Trans>;
            },
        },
    ),
    columnHelper.enum((row) => row.offer.packaging, {
        id: 'package',
        label: () => t`Packaging`,
        size: 90,
        options: [...Object.values(Packaging), null],
        getOptionLabel: (option) => formatPackaging(option),
        cell: (item) => {
            if (!isPresent(item.getValue())) {
                return (
                    <Text variant="inherit" color={colorSystem.neutral[6]}>
                        <Trans>Unknown</Trans>
                    </Text>
                );
            }
            return <Tag color={'neutral'} attention={'low'} label={formatPackaging(item.getValue())} />;
        },
    }),

    columnHelper.monetaryValue(({ offer }) => offer.available_prices.price_breaks.slice(-1)[0].unit_price, {
        label: () => t`Unit price`,
        id: 'unitPrice',
        size: 100,
        formatAs: 'unit-price',
        cell: (item) => formatMonetaryValue(item.getValue(), 'unit-price'),
    }),
    columnHelper.number('offer.available_prices.total_stock', {
        label: () => t`Total inventory`,
        id: 'totalInventory',
        size: 120,
        cell: (item) => formatDecimal(item.getValue(), { ifAbsent: '-' }),
    }),
    columnHelper.number('offer.available_prices.stock', {
        label: () => t`Available inventory`,
        id: 'availableInventory',
        size: 140,
        cell: (item) => formatDecimal(item.getValue(), { ifAbsent: '-' }),
    }),
    columnHelper.enum((row) => row.offer.origin, {
        id: 'origin',
        size: 90,
        label: () => t`Origin`,
        getOptionLabel: (origin) => formatOfferOrigin(origin),
        cell: ({ getValue }) => <Tag color="neutral" attention="low" label={formatOfferOrigin(getValue())} />,
    }),
    columnHelper.date((row) => row.offer.creation_date, {
        id: 'updated',
        size: 90,
        label: () => t`Updated`,
        cell: (item) => (
            <Tooltip title={formatLongDateTime(item.getValue())}>
                <Text variant={'inherit'} color={colorSystem.neutral[7]}>
                    {formatRelativeTime(item.getValue())}
                </Text>
            </Tooltip>
        ),
    }),
];

export function InventoryOfferTable({ offers }: { offers: StandardPartOfferDTO[] }): JSX.Element {
    const { openOfferDrawer } = useOfferDrawer();
    const { data: standardParts } = useStandardPartsFromOffers(offers, { type: 'OutsideRfQ' });
    const { data: organizationSolutionPreferenceDTO } = useOrganizationSolutionPreferences();

    const data = React.useMemo(() => {
        return offers.map((offer) => ({
            offer,
            standardPart: standardParts?.find((p) => p.id === offer.linked_part.id),
        }));
    }, [offers, standardParts]);

    const { table } = useTanStackTable({
        columns,
        data,
        enableColumnOrdering: true,
        enableColumnHiding: true,
        onRowClick: (row) => {
            openOfferDrawer({
                offer: row.original.offer,
                rfqContext: { type: 'OutsideRfQ' },
            });
        },
        sharedContext: { organizationSolutionPreferenceDTO },
    });

    return <TanStackTable table={table} size={'medium'} EmptyPlaceholder={EmptyPlaceholder} />;
}
