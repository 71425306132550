import { styled } from '@mui/material';
import { colorSystem } from '../../theme';
import { BaseButton } from './BaseButton';

export const DestructivePrimaryButton = styled(BaseButton)({
    color: colorSystem.neutral.white,
    background: colorSystem.red[7],
    border: `1px solid ${colorSystem.red[7]}`,

    '&:hover': {
        background: colorSystem.red[8],
    },
    '&:active': {
        background: '#872F37',
    },
    '&:disabled': {
        color: colorSystem.neutral.white,
        background: colorSystem.neutral[5],
    },
});
