import { CenteredLayout, colorSystem } from '@luminovo/design-system';
import { PageLayoutSidebar } from '../../components/PageLayoutSidebar';
import { useSelectedTab } from '../../components/ProgressAwareTabs';
import { useIsBomDropzoneVisible } from '../../resources/assembly/assemblyHandler';
import { useRfQ } from '../../resources/rfq/rfqHandler';
import { useIsRfqEditable } from '../../utils/rfqUtils';
import { UrlParams } from '../../utils/routes';
import { AssemblyDetails } from './components/BomHealthDashboard/AssemblyDetails';
import BomViewer from './components/BomViewer';
import { FilesSection } from './components/FilesSection';
import { ToolbarBom } from './components/ToolbarBom';

const AssemblyPage = ({ pathParams, queryParams }: UrlParams<'/rfqs/:rfqId/bom/assembly/:assemblyId'>): JSX.Element => {
    const { rfqId, assemblyId } = pathParams;
    const { currentParentAssemblyId } = queryParams;
    const { data: rfq } = useRfQ(rfqId);
    const { isRfqEditable } = useIsRfqEditable(rfq?.status, rfq?.is_archived, rfq?.workflow_type);
    const isBomDrropzoneVisible = useIsBomDropzoneVisible({ assemblyId });
    const tab = useSelectedTab();

    return (
        <PageLayoutSidebar
            contentBackground={colorSystem.neutral[1]}
            header={
                <ToolbarBom
                    rfqId={rfqId}
                    assemblyId={assemblyId}
                    isRfqEditable={isRfqEditable}
                    isMonitoringOpen={queryParams.monitoring}
                    currentParentAssemblyId={currentParentAssemblyId}
                />
            }
            sidebar={
                tab === 'Bom' && !isBomDrropzoneVisible ? (
                    <AssemblyDetails
                        rfqId={rfqId}
                        assemblyId={assemblyId}
                        currentParentAssemblyId={currentParentAssemblyId}
                    />
                ) : undefined
            }
        >
            {tab === 'Bom' && <BomViewer isEditable={isRfqEditable} rfqId={rfqId} assemblyId={assemblyId} />}
            {tab === 'Cad' && (
                <CenteredLayout bgcolor={colorSystem.neutral[1]} justifyContent="unset" paddingTop={'48px'}>
                    <FilesSection tab={'Cad'} isEditable={isRfqEditable} assemblyId={assemblyId} rfqId={rfqId} />
                </CenteredLayout>
            )}

            {tab === 'Manufacturing' && (
                <CenteredLayout bgcolor={colorSystem.neutral[1]} justifyContent="unset" paddingTop={'48px'}>
                    <FilesSection
                        tab={'Manufacturing'}
                        isEditable={isRfqEditable}
                        assemblyId={assemblyId}
                        rfqId={rfqId}
                    />
                </CenteredLayout>
            )}
        </PageLayoutSidebar>
    );
};

export default AssemblyPage;
