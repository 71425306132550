/* eslint-disable spellcheck/spell-checker */
/* camelcase */
import { t } from '@lingui/macro';
import { isPresent } from '@luminovo/commons';
import {
    AutocompleteOptions,
    ComplianceIcon,
    CustomerPartNumberIcon,
    LifecycleIcon,
    ManufacturingIcon,
    ParametricSearchInput,
    useParametricSearchInputState,
    UseParametricSearchInputStateReturn,
} from '@luminovo/design-system';
import { ComplianceStatus, ExtractResponseBody, ManufacturerDTO, OtsFullPart, PackageDTO } from '@luminovo/http-client';
import { Subject } from '@mui/icons-material';
import { transEnum } from '../../../components/localization/TransEnum';
import { useHttpQuery } from '../../../resources/http/useHttpQuery';
import { ComplianceStatusTranslations } from '../../../resources/part/i18n';
import { lifecycleEnumPublicTranslations } from '../../../resources/part/partFrontendTypes';
import { usePartPackages } from '../../../resources/part/partHandler';
import { typeSafeObjectKeys } from '../../../utils/typingUtils';

interface PartSearchAutocompleteState {
    manufacturersById: Record<string, ManufacturerDTO>;
    packages: PackageDTO[];
    aggregations?: ExtractResponseBody<'POST /parts/library/search'>['aggregations'];
}

export type UsePartSearchStateResult = UseParametricSearchInputStateReturn<OtsFullPart, PartSearchAutocompleteState>;

export function usePartSearchState(props: UsePartSearchStateProps): UsePartSearchStateResult {
    return useParametricSearchInputState(config(props));
}

export function PartSearchInput({
    searchState,
    searchResultsPage,
}: {
    searchState: UsePartSearchStateResult;
    searchResultsPage?: ExtractResponseBody<'POST /parts/library/search'>;
}) {
    const { data: manufacturersById = {} } = useHttpQuery(
        'GET /manufacturers',
        {},
        {
            select: groupManufacturersById,
            staleTime: Infinity,
        },
    );
    const { data: packages = [] } = usePartPackages();

    const autocompleteState: PartSearchAutocompleteState = {
        manufacturersById,
        aggregations: searchResultsPage?.aggregations,
        packages,
    };

    return <ParametricSearchInput {...searchState} autocompleteState={autocompleteState} placeholder={t`Search`} />;
}

function groupManufacturersById(res: { data: ManufacturerDTO[] }) {
    return res.data.reduce((manufacturersById: Record<string, ManufacturerDTO>, manufacturer) => {
        manufacturersById[manufacturer.id] = manufacturer;
        return manufacturersById;
    }, {});
}

export enum SearchField {
    capacitance = 'capacitance',
    resistance = 'resistance',
    tolerance = 'tolerance',
    mpn = 'mpn',
    ipn = 'ipn',
    cpn = 'cpn',
    spn = 'spn',
    voltageRating = 'voltage-rating',
    reachCompliant = 'reach-compliant',
    rohsCompliant = 'rohs-compliant',
    dielectricDielectric = 'dielectric-dielectric',
    partType = 'part-type',
    package = 'package',
    manufacturer = 'manufacturer',
    lifecycle = 'lifecycle',
    fuzzy = '*',
    powerRating = 'power-rating',
    temperatureCoefficient = 'temperature-coefficient',
    description = 'description',
    linkedParts = 'linked-parts',
    lastChanged = 'last-changed',
    erpChanges = 'erp-data-changes',
    componentType = 'component-type',
    customer = 'customer',
}

export function findMpnGuess(searchState: UsePartSearchStateResult): string {
    const mpnValue = searchState.state.selectedBlocks.find(
        (block) => block.field === SearchField.mpn || block.field === SearchField.fuzzy,
    )?.parameter;
    return mpnValue ? String(mpnValue) : '';
}

const optionsForRohsComplianceStatus = (state?: PartSearchAutocompleteState) => {
    const field = state?.aggregations?.find((agg) => agg.field === 'rohs_compliant');
    if (!isPresent(field)) {
        return typeSafeObjectKeys(ComplianceStatus).map((key) => {
            return { value: ComplianceStatus[key], label: key };
        });
    }
    return field.options.map((b) => {
        return { value: b.value, label: transEnum(b.value, ComplianceStatusTranslations), count: b.count };
    });
};

const optionsForReachComplianceStatus = (state?: PartSearchAutocompleteState) => {
    const field = state?.aggregations?.find((agg) => agg.field === 'reach_compliant');
    if (!isPresent(field)) {
        return typeSafeObjectKeys(ComplianceStatus).map((key) => {
            return { value: ComplianceStatus[key], label: key };
        });
    }
    return field.options.map((b) => {
        return { value: b.value, label: transEnum(b.value, ComplianceStatusTranslations), count: b.count };
    });
};

const optionsForlifecycle = (state?: PartSearchAutocompleteState) => {
    const field = state?.aggregations?.find((agg) => agg.field === 'lifecycle');
    if (!isPresent(field)) {
        return typeSafeObjectKeys(ComplianceStatus).map((key) => {
            return { value: ComplianceStatus[key], label: key };
        });
    }
    return field.options.map((b) => {
        return { value: b.value, label: transEnum(b.value, lifecycleEnumPublicTranslations), count: b.count };
    });
};

const optionsForManufacturer = (state?: PartSearchAutocompleteState) => {
    const field = state?.aggregations?.find((agg) => agg.field === 'manufacturers');
    if (!isPresent(field) || !state) {
        return [];
    }

    return field.options.map((b) => {
        const label = state.manufacturersById[b.value]?.name ?? '...';
        return { value: b.value, label, count: b.count };
    });
};

type UsePartSearchStateProps = Pick<AutocompleteOptions<OtsFullPart, PartSearchAutocompleteState>, 'history'>;

const config = (props: UsePartSearchStateProps): AutocompleteOptions<OtsFullPart, PartSearchAutocompleteState> => {
    return {
        parameters: [
            {
                field: SearchField.manufacturer,
                label: t`Manufacturer`,
                icon: <ManufacturingIcon />,
                formatter: (manufacturerId, state) => {
                    return state?.manufacturersById[String(manufacturerId)]?.name ?? '...';
                },
                ops: [
                    {
                        op: 'is',
                        options: optionsForManufacturer,
                        filter: (part, manufacturerId) => {
                            return part.manufacturer.id === manufacturerId;
                        },
                    },
                ],
            },
            {
                field: SearchField.mpn,
                label: t`MPN`,
                icon: <CustomerPartNumberIcon />,
                ops: [
                    {
                        op: 'starts-with',
                        filter: (part, mpn) => {
                            return part.mpn.startsWith(mpn);
                        },
                    },
                ],
            },
            {
                field: SearchField.description,
                label: t`Description`,
                icon: <Subject style={{ width: 16, height: 16 }} />,
                ops: [
                    {
                        op: 'like',
                        label: t`contains`,
                    },
                ],
            },
            {
                field: SearchField.lifecycle,
                label: t`Lifecycle`,
                icon: <LifecycleIcon />,
                ops: [
                    {
                        op: 'is',
                        options: optionsForlifecycle,
                        filter: (part, lifecycle) => {
                            return part.lifecycle_status === lifecycle;
                        },
                    },
                ],
            },
            {
                field: SearchField.reachCompliant,
                icon: <ComplianceIcon />,
                label: t`REACH compliance`,
                ops: [
                    {
                        op: 'is',
                        options: optionsForReachComplianceStatus,
                        filter: (part, reachCompliance) => {
                            return part.reach_compliant === reachCompliance;
                        },
                    },
                ],
            },
            {
                field: SearchField.rohsCompliant,
                icon: <ComplianceIcon />,
                label: t`RoHS compliance`,
                ops: [
                    {
                        op: 'is',
                        options: optionsForRohsComplianceStatus,
                        filter: (part, rohsCompliance) => {
                            return part.rohs_compliant === rohsCompliance;
                        },
                    },
                ],
            },
        ],
        textQueryParser: (parameter) => {
            return { field: '*', op: 'like', parameter };
        },
        ...props,
    };
};
