/* eslint-disable spellcheck/spell-checker */

export interface Tenant extends BasicTenant {
    auth0OrgId: string;
}

interface BasicTenant {
    name: string;
    tenant: string;
    /**
     * If true, the user must have cookies enabled before they can log in.
     *
     * Note: this is a special case for BMK
     */
    requiresCookiesBeforeAuth?: boolean;

    /**
     * If true, the supplier portal is enabled for this tenant.
     */
    enableSupplierPortal?: boolean;
}

const tenants: Record<string, Tenant> = {
    // 'ci-master.dev.luminovo.ai': {
    //     name: '🚀 CI Master 🚀',
    //     auth0OrgId: 'org_Rnq9vlAkXrBbBucT',
    // },
    // 'localhost:3000': {
    //     name: 'Localhost',
    //     auth0OrgId: 'org_Rnq9vlAkXrBbBucT',
    // },
    // 'luminovo.luminovo.ai': {
    //     name: 'Luminovo GmbH',
    //     auth0OrgId: 'org_8MsDfjHRdBHh6wgs',
    // },
    'zollner.luminovo.com': {
        name: 'Zollner',
        auth0OrgId: 'org_heF6w8FXp7hv8SPo',
        tenant: 'zollner',
    },
    'zollner-usa.luminovo.com': {
        name: 'Zollner USA',
        auth0OrgId: 'org_7LZS3sniFuFkPIsz',
        tenant: 'zollner-usa',
    },
    'zollner-china.luminovo.com': {
        name: 'Zollner China',
        auth0OrgId: 'org_RkGLjCDTkAy6QKcj',
        tenant: 'zollner-china',
    },
    'bmk.luminovo.com': {
        name: 'BMK',
        auth0OrgId: 'org_Lm2vYmpLBQEZIUhf',
        tenant: 'bmk',
        requiresCookiesBeforeAuth: true,
    },
    // Note: the auth0 org ID is the same since BMK can be accessed from both
    // portal.bmkyourproto.de and bmk.luminovo.com
    'portal.bmkyourproto.de': {
        name: 'BMK',
        auth0OrgId: 'org_Lm2vYmpLBQEZIUhf',
        tenant: 'bmk',
        requiresCookiesBeforeAuth: true,
    },
    'pcbwhiz-eu.luminovo.com': {
        name: 'pcbwhiz EU',
        auth0OrgId: 'org_W3wXu4ATrNApbsT9',
        tenant: 'pcbwhiz-eu',
    },
    'limtronik.luminovo.com': {
        name: 'Limtronik',
        auth0OrgId: 'org_ndsPW7R58ESfKjxh',
        tenant: 'limtronik',
    },
    'kessler.luminovo.com': {
        name: 'Kessler',
        auth0OrgId: 'org_JWyvB2dvhAe4iCon',
        tenant: 'kessler',
    },
    'global-components.luminovo.com': {
        name: 'Global Components',
        auth0OrgId: 'org_PMOVmuKUOtrLVJvv',
        tenant: 'global-components',
    },
    // Note: the auth0 org ID is the same since pcbwhiz-eu can be accessed from both
    // app-eu.pcbwhiz.com and pcbwhiz-eu.luminovo.com
    'app-eu.pcbwhiz.com': {
        name: 'pcbwhiz EU',
        auth0OrgId: 'org_W3wXu4ATrNApbsT9',
        tenant: 'pcbwhiz-eu',
    },
    'pcbwhiz-apac.luminovo.com': {
        name: 'pcbwhiz APAC',
        auth0OrgId: 'org_rr51XzK1eKV4t2jW',
        tenant: 'pcbwhiz-apac',
    },
    // Note: the auth0 org ID is the same since pcbwhiz-apac can be accessed from both
    // app-apac.pcbwhiz.com and pcbwhiz-apac.luminovo.com
    'app-apac.pcbwhiz.com': {
        name: 'pcbwhiz APAC',
        auth0OrgId: 'org_rr51XzK1eKV4t2jW',
        tenant: 'pcbwhiz-apac',
    },
    // Note: below are all the *.luminovo.ai domain tenants, kept for backwards-compatibility
    // to be removed once the migration to *.luminovo.com is complete
    'zollner.luminovo.ai': {
        name: 'Zollner',
        auth0OrgId: 'org_heF6w8FXp7hv8SPo',
        tenant: 'zollner',
    },
    'zollner-usa.luminovo.ai': {
        name: 'Zollner USA',
        auth0OrgId: 'org_7LZS3sniFuFkPIsz',
        tenant: 'zollner-usa',
    },
    'bmk.luminovo.ai': {
        name: 'BMK',
        auth0OrgId: 'org_Lm2vYmpLBQEZIUhf',
        tenant: 'bmk',
        requiresCookiesBeforeAuth: true,
    },
    'pcbwhiz-eu.luminovo.ai': {
        name: 'pcbwhiz EU',
        auth0OrgId: 'org_W3wXu4ATrNApbsT9',
        tenant: 'pcbwhiz-eu',
    },
    'limtronik.luminovo.ai': {
        name: 'Limtronik',
        auth0OrgId: 'org_ndsPW7R58ESfKjxh',
        tenant: 'limtronik',
    },
    'kessler.luminovo.ai': {
        name: 'Kessler',
        auth0OrgId: 'org_JWyvB2dvhAe4iCon',
        tenant: 'kessler',
    },
    'global-components.luminovo.ai': {
        name: 'Global Components',
        auth0OrgId: 'org_PMOVmuKUOtrLVJvv',
        tenant: 'global-components',
    },
    'pcbwhiz-apac.luminovo.ai': {
        name: 'pcbwhiz APAC',
        auth0OrgId: 'org_rr51XzK1eKV4t2jW',
        tenant: 'pcbwhiz-apac',
    },
    'adex.luminovo.com': {
        name: 'Adex Electronique',
        auth0OrgId: 'org_jO39RN7EAtKKM3fN',
        tenant: 'adex',
    },
    'adex.luminovo.ai': {
        name: 'Adex Electronique',
        auth0OrgId: 'org_jO39RN7EAtKKM3fN',
        tenant: 'adex',
    },
    'adex-electronique.luminovo.com': {
        name: 'Adex Electronique',
        auth0OrgId: 'org_jO39RN7EAtKKM3fN',
        tenant: 'adex',
    },
    'adex-electronique.luminovo.ai': {
        name: 'Adex Electronique',
        auth0OrgId: 'org_jO39RN7EAtKKM3fN',
        tenant: 'adex',
    },
    'portail.adex-electronique.com': {
        name: 'Adex Electronique',
        auth0OrgId: 'org_jO39RN7EAtKKM3fN',
        tenant: 'adex',
    },
    'connectgroup.luminovo.com': {
        name: 'Connect Group',
        tenant: 'connectgroup',
        auth0OrgId: 'org_CKxX5cyjCdnpsZRB',
    },
    'connectgroup.luminovo.ai': {
        name: 'Connect Group',
        tenant: 'connectgroup',
        auth0OrgId: 'org_CKxX5cyjCdnpsZRB',
    },
    'kirron.luminovo.com': {
        name: 'Kirron',
        tenant: 'kirron',
        auth0OrgId: 'org_Z9atvhbZJAaBrsek',
    },
    'gpv.luminovo.com': {
        name: 'GPV',
        tenant: 'gpv',
        auth0OrgId: 'org_1cd49gIixZWzzLIU',
    },
    'gpv.luminovo.ai': {
        name: 'GPV',
        tenant: 'gpv',
        auth0OrgId: 'org_1cd49gIixZWzzLIU',
    },
    'bomanalyzer24.com': {
        name: 'Kirron',
        tenant: 'kirron',
        auth0OrgId: 'org_Z9atvhbZJAaBrsek',
    },
};

/**
 * Basic tenants are tenants who have multipe domains that are linked to the same tenant
 * and that do not have a customer portal setup (i.e. they do not have an Auth0 organization).
 */
const basicTenants: Record<string, BasicTenant> = {
    'sga.luminovo.ai': {
        name: 'SG Automotive d.o.o.',
        tenant: 'sg-automotive',
    },
    'sga.luminovo.com': {
        name: 'SG Automotive d.o.o.',
        tenant: 'sg-automotive',
    },
    'sg-automotive.luminovo.ai': {
        name: 'SG Automotive d.o.o.',
        tenant: 'sg-automotive',
    },
    'sg-automotive.luminovo.com': {
        name: 'SG Automotive d.o.o.',
        tenant: 'sg-automotive',
    },
    'selva.luminovo.com': {
        name: 'Selva Electronique',
        tenant: 'selva-electronique',
    },
    'selva.luminovo.ai': {
        name: 'Selva Electronique',
        tenant: 'selva-electronique',
    },
    'bmk-series.luminovo.com': {
        name: 'BMK',
        tenant: 'bmk-series',
        requiresCookiesBeforeAuth: true,
    },
    'bmk-israel.luminovo.com': {
        name: 'BMK',
        tenant: 'bmk-israel',
        requiresCookiesBeforeAuth: true,
    },
    'bmk-sol.luminovo.com': {
        name: 'BMK',
        tenant: 'bmk-sol',
        requiresCookiesBeforeAuth: true,
    },
    'starteam.luminovo.com': {
        name: 'Starteam',
        tenant: 'starteam',
        enableSupplierPortal: true,
    },
};

export function getCustomerPortalTenant(): Tenant | undefined {
    const tenant = tenants[window.location.host];
    return tenant;
}

/**
 * Basic tenants are tenants who have multipe domains that are linked to the same tenant
 * and that do not have a customer portal setup (i.e. they do not have an Auth0 organization).
 */
function getBasicTenant(): BasicTenant | undefined {
    const tenant = basicTenants[window.location.host];
    return tenant;
}

/**
 * FIXME: This is a temporary solution to get the tenant ID.
 */
export function getTenantId() {
    const customerPortalTenant = getCustomerPortalTenant();
    if (customerPortalTenant) {
        return customerPortalTenant.tenant;
    }

    const basicTenant = getBasicTenant();
    if (basicTenant) {
        return basicTenant.tenant;
    }

    if (window.location.host.split('.').some((part) => part === 'dev' || part.includes('localhost'))) {
        return 'luminovo';
    }
    return window.location.host.split('.')[0];
}

/**
 * @deprecated - Only used by BMK, which is a special case for the cookie requirement.
 */
export function getRequiresCookiesBeforeAuth() {
    const tenant = getCustomerPortalTenant() ?? getBasicTenant();
    return Boolean(tenant?.requiresCookiesBeforeAuth);
}
