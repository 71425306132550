import { t } from '@lingui/macro';
import { FilterChip, Tooltip } from '@luminovo/design-system';
import { AutoAwesomeRounded } from '@mui/icons-material';
import { Box } from '@mui/material';
import { Capability } from '../../../../resources/pcb/pcbFunctions';
import { usePcbModuleContext } from '../PcbModuleContextProvider';
import { usePdfViewerDrawer } from './usePdfViewerDrawer';

export function InspectPdfButton({ capability }: { capability: Capability }) {
    const [state, dispatch] = usePcbModuleContext();
    const regions = capability.extractedFromPdf?.regions ?? [];
    const file = capability.extractedFromPdf?.file;
    const { openDrawer } = usePdfViewerDrawer();

    if (!capability.extractedFromPdf || !file || regions.length === 0) {
        return null;
    }

    const selectedRegions = state.selectedPdf?.regions
        .map((r) => r.id)
        .sort()
        .join(',');
    const capabilityRegions = regions
        .map((r) => r.id)
        .sort()
        .join(',');
    const filePath = file.path ?? '';
    const isSelected = state.selectedPdf?.fileName === filePath && selectedRegions === capabilityRegions;

    const handleChange = () => {
        const selectedPdf = isSelected
            ? undefined
            : {
                  fileName: filePath,
                  regions: regions,
              };
        dispatch((state) => {
            return {
                ...state,
                selectedPdf,
            };
        });

        if (selectedPdf) {
            openDrawer({ selectedPdf });
        }
    };

    return (
        <Tooltip title={t`Click to see original PDF value`}>
            <Box>
                <FilterChip
                    label="PDF"
                    startIcon={<AutoAwesomeRounded fontSize="inherit" />}
                    isSelected={isSelected}
                    onChange={handleChange}
                    value={''}
                />
            </Box>
        </Tooltip>
    );
}
