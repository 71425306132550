/* eslint-disable camelcase */
import * as r from 'runtypes';
import { ApprovalStatus } from '../backendTypes';
import { CustomComponentFull, IpnWithMatchesFullPart } from '../internalPartNumber/internalPartNumberBackendTypes';
import { PartSpecificationTypes } from './PartSpecificationTypes';
import {
    CustomFullPart,
    CustomOptionDTORuntype,
    CustomOptionTypes,
    GenericFullPart,
    GenericPartTypes,
    IncompleteGenericFullPart,
    OtsFullPart,
    PartAlternativeSimilarityEnumRuntype,
    PartOptionDTORuntype,
    PartSuggestionOriginRuntype,
    StandardPartTypes,
    TechnicalParametersRuntype,
} from './partBackendTypes';

export interface CustomPartSpecification extends r.Static<typeof CustomPartSpecificationRuntype> {}
const CustomPartSpecificationRuntype = r.Record({
    type: r.Literal(PartSpecificationTypes.Custom),
    data: r.Array(CustomOptionDTORuntype),
});

export interface OtsPartSpecification extends r.Static<typeof OtsPartSpecificationRuntype> {}
const OtsPartSpecificationRuntype = r.Record({
    type: r.Literal(PartSpecificationTypes.OffTheShelf),
    data: r.Record({
        is_manufacturer_free: r.Boolean,
        part_options: r.Array(PartOptionDTORuntype),
    }),
});

export type PartSpecification = r.Static<typeof PartSpecificationRuntype>;
export const PartSpecificationRuntype = r.Union(CustomPartSpecificationRuntype, OtsPartSpecificationRuntype);

export type SuggestablePart = r.Static<typeof SuggestablePartRuntype>;
const SuggestablePartRuntype = r.Union(
    r.Record({
        type: r.Literal(StandardPartTypes.OffTheShelf),
        data: r.String,
    }),
    r.Record({
        type: r.Literal(StandardPartTypes.Generic),
        data: r.String,
    }),
    r.Record({
        type: r.Literal(StandardPartTypes.Ipn),
        data: r.String,
    }),
    r.Record({
        type: r.Literal('IncompleteGeneric'),
        data: TechnicalParametersRuntype,
    }),
);

export interface PartSuggestion extends r.Static<typeof PartSuggestionRuntype> {}
export const PartSuggestionRuntype = r.Record({
    part: SuggestablePartRuntype,
    origin: PartSuggestionOriginRuntype.nullable(),
});

/**
 * A CustomPartSpecification or undefined if the given part is not a custom part
 */
export function customPartOrUndefined(part?: PartSpecification | null): CustomPartSpecification | undefined {
    if (!part) {
        return undefined;
    }
    if (part.type === PartSpecificationTypes.Custom) {
        return part;
    }
    return undefined;
}

/**
 * An OtsPartSpecification or undefined if the given part is not a custom part
 */
export function otsPartOrUndefined(part?: PartSpecification | null): OtsPartSpecification | undefined {
    if (!part) {
        return undefined;
    }
    if (part?.type === PartSpecificationTypes.OffTheShelf) {
        return part;
    }
    return undefined;
}

/**
 *
 * @param partSpecification The part specification to retrieve the off-the-shelf part option ids
 * @returns a list of part option ids that are approved and are of off-the-sheld standard part type
 */
export function getApprovedOtsPartIds(partSpecification: PartSpecification): string[] {
    if (partSpecification.type !== PartSpecificationTypes.OffTheShelf) {
        return [];
    }
    return partSpecification.data.part_options
        .filter((part) => {
            return part.approval_status === ApprovalStatus.Approved && part.part.type === StandardPartTypes.OffTheShelf;
        })
        .map((part) => {
            return part.part.data;
        });
}

/**
 *
 * @param partSpecification The part specification to retrieve the generic part option ids
 * @returns a list of part option ids that are approved and are of generic standard part type
 */
export function getApprovedGenericPartIds(partSpecification: PartSpecification): string[] {
    if (partSpecification.type !== PartSpecificationTypes.OffTheShelf) {
        return [];
    }
    return partSpecification.data.part_options
        .filter((part) => {
            return part.approval_status === ApprovalStatus.Approved && part.part.type === StandardPartTypes.Generic;
        })
        .map((part) => {
            return part.part.data;
        });
}

export function getApprovedCustomPartIds(partSpecification: PartSpecification): string[] {
    if (partSpecification.type !== PartSpecificationTypes.Custom || !partSpecification.data) {
        return [];
    }
    return partSpecification.data
        .filter((part) => {
            return part.approval_status === ApprovalStatus.Approved && part.part.type === CustomOptionTypes.CustomPart;
        })
        .map((part) => {
            return part.part.data;
        });
}

/**
 *
 * @param partSpecification The part specification to retrieve the IPN part option ids
 * @returns a list of part option ids that are approved and are of IPN standard part type
 */
export function getApprovedIpnIds(partSpecification: PartSpecification): string[] {
    if (partSpecification.type !== PartSpecificationTypes.OffTheShelf) {
        return [];
    }
    return partSpecification.data.part_options
        .filter((part) => {
            return part.approval_status === ApprovalStatus.Approved && part.part.type === StandardPartTypes.Ipn;
        })
        .map((part) => {
            return part.part.data;
        });
}

export function getCustomApprovedIpnIds(partSpecification: PartSpecification): string[] {
    if (partSpecification.type !== PartSpecificationTypes.Custom) {
        return [];
    }

    return partSpecification.data
        .filter((part) => {
            return (
                part.approval_status === ApprovalStatus.Approved && part.part.type === CustomOptionTypes.CustomComponent
            );
        })
        .map((part) => {
            return part.part.data;
        });
}

// -----

export type FullPart = GenericFullPart | CustomFullPart | OtsFullPart | IpnWithMatchesFullPart | CustomComponentFull;

export function isCustomFullPart(part?: FullPart | null): part is CustomFullPart {
    // TODO(fhur) find a better way to make these type checks
    return (
        !!part &&
        !isOtsFullPart(part) &&
        !isGenericFullPart(part) &&
        !isOtsComponentFull(part) &&
        !isCustomComponentFull(part)
    );
}

export function isOtsFullPart(part?: FullPart | IncompleteGenericFullPart | null): part is OtsFullPart {
    // TODO(fhur) find a better way to make these type checks
    return !!part && 'id' in part && 'mpn' in part;
}

export function isGenericFullPart(part?: FullPart | IncompleteGenericFullPart | null): part is GenericFullPart {
    // TODO(fhur) find a better way to make these type checks
    return (
        !!part &&
        'id' in part &&
        !isIncompleteGenericFullPart(part) &&
        'content' in part &&
        !isOtsFullPart(part) &&
        !isOtsComponentFull(part)
    );
}

export function isOtsComponentFull(part?: FullPart | IncompleteGenericFullPart | null): part is IpnWithMatchesFullPart {
    // TODO(fhur) find a better way to make these type checks
    return !!part && 'id' in part && 'part_specifications' in part && 'matches' in part && 'state' in part;
}

export function isCustomComponentFull(part?: FullPart | IncompleteGenericFullPart | null): part is CustomComponentFull {
    // TODO(fhur) find a better way to make these type checks
    return !!part && 'id' in part && 'description' in part && 'matches' in part && 'state' in part;
}

export function isFullPart(part: FullPart | IncompleteGenericFullPart): part is FullPart {
    return 'id' in part;
}

export function isIncompleteGenericFullPart(
    part: FullPart | IncompleteGenericFullPart | string,
): part is IncompleteGenericFullPart {
    if (
        typeof part === 'object' &&
        part !== null &&
        'type' in part &&
        'technical_parameters' in part &&
        (part.type === GenericPartTypes.Resistor || part.type === GenericPartTypes.Capacitor)
    ) {
        if (part.type === GenericPartTypes.Resistor) {
            return part.technical_parameters.resistance === null || part.technical_parameters.package_id === null;
        } else if (part.type === GenericPartTypes.Capacitor) {
            return part.technical_parameters.capacitance === null || part.technical_parameters.package_id === null;
        }
    }
    return false;
}

export interface AddPartEvents extends r.Static<typeof AddPartEventsRuntype> {}
export const AddPartEventsRuntype = r.Record({
    assembly_id: r.String,
    design_item_ids: r.Array(r.String),
    alternative_origin: PartAlternativeSimilarityEnumRuntype.optional(),
    screen_section: r
        .Union(
            r.Literal('comparePartsModal'),
            r.Literal('alternativeParts'),
            r.Literal('partialMatches'),
            r.Literal('ipnSearch'),
            r.Literal('mpnSearch'),
            r.Literal('otsPartForm'),
            r.Literal('genericPartForm'),
        )
        .optional(),
});
