import { CenteredLayout, colorSystem } from '@luminovo/design-system';
import { PcbAttribute, Region } from '@luminovo/pdf-extractor';
import {
    ImmutableRegionNetwork,
    PdfViewer,
    PdfViewerContextProvider,
    getPdfDocument,
    scrollToRegions,
    usePdfViewerState,
} from '@luminovo/sourcing-core';
import { CircularProgress } from '@mui/material';
import { useQuery } from '@tanstack/react-query';

interface Props {
    pathToPdf: string;
    regions: Region<PcbAttribute>[];
    actionStyles?: React.CSSProperties;
}

export function PcbPdfViewer(props: Props) {
    // Wrap the component in a context provider so that the pdf viewer state is available to all components
    return (
        <PdfViewerContextProvider>
            <PcbPdfViewerInner {...props} />
        </PdfViewerContextProvider>
    );
}

function PcbPdfViewerInner({ pathToPdf, regions, actionStyles }: Props) {
    const [, dispatch] = usePdfViewerState();

    const { data: pdf } = useQuery({
        queryKey: [pathToPdf, regions],
        queryFn: async () => {
            const pdf = await getPdfDocument(pathToPdf);
            dispatch({
                type: 'setRegions',
                regions: new ImmutableRegionNetwork((regions ?? []) as any),
            });
            //@ts-ignore
            scrollToRegions(regions);
            return pdf;
        },
        enabled: Boolean(pathToPdf),
    });
    if (!pdf) {
        return (
            <CenteredLayout>
                <CircularProgress />
            </CenteredLayout>
        );
    }
    return (
        <PdfViewer
            pdf={pdf}
            pdfViewerOptions={{
                actionStyles: {
                    position: 'fixed',
                    top: 'unset',
                    bottom: 40,
                    left: '70%',
                    transform: 'translateX(-50%)',
                    background: colorSystem.neutral[8],
                    padding: '8px',
                    borderRadius: 8,
                    ...actionStyles,
                },
            }}
        />
    );
}
