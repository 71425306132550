import { t } from '@lingui/macro';
import {
    extractAmountFromMonetaryValue,
    formatDecimal,
    formatMonetaryValue,
    isPresent,
    maxBy,
    minBy,
    throwErrorUnlessProduction,
} from '@luminovo/commons';
import { InputAdornment } from '@mui/material';
import { Column } from '@tanstack/react-table';
import { colorSystem } from '../../../theme';
import { Flexbox } from '../../Flexbox';
import { SearchInput } from '../../SearchField';
import { fieldConverterNumber } from '../../forms/FieldNumeric/FieldNumeric';
import { createNumericValidator } from '../../forms/FieldNumeric/FieldNumericController';

export type NumberRange = [number | null, number | null];

function parseNumberValue(value: string | undefined): number | undefined {
    if (!isPresent(value) || value === '' || (typeof value === 'string' && value.trim() === '')) {
        return undefined;
    }

    const parsedValue = Number(value);
    return Number.isFinite(parsedValue) ? parsedValue : undefined;
}

function generatePlaceholder<TData>(column: Column<TData, unknown>, minOrMax: 'min' | 'max'): string {
    if (column.columnDef.filterFn === 'inNumberRange') {
        const facetedMinMaxValues = column.getFacetedMinMaxValues();
        if (isPresent(facetedMinMaxValues)) {
            switch (minOrMax) {
                case 'min':
                    return formatDecimal(facetedMinMaxValues[0], { ifAbsent: '-' });
                case 'max':
                    return formatDecimal(facetedMinMaxValues[1], { ifAbsent: '-' });
            }
        }
    }

    if (column.columnDef.filterFn === 'inMonetaryValueRange') {
        const facedValues = Array.from(column.getFacetedUniqueValues().keys());
        switch (minOrMax) {
            case 'min':
                return formatMonetaryValue(
                    minBy(facedValues, (x) => extractAmountFromMonetaryValue(x)),
                    column.columnDef.meta?.inMonetaryValueRange?.formatAs ?? 'default',
                    { ifAbsent: '-' },
                );
            case 'max':
                return formatMonetaryValue(
                    maxBy(facedValues, (x) => extractAmountFromMonetaryValue(x)),
                    column.columnDef.meta?.inMonetaryValueRange?.formatAs ?? 'default',
                    { ifAbsent: '-' },
                );
        }
    }

    return '-';
}

export function FilterInRange<TData>({
    column,
    onClose,
}: {
    column: Column<TData, unknown>;
    onClose: () => void;
}): JSX.Element | null {
    const unsafeValue = column.getFilterValue();

    if (
        !column.getCanFilter() ||
        (column.columnDef.filterFn !== 'inNumberRange' && column.columnDef.filterFn !== 'inMonetaryValueRange')
    ) {
        return null;
    }

    if (
        unsafeValue === undefined ||
        (Array.isArray(unsafeValue) &&
            unsafeValue.every((v) => typeof v === 'number' || !isPresent(v)) &&
            unsafeValue.length === 2)
    ) {
        const [minValue, maxValue] = (unsafeValue ?? [null, null]) as NumberRange;

        const handleMinChange = (value: string | undefined) => {
            const numberValue = parseNumberValue(value);
            column.setFilterValue((old: NumberRange | undefined) => {
                const max = old?.[1];
                if (!isPresent(numberValue) && !isPresent(max)) {
                    return undefined;
                }
                return [numberValue ?? null, max ?? null];
            });
        };

        const handleMaxChange = (value: string | undefined) => {
            const numberValue = parseNumberValue(value);
            column.setFilterValue((old: NumberRange | undefined) => {
                const min = old?.[0];
                if (!isPresent(numberValue) && !isPresent(min)) {
                    return undefined;
                }
                return [min ?? null, numberValue ?? null];
            });
        };

        return (
            <Flexbox gap={4}>
                <SearchInput
                    placeholder={generatePlaceholder(column, 'min')}
                    value={String(minValue ?? '')}
                    onChange={handleMinChange}
                    debounceWait={50}
                    onKeyEnter={onClose}
                    onClear={() => handleMinChange(undefined)}
                    style={{ backgroundColor: colorSystem.neutral.white, width: '148px' }}
                    formatter={{
                        converter: fieldConverterNumber,
                        validation: createNumericValidator({ required: false }),
                    }}
                    InputProps={{
                        startAdornment: <InputAdornment position="start">{t`Min`}</InputAdornment>,
                    }}
                />
                <SearchInput
                    placeholder={generatePlaceholder(column, 'max')}
                    value={String(maxValue ?? '')}
                    onChange={handleMaxChange}
                    debounceWait={50}
                    onKeyEnter={onClose}
                    onClear={() => handleMaxChange(undefined)}
                    style={{ backgroundColor: colorSystem.neutral.white, width: '148px' }}
                    formatter={{
                        converter: fieldConverterNumber,
                        validation: createNumericValidator({ required: false }),
                    }}
                    InputProps={{
                        startAdornment: <InputAdornment position="start">{t`Max`}</InputAdornment>,
                    }}
                />
            </Flexbox>
        );
    }

    throwErrorUnlessProduction(new Error(`Invalid filter value for in range filter: ${unsafeValue}`));
    return null;
}

export const FilterInNumberRange = FilterInRange;
export const FilterInMonetaryValueRange = FilterInRange;
