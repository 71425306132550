import { t } from '@lingui/macro';
import { validEmailRegex } from '@luminovo/commons';
import { colorSystem, FieldTextControlled, Flexbox, FormItem, Radio } from '@luminovo/design-system';
import { SupplierContactDTO } from '@luminovo/http-client';
import { FormControlLabel, RadioGroup, Typography } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { TransEnum } from '../../../../components/localization/TransEnum';
import { getIconForLanguage } from '../../../../utils/getIconForLanguage';
import { languageTranslations } from '../../../../utils/internationalisation';
import { typeSafeObjectKeys } from '../../../../utils/typingUtils';

type FromType = SupplierContactDTO;

export function FormItemFirstName() {
    const { control } = useFormContext<FromType>();

    return (
        <FormItem label={t`First name`} required={true} flex="100%">
            <FieldTextControlled
                FieldProps={{
                    placeholder: t`First name`,
                }}
                control={control}
                name={`first_name`}
                required
            />
        </FormItem>
    );
}

export function FormItemLastName() {
    const { control } = useFormContext<FromType>();

    return (
        <FormItem label={t`Last name`} required={true} flex="100%">
            <FieldTextControlled
                control={control}
                name={`last_name`}
                required
                FieldProps={{
                    placeholder: t`Last name`,
                }}
            />
        </FormItem>
    );
}

export function FormItemEmail({ existingSupplierContacts }: { existingSupplierContacts: SupplierContactDTO[] }) {
    const { control } = useFormContext<FromType>();

    return (
        <FormItem label={t`Email`} required={true}>
            <FieldTextControlled
                control={control}
                name={`email`}
                required
                FieldProps={{
                    placeholder: t`Email`,
                }}
                validate={(value) => {
                    const isPresent = typeof value === 'string' && value.length > 0;
                    if (!isPresent) {
                        return t`Required`;
                    }
                    if (existingSupplierContacts.some((contact) => contact.email === value)) {
                        return t`Email address already in use`;
                    }
                    if (!validEmailRegex.test(value)) {
                        return t`Invalid email address`;
                    }
                }}
            />
        </FormItem>
    );
}

export function FormItemUserLanguage() {
    const { control } = useFormContext<FromType>();

    return (
        <FormItem label={t`Preferred language`} required={true}>
            <Typography variant="subtitle1">{t`This information will be used to select the right email template`}</Typography>
            <Controller
                name={'user_language'}
                control={control}
                render={({ field, fieldState }): JSX.Element => (
                    <>
                        {fieldState.error?.message !== undefined && (
                            <Typography variant={'body2'} style={{ color: colorSystem.red[6] }}>
                                {fieldState.error?.message}
                            </Typography>
                        )}
                        <RadioGroup {...field}>
                            {typeSafeObjectKeys(languageTranslations).map((value, index) => {
                                return (
                                    <FormControlLabel
                                        value={value}
                                        key={index}
                                        control={<Radio style={{ margin: '8px' }} />}
                                        label={
                                            <Flexbox gap={8}>
                                                {getIconForLanguage(value)}
                                                <TransEnum text={value} translations={languageTranslations} />
                                            </Flexbox>
                                        }
                                    />
                                );
                            })}
                        </RadioGroup>
                    </>
                )}
            />
        </FormItem>
    );
}

export function FormItemPosition() {
    const { control } = useFormContext<FromType>();

    return (
        <FormItem label={t`Position`}>
            <FieldTextControlled control={control} name={`position`} />
        </FormItem>
    );
}
