import { t } from '@lingui/macro';
import { colorSystem, Text } from '@luminovo/design-system';
import { PCBV2 } from '@luminovo/http-client';
import { Box } from '@mui/material';
import React from 'react';
import { PcbCapabilityCheckWidget } from '../../components/PcbCapabilityCheckWidget';
import { PcbPreviewer } from '../../components/SVGPreview';
import { PcbSide } from '../../components/SVGPreview/PCBColorSchemas';

export function PcbRenderer({
    pcb,
    assemblyId,
    rfqId,
    isRfqEditable,
}: {
    pcb: PCBV2;
    assemblyId: string;
    rfqId: string;
    isRfqEditable: boolean;
}) {
    const [pcbSide, setPcbSide] = React.useState<PcbSide>('front');

    const handlePcbSideChange = () => {
        setPcbSide((side) => (side === 'front' ? 'rear' : 'front'));
    };

    return (
        <Box style={{ height: 'calc(100vh - 155px)' }} position={'relative'}>
            <PcbPreviewer
                assemblyId={assemblyId}
                rfqId={rfqId}
                pcb={pcb}
                pcbSide={pcbSide}
                onPcbSideChange={handlePcbSideChange}
            />
            <Text
                variant="body-small-semibold"
                style={{ position: 'absolute', left: 5, top: 5, color: colorSystem.neutral.white }}
                onClick={() => setPcbSide((side) => (side === 'front' ? 'rear' : 'front'))}
            >
                {pcbSide === 'front' ? t`Showing front` : t`Showing rear`}
            </Text>
            <PcbCapabilityCheckWidget pcb={pcb} rfqId={rfqId} assemblyId={assemblyId} isRfqEditable={isRfqEditable} />
        </Box>
    );
}
