import { t, Trans } from '@lingui/macro';
import { isAuthorized, Permission, usePermissions } from '@luminovo/auth';
import { isPresent, sortBy } from '@luminovo/commons';
import {
    colorSystem,
    DataTable,
    Flexbox,
    Message,
    RenderHeadProps,
    Row,
    Text,
    Tooltip,
    useDataTableState,
} from '@luminovo/design-system';
import {
    BomItemIssue,
    IpnFormAndFitFormPatchValues,
    isGenericFullPart,
    isOtsComponentFull,
    isOtsFullPart,
    PackageUserInput,
    RfqContext,
    UserType,
} from '@luminovo/http-client';
import { ErrorRounded } from '@mui/icons-material';
import { TableCell } from '@mui/material';
import { useCallback, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useUserType } from '../../../../components/contexts/CurrentUserDetailsContext';
import {
    CpnView,
    RenderDescription,
    RenderIpn,
    RenderManufacturer,
    RenderMpn,
} from '../../../../components/partColumns';
import { RenderDatasheet } from '../../../../components/partColumns/RenderDatasheet';
import { useGenericPartDetailsDrawer } from '../../../../components/partSpecificationCards/GenericPart/GenericPartCardDetails';
import { useIpnDetailsDrawer } from '../../../../components/partSpecificationCards/Ipn/useIpnDetailsDrawer';
import { useOtsPartDetailsDrawer } from '../../../../components/partSpecificationCards/OTSPart/useOtsPartDetailsDrawer';
import { useHttpMutation } from '../../../../resources/mutation/useHttpMutation';
import { FullPartWithApprovalStatus } from '../../../../resources/part/partFrontendTypes';
import { usePartPackages } from '../../../../resources/part/partHandler';
import { BomItemTableData } from '../../../Bom/components/ModuleTableData';
import { bomItemIssueMessage } from '../../../Bom/hooks/utils/bomIssues';
import { useShouldShowManufacturingWarning } from '../../../Manufacturing/shared/manufacturingUtils';
import { TableContainer } from '../AddParts/TableContainer';
import { BomItemFormState } from '../BomItemFormState';
import { RenderMounting } from './columns/RenderMounting';
import { RenderNumberOfPins } from './columns/RenderNumberOfPins';
import { RenderPackageName } from './columns/RenderPackageName';
import { ManufacturingDataContext } from './ManufacturingDataTypes';

type ManufacturingDataProps = {
    bomItem: BomItemTableData;
    isRfqEditable: boolean;
    rfqId: string;
    submitForm: () => void;
};

const sortBomItemPartsByType = (parts: FullPartWithApprovalStatus[]) => {
    return sortBy(parts, [
        (p) => {
            return p.type === 'Custom';
        },
        (p) => {
            return p.type === 'CustomComponent';
        },
        (p) => {
            return p.type === 'OffTheShelf';
        },
        (p) => {
            return p.type === 'Generic';
        },
        (p) => {
            return p.type === 'Ipn';
        },
    ]);
};

export const ManufacturingData = ({ bomItem, isRfqEditable, rfqId, submitForm }: ManufacturingDataProps) => {
    const rfqContext: RfqContext = useMemo(() => ({ type: 'WithinRfQ', rfq_id: rfqId }), [rfqId]);
    const { setValue } = useFormContext<BomItemFormState>();
    const toggleIgnorePackageNameMismatchWarning = () => {
        setValue('ignorePackageNameMismatch', !bomItem.ignorePackageNameMismatch, {
            shouldDirty: true,
            shouldValidate: true,
            shouldTouch: true,
        });
        submitForm();
    };
    const { permissions } = usePermissions();
    const userType = useUserType();
    const isActionButtonVisible = userType === UserType.Internal;
    const hasPackageNameOnlyMismatch = bomItem.issues.includes(BomItemIssue.PackageNameOnlyMismatch);
    const hasGeneralPackageMismatch = bomItem.issues.includes(BomItemIssue.PackageMismatch);

    return (
        <>
            {hasGeneralPackageMismatch && (
                <span>
                    <Message
                        title={bomItemIssueMessage(BomItemIssue.PackageMismatch)}
                        attention="high"
                        size="small"
                        variant="yellow"
                    />
                </span>
            )}
            {!hasPackageNameOnlyMismatch && !hasGeneralPackageMismatch && bomItem.ignorePackageNameMismatch && (
                <span>
                    <Message
                        title={`Ignored ${bomItemIssueMessage(BomItemIssue.PackageNameOnlyMismatch)}`}
                        attention="low"
                        message="The inconsistency has been ignored and is not considered further."
                        size="small"
                        variant="blue"
                        action={
                            isActionButtonVisible
                                ? {
                                      label: t`Reconsider inconsistency`,
                                      onClick: toggleIgnorePackageNameMismatchWarning,
                                  }
                                : undefined
                        }
                    />
                </span>
            )}
            {hasPackageNameOnlyMismatch && !hasGeneralPackageMismatch && !bomItem.ignorePackageNameMismatch && (
                <span>
                    <Message
                        title={bomItemIssueMessage(BomItemIssue.PackageNameOnlyMismatch)}
                        attention="high"
                        size="small"
                        variant="yellow"
                        action={
                            isActionButtonVisible
                                ? {
                                      label: t`Ignore`,
                                      onClick: toggleIgnorePackageNameMismatchWarning,
                                  }
                                : undefined
                        }
                    />
                </span>
            )}
            <ManufacturingDataTable
                bomItem={bomItem}
                isRfqEditable={isRfqEditable}
                rfqContext={rfqContext}
                rfqId={rfqId}
                permissions={permissions}
            />
        </>
    );
};

const isManufacturingDataFormValid = ({
    name: packageName,
    mounting,
    number_of_pins: numberOfPins,
}: PackageUserInput) => {
    const isOnlyNumberOfPinsDefined = isPresent(numberOfPins) && !isPresent(packageName) && !isPresent(mounting);

    const isOnlyNameIsDefined = !isPresent(numberOfPins) && isPresent(packageName) && !isPresent(mounting);

    return !isOnlyNumberOfPinsDefined && !isOnlyNameIsDefined;
};

const ManufacturingDataTable = ({
    bomItem,
    isRfqEditable,
    rfqContext,
    rfqId,
    permissions,
}: {
    bomItem: BomItemTableData;
    isRfqEditable: boolean;
    rfqContext: RfqContext;
    rfqId: string;
    permissions: Permission[];
}) => {
    const { openDrawer: openOtsDrawer } = useOtsPartDetailsDrawer();
    const { openDrawer: openIpnDrawer } = useIpnDetailsDrawer();
    const { openDrawer: openGenericPartDrawer } = useGenericPartDetailsDrawer();
    // Even without the 'edit:ots:part' permission, if the 'show_part_data_warnings' flag is enabled, the user should be able to edit the part.
    // In the backend, the 'edit:ots:part' permission is granted programmatically if the flag is true.
    let { shouldShowManufacturingWarning } = useShouldShowManufacturingWarning();
    const isAuthorizedToEditOtsPart = isAuthorized(permissions, ['edit:ots:part']) || shouldShowManufacturingWarning;

    const isAuthorizedToEditComponentSpecs =
        isAuthorized(permissions, ['edit:component_spec']) || isAuthorized(permissions, ['edit:ipn']);

    const { data: packageData = [] } = usePartPackages('user-selectable');
    const sortedParts: FullPartWithApprovalStatus[] = sortBomItemPartsByType(bomItem.parts);
    const shouldPackageMismatchWarningBeShown = bomItem.issues.includes(BomItemIssue.PackageMismatch);

    const { mutateAsync: saveOtsPart } = useHttpMutation('PATCH /parts/off-the-shelf/:partId', {
        snackbarMessage: null,
    });

    const { mutateAsync: saveOtsComponent } = useHttpMutation('PATCH /ipns/:id/form-and-fit', {
        snackbarMessage: null,
    });

    const onChangeOtsPart = useCallback(
        ({ name, mounting, number_of_pins, partId }: PackageUserInput & { partId: string }) => {
            if (!isManufacturingDataFormValid({ name, mounting, number_of_pins })) {
                return;
            }
            const requestBody = {
                package: {
                    name,
                    mounting,
                    number_of_pins,
                },
                rfq_context: 'WithinRfQ',
                rfq_id: rfqId,
            };

            saveOtsPart({
                pathParams: { partId },
                queryParams: { rfq_context: 'WithinRfQ', rfq_id: rfqId },
                requestBody,
            });
        },
        [saveOtsPart, rfqId],
    );

    const onChangeOtsComponent = useCallback(
        ({ name, mounting, number_of_pins, partId }: PackageUserInput & { partId: string }) => {
            if (!isManufacturingDataFormValid({ name, mounting, number_of_pins })) {
                return;
            }
            const requestBody: IpnFormAndFitFormPatchValues = {
                form_and_fit: {
                    name,
                    mounting,
                    number_of_pins,
                },
                rfq_context: 'WithinRfQ',
                rfq_id: rfqId,
            };

            saveOtsComponent({
                pathParams: { id: partId },
                requestBody,
            });
        },
        [saveOtsComponent, rfqId],
    );

    const openPartDrawer = useCallback(
        (fullPartWithApprovalStatus: FullPartWithApprovalStatus) => {
            const fullPart = fullPartWithApprovalStatus.part;
            if (isOtsFullPart(fullPart)) {
                return openOtsDrawer({ part: fullPart, rfqContext });
            }
            if (isOtsComponentFull(fullPart)) {
                return openIpnDrawer({ ipnId: fullPart.id, rfqContext });
            }
            if (isGenericFullPart(fullPart)) {
                return openGenericPartDrawer({
                    genericPart: fullPart.content,
                    mpnMatches: fullPart.matches,
                    isEditEnable: false,
                    onAddAlsoRemove: () => {},
                    rfqContext,
                });
            }
        },
        [openGenericPartDrawer, openIpnDrawer, openOtsDrawer, rfqContext],
    );
    const sharedContext: ManufacturingDataContext = {
        isRfqEditable,
        canEditOtsParts: isRfqEditable && isAuthorizedToEditOtsPart,
        canEditOtsComponents: isRfqEditable && isAuthorizedToEditComponentSpecs,
        rfqContext,
        issues: bomItem.issues,
        shouldPackageMismatchWarningBeShown,
        packageData,
        editOtsPartTooltip:
            isRfqEditable && !isAuthorizedToEditOtsPart
                ? t`You don't have permission to edit this off-the-shelf part.`
                : '',
        editComponentToolTip:
            isRfqEditable && !isAuthorizedToEditComponentSpecs
                ? t`You don't have permission to edit this component.`
                : '',
        onChangeOtsPart,
        onChangeOtsComponent,
        // we are passing this prop in the sharedContext instead of as a rowClick because we want to open the drawer only when specific cells are clicked.
        // ie: All cells apart from the package related cells
        openPartDrawer,
    };

    const tableState = useDataTableState({
        columns: [
            columnIpn,
            columnCpn,
            columnMpn,
            columnManufacturer,
            columnDescription,
            columnDatasheet,
            columnMounting,
            columnPackageName,
            columnNumberOfPins,
        ],
        items: sortedParts,
        persistenceId: 'manufacturing-data',
        sharedContext,
        paginationOptions: { showPagination: false, defaultRowsPerPage: 50 },
    });

    return (
        <DataTable
            tableState={tableState}
            size="large"
            stickyHeader={false}
            overrides={{ Container: TableContainer }}
            // just a hack, so that the row gets a pointer cursor
            onItemClick={() => {}}
        />
    );
};

const columnIpn = {
    id: `manufacturing-data-ipn`,
    label: <Trans>IPN</Trans>,
    render: ({ data }: Row<FullPartWithApprovalStatus>, sharedContext: ManufacturingDataContext): JSX.Element => {
        return (
            <RenderIpn
                part={data.part}
                onClick={() => {
                    sharedContext.openPartDrawer(data);
                }}
            />
        );
    },
};

const columnCpn = {
    id: `manufacturing-data-cpn-rev`,
    label: <Trans>CPN • Rev</Trans>,
    render: ({ data }: Row<FullPartWithApprovalStatus>, sharedContext: ManufacturingDataContext): JSX.Element => {
        return (
            <TableCell
                onClick={() => {
                    sharedContext.openPartDrawer(data);
                }}
            >
                <CpnView part={data.part} />
            </TableCell>
        );
    },
};

const columnMpn = {
    id: `manufacturing-mpn`,
    label: <Trans>MPN</Trans>,
    render: ({ data }: Row<FullPartWithApprovalStatus>, sharedContext: ManufacturingDataContext): JSX.Element => {
        return (
            <RenderMpn
                onClick={() => {
                    sharedContext.openPartDrawer(data);
                }}
                part={data.part}
            />
        );
    },
};

const columnManufacturer = {
    id: `manufacturing-manufacturer`,
    label: <Trans>Manufacturer</Trans>,
    render: ({ data }: Row<FullPartWithApprovalStatus>, sharedContext: ManufacturingDataContext): JSX.Element => {
        return (
            <RenderManufacturer
                onClick={() => {
                    sharedContext.openPartDrawer(data);
                }}
                part={data.part}
            />
        );
    },
};

const columnDescription = {
    id: `manufacturing-description`,
    label: <Trans>Description</Trans>,
    render: ({ data }: Row<FullPartWithApprovalStatus>, sharedContext: ManufacturingDataContext): JSX.Element => {
        return (
            <RenderDescription
                part={data.part}
                onClick={() => {
                    sharedContext.openPartDrawer(data);
                }}
            />
        );
    },
};

const columnDatasheet = {
    id: `manufacturing-datasheet`,
    label: '',
    render: ({ data }: Row<FullPartWithApprovalStatus>, sharedContext: ManufacturingDataContext): JSX.Element => {
        return (
            <RenderDatasheet
                part={data.part}
                onClick={() => {
                    sharedContext.openPartDrawer(data);
                }}
            />
        );
    },
};

const columnMounting = {
    id: `mounting`,
    label: '',
    renderHead: ({ sharedContext }: RenderHeadProps<ManufacturingDataContext>) => (
        <TableCell>
            <Flexbox alignItems="center" gap="4px">
                <Text variant="h5" color={colorSystem.neutral[7]}>
                    <Trans>Mounting</Trans>
                </Text>{' '}
                {sharedContext.issues.includes(BomItemIssue.MountingMissing) &&
                    !sharedContext.shouldPackageMismatchWarningBeShown && (
                        <WarningIconWithTooltip
                            tooltip={t`${bomItemIssueMessage(
                                BomItemIssue.MountingMissing,
                            )}. Please ensure that at least one part has both mounting and pins specified.`}
                        />
                    )}
            </Flexbox>
        </TableCell>
    ),
    render: RenderMounting,
};

const columnPackageName = {
    id: `package-name`,
    label: '',
    renderHead: ({ sharedContext }: RenderHeadProps<ManufacturingDataContext>) => (
        <TableCell>
            <Flexbox alignItems="center" gap="4px">
                <Text variant="h5" color={colorSystem.neutral[7]}>
                    <Trans>Package</Trans>
                </Text>{' '}
                {sharedContext.issues.includes(BomItemIssue.PackageNameMissing) &&
                    !sharedContext.shouldPackageMismatchWarningBeShown && (
                        <WarningIconWithTooltip tooltip={bomItemIssueMessage(BomItemIssue.PackageNameMissing)} />
                    )}
            </Flexbox>
        </TableCell>
    ),
    render: RenderPackageName,
};

const columnNumberOfPins = {
    id: `number-of-pins`,
    label: '',
    renderHead: ({ sharedContext }: RenderHeadProps<ManufacturingDataContext>) => (
        <TableCell>
            <Flexbox alignItems="center" gap="4px">
                <Text variant="h5" color={colorSystem.neutral[7]}>
                    <Trans>Pins</Trans>
                </Text>{' '}
                {sharedContext.issues.includes(BomItemIssue.PinsMissing) &&
                    !sharedContext.shouldPackageMismatchWarningBeShown && (
                        <WarningIconWithTooltip
                            tooltip={t`${bomItemIssueMessage(
                                BomItemIssue.PinsMissing,
                            )}. Please ensure that at least one part has both mounting and pins specified.`}
                        />
                    )}
            </Flexbox>
        </TableCell>
    ),
    render: RenderNumberOfPins,
};

const WarningIconWithTooltip = ({ tooltip }: { tooltip?: string }) => {
    return (
        <Tooltip title={tooltip ? tooltip : ''} placement="top" arrow>
            <ErrorRounded style={{ color: colorSystem.yellow[6], fontSize: '16px' }} />
        </Tooltip>
    );
};
