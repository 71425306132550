import { Flexbox, RightBoxDrawer, SecondaryIconButton, Text } from '@luminovo/design-system';
import { PcbAttribute, Region } from '@luminovo/pdf-extractor';
import { Close } from '@mui/icons-material';
import { Box } from '@mui/material';
import { useDrawerContext } from '../../../../components/contexts/ModalContext';
import { PcbPdfViewer } from './PcbPdfViewer';

type SelectedPdfType = {
    filePath: string;
    regions: Array<Region<PcbAttribute>>;
};

export const getFileNameFromPath = (path: string) => {
    const regex = /\/([^/?]+\.pdf)/;
    const match = regex.exec(path);
    if (match && match[1]) {
        return match[1];
    }
    return '';
};

const PdfViewerDrawer = ({ onClose, pdf }: { onClose: () => void; pdf: SelectedPdfType }) => {
    const fileName = getFileNameFromPath(pdf.filePath);
    return (
        <RightBoxDrawer onClose={onClose}>
            <Flexbox
                padding={'16px'}
                flexDirection={'column'}
                gap={'20px'}
                style={{
                    width: 'calc(100vw - 595px)',
                    maxWidth: 'calc(100vw - 595px)',
                }}
            >
                <SecondaryIconButton size="small" onClick={onClose}>
                    <Close fontSize="inherit" />
                </SecondaryIconButton>
                <Text variant="h2">{fileName}</Text>
                <Box
                    style={{
                        maxHeight: 'calc(100vh - 200px)',
                        width: '100%',
                        overflow: 'auto',
                    }}
                >
                    <PcbPdfViewer pathToPdf={pdf.filePath} regions={pdf.regions} />
                </Box>
            </Flexbox>
        </RightBoxDrawer>
    );
};

export const usePdfViewerDrawer = () => {
    const { setDrawer, closeDrawer } = useDrawerContext();

    return {
        openDrawer: ({ selectedPdf }: { selectedPdf: SelectedPdfType }) => {
            setDrawer(<PdfViewerDrawer pdf={selectedPdf} onClose={closeDrawer} />);
        },
    };
};
