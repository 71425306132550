import { t } from '@lingui/macro';
import { assertUnreachable } from '@luminovo/commons';
import { Flexbox, Tag, Text, Tooltip, colorSystem } from '@luminovo/design-system';
import {
    EmsPartNumberPartMatchFullPart,
    FullPart,
    PartMatchTypeFullResponse,
    isCustomComponentFull,
    isCustomFullPart,
    isGenericFullPart,
    isOtsComponentFull,
    isOtsFullPart,
} from '@luminovo/http-client';
import { PreferredManufacturerTag } from '@luminovo/sourcing-core';
import { TableCell } from './TableCell';

const RenderOtsComponentManufacturerMatch = ({
    match,
    otsComponentState,
}: {
    match: PartMatchTypeFullResponse;
    otsComponentState?: 'Active' | 'Removed';
}) => {
    const { type, data: partData } = match;
    if (type === 'Generic') {
        return (
            <Tag
                color="neutral"
                attention="low"
                label={t`Generic`}
                style={{ color: otsComponentState === 'Removed' ? colorSystem.neutral[6] : colorSystem.neutral[8] }}
            />
        );
    }
    if (type === 'OffTheShelf') {
        return (
            <Flexbox alignItems="center" gap="4px">
                <Text
                    variant="body-small"
                    style={{ color: otsComponentState === 'Removed' ? colorSystem.neutral[6] : colorSystem.neutral[9] }}
                >
                    {partData.manufacturer.name}
                </Text>
                {partData.is_manufacturer_preferred && <PreferredManufacturerTag />}
            </Flexbox>
        );
    }
    assertUnreachable(type);
};

const RenderOtsComponentManufacturerMatches = ({
    matches,
    otsComponentState,
    isManufacturerPreferred,
}: {
    matches: EmsPartNumberPartMatchFullPart[];
    otsComponentState: 'Active' | 'Removed';
    isManufacturerPreferred: boolean;
}) => {
    if (matches.length === 0) {
        return (
            <Tag
                color="yellow"
                label={t`No linked parts`}
                attention="low"
                style={{ color: otsComponentState === 'Removed' ? colorSystem.neutral[6] : colorSystem.yellow[7] }}
            />
        );
    }
    if (matches.length === 1) {
        return <RenderOtsComponentManufacturerMatch match={matches[0].part} otsComponentState={otsComponentState} />;
    }
    return (
        <Tooltip
            variant="white"
            arrow
            title={
                <Flexbox flexDirection="column" padding="4px" gap="4px">
                    {matches.map((match, i) => {
                        return (
                            <span key={i}>
                                <RenderOtsComponentManufacturerMatch match={match.part} />
                            </span>
                        );
                    })}
                </Flexbox>
            }
        >
            <span>
                <Flexbox alignItems="center" gap="4px">
                    <Tag
                        color="neutral"
                        attention="low"
                        label={t`${matches.length} manufacturers`}
                        style={{
                            color: otsComponentState === 'Removed' ? colorSystem.neutral[6] : colorSystem.neutral[8],
                        }}
                    />
                    {isManufacturerPreferred && <PreferredManufacturerTag />}
                </Flexbox>
            </span>
        </Tooltip>
    );
};

export const RenderManufacturer = ({ part, onClick }: { part: FullPart; onClick?: () => void }): JSX.Element => {
    if (isOtsFullPart(part)) {
        return (
            <TableCell onClick={onClick}>
                <Flexbox alignItems="center" gap="4px">
                    {part.manufacturer.name} {part.is_manufacturer_preferred && <PreferredManufacturerTag />}
                </Flexbox>
            </TableCell>
        );
    }
    if (isGenericFullPart(part)) {
        return (
            <TableCell onClick={onClick}>
                <Tag color="neutral" attention="low" label={t`Generic`} />
            </TableCell>
        );
    }
    if (isOtsComponentFull(part)) {
        return (
            <TableCell isComponentRemoved={part.state === 'Removed'} onClick={onClick}>
                <RenderOtsComponentManufacturerMatches
                    matches={part.matches}
                    otsComponentState={part.state}
                    isManufacturerPreferred={part.is_manufacturer_preferred}
                />
            </TableCell>
        );
    }
    if (isCustomFullPart(part)) {
        return <TableCell onClick={onClick} />;
    }
    if (isCustomComponentFull(part)) {
        return (
            <TableCell onClick={onClick} isComponentRemoved={part.state === 'Removed'}>
                -
            </TableCell>
        );
    }
    assertUnreachable(part);
};
