import { Trans } from '@lingui/macro';
import {
    colorSystem,
    Flexbox,
    PrimaryButton,
    SecondaryButton,
    StickyLayout,
    Text,
    Toolbar,
} from '@luminovo/design-system';
import { Edit as EditIcon, InfoOutlined } from '@mui/icons-material';
import { ButtonProps } from '@mui/material';
import { useFormState } from 'react-hook-form';
import { SubmitButton } from '../../../components/formLayouts/SubmitButton';

export type FormActionToolbarProps = {
    pageTitle: string;
    formId?: string;
    disabled?: boolean;
    isEditing: boolean;
    isDirty?: boolean;
    isEditable?: boolean;
    submitButtonOptions?: ButtonProps;
    editButtonOptions?: ButtonProps;
    cancelButtonOptions?: ButtonProps;
};

/**
 * Helps with the save and cancel actions of all PCB forms
 * If is editing, shows the cancel and save buttons
 * else, shows the edit button
 *
 * Must be wrapped in a FormProvider
 */
export const FormActionToolbar = ({
    pageTitle,
    formId,
    disabled,
    isEditing,
    isDirty: isDirtyManual,
    isEditable,
    submitButtonOptions,
    cancelButtonOptions,
    editButtonOptions,
}: FormActionToolbarProps) => {
    const { isSubmitting, isDirty: isDirtyFormState } = useFormState();
    // The point of moving this here is to get the isDirty state from the form state
    // while we refactor the FileMatcher and DrillManagement to use the form we could pass
    // the isDirty state manually
    // TODO: remove this when the mentioned components are using useForm properly
    const isDirty = isDirtyManual ?? isDirtyFormState;
    return (
        <StickyLayout style={{ maxWidth: '100vw', top: 0, zIndex: 3 }}>
            <Toolbar
                breadcrumbs={[]}
                left={
                    <Flexbox gap={'16px'} alignItems={'center'}>
                        <Text variant="h3">{pageTitle}</Text>
                        {isDirty && (
                            <Text
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '8px',
                                    color: colorSystem.neutral[9],
                                }}
                            >
                                <InfoOutlined fontSize="small" style={{ color: colorSystem.neutral[7] }} />
                                <Trans>Unsaved changes</Trans>
                            </Text>
                        )}
                    </Flexbox>
                }
            >
                {isEditable && (
                    <>
                        {isEditing ? (
                            <>
                                <SecondaryButton
                                    size="medium"
                                    onClick={cancelButtonOptions?.onClick}
                                    disabled={disabled || isSubmitting || cancelButtonOptions?.disabled}
                                    id={cancelButtonOptions?.id}
                                >
                                    <Trans>Cancel</Trans>
                                </SecondaryButton>
                                <SubmitButton
                                    form={formId}
                                    size="medium"
                                    onClick={submitButtonOptions?.onClick}
                                    disabled={submitButtonOptions?.disabled}
                                    id={submitButtonOptions?.id}
                                />
                            </>
                        ) : (
                            <PrimaryButton
                                size="medium"
                                onClick={editButtonOptions?.onClick}
                                disabled={disabled || isSubmitting || editButtonOptions?.disabled}
                                id={editButtonOptions?.id}
                            >
                                <EditIcon
                                    style={{
                                        fontSize: 16,
                                        marginRight: 4,
                                    }}
                                />
                                <Trans>Edit</Trans>
                            </PrimaryButton>
                        )}
                    </>
                )}
            </Toolbar>
        </StickyLayout>
    );
};
