import { formatDecimal } from '@luminovo/commons';
import { Flexbox, Text, Tooltip, colorSystem } from '@luminovo/design-system';
import { SourcingScenarioDTO } from '@luminovo/http-client';
import { Skeleton } from '@mui/lab';
import { withErrorBoundary } from '@sentry/react';
import { useAssembly } from '../../resources/assembly/assemblyHandler';
import { withSuspense } from '../withSuspense';

interface LabelScenarioOrderSizeProps {
    sourcingScenario: SourcingScenarioDTO;
}

export function LabelScenarioOrderSize({ sourcingScenario }: LabelScenarioOrderSizeProps): JSX.Element {
    const orderSize = sourcingScenario.assembly_quantities.items
        .map((item) => item.quantity)
        .reduce((a, b) => a + b, 0);

    const quantities = sourcingScenario.assembly_quantities.items;

    const tooltip = () => {
        return (
            <Flexbox flexDirection={'column'} gap={'4px'}>
                {quantities.map((item, idx) => {
                    return (
                        <Flexbox key={idx} justifyContent={'space-between'} gap={'24px'}>
                            <AssemblyLabelWithErrorBoundary assembly={item.assembly} />
                            <Text>{formatDecimal(item.quantity)}</Text>
                        </Flexbox>
                    );
                })}
                <Flexbox
                    justifyContent={'space-between'}
                    gap={'24px'}
                    borderTop={`1px solid ${colorSystem.neutral[2]}`}
                >
                    <Text variant="body-semibold">Total</Text>
                    <Text variant="body-semibold">{formatDecimal(orderSize)}</Text>
                </Flexbox>
            </Flexbox>
        );
    };

    if (quantities.length === 1) {
        return <span>{formatDecimal(orderSize)}</span>;
    }

    return (
        <Tooltip variant="white" title={quantities.length > 0 ? tooltip() : ''}>
            <span>
                {formatDecimal(orderSize)}
                <Text color={colorSystem.neutral[6]}>*</Text>
            </span>
        </Tooltip>
    );
}

const AssemblyLabel = withSuspense(
    function AssemblyLabel({ assembly }: { assembly: string }): JSX.Element {
        const { data: assemblyDto } = useAssembly(assembly);
        return <Text>{assemblyDto?.designator}</Text>;
    },
    {
        fallback: <Skeleton width="20px" />,
    },
);

const AssemblyLabelWithErrorBoundary = withErrorBoundary(AssemblyLabel, {
    fallback: <Text color={colorSystem.neutral[6]}>Unknown assembly</Text>,
});
