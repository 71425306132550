import { Trans } from '@lingui/macro';
import { Flexbox, Select, Text } from '@luminovo/design-system';
import { BomScreeningSheet, ScreenerHandlerOutput, SingleOriginalExcelRow } from '@luminovo/http-client';
import { InfoOutlined } from '@mui/icons-material';
import { Box, MenuItem, SelectChangeEvent, styled } from '@mui/material';
import { BomImporterIssue, IssueNameEnum, Level } from '../../../resources/bomImporter/bomImporterIssuesEnum';
import { colorSystem } from '../../../themes';
import { throwErrorUnlessProduction } from '../../../utils/customConsole';
import { id } from '../../../utils/ids';
import { BomIssueItem, ValidationIssueItem } from './bomImporterDialogComponents';

export function GeneralInformation({
    bomScreeningOutput,
    sheetIndex,
    validationErrors,
    switchSheet,
}: {
    bomScreeningOutput: ScreenerHandlerOutput;
    sheetIndex: number;
    validationErrors: { message: string; level: Level }[];
    switchSheet: (sheetIndex: number) => void;
}) {
    const bomScreeningSheet: BomScreeningSheet = bomScreeningOutput.sheets[sheetIndex];
    const excelLines = bomScreeningSheet.excel_lines;
    const numberOfLines = excelLines.length;
    const numberOfLinesTag: BomImporterIssue = {
        name: IssueNameEnum.NUMBER_OF_LINES,
        content: {
            numberOfLines: numberOfLines,
        },
        level: Level.Notification,
    };

    const handleChange = (event: SelectChangeEvent<unknown>) => {
        if (typeof event.target.value !== 'number') {
            return throwErrorUnlessProduction('selected sheet index should be a number');
        }
        switchSheet(event.target.value);
    };

    const sheets = bomScreeningOutput.sheets.map((sheet) => sheet.sheet_name);
    const duplicatedDesignatorsIssue = consolidateDuplicatedDesignators(excelLines);

    return (
        <Container id={id('bom_importer/box_header')}>
            <Text variant={'h4'} style={{ marginBottom: '8px' }}>
                <Trans>
                    Importing <i>{bomScreeningOutput.file_name}</i>
                </Trans>
            </Text>
            {sheets.length > 1 ? (
                <Flexbox flexDirection={'column'} gap={8} marginBottom="8px">
                    <Text>
                        <Trans>Please select the BOM sheet below:</Trans>
                    </Text>
                    <StyledSelect
                        id="sheetSelect"
                        onChange={handleChange}
                        variant="outlined"
                        value={sheetIndex}
                        style={{ width: '350px' }}
                    >
                        {sheets.map((sheet: string, i: number) => (
                            <MenuItem key={i} value={i}>
                                {sheet}
                            </MenuItem>
                        ))}
                    </StyledSelect>
                </Flexbox>
            ) : (
                <></>
            )}
            <Flexbox alignItems={'center'} gap={8}>
                <InfoOutlined style={{ color: colorSystem.neutral[6] }} />
                <Text>
                    <Trans>
                        We automatically tagged some columns in your BOM. Please make sure the column mapping below is
                        accurate.
                    </Trans>
                </Text>
            </Flexbox>
            <BomIssueItem issue={numberOfLinesTag} />
            {bomScreeningSheet.global_issues_response.map((issue, i) => {
                return <BomIssueItem key={`globalIssues:${i}`} issue={issue} />;
            })}
            {validationErrors.map((error, i) => {
                return (
                    <ValidationIssueItem key={`validationErrors:${i}`} message={error.message} level={error.level} />
                );
            })}
            {duplicatedDesignatorsIssue && <BomIssueItem issue={duplicatedDesignatorsIssue} />}
        </Container>
    );
}

export const consolidateDuplicatedDesignators = (excelRows: SingleOriginalExcelRow[]): BomImporterIssue | undefined => {
    const duplicatedDesignatorsIssues = excelRows
        .flatMap((row) => row.issues)
        .filter((issue) => issue.name === IssueNameEnum.DUPLICATE_DESIGNATORS_FOUND);

    const duplicatedDesignators = duplicatedDesignatorsIssues
        .map((issue) => {
            if (issue.name === IssueNameEnum.DUPLICATE_DESIGNATORS_FOUND) {
                return issue.content.duplicatedDesignators;
            } else {
                return [];
            }
        })
        .flat();
    if (
        duplicatedDesignatorsIssues.length > 0 &&
        duplicatedDesignatorsIssues[0].name === IssueNameEnum.DUPLICATE_DESIGNATORS_FOUND
    ) {
        const issue = duplicatedDesignatorsIssues[0];
        issue.content.duplicatedDesignators = Array.from(new Set(duplicatedDesignators));
        return issue;
    }

    return undefined;
};

const Container = styled(Box)({
    position: 'sticky',
    zIndex: 2,
    left: 0,
    background: colorSystem.neutral.white,
    border: `1px solid ${colorSystem.neutral[2]}`,
    padding: 16,
    paddingRight: 32,
    borderRadius: 8,
    marginRight: 32,
    display: 'flex',
    flexDirection: 'column',
    gap: 12,
});

const StyledSelect = styled(Select)({
    height: '32px',
    boxSizing: 'border-box',
    fontSize: '12px',
    '& .MuiSelect-select:focus': {
        background: 'none',
    },
});
