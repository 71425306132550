import type { ParseResult, Parser } from '../types';

export interface Opts {}

export const parseDateString: Parser<string, Opts> = async function ([value], opts): Promise<ParseResult<string>> {
    const num = Date.parse(value);
    if (isNaN(num)) {
        return {
            status: 'error',
            message: `Unable to parse date`,
        };
    }

    const date = new Date(num);

    return {
        status: 'done',
        value: { id: date.toISOString(), label: date.toLocaleDateString() },
    };
};
