import { Trans } from '@lingui/macro';
import { id } from '@luminovo/commons';
import { colorSystem, Flexbox, PrimaryButton, Tag } from '@luminovo/design-system';
import { AssemblyTypeEnum } from '@luminovo/http-client';
import { Typography } from '@mui/material';
import React from 'react';
import { transEnum } from '../../../../components/localization/TransEnum';
import { assemblyTypePublicTranslations } from '../../../../resources/rfq/i18n';
import { useAddManufacturingScenarioDialog } from '../../ManufacturingScenario/ManufacturingScenarioForm/useAddManufacturingScenarioDialog';
import { DividerWithMargins } from '../assemblyOverviewCardComponents';
import { AssemblyPath } from './components/AssemblyPath';
import { StyledBoxGrid } from './components/StyledBoxGrid';

export interface AssemblyOverviewBlankCardProps {
    rfqId: string;
    designator: string;
    assemblyType: AssemblyTypeEnum;
    assemblyId: string;
    parentAssemblies: string[];
    manufacturingAssemblyDetailsId: string | undefined;
}

export const AssemblyOverviewBlankCard: React.FunctionComponent<AssemblyOverviewBlankCardProps> = ({
    rfqId,
    designator,
    assemblyType,
    assemblyId,
}: AssemblyOverviewBlankCardProps): JSX.Element => {
    const { openDialog } = useAddManufacturingScenarioDialog({ rfqId, assemblyId });

    return (
        <StyledBoxGrid>
            <Flexbox flexDirection="column">
                <Flexbox
                    alignItems={'center'}
                    style={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        maxWidth: '100%',
                    }}
                >
                    <Typography
                        variant={'h2'}
                        color="textSecondary"
                        style={{
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            maxWidth: '100%',
                        }}
                    >
                        {designator}
                    </Typography>
                    <Tag
                        color={'neutral'}
                        label={transEnum(assemblyType, assemblyTypePublicTranslations)}
                        style={{ marginLeft: '8px' }}
                    />
                </Flexbox>
                <AssemblyPath assemblyId={assemblyId} />
            </Flexbox>
            <DividerWithMargins />
            <Flexbox flexDirection={'column'}>
                <Typography variant={'body1'} style={{ color: colorSystem.neutral[6] }}>
                    <Trans>No manufacturing information.</Trans>
                </Typography>
            </Flexbox>
            <Flexbox>
                <PrimaryButton
                    style={{ marginLeft: 'auto' }}
                    onClick={openDialog}
                    size="medium"
                    id={id('manufacturing_scenario/button_create_manufacturing_scenario')}
                >
                    <Trans>Create manufacturing scenario</Trans>
                </PrimaryButton>
            </Flexbox>
        </StyledBoxGrid>
    );
};
