/* eslint-disable camelcase */

import * as r from 'runtypes';
import { runtypeFromEnum } from '../../utils/typingUtils';
import { AdditionalServicesGetResponseRuntype } from '../additionalServices';
import {
    AssemblyIndustryRuntype,
    AssemblyTypeRuntype,
    BomItemApprovalStatus,
    BomItemIssue,
} from '../assembly/assemblyBackendTypes';
import { CurrencyRuntype, MonetaryValueBackendRuntype } from '../backendTypes';
import { DemandScenarioRuntype, RfqCreationDemandScenarioRuntype } from '../demandScenario/demandScenarioBackendTypes';
import { UserDTORuntype } from '../user';

export enum RfqStatus {
    /**
     * The initial state.
     */
    RequestInDraft = 'RequestInDraft',
    /**
     * When a quotation is requested by the OEM.
     */
    QuotationInProgress = 'QuotationInProgress',
    /**
     * When the EMS uploads a quotation.
     */
    QuotationAvailable = 'QuotationAvailable',
    /**
     * When the customer has placed an order.
     */
    OrderPlaced = 'OrderPlaced',
    /**
     * When the EMS "won" the RfQ.
     */
    OrderConfirmed = 'OrderConfirmed',
    /**
     * When the payment of an order has failed.
     */
    PaymentFailed = 'PaymentFailed',
    /**
     * When the EMS is in the process of procuring the parts from the suppliers.
     */
    OrderInProcurement = 'OrderInProcurement',
    /**
     * When the EMS has the assembly in production.
     */
    OrderInProduction = 'OrderInProduction',
    /**
     * Terminal state.
     * When the EMS has shipped the assembly.
     */
    OrderShipped = 'OrderShipped',
    /**
     * Terminal state. No bid.
     */
    NoQuotation = 'NoQuotation',
    /**
     * Terminal state.
     * When the EMS "lost" the RfQ.
     */
    NoOrder = 'NoOrder',
}
export const RfqStatusRuntype = runtypeFromEnum(RfqStatus);

/**
 * Returns true if the given status indicates that work needs to be done on the RfQ.
 *
 * This is used to determine if the RfQ should be included in the "work in progress" chart.
 */
export function isWorkInProgress(status: RfqStatus): boolean {
    return (
        status === RfqStatus.RequestInDraft ||
        status === RfqStatus.QuotationInProgress ||
        status === RfqStatus.QuotationAvailable ||
        status === RfqStatus.OrderPlaced ||
        status === RfqStatus.OrderInProduction ||
        status === RfqStatus.OrderInProcurement
    );
}

export const WorkflowTypeRuntype = r.Union(r.Literal('Automatic'), r.Literal('Manual'), r.Literal('DontShow'));
export type WorkflowType = r.Static<typeof WorkflowTypeRuntype>;

export interface VolumeEstimate extends r.Static<typeof VolumeEstimateRuntype> {}
const VolumeEstimateRuntype = r.Record({
    lower: r.String.nullable(),
    upper: r.String.nullable(),
    currency: CurrencyRuntype,
});

export interface EMSRfqDetails extends r.Static<typeof EMSRfqDetailsRuntype> {}
const EMSRfqDetailsRuntype = r.Record({
    volume_estimate: r.Null.Or(VolumeEstimateRuntype),
});

export const EmsRfqUpdateRuntype = r.Record({
    name: r.String.optional(),
    internal_number: r.String.nullable().optional(),
    due_date: r.String.nullable().optional(),
    currency: CurrencyRuntype.optional(),
    volume_estimate: VolumeEstimateRuntype.optional(),
    status: RfqStatusRuntype.optional(),
    shipping_tracking_link: r.String.nullable().optional(),
    notes: r.String.nullable().optional(),
});

const ExistingOrderSizeRuntype = r.Record({
    sourcing_scenario_id: r.String,
    quantity: r.Number,
    desired_date: r.String.nullable(),
});

const NewOrderSizeRuntype = r.Record({
    quantity: r.Number,
    desired_date: r.String.nullable(),
});

export const CustomerOrderSizeRuntype = r
    .Record({
        type: r.Literal('Existing'),
        data: ExistingOrderSizeRuntype,
    })
    .Or(
        r.Record({
            type: r.Literal('New'),
            data: NewOrderSizeRuntype,
        }),
    );
export type CustomerOrderSizeDTO = r.Static<typeof CustomerOrderSizeRuntype>;

export const CustomerRfqUpdateRuntype = r.Record({
    name: r.String.optional(),
    industry: AssemblyIndustryRuntype.optional(),
    order_sizes: r.Array(CustomerOrderSizeRuntype).optional(),
    additional_services: r.Array(r.String),
});

export const RfqUpdateRuntype = r
    .Record({
        type: r.Literal('Ems'),
        data: EmsRfqUpdateRuntype,
    })
    .Or(
        r.Record({
            type: r.Literal('Customer'),
            data: CustomerRfqUpdateRuntype,
        }),
    );

export interface CustomerRfqUpdateDTO extends r.Static<typeof CustomerRfqUpdateRuntype> {}
export interface EmsRfqUpdateDTO extends r.Static<typeof EmsRfqUpdateRuntype> {}

export const SalesOrderOverviewRuntype = r.Record({
    id: r.String,
    estimated_lead_time_in_days: r.Number.nullable(),
    order_size: r.Number,
    total_price: MonetaryValueBackendRuntype,
});

export const AdditionalServicesOverviewRuntype = r.Record({
    id: r.String,
    name_en: r.String,
    name_de: r.String,
});

export interface RfqListItemDTO extends r.Static<typeof RfqListItemRuntype> {}
export const RfqListItemRuntype = r.Record({
    id: r.String,
    name: r.String,
    customer_name: r.String,
    customer_number: r.String.nullable(),
    ems_internal_number: r.String.nullable(),
    status: RfqStatusRuntype,
    creation_date: r.String,
    due_date: r.String.nullable(),
    ems_rfq_details: EMSRfqDetailsRuntype,
    created_by: UserDTORuntype.nullable(),
    contributors: r.Array(
        r.Record({
            name: r.String,
            email: r.String,
        }),
    ),
    notes: r.String.nullable(),
    assembly_types: r.Array(AssemblyTypeRuntype),
    is_archived: r.Boolean,
    currency: CurrencyRuntype,
    additional_services: r.Array(AdditionalServicesGetResponseRuntype.pick('id', 'name_en', 'name_de')),
    sales_order: SalesOrderOverviewRuntype.nullable(),
});

export interface RfqAssemblyDTO extends r.Static<typeof RfqAssemblyRuntype> {}
export const RfqAssemblyRuntype = r.Record({
    id: r.String,
    designator: r.String,
    assembly_type: AssemblyTypeRuntype,
});

export interface RfqDTO extends r.Static<typeof RfqDTORuntype> {}

export const RfqDTORuntype = r.Record({
    creation_date: r.String,
    due_date: r.String.nullable(),
    ems_rfq_details: EMSRfqDetailsRuntype,
    id: r.String,
    name: r.String,
    customer: r.String,
    top_level_assemblies: r.Array(RfqAssemblyRuntype),
    sourcing_scenarios: r.Array(r.String),
    demand_scenarios: r.Array(DemandScenarioRuntype),
    status: RfqStatusRuntype,
    shipping_tracking_link: r.String.nullable().optional(),
    ems_internal_number: r.String.nullable(),
    design_items_count: r.Number,
    assemblies_count: r.Number,
    assemblies_types: r.Array(AssemblyTypeRuntype),
    created_by: r.String.nullable(),
    currency: CurrencyRuntype,
    is_archived: r.Boolean,
    workflow_type: WorkflowTypeRuntype,
    industry: AssemblyIndustryRuntype,
    notes: r.String.nullable(),
});

export const CustomerIdInfoRuntype = r.Record({
    type: r.Literal('ExistingCustomer').Or(r.Literal('NewCustomer')),
    data: r.String,
});

export type CustomerInfoDTO = r.Static<typeof CustomerInfoRuntype>;
export const CustomerInfoRuntype = r.Record({
    customer: CustomerIdInfoRuntype,
});

export type AssembliesDTO = r.Static<typeof AssembliesRuntype>;
export const AssembliesRuntype = r.Array(
    r.Record({
        name: r.String,
        assemblyType: AssemblyTypeRuntype,
    }),
);

export type AssemblyInfoDTO = r.Static<typeof AssemblyInfoRuntype>;
export const AssemblyInfoRuntype = r.Record({
    industry: AssemblyIndustryRuntype,
    assemblies: AssembliesRuntype,
});

export type RfqInfoDTO = r.Static<typeof RfqInfoRuntype>;
export const RfqInfoRuntype = r.Record({
    name: r.String,
    emsInternalNumber: r.String.Or(r.Undefined),
    contributors: r.Array(r.String),
    dueDate: r.String.Or(r.Undefined),
    currency: CurrencyRuntype,
    volumeEstimate: VolumeEstimateRuntype.Or(r.Undefined),
    comment: r.String.nullable(),
});

export type RfqInputDTO = r.Static<typeof RfqInputDTORuntype>;
export const RfqInputDTORuntype = r.Record({
    customer: CustomerInfoRuntype,
    assembly: AssemblyInfoRuntype,
    rfq: RfqInfoRuntype,
    additional_service_ids: r.Array(r.String),
    demand_scenarios: r.Array(RfqCreationDemandScenarioRuntype),
});

export type UsedInAssemblyData = r.Static<typeof UsedInAssemblyDataRuntype>;
const UsedInAssemblyDataRuntype = r.Record({
    id: r.String,
    designator: r.String,
    rfq: r.Record({
        id: r.String,
        name: r.String,
        status: RfqStatusRuntype,
        creation_date: r.String,
    }),
    customer: r.Null.Or(r.Record({ id: r.String, name: r.String, number: r.String.optional() })),
    initial_matched_bom_item: r.Record({ ids: r.Array(r.String) }),
});

export type UsedInResponse = r.Static<typeof UsedInResponseRuntype>;
export const UsedInResponseRuntype = r.Dictionary(r.Array(UsedInAssemblyDataRuntype), r.String);

const BomIssueInfoRuntype = r.Record({
    approval_status: runtypeFromEnum(BomItemApprovalStatus),
    design_item_ids: r.Array(r.String),
    count: r.Number,
});
const BomIssuesRuntype = r.Dictionary(BomIssueInfoRuntype, runtypeFromEnum(BomItemIssue));
export type BomIssues = r.Static<typeof BomIssuesRuntype>;
export type BomIssueInfo = r.Static<typeof BomIssueInfoRuntype>;

const PendingStatusRuntype = r.Record({ type: r.Literal('Pending') });
const DoneStatusRuntype = r.Record({ type: r.Literal('Done') });
const BomProgressRuntype = r.Record({
    type: r.Literal('InProgress'),
    issues: BomIssuesRuntype,
});

const BomStatusRuntype = PendingStatusRuntype.Or(DoneStatusRuntype).Or(BomProgressRuntype);
const RequirementRuntype = r.Literal('Required').Or(r.Literal('Optional')).Or(r.Literal('DontShow'));
export type CustomerPortalRequirement = r.Static<typeof RequirementRuntype>;
const BomStateRuntype = r.Record({
    status: BomStatusRuntype,
    requirement: RequirementRuntype,
});
export type BomState = r.Static<typeof BomStateRuntype>;

const PcbAnalysisInProgressIssueRuntype = r.Record({
    type: r.Literal('IsAnalyzing'),
    progress: r.Number,
});

const OtherPcbIssuesRuntype = r.Record({
    type: r.Literal('NoOffer').Or(r.Literal('NotApproved')).Or(r.Literal('AnalysisError')).Or(r.Literal('NoFiles')),
});

const PcbIssueRuntype = PcbAnalysisInProgressIssueRuntype.Or(OtherPcbIssuesRuntype);
export type PcbIssue = r.Static<typeof PcbIssueRuntype>;

const PcbIssuesRuntype = r.Array(PcbIssueRuntype);
const PcbProgressRuntype = r.Record({
    type: r.Literal('InProgress'),
    issues: PcbIssuesRuntype,
});
const PcbStatusRuntype = PendingStatusRuntype.Or(DoneStatusRuntype).Or(PcbProgressRuntype);
const PcbStateRuntype = r.Record({
    status: PcbStatusRuntype,
    requirement: RequirementRuntype,
});
export type PcbState = r.Static<typeof PcbStateRuntype>;

const SourcingProgressRuntype = r.Record({ type: r.Literal('InProgress') });
const SourcingStatusRuntype = PendingStatusRuntype.Or(DoneStatusRuntype).Or(SourcingProgressRuntype);
const SourcingStateRuntype = r.Record({
    status: SourcingStatusRuntype,
    requirement: r.Literal('Required'),
});
export type SourcingStateDTO = r.Static<typeof SourcingStateRuntype>;

const FileUploadProgressRuntype = r.Record({ type: r.Literal('InProgress') });
const FileUploadStatusRuntype = PendingStatusRuntype.Or(DoneStatusRuntype).Or(FileUploadProgressRuntype);
const FileUploadRuntype = r.Record({
    status: FileUploadStatusRuntype,
    requirement: RequirementRuntype,
});
export type CadState = r.Static<typeof FileUploadRuntype>;
export type ManufacturingState = r.Static<typeof FileUploadRuntype>;
export type PnpState = r.Static<typeof FileUploadRuntype>;

export const CustomerPortalAssemblyStateRuntype = r.Record({
    bom: BomStateRuntype,
    pcb: PcbStateRuntype,
    cad: FileUploadRuntype,
    manufacturing: FileUploadRuntype,
    pnp: FileUploadRuntype,
});

export type CustomerPortalAssemblyState = r.Static<typeof CustomerPortalAssemblyStateRuntype>;

const AssemblyIdRuntype = r.String;

export const CustomerPortalStateRuntype = r.Record({
    sourcing_state: SourcingStateRuntype,
    breadcrumbs: r.Dictionary(r.Array(r.String), AssemblyIdRuntype),
    assembly_states: r.Dictionary(CustomerPortalAssemblyStateRuntype, AssemblyIdRuntype),
});

export type CustomerPortalState = r.Static<typeof CustomerPortalStateRuntype>;

export const AssemblyStateRuntype = r.Record({
    is_bom_done: r.Boolean,
    is_pcb_done: r.Boolean,
    is_cad_done: r.Boolean,
    is_manufacturing_done: r.Boolean,
    is_pnp_done: r.Boolean,
});

export type AssemblyState = r.Static<typeof AssemblyStateRuntype>;

const QuotationWarningsOk = r.Record({
    type: r.Literal('Ok'),
});

const QuotationWarningsCalculationMissing = r.Record({
    type: r.Literal('CalculationMissing'),
    data: r.Record({
        assembly_designators: r.Array(r.String),
    }),
});

const QuotationWarningsQuotationDocumentMissing = r.Record({
    type: r.Literal('QuotationDocumentMissing'),
});

export const QuotationWarningsRuntype = QuotationWarningsOk.Or(QuotationWarningsCalculationMissing).Or(
    QuotationWarningsQuotationDocumentMissing,
);

export type QuotationWarningsDTO = r.Static<typeof QuotationWarningsRuntype>;
