import { logToExternalErrorHandlers } from '../errors';
import { getLocale, LanguageEnum } from '../i18n';
import { memoize } from '../utils';

export enum Currency {
    USD = 'USD',
    EUR = 'EUR',
    AUD = 'AUD',
    BGN = 'BGN',
    BRL = 'BRL',
    CAD = 'CAD',
    CHF = 'CHF',
    CNY = 'CNY',
    CZK = 'CZK',
    DKK = 'DKK',
    GBP = 'GBP',
    HKD = 'HKD',
    HRK = 'HRK',
    HUF = 'HUF',
    IDR = 'IDR',
    ILS = 'ILS',
    INR = 'INR',
    ISK = 'ISK',
    JPY = 'JPY',
    KRW = 'KRW',
    MXN = 'MXN',
    MYR = 'MYR',
    NOK = 'NOK',
    NZD = 'NZD',
    PHP = 'PHP',
    PLN = 'PLN',
    RON = 'RON',
    RUB = 'RUB',
    SEK = 'SEK',
    SGD = 'SGD',
    THB = 'THB',
    TRY = 'TRY',
    ZAR = 'ZAR',
    TWD = 'TWD',
    VND = 'VND',
}

/**
 * Returns a memoized currency formater
 */
const currencyFormater = memoize(
    (currency: string, minimumFractionDigits: number, locale: LanguageEnum) => {
        return new Intl.NumberFormat(locale, {
            currency,
            style: 'currency',
            minimumFractionDigits,
        });
    },
    (currency: string, minimumFractionDigits: number) => `${currency}-${minimumFractionDigits}`,
);

const formattingOptions = {
    default: { minimumFractionDigits: 2 },
    'unit-price': { minimumFractionDigits: 5 },
    'currency-settings': { minimumFractionDigits: 10 },
    estimate: { minimumFractionDigits: 0 },
};

export type FormattingType = keyof typeof formattingOptions;

/**
 * Use this function whenever you are going to format a currency.
 *
 * @param amount The number that will be formatted.
 * @param currencySymbol The currency of the amount e.g. 'EUR'.
 * @param type The type of formatting used.
 *
 * There are 3 different ways of formatting currencies in Luminovo
 * 1. **estimate**: Estimates are rough and should therefore not care too much about decimals. Decimal digits: 0.
 * 2. **unit-price**: Unit prices can be very small, because bulk buying means you can end up paying less tan 1 cent per unit.
 * So we display 5 fraction digits. Decimal digits: 5.
 * 3. **default**: 2. These are your regular day to day prices. Decimal digits: 2.
 *
 * You can read more about this here:
 *
 * https://www.notion.so/luminovo/Internationalize-all-the-things-German-7dfdd1df8dd3487485b2380fe1a472df?p=e3c23a32d0d14351bb2432d6b6f094f6
 *
 * Examples:
 *
 * ```
 * formatCurrency(123, Currency.EUR)              // => €123.00
 * formatCurrency(123.1, Currency.USD)            // => $123.10
 * formatCurrency(0.1, Currency.EUR ,'unit-price') // => €0.10000
 * ```
 */
export const formatCurrency = (
    amount: string | number,
    currencySymbol: Currency,
    type: FormattingType = 'default',
): string => {
    const { minimumFractionDigits } = formattingOptions[type];
    return currencyFormater(currencySymbol, minimumFractionDigits, getLocale()).format(Number(amount));
};

export const formatCurrencyWithoutSymbol = (amount: number, type: FormattingType = 'default'): string => {
    return formatCurrency(amount, Currency.EUR, type).replace('€', '');
};

export const displayCurrencySymbol = (currency: Currency): string => {
    try {
        const localisedCurrency = new Intl.NumberFormat('de-DE', { style: 'currency', currency: currency }).format(0);
        // Returns "0,00 Symbol", so we need to extract the symbol
        const currencySymbol = localisedCurrency.trim().split(/\s+/).pop();
        return currencySymbol ? currencySymbol : '?';
    } catch {
        logToExternalErrorHandlers(new Error(`Unknown currency code: ${currency}`));
        return '?';
    }
};
