import { t } from '@lingui/macro';
import { Flexbox, InteractiveCard, Text, colorSystem } from '@luminovo/design-system';
import { EqualizerRounded, Numbers, TableChartOutlined } from '@mui/icons-material';

export function Indicator({
    label,
    indicator,
    secondary,
    variant = 'metric',
}: {
    label: JSX.Element | string;
    indicator: JSX.Element | string;
    variant?: 'graph' | 'table' | 'metric' | 'no-icon';
    secondary: JSX.Element | string;
}) {
    const { icon, title } = {
        graph: {
            title: t`Graph`,
            icon: <EqualizerRounded fontSize="small" style={{ color: colorSystem.neutral[6] }} />,
        },
        table: {
            title: t`Table`,
            icon: <TableChartOutlined fontSize="small" style={{ color: colorSystem.neutral[6] }} />,
        },
        metric: {
            title: t`Metric`,
            icon: <Numbers fontSize="small" style={{ color: colorSystem.neutral[6] }} />,
        },
        'no-icon': {
            title: '',
            icon: null,
        },
    }[variant];

    return (
        <InteractiveCard
            selectable={false}
            tabIndex={undefined}
            sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}
        >
            <Flexbox gap="8px" alignItems="center">
                <Text style={{ textTransform: 'uppercase' }} color={colorSystem.neutral[7]} variant="h4">
                    {label}
                </Text>

                <span style={{ flexGrow: 1 }} />
                <Flexbox gap={4} alignItems={'center'}>
                    <Text variant={'body'} color={colorSystem.neutral[6]}>
                        {title}
                    </Text>
                    {icon}
                </Flexbox>
            </Flexbox>
            <Flexbox gap={4} alignItems={'baseline'}>
                {typeof indicator === 'string' ? (
                    <Text variant="h2" color={colorSystem.neutral[8]}>
                        {indicator}
                    </Text>
                ) : (
                    indicator
                )}
                {secondary && (
                    <Text color={colorSystem.neutral[6]} variant="body">
                        {secondary}
                    </Text>
                )}
            </Flexbox>
        </InteractiveCard>
    );
}
