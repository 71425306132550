import { t } from '@lingui/macro';
import { assertUnreachable } from '@luminovo/commons';
import { RegionsEnum, SupplierAndStockLocationDTO, SupplierDTO } from '@luminovo/http-client';

export function formatSupplierDTO(supplier?: SupplierDTO): string {
    if (!supplier) {
        return t`Unknown`;
    }
    return supplier.name;
}

export function formatSupplierAndStockLocationDTO(sasl?: SupplierAndStockLocationDTO): string {
    if (!sasl) {
        return t`Unknown`;
    }
    return formatSupplierNameWithStockLocation(sasl.supplier.name, sasl.stock_location);
}

export function formatSupplierNameWithStockLocation(name: string, stockLocation: RegionsEnum): string {
    if (stockLocation === RegionsEnum.Unknown) {
        return name;
    }

    // First try the short 3 letter code but if not available go for full name
    const stockLocationText = formatRegionEnumAsISO3166Alpha3(stockLocation) || formatRegionAsName(stockLocation);
    return `${name} · ${stockLocationText}`;
}

export function formatRegionAsName(region: RegionsEnum): string {
    switch (region) {
        case RegionsEnum.Unknown:
            return 'Unknown';
        case RegionsEnum.World:
            return 'World';
        case RegionsEnum.Africa:
            return 'Africa';
        case RegionsEnum.NorthernAfrica:
            return 'Northern Africa';
        case RegionsEnum.SubSaharanAfrica:
            return 'Sub-Saharan Africa';
        case RegionsEnum.WesternAfrica:
            return 'Western Africa';
        case RegionsEnum.EasternAfrica:
            return 'Eastern Africa';
        case RegionsEnum.MiddleAfrica:
            return 'Middle Africa';
        case RegionsEnum.SouthernAfrica:
            return 'Southern Africa';
        case RegionsEnum.Americas:
            return 'Americas';
        case RegionsEnum.NorthernAmerica:
            return 'Northern America';
        case RegionsEnum.LatinAmericaAndTheCaribbean:
            return 'Latin America and the Caribbean';
        case RegionsEnum.SouthAmerica:
            return 'South America';
        case RegionsEnum.CentralAmerica:
            return 'Central America';
        case RegionsEnum.Caribbean:
            return 'Caribbean';
        case RegionsEnum.Asia:
            return 'Asia';
        case RegionsEnum.EasternAsia:
            return 'Eastern Asia';
        case RegionsEnum.SouthernAsia:
            return 'Southern Asia';
        case RegionsEnum.SouthEasternAsia:
            return 'South-eastern Asia';
        case RegionsEnum.CentralAsia:
            return 'Central Asia';
        case RegionsEnum.WesternAsia:
            return 'Western Asia';
        case RegionsEnum.Europe:
            return 'Europe';
        case RegionsEnum.SouthernEurope:
            return 'Southern Europe';
        case RegionsEnum.EasternEurope:
            return 'Eastern Europe';
        case RegionsEnum.NorthernEurope:
            return 'Northern Europe';
        case RegionsEnum.ChannelIslands:
            return 'Channel Islands';
        case RegionsEnum.WesternEurope:
            return 'Western Europe';
        case RegionsEnum.Oceania:
            return 'Oceania';
        case RegionsEnum.AustraliaAndNewZealand:
            return 'Australia and New Zealand';
        case RegionsEnum.Melanesia:
            return 'Melanesia';
        case RegionsEnum.Micronesia:
            return 'Micronesia';
        case RegionsEnum.Polynesia:
            return 'Polynesia';
        case RegionsEnum.Algeria:
            return 'Algeria';
        case RegionsEnum.Egypt:
            return 'Egypt';
        case RegionsEnum.Libya:
            return 'Libya';
        case RegionsEnum.Morocco:
            return 'Morocco';
        case RegionsEnum.Sudan:
            return 'Sudan';
        case RegionsEnum.Tunisia:
            return 'Tunisia';
        case RegionsEnum.WesternSahara:
            return 'Western Sahara';
        case RegionsEnum.BritishIndianOceanTerritory:
            return 'British Indian Ocean Territory';
        case RegionsEnum.Burundi:
            return 'Burundi';
        case RegionsEnum.Comoros:
            return 'Comoros';
        case RegionsEnum.Djibouti:
            return 'Djibouti';
        case RegionsEnum.Eritrea:
            return 'Eritrea';
        case RegionsEnum.Ethiopia:
            return 'Ethiopia';
        case RegionsEnum.FrenchSouthernTerritories:
            return 'French Southern Territories';
        case RegionsEnum.Kenya:
            return 'Kenya';
        case RegionsEnum.Madagascar:
            return 'Madagascar';
        case RegionsEnum.Malawi:
            return 'Malawi';
        case RegionsEnum.Mauritius:
            return 'Mauritius';
        case RegionsEnum.Mayotte:
            return 'Mayotte';
        case RegionsEnum.Mozambique:
            return 'Mozambique';
        case RegionsEnum.Reunion:
            return 'Réunion';
        case RegionsEnum.Rwanda:
            return 'Rwanda';
        case RegionsEnum.Seychelles:
            return 'Seychelles';
        case RegionsEnum.Somalia:
            return 'Somalia';
        case RegionsEnum.SouthSudan:
            return 'South Sudan';
        case RegionsEnum.Uganda:
            return 'Uganda';
        case RegionsEnum.UnitedRepublicOfTanzania:
            return 'United Republic of Tanzania';
        case RegionsEnum.Zambia:
            return 'Zambia';
        case RegionsEnum.Zimbabwe:
            return 'Zimbabwe';
        case RegionsEnum.Angola:
            return 'Angola';
        case RegionsEnum.Cameroon:
            return 'Cameroon';
        case RegionsEnum.CentralAfricanRepublic:
            return 'Central African Republic';
        case RegionsEnum.Chad:
            return 'Chad';
        case RegionsEnum.Congo:
            return 'Congo';
        case RegionsEnum.DemocraticRepublicOfTheCongo:
            return 'Democratic Republic of the Congo';
        case RegionsEnum.EquatorialGuinea:
            return 'Equatorial Guinea';
        case RegionsEnum.Gabon:
            return 'Gabon';
        case RegionsEnum.SaoTomeAndPrincipe:
            return 'Sao Tome and Principe';
        case RegionsEnum.Botswana:
            return 'Botswana';
        case RegionsEnum.Eswatini:
            return 'Eswatini';
        case RegionsEnum.Lesotho:
            return 'Lesotho';
        case RegionsEnum.Namibia:
            return 'Namibia';
        case RegionsEnum.SouthAfrica:
            return 'South Africa';
        case RegionsEnum.Benin:
            return 'Benin';
        case RegionsEnum.BurkinaFaso:
            return 'Burkina Faso';
        case RegionsEnum.CaboVerde:
            return 'Cabo Verde';
        case RegionsEnum.CoteDIvore:
            return 'Côte d’Ivoire';
        case RegionsEnum.Gambia:
            return 'Gambia';
        case RegionsEnum.Ghana:
            return 'Ghana';
        case RegionsEnum.Guinea:
            return 'Guinea';
        case RegionsEnum.GuineaBissau:
            return 'Guinea-Bissau';
        case RegionsEnum.Liberia:
            return 'Liberia';
        case RegionsEnum.Mali:
            return 'Mali';
        case RegionsEnum.Mauritania:
            return 'Mauritania';
        case RegionsEnum.Niger:
            return 'Niger';
        case RegionsEnum.Nigeria:
            return 'Nigeria';
        case RegionsEnum.SaintHelena:
            return 'Saint Helena';
        case RegionsEnum.Senegal:
            return 'Senegal';
        case RegionsEnum.SierraLeone:
            return 'Sierra Leone';
        case RegionsEnum.Togo:
            return 'Togo';
        case RegionsEnum.Anguilla:
            return 'Anguilla';
        case RegionsEnum.AntiguaAndBarbuda:
            return 'Antigua and Barbuda';
        case RegionsEnum.Aruba:
            return 'Aruba';
        case RegionsEnum.Bahamas:
            return 'Bahamas';
        case RegionsEnum.Barbados:
            return 'Barbados';
        case RegionsEnum.BonaireAndSintEustatiusAndSaba:
            return 'Bonaire, Sint Eustatius and Saba';
        case RegionsEnum.BritishVirginIslands:
            return 'British Virgin Islands';
        case RegionsEnum.CaymanIslands:
            return 'Cayman Islands';
        case RegionsEnum.Cuba:
            return 'Cuba';
        case RegionsEnum.Curacao:
            return 'Curaçao';
        case RegionsEnum.Dominica:
            return 'Dominica';
        case RegionsEnum.DominicanRepublic:
            return 'Dominican Republic';
        case RegionsEnum.Grenada:
            return 'Grenada';
        case RegionsEnum.Guadeloupe:
            return 'Guadeloupe';
        case RegionsEnum.Haiti:
            return 'Haiti';
        case RegionsEnum.Jamaica:
            return 'Jamaica';
        case RegionsEnum.Martinique:
            return 'Martinique';
        case RegionsEnum.Montserrat:
            return 'Montserrat';
        case RegionsEnum.PuertoRico:
            return 'Puerto Rico';
        case RegionsEnum.SaintBarthelemy:
            return 'Saint Barthélemy';
        case RegionsEnum.SaintKittsAndNevis:
            return 'Saint Kitts and Nevis';
        case RegionsEnum.SaintLucia:
            return 'Saint Lucia';
        case RegionsEnum.SaintMartin:
            return 'Saint Martin (French Part)';
        case RegionsEnum.SaintVincentAndTheGrenadines:
            return 'Saint Vincent and the Grenadines';
        case RegionsEnum.SintMaarten:
            return 'Sint Maarten (Dutch part)';
        case RegionsEnum.TrinidadAndTobago:
            return 'Trinidad and Tobago';
        case RegionsEnum.TurksAndCaicosIslands:
            return 'Turks and Caicos Islands';
        case RegionsEnum.UnitedStatesVirginIslands:
            return 'United States Virgin Islands';
        case RegionsEnum.Belize:
            return 'Belize';
        case RegionsEnum.CostaRica:
            return 'Costa Rica';
        case RegionsEnum.ElSalvador:
            return 'El Salvador';
        case RegionsEnum.Guatemala:
            return 'Guatemala';
        case RegionsEnum.Honduras:
            return 'Honduras';
        case RegionsEnum.Mexico:
            return 'Mexico';
        case RegionsEnum.Nicaragua:
            return 'Nicaragua';
        case RegionsEnum.Panama:
            return 'Panama';
        case RegionsEnum.Argentina:
            return 'Argentina';
        case RegionsEnum.Bolivia:
            return 'Bolivia (Plurinational State of)';
        case RegionsEnum.BouvetIsland:
            return 'Bouvet Island';
        case RegionsEnum.Brazil:
            return 'Brazil';
        case RegionsEnum.Chile:
            return 'Chile';
        case RegionsEnum.Colombia:
            return 'Colombia';
        case RegionsEnum.Ecuador:
            return 'Ecuador';
        case RegionsEnum.FalklandIslands:
            return 'Falkland Islands (Malvinas)';
        case RegionsEnum.FrenchGuiana:
            return 'French Guiana';
        case RegionsEnum.Guyana:
            return 'Guyana';
        case RegionsEnum.Paraguay:
            return 'Paraguay';
        case RegionsEnum.Peru:
            return 'Peru';
        case RegionsEnum.SouthGeorgiaAndTheSouthSandwichIslands:
            return 'South Georgia and the South Sandwich Islands';
        case RegionsEnum.Suriname:
            return 'Suriname';
        case RegionsEnum.Uruguay:
            return 'Uruguay';
        case RegionsEnum.Venezuela:
            return 'Venezuela (Bolivarian Republic of)';
        case RegionsEnum.Bermuda:
            return 'Bermuda';
        case RegionsEnum.Canada:
            return 'Canada';
        case RegionsEnum.Greenland:
            return 'Greenland';
        case RegionsEnum.SaintPierreAndMiquelon:
            return 'Saint Pierre and Miquelon';
        case RegionsEnum.UnitedStatesOfAmerica:
            return 'United States of America';
        case RegionsEnum.Antarctica:
            return 'Antarctica';
        case RegionsEnum.Kazakhstan:
            return 'Kazakhstan';
        case RegionsEnum.Kyrgyzstan:
            return 'Kyrgyzstan';
        case RegionsEnum.Tajikistan:
            return 'Tajikistan';
        case RegionsEnum.Turkmenistan:
            return 'Turkmenistan';
        case RegionsEnum.Uzbekistan:
            return 'Uzbekistan';
        case RegionsEnum.China:
            return 'China';
        case RegionsEnum.Taiwan:
            return 'Taiwan';
        case RegionsEnum.HongKong:
            return 'China, Hong Kong Special Administrative Region';
        case RegionsEnum.Macao:
            return 'China, Macao Special Administrative Region';
        case RegionsEnum.DemocraticPeoplesRepublicOfKorea:
            return "Democratic People's Republic of Korea";
        case RegionsEnum.Japan:
            return 'Japan';
        case RegionsEnum.Mongolia:
            return 'Mongolia';
        case RegionsEnum.RepublicOfKorea:
            return 'Republic of Korea';
        case RegionsEnum.BruneiDarussalam:
            return 'Brunei Darussalam';
        case RegionsEnum.Cambodia:
            return 'Cambodia';
        case RegionsEnum.Indonesia:
            return 'Indonesia';
        case RegionsEnum.LaoPeoplesDemocraticRepublic:
            return "Lao People's Democratic Republic";
        case RegionsEnum.Malaysia:
            return 'Malaysia';
        case RegionsEnum.Myanmar:
            return 'Myanmar';
        case RegionsEnum.Philippines:
            return 'Philippines';
        case RegionsEnum.Singapore:
            return 'Singapore';
        case RegionsEnum.Thailand:
            return 'Thailand';
        case RegionsEnum.TimorLeste:
            return 'Timor-Leste';
        case RegionsEnum.VietNam:
            return 'Viet Nam';
        case RegionsEnum.Afghanistan:
            return 'Afghanistan';
        case RegionsEnum.Bangladesh:
            return 'Bangladesh';
        case RegionsEnum.Bhutan:
            return 'Bhutan';
        case RegionsEnum.India:
            return 'India';
        case RegionsEnum.Iran:
            return 'Iran (Islamic Republic of)';
        case RegionsEnum.Maldives:
            return 'Maldives';
        case RegionsEnum.Nepal:
            return 'Nepal';
        case RegionsEnum.Pakistan:
            return 'Pakistan';
        case RegionsEnum.SriLanka:
            return 'Sri Lanka';
        case RegionsEnum.Armenia:
            return 'Armenia';
        case RegionsEnum.Azerbaijan:
            return 'Azerbaijan';
        case RegionsEnum.Bahrain:
            return 'Bahrain';
        case RegionsEnum.Cyprus:
            return 'Cyprus';
        case RegionsEnum.Georgia:
            return 'Georgia';
        case RegionsEnum.Iraq:
            return 'Iraq';
        case RegionsEnum.Israel:
            return 'Israel';
        case RegionsEnum.Jordan:
            return 'Jordan';
        case RegionsEnum.Kuwait:
            return 'Kuwait';
        case RegionsEnum.Lebanon:
            return 'Lebanon';
        case RegionsEnum.Oman:
            return 'Oman';
        case RegionsEnum.Qatar:
            return 'Qatar';
        case RegionsEnum.SaudiArabia:
            return 'Saudi Arabia';
        case RegionsEnum.StateOfPalestine:
            return 'State of Palestine';
        case RegionsEnum.SyrianArabRepublic:
            return 'Syrian Arab Republic';
        case RegionsEnum.Turkey:
            return 'Turkey';
        case RegionsEnum.UnitedArabEmirates:
            return 'United Arab Emirates';
        case RegionsEnum.Yemen:
            return 'Yemen';
        case RegionsEnum.Belarus:
            return 'Belarus';
        case RegionsEnum.Bulgaria:
            return 'Bulgaria';
        case RegionsEnum.Czechia:
            return 'Czechia';
        case RegionsEnum.Hungary:
            return 'Hungary';
        case RegionsEnum.Poland:
            return 'Poland';
        case RegionsEnum.RepublicOfMoldova:
            return 'Republic of Moldova';
        case RegionsEnum.Romania:
            return 'Romania';
        case RegionsEnum.RussianFederation:
            return 'Russian Federation';
        case RegionsEnum.Slovakia:
            return 'Slovakia';
        case RegionsEnum.Ukraine:
            return 'Ukraine';
        case RegionsEnum.AlandIslands:
            return 'Åland Islands';
        case RegionsEnum.Guernsey:
            return 'Guernsey';
        case RegionsEnum.Jersey:
            return 'Jersey';
        case RegionsEnum.Sark:
            return 'Sark';
        case RegionsEnum.Denmark:
            return 'Denmark';
        case RegionsEnum.Estonia:
            return 'Estonia';
        case RegionsEnum.FaroeIslands:
            return 'Faroe Islands';
        case RegionsEnum.Finland:
            return 'Finland';
        case RegionsEnum.Iceland:
            return 'Iceland';
        case RegionsEnum.Ireland:
            return 'Ireland';
        case RegionsEnum.IsleOfMan:
            return 'Isle of Man';
        case RegionsEnum.Latvia:
            return 'Latvia';
        case RegionsEnum.Lithuania:
            return 'Lithuania';
        case RegionsEnum.Norway:
            return 'Norway';
        case RegionsEnum.SvalbardAndJanMayenIslands:
            return 'Svalbard and Jan Mayen Islands';
        case RegionsEnum.Sweden:
            return 'Sweden';
        case RegionsEnum.UnitedKingdomOfGreatBritainAndNorthernIreland:
            return 'United Kingdom of Great Britain and Northern Ireland';
        case RegionsEnum.Albania:
            return 'Albania';
        case RegionsEnum.Andorra:
            return 'Andorra';
        case RegionsEnum.BosniaAndHerzegovina:
            return 'Bosnia and Herzegovina';
        case RegionsEnum.Croatia:
            return 'Croatia';
        case RegionsEnum.Gibraltar:
            return 'Gibraltar';
        case RegionsEnum.Greece:
            return 'Greece';
        case RegionsEnum.HolySee:
            return 'Holy See';
        case RegionsEnum.Italy:
            return 'Italy';
        case RegionsEnum.Malta:
            return 'Malta';
        case RegionsEnum.Montenegro:
            return 'Montenegro';
        case RegionsEnum.NorthMacedonia:
            return 'North Macedonia';
        case RegionsEnum.Portugal:
            return 'Portugal';
        case RegionsEnum.SanMarino:
            return 'San Marino';
        case RegionsEnum.Serbia:
            return 'Serbia';
        case RegionsEnum.Slovenia:
            return 'Slovenia';
        case RegionsEnum.Spain:
            return 'Spain';
        case RegionsEnum.Austria:
            return 'Austria';
        case RegionsEnum.Belgium:
            return 'Belgium';
        case RegionsEnum.France:
            return 'France';
        case RegionsEnum.Germany:
            return 'Germany';
        case RegionsEnum.Liechtenstein:
            return 'Liechtenstein';
        case RegionsEnum.Luxembourg:
            return 'Luxembourg';
        case RegionsEnum.Monaco:
            return 'Monaco';
        case RegionsEnum.Netherlands:
            return 'Netherlands';
        case RegionsEnum.Switzerland:
            return 'Switzerland';
        case RegionsEnum.Australia:
            return 'Australia';
        case RegionsEnum.ChristmasIsland:
            return 'Christmas Island';
        case RegionsEnum.CocosKeelingIslands:
            return 'Cocos (Keeling) Islands';
        case RegionsEnum.HeardIslandAndMcDonaldIslands:
            return 'Heard Island and McDonald Islands';
        case RegionsEnum.NewZealand:
            return 'New Zealand';
        case RegionsEnum.NorfolkIsland:
            return 'Norfolk Island';
        case RegionsEnum.Fiji:
            return 'Fiji';
        case RegionsEnum.NewCaledonia:
            return 'New Caledonia';
        case RegionsEnum.PapuaNewGuinea:
            return 'Papua New Guinea';
        case RegionsEnum.SolomonIslands:
            return 'Solomon Islands';
        case RegionsEnum.Vanuatu:
            return 'Vanuatu';
        case RegionsEnum.Guam:
            return 'Guam';
        case RegionsEnum.Kiribati:
            return 'Kiribati';
        case RegionsEnum.MarshallIslands:
            return 'Marshall Islands';
        case RegionsEnum.FederatedStatesOfMicronesia:
            return 'Micronesia (Federated States of)';
        case RegionsEnum.Nauru:
            return 'Nauru';
        case RegionsEnum.NorthernMarianaIslands:
            return 'Northern Mariana Islands';
        case RegionsEnum.Palau:
            return 'Palau';
        case RegionsEnum.UnitedStatesMinorOutlyingIslands:
            return 'United States Minor Outlying Islands';
        case RegionsEnum.AmericanSamoa:
            return 'American Samoa';
        case RegionsEnum.CookIslands:
            return 'Cook Islands';
        case RegionsEnum.FrenchPolynesia:
            return 'French Polynesia';
        case RegionsEnum.Niue:
            return 'Niue';
        case RegionsEnum.Pitcairn:
            return 'Pitcairn';
        case RegionsEnum.Samoa:
            return 'Samoa';
        case RegionsEnum.Tokelau:
            return 'Tokelau';
        case RegionsEnum.Tonga:
            return 'Tonga';
        case RegionsEnum.Tuvalu:
            return 'Tuvalu';
        case RegionsEnum.WallisAndFutunaIslands:
            return 'Wallis and Futuna Islands';
        default:
            assertUnreachable(region);
    }
}

// Return 3 letter code for the region if one exists
export function formatRegionEnumAsISO3166Alpha3(region: RegionsEnum): string | null {
    switch (region) {
        case RegionsEnum.Algeria:
            return 'DZA';
        case RegionsEnum.Egypt:
            return 'EGY';
        case RegionsEnum.Libya:
            return 'LBY';
        case RegionsEnum.Morocco:
            return 'MAR';
        case RegionsEnum.Sudan:
            return 'SDN';
        case RegionsEnum.Tunisia:
            return 'TUN';
        case RegionsEnum.WesternSahara:
            return 'ESH';
        case RegionsEnum.BritishIndianOceanTerritory:
            return 'IOT';
        case RegionsEnum.Burundi:
            return 'BDI';
        case RegionsEnum.Comoros:
            return 'COM';
        case RegionsEnum.Djibouti:
            return 'DJI';
        case RegionsEnum.Eritrea:
            return 'ERI';
        case RegionsEnum.Ethiopia:
            return 'ETH';
        case RegionsEnum.FrenchSouthernTerritories:
            return 'ATF';
        case RegionsEnum.Kenya:
            return 'KEN';
        case RegionsEnum.Madagascar:
            return 'MDG';
        case RegionsEnum.Malawi:
            return 'MWI';
        case RegionsEnum.Mauritius:
            return 'MUS';
        case RegionsEnum.Mayotte:
            return 'MYT';
        case RegionsEnum.Mozambique:
            return 'MOZ';
        case RegionsEnum.Reunion:
            return 'REU';
        case RegionsEnum.Rwanda:
            return 'RWA';
        case RegionsEnum.Seychelles:
            return 'SYC';
        case RegionsEnum.Somalia:
            return 'SOM';
        case RegionsEnum.SouthSudan:
            return 'SSD';
        case RegionsEnum.Uganda:
            return 'UGA';
        case RegionsEnum.UnitedRepublicOfTanzania:
            return 'TZA';
        case RegionsEnum.Zambia:
            return 'ZMB';
        case RegionsEnum.Zimbabwe:
            return 'ZWE';
        case RegionsEnum.Angola:
            return 'AGO';
        case RegionsEnum.Cameroon:
            return 'CMR';
        case RegionsEnum.CentralAfricanRepublic:
            return 'CAF';
        case RegionsEnum.Chad:
            return 'TCD';
        case RegionsEnum.Congo:
            return 'COG';
        case RegionsEnum.DemocraticRepublicOfTheCongo:
            return 'COD';
        case RegionsEnum.EquatorialGuinea:
            return 'GNQ';
        case RegionsEnum.Gabon:
            return 'GAB';
        case RegionsEnum.SaoTomeAndPrincipe:
            return 'STP';
        case RegionsEnum.Botswana:
            return 'BWA';
        case RegionsEnum.Eswatini:
            return 'SWZ';
        case RegionsEnum.Lesotho:
            return 'LSO';
        case RegionsEnum.Namibia:
            return 'NAM';
        case RegionsEnum.SouthAfrica:
            return 'ZAF';
        case RegionsEnum.Benin:
            return 'BEN';
        case RegionsEnum.BurkinaFaso:
            return 'BFA';
        case RegionsEnum.CaboVerde:
            return 'CPV';
        case RegionsEnum.CoteDIvore:
            return 'CIV';
        case RegionsEnum.Gambia:
            return 'GMB';
        case RegionsEnum.Ghana:
            return 'GHA';
        case RegionsEnum.Guinea:
            return 'GIN';
        case RegionsEnum.GuineaBissau:
            return 'GNB';
        case RegionsEnum.Liberia:
            return 'LBR';
        case RegionsEnum.Mali:
            return 'MLI';
        case RegionsEnum.Mauritania:
            return 'MRT';
        case RegionsEnum.Niger:
            return 'NER';
        case RegionsEnum.Nigeria:
            return 'NGA';
        case RegionsEnum.SaintHelena:
            return 'SHN';
        case RegionsEnum.Senegal:
            return 'SEN';
        case RegionsEnum.SierraLeone:
            return 'SLE';
        case RegionsEnum.Togo:
            return 'TGO';
        case RegionsEnum.Anguilla:
            return 'AIA';
        case RegionsEnum.AntiguaAndBarbuda:
            return 'ATG';
        case RegionsEnum.Aruba:
            return 'ABW';
        case RegionsEnum.Bahamas:
            return 'BHS';
        case RegionsEnum.Barbados:
            return 'BRB';
        case RegionsEnum.BonaireAndSintEustatiusAndSaba:
            return 'BES';
        case RegionsEnum.BritishVirginIslands:
            return 'VGB';
        case RegionsEnum.CaymanIslands:
            return 'CYM';
        case RegionsEnum.Cuba:
            return 'CUB';
        case RegionsEnum.Curacao:
            return 'CUW';
        case RegionsEnum.Dominica:
            return 'DMA';
        case RegionsEnum.DominicanRepublic:
            return 'DOM';
        case RegionsEnum.Grenada:
            return 'GRD';
        case RegionsEnum.Guadeloupe:
            return 'GLP';
        case RegionsEnum.Haiti:
            return 'HTI';
        case RegionsEnum.Jamaica:
            return 'JAM';
        case RegionsEnum.Martinique:
            return 'MTQ';
        case RegionsEnum.Montserrat:
            return 'MSR';
        case RegionsEnum.PuertoRico:
            return 'PRI';
        case RegionsEnum.SaintBarthelemy:
            return 'BLM';
        case RegionsEnum.SaintKittsAndNevis:
            return 'KNA';
        case RegionsEnum.SaintLucia:
            return 'LCA';
        case RegionsEnum.SaintMartin:
            return 'MAF';
        case RegionsEnum.SaintVincentAndTheGrenadines:
            return 'VCT';
        case RegionsEnum.SintMaarten:
            return 'SXM';
        case RegionsEnum.TrinidadAndTobago:
            return 'TTO';
        case RegionsEnum.TurksAndCaicosIslands:
            return 'TCA';
        case RegionsEnum.UnitedStatesVirginIslands:
            return 'VIR';
        case RegionsEnum.Belize:
            return 'BLZ';
        case RegionsEnum.CostaRica:
            return 'CRI';
        case RegionsEnum.ElSalvador:
            return 'SLV';
        case RegionsEnum.Guatemala:
            return 'GTM';
        case RegionsEnum.Honduras:
            return 'HND';
        case RegionsEnum.Mexico:
            return 'MEX';
        case RegionsEnum.Nicaragua:
            return 'NIC';
        case RegionsEnum.Panama:
            return 'PAN';
        case RegionsEnum.Argentina:
            return 'ARG';
        case RegionsEnum.Bolivia:
            return 'BOL';
        case RegionsEnum.BouvetIsland:
            return 'BVT';
        case RegionsEnum.Brazil:
            return 'BRA';
        case RegionsEnum.Chile:
            return 'CHL';
        case RegionsEnum.Colombia:
            return 'COL';
        case RegionsEnum.Ecuador:
            return 'ECU';
        case RegionsEnum.FalklandIslands:
            return 'FLK';
        case RegionsEnum.FrenchGuiana:
            return 'GUF';
        case RegionsEnum.Guyana:
            return 'GUY';
        case RegionsEnum.Paraguay:
            return 'PRY';
        case RegionsEnum.Peru:
            return 'PER';
        case RegionsEnum.SouthGeorgiaAndTheSouthSandwichIslands:
            return 'SGS';
        case RegionsEnum.Suriname:
            return 'SUR';
        case RegionsEnum.Uruguay:
            return 'URY';
        case RegionsEnum.Venezuela:
            return 'VEN';
        case RegionsEnum.Bermuda:
            return 'BMU';
        case RegionsEnum.Canada:
            return 'CAN';
        case RegionsEnum.Greenland:
            return 'GRL';
        case RegionsEnum.SaintPierreAndMiquelon:
            return 'SPM';
        case RegionsEnum.UnitedStatesOfAmerica:
            return 'USA';
        case RegionsEnum.Antarctica:
            return 'ATA';
        case RegionsEnum.Kazakhstan:
            return 'KAZ';
        case RegionsEnum.Kyrgyzstan:
            return 'KGZ';
        case RegionsEnum.Tajikistan:
            return 'TJK';
        case RegionsEnum.Turkmenistan:
            return 'TKM';
        case RegionsEnum.Uzbekistan:
            return 'UZB';
        case RegionsEnum.China:
            return 'CHN';
        case RegionsEnum.Taiwan:
            return 'TWN';
        case RegionsEnum.HongKong:
            return 'HKG';
        case RegionsEnum.Macao:
            return 'MAC';
        case RegionsEnum.DemocraticPeoplesRepublicOfKorea:
            return 'PRK';
        case RegionsEnum.Japan:
            return 'JPN';
        case RegionsEnum.Mongolia:
            return 'MNG';
        case RegionsEnum.RepublicOfKorea:
            return 'KOR';
        case RegionsEnum.BruneiDarussalam:
            return 'BRN';
        case RegionsEnum.Cambodia:
            return 'KHM';
        case RegionsEnum.Indonesia:
            return 'IDN';
        case RegionsEnum.LaoPeoplesDemocraticRepublic:
            return 'LAO';
        case RegionsEnum.Malaysia:
            return 'MYS';
        case RegionsEnum.Myanmar:
            return 'MMR';
        case RegionsEnum.Philippines:
            return 'PHL';
        case RegionsEnum.Singapore:
            return 'SGP';
        case RegionsEnum.Thailand:
            return 'THA';
        case RegionsEnum.TimorLeste:
            return 'TLS';
        case RegionsEnum.VietNam:
            return 'VNM';
        case RegionsEnum.Afghanistan:
            return 'AFG';
        case RegionsEnum.Bangladesh:
            return 'BGD';
        case RegionsEnum.Bhutan:
            return 'BTN';
        case RegionsEnum.India:
            return 'IND';
        case RegionsEnum.Iran:
            return 'IRN';
        case RegionsEnum.Maldives:
            return 'MDV';
        case RegionsEnum.Nepal:
            return 'NPL';
        case RegionsEnum.Pakistan:
            return 'PAK';
        case RegionsEnum.SriLanka:
            return 'LKA';
        case RegionsEnum.Armenia:
            return 'ARM';
        case RegionsEnum.Azerbaijan:
            return 'AZE';
        case RegionsEnum.Bahrain:
            return 'BHR';
        case RegionsEnum.Cyprus:
            return 'CYP';
        case RegionsEnum.Georgia:
            return 'GEO';
        case RegionsEnum.Iraq:
            return 'IRQ';
        case RegionsEnum.Israel:
            return 'ISR';
        case RegionsEnum.Jordan:
            return 'JOR';
        case RegionsEnum.Kuwait:
            return 'KWT';
        case RegionsEnum.Lebanon:
            return 'LBN';
        case RegionsEnum.Oman:
            return 'OMN';
        case RegionsEnum.Qatar:
            return 'QAT';
        case RegionsEnum.SaudiArabia:
            return 'SAU';
        case RegionsEnum.StateOfPalestine:
            return 'PSE';
        case RegionsEnum.SyrianArabRepublic:
            return 'SYR';
        case RegionsEnum.Turkey:
            return 'TUR';
        case RegionsEnum.UnitedArabEmirates:
            return 'ARE';
        case RegionsEnum.Yemen:
            return 'YEM';
        case RegionsEnum.Belarus:
            return 'BLR';
        case RegionsEnum.Bulgaria:
            return 'BGR';
        case RegionsEnum.Czechia:
            return 'CZE';
        case RegionsEnum.Hungary:
            return 'HUN';
        case RegionsEnum.Poland:
            return 'POL';
        case RegionsEnum.RepublicOfMoldova:
            return 'MDA';
        case RegionsEnum.Romania:
            return 'ROU';
        case RegionsEnum.RussianFederation:
            return 'RUS';
        case RegionsEnum.Slovakia:
            return 'SVK';
        case RegionsEnum.Ukraine:
            return 'UKR';
        case RegionsEnum.AlandIslands:
            return 'ALA';
        case RegionsEnum.Guernsey:
            return 'GGY';
        case RegionsEnum.Jersey:
            return 'JEY';
        case RegionsEnum.Denmark:
            return 'DNK';
        case RegionsEnum.Estonia:
            return 'EST';
        case RegionsEnum.FaroeIslands:
            return 'FRO';
        case RegionsEnum.Finland:
            return 'FIN';
        case RegionsEnum.Iceland:
            return 'ISL';
        case RegionsEnum.Ireland:
            return 'IRL';
        case RegionsEnum.IsleOfMan:
            return 'IMN';
        case RegionsEnum.Latvia:
            return 'LVA';
        case RegionsEnum.Lithuania:
            return 'LTU';
        case RegionsEnum.Norway:
            return 'NOR';
        case RegionsEnum.SvalbardAndJanMayenIslands:
            return 'SJM';
        case RegionsEnum.Sweden:
            return 'SWE';
        case RegionsEnum.UnitedKingdomOfGreatBritainAndNorthernIreland:
            return 'GBR';
        case RegionsEnum.Albania:
            return 'ALB';
        case RegionsEnum.Andorra:
            return 'AND';
        case RegionsEnum.BosniaAndHerzegovina:
            return 'BIH';
        case RegionsEnum.Croatia:
            return 'HRV';
        case RegionsEnum.Gibraltar:
            return 'GIB';
        case RegionsEnum.Greece:
            return 'GRC';
        case RegionsEnum.HolySee:
            return 'VAT';
        case RegionsEnum.Italy:
            return 'ITA';
        case RegionsEnum.Malta:
            return 'MLT';
        case RegionsEnum.Montenegro:
            return 'MNE';
        case RegionsEnum.NorthMacedonia:
            return 'MKD';
        case RegionsEnum.Portugal:
            return 'PRT';
        case RegionsEnum.SanMarino:
            return 'SMR';
        case RegionsEnum.Serbia:
            return 'SRB';
        case RegionsEnum.Slovenia:
            return 'SVN';
        case RegionsEnum.Spain:
            return 'ESP';
        case RegionsEnum.Austria:
            return 'AUT';
        case RegionsEnum.Belgium:
            return 'BEL';
        case RegionsEnum.France:
            return 'FRA';
        case RegionsEnum.Germany:
            return 'DEU';
        case RegionsEnum.Liechtenstein:
            return 'LIE';
        case RegionsEnum.Luxembourg:
            return 'LUX';
        case RegionsEnum.Monaco:
            return 'MCO';
        case RegionsEnum.Netherlands:
            return 'NLD';
        case RegionsEnum.Switzerland:
            return 'CHE';
        case RegionsEnum.Australia:
            return 'AUS';
        case RegionsEnum.ChristmasIsland:
            return 'CXR';
        case RegionsEnum.CocosKeelingIslands:
            return 'CCK';
        case RegionsEnum.HeardIslandAndMcDonaldIslands:
            return 'HMD';
        case RegionsEnum.NewZealand:
            return 'NZL';
        case RegionsEnum.NorfolkIsland:
            return 'NFK';
        case RegionsEnum.Fiji:
            return 'FJI';
        case RegionsEnum.NewCaledonia:
            return 'NCL';
        case RegionsEnum.PapuaNewGuinea:
            return 'PNG';
        case RegionsEnum.SolomonIslands:
            return 'SLB';
        case RegionsEnum.Vanuatu:
            return 'VUT';
        case RegionsEnum.Guam:
            return 'GUM';
        case RegionsEnum.Kiribati:
            return 'KIR';
        case RegionsEnum.MarshallIslands:
            return 'MHL';
        case RegionsEnum.FederatedStatesOfMicronesia:
            return 'FSM';
        case RegionsEnum.Nauru:
            return 'NRU';
        case RegionsEnum.NorthernMarianaIslands:
            return 'MNP';
        case RegionsEnum.Palau:
            return 'PLW';
        case RegionsEnum.UnitedStatesMinorOutlyingIslands:
            return 'UMI';
        case RegionsEnum.AmericanSamoa:
            return 'ASM';
        case RegionsEnum.CookIslands:
            return 'COK';
        case RegionsEnum.FrenchPolynesia:
            return 'PYF';
        case RegionsEnum.Niue:
            return 'NIU';
        case RegionsEnum.Pitcairn:
            return 'PCN';
        case RegionsEnum.Samoa:
            return 'WSM';
        case RegionsEnum.Tokelau:
            return 'TKL';
        case RegionsEnum.Tonga:
            return 'TON';
        case RegionsEnum.Tuvalu:
            return 'TUV';
        case RegionsEnum.WallisAndFutunaIslands:
            return 'WLF';
        default:
            return null;
    }
}
