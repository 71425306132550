/* eslint-disable camelcase */
import { assertPresent } from '@luminovo/commons';
import { PerPcbPanelPostDTO, PerSourcingScenarioPanelPostDTO } from '@luminovo/http-client';
import { PanelFormState } from './types';

export const convertPanelFormValuesToDTO = ({
    formValues,
    pcbId,
}: {
    formValues: PanelFormState;
    pcbId: string;
}): PerSourcingScenarioPanelPostDTO[] | PerPcbPanelPostDTO => {
    switch (formValues.scope) {
        case 'PerSourcingScenario': {
            return formValues.data.map((data) => {
                const { sourcingScenarioId, ...panelDetails } = data;
                return {
                    type: 'PerSourcingScenario',
                    data: {
                        sourcing_scenario: assertPresent(sourcingScenarioId),
                        pcb: pcbId,
                        panel_details: panelDetails,
                    },
                };
            });
        }
        case 'PerPcb': {
            const { sourcingScenarioId, ...panelDetails } = formValues.data[0];
            return {
                type: 'PerPcb',
                data: {
                    pcb: pcbId,
                    panel_details: panelDetails,
                },
            };
        }
        case 'ExistingPanel': {
            throw new Error('Not implemented');
        }
    }
};
