/* eslint-disable camelcase */
import * as r from 'runtypes';
import { runtypeFromEnum } from '../../utils/typingUtils';
import { MonetaryValueBackendRuntype } from '../backendTypes';
import { PackagingRuntype } from '../offer';

export enum LimitType {
    Never = 'Never',
    Days = 'Days',
}

const LimitTypeEnum = runtypeFromEnum(LimitType);

const LimitRuntype = r.Record({
    type: LimitTypeEnum,
    number: r.Number.optional(),
});

export interface ExcessPreferenceDTO extends r.Static<typeof ExcessPreferenceDTORuntype> {}

const ExcessPreferenceDTORuntype = r.Record({
    max_ratio_percent: r.Number.nullable(),
    minimum_savings: MonetaryValueBackendRuntype.nullable(),
});

export interface OfferAgeLimitDTO extends r.Static<typeof OfferAgeLimitDTORuntype> {}
export const OfferAgeLimitDTORuntype = r.Record({
    expiring_before_validity_date: LimitRuntype,
    expired_after_validity_date: LimitRuntype,
    old_after_update: LimitRuntype,
    outdated_after_update: LimitRuntype,
});

export enum SourcingPreferenceOptions {
    Fastest = 'Fastest',
    BestPrice = 'BestPrice',
    BestPriceBy = 'BestPriceBy',
}

export enum PriceType {
    ListPrice = 'ListPrice',
    ContractPrice = 'ContractPrice',
    QuotePrice = 'QuotePrice',
    CustomerNegotiatedPrice = 'CustomerNegotiatedPrice',
    PurchasePrice = 'PurchasePrice',
    StandardPrice = 'StandardPrice',
}
export const PriceTypeRuntype = runtypeFromEnum(PriceType);

const SourcingPreferenceOptionsRuntype = runtypeFromEnum(SourcingPreferenceOptions);

export interface LeadTimePreference extends r.Static<typeof LeadTimePreferenceRuntype> {}

const LeadTimePreferenceRuntype = r.Record({
    type: SourcingPreferenceOptionsRuntype,
    target: r.String.optional(),
});

export const InventoryPreferenceRuntype = r.Union(
    r.Literal('PreferInventory'),
    r.Literal('Neutral'),
    r.Literal('PreferMarketOffers'),
);
export type InventoryPreferenceType = r.Static<typeof InventoryPreferenceRuntype>;

export const SolutionPreferenceDTORuntype = r.Record({
    lead_time_preference: LeadTimePreferenceRuntype,
    preferred_suppliers_and_stock_locations: r.Null.Or(r.Array(r.String)),
    approved_suppliers_and_stock_locations: r.Null.Or(r.Array(r.String)),
    preferred_inventory_sites: r.Null.Or(r.Array(r.String)),
    approved_inventory_sites: r.Null.Or(r.Array(r.String)),
    excess_preference: ExcessPreferenceDTORuntype,
    offer_age_limit: OfferAgeLimitDTORuntype,
    low_stock_warning_factor: r.Number,
    inventory_preference: InventoryPreferenceRuntype,
    use_unit_price: r.Boolean,
    packaging_preference: r.Array(PackagingRuntype),
    prefer_direct_api_integrations: r.Boolean,
    excluded_price_types: r.Array(PriceTypeRuntype),
    exclude_mismatched_price_points: r.Boolean,
    prioritize_preferred_manufacturers: r.Boolean,
});
export interface SolutionPreferenceDTO extends r.Static<typeof SolutionPreferenceDTORuntype> {}
