/* eslint-disable camelcase */
import * as r from 'runtypes';
import { runtypeFromEnum } from '../../utils/typingUtils';
import { BucketRuntype, ComplianceStatus, ElasticTotalHitsDTORuntype, NumericBucketRuntype } from '../backendTypes';
import {
    CustomFullPartRuntype,
    DielectricRuntype,
    GenericFullPartRuntype,
    LifecycleEnum,
    OffTheShelfPartWithFullPackageRuntype,
    OtsFullPartRuntype,
    PackageDTORuntype,
    PackageUserInputRuntype,
    StandardPartTypes,
    TechnicalParametersRuntype,
} from '../part/partBackendTypes';

export type PartMatchTypeFullResponse = r.Static<typeof PartMatchTypeFullResponseRuntype>;
export const PartMatchTypeFullResponseRuntype = r.Union(
    r.Record({
        type: r.Literal('OffTheShelf'),
        data: OtsFullPartRuntype,
    }),
    r.Record({
        type: r.Literal('Generic'),
        data: GenericFullPartRuntype,
    }),
);

export type EmsPartNumberPartSpecification = r.Static<typeof EmsPartNumberPartSpecificationRuntype>;
const EmsPartNumberPartSpecificationRuntype = r.Record({
    mpn: r.String.nullable().optional(),
    manufacturer: r.String.nullable().optional(),
    description: r.String.nullable().optional(),
    package: r.String.nullable().optional(),
    part_type: r.String.nullable().optional(),
});

const MatchingTypeRuntype = r.Record({
    type: r.String,
    id: r.String.optional(),
});

export type MatchingReason = r.Static<typeof MatchingReasonRuntype>;
export const MatchingReasonRuntype = r.Record({
    matching_type: r.Union(r.Literal('Automatic'), r.Literal('Manual')),
    reason: MatchingTypeRuntype.nullable().optional(),
});

export interface EmsPartNumberPartMatchFullPart extends r.Static<typeof EmsPartNumberPartMatchFullPartRuntype> {}
const EmsPartNumberPartMatchFullPartRuntype = r.Record({
    ipn: r.String,
    part: PartMatchTypeFullResponseRuntype,
    matching_reason: MatchingReasonRuntype,
});

export type IpnSuggestableIncompleteGenericFullPart = Extract<
    IpnSuggestablePartFullResponse,
    { type: 'IncompleteGeneric' }
>;
export type IpnSuggestablePartFullResponse = r.Static<typeof IpnSuggestablePartFullResponseRuntype>;
const IpnSuggestablePartFullResponseRuntype = r.Union(
    r.Record({
        type: r.Literal(StandardPartTypes.Generic),
        data: GenericFullPartRuntype,
    }),
    r.Record({
        type: r.Literal('IncompleteGeneric'),
        data: TechnicalParametersRuntype,
    }),
    r.Record({
        type: r.Literal(StandardPartTypes.OffTheShelf),
        data: OtsFullPartRuntype,
    }),
);

const IpnSuggestionReasonRuntype = r.Record({
    type: r.Union(r.Literal('Description'), r.Literal('Mpn'), r.Literal('DescriptionMpn'), r.Literal('DescriptionSpn')),
    id: r.String,
});

export interface IpnSuggestionFullPart extends r.Static<typeof IpnSuggestionFullPartRuntype> {}
const IpnSuggestionFullPartRuntype = r.Record({
    ipn: r.String,
    part: IpnSuggestablePartFullResponseRuntype,
    matching_reason: IpnSuggestionReasonRuntype,
});

export interface CustomerPartNumber extends r.Static<typeof CustomerPartNumberRuntype> {}
const CustomerPartNumberRuntype = r.Record({
    id: r.String,
    value: r.String,
    customer: r.String,
    revision: r.String.nullable(),
    created_at: r.String,
    updated_at: r.String,
});

export interface SupplierPartNumber extends r.Static<typeof SupplierPartNumberRuntype> {}
const SupplierPartNumberRuntype = r.Record({
    supplier_part_number: r.String,
    supplier: r.String.nullable(),
});

export type CapacitorFunction = r.Static<typeof CapacitorFunctionRuntype>;
const CapacitorFunctionRuntype = r.Record({
    type: r.Literal('Capacitor'),
    technical_parameters: r.Record({
        capacitance: r.String.nullable(), // Strings because these values are decimal
        dielectric: DielectricRuntype.nullable(),
        tolerance: r.String.nullable(),
        voltage_rating: r.String.nullable(),
    }),
});

export type ResistorFunction = r.Static<typeof ResistorFunctionRuntype>;
const ResistorFunctionRuntype = r.Record({
    type: r.Literal('Resistor'),
    technical_parameters: r.Record({
        resistance: r.String.nullable(), // Strings because these values are decimal
        power_rating: r.String.nullable(),
        tolerance: r.String.nullable(),
        temperature_coefficient: r.String.nullable(),
        voltage_rating: r.String.nullable(),
    }),
});

export type UnsupportedFunction = r.Static<typeof UnsupportedFunctionRuntype>;
const UnsupportedFunctionRuntype = r.Record({
    type: r.Literal('Unsupported'),
    technical_parameters: r.Record({
        part_type: r.String.nullable(),
    }),
});

export type ComponentFunction = r.Static<typeof FunctionSpecificationRuntype>;
const FunctionSpecificationRuntype = r.Union(
    CapacitorFunctionRuntype,
    ResistorFunctionRuntype,
    UnsupportedFunctionRuntype,
);

export interface ComponentSpecification extends r.Static<typeof ComponentSpecificationRuntype> {}
export const ComponentSpecificationRuntype = r.Record({
    form_and_fit: r.String.nullable(),
    function_specification: FunctionSpecificationRuntype.nullable(),
});

export interface ComponentSpecificationWithFullPackage
    extends r.Static<typeof ComponentSpecificationWithFullPackageRuntype> {}
export const ComponentSpecificationWithFullPackageRuntype = r.Record({
    form_and_fit: PackageDTORuntype.nullable(),
    function_specification: FunctionSpecificationRuntype.nullable(),
});

export interface SpecificationsConflict extends r.Static<typeof SpecificationsConflictRuntype> {}
export const SpecificationsConflictRuntype = r.Record({
    form_and_fit: r.Boolean,
    function_specification_type: r.Boolean,
    function_specification_keys: r.Array(r.String),
});

export enum ComponentTypeEnum {
    Custom = 'Custom',
    OffTheShelf = 'OffTheShelf',
}

export enum ErpDataChangesFilter {
    ReviewRequired = 'ReviewRequired',
    NoReviewRequired = 'NoReviewRequired',
}

export enum LinkedPartsFilter {
    LinkedParts = 'LinkedParts',
    NoLinkedParts = 'NoLinkedParts',
    IncompleteLinkedParts = 'IncompleteLinkedParts',
}

export enum IpnLastChanged {
    Today = 'Today',
    Yesterday = 'Yesterday',
    Last7Days = 'Last7Days',
    MoreThan7DaysAgo = 'MoreThan7DaysAgo',
}

const PartMappedToIpnWithFullPackageRuntype = r.Record({
    OffTheShelf: OffTheShelfPartWithFullPackageRuntype.optional(),
    Generic: GenericFullPartRuntype.optional(),
});

const IpnFullPartMatchRuntype = r.Record({
    id: r.String,
    ipn: r.String,
    part: PartMappedToIpnWithFullPackageRuntype,
    matching_reason: MatchingReasonRuntype,
});

export interface IpnRawSpecification extends r.Static<typeof IpnRawSpecificationRuntype> {}
const IpnRawSpecificationRuntype = r.Record({
    id: r.String,
    mpn: r.String.nullable(),
    manufacturer: r.String.nullable(),
    description: r.String.nullable(),
    package: r.String.nullable(),
    part_type: r.String.nullable(),
    manufacturer_id: r.String.nullable(),
});

export interface IpnWithFullPartMatches extends r.Static<typeof IpnWithFullPartMatchesRuntype> {}
export const IpnWithFullPartMatchesRuntype = r.Record({
    id: r.String,
    state: r.Union(r.Literal('Active'), r.Literal('Removed')),
    part_specifications: r.Array(IpnRawSpecificationRuntype),
    matches: r.Array(IpnFullPartMatchRuntype),
});

export interface IpnWithMatchesFullPart extends r.Static<typeof IpnWithMatchesFullPartRuntype> {}
export const IpnWithMatchesFullPartRuntype = r.Record({
    id: r.String,
    is_preferred: r.Boolean,
    part_specifications: r.Array(EmsPartNumberPartSpecificationRuntype),
    matches: r.Array(EmsPartNumberPartMatchFullPartRuntype),
    suggestions: r.Array(IpnSuggestionFullPartRuntype),
    state: r.Union(r.Literal('Active'), r.Literal('Removed')),
    updated_at: r.String,
    last_imported_at: r.String,
    cpns: r.Array(CustomerPartNumberRuntype),
    has_unreviewed_erp_changes: r.Boolean,
    is_restricted_to_customers: r.Boolean,
    reach_compliant: runtypeFromEnum(ComplianceStatus),
    rohs_compliant: runtypeFromEnum(ComplianceStatus),
    aecq_compliant: runtypeFromEnum(ComplianceStatus),
    lifecycle_status: runtypeFromEnum(LifecycleEnum),
    is_manufacturer_preferred: r.Boolean,
    component_specification: ComponentSpecificationWithFullPackageRuntype.optional(),
    component_specifications: r
        .Record({
            edited: ComponentSpecificationWithFullPackageRuntype.optional(),
            imported: ComponentSpecificationWithFullPackageRuntype.optional(),
            inferred: ComponentSpecificationWithFullPackageRuntype.optional(),
            conflicts: SpecificationsConflictRuntype.optional(),
        })
        .optional(),
    spns: r.Array(SupplierPartNumberRuntype),
    linked_parts_filter: runtypeFromEnum(LinkedPartsFilter),
});

const PartMatchTypeResponseRuntype = r.Union(
    r.Record({
        type: r.Literal(StandardPartTypes.OffTheShelf),
        id: r.String,
    }),
    r.Record({
        type: r.Literal(StandardPartTypes.Generic),
        id: r.String,
    }),
);

export interface EmsPartNumberPartMatchResponse extends r.Static<typeof EmsPartNumberPartMatchResponseRuntype> {}
export const EmsPartNumberPartMatchResponseRuntype = r.Record({
    ipn: r.String,
    part: PartMatchTypeResponseRuntype,
    matching_reason: MatchingReasonRuntype,
});

export interface EmsPartNumberPartMatchRequest extends r.Static<typeof EmsPartNumberPartMatchRequestRuntype> {}
export const EmsPartNumberPartMatchRequestRuntype = r.Record({
    ipn: r.String,
    part_matches: r.Record({
        off_the_shelf: r.Array(r.String),
        generic: r.Array(r.String),
    }),
});

export type CustomComponentFull = r.Static<typeof CustomComponentFullRuntype>;
export const CustomComponentFullRuntype = r.Record({
    id: r.String,
    is_preferred: r.Boolean,
    state: r.Union(r.Literal('Active'), r.Literal('Removed')),
    updated_at: r.String,
    description: r.String,
    last_imported_at: r.String,
    cpns: r.Array(CustomerPartNumberRuntype),
    is_restricted_to_customers: r.Boolean,
    matches: r.Array(CustomFullPartRuntype),
});

export type OtsOrCustomComponent = r.Static<typeof OtsOrCustomComponentRuntype>;
export const OtsOrCustomComponentRuntype = r.Union(
    r.Record({
        type: r.Literal('OffTheShelf'),
        data: IpnWithMatchesFullPartRuntype,
    }),
    r.Record({
        type: r.Literal('Custom'),
        data: CustomComponentFullRuntype,
    }),
);

const AggregationsRuntype = r.Record({
    'part-types': r.Record({
        'type-name': r.Record({ buckets: r.Array(BucketRuntype) }),
    }),
    linked_parts_filter: r.Record({
        buckets: r.Array(r.Record({ key: runtypeFromEnum(LinkedPartsFilter), doc_count: r.Number })),
    }),
    ipn_state: r.Record({
        buckets: r.Array(r.Record({ key: r.Union(r.Literal('Active'), r.Literal('Removed')), doc_count: r.Number })),
    }),
    last_changed: r.Record({
        buckets: r.Array(r.Record({ key: runtypeFromEnum(IpnLastChanged), doc_count: r.Number })),
    }),
    last_imported_at: r.Record({ value: r.String.nullable() }),
    manufacturers: r.Record({ 'manufacturer-id': r.Record({ buckets: r.Array(BucketRuntype) }) }),
    packages: r.Record({ buckets: r.Array(BucketRuntype) }),
    resistance: r.Record({ value: r.Record({ buckets: r.Array(NumericBucketRuntype) }) }),
    capacitance: r.Record({ value: r.Record({ buckets: r.Array(NumericBucketRuntype) }) }),
    voltage_rating: r.Record({ value: r.Record({ buckets: r.Array(NumericBucketRuntype) }) }),
    power_rating: r.Record({ value: r.Record({ buckets: r.Array(NumericBucketRuntype) }) }),
    temperature_coefficient: r.Record({ value: r.Record({ buckets: r.Array(NumericBucketRuntype) }) }),
    tolerance: r.Record({ value: r.Record({ buckets: r.Array(NumericBucketRuntype) }) }),
    has_unreviewed_erp_changes: r.Record({
        buckets: r.Array(
            r.Record({
                key: r.Number,
                key_as_string: r.Union(r.Literal('true'), r.Literal('false')),
                doc_count: r.Number,
            }),
        ),
    }),
});

export const ComponentsSearchResponseDTORuntype = r.Record({
    ipns: r.Array(OtsOrCustomComponentRuntype),
    total_hits: ElasticTotalHitsDTORuntype,
    highlights: r.Dictionary(r.Array(r.String), r.String).optional(),
    aggregations: AggregationsRuntype,
});

export const OTSComponentsSearchResponseDTORuntype = r.Record({
    ipns: r.Array(IpnWithMatchesFullPartRuntype),
    total_hits: ElasticTotalHitsDTORuntype,
    highlights: r.Dictionary(r.Array(r.String), r.String).optional(),
    aggregations: AggregationsRuntype,
});

export interface IpnFormAndFitFormPatchValues extends r.Static<typeof IpnFormAndFitFormPatchValuesRuntype> {}
export const IpnFormAndFitFormPatchValuesRuntype = r.Record({
    form_and_fit: PackageUserInputRuntype.optional(),
    rfq_context: r.Literal('WithinRfQ'),
    rfq_id: r.String,
});
