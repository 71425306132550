import { Trans } from '@lingui/macro';
import { Flexbox, Text, colorSystem } from '@luminovo/design-system';
import { MenuBookRounded } from '@mui/icons-material';
import { analytics } from '../../../../utils/analytics';
import { useColumnMappingGuidance } from './useColumnMappingGuidance';

export const ColumnMappingGuidanceBanner = () => {
    const { openDrawer } = useColumnMappingGuidance();

    return (
        <Flexbox gap="4px" alignItems="center" style={{ cursor: 'pointer' }} marginRight="16px">
            <MenuBookRounded style={{ color: colorSystem.primary[6] }} fontSize="small" />
            <Text
                color={colorSystem.primary[6]}
                onClick={() => {
                    analytics.track('clicked_column_mapping_guidance');
                    openDrawer();
                }}
            >
                <Trans>How to use the column tags?</Trans>
            </Text>
        </Flexbox>
    );
};
