import { useQuery, UseQueryResult } from '@tanstack/react-query';

import { runParsers } from '../../../model/runParsers';
import { useUniversalImporter } from '../context';

export const useRunParsersQueryKey = ['step-check'];

export function useRunParsers(): UseQueryResult<null> {
    const { dispatch, state } = useUniversalImporter();
    const { config, table } = state;

    return useQuery({
        queryKey: [...useRunParsersQueryKey, config],
        queryFn: async () => {
            for await (const intermediateState of runParsers(table ?? [], config)) {
                dispatch({ type: 'setImporterTable', table: intermediateState });
            }
            return null;
        },
        retry: false,
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
        staleTime: Infinity,
        cacheTime: Infinity,
    });
}
