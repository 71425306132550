/* eslint-disable spellcheck/spell-checker */
import { transEnum } from '@luminovo/commons';
import {
    baseMaterialTranslations,
    chamferingTranslations,
    ipc600ClassTranslations,
    layerStackTypeTranslations,
    sideTranslations,
    surfaceFinishTranslations,
    ulMarkingTranslations,
    viaFillingTranslations,
} from '../../../../resources/pcb/i18n';
import { Capability } from '../../../../resources/pcb/pcbFunctions';
import { capitalizeFirstLetter } from '../../../../utils/stringFunctions';

export function getUnit(capabilityName: Capability['name']): string | undefined {
    if (capabilityName === 'board.boardWidth' || capabilityName === 'board.boardHeight') {
        return 'mm';
    }
    if (capabilityName === 'layerStack.innerCopperThickness' || capabilityName === 'layerStack.outerCopperThickness') {
        return 'µm';
    }
    if (capabilityName === 'mechanical.minViaDiameter') {
        return 'mm';
    }
    if (capabilityName === 'layerStack.finalThickness') {
        return 'mm';
    }
    // eslint-disable-next-line spellcheck/spell-checker
    if (capabilityName === 'board.enigThickness') {
        return 'µin';
    }
    if (
        capabilityName === 'layerStack.minInnerLayerStructure' ||
        capabilityName === 'layerStack.minOuterLayerStructure'
    ) {
        return 'mm';
    }
    return undefined;
}

export function formatOptionLabel(capabilityName: Capability['name'], value: string | number | boolean | null): string {
    if (
        capabilityName === 'board.soldermaskSide' ||
        capabilityName === 'board.silkscreenSide' ||
        capabilityName === 'board.peelableMask'
    ) {
        return capitalizeFirstLetter(transEnum(value + '', sideTranslations));
    }
    if (capabilityName === 'mechanical.chamfering') {
        return capitalizeFirstLetter(transEnum(value + '', chamferingTranslations));
    }
    if (capabilityName === 'board.surfaceFinish') {
        return transEnum(value + '', surfaceFinishTranslations);
    }
    if (capabilityName === 'board.ipc600Class') {
        return transEnum(value + '', ipc600ClassTranslations);
    }
    if (capabilityName === 'layerStack.baseMaterial') {
        return transEnum(value + '', baseMaterialTranslations);
    }
    if (capabilityName === 'layerStack.ulMarkingType') {
        return transEnum(value + '', ulMarkingTranslations);
    }
    if (capabilityName === 'mechanical.viaFilling') {
        return transEnum(value + '', viaFillingTranslations);
    }
    if (capabilityName === 'layerStack.layerstackType') {
        return capitalizeFirstLetter(transEnum(value + '', layerStackTypeTranslations));
    }

    const unit = getUnit(capabilityName);
    if (unit) {
        return `${value} ${unit}`;
    }

    return value + '';
}
