import { Trans } from '@lingui/macro';
import { Flexbox, SecondaryButton, TertiaryIconButton, Text, colorSystem } from '@luminovo/design-system';
import { Close as CloseIcon, Info as InfoIcon } from '@mui/icons-material';
import { Box } from '@mui/material';
import { useHistory } from 'react-router';
import { route } from '../../../../../utils/routes';

export const PcbAnalysisNotification = ({
    onClose,
    assemblyId,
    rfqId,
}: {
    onClose: () => void;
    assemblyId: string;
    rfqId: string;
}) => {
    const history = useHistory();

    return (
        <Box padding={'14px'} bgcolor={colorSystem.primary[8]} borderRadius="8px">
            <Flexbox justifyContent={'space-between'} alignItems={'flex-start'} gap={8}>
                <InfoIcon style={{ color: colorSystem.neutral.white }} fontSize="small" />
                <Flexbox flexDirection={'column'} gap={'8px'}>
                    <Text variant={'h4'} color={colorSystem.neutral.white}>
                        <Trans>Your PCB is ready, please review the specification</Trans>
                    </Text>
                    <Text variant="body" color={colorSystem.neutral.white}>
                        <Trans>
                            The board analysis is finished. Please check the extracted data and set your PCB
                            preferences.
                        </Trans>
                    </Text>
                    <SecondaryButton
                        size="medium"
                        style={{ width: 'fit-content', marginBlockStart: '16px' }}
                        onClick={() => {
                            history.push(
                                route('/rfqs/:rfqId/bom/assembly/:assemblyId/pcb/specification', { assemblyId, rfqId }),
                            );
                            onClose();
                        }}
                    >
                        <Trans>Go to specification</Trans>
                    </SecondaryButton>
                </Flexbox>
                <TertiaryIconButton
                    size={'small'}
                    style={{ marginLeft: 'auto', padding: '0px', color: colorSystem.neutral.white }}
                    onClick={onClose}
                >
                    <CloseIcon fontSize="inherit" />
                </TertiaryIconButton>
            </Flexbox>
        </Box>
    );
};
