import { usePersistedState } from '@luminovo/design-system';
import { PCBV2 } from '@luminovo/http-client';
import React from 'react';
import { useLocation } from 'react-router';
import { isPcbApproved, isPcbSetupWithoutFiles } from '../../../../../resources/pcb/pcbFunctions';
import { findRouteId } from '../../../../../utils/routes';
import { usePcbAnalysisProgress } from '../../../utils/usePcbAnalysisProgress';
import { PCBRoutesExceptSpecification } from './const';
import { getPcbAnalysisId } from './getPcbAnalysisId';

export const useShowPcbAnalysisNotification = (pcb: PCBV2) => {
    const { pathname } = useLocation();
    const [showNotification, setShowNotification] = usePersistedState<'true' | 'false'>(
        getPcbAnalysisId(pcb.id),
        'true',
        localStorage,
    );
    const { isPcbAnalysisDone } = usePcbAnalysisProgress(pcb);
    const currentRoute = findRouteId(pathname);

    React.useEffect(() => {
        if (isPcbAnalysisDone === true && showNotification !== 'false') {
            setShowNotification('true');
        }
    }, [isPcbAnalysisDone, setShowNotification, showNotification]);

    if (currentRoute === undefined) {
        return {
            showNotification: showNotification === 'true',
            setShowNotification,
            showNotificationIcon: false,
        };
    }

    if (isPcbSetupWithoutFiles(pcb) === true) {
        return {
            showNotification: false,
            setShowNotification,
            showNotificationIcon: false,
        };
    }

    return {
        showNotification:
            showNotification === 'true' &&
            isPcbAnalysisDone &&
            isPcbApproved(pcb) === false &&
            PCBRoutesExceptSpecification.includes(currentRoute),
        setShowNotification,
        showNotificationIcon: isPcbAnalysisDone
            ? isPcbApproved(pcb) === false && currentRoute !== '/rfqs/:rfqId/bom/assembly/:assemblyId/pcb/specification'
            : false,
    };
};
