import { Comparable, ComparableResult, ComparableWithContext } from './types';

/*
 * Function to chain multiple comparators. I.e. start with the comparators passed
 *  first and only if those are equal try comparing using later passed comparators.
 */
export function chainComparators<TRowData>(
    comp: Comparable<TRowData>,
    ...rest: Comparable<TRowData>[]
): Comparable<TRowData> {
    return (leftItem, rightItem) => {
        let result = comp(leftItem, rightItem);
        let i = 0;
        while (result === ComparableResult.equal && i < rest.length) {
            result = rest[i](leftItem, rightItem);
            i++;
        }
        return result;
    };
}

/*
 * Function to chain multiple comparators. I.e. start with the comparators passed
 *  first and only if those are equal try comparing using later passed comparators.
 */
export function chainComparatorsWithContext<TRowData, TContext>(
    comp: ComparableWithContext<TRowData, TContext>,
    ...rest: ComparableWithContext<TRowData, TContext>[]
): ComparableWithContext<TRowData, TContext> {
    return (leftItem, rightItem, context) => {
        let result = comp(leftItem, rightItem, context);
        let i = 0;
        while (result === ComparableResult.equal && i < rest.length) {
            result = rest[i](leftItem, rightItem, context);
            i++;
        }
        return result;
    };
}

/*
 * Generate comparator for an item by extracting some numerical value from the item.
 */
export function compareByNumber<TRowData>(getter: (item: TRowData) => number): Comparable<TRowData> {
    return (left: TRowData, right: TRowData) => {
        const numA = getter(left);
        const numB = getter(right);
        if (numA === numB) {
            return ComparableResult.equal;
        } else if (numA < numB) {
            return ComparableResult.less;
        }
        return ComparableResult.greater;
    };
}
