import { isPresent } from '@luminovo/commons';
import { PCBV2, PCBV2Properties, PcbSpecificationProperties } from '@luminovo/http-client';
import { Capability } from './usePcbCapabilities';

export function createInitialPcbFormValues({
    pcb,
    fields,
    pcbSide,
}: {
    pcb: PCBV2;
    fields: Capability[];
    pcbSide?: number;
}): PcbSpecificationProperties {
    const analysisValues: PCBV2Properties = pcb.properties;
    const userDefinedValues: PCBV2Properties = pcb.specifications[0]?.settings ?? {
        board: {},
        layerStack: {},
        mechanical: {},
    };

    const initialFormValues: PcbSpecificationProperties = {
        board: {},
        layerStack: {},
        mechanical: {},
        placementSelection: {},
    };

    function merge<T>(defaultValue?: T, analysis?: T, userDefined?: T, pdfSpecificationValue?: T): T | undefined {
        // user defined value → extracted → analysis → default
        if (isPresent(userDefined)) {
            return userDefined;
        }
        if (isPresent(pdfSpecificationValue)) {
            return pdfSpecificationValue;
        }
        if (isPresent(analysis)) {
            return analysis;
        }
        return defaultValue;
    }

    for (const field of fields) {
        const defaultValue = field.restrictions.default;

        // @ts-ignore
        const analysisValue: typeof defaultValue = analysisValues[field.section][field.capabilityName];
        // @ts-ignore
        const userDefinedValue: typeof defaultValue = userDefinedValues[field.section][field.capabilityName];
        // @ts-ignore
        const pdfSpecificationValue: typeof defaultValue = field.extractedFromPdf?.value;
        // @ts-ignore
        initialFormValues[field.section][field.capabilityName] = merge(
            defaultValue,
            analysisValue,
            userDefinedValue,
            pdfSpecificationValue,
        );
    }

    if (pcbSide !== undefined) {
        initialFormValues.placementSelection.pcbSide = pcbSide;
    }

    return initialFormValues;
}
