import { assertUnreachable } from '@luminovo/commons';
import { NOTO_SANS, Tooltip, colorSystem } from '@luminovo/design-system';
import { Edit } from '@mui/icons-material';
import { styled } from '@mui/material';
import React from 'react';
import { ImporterCell, ImporterRow, ParseStatus } from '../../../../types';
import { useInputPopup } from './useInputPopup';

function getCellText(row?: ImporterCell): string {
    return row?.text ?? '';
}

export const EditableCell = React.memo(function EditableCell({
    cell,
    rowIndex,
    columnIndex,
    row,
}: {
    cell?: ImporterCell;
    rowIndex: number;
    columnIndex: number;
    row: ImporterRow;
}): JSX.Element {
    const { hovered, ...onMouseActions } = useOnHover();
    const { openPopup, popup, anchorEl } = useInputPopup({ cell, rowIndex, columnIndex });

    const cellText = getCellText(cell);
    const skipped = row.status === 'skipped';
    const status = cell?.status.status ?? 'pending';

    const message = cell && 'message' in cell.status ? cell.status.message ?? '' : '';

    return (
        <Tooltip title={message} placement="right">
            <StyledTableCell
                style={{
                    backgroundColor: backgroundColor(status, skipped),
                    border: outlineColor(status, skipped, Boolean(anchorEl)),
                    opacity: opacityFromStatus(status, skipped),
                    color: status === 'error' ? colorSystem.red[7] : undefined,
                }}
                onClick={(e) => {
                    const isPopupOpen = Boolean(popup);
                    if (!isPopupOpen) {
                        openPopup(e);
                    }
                }}
                {...onMouseActions}
            >
                {popup}
                {cellText || '-'}

                {hovered && (
                    <Edit
                        style={{
                            position: 'absolute',
                            borderRadius: '100%',
                            top: '50%',
                            right: 8,
                            transform: 'translateY(-50%)',
                            height: 16,
                            width: 16,
                            boxSizing: 'border-box',
                            color: colorSystem.neutral[6],
                        }}
                    />
                )}
            </StyledTableCell>
        </Tooltip>
    );
});

const StyledTableCell = styled('td')({
    fontFamily: NOTO_SANS,
    padding: '4px 8px',
    fontSize: 12,
    background: colorSystem.neutral.white,
    position: 'relative',
    cursor: 'pointer',
    outlineOffset: -1,
    '&:hover': {
        outline: `1px solid ${colorSystem.primary[5]}`,
        borderWidth: 0,
        zIndex: 10,
    },
    // no overflow
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
});

function useOnHover(): { onMouseEnter: () => void; onMouseLeave: () => void; hovered: boolean } {
    const [hovered, setHovered] = React.useState(false);

    const onMouseEnter = React.useCallback(() => {
        setHovered(true);
    }, []);

    const onMouseLeave = React.useCallback(() => {
        setHovered(false);
    }, []);

    return { onMouseEnter, onMouseLeave, hovered };
}

function opacityFromStatus(status: ParseStatus, skipped: boolean): number {
    if (status === 'pending' || skipped) {
        return 0.3;
    }
    return 1;
}

const backgroundColor = (status: ParseStatus, skipped: boolean) => {
    if (status === 'pending' || status === undefined || skipped) {
        return undefined;
    }
    if (status === 'skipped' || skipped) {
        return colorSystem.neutral[1];
    }
    if (status === 'error') {
        return colorSystem.red[1];
    }
    if (status === 'done') {
        return undefined;
    }
    if (status === 'warning') {
        return colorSystem.yellow[1];
    }
    assertUnreachable(status);
};

const outlineColor = (status: ParseStatus, skipped: boolean, selected: boolean) => {
    if (selected) {
        return `1px solid ${colorSystem.primary[5]}`;
    }
    if (status === 'pending' || status === undefined || status === 'skipped' || skipped) {
        return undefined;
    }
    if (status === 'error') {
        return `1px solid ${colorSystem.red[4]}`;
    }
    if (status === 'done') {
        return undefined;
    }
    if (status === 'warning') {
        return `1px solid ${colorSystem.yellow[4]}`;
    }
    assertUnreachable(status);
};
