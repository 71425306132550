/* eslint-disable camelcase */
import { t } from '@lingui/macro';
import { assertUnreachable, formatToIso8601Date } from '@luminovo/commons';
import { ExtractRequestBody, SourceListImportResponseErrorDTO } from '@luminovo/http-client';
import { UniversalImporter } from '@luminovo/universal-importer';
import { ImportStatus } from '@luminovo/universal-importer/src/types';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router';
import { useHttpMutation } from '../../resources/mutation/useHttpMutation';
import { route } from '../../utils/routes';
import { formatError } from '../Error/formatError';

export function SourceListImporterPage() {
    const { enqueueSnackbar } = useSnackbar();
    const { mutateAsync: importSourceList } = useHttpMutation('POST /source-list/import', { snackbarMessage: null });
    const history = useHistory();

    return (
        <UniversalImporter
            title={t`Import source list`}
            batchSize={Infinity}
            hrefBack={route('/parts/components/ipn')}
            onImportDone={() => {
                enqueueSnackbar(t`Import successful`, {
                    variant: 'success',
                    anchorOrigin: {
                        horizontal: 'center',
                        vertical: 'top',
                    },
                });
                history.goBack();
            }}
            onImportBatch={async (batch) => {
                const requestBody: ExtractRequestBody<'POST /source-list/import'> = batch.map((row) => {
                    const { data: rowData } = row;
                    return {
                        internal_part_number: {
                            value: rowData.ipn,
                        },
                        end_date: rowData.end_date ? formatToIso8601Date(rowData.end_date) : undefined,
                        start_date: formatToIso8601Date(rowData.start_date),
                        site_number: rowData.site_number === '' ? undefined : rowData.site_number,
                        supplier_number: rowData.supplier_number ?? null,
                    };
                });

                return importSourceList({
                    requestBody,
                })
                    .then((response) => {
                        if (response.errors.length > 0) {
                            //TODO: This error handling would be so much better if handled in the backend and then parsed properly.
                            return batch.map((row) => {
                                const formatBatchError = (error: SourceListImportResponseErrorDTO) => {
                                    const er = error.error;
                                    switch (er) {
                                        case 'IpnNotFound':
                                            if (error.details.value === row.data.ipn) {
                                                return {
                                                    success: false as const,
                                                    message: t`IPN not found`,
                                                };
                                            }
                                            return;
                                        case 'AmbiguousSiteNumber':
                                            if (error.details === row.data.site_number) {
                                                return {
                                                    success: false as const,
                                                    message: t`Ambiguous site number`,
                                                };
                                            }
                                            return;
                                        case 'InvalidDates':
                                            if (
                                                error.details.start_date === row.data.start_date &&
                                                error.details.end_date === row.data.end_date
                                            ) {
                                                return {
                                                    success: false as const,
                                                    message: t`Invalid dates`,
                                                };
                                            }
                                            return;
                                        case 'SiteNotFound':
                                            if (error.details === row.data.site_number) {
                                                return {
                                                    success: false as const,
                                                    message: t`Site not found`,
                                                };
                                            }
                                            return;
                                        case 'SupplierNotFound':
                                            if (error.details === row.data.supplier_number) {
                                                return {
                                                    success: false as const,
                                                    message: t`Supplier not found`,
                                                };
                                            }
                                            return;
                                        default:
                                            assertUnreachable(er);
                                    }
                                };

                                const getErrorImportStatusesOfBatch = () => {
                                    let importStatuses: ImportStatus[] = [];
                                    response.errors.forEach((error) => {
                                        const importStatus = formatBatchError(error);
                                        if (importStatus) {
                                            importStatuses.push(importStatus);
                                        }
                                    });
                                    return importStatuses;
                                };
                                const errorImportStatuses = getErrorImportStatusesOfBatch();
                                if (errorImportStatuses.length > 0) {
                                    return errorImportStatuses[0];
                                } else {
                                    return {
                                        success: true as const,
                                    };
                                }
                            });
                        }
                        return batch.map((b) => {
                            return {
                                success: true as const,
                            };
                        });
                    })
                    .catch((error) => {
                        return batch.map((row) => {
                            return {
                                success: false as const,
                                message: formatError(error),
                            };
                        });
                    });
            }}
            config={{
                fields: [
                    {
                        id: 'ipn' as const,
                        columnIndices: [0],
                        required: true,
                        parser: { type: 'ipn', options: { ipns: [] } },
                        label: t`IPN`,
                        description: t`The internal part number`,
                    },
                    {
                        id: 'start_date' as const,
                        columnIndices: [1],
                        required: true,
                        parser: { type: 'date-string', options: { trim: true } },
                        label: t`Start date`,
                        description: t`The start_date in format "YYYY-MM-DD". This is the date from which this record is in effect.`,
                    },
                    {
                        id: 'end_date' as const,
                        columnIndices: [2],
                        required: false,
                        parser: { type: 'string', options: { trim: true } },
                        label: t`End date`,
                        description: t`The end_date in format "YYYY-MM-DD". This is the date from which this record is no longer in effect. If present, this must be greater than or equal to start_date.`,
                    },

                    {
                        id: 'supplier_number' as const,
                        columnIndices: [3],
                        required: true,
                        parser: { type: 'string', options: { trim: true } },
                        label: t`Supplier number`,
                        description: t`The supplier number as found in the ERP.`,
                    },
                    {
                        id: 'site_number' as const,
                        columnIndices: [4],
                        required: false,
                        parser: { type: 'string', options: { trim: true } },
                        label: t`Site number`,
                        description: t`The site number (as found in ERP) for which this record is valid. If not present, this record is applied to all sites. In case there are 2 records for same IPN, then the record which matches the site of if the recipient of the demand is used. In case the recipient of the demand is external, the records which have no sites are used.`,
                    },
                ],
            }}
        />
    );
}
