import { t } from '@lingui/macro';
import { isPresent, transEnum } from '@luminovo/commons';
import { Text, colorSystem } from '@luminovo/design-system';
import { PcbSpecificationProperties } from '@luminovo/http-client';
import { Box } from '@mui/material';
import React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { placementSelectionTranslations } from '../../../resources/pcb/i18n';
import { CollapsibleSection } from './CollapsibleSection';
import { PcbFormToggleButton, PcbFormToggleButtonGroup } from './PcbFormToggleButton';

const PLACEMENT_VALUE_MAP = {
    ONE_SIDED: 1,
    TWO_SIDED: 2,
};

export const PlacementsSelection: React.FunctionComponent<{ isEditable: boolean; isEditing: boolean }> = ({
    isEditable,
    isEditing,
}) => {
    const { setValue, control } = useFormContext<PcbSpecificationProperties>();
    const pcbSidesData = useWatch({ control, name: 'placementSelection.pcbSide' });

    if (!isPresent(pcbSidesData)) {
        return null;
    }

    return (
        <Box style={{ borderRadius: '8px', backgroundColor: colorSystem.neutral.white }}>
            <CollapsibleSection
                label={t`Placements`}
                description={t`Please indicate if the parts are mounted on one side only or on both sides.`}
            >
                {isEditing === false ? (
                    <Text variant="body">{transEnum(pcbSidesData, placementSelectionTranslations)}</Text>
                ) : (
                    <PcbFormToggleButtonGroup
                        exclusive
                        size="small"
                        value={pcbSidesData}
                        defaultValue={pcbSidesData}
                        onChange={(_, value: number) => setValue('placementSelection.pcbSide', value)}
                        disabled={!isEditable}
                    >
                        <PcbFormToggleButton value={PLACEMENT_VALUE_MAP.ONE_SIDED}>
                            <Text variant="body-small" style={{ whiteSpace: 'nowrap' }}>
                                {t`One-sided`}
                            </Text>
                        </PcbFormToggleButton>
                        <PcbFormToggleButton value={PLACEMENT_VALUE_MAP.TWO_SIDED}>
                            <Text variant="body-small" style={{ whiteSpace: 'nowrap' }}>
                                {t`Two-sided`}
                            </Text>
                        </PcbFormToggleButton>
                    </PcbFormToggleButtonGroup>
                )}
            </CollapsibleSection>
        </Box>
    );
};
