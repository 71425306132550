import { RightBoxDrawer } from '@luminovo/design-system';
import {
    CustomOptionOfferDTO,
    RfqContext,
    StandardPartInventoryOfferDTO,
    StandardPartMarketOfferDTO,
    StandardPartOfferDTO,
} from '@luminovo/http-client';
import {
    OfferDrawer,
    Solution,
    isCustomComponentOffer,
    isCustomPartOffer,
    isStandardPartInventoryOffer,
    isStandardPartMarketOffer,
    isStandardPartOffer,
} from '@luminovo/sourcing-core';
import React from 'react';
import { useDrawerContext } from '../../../../components/contexts/ModalContext';
import { useSolutionConfiguration } from '../../../../resources/solutionConfiguration/solutionConfigurationHandler';
import { CustomPartDrawerHeader } from './components/CustomPartOfferHeader';
import { InventoryDrawerHeader } from './components/InventoryDrawerHeader';
import { MarketOfferHeader } from './components/MarketOfferHeader';

type MarketOfferDrawerProps = {
    offer: StandardPartMarketOfferDTO;
    rfqContext: RfqContext;
    solution?: Solution;
    solutionConfigurationId?: string;
    fallbackSolutionTags?: Solution['solutionTags'];
};

type InventoryDrawerProps = {
    offer: StandardPartInventoryOfferDTO;
    rfqContext: RfqContext;
    solution?: Solution;
    solutionConfigurationId?: string;
    fallbackSolutionTags?: Solution['solutionTags'];
};

type CustomPartDrawerProps = {
    offer: CustomOptionOfferDTO;
    rfqContext: RfqContext;
    solution?: Solution;
    solutionConfigurationId?: string;
    fallbackSolutionTags?: Solution['solutionTags'];
};

type UseOfferDrawerProps = {
    offer: StandardPartOfferDTO | CustomOptionOfferDTO;
    rfqContext: RfqContext;
    solution?: Solution;
    solutionConfigurationId?: string;
    fallbackSolutionTags?: Solution['solutionTags'];
};

export function useOfferDrawer() {
    const { setDrawer, closeDrawer, isOpen } = useDrawerContext();

    return {
        isOpen,
        openOfferDrawer: (props: UseOfferDrawerProps) => {
            const content = () => {
                const { offer, ...rest } = props;
                if (isStandardPartOffer(offer) && isStandardPartInventoryOffer(offer)) {
                    return <InventoryOfferDrawer offer={offer} {...rest} />;
                }
                if (isStandardPartOffer(offer) && isStandardPartMarketOffer(offer)) {
                    return <MarketOfferDrawer offer={offer} {...rest} />;
                }
                if (isCustomPartOffer(offer)) {
                    return <CustomPartOfferDrawer offer={offer} {...rest} />;
                }
                if (isCustomComponentOffer(offer)) {
                    return <CustomPartOfferDrawer offer={offer} {...rest} />;
                }
                throw new Error("Illegal state: can't open offer drawer");
            };
            return setDrawer(<RightBoxDrawer onClose={closeDrawer}>{content()}</RightBoxDrawer>);
        },
    };
}

const InventoryOfferDrawer: React.FunctionComponent<InventoryDrawerProps> = ({
    offer,
    rfqContext,
    solution,
    solutionConfigurationId,
    fallbackSolutionTags,
}): JSX.Element => {
    const { data: solutionConfiguration } = useSolutionConfiguration(solutionConfigurationId);

    return (
        <OfferDrawer
            header={
                <InventoryDrawerHeader
                    offer={offer}
                    solution={solution}
                    rfqContext={rfqContext}
                    fallbackSolutionTags={fallbackSolutionTags}
                />
            }
            solutionConfiguration={solutionConfiguration}
            standardPartOffer={offer}
            solution={solution}
        />
    );
};

const MarketOfferDrawer: React.FunctionComponent<MarketOfferDrawerProps> = ({
    offer,
    rfqContext,
    solution,
    solutionConfigurationId,
    fallbackSolutionTags,
}): JSX.Element => {
    const { data: solutionConfiguration } = useSolutionConfiguration(solutionConfigurationId);

    return (
        <OfferDrawer
            header={
                <MarketOfferHeader
                    offer={offer}
                    rfqContext={rfqContext}
                    solution={solution}
                    fallbackSolutionTags={fallbackSolutionTags}
                />
            }
            standardPartOffer={offer}
            solution={solution}
            solutionConfiguration={solutionConfiguration}
        />
    );
};

const CustomPartOfferDrawer: React.FunctionComponent<CustomPartDrawerProps> = ({
    offer,
    rfqContext,
    solution,
    solutionConfigurationId,
    fallbackSolutionTags,
}): JSX.Element => {
    const { data: solutionConfiguration } = useSolutionConfiguration(solutionConfigurationId);

    return (
        <OfferDrawer
            header={
                <CustomPartDrawerHeader
                    offer={offer}
                    rfqContext={rfqContext}
                    solution={solution}
                    fallbackSolutionTags={fallbackSolutionTags}
                    sourcingScenarioId={solutionConfiguration?.sourcing_scenario ?? ''}
                />
            }
            customPartOffer={offer}
            solution={solution}
            solutionConfiguration={solutionConfiguration}
        />
    );
};
