import { maxBy } from '@luminovo/commons';
import { CandidateCpn, CustomerPartNumber } from '@luminovo/http-client';
import { HighlightableString } from './highlightableString';
import { highlightIPNMatches } from './ipnHighlighting';

type HighlightableCpnRevision = {
    cpnFragments: HighlightableString;
    revisionFragments: HighlightableString;
};
interface Match {
    id: string;
    match: string;
}

export function highlightCpnRevisionMatches({
    cpns,
    searchQuery,
    candidateCpns,
}: {
    cpns: CustomerPartNumber[];
    searchQuery: string;
    candidateCpns: CandidateCpn[] | undefined;
}): HighlightableCpnRevision {
    const queryOrCandidateCpn = getQueryOrCandidateCpn({ query: searchQuery, candidateCpns });
    const cpnMatch = findBestCpnMatch({ cpns, query: queryOrCandidateCpn });

    const cpnToDisplay: string = cpnMatch.length > 0 ? cpnMatch[0].value : '';
    const cpnFragments: HighlightableString = highlightIPNMatches(cpnToDisplay, queryOrCandidateCpn);

    const revisionToDisplay: string = cpnMatch.length > 0 ? cpnMatch[0].revision ?? '' : '';
    const revisionQuery =
        searchQuery === '' && candidateCpns && candidateCpns.length > 0
            ? `${candidateCpns[0].revision ?? ''}`
            : searchQuery;
    const revisionFragments: HighlightableString = findRevisionFragments({ revisionToDisplay, revisionQuery });

    return {
        cpnFragments: cpnFragments,
        revisionFragments: revisionFragments,
    };
}

function findBestCpnMatch({ cpns, query }: { cpns: CustomerPartNumber[]; query: string }): CustomerPartNumber[] {
    const cpnMatches: Match[] = [];

    for (const cpn of cpns) {
        const cpnHighlighted = highlightIPNMatches(cpn.value, query).filter((c) => c.isHighlighted);
        const revisionHighlighted = cpn.revision
            ? highlightIPNMatches(cpn.revision, query).filter((c) => c.isHighlighted)
            : [];

        const longestMatchWithinSameCpnOrRevision = maxBy([...cpnHighlighted, ...revisionHighlighted], (c) => {
            return c.fragment.length;
        });

        if (longestMatchWithinSameCpnOrRevision) {
            cpnMatches.push({ id: cpn.id, match: longestMatchWithinSameCpnOrRevision.fragment });
        }
    }

    const longestMatchAcrossAllCpns = maxBy(cpnMatches, (c) => c.match.length);

    const fullCpn = cpns.find((cpn) => cpn.id === longestMatchAcrossAllCpns?.id);

    return fullCpn ? [fullCpn] : cpns;
}

function getQueryOrCandidateCpn({
    query,
    candidateCpns,
}: {
    query: string;
    candidateCpns: CandidateCpn[] | undefined;
}): string {
    if (candidateCpns && candidateCpns.length > 0) {
        return `${candidateCpns[0].value} ${candidateCpns[0].revision ?? ''}`;
    } else {
        return query;
    }
}

function findRevisionFragments({
    revisionToDisplay,
    revisionQuery,
}: {
    revisionToDisplay: string;
    revisionQuery: string;
}): HighlightableString {
    if (revisionQuery.length > 0 && revisionQuery === revisionToDisplay) {
        return [
            {
                fragment: revisionToDisplay,
                isHighlighted: true,
            },
        ];
    }
    return highlightIPNMatches(revisionToDisplay, revisionQuery);
}
