import { Trans, t } from '@lingui/macro';
import { Protected } from '@luminovo/auth';
import { PrimaryButton, ReleaseTag, Toolbar, Tooltip } from '@luminovo/design-system';
import { useHttpQuery } from '../../../../resources/http/useHttpQuery';
import { route } from '../../../../utils/routes';
import SourcingScenarioActionMenu from '../SourcingScenarios/SourcingScenarioActionMenu';

export function ToolbarSourcingScenario({
    rfqId,
    sourcingScenarioName,
    sourcingScenarioId,
}: {
    rfqId: string;
    sourcingScenarioName: string;
    sourcingScenarioId: string;
}) {
    return (
        <Toolbar
            breadcrumbs={[
                {
                    title: t`Sourcing`,
                    href: route(`/rfqs/:rfqId/sourcing`, { rfqId }),
                },
                {
                    title: sourcingScenarioName,
                },
            ]}
        >
            <SourcingScenarioActionMenu sourcingScenarioId={sourcingScenarioId} />
            <ButtonPurchase rfqId={rfqId} sourcingScenarioId={sourcingScenarioId} />
        </Toolbar>
    );
}

function ButtonPurchase({ rfqId, sourcingScenarioId }: { rfqId: string; sourcingScenarioId: string }) {
    const { data: count = 0 } = useHttpQuery(
        'GET /purchase-orders/:rfqId',
        {
            pathParams: {
                rfqId,
            },
            queryParams: {
                // eslint-disable-next-line camelcase
                sourcing_scenario_id: sourcingScenarioId,
            },
        },
        { select: (res) => res.pending.length + res.created.length },
    );

    const isDisabled = count === 0;
    const title = count === 0 ? '' : count === 1 ? t`1 purchase order` : t`${count} purchase orders`;

    return (
        <Protected requiredPermissions={['create:purchase_order', 'view:purchase_order']}>
            <Tooltip title={title}>
                <PrimaryButton
                    endIcon={isDisabled ? <></> : <ReleaseTag color="white" label="BETA" />}
                    disabled={isDisabled}
                    href={route('/rfqs/:rfqId/sourcing/scenarios/:sourcingScenarioId/purchase-orders', {
                        rfqId,
                        sourcingScenarioId,
                    })}
                    size="medium"
                >
                    <Trans>Purchase</Trans>
                </PrimaryButton>
            </Tooltip>
        </Protected>
    );
}
