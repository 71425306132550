import { t } from '@lingui/macro';
import { assertUnreachable } from '@luminovo/commons';
import {
    Checkbox,
    TanStackTable,
    Text,
    colorSystem,
    createColumnHelper,
    useTanStackTable,
} from '@luminovo/design-system';
import { CheckCircleRounded, Error, Warning } from '@mui/icons-material';
import { Table, styled } from '@mui/material';
import React from 'react';
import { formatParseStatus } from '../../../../model/formatParseStatus';
import { ImporterRow, ParseStatus } from '../../../../types';
import { useUniversalImporter } from '../../context';
import { ButtonExcludeErrors } from './ButtonExcludeErrors';
import { ButtonRefresh } from './ButtonRefresh';
import { EditableCell } from './EditableCell';

export function TableCheckRows({ rows }: { rows: ImporterRow[] }): JSX.Element {
    const { state } = useUniversalImporter();
    const { config } = state;

    const fields = config.fields;

    const columnFields = React.useMemo(() => {
        return fields.map((field, columnIndex) => {
            return columnHelper.enum((row) => row.cells[columnIndex]?.text, {
                label: () => field.label ?? field.id,
                getOptionLabel: (value) => value ?? '',
                id: field.id,
                size: 300,
                minSize: 300,
                maxSize: 300,
                disableTableCell: true,
                enableColumnFilter: true,
                cell: function Render({ getValue, row }) {
                    return (
                        <EditableCell
                            cell={row.original.cells[columnIndex]}
                            columnIndex={columnIndex}
                            rowIndex={row.original.index}
                            row={row.original}
                        />
                    );
                },
            });
        });
    }, [fields]);

    const columns = React.useMemo(() => [columnSelection, ...columnFields, columnStatus], [columnFields]);

    const tableState = useTanStackTable({
        columns,
        data: rows,
        enableColumnOrdering: false,
    });

    return (
        <TanStackTable
            size="small"
            table={tableState.table}
            overrides={{
                Table: GridTable,
            }}
            ActionButton={() => {
                return (
                    <>
                        <ButtonRefresh />
                        <ButtonExcludeErrors />
                    </>
                );
            }}
            enableMenuBar={{
                globalSearch: false,
                resultCount: false,
                controlButtons: false,
            }}
        />
    );
}

const columnHelper = createColumnHelper<ImporterRow>();

const statuses: ParseStatus[] = ['done', 'error', 'pending', 'skipped', 'warning'];

const columnStatus = columnHelper.enum(
    (row) => {
        return row.status;
    },
    {
        quickFilters: statuses.map((status) => {
            return {
                showCount: true,
                label: () => formatParseStatus(status),
                value: [status],
            };
        }),

        label: () => t`Status`,
        id: 'status',
        enableSorting: false,
        initialPinning: 'right',
        cell: (opt) => {
            const status = opt.getValue();

            const statusIcon = () => {
                if (status === 'skipped') {
                    return <></>;
                }
                if (status === 'pending') {
                    return <Text>...</Text>;
                }
                if (status === 'error') {
                    return <Error style={{ color: colorSystem.red[6], height: 14, marginTop: 2 }} />;
                }
                if (status === 'done') {
                    return <CheckCircleRounded style={{ color: colorSystem.green[6], height: 14, marginTop: 2 }} />;
                }
                if (status === 'warning') {
                    return <Warning style={{ color: colorSystem.yellow[6], height: 14, marginTop: 2 }} />;
                }
                if (status === undefined) {
                    return <></>;
                }
                assertUnreachable(status);
            };

            return <>{statusIcon()}</>;
        },
        getOptionLabel: (status: ParseStatus | undefined) => {
            if (status === undefined) {
                return '';
            }
            return formatParseStatus(status);
        },
        size: 50,
    },
);

const columnSelection = columnHelper.enum((row) => row.status === 'skipped', {
    id: 'Include',
    size: 50,
    label: () => t`Include`,
    enableColumnFilter: false,
    enableSorting: false,
    getOptionLabel: (status: boolean) => (status ? t`Include` : t`Skip`),
    cell: function Render({ row }) {
        const index = row.original.index;
        const { dispatch, state } = useUniversalImporter();
        const isSkipped = row.original.status === 'skipped';
        return (
            <Checkbox
                size="small"
                checked={!isSkipped}
                onChange={() => {
                    dispatch({ type: 'setImporterTable', table: state.importerTable?.skipRow(index) });
                }}
            />
        );
    },
    header: function Render({ table }) {
        const { state, dispatch } = useUniversalImporter();

        const allIncluded = state.importerTable?.countIncluded() ?? 0;
        const allRows = state.importerTable?.getSize() ?? 0;

        const checked = allIncluded === allRows;

        const indices = table
            .getCenterRows()
            .map((r) => r.original)
            .map((r) => r.index);

        return (
            <Checkbox
                size="small"
                checked={checked}
                indeterminate={allIncluded > 0 && allIncluded < allRows}
                onChange={() => {
                    dispatch({ type: 'setImporterTable', table: state.importerTable?.excludeAll(checked, indices) });
                }}
            />
        );
    },
});

const GridTable = styled(Table)({
    borderCollapse: 'collapse',
    borderSpacing: 1,
    background: colorSystem.neutral.white,

    '& td': {
        borderLeft: `1px solid ${colorSystem.neutral[2]}`,
    },

    '& tr:last-child td': {
        borderLeft: `1px solid ${colorSystem.neutral[2]}`,
    },

    '& td:first-child': {
        borderLeft: 'none',
    },

    color: colorSystem.neutral[9],
});
