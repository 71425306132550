import * as r from 'runtypes';
import { QuantityUnitDTORuntype } from '../backendTypes';
import { OneTimeCostDTORuntype } from '../offer';
import { DerivedScrapQuantityDTORuntype, SolutionStatusRuntype } from '../solution/solutionBackendTypes';

/* eslint-disable camelcase */
export interface SolutionConfigurationDTO extends r.Static<typeof SolutionConfigurationDTORuntype> {}
export const SolutionConfigurationDTORuntype = r.Record({
    id: r.String,
    aggregated_quantity: QuantityUnitDTORuntype,
    scrap_quantity: r.Null.Or(DerivedScrapQuantityDTORuntype),
    bom_items: r.Record({
        design_items: r.Record({
            items: r.Array(r.String),
        }),
    }),
    sourcing_scenario: r.String,
    is_consigned: r.Boolean,
    notes: r.String.nullable(),
    manual_unit_costs: r.Array(OneTimeCostDTORuntype).nullable(),
    manual_one_time_costs: r.Array(OneTimeCostDTORuntype).nullable(),
});

export interface SolutionConfigurationPatchDTO extends r.Static<typeof SolutionConfigurationPatchDTORuntype> {}
export const SolutionConfigurationPatchDTORuntype = r.Record({
    manually_selected_solution_token: r.Optional(r.Null.Or(r.String)),
    manual_scrap_quantity: r.Optional(r.Null.Or(r.Number)),
    notes: r.Optional(r.String.nullable()),
    manual_solution_status: r.Optional(SolutionStatusRuntype.nullable()),
    manual_unit_costs: r.Optional(r.Array(OneTimeCostDTORuntype)),
    manual_one_time_costs: r.Optional(r.Array(OneTimeCostDTORuntype)),
});

export interface SolutionConfigurationsBulkPatchDTO
    extends r.Static<typeof SolutionConfigurationsBulkPatchDTORuntype> {}
export const SolutionConfigurationsBulkPatchDTORuntype = r.Record({
    items: r.Array(
        r.Record({
            id: r.String,
            update: SolutionConfigurationPatchDTORuntype,
        }),
    ),
});
export interface SingelSolutionConfigurationPatchResponseDTO
    extends r.Static<typeof SingelSolutionConfigurationPatchResponseDTORuntype> {}
export const SingelSolutionConfigurationPatchResponseDTORuntype = r.Record({
    id: r.String,
    manually_selected_solution: r.Null.Or(
        r.Record({
            token: r.Null.Or(r.String),
        }),
    ),
    manual_scrap_quantity: r.Null.Or(r.Number),
});
