import { t } from '@lingui/macro';
import { assertUnreachable, formatDays } from '@luminovo/commons';
import { Availability, AvailabilityType } from '@luminovo/http-client';

export function formatAvailabilityWithExtraDays({
    availability,
    additionalLeadTimeDays,
    manufacturingLeadTime,
}: {
    availability: Availability | undefined;
    additionalLeadTimeDays: number | undefined;
    manufacturingLeadTime: number | undefined;
}): string {
    if (availability === null || availability === undefined) {
        return t`Unknown`;
    }
    const additionalLeadTimeDaysOrDefault = additionalLeadTimeDays ?? 0;
    const manufacturingLeadTimeOrDefault = manufacturingLeadTime ?? 0;
    const nonSourcingDays = additionalLeadTimeDaysOrDefault + manufacturingLeadTimeOrDefault;
    switch (availability.type) {
        case AvailabilityType.LeadTime:
            return formatDays(Math.max(availability.days + nonSourcingDays, 0));
        case AvailabilityType.PriceBreakLeadTime:
            return formatDays(Math.max(availability.days + nonSourcingDays, 0));
        case AvailabilityType.OnOrderWithUnknownLeadTime:
            // Using message id directly as we want different German translations
            // of "On order" in different contexts.
            return t({ id: 'on.order.indicator', message: 'On order', comment: 'On order with unknown lead time' });
        case AvailabilityType.OnOrder:
            return formatDays(Math.max(availability.days + nonSourcingDays, 0));
        case AvailabilityType.Stock:
            return formatDays(Math.max(nonSourcingDays, 0));
        default:
            /* c8 ignore next */
            assertUnreachable(availability);
    }
}
