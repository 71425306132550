import { Trans } from '@lingui/macro';
import { Flexbox, Text, colorSystem } from '@luminovo/design-system';
import { Box } from '@mui/material';

export const CustomPanelRenderer = () => {
    return (
        <Box
            style={{
                display: 'grid',
                placeItems: 'center',
                background: 'radial-gradient(#eee, #777)',
                height: '100%',
            }}
        >
            <Flexbox
                alignItems={'center'}
                flexDirection={'column'}
                gap={'12px'}
                style={{
                    width: '280px',
                    padding: '28px 12px',
                    textAlign: 'center',
                    backgroundColor: colorSystem.neutral.white,
                    borderRadius: '8px',
                }}
            >
                <Text variant="h1">
                    <Trans>No preview</Trans>
                </Text>
                <Text variant="body-small">
                    <Trans>We can’t generate a preview for an already existing panel</Trans>
                </Text>
            </Flexbox>
        </Box>
    );
};
