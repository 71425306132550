import { ColumnTagsSheetsState } from './types';

const columnMappingStorageKey = 'luminovo.bom-importer.column-mapping';

export function saveColumnMapping({ tableState }: { tableState: ColumnTagsSheetsState }): void {
    sessionStorage.setItem(columnMappingStorageKey, JSON.stringify(tableState));
}

export const defaultTagsState: ColumnTagsSheetsState = { sheets: [{ tags: [] }], selectedSheet: 0, hasChanged: false };

export function loadColumnMapping(): ColumnTagsSheetsState {
    const result = sessionStorage.getItem(columnMappingStorageKey);

    if (!result) {
        return defaultTagsState;
    }

    return JSON.parse(result);
}
