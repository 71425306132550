/* eslint-disable spellcheck/spell-checker */
/* eslint-disable spellcheck/spell-checker */
import { t, Trans } from '@lingui/macro';
import { formatToIso8601Date, isPresent, LanguageEnum, logToExternalErrorHandlers, uniq } from '@luminovo/commons';
import {
    colorSystem,
    Dialog,
    DialogContent,
    DialogTitle,
    FieldMultiSelectControlled,
    FieldTextControlled,
    Flexbox,
    SecondaryButton,
    TertiaryButton,
    TertiaryIconButton,
    Text,
    XlsIcon,
    ZipIcon,
} from '@luminovo/design-system';
import {
    CurrentUserDetailsDTO,
    PartDTO,
    QuotePriceBulkRequestBodyDTO,
    SendQuotePriceRequestBodyDTO,
    SourcingScenarioDTO,
    SupplierAndStockLocationDTO,
    SupplierContactDTO,
    SupplierTag,
    SupportedPartsDTO,
    UserDTO,
} from '@luminovo/http-client';
import { formatSupplierAndStockLocationDTO, hasSupplierTag } from '@luminovo/sourcing-core';
import {
    GetApp as GetAppIcon,
    InfoOutlined as InfoOutlinedIcon,
    OpenInNewRounded as OpenInNewRoundedIcon,
} from '@mui/icons-material';
import { Box, Divider, ListItem, styled } from '@mui/material';
import * as React from 'react';
import { useFormContext, useFormState, useWatch } from 'react-hook-form';
import { useHistory } from 'react-router';
import { useCurrentUserDetailsContext } from '../../../../components/contexts/CurrentUserDetailsContext';
import { useDialogContext } from '../../../../components/contexts/ModalContext';
import { FormContainer } from '../../../../components/formLayouts/FormContainer';
import { SubmitButton } from '../../../../components/formLayouts/SubmitButton';
import { useDownloadSupplierQuotePriceTemplate } from '../../../../resources/export/exportHandler';
import { useHttpMutation } from '../../../../resources/mutation/useHttpMutation';
import { useSupportedPartsOfSupplier } from '../../../../resources/supplier/supplierHandler';
import {
    useSupplierContacts,
    useSupplierContactsFromSupplier,
} from '../../../../resources/supplierContact/supplierContactHandler';
import { useAllOrganizationUsers } from '../../../../resources/user/userHandler';
import { route } from '../../../../utils/routes';
import { useConfirmInviteSupplierContact } from './components/useConfirmInviteSupplierContact';

const StyledOpenInNewRoundedIcon = styled(OpenInNewRoundedIcon)({
    color: colorSystem.neutral[7],
    fontSize: '16px',
    lineHeight: '20px',
});

export type EmailFileManagementInformation = {
    includePcbSpecification: boolean;
    includeShippingPanelSpecification: boolean;
};

type EmailBodyInformation = {
    subject?: string;
    projectInformation?: string;
    dueDate?: string;
    fileManagementInformation: EmailFileManagementInformation;
    quotePortalLink?: string;
};

type InitialState = {
    rfqId: string;
    partIds: PartDTO[];
    suppliersAndStockLocations: SupplierAndStockLocationDTO[];
    sourcingScenarios: SourcingScenarioDTO[];
    shouldFilterByLineCard: boolean;
    generateAllQuantityCombinations: boolean;
    emailBodyInformation: EmailBodyInformation;
};

type SupplierEmail = {
    supplierAndStockLocation: SupplierAndStockLocationDTO;
    supplierContacts: SupplierContactDTO[];
    carbonCopy: Array<SupplierContactDTO | UserDTO>;
    supportedParts: PartDTO[];
    email: {
        subject: string;
        body: string;
    };
};

type EmailManagerFormState = {
    rfqId: string;
    selectedSupplier: SupplierAndStockLocationDTO | undefined;
    sourcingScenarios: SourcingScenarioDTO[];
    supplierEmails: SupplierEmail[];
    shouldFilterByLineCard: boolean;
    generateAllQuantityCombinations: boolean;
    emailBodyInformation: EmailBodyInformation;
};

const StyledInfoOutlinedIconNeutral = styled(InfoOutlinedIcon)({
    color: colorSystem.neutral[6],
    fontSize: '20px',
    lineHeight: '24px',
});

const SupplierListItem: React.FunctionComponent<{
    supplierEmail: SupplierEmail;
    isSelected: boolean;
}> = ({ supplierEmail, isSelected }) => {
    const { setValue } = useFormContext<EmailManagerFormState>();
    const { supplierAndStockLocation, supplierContacts, supportedParts } = supplierEmail;
    const firstSupplierContact = supplierContacts.find((_, index) => index === 0);

    return (
        <ListItem
            key={supplierAndStockLocation.id}
            onClick={() => setValue('selectedSupplier', supplierAndStockLocation)}
            style={{ padding: 0, backgroundColor: isSelected ? colorSystem.primary[1] : undefined }}
        >
            <Flexbox
                flexDirection={'column'}
                gap={8}
                marginX={'24px'}
                paddingY={'20px'}
                width={'100%'}
                borderBottom={`1px solid ${colorSystem.neutral[2]}`}
            >
                <Flexbox justifyContent="space-between">
                    <Text
                        variant={'h4'}
                        color={isSelected ? colorSystem.primary[7] : colorSystem.neutral[8]}
                        style={{
                            maxWidth: '200px',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                        }}
                    >
                        {formatSupplierAndStockLocationDTO(supplierAndStockLocation)}
                    </Text>
                    <Text variant={'body-small'} color={colorSystem.neutral[7]}>{`${supportedParts.length} ${
                        hasSupplierTag(supplierAndStockLocation, SupplierTag.PcbSupplier) ? t`PCB(s)` : t`part(s)`
                    }`}</Text>
                </Flexbox>

                {isPresent(firstSupplierContact) && (
                    <Text variant={'body-small'} color={isSelected ? colorSystem.primary[7] : colorSystem.neutral[8]}>
                        {`${firstSupplierContact.email}  •  ${firstSupplierContact.user_language.toLocaleUpperCase()}${
                            supplierContacts.length > 1 ? ` +${supplierContacts.length - 1}` : ''
                        }`}
                    </Text>
                )}
            </Flexbox>
        </ListItem>
    );
};

const SupplierList: React.FunctionComponent<{}> = () => {
    const { control } = useFormContext<EmailManagerFormState>();

    const selectedSupplier = useWatch({
        control: control,
        name: 'selectedSupplier',
    });

    const supplierEmails = useWatch({
        control: control,
        name: 'supplierEmails',
    });

    return (
        <Flexbox flexDirection={'column'} borderRight={`1px solid ${colorSystem.neutral[2]}`}>
            <Flexbox paddingX={'24px'} paddingY={'20px'} borderBottom={`1px solid ${colorSystem.neutral[2]}`}>
                <Text variant={'h2'} color={colorSystem.neutral[8]}>
                    <Trans>Recipients</Trans>
                </Text>
            </Flexbox>
            <Flexbox flexDirection={'column'} height={'60vh'} style={{ overflowY: 'auto' }}>
                {supplierEmails.map((supplierEmail, key) => (
                    <SupplierListItem
                        key={key}
                        supplierEmail={supplierEmail}
                        isSelected={selectedSupplier?.id === supplierEmail.supplierAndStockLocation.id}
                    />
                ))}
            </Flexbox>
        </Flexbox>
    );
};

const FormItemRecipient = ({
    index,
    supplierEmail,
    resetForm,
}: {
    index: number;
    supplierEmail: SupplierEmail;
    resetForm: (supplierContact: SupplierContactDTO[]) => void;
}) => {
    const { control } = useFormContext<EmailManagerFormState>();

    const { data: supplierContacts = [] } = useSupplierContactsFromSupplier(
        supplierEmail.supplierAndStockLocation.supplier.id,
        {
            refetchOnWindowFocus: true,
        },
    );

    const recipient = useWatch({
        control: control,
        name: `supplierEmails.${index}.supplierContacts`,
    });

    React.useEffect(() => {
        resetForm(recipient);
        // We only want to reset the form when the recipient changes
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [recipient]);

    return (
        <Flexbox gap={24} alignItems={'center'}>
            <Text variant={'h4'} color={colorSystem.neutral[6]} style={{ width: 86 }}>
                <Trans>Recipient</Trans>
            </Text>
            <FieldMultiSelectControlled
                control={control}
                name={`supplierEmails.${index}.supplierContacts`}
                FieldProps={{
                    options: supplierContacts,
                    fullWidth: true,
                    disableClearable: true,
                    getOptionKey: (supplierContact) => supplierContact.id,
                    getOptionLabel: (supplierContact) =>
                        isPresent(supplierContact)
                            ? `${supplierContact.first_name} ${supplierContact.last_name}`
                            : t`No recipient selected`,
                    isOptionEqualToValue: (opt, value) => opt.id === value.id,
                }}
            />
        </Flexbox>
    );
};

const FormItemCarbonCopy = ({ index, supplierEmail }: { index: number; supplierEmail: SupplierEmail }) => {
    const { control, setValue } = useFormContext<EmailManagerFormState>();

    const { data: internalUsers = [] } = useAllOrganizationUsers();
    const { data: supplierContacts = [] } = useSupplierContactsFromSupplier(
        supplierEmail.supplierAndStockLocation.supplier.id,
        {
            refetchOnWindowFocus: true,
        },
    );

    const recipient = useWatch({
        control: control,
        name: `supplierEmails.${index}.supplierContacts`,
    });

    const carbonCopy = useWatch({
        control: control,
        name: `supplierEmails.${index}.carbonCopy`,
    });

    React.useEffect(() => {
        setValue(
            `supplierEmails.${index}.carbonCopy`,
            carbonCopy.filter((cc) => !recipient.some((r) => r.id === cc.id)),
        );
        // We only want to reset the form when the recipient changes
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [recipient]);

    return (
        <Flexbox gap={24} alignItems={'center'}>
            <Text variant={'h4'} color={colorSystem.neutral[6]} style={{ width: 86 }}>
                <Trans>Cc</Trans>
            </Text>
            <FieldMultiSelectControlled
                control={control}
                name={`supplierEmails.${index}.carbonCopy`}
                FieldProps={{
                    size: 'small',
                    options: [...supplierContacts, ...internalUsers],
                    getOptionKey: (supplierContact) => supplierContact.id,
                    getOptionLabel: (supplierContact) => `${supplierContact.first_name} ${supplierContact.last_name}`,
                    isOptionEqualToValue: (opt, value) => opt.id === value.id,
                    getOptionDisabled: (opt) => recipient.some((r) => r.id === opt.id),
                    fullWidth: true,
                    disableCloseOnSelect: true,
                    placeholder: carbonCopy.length === 0 ? t`No other recipients selected` : '',
                    groupBy: (opt) => {
                        if (supplierContacts.some((s) => s.id === opt.id)) {
                            return t`Supplier contacts`;
                        }
                        if (internalUsers.some((s) => s.id === opt.id)) {
                            return t`Internal contributors`;
                        }
                        return t`Other contacts`;
                    },
                }}
            />
        </Flexbox>
    );
};

const FormTitle = ({
    index,
    supplierEmail,
    resetForm,
}: {
    index: number;
    supplierEmail: SupplierEmail;
    resetForm: (supplierContact: SupplierContactDTO[]) => void;
}) => {
    const { control } = useFormContext<EmailManagerFormState>();

    const { dirtyFields } = useFormState({
        control,
    });

    const hasDirtyFields =
        dirtyFields.supplierEmails?.[index]?.email?.subject || dirtyFields.supplierEmails?.[index]?.email?.body;

    const handleOpenSupplierPage = () => {
        window.open(
            route('/supplier/:supplierAndStockLocationId', {
                supplierAndStockLocationId: supplierEmail.supplierAndStockLocation.id,
            }),
            '_blank',
            'noopener noreferrer',
        );
    };

    return (
        <Flexbox alignItems={'center'} justifyContent="space-between">
            <Text variant={'h3'} color={colorSystem.neutral[8]}>
                <Trans>Email</Trans>
            </Text>

            <Flexbox gap={4} alignItems={'center'}>
                {hasDirtyFields && (
                    <>
                        <TertiaryButton size="small" onClick={() => resetForm(supplierEmail.supplierContacts)}>
                            <Trans>Reset form</Trans>
                        </TertiaryButton>
                        <Divider orientation="vertical" flexItem />
                    </>
                )}

                <TertiaryButton
                    size="small"
                    onClick={handleOpenSupplierPage}
                    startIcon={<StyledOpenInNewRoundedIcon />}
                >
                    {formatSupplierAndStockLocationDTO(supplierEmail.supplierAndStockLocation)}
                </TertiaryButton>
            </Flexbox>
        </Flexbox>
    );
};

const FormItemSubject = ({ index }: { index: number }) => {
    const { control } = useFormContext<EmailManagerFormState>();

    return (
        <Flexbox gap={24} alignItems={'center'}>
            <Text variant={'h4'} color={colorSystem.neutral[6]} style={{ width: 86 }}>
                <Trans>Subject</Trans>
            </Text>
            <FieldTextControlled
                control={control}
                name={`supplierEmails.${index}.email.subject`}
                FieldProps={{ fullWidth: true }}
            />
        </Flexbox>
    );
};

const FormItemBody = ({ index }: { index: number }) => {
    const { control } = useFormContext<EmailManagerFormState>();

    return (
        <Flexbox>
            <FieldTextControlled
                control={control}
                name={`supplierEmails.${index}.email.body`}
                FieldProps={{ multiline: true, minRows: 12, fullWidth: true }}
            />
        </Flexbox>
    );
};

const Attachments = ({ supplierEmail }: { supplierEmail: SupplierEmail }) => {
    const { control } = useFormContext<EmailManagerFormState>();

    const rfqId = useWatch({
        control: control,
        name: 'rfqId',
    });

    const sourcingScenarios = useWatch({
        control: control,
        name: 'sourcingScenarios',
    });

    const shouldFilterByLineCard = useWatch({
        control: control,
        name: 'shouldFilterByLineCard',
    });

    const generateAllQuantityCombinations = useWatch({
        control: control,
        name: 'generateAllQuantityCombinations',
    });

    const { includeShippingPanelSpecification, includePcbSpecification } = useWatch({
        control,
        name: 'emailBodyInformation.fileManagementInformation',
    });

    const quotePortalLink = useWatch({
        control,
        name: 'emailBodyInformation.quotePortalLink',
    });

    const { mutateAsync } = useDownloadSupplierQuotePriceTemplate();

    const onClick = async () => {
        /* eslint-disable camelcase */
        const requestBody: QuotePriceBulkRequestBodyDTO = {
            rfq: rfqId,
            supplier_ids: [supplierEmail.supplierAndStockLocation.id],
            part_ids: supplierEmail.supportedParts.map((p) => p.data),
            sourcing_scenario_ids: sourcingScenarios.map((s) => s.id),
            should_filter_by_line_card: shouldFilterByLineCard,
            generate_all_quantity_combinations: generateAllQuantityCombinations,
            should_include_shipping_panel_specification: includeShippingPanelSpecification,
            should_include_pcb_specification: includePcbSpecification,
        };
        /* eslint-enable camelcase */

        await mutateAsync({ requestBody });
    };

    if (isPresent(quotePortalLink)) {
        return null;
    }

    return (
        <Flexbox flexDirection={'column'} gap={8}>
            <Text variant={'h4'} color={colorSystem.neutral[6]}>
                <Trans>Attachments</Trans>
            </Text>
            <Flexbox
                justifyContent="space-between"
                alignItems={'center'}
                border={`1px solid ${colorSystem.neutral[2]}`}
                borderRadius={'4px'}
            >
                <Flexbox gap={8} paddingX={'12px'} alignItems={'center'}>
                    <XlsIcon overflow={'inherit'} />
                    <Text variant={'body-semibold'} color={colorSystem.neutral[8]}>
                        {`${formatSupplierAndStockLocationDTO(supplierEmail.supplierAndStockLocation)}.xls • 
                        ${supplierEmail.supportedParts.length} 
                        ${
                            hasSupplierTag(supplierEmail.supplierAndStockLocation, SupplierTag.PcbSupplier)
                                ? t`PCB(s)`
                                : t`part(s)`
                        }`}
                    </Text>
                </Flexbox>

                <TertiaryIconButton onClick={onClick}>
                    <GetAppIcon fontSize="inherit" />
                </TertiaryIconButton>
            </Flexbox>

            {hasSupplierTag(supplierEmail.supplierAndStockLocation, SupplierTag.PcbSupplier) && (
                <Flexbox
                    justifyContent="space-between"
                    alignItems={'center'}
                    border={`1px solid ${colorSystem.neutral[2]}`}
                    borderRadius={'4px'}
                >
                    <Flexbox gap={8} paddingY={'12px'} paddingX={'12px'} alignItems={'center'}>
                        <ZipIcon overflow={'inherit'} />
                        <Text variant={'body-semibold'} color={colorSystem.neutral[8]}>
                            {t`PCB-Specification.zip`}
                        </Text>
                    </Flexbox>
                </Flexbox>
            )}
        </Flexbox>
    );
};

const EmailEditor: React.FunctionComponent<{}> = () => {
    const { control, setValue, reset } = useFormContext<EmailManagerFormState>();

    const selectedSupplier = useWatch({
        control: control,
        name: 'selectedSupplier',
    });

    const supplierEmails = useWatch({
        control: control,
        name: 'supplierEmails',
    });

    const supplierEmail = supplierEmails.find((elem) => elem.supplierAndStockLocation.id === selectedSupplier?.id);
    const index = supplierEmails.findIndex((elem) => elem.supplierAndStockLocation.id === selectedSupplier?.id);

    const currentUserDetails = useCurrentUserDetailsContext();
    const emailBodyInformation = useWatch({
        control: control,
        name: 'emailBodyInformation',
    });

    if (!isPresent(supplierEmail)) {
        logToExternalErrorHandlers(new Error('Debug: Email Manager opened without a supplier'));
        return (
            <Flexbox alignItems={'center'} justifyContent={'center'}>
                <Text variant="h4">
                    <Trans>No supplier selected</Trans>
                </Text>
            </Flexbox>
        );
    }

    const resetForm = (supplierContacts: SupplierContactDTO[]) => {
        const subject = generateEmailSubject(currentUserDetails, supplierContacts, emailBodyInformation);
        const body = generateEmailBody(
            currentUserDetails,
            supplierContacts,
            emailBodyInformation,
            supplierEmail.supplierAndStockLocation,
        );
        setValue(`supplierEmails.${index}.email.subject`, subject);
        setValue(`supplierEmails.${index}.email.body`, body);
        // Reset the form to remove the dirty fields
        reset({}, { keepValues: true });
    };

    return (
        <Flexbox
            flexDirection={'column'}
            gap={24}
            paddingX={'24px'}
            paddingY={'20px'}
            height={'60vh'}
            style={{ overflowY: 'auto' }}
        >
            <FormTitle key={`title-${index}`} index={index} supplierEmail={supplierEmail} resetForm={resetForm} />
            <FormItemRecipient
                key={`recipient-${index}`}
                index={index}
                supplierEmail={supplierEmail}
                resetForm={resetForm}
            />
            <FormItemCarbonCopy key={`carbon-copy-${index}`} index={index} supplierEmail={supplierEmail} />
            <FormItemSubject key={`subject-${index}`} index={index} />
            <FormItemBody key={`body-${index}`} index={index} />
            <Attachments supplierEmail={supplierEmail} />
        </Flexbox>
    );
};

const ActionsButtons: React.FunctionComponent<{ onBack: () => void }> = ({ onBack }) => {
    const { control } = useFormContext<EmailManagerFormState>();

    const supplierEmails = useWatch({
        control: control,
        name: 'supplierEmails',
    });

    const numberOfValidEmails = supplierEmails.filter(
        (supplierEmail) => supplierEmail.supportedParts.length > 0,
    ).length;

    return (
        <Flexbox
            flexDirection="row"
            gap={8}
            justifyContent="space-between"
            paddingX={'20px'}
            paddingY={'24px'}
            style={{
                position: 'sticky',
                left: 0,
                bottom: 0,
                boxSizing: 'border-box',
                background: colorSystem.neutral.white,
                borderTop: `1px solid ${colorSystem.neutral[2]}`,
            }}
        >
            <SecondaryButton onClick={onBack}>
                <Trans>Back</Trans>
            </SecondaryButton>
            <Flexbox gap={26} alignItems={'center'}>
                <Flexbox gap={8} alignItems={'center'}>
                    <StyledInfoOutlinedIconNeutral />
                    <Text variant={'body-semibold'} color={colorSystem.neutral[8]}>
                        <Trans>We will send a copy of this to your email address.</Trans>
                    </Text>
                </Flexbox>

                <SubmitButton
                    label={
                        numberOfValidEmails > 1
                            ? t`Send ${numberOfValidEmails} emails`
                            : t`Send ${numberOfValidEmails} email`
                    }
                />
            </Flexbox>
        </Flexbox>
    );
};

function generateEmailSubject(
    currentUserDetails: CurrentUserDetailsDTO,
    supplierContacts: SupplierContactDTO[],
    emailBodyInformation: EmailBodyInformation,
): string {
    const { organization } = currentUserDetails;
    const firstSupplierContact = supplierContacts.find((_, index) => index === 0);
    const today = formatToIso8601Date(new Date());

    if (Boolean(emailBodyInformation.subject) && isPresent(emailBodyInformation.subject)) {
        return emailBodyInformation.subject;
    }

    /* eslint-disable spellcheck/spell-checker */
    switch (firstSupplierContact?.user_language ?? 'en') {
        case 'en':
            return `Quote request from ${organization.name} - Quote # {quote_number} (${today})`;
        case 'de':
            return `Angebotsanfrage von ${organization.name} - Quote # {quote_number}  (${today})`;
        case 'es':
            return `Solicitud de presupuesto de ${organization.name} - Quote # {quote_number} (${today})`;
        case 'fr':
            return `Demande de devis de ${organization.name} - Quote # {quote_number} - (${today})`;
    }
    /* eslint-enable spellcheck/spell-checker */
}

function generateEmailBody(
    currentUserDetails: CurrentUserDetailsDTO,
    supplierContacts: SupplierContactDTO[],
    emailBodyInformation: EmailBodyInformation,
    supplier: SupplierAndStockLocationDTO,
) {
    const firstSupplierContact = supplierContacts.find((_, index) => index === 0);

    if (!isPresent(firstSupplierContact)) {
        return '';
    }

    const language = firstSupplierContact.user_language;
    const hasMultipleRecipients = supplierContacts.length > 1;

    const { projectInformation, dueDate, quotePortalLink } = emailBodyInformation;
    const DateFormatter = new Intl.DateTimeFormat(firstSupplierContact.user_language, {
        month: 'long',
        year: 'numeric',
        day: 'numeric',
    });
    /* eslint-disable spellcheck/spell-checker */
    const greetingsSingle = {
        en: `Dear ${firstSupplierContact.first_name} ${firstSupplierContact.last_name},\n\nI hope this email finds you well.\n\n`,
        de: `Guten Tag ${firstSupplierContact.first_name} ${firstSupplierContact.last_name},\n\n`,
        es: `Hola ${firstSupplierContact.first_name} ${firstSupplierContact.last_name},\n\nEspero que vaya todo bien.\n\n`,
        fr: `Bonjour ${firstSupplierContact.first_name} ${firstSupplierContact.last_name},\n\n`,
    };

    const greetingsAll = {
        en: `Dear all,\n\nI hope this email finds you well.\n\n`,
        de: `Guten Tag zusammen,\n\n`,
        es: `Hola a todos,\n\nEspero que vaya todo bien.\n\n`,
        fr: `Bonjour,\n\n`,
    };

    const mainContent = (language: LanguageEnum) => {
        const offTheShelfPartSupplierMessage = {
            en: 'We would like to receive price and lead time information for an upcoming project. May we kindly ask you to send us an updated price and a delivery time for the list of parts in the file attached to this email?\n\n',
            de: 'wir würden gerne Informationen über Preise und Lieferzeiten für ein bevorstehendes Projekt einholen. Dürften wir Sie bitten, uns aktualisierte Preise und Lieferzeiten für die Bauteile im Anhang dieser E-Mail zu schicken?\n\n',
            es: 'Quisieramos recibir información sobre precios y plazos de entrega para un nuevo proyecto. ¿Podría enviarnos un precio actualizado y un plazo de entrega para la lista de piezas que figura en el archivo adjunto a este correo electrónico?\n\n',
            fr: 'Nous souhaiterions faire une demande de prix pour un projet à venir. Pourriez-vous nous faire parvenir un prix actualisé avec les délais pour la liste de composants dans le fichier joint à cet email?\n\n',
        };

        const pcbSupplierMessage = {
            en: 'We would like to receive price and lead time information for an upcoming project. May we kindly ask you to send us an updated price and a delivery time for the PCBs attached to this email? Also attached is the original manufacturing data as well as additional information for the specification of the PCBs.\n\n',
            de: 'wir würden gerne Informationen über Preise und Lieferzeiten für ein bevorstehendes Projekt einholen. Dürften wir Sie bitten, uns Preise und Lieferzeiten für die Leiterplatten im Anhang dieser E-Mail zu schicken? Das angehängte Archiv beinhaltet die original Fertigungsdaten sowie weitere Informationen zur Spezifikation der Leiterplatten.\n\n',
            es: 'Quisieramos recibir información sobre precios y plazos de entrega para un nuevo proyecto. ¿Podría enviarnos un precio actualizado y un plazo de entrega para la lista de PCBs que figura en el archivo adjunto a este correo electrónico?\n\n',
            fr: `Nous aimerions obtenir des informations sur les prix et les délais de livraison pour un projet à venir. Pourriez-vous nous envoyer les prix et les délais de livraison pour les circuits imprimés joints à cet e-mail ? L'archive en pièce jointe contient les données de production originales ainsi que d'autres informations sur les spécifications des circuits imprimés.\n\n`,
        };

        const customPartSupplierMessage = {
            en: 'We would like to receive price and lead time information for an upcoming project. May we kindly ask you to send us price and delivery information for the custom parts referenced in the attached file?\nYou may find additional attachments for the requested custom parts linked in the attached file.\n\n',
            de: 'wir würden gerne Informationen über Preise und Lieferzeiten für ein bevorstehendes Projekt einholen. Dürfen wir Sie bitten, uns Preise und Lieferzeiten für die in der angehängten Datei genannten Zeichnungsteile zukommen zu lassen?\nIn der beigefügten Datei können weitere Anhänge für die angefragten Zeichnungsteile verlinkt sein.\n\n',
            es: 'Nos gustaría recibir información sobre precios y plazos de entrega para un próximo proyecto. Quisiera solicitarle amablemente que nos envíe dicha información para las piezas sobre plano a las que se hace referencia en el archivo adjunto.\nEn dicho adjunto encontrará adjuntos adicionales referente a las piezas sobre plano que se solicitan.\n\n',
            fr: `Nous souhaiterions recevoir de votre part des informations sur les prix et délais pour un projet en cours. Pouvez-vous nous communiquer les prix et les délais de livraison pour les pièces sur plan présentes dans le fichier ci-joint?\nIl se peut que nous joignions d'autres fichiers supplémentaires pour les pièces sur plan demandées.\n\n`,
        };

        if (hasSupplierTag(supplier, SupplierTag.OffTheShelfPartSupplier)) {
            return offTheShelfPartSupplierMessage[language];
        }
        if (hasSupplierTag(supplier, SupplierTag.PcbSupplier)) {
            return pcbSupplierMessage[language];
        }
        if (hasSupplierTag(supplier, SupplierTag.CustomPartSupplier)) {
            return customPartSupplierMessage[language];
        }
    };

    const projectInfo =
        isPresent(projectInformation) && Boolean(projectInformation)
            ? {
                  en: projectInformation,
                  de: projectInformation,
                  es: projectInformation,
                  fr: projectInformation,
              }
            : { en: '', de: '', es: '', fr: '' };

    const dueDateInfo =
        isPresent(dueDate) && Boolean(dueDate)
            ? {
                  en: `\nPlease send us a quote by ${DateFormatter.format(new Date(dueDate))}.\n`,
                  de: `\nBitte senden Sie uns ein Angebot bis ${DateFormatter.format(new Date(dueDate))}.\n`,
                  es: `\nPor favor, envíenos un presupuesto antes del ${DateFormatter.format(new Date(dueDate))}.\n`,
                  fr: `\nMerci de nous communiquer votre devis avant le ${DateFormatter.format(new Date(dueDate))}.\n`,
              }
            : { en: '', de: '', es: '', fr: '' };

    const supplierPortalLink =
        quotePortalLink &&
        (hasSupplierTag(supplier, SupplierTag.PcbSupplier) || hasSupplierTag(supplier, SupplierTag.CustomPartSupplier))
            ? {
                  en: `\nPlease submit your quote through our supplier portal: ${quotePortalLink}`,
                  de: `\nBitte reichen Sie Ihr Angebot über unser Lieferantenportal ein: ${quotePortalLink}`,
                  es: `\nPor favor, envíe su presupuesto a través de nuestro portal de proveedores: ${quotePortalLink}`,
                  fr: `\nVeuillez soumettre votre devis via notre portail fournisseur: ${quotePortalLink}`,
              }
            : { en: '', de: '', es: '', fr: '' };

    const closing = {
        en: `\n\nThank you very much in advance.\n\nKind regards\n${currentUserDetails.user.first_name} ${currentUserDetails.user.last_name}\n${currentUserDetails.organization.name}`,
        de: `\n\nVielen Dank im Voraus.\n\nMit freundlichen Grüßen\n${currentUserDetails.user.first_name} ${currentUserDetails.user.last_name}\n${currentUserDetails.organization.name}`,
        es: `\n\nMuchas gracias de antemano.\n\nCordialmente\n${currentUserDetails.user.first_name} ${currentUserDetails.user.last_name}\n${currentUserDetails.organization.name}`,
        fr: `\n\nEn vous remerciant\n\nCordialement\n${currentUserDetails.user.first_name} ${currentUserDetails.user.last_name}\n${currentUserDetails.organization.name}`,
    };
    /* eslint-enable spellcheck/spell-checker */

    return (
        (hasMultipleRecipients ? greetingsAll[language] : greetingsSingle[language]) +
        mainContent(language) +
        projectInfo[language] +
        dueDateInfo[language] +
        supplierPortalLink[language] +
        closing[language]
    );
}

function createEmailManagerFormState({
    initalState,
    currentUserDetails,
    supplierContactDTOs,
    supportedPartsDTOs,
}: {
    initalState: InitialState;
    currentUserDetails: CurrentUserDetailsDTO;
    supplierContactDTOs: SupplierContactDTO[];
    supportedPartsDTOs: SupportedPartsDTO[];
}) {
    const { shouldFilterByLineCard, suppliersAndStockLocations } = initalState;
    const supplierEmails = suppliersAndStockLocations.flatMap((supplierAndStockLocation) => {
        const supplierContacts = supplierContactDTOs
            .filter((item) => item.supplier === supplierAndStockLocation.supplier.id)
            .filter((item) => item.is_main_contact);

        const supplierLinecardSupportedParts = uniq(
            supportedPartsDTOs
                .filter((item) => item.supplier === supplierAndStockLocation.supplier.id)
                .flatMap((item) => item.part_ids),
        );

        const supportedParts = shouldFilterByLineCard ? supplierLinecardSupportedParts : initalState.partIds;

        if (supplierContacts.length === 0 || supportedParts.length === 0) {
            return [];
        }

        return [
            {
                supplierAndStockLocation,
                supplierContacts,
                carbonCopy: [],
                supportedParts,
                email: {
                    subject: generateEmailSubject(
                        currentUserDetails,
                        supplierContacts,
                        initalState.emailBodyInformation,
                    ),
                    body: generateEmailBody(
                        currentUserDetails,
                        supplierContacts,
                        initalState.emailBodyInformation,
                        supplierAndStockLocation,
                    ),
                },
            },
        ];
    });

    const defaultValues: EmailManagerFormState = {
        ...initalState,
        selectedSupplier: supplierEmails[0]?.supplierAndStockLocation,
        supplierEmails: supplierEmails,
    };

    return { defaultValues };
}

const TitleComponent: React.FunctionComponent<{ title: React.ReactNode }> = ({ title }) => {
    return (
        <Text variant="h1" color={colorSystem.neutral[8]}>
            {title}
        </Text>
    );
};

const EmailManagerForm: React.FunctionComponent<{
    initalState: InitialState;
    onBack: () => void;
}> = ({ initalState, onBack }) => {
    const history = useHistory();
    const { rfqId, partIds, suppliersAndStockLocations } = initalState;

    const { closeDialog } = useDialogContext();
    const currentUserDetails = useCurrentUserDetailsContext();

    const { data: supplierContactDTOs } = useSupplierContacts({ refetchOnWindowFocus: false, suspense: true });
    const { data: supportedPartsDTOs } = useSupportedPartsOfSupplier(
        rfqId,
        partIds,
        suppliersAndStockLocations.map((s) => s.supplier.id),
        { suspense: true },
    );

    const { sendInvite } = useConfirmInviteSupplierContact({
        disableOnError: true,
    });
    const { mutateAsync } = useHttpMutation('POST /export/send-quote-price-mail', {
        snackbarMessage: t`Emails successfully sent`,
        onSuccess: () => {
            closeDialog();
            history.push(route('/rfqs/:rfqId/sourcing', { rfqId: rfqId }));
        },
    });

    if (!isPresent(supplierContactDTOs) || !isPresent(supportedPartsDTOs)) {
        return <></>;
    }

    const { defaultValues } = createEmailManagerFormState({
        initalState,
        currentUserDetails,
        supplierContactDTOs,
        supportedPartsDTOs,
    });

    const onSubmit = async (form: EmailManagerFormState) => {
        /* eslint-disable camelcase */
        const requestBody: SendQuotePriceRequestBodyDTO = {
            rfq: form.rfqId,
            part_ids: partIds.map((p) => p.data),
            should_filter_by_line_card: form.shouldFilterByLineCard,
            generate_all_quantity_combinations: form.generateAllQuantityCombinations,
            sourcing_scenario_ids: form.sourcingScenarios.map((s) => s.id),
            due_date: form.emailBodyInformation.dueDate,
            should_include_shipping_panel_specification:
                form.emailBodyInformation.fileManagementInformation.includeShippingPanelSpecification,
            should_include_pcb_specification:
                form.emailBodyInformation.fileManagementInformation.includePcbSpecification,
            should_include_mail_attachments: form.emailBodyInformation.quotePortalLink ? false : true,
            mail_data: form.supplierEmails.map((supplierEmail) => {
                return {
                    supplier_id: supplierEmail.supplierAndStockLocation.id,
                    supplier_contacts: supplierEmail.supplierContacts.map((re) => re.id),
                    cc: supplierEmail.carbonCopy.map((cc) => cc.id),
                    email: {
                        subject: supplierEmail.email.subject,
                        // SendGrid assumes that the email body is HTML, therfore we need to replace \n with <br>.
                        body: supplierEmail.email.body.replace(new RegExp('\\r?\\n', 'g'), '<br />'),
                    },
                };
            }),
        };
        /* eslint-enable camelcase */

        // Only send invites to pcb suppliers, that are not yet invited
        if (isPresent(form.emailBodyInformation.quotePortalLink)) {
            const pcbSupplierContacts = form.supplierEmails
                .filter(
                    (s) =>
                        hasSupplierTag(s.supplierAndStockLocation, SupplierTag.PcbSupplier) ||
                        hasSupplierTag(s.supplierAndStockLocation, SupplierTag.CustomPartSupplier),
                )
                .flatMap((s) => s.supplierContacts)
                .filter((s) => s.state.state === 'Uninvited' || s.state.state === 'Invited');

            await sendInvite(pcbSupplierContacts);
        }

        await mutateAsync({ requestBody });
    };

    return (
        <FormContainer defaultValues={defaultValues} onSubmit={onSubmit}>
            <Box display="grid" gridTemplateColumns="2fr 3fr">
                <SupplierList />
                <EmailEditor />
            </Box>
            <ActionsButtons onBack={onBack} />
        </FormContainer>
    );
};

export function useEmailManagerDialog() {
    const { setDialog, closeDialog } = useDialogContext();

    return {
        openDialog: (initialState: InitialState, onBack: () => void) =>
            setDialog(
                <Dialog
                    open={true}
                    maxWidth={'md'}
                    fullWidth={true}
                    transitionDuration={0}
                    onClose={() => closeDialog()}
                >
                    <DialogTitle
                        title={t`Email manager`}
                        handleClose={() => closeDialog()}
                        style={{ borderBottom: `1px solid ${colorSystem.neutral[2]}` }}
                        overrides={{ TitleComponent }}
                    />
                    <DialogContent style={{ padding: '0px' }}>
                        <EmailManagerForm initalState={initialState} onBack={onBack} />
                    </DialogContent>
                </Dialog>,
            ),
    };
}
