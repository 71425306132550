import { t } from '@lingui/macro';
import { isPresent } from '@luminovo/commons';
import { CenteredLayout } from '@luminovo/design-system';
import { AvailabilityType, CustomPriceType, RfqContext } from '@luminovo/http-client';
import { CircularProgress } from '@mui/material';
import { useHistory } from 'react-router';
import { useHttpMutation } from '../../../resources/mutation/useHttpMutation';
import { useCustomPartOffer } from '../../../resources/offer/offerHandler';
import { useGlobalCurrency } from '../../../resources/organizationSettings/currencySettingsHandler';
import { useSupplierAndStockLocations } from '../../../resources/supplierAndStockLocation/supplierAndStockLocationHandler';
import { nullToUndefined } from '../../../utils/typingUtils';
import { CustomPartOfferForm, CustomPartOfferFormValues } from './CustomPartOfferForm';
import { convertToCustomPartOfferUpdateDTO } from './converters';

export function EditCustomPartOfferForm({ rfqId, offerId }: { rfqId: string; offerId: string }): JSX.Element {
    const rfqContext: RfqContext = { type: 'WithinRfQ', rfq_id: rfqId };

    const history = useHistory();
    const { data: offer } = useCustomPartOffer(offerId);
    const { data: supplierAndStockLocations } = useSupplierAndStockLocations();
    const { preferredCurrency } = useGlobalCurrency();

    const { mutateAsync } = useHttpMutation('PATCH /offers/custom-part/:id', {
        snackbarMessage: t`Manual offer updated`,
        onSuccess: () => history.goBack(),
    });

    if (!isPresent(offer) || !isPresent(supplierAndStockLocations)) {
        return (
            <CenteredLayout height={'30vh'}>
                <CircularProgress />
            </CenteredLayout>
        );
    }

    const onSubmitCallback = async (values: CustomPartOfferFormValues) => {
        const requestBody = convertToCustomPartOfferUpdateDTO(values);
        await mutateAsync({ pathParams: { id: offer.id }, requestBody });
    };

    const defaultValues: CustomPartOfferFormValues = {
        linkedPart: offer.linked_part,
        priceType: offer.price_type ?? CustomPriceType.ContractPrice,
        supplierAndStockLocation: offer.linked_location,
        currency: offer.price_points[0].unit_price.currency ?? preferredCurrency,
        quantityUnit: offer.unit_of_measurement.quantity_unit,
        pricePoints: offer.price_points.map((p) => ({
            quantity: p.quantity,
            amount: p.unit_price.amount,
            lead_time_days: p.availability.type === AvailabilityType.LeadTime ? p.availability.days : undefined,
        })),
        validUntilDate: nullToUndefined(offer.valid_until),
        oneTimeCosts: offer.one_time_costs.map((c) => ({
            amount: c.price.amount,
            description: c.description ?? undefined,
        })),
        notes: offer.notes,
    };

    return <CustomPartOfferForm rfqContext={rfqContext} onSubmit={onSubmitCallback} defaultValues={defaultValues} />;
}
