import { t } from '@lingui/macro';
import { formatInteger, formatToMonth } from '@luminovo/commons';
import { TanStackTable, createColumnHelper, useTanStackTable } from '@luminovo/design-system';
import { TableDemand, Timeframe } from '@luminovo/manufacturing-core';
import { TableCell } from '@mui/material';

type Row = string[];
type Table = Array<Row>;

interface TableFields {
    type: 'string';
    id: string;
    label?: string;
    columnIndex: number;
}

export function transformYearDemandToTable(
    yearDemands: TableDemand[],
    timeframe: Timeframe,
): { tableFields: TableFields[]; table: Table } {
    const supplierOrRecipientSet = new Set<string>();
    const dateSet = new Set<string>();

    yearDemands.forEach((item) => {
        supplierOrRecipientSet.add(item.supplierOrRecipient);
        dateSet.add(item.demandDate);
    });

    const suppliersOrRecipients = Array.from(supplierOrRecipientSet);
    const dates = Array.from(dateSet).sort();

    const mapDateAndIndex = (date: string, index: number): TableFields => ({
        id: timeframe === 'month' ? formatToMonth(date) : new Date(date).getFullYear().toString(),
        columnIndex: index + 1,
        type: 'string',
    });

    const tableFields: TableFields[] = [
        { id: 'Supplier', columnIndex: 0, type: 'string', label: t`Supplier` },
        ...dates.map(mapDateAndIndex),
    ];

    const table: Table = suppliersOrRecipients.map((supplierOrRecipient) => [
        supplierOrRecipient,
        ...dates.map(() => ''),
    ]);
    //TODO: If both yearDemands and dates can be very long, we may have some performance issues if we have a LOT of demands, since for each demand we're looping over all the dates with indexOf.
    // We may need to port this grouping to the backend https://gitlab.com/luminovo/product/epibator/-/merge_requests/11187#note_2039052426
    yearDemands.forEach((item) => {
        const rowIndex = suppliersOrRecipients.indexOf(item.supplierOrRecipient);
        const columnIndex = dates.indexOf(item.demandDate) + 1;
        table[rowIndex][columnIndex] = formatInteger(parseInt(item.demand));
    });

    return { tableFields, table };
}
const columnHelper = createColumnHelper<Row>();

export function DemandSummaryTableDynamic({ table, fields }: { table: Table; fields: TableFields[] }): JSX.Element {
    const columns = Object.keys(table[0]).map((key, colIndex) => {
        const selected = fields.find((option) => option.columnIndex === colIndex) ?? null;
        const label = selected ? selected.label ?? selected.id : `Column #${colIndex + 1}`;
        return columnHelper.text((row) => row[colIndex] ?? '', {
            id: String(colIndex),
            enableColumnFilter: false,
            enableSorting: false,
            enableGlobalFilter: true,
            enableMultiSort: false,
            label: () => label,
            header: function Header() {
                return (
                    <TableCell style={{ verticalAlign: 'middle', paddingLeft: 0 }} variant="head">
                        {label}
                    </TableCell>
                );
            },
            size: 100,
        });
    });

    const tanstackTable = useTanStackTable({
        columns,
        data: table,
        enablePersistentGlobalFilter: true,
        enableColumnOrdering: false,
        enablePersistentColumnFilters: false,
    });

    return <TanStackTable size="medium" enableMenuBar={false} table={tanstackTable.table} />;
}
