import { assertUnreachable } from '@luminovo/commons';
import { DetailedCustomPartOfferData } from '@luminovo/http-client';
import { convertOfferErrorToText } from './convertOfferErrorToText';
import { PcbSupplierOffer } from './pcbSupplierOfferType';

const getOfferStatus = (offer: DetailedCustomPartOfferData): 'green' | 'yellow' | 'red' | 'neutral' => {
    switch (offer.status.type) {
        case 'OffersAvailable':
            return 'green';
        case 'NoOffersReceived':
            return 'yellow';
        case 'NeedsRefreshForOffers':
            return 'neutral';
        case 'Pending':
        case 'MissingCredentials':
        case 'InvalidCredentials':
        case 'ApiNotSetUp':
        case 'InvalidSpecification':
        case 'PanelError':
        case 'SupplierApiError':
            return 'red';
        default:
            assertUnreachable(offer.status);
    }
};

export const convertOffersToSupplierData = (
    offers: DetailedCustomPartOfferData[],
    approvedSupplierIds: string[],
): PcbSupplierOffer[] => {
    return offers.map((offer) => {
        return {
            name: offer.supplier.name,
            id: offer.supplier.id,
            status: getOfferStatus(offer),
            region: offer.stock_location,
            error: convertOfferErrorToText(offer.status),
            approved: approvedSupplierIds.includes(offer.supplier.id),
        };
    });
};
