export const MOCK_SITE_NUMBER = '0100';
export const MOCK_SITE_NAME = 'Zurich';
export const MOCK_SITE_NUMBER_2 = '0300';
export const MOCK_SITE_NAME_2 = 'Warsaw';
export const MOCK_SUPPLIER_NAME_1 = 'Warsaw';
export const MOCK_SUPPLIER_NUMBER_1 = '62093';
export const MOCK_SUPPLIER_NUMBER_2 = 'supplier-2';
/* eslint-disable-next-line spellcheck/spell-checker */
export const MOCK_SUPPLIER_NAME_2 = 'Karlsruhe';
export const MOCK_CUSTOMER_NUMBER = 'B453-Awesome';
