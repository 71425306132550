import {
    AssemblyIndustry,
    FullPart,
    isGenericFullPart,
    isOtsComponentFull,
    isOtsFullPart,
} from '@luminovo/http-client';
import { ComplianceOverviewChips } from '@luminovo/sourcing-core';

export const PartComplianceView = ({
    part,
    assemblyIndustry,
    disabled = false,
}: {
    part: FullPart;
    assemblyIndustry?: AssemblyIndustry;
    disabled?: boolean;
}): JSX.Element => {
    const isAutomotiveAssembly = assemblyIndustry === 'Auto';
    if (isOtsFullPart(part) || isOtsComponentFull(part) || isGenericFullPart(part)) {
        return (
            <ComplianceOverviewChips
                reach={part.reach_compliant}
                rohs={part.rohs_compliant}
                aecq={isAutomotiveAssembly ? part.aecq_compliant : undefined}
                disabled={disabled}
            />
        );
    }
    return <></>;
};
