import * as r from 'runtypes';
import { runtypeFromEnum } from '../../utils/typingUtils';

export enum CostedBomColumn {
    Designator = 'Designator',
    Quantity = 'Quantity',
    ApprovedParts = 'ApprovedParts',
    PendingParts = 'PendingParts',
    ManufacturerFree = 'ManufacturerFree',
    InternalPartNumber = 'InternalPartNumber',
    Manufacturer = 'Manufacturer',
    Mpn = 'Mpn',
    PartType = 'PartType',
    Description = 'Description',
    Package = 'Package',
    Mounting = 'Mounting',
    Pins = 'Pins',
    PartWarnings = 'PartWarnings',
    AggregatedQuantity = 'AggregatedQuantity',
    ScrapQuantity = 'ScrapQuantity',
    PurchaseQuantity = 'PurchaseQuantity',
    ExcessMaterialQuantity = 'ExcessMaterialQuantity',
    Consignment = 'Consignment',
    Supplier = 'Supplier',
    SupplierNumber = 'SupplierNumber',
    SupplierPartNumber = 'SupplierPartNumber',
    CustomerPartNumber = 'CustomerPartNumber',
    UnitPrice = 'UnitPrice',
    LineValue = 'LineValue',
    UnitPriceOriginalCurrency = 'UnitPriceOriginalCurrency',
    OneTimeCosts = 'OneTimeCosts',
    PurchasePrice = 'PurchasePrice',
    OriginalPurchasePrice = 'OriginalPurchasePrice',
    ExcessMaterialCosts = 'ExcessMaterialCosts',
    OriginalCurrency = 'OriginalCurrency',
    CurrencyExchangeRate = 'CurrencyExchangeRate',
    LeadTimeDays = 'LeadTimeDays',
    Stock = 'Stock',
    OnOrder = 'OnOrder',
    StdFactoryLeadTimeDays = 'StdFactoryLeadTimeDays',
    FactoryQuantity = 'FactoryQuantity',
    Packaging = 'Packaging',
    Moq = 'Moq',
    Mpq = 'Mpq',
    OfferNumber = 'OfferNumber',
    OfferNotes = 'OfferNotes',
    SolutionWarnings = 'SolutionWarnings',
    PriceType = 'PriceType',
    Validity = 'Validity',
    Origin = 'Origin',
    Notes = 'Notes',
    SourcingNotes = 'SourcingNotes',
    LastUpdated = 'LastUpdated',
    Ncnr = 'Ncnr',
    CountryOfOrigin = 'CountryOfOrigin',
    Eccn = 'Eccn',
    HtsCode = 'HtsCode',
    ChinaRohs = 'ChinaRohs',
    Prop65 = 'Prop65',
    ConflictMineralStatus = 'ConflictMineralStatus',
    ReachCompliant = 'ReachCompliant',
    RohsCompliant = 'RohsCompliant',
    RohsVersion = 'RohsVersion',
    LatestDatasheetUrl = 'LatestDatasheetUrl',
    LifecycleYTEOL = 'LifecycleYTEOL',
    LifecycleYTEOLRange = 'LifecycleYTEOLRange',
    LastBuyDate = 'LastBuyDate',
    LastDeliveryDate = 'LastDeliveryDate',
    SvhcCasNumber = 'SvhcCasNumber',
    OriginSetMethod = 'OriginSetMethod',
    BomIpn = 'BomIpn',
    BomCpn = 'BomCpn',
    BomMpn = 'BomMpn',
    AssemblyQuantity = 'AssemblyQuantity',
    AssemblyExcessMaterialQuantity = 'AssemblyExcessMaterialQuantity',
    AssemblyDesignator = 'AssemblyDesignator',
}

export const CostedBomExportTemplateRuntype = r.Record({
    id: r.String,
    name: r.String,
    description: r.String.nullable(),
    is_default: r.Boolean,
    columns: r.Array(runtypeFromEnum(CostedBomColumn)),
    last_modified: r.String,
    include_summary_sheet: r.Boolean,
});

export type CostedBomExportTemplate = r.Static<typeof CostedBomExportTemplateRuntype>;

export const CostedBomExportTemplateInputRuntype = r.Record({
    name: r.String,
    description: r.String.nullable(),
    is_default: r.Boolean,
    included_columns: r.Array(runtypeFromEnum(CostedBomColumn)),
    include_summary_sheet: r.Boolean,
});
