import { assertUnreachable } from '@luminovo/commons';
import { DemandResponseDTO } from '@luminovo/http-client';
import { CompanyRole } from './types';

export const getSupplierOrRecipientNumber = (demand: DemandResponseDTO, company: CompanyRole): string => {
    switch (company) {
        case 'supplier':
            return demand.supplier.kind === 'external' ? demand.supplier.supplier_number : demand.supplier.site_number;
        case 'recipient':
            return demand.recipient.kind === 'external'
                ? demand.recipient.supplier_number
                : demand.recipient.site_number;
        default:
            assertUnreachable(company);
    }
};
