import { currency } from './domain/currency';
import { language } from './domain/language';
import { leadTime } from './domain/leadTime';
import { manufacturerName } from './domain/manufacturerName';
import { monetaryValue } from './domain/monetaryValue';
import { notes } from './domain/notes';
import { packaging } from './domain/packaging';
import { quantity } from './domain/quantity';
import { stock } from './domain/stock';
import { boolean } from './parsers/boolean';
import { number } from './parsers/number';
import { email } from './parsers/regex';

export type { Parser } from './parsers/Parser';

export const parse = {
    packaging,
    currency,
    monetaryValue,
    language,
    notes,
    quantity,
    stock,
    number,
    email,
    boolean,
    manufacturerName,
    leadTime,
};
