import { t, Trans } from '@lingui/macro';
import { isPresent } from '@luminovo/commons';
import { Flexbox } from '@luminovo/design-system';
import { PackageDTO } from '@luminovo/http-client';
import { Typography } from '@mui/material';
import React from 'react';
import { StyledLightTypography } from '../PartSpecificationCardFields';

interface OTSPartDetailsMountingProps {
    packageData: PackageDTO | null;
}

const MountingItem = ({ name, value }: { name: string; value: string }) => (
    <Flexbox flexDirection={'column'} gap={8}>
        <StyledLightTypography variant="h4">{name}</StyledLightTypography>
        <Typography color="textPrimary">{value}</Typography>
    </Flexbox>
);

export const OTSPartDetailsMounting: React.FunctionComponent<OTSPartDetailsMountingProps> = ({
    packageData,
}: OTSPartDetailsMountingProps): JSX.Element => {
    return (
        <Flexbox gap={8} flexDirection="column">
            <Typography variant="h4" color="textSecondary">
                <Trans>Package</Trans>
            </Typography>
            <Flexbox gap={24}>
                {packageData ? (
                    <>
                        {packageData.name && <MountingItem name={t`Name`} value={packageData.name ?? ''} />}
                        {packageData.mounting && <MountingItem name={t`Mounting`} value={packageData.mounting ?? ''} />}
                    </>
                ) : (
                    <Typography>
                        <Trans>Missing</Trans>
                    </Typography>
                )}
                {isPresent(packageData?.number_of_pins) && (
                    <MountingItem name={t`Number of pins`} value={packageData?.number_of_pins.toString() ?? ''} />
                )}
            </Flexbox>
        </Flexbox>
    );
};
