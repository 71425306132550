import { t, Trans } from '@lingui/macro';
import { Currency, formatDecimal, transEnum } from '@luminovo/commons';
import {
    Chip,
    colorSystem,
    Dialog,
    DialogContent,
    DialogTitle,
    FieldNumericControlled,
    FieldSelectControlled,
    FieldTextControlled,
    Flexbox,
    FormItem,
    SecondaryButton,
    SecondaryIconButton,
    TertiaryButton,
    Text,
} from '@luminovo/design-system';
import { OneTimeCostDTO, SolutionConfigurationDTO } from '@luminovo/http-client';
import { hasSolutionConfigurationManualCost, Solution } from '@luminovo/sourcing-core';
import { Add, Delete, Edit } from '@mui/icons-material';
import { InputAdornment } from '@mui/material';
import React from 'react';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import { useDialogContext } from '../../../../components/contexts/ModalContext';
import { FormContainer } from '../../../../components/formLayouts/FormContainer';
import { SubmitButton } from '../../../../components/formLayouts/SubmitButton';
import { inputCurrenciesPublicTranslations } from '../../../../resources/currencyInputTypes';
import { useMutationUpdateSolutionConfiguration } from '../../../../resources/solutionConfiguration/solutionConfigurationHandler';

type FormState = {
    oneTimeCosts: OneTimeCostDTO[];
};

const OneTimeCostItem: React.FunctionComponent<{ index: number; onClickDelete: () => void }> = ({
    index,
    onClickDelete,
}): JSX.Element => {
    const { control } = useFormContext<FormState>();

    const currency = useWatch({
        control,
        name: `oneTimeCosts.${index}.price.currency`,
    });

    return (
        <Flexbox flexDirection="column" border={`1px solid ${colorSystem.neutral[4]}`} borderRadius={'4px'}>
            <Flexbox
                flexDirection="row"
                justifyContent={'space-between'}
                bgcolor={colorSystem.neutral[0]}
                padding={'12px'}
                borderBottom={`1px solid ${colorSystem.neutral[4]}`}
                borderRadius={'4px 4px 0 0'}
            >
                <Text variant={'h3'} color={colorSystem.neutral[8]}>
                    <Trans>One time cost</Trans>
                </Text>
                <SecondaryIconButton size={'small'} onClick={onClickDelete}>
                    <Delete fontSize="inherit" />
                </SecondaryIconButton>
            </Flexbox>
            <Flexbox flexDirection="row" justifyContent={'space-between'} padding={'12px'}>
                <FormItem label={t`Cost`} required>
                    <Flexbox flexDirection="row" gap={8}>
                        <FieldNumericControlled
                            control={control}
                            name={`oneTimeCosts.${index}.price.amount`}
                            required
                            min={0}
                            isFinite={true}
                            FieldProps={{
                                InputProps: {
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            {transEnum(currency, inputCurrenciesPublicTranslations)}
                                        </InputAdornment>
                                    ),
                                },
                            }}
                        />
                        <FieldSelectControlled
                            control={control}
                            name={`oneTimeCosts.${index}.price.currency`}
                            required
                            FieldProps={{
                                options: Object.values(Currency),
                                getOptionLabel: (option) => transEnum(option, inputCurrenciesPublicTranslations),
                                disableClearable: true,
                            }}
                        />
                    </Flexbox>
                </FormItem>

                <FormItem label={t`Description`} style={{ width: '50%' }}>
                    <FieldTextControlled
                        control={control}
                        name={`oneTimeCosts.${index}.description`}
                        FieldProps={{ style: { width: '100%' } }}
                    />
                </FormItem>
            </Flexbox>
        </Flexbox>
    );
};

const ManualCostFormInner: React.FunctionComponent<{ offerCurrency: Currency }> = ({ offerCurrency }): JSX.Element => {
    const { control } = useFormContext<FormState>();

    const { fields, append, remove } = useFieldArray({
        control,
        name: 'oneTimeCosts',
    });

    return (
        <Flexbox flexDirection="column" gap={12}>
            <Text variant={'body'} color={colorSystem.neutral[8]}>
                <Trans>
                    You can manually add extra costs to the selected solution. The manually added costs will only be
                    applied to this sourcing scenario.
                </Trans>
            </Text>
            <Flexbox overflow={'auto'} flexDirection="column" gap={12} style={{ height: '500px' }}>
                {fields.map((_, index) => (
                    <OneTimeCostItem
                        key={`${index}-${fields.length}`}
                        index={index}
                        onClickDelete={() => remove(index)}
                    />
                ))}
            </Flexbox>

            <Flexbox flexDirection="row" justifyContent={'space-between'} alignItems={'center'}>
                <SecondaryButton
                    startIcon={<Add />}
                    disabled={fields.length >= 10}
                    onClick={() => append({ price: { amount: '0', currency: offerCurrency }, description: '' })}
                    style={{ width: 'fit-content' }}
                >
                    <Trans>Add another cost</Trans>
                </SecondaryButton>
                <SubmitButton />
            </Flexbox>
        </Flexbox>
    );
};

function defaultValues({
    solutionConfiguration,
    offerCurrency,
}: {
    solutionConfiguration: SolutionConfigurationDTO;
    offerCurrency: Currency;
}): FormState {
    return {
        oneTimeCosts:
            solutionConfiguration.manual_one_time_costs && hasSolutionConfigurationManualCost(solutionConfiguration)
                ? solutionConfiguration.manual_one_time_costs
                : [{ price: { amount: '0', currency: offerCurrency }, description: '' }],
    };
}

function useManualCostDialog(solutionConfigurationId: string, solution: Solution) {
    const { setDialog, closeDialog } = useDialogContext();

    const { mutateAsync } = useMutationUpdateSolutionConfiguration(solutionConfigurationId);

    const onSubmit = async (formValues: FormState) => {
        await mutateAsync({ manualOneTimeCosts: formValues.oneTimeCosts, solutionToken: solution.token });
        closeDialog();
    };

    const offerCurrency = solution.firstPurchaseOption?.unit_price_original?.currency ?? Currency.EUR;

    return {
        openDialog: ({ solutionConfiguration }: { solutionConfiguration: SolutionConfigurationDTO }) =>
            setDialog(
                <Dialog open={true} maxWidth={'md'} fullWidth={true} onClose={() => closeDialog()}>
                    <DialogTitle title={t`Add costs manually`} handleClose={() => closeDialog()} />
                    <DialogContent style={{ paddingBottom: '24px' }}>
                        <FormContainer
                            defaultValues={defaultValues({ solutionConfiguration, offerCurrency })}
                            onSubmit={onSubmit}
                        >
                            <ManualCostFormInner offerCurrency={offerCurrency} />
                        </FormContainer>
                    </DialogContent>
                </Dialog>,
            ),
    };
}

export const ManualCostButton: React.FunctionComponent<{
    solutionConfiguration: SolutionConfigurationDTO;
    solution: Solution;
}> = ({ solutionConfiguration, solution }): JSX.Element => {
    const { openDialog } = useManualCostDialog(solutionConfiguration.id, solution);

    const count =
        (solutionConfiguration.manual_unit_costs?.length ?? 0) +
        (solutionConfiguration.manual_one_time_costs?.length ?? 0);

    if (hasSolutionConfigurationManualCost(solutionConfiguration)) {
        return (
            <Flexbox alignItems="center" justifyContent={'space-between'} style={{ width: '100%' }}>
                <Chip label={t`Manually added cost • ${formatDecimal(count)}`} color={'neutral'} />
                <TertiaryButton size="small" startIcon={<Edit />} onClick={() => openDialog({ solutionConfiguration })}>
                    <Trans>Edit</Trans>
                </TertiaryButton>
            </Flexbox>
        );
    } else {
        return (
            <TertiaryButton size="small" startIcon={<Add />} onClick={() => openDialog({ solutionConfiguration })}>
                <Trans>Add costs manually</Trans>
            </TertiaryButton>
        );
    }
};
