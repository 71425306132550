import { t } from '@lingui/macro';
import { throwErrorUnlessProduction } from '@luminovo/commons';
import { Column } from '@tanstack/react-table';
import { colorSystem } from '../../../theme';
import { SearchInput } from '../../SearchField';

export function FilterIncludesString<TData>({
    column,
    onClose,
}: {
    column: Column<TData, unknown>;
    onClose: () => void;
}): JSX.Element | null {
    const unsafeValue = column.getFilterValue();

    if (!column.getCanFilter() || column.columnDef.filterFn !== 'includesString') {
        return null;
    }

    if (unsafeValue === undefined || typeof unsafeValue === 'string') {
        return (
            <SearchInput
                placeholder={t`Type to filter`}
                value={unsafeValue}
                debounceWait={50}
                onChange={(value) => column.setFilterValue(Boolean(value) ? value : undefined)}
                onKeyEnter={onClose}
                onClear={() => column.setFilterValue(undefined)}
                style={{ backgroundColor: colorSystem.neutral.white }}
            />
        );
    }

    throwErrorUnlessProduction(new Error('Invalid filter value for includesString filter'));
    return null;
}
