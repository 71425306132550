/* eslint-disable spellcheck/spell-checker */
import { Trans } from '@lingui/macro';
import { compareByString } from '@luminovo/commons';
import {
    Chip,
    DataTable,
    Flexbox,
    MediumContainer,
    Text,
    colorSystem,
    useDataTableState,
} from '@luminovo/design-system';
import { TableCell } from '@mui/material';
import { styled } from '@mui/styles';

type ColumnMappingGuidanceRowTypes = {
    label: JSX.Element;
    description: JSX.Element;
    supportedKeywords?: string[];
};

export const ColumnMappingGuidanceTable = () => {
    const tableState = useDataTableState({
        columns: [columnColumnTag, columnDescription],
        items: items,
        persistenceId: 'column-mapping-guidance',
        paginationOptions: { showPagination: false },
    });
    return <DataTable tableState={tableState} size="medium" overrides={{ Container }} />;
};

const columnColumnTag = {
    id: `columnTag`,
    label: '',
    renderHead: () => (
        <TableCell>
            <Text variant="h5">
                <Trans>Column tag</Trans>
            </Text>
        </TableCell>
    ),
    render: ({ data }: { data: ColumnMappingGuidanceRowTypes }) => {
        return (
            <TableCell>
                <Chip color="primary" label={data.label} />
            </TableCell>
        );
    },
};

const columnDescription = {
    id: `columnTag`,
    label: '',
    renderHead: () => (
        <TableCell>
            <Text variant="h5">
                <Trans>Description</Trans>
            </Text>
        </TableCell>
    ),
    render: ({ data }: { data: ColumnMappingGuidanceRowTypes }) => {
        if (!data.supportedKeywords) {
            return <TableCell>{data.description}</TableCell>;
        }
        return (
            <TableCell style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
                {data.description}
                <Flexbox gap="4px" maxWidth="1000px">
                    <Text variant="body-small-semibold">
                        <Trans>Supported keywords:</Trans>{' '}
                        <Text variant="code" color={colorSystem.neutral[9]} style={{ fontSize: '12px' }}>
                            {data.supportedKeywords.sort(compareByString).join(', ')}
                        </Text>
                    </Text>
                </Flexbox>
            </TableCell>
        );
    },
};

const items = [
    {
        label: <Trans>Designator</Trans>,
        description: (
            <Trans>To uniquely identify and reference components in the assembly. Can only be assigned once.</Trans>
        ),
    },
    {
        label: <Trans>Quantity</Trans>,
        description: <Trans>To determine the number of units per designator. Can only be assigned once.</Trans>,
    },
    {
        label: <Trans>Unit</Trans>,
        description: <Trans>To determine the unit of measurement of the components.</Trans>,
        supportedKeywords: [
            'pieces',
            'piece',
            'pcs',
            'pcs.',
            'pc',
            'pc.',
            'pce',
            'pce.',
            'stück',
            'stueck',
            'stck',
            'stck.',
            'stk',
            'stk.',
            'st',
            'st.',
            'kg',
            'kilogramm',
            'kilogram',
            'kilograms',
            'kilogramme',
            'kilogrammes',
            'kilo',
            'g',
            'gramms',
            'grams',
            'gram',
            'gramm',
            'gramme',
            'grammes',
            'liters',
            'liter',
            'litre',
            'litres',
            'l',
            'ml',
            'milliliter',
            'millilitre',
            'inch',
            'inches',
            'in',
            'yards',
            'yard',
            'yd',
            'yd.',
            'meters',
            'meter',
            'mètre',
            'mètres',
            'm',
            'cm',
            'centimeter',
            'centimeters',
            'centimetre',
            'centimetres',
            'mm',
            'millimeter',
            'millimeters',
            'millimetre',
            'millimetres',
            'each',
        ],
    },
    {
        label: <Trans>Do not place</Trans>,
        description: <Trans>To determine components that aren’t populated on the assembly (DNP).</Trans>,
        supportedKeywords: [
            'dnp',
            'dnf',
            'nc',
            'np',
            'n.c',
            'n.c.',
            'non cablé',
            'non cablés',
            'non monté',
            'non montés',
            'nb',
            'n.b',
            'n.b.',
            'nm',
            'n.m',
            'n.m.',
            'n.i.',
            'n i',
            'not fitted',
            'not_fitted',
            'no fit',
            'nicht bestücken',
            'nicht bestückt',
            'non monté',
            'do not populate',
        ],
    },
    {
        label: <Trans>Consigned</Trans>,
        description: <Trans>To identify externally supplied components to be excluded of the material cost.</Trans>,
        supportedKeywords: [
            'yes',
            'ja',
            'si',
            'oui',
            'yeah',
            'y',
            't',
            'true',
            'consigned',
            'beigestellt',
            'free issue',
            'beistellung',
            'cust. provided',
            'customer provided',
            'cliente',
            'consignado',
            'consignment',
        ],
    },
    {
        label: <Trans>Manufacturer</Trans>,
        description: <Trans>To determine the manufacturer name of parts bound to a specific MPN.</Trans>,
    },
    {
        label: <Trans>Manufacturer free</Trans>,
        description: <Trans>To determine components that are manufacturer-free and not bound to a specific MPN.</Trans>,
        supportedKeywords: [
            'any',
            'frei',
            'free',
            '-',
            '#',
            '*',
            'open',
            'open source',
            'generic',
            'diverse',
            'divers',
            'herstellerfrei',
            'hersteller frei',
            'various',
            'undefiniert',
            'vorzugshersteller',
            'keine herstellerbindung',
            'all',
            'alle',
        ],
    },
    {
        label: <Trans>MPN</Trans>,
        description: (
            <Trans>
                To identify manufacturer-bound parts. Material Part Numbers are assigned to parts by the manufacturer.
            </Trans>
        ),
    },
    {
        label: <Trans>SPN</Trans>,
        description: (
            <Trans>
                To identify manufacturer-bound parts. Supplier Part Numbers are assigned to parts & package by the
                supplier (e.g,. Digi-Key).
            </Trans>
        ),
    },
    {
        label: <Trans>IPN</Trans>,
        description: (
            <Trans>
                To identify internal components of EMS if Internal Part Number was uploaded from ERP system of EMS.
            </Trans>
        ),
    },
    {
        label: <Trans>CPN</Trans>,
        description: (
            <Trans>
                To identify internal components of customer if Customer Part Number is known in ERP system of EMS.
            </Trans>
        ),
    },
    {
        label: <Trans>CPN revision</Trans>,
        description: (
            <Trans>
                To identify internal components of the customer if CPN Revision is known in the ERP system of the EMS.
            </Trans>
        ),
    },
    {
        label: <Trans>Technical parameters</Trans>,
        description: (
            <Trans>To identify manufacturer-free parts (SMT resistors or ceramic capacitors) or IPN components.</Trans>
        ),
    },
    {
        label: <Trans>Notes</Trans>,
        description: (
            <Trans>
                To copy one or concatenate several columns into notes and a column in the costed BOM export (Sourcing).
            </Trans>
        ),
    },
    {
        label: <Trans>Copy to BOM export</Trans>,
        description: (
            <Trans>To copy one or concatenate several columns into a column in the BOM export (Design).</Trans>
        ),
    },
];

export const Container = styled(MediumContainer)(() => ({
    '& .MuiTableCell-body': {
        height: 'unset',
        padding: '12px',
    },
}));
