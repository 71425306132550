import * as r from 'runtypes';
import { DriverIdRuntype } from '../driver';

const OKDriverStatusResponseRuntype = r.Record({
    type: r.Literal('Ok'),
});

export type OKDriverStatusDTO = r.Static<typeof OKDriverStatusResponseRuntype>;

export const DriverStatusDesignItemRuntype = r.Record({
    id: r.String,
    designator: r.String.nullable(),
    assembly: r.String,
});

export type DriverStatusDesignItem = r.Static<typeof DriverStatusDesignItemRuntype>;

const MissingDesignItemInformationRuntype = r.Record({
    type: r.Literal('MissingDesignItemInformation'),
    data: r.Array(DriverStatusDesignItemRuntype),
});
export type MissingDesignItemInformationDTO = r.Static<typeof MissingDesignItemInformationRuntype>;

const MissingPnpInformationRuntype = r.Record({
    type: r.Literal('MissingPnpInformation'),
    data: r.Array(DriverStatusDesignItemRuntype),
});
export type MissingPnpInformationDTO = r.Static<typeof MissingPnpInformationRuntype>;

const MissingOfferDataRuntype = r.Record({
    design_item: DriverStatusDesignItemRuntype,
    sourcing_scenario_id: r.String,
});

const AssemblyTypeNotPCBARuntype = r.Record({
    type: r.Literal('AssemblyNotPCBA'),
});

const MissingOfferRuntype = r.Record({
    type: r.Literal('MissingOffer'),
    data: r.Array(MissingOfferDataRuntype),
});
export type MissingOfferDriverStatusDTO = r.Static<typeof MissingOfferRuntype>;

const WarningDriverStatusResponseRuntype = r.Record({
    type: r.Literal('Warning'),
    data: r.Union(
        MissingDesignItemInformationRuntype,
        MissingPnpInformationRuntype,
        MissingOfferRuntype,
        AssemblyTypeNotPCBARuntype,
    ),
});
export type WarningDriverStatusDTO = r.Static<typeof WarningDriverStatusResponseRuntype>;

const MissingDriverCountRuntype = r.Record({
    type: r.Literal('MissingDriverCount'),
});

const MissingPcbInformationRuntype = r.Record({
    type: r.Literal('MissingPcbInformation'),
});

const MissingPnpFileRuntype = r.Record({
    type: r.Literal('MissingPnpFile'),
});

const NoSourcingScenarioLinkedRuntype = r.Record({
    type: r.Literal('NoSourcingScenarioLinked'),
    data: r.Record({
        manufacturing_scenario_id: r.String,
    }),
});
const ErrorDriverStatusResponseRuntype = r.Record({
    type: r.Literal('Error'),
    data: r.Union(
        MissingDriverCountRuntype,
        NoSourcingScenarioLinkedRuntype,
        MissingPcbInformationRuntype,
        MissingPnpFileRuntype,
    ),
});
export type ErrorDriverStatusDTO = r.Static<typeof ErrorDriverStatusResponseRuntype>;

export const DriverStatusRuntype = r.Union(
    OKDriverStatusResponseRuntype,
    WarningDriverStatusResponseRuntype,
    ErrorDriverStatusResponseRuntype,
);
export type DriverStatusDTO = r.Static<typeof DriverStatusRuntype>;
export const DriverStatusWithDriverIdRuntype = r.Record({
    driver_id: DriverIdRuntype,
    status: DriverStatusRuntype,
});
export type DriverStatusWithDriverIdDTO = r.Static<typeof DriverStatusWithDriverIdRuntype>;

const DriverStatusInvalidFormulaRuntype = r.Record({
    type: r.Literal('InvalidFormula'),
});
const DriverStatusActualStatusRuntype = r.Record({
    type: r.Literal('Status'),
    details: DriverStatusWithDriverIdRuntype,
});

export const DriverStatusDetailsRuntype = r.Union(DriverStatusInvalidFormulaRuntype, DriverStatusActualStatusRuntype);
export type DriverStatusDetailsDTO = r.Static<typeof DriverStatusDetailsRuntype>;
