import React from 'react';
import { colorSystem } from '../../theme';
import { Text } from '../Text/Text';

export interface TagProps {
    color: keyof typeof colorSystem;
    label: string | JSX.Element;
    attention?: 'high' | 'low' | 'formula';
    style?: React.CSSProperties;
}

const defaultStyle: React.CSSProperties = {
    width: 'fit-content',
    height: 'fit-content',
    cursor: 'inherit',
    whiteSpace: 'nowrap',
    borderRadius: '4px',
};

export const Tag = React.forwardRef(
    ({ label, color, attention = 'high', style }: TagProps, ref: React.Ref<HTMLDivElement>): JSX.Element => {
        switch (attention) {
            case 'high':
                return (
                    <Text
                        ref={ref}
                        variant="h5"
                        color={colorSystem[color][8]}
                        style={{
                            ...defaultStyle,
                            padding: '2px 4px',
                            backgroundColor: colorSystem[color][2],
                            ...style,
                        }}
                    >
                        {label}
                    </Text>
                );
            case 'low':
                return (
                    <Text
                        ref={ref}
                        variant="body-small"
                        color={colorSystem[color][8]}
                        style={{
                            ...defaultStyle,
                            padding: '1px 3px',
                            backgroundColor: colorSystem[color][1],
                            border: `1px solid ${colorSystem[color][2]}`,
                            ...style,
                        }}
                    >
                        {label}
                    </Text>
                );
            case 'formula':
                return (
                    <Text
                        ref={ref}
                        variant="body-small"
                        color={colorSystem[color][color === 'neutral' || color === 'yellow' ? 8 : 7]}
                        style={{
                            ...defaultStyle,
                            padding: '1px 3px',
                            backgroundColor: colorSystem.neutral[1],
                            border: `1px solid ${colorSystem.neutral[2]}`,
                            ...style,
                        }}
                    >
                        {label}
                    </Text>
                );
        }
    },
);
