import { DescendantsDTO, ParentAssembly } from '@luminovo/http-client';
import React from 'react';
import { useHistory } from 'react-router';
import { getSpecificAssemblyPath, useParentsIdentifiers } from '../../../resources/assembly/assemblyHandler';
import { useQueryParams } from '../../../resources/hooks';
import { useBOMTableData } from './ModuleTableData/useBOMTableData';

function useShowTopLevelAssembly(): boolean {
    const { hideTopLevelAssembly } = useQueryParams<'/rfqs/:rfqId/bom'>();
    return hideTopLevelAssembly !== 'true';
}

export function useChangeBomPageIfSingleAssembly({ rfqId, assemblyId }: { rfqId: string; assemblyId: string }): void {
    const history = useHistory();
    const { descendants } = useBOMTableData({ assemblyId, rfqId });
    // eslint-disable-next-line camelcase
    const { data: { multi_parent_items: multiParentItems = [] } = {} } = useParentsIdentifiers(assemblyId, {
        suspense: true,
    });
    const showTopLevelAssembly = useShowTopLevelAssembly();
    const topLevelAssemblyId = getTopLevelAssemblyId(showTopLevelAssembly, multiParentItems, descendants, assemblyId);

    React.useEffect(() => {
        if (descendants !== undefined && topLevelAssemblyId) {
            history.replace(
                getSpecificAssemblyPath({ rfqId, assemblyId: topLevelAssemblyId, currentParentAssemblyId: null }),
            );
        }
    }, [multiParentItems, showTopLevelAssembly, topLevelAssemblyId, assemblyId, descendants, history, rfqId]);
}

function getTopLevelAssemblyId(
    showTopLevelAssembly: boolean,
    parents: ParentAssembly[][],
    descendants: DescendantsDTO | undefined,
    assemblyId: string,
): string | undefined {
    const parentsFirstPath = parents[0] ?? [];
    if (showTopLevelAssembly && parentsFirstPath.length === 1 && descendants !== undefined) {
        if (descendants.assemblies.filter((assembly) => assemblyId in assembly.parents).length === 1) {
            return descendants.assemblies[0].id;
        }
    }
    return undefined;
}
