import { useAuth0 } from '@auth0/auth0-react';
import { t } from '@lingui/macro';
import { transEnum } from '@luminovo/commons';
import { CenteredLayout, NonIdealState } from '@luminovo/design-system';
import { ErrorCode } from '@luminovo/http-client';
import { CheckCircleOutlineRounded, Error, ExitToApp, Lock, NetworkWifiOutlined, Refresh } from '@mui/icons-material';
import { ORIGIN } from '../../const';
import { errorCodeTranslations } from '../../modules/Error/errorCodeTranslations';
import { analytics } from '../../utils/analytics';

export function NewVersionAvailable(): JSX.Element {
    return (
        <CenteredLayout>
            <NonIdealState
                title={t`New version available`}
                description={t`A new version of Luminovo is available, please reload the page to update.`}
                variant="success"
                Icon={CheckCircleOutlineRounded}
                action={{
                    onClick: () => window.location.reload(),
                    variant: 'outlined',
                    color: 'primary',
                    startIcon: <Refresh />,
                    children: t`Refresh page`,
                }}
            />
        </CenteredLayout>
    );
}

export function NetworkConnectionProblem(): JSX.Element {
    return (
        <CenteredLayout>
            <NonIdealState
                Icon={NetworkWifiOutlined}
                title={t`No internet`}
                description={t`Please check your internet connection and try again.`}
                action={{
                    onClick: () => window.location.reload(),
                    variant: 'outlined',
                    color: 'primary',
                    startIcon: <Refresh />,
                    children: t`Refresh page`,
                }}
            />
        </CenteredLayout>
    );
}

export function AuthenticationProblem(): JSX.Element {
    const { logout } = useAuth0();
    return (
        <CenteredLayout>
            <NonIdealState
                Icon={Lock}
                title={t`Authentication problem`}
                description={t`Sorry, it looks like your session expired. Try refreshing the page or logging in again. If this problem persists, please reach out to our support team using the chat box in the bottom right corner.`}
                action={{
                    onClick: () => {
                        analytics.track('logout');
                        logout({ returnTo: ORIGIN });
                    },
                    variant: 'outlined',
                    color: 'primary',
                    startIcon: <ExitToApp />,
                    children: t`Log out`,
                }}
            />
        </CenteredLayout>
    );
}

export function ErrorCodeProblem({ code }: { code: ErrorCode }): JSX.Element {
    return (
        <CenteredLayout>
            <NonIdealState
                Icon={Error}
                title={t`There was a problem`}
                description={transEnum(code, errorCodeTranslations)}
                action={{
                    onClick: () => window.location.reload(),
                    variant: 'outlined',
                    color: 'primary',
                    startIcon: <Refresh />,
                    children: t`Refresh page`,
                }}
            />
        </CenteredLayout>
    );
}
