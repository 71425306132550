import { debounce, groupBy, isPresent, uniq, uniqBy } from '@luminovo/commons';
import { usePersistedState } from '@luminovo/design-system';
import { DesignItemOriginTypes } from '@luminovo/http-client';
import { Box, styled } from '@mui/material';
import React, { useEffect, useMemo, useRef } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { useBomFiles } from '../../../resources/bomImporter/bomImporterHandler';
import { BomItem } from '../../../resources/designItem/bomItemFrontendTypes';
import { getAllCandidates } from '../../../resources/designItem/getAllCandidates';
import { colorSystem } from '../../../themes';
import { notUndefined } from '../../../utils/typingUtils';
import { useSuggestions } from '../../DesignItem/components/AddParts/useSuggestions';
import { BomItemFormState } from '../../DesignItem/components/BomItemFormState';
import { ColumnTagsSheetsState } from './AutocompleteColumnTags/types';
import { createInitialTagsStateFromBomFile } from './AutocompleteColumnTags/useTagsState';
import { EmbeddedTable } from './EmbeddedTable/EmbeddedTable';

const ScrollableContainer = styled(Box)({
    width: '100%',
    overflow: 'scroll',
    maxHeight: '20vh',
    border: `1px solid ${colorSystem.neutral[2]}`,
    borderRadius: '8px',
});

interface BomFileDetailsProps {
    bomItem: BomItem;
    useFormReturn: UseFormReturn<BomItemFormState>;
    isColumnMap: boolean;
}

export const BomFileDetails: React.FunctionComponent<BomFileDetailsProps> = React.memo(
    ({ bomItem, useFormReturn, isColumnMap }: BomFileDetailsProps): JSX.Element => {
        // TODO: @Tev, turn this into a single bulk query.
        const { data: bomFileHeaders = [] } = useBomFiles(bomItem.individualDesignItems);

        const aggregationKey = bomItem.individualDesignItems[0]?.aggregation_key;
        const assembly = bomItem.individualDesignItems[0]?.assembly;
        const { suggestions } = useSuggestions(assembly, aggregationKey);

        const allCandidates: string[] = React.useMemo(() => {
            return getAllCandidates(bomItem, suggestions);
        }, [bomItem, suggestions]);

        const highlightedColumnNamesPartSuggestions = React.useMemo(() => {
            return uniq(suggestions.map((suggestions) => suggestions?.origin?.column).filter(notUndefined));
        }, [suggestions]);

        const tagsState: ColumnTagsSheetsState = createInitialTagsStateFromBomFile(bomFileHeaders[0]);

        const originalExcelData = React.useMemo(() => {
            const designItemsByBomId = groupBy(bomItem.individualDesignItems, (c) =>
                c.origin.type === DesignItemOriginTypes.ExcelFile ? c.origin.data?.bom_file_id : undefined,
            );
            return Object.entries(designItemsByBomId)
                .map(([bomFileId, designItems]) => {
                    const bomFile = bomFileHeaders.find((b) => b.id === bomFileId);
                    const excelRows = uniqBy(
                        designItems.flatMap((designItem) =>
                            designItem.origin.type === DesignItemOriginTypes.ExcelFile
                                ? designItem.origin.data?.excel_lines ?? []
                                : [],
                        ),
                        (line) => line.line_number,
                    );
                    if (!bomFile) {
                        return null;
                    }
                    if (!excelRows) {
                        return null;
                    }

                    return {
                        columnMap: bomFile.column_map,
                        rawHeader: bomFile.raw_header,
                        excelRows,
                        bomFileId,
                    };
                })
                .filter(isPresent);
        }, [bomFileHeaders, bomItem.individualDesignItems]);

        const { tableRef, setOriginalExcelScrollX } = useOriginalExcelScrollX();

        return (
            <ScrollableContainer id="original_excel" ref={tableRef} onScroll={setOriginalExcelScrollX}>
                {originalExcelData.map((data) => {
                    return (
                        <EmbeddedTable
                            key={data.bomFileId}
                            columnMap={data.columnMap}
                            useFormReturn={useFormReturn}
                            allCandidatesForHighlights={allCandidates}
                            highlightedColumnNamesPartSuggestions={highlightedColumnNamesPartSuggestions}
                            headerRowJson={data.rawHeader}
                            showLineNumberColumn={true}
                            excelRows={data.excelRows}
                            tagsState={tagsState}
                            isColumnMap={isColumnMap}
                        />
                    );
                })}
            </ScrollableContainer>
        );
    },
);

const useOriginalExcelScrollX = () => {
    const tableRef = useRef<HTMLDivElement>(null);

    const [scrollX, setScrollX] = usePersistedState<string>('luminovo.original-excel.scroll-x', '0', sessionStorage);

    const setOriginalExcelScrollX = useMemo(() => {
        return debounce(() => {
            if (tableRef.current) {
                setScrollX(String(tableRef.current.scrollLeft));
            }
        }, 100);
    }, [setScrollX, tableRef]);

    useEffect(() => {
        if (tableRef.current) {
            // Set the scroll position to the previously saved position or 0 if none
            tableRef.current.scrollLeft = Number(scrollX);
        }
    }, [scrollX]);

    return { tableRef, setOriginalExcelScrollX };
};
