import { Trans } from '@lingui/macro';
import { ActorResponseRuntypeDTO, HistoryOperation } from '@luminovo/http-client';
import { isActorDefined } from '../utils';
import {
    ActionPerformedBy,
    TimelineAddIcon,
    TimelineContentContainer,
    TimelineDeleteIcon,
    TimelineText,
} from './Utils';

export function SubAssemblyIcon({ operation }: { operation: HistoryOperation }) {
    if (operation === HistoryOperation.ManualAdd) return <TimelineAddIcon />;
    return <TimelineDeleteIcon />;
}

type Props = {
    actor: ActorResponseRuntypeDTO;
    assemblyName: string;
    operation: HistoryOperation;
};

function useSubAssemblyAction(operation: HistoryOperation, actor: ActorResponseRuntypeDTO, assemblyName: string) {
    if (!isActorDefined(actor)) return null;
    switch (operation) {
        case HistoryOperation.ManualAdd:
            return (
                <TimelineText>
                    <Trans>
                        Subassembly <b>{assemblyName}</b> added
                    </Trans>{' '}
                    <ActionPerformedBy actor={actor} />
                </TimelineText>
            );
        case HistoryOperation.ManualRemove:
            return (
                <TimelineText>
                    <Trans>
                        Subassembly <b>{assemblyName}</b> removed
                    </Trans>{' '}
                    <ActionPerformedBy actor={actor} />
                </TimelineText>
            );
        case HistoryOperation.AutoRemove:
            return (
                <TimelineText>
                    <Trans>
                        Subassembly <b>{assemblyName}</b> removed automatically
                    </Trans>{' '}
                    <ActionPerformedBy actor={actor} />
                </TimelineText>
            );
        default:
            return null;
    }
}

export default function SubAssembly({ actor, assemblyName, operation }: Props) {
    const action = useSubAssemblyAction(operation, actor, assemblyName);
    if (!action) return null;
    return <TimelineContentContainer>{action}</TimelineContentContainer>;
}
