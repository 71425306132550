import {
    RegionsEnum,
    SitePostDTO,
    SupplierAndStockLocationPostDTO,
    SupplierPartType,
    SupplierPreference,
    SupplierType,
} from '@luminovo/http-client';
import { assemblies } from '../assemblyMocks';
import { createDemandsForAssemblies, createExampleDemands } from '../demandMocks';
import {
    MOCK_SITE_NAME,
    MOCK_SITE_NAME_2,
    MOCK_SITE_NUMBER,
    MOCK_SITE_NUMBER_2,
    MOCK_SUPPLIER_NAME_1,
    MOCK_SUPPLIER_NAME_2,
    MOCK_SUPPLIER_NUMBER_1,
    MOCK_SUPPLIER_NUMBER_2,
} from '../mockNames';
import { customerDemands } from './customerDemands';

const mockSupplier: SupplierAndStockLocationPostDTO = {
    name: MOCK_SUPPLIER_NAME_1,
    stock_location: RegionsEnum.Unknown,
    supplier_number: MOCK_SUPPLIER_NUMBER_1,
    supplier_preference: SupplierPreference.NotApproved,
    shipping_time_in_days: null,
    supplier_type: SupplierType.Ems,
    supplier_part_type: SupplierPartType.OffTheShelf,
};

const mockSupplier2: SupplierAndStockLocationPostDTO = {
    name: MOCK_SUPPLIER_NAME_2,
    stock_location: RegionsEnum.Unknown,
    supplier_number: MOCK_SUPPLIER_NUMBER_2,
    supplier_preference: SupplierPreference.NotApproved,
    shipping_time_in_days: null,
    supplier_type: SupplierType.Ems,
    supplier_part_type: SupplierPartType.OffTheShelf,
};

const mockSuppliers: SupplierAndStockLocationPostDTO[] = [mockSupplier, mockSupplier2];

const siteMock: SitePostDTO = {
    name: MOCK_SITE_NAME,
    address: { streetAddress: '', city: '', country: null, postcode: '' },
    notes: '',
    site_number: MOCK_SITE_NUMBER,
    tags: ['Manufacturing'],
    is_default_for: [],
};

const siteMock2: SitePostDTO = {
    name: MOCK_SITE_NAME_2,
    address: { streetAddress: '', city: '', country: null, postcode: '' },
    notes: '',
    site_number: MOCK_SITE_NUMBER_2,
    tags: ['Manufacturing'],
    is_default_for: [],
};
const mockSites: SitePostDTO[] = [siteMock, siteMock2];

export const mockDemandEnvironment = {
    suppliers: mockSuppliers,
    sites: mockSites,
    demands: createExampleDemands(customerDemands),
    assemblies,
};

export const mockDemandEnvironmentAgain = {
    suppliers: mockSuppliers,
    sites: mockSites,
    demands: createDemandsForAssemblies({ assemblyIpns: ['Assembly-1', 'Assembly-2'] }),
    assemblies,
};
