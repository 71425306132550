import { Trans, t } from '@lingui/macro';
import { Tag, Text, Tooltip, colorSystem, compareByNumber } from '@luminovo/design-system';
import { CheckCircle, Error } from '@mui/icons-material';
import { Box, Divider } from '@mui/material';
import React from 'react';
import { ImporterField } from '../../../../types';
import { useUniversalImporter } from '../../context';
import { GlobalFieldsForm } from '../globalFields/StepSetGlobalFields';
import { TableMapColumns } from './TableMapColumns';

export function StepLinkColumns(): JSX.Element {
    const { state } = useUniversalImporter();
    const { table } = state;

    if (!table) {
        return <></>;
    }

    return (
        <Box
            sx={{
                position: 'relative',
                width: '100vw',
                boxSizing: 'border-box',
                overflow: 'hidden',
                height: '100%',
            }}
        >
            <Box
                sx={{
                    display: 'grid',
                    gap: 0,
                    gridTemplateColumns: '1fr auto',
                    boxSizing: 'border-box',
                }}
            >
                <Box
                    sx={{
                        padding: 3,
                        height: 'calc(100% - 80px)',
                        boxSizing: 'border-box',
                    }}
                >
                    <TableMapColumns table={table} />
                </Box>
                <Box
                    sx={{
                        position: 'sticky',
                        zIndex: 2,
                        top: 0,
                        right: 0,
                        minWidth: 'fit-content',
                        padding: 2,
                        height: '100vh',
                        borderLeft: `1px solid ${colorSystem.neutral[2]}`,
                        backgroundColor: colorSystem.neutral.white,
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 2,
                        overflowY: 'auto',
                    }}
                >
                    <Text variant="h1">
                        <Trans>Global fields</Trans>
                    </Text>
                    <GlobalFieldsForm />
                    <Divider style={{ margin: '4px -16px' }} />
                    <Text variant="h1">
                        <Trans>Fields</Trans>
                    </Text>
                    <FieldDescriptions showDescription={false} fields={state.config.fields} />
                </Box>
            </Box>
        </Box>
    );
}

export function FieldDescriptions({
    fields,
    showDescription = true,
    showLinked = true,
    useColumnsLabel = false,
}: {
    fields: readonly ImporterField[];
    showDescription?: boolean;
    showLinked?: boolean;
    useColumnsLabel?: boolean;
}): JSX.Element {
    const autoCount = 3 + (showLinked ? 1 : 0) + (showDescription ? 1 : 0);

    return (
        <Box sx={{ display: 'grid', gridTemplateColumns: Array(autoCount).fill('auto').join(' '), gap: 2 }}>
            <Text variant="h5">{useColumnsLabel ? t`Columns` : t`Field`}</Text>
            <Text variant="h5">
                <Trans>Required</Trans>
            </Text>
            <Text variant="h5">
                <Trans>Default</Trans>
            </Text>

            {showLinked && (
                <Text variant="h5">
                    <Trans>Linked</Trans>
                </Text>
            )}
            {showDescription && (
                <Text variant="h5">
                    <Trans>Description</Trans>
                </Text>
            )}
            {Array.from(fields)
                .sort(compareByNumber((field) => (field.required ? 0 : 1)))
                .map((field: ImporterField) => {
                    return (
                        <React.Fragment key={field.id}>
                            <Tag
                                attention="low"
                                color={field.required ? 'blue' : 'neutral'}
                                label={field.label ?? field.id}
                            />

                            <Text variant="body-small">{field.required ? t`Yes` : t`No`}</Text>
                            <Text variant="body-small">{field.defaultValue?.label ?? '-'}</Text>

                            {showLinked && (
                                <Text>
                                    {field.required && field.columnIndices.length === 0 && (
                                        <Tooltip title={t`Map this required field to continue`}>
                                            <Error style={{ color: colorSystem.red[6], height: 16 }} />
                                        </Tooltip>
                                    )}
                                    {!field.required && field.columnIndices.length === 0 && (
                                        <CheckCircle style={{ color: colorSystem.neutral[5], height: 16 }} />
                                    )}
                                    {field.columnIndices.length > 0 && (
                                        <CheckCircle style={{ color: colorSystem.green[6], height: 16 }} />
                                    )}
                                </Text>
                            )}
                            {showDescription && <Text variant="body-small">{field.description}</Text>}
                        </React.Fragment>
                    );
                })}
        </Box>
    );
}
