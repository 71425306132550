import { t } from '@lingui/macro';
import { MonetaryValue, isPresent } from '@luminovo/commons';
import {
    AccessorFn,
    CellContext,
    ColumnDefTemplate,
    ColumnMeta,
    DeepKeys,
    DeepValue,
    DisplayColumnDef,
    IdentifiedColumnDef,
    RowData,
    ColumnHelper as TanStackColumnHelper,
    createColumnHelper as tanStackCreateColumnHelper,
} from '@tanstack/react-table';
import { NumberRange } from './Filters';
import { ColumnDefWithSharedContext, QuickFilter } from './type';
import { createPositionBasedSortFunction } from './utils';

type RequiredColumnDef<TData, TValue, TSharedContext> = {
    /**
     * The width of the column in pixels.
     */
    size: number;

    /**
     * Template for rendering the cell content. Uses CellContext to provide context information.
     *
     * @example
     *   cell: (item) => <Tag color={'neutral'} attention={'low'} label={item.getValue() ?? t`Unknown`} />,
     *
     *   cell: ({row, sharedContext}) => formatPackaging(row.original.packaging, sharedContext),
     */
    cell?: ColumnDefTemplate<CellContext<TData, TValue, TSharedContext>>;
} & Pick<
    ColumnMeta<TData, TValue>,
    | 'enableOnRowClick'
    | 'align'
    | 'description'
    | 'label'
    | 'disableTableCell'
    | 'initialPinning'
    | 'initialVisibility'
    | 'renderType'
>;

type SafeTypeFilter<TValue> = {
    quickFilters?: Array<QuickFilter<TValue>>;
    filterValueTransform?: (value: TValue) => unknown;
};

type RequiredDisplayColumnDef<TData, TValue, TSharedContext> = Omit<
    DisplayColumnDef<TData, TValue>,
    'cell' | 'filterFn' | 'enablePinning'
> &
    RequiredColumnDef<TData, TValue, TSharedContext> & { id: string };
type RequiredIdentifiedColumnDef<TData, TValue, TSharedContext> = Omit<
    IdentifiedColumnDef<TData, TValue>,
    'cell' | 'filterFn' | 'enablePinning'
> &
    RequiredColumnDef<TData, TValue, TSharedContext>;

type TextDisplayColumnDef<TData, TValue, TSharedContext> = RequiredDisplayColumnDef<TData, TValue, TSharedContext> &
    SafeTypeFilter<string>;

type TextIdentifiedColumnDef<TData, TValue, TSharedContext> = RequiredIdentifiedColumnDef<
    TData,
    TValue,
    TSharedContext
> &
    SafeTypeFilter<string>;

type NumberDisplayColumnDef<TData, TValue, TSharedContext> = RequiredDisplayColumnDef<TData, TValue, TSharedContext> &
    SafeTypeFilter<NumberRange>;

type NumberIdentifiedColumnDef<TData, TValue, TSharedContext> = RequiredIdentifiedColumnDef<
    TData,
    TValue,
    TSharedContext
> &
    SafeTypeFilter<NumberRange>;

type MonetaryValueDisplayColumnDef<TData, TValue, TSharedContext> = RequiredDisplayColumnDef<
    TData,
    TValue,
    TSharedContext
> &
    ColumnMeta<TData, TValue, TSharedContext>['inMonetaryValueRange'] &
    SafeTypeFilter<NumberRange>;

type MonetaryValueIdentifiedColumnDef<TData, TValue, TSharedContext> = RequiredIdentifiedColumnDef<
    TData,
    TValue,
    TSharedContext
> &
    ColumnMeta<TData, TValue, TSharedContext>['inMonetaryValueRange'] &
    SafeTypeFilter<NumberRange>;

type EnumDisplayColumnDef<TData, TValue, TSharedContext> = RequiredDisplayColumnDef<TData, TValue, TSharedContext> &
    ColumnMeta<TData, TValue, TSharedContext>['equalsAny'] &
    SafeTypeFilter<Array<TValue>>;

type EnumIdentifiedColumnDef<TData, TValue, TSharedContext> = RequiredIdentifiedColumnDef<
    TData,
    TValue,
    TSharedContext
> &
    ColumnMeta<TData, TValue, TSharedContext>['equalsAny'] &
    SafeTypeFilter<Array<TValue>>;

type ArrayDisplayColumnDef<TData, TValue, TSharedContext> = RequiredDisplayColumnDef<TData, TValue, TSharedContext> &
    ColumnMeta<TData, TValue, TSharedContext>['arrIncludesSome'] &
    SafeTypeFilter<TValue>;

type ArrayIdentifiedColumnDef<TData, TValue, TSharedContext> = RequiredIdentifiedColumnDef<
    TData,
    TValue,
    TSharedContext
> &
    ColumnMeta<TData, TValue, TSharedContext>['arrIncludesSome'] &
    SafeTypeFilter<TValue>;

type DateDisplayColumnDef<TData, TValue, TSharedContext> = RequiredDisplayColumnDef<TData, TValue, TSharedContext> &
    SafeTypeFilter<string>;

type DateIdentifiedColumnDef<TData, TValue, TSharedContext> = RequiredIdentifiedColumnDef<
    TData,
    TValue,
    TSharedContext
> &
    SafeTypeFilter<string>;

type ActionColumnDef<TData, TSharedContext> = Omit<DisplayColumnDef<TData>, 'cell'> & {
    /**
     * The width of the column in pixels.
     */
    size: number;

    /**
     * Template for rendering the cell content. Uses CellContext to provide context information.
     *
     * @example
     *   cell: (item) => <Tag color={'neutral'} attention={'low'} label={item.getValue() ?? t`Unknown`} />,
     *
     *   cell: ({row, sharedContext}) => formatPackaging(row.original.packaging, sharedContext),
     */
    cell?: ColumnDefTemplate<CellContext<TData, unknown, TSharedContext>>;
} & Pick<ColumnMeta<TData, unknown>, 'enableOnRowClick' | 'align' | 'disableTableCell'>;

/**
 * A helper type for creating column definitions with various data types and shared contexts.
 *
 * @template TData - The type of the row data.
 * @template TSharedContext - The type of the shared context.
 */
type ColumnHelper<TData extends RowData, TSharedContext = unknown> = {
    /**
     * Creates a column definition for text data.
     *
     * Requires the `id` when the accessor is a function.
     */
    text: <
        TAccessor extends AccessorFn<TData> | DeepKeys<TData>,
        TValue extends TAccessor extends AccessorFn<TData, infer TReturn>
            ? TReturn extends string | null | undefined
                ? TReturn
                : unknown
            : TAccessor extends DeepKeys<TData>
              ? DeepValue<TData, TAccessor> extends string | null | undefined
                  ? DeepValue<TData, TAccessor>
                  : unknown
              : never,
    >(
        accessor: TAccessor,
        column: TAccessor extends AccessorFn<TData>
            ? TextDisplayColumnDef<TData, TValue, TSharedContext>
            : TextIdentifiedColumnDef<TData, TValue, TSharedContext>,
    ) => ColumnDefWithSharedContext<TData, TValue, TSharedContext>;

    /**
     * Creates a column definition for numeric data.
     *
     * Requires the `id` when the accessor is a function.
     */
    number: <
        TAccessor extends AccessorFn<TData> | DeepKeys<TData>,
        TValue extends TAccessor extends AccessorFn<TData, infer TReturn>
            ? TReturn extends number | null | undefined
                ? TReturn
                : unknown
            : TAccessor extends DeepKeys<TData>
              ? DeepValue<TData, TAccessor> extends number | null | undefined
                  ? DeepValue<TData, TAccessor>
                  : unknown
              : never,
    >(
        accessor: TAccessor,
        column: TAccessor extends AccessorFn<TData>
            ? NumberDisplayColumnDef<TData, TValue, TSharedContext>
            : NumberIdentifiedColumnDef<TData, TValue, TSharedContext>,
    ) => ColumnDefWithSharedContext<TData, TValue, TSharedContext>;

    /**
     * Creates a column definition for enum data.
     *
     * Requires the `id` when the accessor is a function.
     * Requires `getOptionLabel` to provide labels for the enum options.
     * Optionally, `options` and `renderOption` can be provided to define the enum options and their rendering.
     */
    enum: <
        TAccessor extends AccessorFn<TData> | DeepKeys<TData>,
        TValue extends TAccessor extends AccessorFn<TData, infer TReturn>
            ? TReturn
            : TAccessor extends DeepKeys<TData>
              ? DeepValue<TData, TAccessor>
              : never,
    >(
        accessor: TAccessor,
        column: TAccessor extends AccessorFn<TData>
            ? EnumDisplayColumnDef<TData, TValue, TSharedContext>
            : EnumIdentifiedColumnDef<TData, TValue, TSharedContext>,
    ) => ColumnDefWithSharedContext<TData, TValue, TSharedContext>;

    /**
     * Creates a column definition for array data.
     *
     * Requires the `id` when the accessor is a function.
     * Requires `getOptionLabel` to provide labels for the array options.
     * Optionally, `options` and `renderOption` can be provided to define the array options and their rendering.
     */
    array: <
        TAccessor extends AccessorFn<TData> | DeepKeys<TData>,
        TValue extends TAccessor extends AccessorFn<TData, infer TReturn>
            ? TReturn
            : TAccessor extends DeepKeys<TData>
              ? DeepValue<TData, TAccessor> extends Array<unknown>
                  ? DeepValue<TData, TAccessor>
                  : never
              : never,
    >(
        accessor: TAccessor,
        column: TAccessor extends AccessorFn<TData>
            ? ArrayDisplayColumnDef<TData, TValue, TSharedContext>
            : ArrayIdentifiedColumnDef<TData, TValue, TSharedContext>,
    ) => ColumnDefWithSharedContext<TData, TValue, TSharedContext>;

    /**
     * Creates a column definition for monetary value data.
     *
     * Requires the `id` when the accessor is a function.
     */
    monetaryValue: <
        TAccessor extends AccessorFn<TData> | DeepKeys<TData>,
        TValue extends TAccessor extends AccessorFn<TData, infer TReturn>
            ? TReturn extends MonetaryValue | null | undefined
                ? TReturn
                : unknown
            : TAccessor extends DeepKeys<TData>
              ? DeepValue<TData, TAccessor> extends MonetaryValue | null | undefined
                  ? DeepValue<TData, TAccessor>
                  : unknown
              : never,
    >(
        accessor: TAccessor,
        column: TAccessor extends AccessorFn<TData>
            ? MonetaryValueDisplayColumnDef<TData, TValue, TSharedContext>
            : MonetaryValueIdentifiedColumnDef<TData, TValue, TSharedContext>,
    ) => ColumnDefWithSharedContext<TData, TValue, TSharedContext>;

    /**
     * Creates a column definition for date data.
     *
     * Requires the `id` when the accessor is a function.
     */
    date: <
        TAccessor extends AccessorFn<TData> | DeepKeys<TData>,
        TValue extends TAccessor extends AccessorFn<TData, infer TReturn>
            ? TReturn extends string | null | undefined
                ? TReturn
                : unknown
            : TAccessor extends DeepKeys<TData>
              ? DeepValue<TData, TAccessor> extends string | null | undefined
                  ? DeepValue<TData, TAccessor>
                  : unknown
              : never,
    >(
        accessor: TAccessor,
        column: TAccessor extends AccessorFn<TData>
            ? DateDisplayColumnDef<TData, TValue, TSharedContext>
            : DateIdentifiedColumnDef<TData, TValue, TSharedContext>,
    ) => ColumnDefWithSharedContext<TData, TValue, TSharedContext>;

    /**
     * @deprecated Use `text`, `number`, `array`, or `enum` instead when posible.
     *
     * Requires the `id` when the accessor is a function.
     */
    generic: <
        TAccessor extends AccessorFn<TData> | DeepKeys<TData>,
        TValue extends TAccessor extends AccessorFn<TData, infer TReturn>
            ? TReturn
            : TAccessor extends DeepKeys<TData>
              ? DeepValue<TData, TAccessor>
              : never,
    >(
        accessor: TAccessor,
        column: TAccessor extends AccessorFn<TData>
            ? RequiredDisplayColumnDef<TData, TValue, TSharedContext>
            : RequiredIdentifiedColumnDef<TData, TValue, TSharedContext>,
    ) => ColumnDefWithSharedContext<TData, TValue, TSharedContext>;

    /**
     * KNOWN ISSUE: the cell function isn't type correctly in combination with a sharedContext.
     * Since we don't have a use case for this at the moment, we leave it as is. If we need it, we can fix it - hopefully.
     */
    action: (
        column: ActionColumnDef<TData, TSharedContext>,
    ) => ColumnDefWithSharedContext<TData, unknown, TSharedContext>;

    // Disabled because we don't need it at the moment
    // display: (column: DisplayColumnDef<TData>) => ColumnDefWithSharedContext<TData, unknown, TSharedContext>;
    // group: (column: GroupColumnDef<TData>) => ColumnDefWithSharedContext<TData, unknown, TSharedContext>;
};

export function createColumnHelper<TData extends RowData, TSharedContext = unknown>(): ColumnHelper<
    TData,
    TSharedContext
> {
    type TanStackParemeter<TData> = Parameters<TanStackColumnHelper<TData>['accessor']>;
    type TanStackAccessor<TData> = TanStackParemeter<TData>[0];
    type TanStackColumn<TData> = TanStackParemeter<TData>[1];

    type InnerAccessor<TData> = TanStackAccessor<TData>;
    type InnerColumn<TData> = Omit<TanStackColumn<TData>, 'filterFn' | 'size'> &
        RequiredColumnDef<TData, unknown, unknown> & { id: string } & SafeTypeFilter<unknown>;

    const columnHelper = tanStackCreateColumnHelper<TData>();

    function genericAccessor(
        accessor: InnerAccessor<TData>,
        {
            enableOnRowClick,
            align,
            description,
            label,
            quickFilters,
            filterValueTransform,
            disableTableCell,
            initialPinning,
            initialVisibility,
            renderType,
            meta,
            ...column
        }: InnerColumn<TData> & { meta: Partial<ColumnMeta<TData, any>> },
    ) {
        return columnHelper.accessor(accessor, {
            header: label,
            ...column,
            // if the accessor is a string and the column id is not set we set is as the id
            id: typeof accessor === 'string' && !column.id ? accessor : column.id,
            meta: {
                dataType: 'generic',
                ...meta,
                enableOnRowClick,
                align: align ?? meta.align,
                description,
                label,
                quickFilters,
                filterValueTransform,
                disableTableCell,
                initialPinning,
                initialVisibility,
                renderType,
            },
        });
    }

    function textAccessor(accessorFn: InnerAccessor<TData>, column: InnerColumn<TData>) {
        return genericAccessor(accessorFn, {
            ...column,
            filterFn: 'includesString',
            meta: {
                dataType: 'text',
            },
        });
    }

    function numberAccessor(accessorFn: InnerAccessor<TData>, column: InnerColumn<TData>) {
        return genericAccessor(accessorFn, {
            ...column,
            filterFn: 'inNumberRange',
            meta: {
                dataType: 'number',
                align: 'right',
            },
        });
    }

    function enumAccessor(
        accessorFn: InnerAccessor<TData>,
        column: InnerColumn<TData> & ColumnMeta<TData, any>['equalsAny'],
    ) {
        const getOptionLabel = (value: TData) => (isPresent(value) ? column.getOptionLabel(value) : t`Unknown`);

        return genericAccessor(accessorFn, {
            ...column,
            filterFn: 'equalsAny',
            sortingFn: createPositionBasedSortFunction<TData>({ options: column.options, getOptionLabel }),
            meta: {
                dataType: 'enum',
                equalsAny: {
                    options: column.options,
                    getOptionLabel: getOptionLabel,
                    renderOption: column.renderOption,
                    getOptionCount: column.getOptionCount,
                    getOptionKey: column.getOptionKey,
                },
            },
        });
    }

    function arrayAccessor(
        accessorFn: InnerAccessor<TData>,
        column: InnerColumn<TData> & ColumnMeta<TData, any>['arrIncludesSome'],
    ) {
        return genericAccessor(accessorFn, {
            ...column,
            filterFn: 'arrIncludesSome',
            meta: {
                dataType: 'array',
                arrIncludesSome: {
                    options: column.options,
                    getOptionLabel: (value) => (isPresent(value) ? column.getOptionLabel(value) : t`Unknown`),
                    renderOption: column.renderOption,
                    getOptionCount: column.getOptionCount,
                    getOptionKey: column.getOptionKey,
                },
            },
        });
    }

    function monetaryValueAccessor(
        accessorFn: InnerAccessor<TData>,
        column: InnerColumn<TData> & ColumnMeta<TData, any>['inMonetaryValueRange'],
    ) {
        return genericAccessor(accessorFn, {
            enableGlobalFilter: false,
            ...column,
            sortingFn: 'monetaryValue',
            filterFn: 'inMonetaryValueRange',
            meta: {
                dataType: 'monetaryValue',
                align: 'right',
                inMonetaryValueRange: {
                    formatAs: column.formatAs ?? 'default',
                },
            },
        });
    }

    function dateAccessor(accessorFn: InnerAccessor<TData>, column: InnerColumn<TData>) {
        return genericAccessor(accessorFn, {
            enableGlobalFilter: false,
            ...column,
            filterFn: 'inDateRange',
            meta: {
                dataType: 'date',
            },
        });
    }

    function action({
        enableOnRowClick = false,
        align,
        disableTableCell,
        meta,
        ...column
    }: ActionColumnDef<TData, TSharedContext>) {
        return columnHelper.display({
            ...column,
            enableHiding: false,
            meta: {
                dataType: 'generic',
                label: () => t`Unknown`,
                enableOnRowClick,
                align,
                disableTableCell,
                ...meta,
            },
        } as any);
    }
    // Type safe since we guarde the outside with ColumnHelper<TData> and the inner with Accessor<TData> and Column<TData>
    return {
        text: textAccessor as any,
        number: numberAccessor as any,
        enum: enumAccessor as any,
        array: arrayAccessor as any,
        monetaryValue: monetaryValueAccessor as any,
        date: dateAccessor as any,
        generic: genericAccessor as any,
        action: action as any,
    };
}
