import { StandardPartOfferDTO, ValidFor } from '@luminovo/http-client';

export function extractValidFor(offer: StandardPartOfferDTO): ValidFor {
    if (!offer.customer && !offer.rfq) {
        return ValidFor.EveryCustomer;
    }
    if (offer.customer) {
        return ValidFor.ThisCustomer;
    }
    if (offer.rfq) {
        return ValidFor.ThisRfQ;
    }
    return ValidFor.EveryCustomer;
}
