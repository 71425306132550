/* eslint-disable camelcase */
import { t } from '@lingui/macro';
import { assertUnreachable, formatLongDateTime, formatToIso8601Date } from '@luminovo/commons';
import {
    ExtractRequestBody,
    PlannedOrderKind,
    PlannedOrderKindValues,
    PlannedOrderSupplierOrRecipientImportDTO,
    QuantityUnit,
} from '@luminovo/http-client';
import { UniversalImporter } from '@luminovo/universal-importer';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router';
import { useHttpMutation } from '../../resources/mutation/useHttpMutation';
import { route } from '../../utils/routes';
import { formatError } from '../Error/formatError';

const supplierOrRecipientParserConfigOptions = {
    caseSensitive: false,
    cases: PlannedOrderKindValues.map((kind) => ({
        value: {
            id: kind,
            label: kind,
        },
        matches: [kind],
    })),
};

const getSupplier = ({
    type,
    supplierSiteNumber,
}: {
    type: PlannedOrderKind;
    supplierSiteNumber: string;
}): PlannedOrderSupplierOrRecipientImportDTO => {
    switch (type) {
        case 'internal':
            return {
                kind: 'internal',
                site_number: supplierSiteNumber,
            };
        case 'external':
            return {
                kind: 'external',
                supplier_number: supplierSiteNumber,
            };
        default:
            assertUnreachable(type);
    }
};

export function DemandImporterPage() {
    const { enqueueSnackbar } = useSnackbar();
    const { mutateAsync: importDemand } = useHttpMutation('POST /demand/import', { snackbarMessage: null });
    const history = useHistory();
    const defaultContext = `Demand - ${formatLongDateTime(new Date().toString())}`;
    return (
        <UniversalImporter
            title={t`Import demand`}
            batchSize={Infinity}
            hrefBack={route('/parts/components/ipn')}
            onImportDone={() => {
                enqueueSnackbar(t`Import successful`, {
                    variant: 'success',
                    anchorOrigin: {
                        horizontal: 'center',
                        vertical: 'top',
                    },
                });
                history.goBack();
            }}
            onImportBatch={async (batch, globalFields) => {
                const demandItems: ExtractRequestBody<'POST /demand/import'>['items'] = batch.map((row) => {
                    const delivery_date = formatToIso8601Date(row.data.delivery_date);
                    return {
                        type: 'gross',
                        delivery_start_date: delivery_date,
                        delivery_end_date: delivery_date,
                        internal_part_number: {
                            value: row.data.ipn,
                        },
                        production_start_date:
                            row.data.production_start_date === ''
                                ? undefined
                                : formatToIso8601Date(row.data.production_start_date),
                        quantity: {
                            quantity: row.data.quantity,
                            unit: QuantityUnit.Pieces,
                        },
                        recipient: getSupplier({
                            type: row.data['recipient-type'] as PlannedOrderKind,
                            supplierSiteNumber: row.data['recipient-supplier-site-number'],
                        }),
                        supplier: getSupplier({
                            type: row.data['supplier-type'] as PlannedOrderKind,
                            supplierSiteNumber: row.data['supplier-supplier-site-number'],
                        }),
                    };
                });

                return importDemand({
                    requestBody: {
                        items: demandItems,
                        context:
                            globalFields && globalFields.length > 0 && globalFields[0].value?.id
                                ? globalFields[0].value.id
                                : defaultContext,
                    },
                })
                    .then((results) => {
                        return batch.map((batchItem) => {
                            const resultItem = results.find(
                                (resultItem) => batchItem.data.ipn === resultItem.internal_part_number.value,
                            );

                            if (!resultItem) {
                                return {
                                    success: false as const,
                                    message: t`Unknown error`,
                                };
                            }

                            if (resultItem.status > 299) {
                                return {
                                    success: false as const,
                                    message: resultItem.description ?? t`Unknown error`,
                                };
                            }

                            return {
                                success: true as const,
                            };
                        });
                    })
                    .catch((error) => {
                        return batch.map((row) => {
                            return {
                                success: false as const,
                                message: formatError(error),
                            };
                        });
                    });
            }}
            config={{
                globalFields: [
                    {
                        id: 'context' as const,
                        label: t`Context`,
                        description: t`The context in which demands are imported or managed, helping to distinguish between different imports.`,
                        value: { id: defaultContext, label: t`Context` },
                        required: true,
                        parser: { type: 'string', options: { trim: true } },
                    },
                ],
                fields: [
                    {
                        id: 'ipn' as const,
                        columnIndices: [0],
                        required: true,
                        parser: { type: 'ipn', options: { ipns: [] } },
                        label: t`IPN`,
                        description: t`The internal part number`,
                    },
                    {
                        id: 'quantity' as const,
                        columnIndices: [1],
                        required: true,
                        parser: { type: 'number', options: { min: 0 } },
                        label: t`Quantity`,
                    },
                    //TODO: split this up when supported by the backend.
                    {
                        id: 'delivery_date' as const,
                        columnIndices: [2],
                        required: true,
                        parser: { type: 'date-string', options: { trim: true } },
                        label: t`Delivery date`,
                    },
                    {
                        id: 'production_start_date' as const,
                        columnIndices: [3],
                        required: true,
                        parser: { type: 'date-string', options: { trim: true } },
                        label: t`Production start date`,
                    },
                    {
                        id: 'supplier-type' as const,
                        columnIndices: [4],
                        required: false,
                        parser: {
                            type: 'enum',
                            options: supplierOrRecipientParserConfigOptions,
                        },
                        label: t`Supplier type`,
                        description: t`Internal or external`,
                    },
                    {
                        id: 'supplier-supplier-site-number' as const,
                        columnIndices: [5],
                        required: false,
                        parser: { type: 'string', options: { trim: true } },

                        label: t`Supplier: supplier or site number`,
                        description: t`If the type is internal than this is the site number, if the supplier type is external than this is the supplier number.`,
                    },
                    {
                        id: 'recipient-type' as const,
                        columnIndices: [6],
                        required: false,
                        parser: {
                            type: 'enum',
                            options: supplierOrRecipientParserConfigOptions,
                        },
                        label: t`Recipient type`,
                        description: t`Internal or external`,
                    },
                    {
                        id: 'recipient-supplier-site-number' as const,
                        columnIndices: [7],
                        required: false,
                        parser: { type: 'string', options: { trim: true } },
                        label: t`Recipient: supplier or site number`,
                        description: t`If the type is internal than this is the site number, if the supplier type is external than this is the supplier number.`,
                    },
                ],
            }}
        />
    );
}
