import { Trans, plural, t } from '@lingui/macro';
import {
    assertPresent,
    assertUnreachable,
    formatDecimal,
    formatToIso8601Date,
    isPresent,
    throwErrorUnlessProduction,
} from '@luminovo/commons';
import {
    DialogTitle,
    Flexbox,
    Message,
    PrimaryButton,
    SecondaryButton,
    Text,
    Toolbar,
    colorSystem,
} from '@luminovo/design-system';
import {
    CustomLinkedPartDTO,
    CustomPartOfferInputDTO,
    QuantityUnit,
    QuoteImporterResponse,
    StandardPartDTO,
    StandardPartOfferBulkInputDTO,
    StandardPartTypes,
} from '@luminovo/http-client';
import {
    convertValidForToCustomer,
    convertValidForToRfqId,
    formatSupplierAndStockLocationDTO,
} from '@luminovo/sourcing-core';
import { AddRounded, Close } from '@mui/icons-material';
import { Dialog, DialogContent } from '@mui/material';
import * as Sentry from '@sentry/react';
import React from 'react';
import { useFormContext, useFormState, useWatch } from 'react-hook-form';
import { useHistory } from 'react-router';
import { useDialogContext } from '../../../../components/contexts/ModalContext';
import { useHttpFileUpload } from '../../../../resources/http/useHttpFileUpload';
import { useHttpMutation } from '../../../../resources/mutation/useHttpMutation';
import { analytics } from '../../../../utils/analytics';
import {
    CustomPartQuoteImporterFormState,
    CustomPartQuoteLineTableData,
    CustomPartSubmitProps,
    QuoteImporterFormState,
    StandardPartQuoteImporterFormState,
    StandardPartQuoteLineTableData,
    StandardPartSubmitProps,
    hasErrorsForLine,
} from './types';

function convertValidUntil(
    line: StandardPartQuoteLineTableData | CustomPartQuoteLineTableData,
    offerValidity: string | undefined,
): string | null {
    if (line.row.validUntil) {
        return formatToIso8601Date(line.row.validUntil);
    }

    if (offerValidity) {
        return formatToIso8601Date(offerValidity);
    }

    return null;
}

function convertLeadTime(line: StandardPartQuoteLineTableData) {
    if (!isPresent(line.row.leadTime) || !isPresent(line.row.leadTimeUnit)) {
        return undefined;
    }

    switch (line.row.leadTimeUnit) {
        case 'days':
            return line.row.leadTime * 1;
        case 'weeks':
            return line.row.leadTime * 7;
    }
}

function getRequestAndLinkedParts(data: StandardPartQuoteLineTableData): {
    requested_part: StandardPartDTO | null;
    linked_part: StandardPartDTO | null;
} {
    const linked_part = (() => {
        switch (data.linkedPart.type) {
            case 'OffTheShelf':
                return {
                    type: StandardPartTypes.OffTheShelf,
                    data: data.linkedPart.part.id,
                };
            case 'Ipn':
                return {
                    type: StandardPartTypes.Ipn,
                    data: data.linkedPart.part.id,
                };
            case 'AlternativePart':
                if (!isPresent(data.linkedPart.part)) {
                    return null;
                }
                return {
                    type: StandardPartTypes.OffTheShelf,
                    data: data.linkedPart.part.id,
                };
            case 'NonMatching':
                return null;
        }
    })();

    const requested_part = (() => {
        if (isPresent(data.requestedPart)) {
            return data.requestedPart;
        }

        if (isPresent(linked_part)) {
            return linked_part;
        }

        return null;
    })();

    return { requested_part, linked_part };
}

function createStandardPartOffers({ values, errors }: StandardPartSubmitProps): StandardPartOfferBulkInputDTO[] {
    return values.lines
        .filter((line) => !hasErrorsForLine(line, errors))
        .filter((line) => values.selectedIndices.includes(line.index))
        .flatMap((line) => {
            const { requested_part, linked_part } = getRequestAndLinkedParts(line);
            if (!isPresent(requested_part) || !isPresent(linked_part)) {
                throwErrorUnlessProduction(new Error('Requested part or linked part is missing'), {
                    extra: { requested_part, linked_part },
                });
                return [];
            }

            return [
                {
                    /* eslint-disable camelcase */
                    requested_part,
                    linked_part,
                    currency: assertPresent(line.row.currency),
                    notes: line.row.additionalNotes ?? null,
                    packaging: line.row.packaging ?? null,
                    availability_input: {
                        stock: line.row.stock ?? null,
                        factory_lead_time_days: convertLeadTime(line) ?? null,
                        factory_quantity: null,
                        on_order: [],
                    },
                    valid_until: convertValidUntil(line, values.offerValidity),
                    supplier_and_stock_location: assertPresent(values.supplierAndStockLocation?.id),
                    customer: convertValidForToCustomer(values.validFor, values.rfq.customer),
                    rfq_id: convertValidForToRfqId(values.validFor, values.rfq.id),
                    unit_of_measurement: {
                        unit: assertPresent(line.row.unit),
                        quantity: 1,
                    },
                    supplier_part_number: line.row.supplierPartNumber,
                    price_break_inputs: [
                        {
                            moq: line.row.minOrderQuantity ?? 1,
                            mpq: line.row.minPackagingQuantity ?? 1,
                            unit_price: assertPresent(line.row.unitPrice) / assertPresent(line.row.unitPriceQuantity),
                            lead_time_days: null,
                        },
                    ],
                    attachment: null,
                    offer_number: values.offerNumber ?? null,
                    price_type: values.priceType,
                    ncnr: line.row.ncnr === undefined ? null : line.row.ncnr,
                    /* eslint-enable camelcase */
                },
            ];
        });
}

function createCustomPartOffers({ values, errors }: CustomPartSubmitProps): CustomPartOfferInputDTO[] {
    return values.lines
        .filter((line) => !hasErrorsForLine(line, errors))
        .filter((line) => values.selectedIndices.includes(line.index))
        .map((line) => {
            return {
                /* eslint-disable camelcase */
                linked_part: assertPresent(getCustomLinkedPart(line)),
                supplier_and_stock_location: assertPresent(values.supplierAndStockLocation?.id),
                unit_of_measurement: { quantity: 1, unit: QuantityUnit.Pieces },
                currency: assertPresent(line.row.currency),
                price_points: [
                    {
                        quantity: assertPresent(line.row.requiredQuantity),
                        amount: String(assertPresent(line.row.unitPrice)),
                        lead_time_days: line.row.leadTimeInDays,
                    },
                ],
                one_time_costs: isPresent(line.row.oneTimeCosts)
                    ? [
                          {
                              amount: String(assertPresent(line.row.oneTimeCosts)),
                              description: undefined,
                          },
                      ]
                    : [],
                valid_until: convertValidUntil(line, values.offerValidity) ?? undefined,
                price_type: values.priceType,
                offer_number: values.offerNumber,
                notes: line.row.additionalNotes,
                sourcing_scenario_id: assertPresent(line.row.sourcingScenarioId),
                /* eslint-enable camelcase */
            };
        });
}

function getCustomLinkedPart(data: CustomPartQuoteLineTableData): CustomLinkedPartDTO | null {
    switch (data.linkedPart.type) {
        case 'CustomPart':
            return {
                type: 'CustomPart',
                id: data.linkedPart.part.id,
            };
        case 'CustomComponent':
            return {
                type: 'CustomComponent',
                id: data.linkedPart.part.id,
            };
        case 'NonMatching':
            return null;
        default:
            assertUnreachable(data.linkedPart);
    }
}

function useValidLines() {
    const { control } = useFormContext<QuoteImporterFormState>();
    const { errors } = useFormState({ control });

    const lines = useWatch({ control, name: 'lines' });
    const selectedIndices = useWatch({ control, name: 'selectedIndices' });
    const validLines = lines.filter((line) => !hasErrorsForLine(line, errors));

    const numberOfTotalLines = lines.length;
    const numberOfValidLines = validLines.length;
    const numberOfValidSelectedLines = validLines.filter(({ index }) => selectedIndices.includes(index)).length;

    const disabled =
        numberOfValidSelectedLines === 0 ||
        isPresent(errors.supplierAndStockLocation) ||
        isPresent(errors.offerNumber) ||
        isPresent(errors.offerValidity) ||
        isPresent(errors.validFor);

    return { numberOfTotalLines, numberOfValidLines, numberOfValidSelectedLines, disabled };
}

function useQuoteImporterSuccessDialog({ onClose }: { onClose: () => void }) {
    const { setDialog } = useDialogContext();

    return {
        openDialog: ({ data }: { data: QuoteImporterResponse }) =>
            setDialog(
                <Dialog open={true} maxWidth={'sm'} fullWidth={true} onClose={() => onClose()}>
                    <DialogTitle title={t`Import successful`} handleClose={() => onClose()} />
                    <DialogContent>
                        <Flexbox flexDirection={'column'} gap={'8px'}>
                            <Message
                                attention={'low'}
                                size={'large'}
                                variant={'green'}
                                title={plural(data.ids.length, {
                                    one: `# offer created`,
                                    other: `# offers created`,
                                })}
                                message={t`Your quote request has been imported successfully. We have created ${formatDecimal(
                                    data.ids.length,
                                )} offers for you.`}
                            />
                            {data.design_items.length > 0 && (
                                <Message
                                    attention={'low'}
                                    size={'large'}
                                    variant={'yellow'}
                                    title={t`New part options`}
                                    message={t`The following design items have alternative part options: ${data.design_items.join(
                                        ', ',
                                    )}`}
                                />
                            )}
                            <Flexbox flexDirection={'row'} justifyContent={'space-around'} paddingTop={'12px'}>
                                <PrimaryButton size={'medium'} onClick={() => onClose()}>
                                    <Trans>Done</Trans>
                                </PrimaryButton>
                            </Flexbox>
                        </Flexbox>
                    </DialogContent>
                </Dialog>,
            ),
    };
}

const StandardPartImportQuoteButton: React.FunctionComponent = () => {
    const history = useHistory();

    const { control, getValues } = useFormContext<StandardPartQuoteImporterFormState>();
    const { errors } = useFormState({ control });
    const { numberOfTotalLines, numberOfValidLines, numberOfValidSelectedLines, disabled } = useValidLines();

    const { mutateAsync: mutateAsyncUpload, isLoading: isUploading } = useHttpFileUpload(
        'GET /quote-tracking/:id/additional-files/upload-link',
        (response) => response.data.url,
        {
            snackbarMessage: t`Files uploaded successfully`,
        },
    );
    const { openDialog: openQuoteImorterSuccessDialog } = useQuoteImporterSuccessDialog({
        onClose: () => history.goBack(),
    });

    const { mutateAsync, isLoading } = useHttpMutation('POST /offers/off-the-shelf/bulk/import', {
        snackbarMessage: null,
        onSuccess: async (data) => {
            const { file, supplierAndStockLocation } = getValues();

            analytics.track('excel_quote_imported_successful', {
                number_of_quotes: data.ids.length,
                number_of_alternative_parts: data.design_items.length,
                numberOfTotalLines,
                fileName: file.name,
                supplierName: formatSupplierAndStockLocationDTO(supplierAndStockLocation),
            });

            await mutateAsyncUpload({ pathParams: { id: data.quote_tracking_id }, files: file });

            Sentry.metrics.increment('quote_import.successful', 1, {
                tags: {
                    type: 'StandardPart',
                    'file.name': file.name,
                    'quotes.count': data.ids.length,
                    'alternative_parts.count': data.design_items.length,
                    'supplier.name': formatSupplierAndStockLocationDTO(supplierAndStockLocation),
                },
            });

            Sentry.metrics.increment('quote_import.funnel', 1, { tags: { step: 'successful' } });

            openQuoteImorterSuccessDialog({ data });
        },
    });

    const handleSubmit = React.useCallback(async () => {
        const values = getValues();

        await mutateAsync({
            requestBody: {
                rfq_id: values.rfq.id,
                supplier_and_stock_location: assertPresent(values.supplierAndStockLocation?.id),
                quote_tracking_id: values.quoteTracking?.id,
                inputs: createStandardPartOffers({ values, errors }),
                event_metadata: { type: 'Excel' },
            },
        });
    }, [getValues, mutateAsync, errors]);

    return (
        <Flexbox gap={8} alignItems={'baseline'}>
            <Text variant={'body-small'} color={colorSystem.neutral[7]}>
                {t`${formatDecimal(numberOfValidLines)} of ${formatDecimal(
                    numberOfTotalLines,
                )} offers ready to be imported`}
            </Text>
            <PrimaryButton
                size={'medium'}
                onClick={handleSubmit}
                disabled={disabled}
                isLoading={isLoading || isUploading}
                startIcon={<AddRounded />}
            >
                {t`Import ${formatDecimal(numberOfValidSelectedLines)} offers`}
            </PrimaryButton>
        </Flexbox>
    );
};

const CustomPartImportQuoteButton: React.FunctionComponent = () => {
    const history = useHistory();

    const { control, getValues } = useFormContext<CustomPartQuoteImporterFormState>();
    const { errors } = useFormState({ control });

    const { mutateAsync: mutateAsyncUpload, isLoading: isUploading } = useHttpFileUpload(
        'GET /quote-tracking/:id/additional-files/upload-link',
        (response) => response.data.url,
        {
            snackbarMessage: t`Files uploaded successfully`,
        },
    );

    const { numberOfTotalLines, numberOfValidLines, numberOfValidSelectedLines, disabled } = useValidLines();

    const { mutateAsync, isLoading } = useHttpMutation('POST /offers/custom-part/bulk/import', {
        snackbarMessage: t`Successfully imported ${formatDecimal(numberOfValidSelectedLines)} offers`,
        onSuccess: async (data) => {
            const { file, supplierAndStockLocation } = getValues();

            await mutateAsyncUpload({ pathParams: { id: data.quote_tracking_id }, files: file });

            analytics.track('excel_custom_part_quote_imported_successful', {
                number_of_quotes: data.items.length,
                fileName: file.name,
                supplierName: formatSupplierAndStockLocationDTO(supplierAndStockLocation),
            });

            Sentry.metrics.increment('quote_import.successful', 1, {
                tags: {
                    type: 'CustomPart',
                    'file.name': file.name,
                    'quotes.count': data.items.length,
                    'supplier.name': formatSupplierAndStockLocationDTO(supplierAndStockLocation),
                },
            });

            Sentry.metrics.increment('quote_import.funnel', 1, { tags: { step: 'successful' } });
            history.goBack();
        },
    });

    const handleSubmit = React.useCallback(async () => {
        const values = getValues();

        await mutateAsync({
            requestBody: {
                rfq_id: values.rfq.id,
                supplier_and_stock_location_id: assertPresent(values.supplierAndStockLocation).id,
                quote_tracking_id: values.quoteTracking?.id,
                custom_part_offers: createCustomPartOffers({ values, errors }),
                event_metadata: { type: 'Excel' },
            },
        });
    }, [getValues, mutateAsync, errors]);

    return (
        <Flexbox gap={8} alignItems={'baseline'}>
            <Text variant={'body-small'} color={colorSystem.neutral[7]}>
                {t`${formatDecimal(numberOfValidLines)} of ${formatDecimal(
                    numberOfTotalLines,
                )} offers ready to be imported`}
            </Text>
            <PrimaryButton
                size={'medium'}
                onClick={handleSubmit}
                disabled={disabled}
                isLoading={isLoading || isUploading}
                startIcon={<AddRounded />}
            >
                {t`Import ${formatDecimal(numberOfValidSelectedLines)} offers`}
            </PrimaryButton>
        </Flexbox>
    );
};

const CloseButton: React.FunctionComponent = () => {
    const history = useHistory();
    const { control } = useFormContext<QuoteImporterFormState>();
    const file = useWatch({ control, name: 'file' });

    return (
        <Flexbox gap={12} alignItems={'center'}>
            <SecondaryButton
                size="medium"
                startIcon={<Close />}
                onClick={() => history.goBack()}
                style={{ width: 'fit-content' }}
            >
                <Trans>Close</Trans>
            </SecondaryButton>
            <Flexbox gap={'4px'}>
                <Text variant="h4">
                    <Trans>Importing:</Trans>
                </Text>
                <Text variant="h4" color={colorSystem.neutral[7]}>
                    <Trans>{file.name}</Trans>
                </Text>
            </Flexbox>
        </Flexbox>
    );
};

export function ExcelQuoteImporterToolbar() {
    const { control } = useFormContext<QuoteImporterFormState>();
    const state = useWatch({ control, name: 'state' });
    const lines = useWatch({ control, name: 'lines' });

    if (state === 'incomplete' || state === 'loading') {
        return <></>;
    }

    if (lines.every((item) => item.type === 'StandardPartLine')) {
        return (
            <Toolbar breadcrumbs={[]} left={<CloseButton />}>
                <StandardPartImportQuoteButton />
            </Toolbar>
        );
    }

    if (lines.every((item) => item.type === 'CustomPartLine')) {
        return (
            <Toolbar breadcrumbs={[]} left={<CloseButton />}>
                <CustomPartImportQuoteButton />
            </Toolbar>
        );
    }

    throwErrorUnlessProduction(new Error('Unsupported line type'), { extra: { lines } });
    return <></>;
}
