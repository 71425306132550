// We want to support umlauts characters
// this function is mostly needed in file downloads whereby the umlauts in the file name get wrongly encoded
// an example of this is can be found here https://luminovo.slack.com/archives/C05KK5GDCRZ/p1720694847048449
export function normalizeUmlauts(str: string): string {
    return str
        .replace(/Ã¼/g, 'ü')
        .replace(/Ã¤/g, 'ä')
        .replace(/Ã¶/g, 'ö')
        .replace(/Ãœ/g, 'Ü')
        .replace(/Ã„/g, 'Ä')
        .replace(/Ã–/g, 'Ö')
        .replace(/ÃŸ/g, 'ß');
}
