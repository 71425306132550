import { colorSystem } from '@luminovo/design-system';
import { TableCell, styled } from '@mui/material';

export const StickyTableCell = styled(TableCell)(({ isPartRemoved }: { isPartRemoved?: boolean }) => ({
    textAlign: 'center',
    position: 'sticky',
    right: 0,
    background: isPartRemoved ? colorSystem.neutral[0] : colorSystem.neutral.white,
    boxShadow: `inset 1px 0px 0px 0px ${colorSystem.neutral[2]}`,
}));
