import { t } from '@lingui/macro';
import { assertPresent, formatToIso8601Date } from '@luminovo/commons';
import { CenteredLayout } from '@luminovo/design-system';
import { RfqContext } from '@luminovo/http-client';
import { extractValidFor } from '@luminovo/sourcing-core';
import { CircularProgress } from '@mui/material';
import { useHistory } from 'react-router';
import { useHttpMutation } from '../../../resources/mutation/useHttpMutation';
import { useOTSOffer } from '../../../resources/offer/offerHandler';
import { useGlobalCurrency } from '../../../resources/organizationSettings/currencySettingsHandler';
import { useRfQ } from '../../../resources/rfq/rfqHandler';
import { useSupplierAndStockLocations } from '../../../resources/supplierAndStockLocation/supplierAndStockLocationHandler';
import { UrlParams } from '../../../utils/routes';
import { StandardPartOfferForm, StandardPartOfferFormValues } from './StandardPartOfferForm';
import { convertPriceBreaks, convertToStandardPartOfferInputDTO } from './converters';

export function DuplicateStandardPartOfferForm({
    pathParams,
}:
    | UrlParams<'/rfqs/:rfqId/sourcing/offer/off-the-shelf/:offerId/duplicate'>
    | UrlParams<'/rfqs/:rfqId/sourcing/offer/internal-part-number/:offerId/duplicate'>) {
    const { rfqId, offerId } = pathParams;
    const history = useHistory();
    const rfqContext: RfqContext = { type: 'WithinRfQ', rfq_id: rfqId };

    const { data: offer } = useOTSOffer({ offerId, rfqContext });
    const { data: supplierAndStockLocations } = useSupplierAndStockLocations();

    const { data: rfq } = useRfQ(rfqId);
    const { preferredCurrency } = useGlobalCurrency();

    const { mutateAsync } = useHttpMutation('POST /offers/off-the-shelf', {
        snackbarMessage: t`Manual offer successfully created`,
        onSuccess: () => history.goBack(),
    });

    if (!offer || !rfq || !supplierAndStockLocations) {
        return (
            <CenteredLayout height={'30vh'}>
                <CircularProgress />
            </CenteredLayout>
        );
    }

    const defaultValues: StandardPartOfferFormValues = {
        linkedPart: offer.linked_part,
        supplierAndStockLocation: assertPresent(
            supplierAndStockLocations.find((s) => s.id === offer.linked_location.id),
        ),
        currency: offer.available_prices.price_breaks[0].unit_price.currency ?? preferredCurrency,
        notes: offer.notes,
        validUntilDate: offer.valid_until ? formatToIso8601Date(offer.valid_until) : undefined,
        supplierPartNumber: offer.supplier_part_number ?? '',
        offerNumber: offer.offer_number,
        quantityUnit: offer.unit_of_measurement.quantity_unit.unit,
        quantity: offer.unit_of_measurement.quantity_unit.quantity,
        priceType: offer.price_type,
        factoryLeadTime: offer.available_prices.factory_lead_time_days,
        factoryQuantity: offer.available_prices.factory_quantity,
        onOrder: offer.available_prices.on_order,
        packaging: offer.packaging,
        stock: offer.available_prices.stock,
        rfqId: offer.rfq ?? rfq.id,
        customerId: offer.customer ?? rfq.customer,
        priceBreaks: convertPriceBreaks(offer.available_prices.price_breaks),
        validFor: extractValidFor(offer),
        ncnr: offer.ncnr,
    };

    return (
        <StandardPartOfferForm
            rfqContext={rfqContext}
            onSubmit={async (form) => {
                await mutateAsync({
                    requestBody: convertToStandardPartOfferInputDTO(form),
                });
            }}
            defaultValues={defaultValues}
            disabledQuantityUnit={false}
            rfqId={rfqId}
        />
    );
}
