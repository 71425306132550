import * as r from 'runtypes';
import { endpoint } from '../http/endpoint';
import { BomFileDTORuntype, BomProgressRuntype } from './bomImporterBackendTypes';

export const bomImporterEndpoints = {
    'GET /bom-files/:id': endpoint({
        description: "returns a BOM file given it's ID",
        pathParams: r.Record({ id: r.String }),
        queryParams: r.Undefined,
        requestBody: r.Undefined,
        responseBody: r.Record({ data: BomFileDTORuntype }),
    }),

    'GET /bom-files/example': endpoint({
        description: 'returns a URL to download a BOM example - used in the guidance drawer for the BOM structure',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: r.Undefined,
        responseBody: r.Record({ data: r.Record({ url: r.String }) }),
    }),

    'GET /bom-importer/screener/tasks/:taskId': endpoint({
        description: 'returns the status of a BOM import task',
        pathParams: r.Record({ taskId: r.String }),
        queryParams: r.Undefined,
        requestBody: r.Undefined,
        responseBody: r.Record({ data: BomProgressRuntype }),
    }),
    'GET /bom-importer/line-builder/tasks/:taskId': endpoint({
        description: 'returns the status of a BOM import task',
        pathParams: r.Record({ taskId: r.String }),
        queryParams: r.Undefined,
        requestBody: r.Undefined,
        responseBody: r.Record({ data: BomProgressRuntype }),
    }),
};
