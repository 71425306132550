import {
    AvailabilityDTO,
    BomItemApprovalStatus,
    BomItemGeneralProperties,
    BomItemIssue,
    BomItemPartDataIssues,
    ComplianceStatus,
    DesignItemOriginTypesRuntype,
    DesignItemResponseDTO,
    EmissionData,
    FullPart,
    LifecycleEnum,
    PartCountEnum,
    PartSpecification,
    QuantityUnitDTO,
} from '@luminovo/http-client';
import * as r from 'runtypes';
import { FullPartWithApprovalStatus } from '../part/partFrontendTypes';

interface BomItemOrigin extends r.Static<typeof BomItemOriginRuntype> {}
export const BomItemOriginRuntype = r.Record({
    type: DesignItemOriginTypesRuntype,
    bomFileIds: r.Optional(r.Array(r.String)),
    originalRowNumber: r.Optional(r.Number),
    originalRowNumbers: r.Optional(r.Array(r.Number)),
    pcb: r.Optional(r.String),
});

export interface BomItem {
    /**
     * The list of all aggregated IDs.
     *
     * An empty array is a possible value as a BOM items imported from an empty BOM line can
     * have no designator.
     */
    id: Array<string>;
    /**
     * The list of all aggregated designators.
     */
    designator: string[];
    quantity: QuantityUnitDTO | null;
    doNotPlace: boolean;
    ignorePackageNameMismatch: boolean;
    notes: string;
    parentId: string;
    optionIds: string[] | null;
    specification: PartSpecification | null;
    /**
     * An array of parts with their approval status derived from the design item's specification
     *
     * Every part mentioned in this list conforms with the design item's specification.
     */
    parts: FullPartWithApprovalStatus[];
    /**
     * An array of approved part options derived from the design item's specification
     *
     * Every part mentioned in this list conforms with the design item's specification.
     */
    approvedPartOptions: FullPart[];
    origin: BomItemOrigin;
    /**
     * A reference to the underlying, (i.e. non-aggregated) design items.
     */
    individualDesignItems: DesignItemResponseDTO[];
    /**
     * is_consigned derived from the DesignItem Response
     * When a design item is consigned it means that it will be sourced by the customer to the EMS
     */
    isConsigned: boolean;
    /**
     * Contains BOM item & part availability information
     */
    availability: AvailabilityDTO | null;

    reachCompliant?: ComplianceStatus;
    rohsCompliant?: ComplianceStatus;
    aecqCompliant?: ComplianceStatus;
    lifecycleStatus?: LifecycleEnum;
    /**
     * a flag that determines if we should display the part alternatives components
     */
    showPartAlternatives: boolean;

    emissionData: EmissionData | null;

    yearsToEndOfLife: number | null;

    approvedPartOptionCardinality: PartCountEnum;

    approvalStatus: BomItemApprovalStatus;
    /**
     * These represent the active set of issues the bomItem has.
     * They are computed in the backend based off the global bom warnings configuration, missingManufacturing fields &
     * for customer users, if show part data warnings is set to true.
     */
    issues: BomItemIssue[];
    /**
     * These represent the issues derived from the bomItem's part data. They only check
     * part_data information and do not consider any configuration. This is important,
     * because users would like to filter BomItems based off these issues regardless of whether manufacturing
     * is activated or not.
     */
    partDataIssues: BomItemPartDataIssues[];

    generalProperties: BomItemGeneralProperties[];
}
