import { colorSystem, Flexbox } from '@luminovo/design-system';
import { InfoOutlined } from '@mui/icons-material';
import { Box, Tooltip, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';

export const LayerGroupColumn = styled(Box)({
    boxSizing: 'border-box',
    backgroundColor: colorSystem.neutral[0],
    border: `1px solid ${colorSystem.neutral[2]}`,
    padding: '20px 16px',
    borderRadius: '8px',
    flex: 1,
    minHeight: '100%',
});

export const FileGroupColumn = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: colorSystem.neutral[0],
    border: `1px solid ${colorSystem.neutral[2]}`,
    borderRadius: '8px',
    padding: '20px 16px',
    gap: '16px',
});

export const LayerGroupHeader: React.FunctionComponent<{
    id?: string;
    name: string;
    style?: React.CSSProperties;
    description?: string;
}> = ({ id, name, description, style }) => {
    return (
        <Flexbox id={id} gap={'8px'} style={style} alignItems={'center'}>
            <Typography variant="h2" style={{ color: colorSystem.neutral[8], fontSize: '18px' }}>
                {name}
            </Typography>
            {description && (
                <Tooltip title={description} arrow>
                    <InfoOutlined fontSize="small" style={{ cursor: 'pointer', color: colorSystem.neutral[7] }} />
                </Tooltip>
            )}
        </Flexbox>
    );
};
