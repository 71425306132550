import { t, Trans } from '@lingui/macro';
import { compareByString, isPresent, TransEnum } from '@luminovo/commons';
import { colorSystem, DefaultCloseButton, Flexbox, h5, Tag, Text, Tooltip } from '@luminovo/design-system';
import { AssemblyDTO, OverallRisk, ParentAssembly as ParentAssemblyType } from '@luminovo/http-client';
import { assemblyIndustryTranslations } from '@luminovo/manufacturing-core';
import { ChevronRight, ExpandMore, InfoRounded } from '@mui/icons-material';
import { Avatar, Box, Collapse, Dialog, DialogContent, Paper, styled } from '@mui/material';
import React from 'react';
import { useHistory } from 'react-router';
import {
    getSpecificAssemblyPath,
    useDescendants,
    useDescendantsSummary,
    useParentsIdentifiers,
} from '../../../../resources/assembly/assemblyHandler';
import { assemblyTypePublicTranslations } from '../../../../resources/rfq/i18n';
import { typeSafeObjectKeys } from '../../../../utils/typingUtils';
import { ViewContext } from '../ModuleTableData';

export const AssemblyDetails = ({
    rfqId,
    assemblyId,
    currentParentAssemblyId,
}: {
    rfqId: string;
    assemblyId: string;
    currentParentAssemblyId?: string | null;
}) => {
    const { data: parentsData } = useParentsIdentifiers(assemblyId);
    const parents: ParentAssemblyType[][] = parentsData?.multi_parent_items ?? [];
    const parentsFirstPath = parents[0] ?? [];
    const isBomMainPage = parentsFirstPath.length < 2;
    const { data: descendants } = useDescendants(assemblyId);
    const assembly = descendants?.data.parent;

    const subAssemblies = React.useMemo(() => {
        return descendants?.data.assemblies.filter((assembly) => assemblyId in assembly.parents) ?? [];
    }, [descendants?.data.assemblies, assemblyId]);

    const otherParents = React.useMemo(() => {
        const assemblyParents = Object.entries(assembly?.parents ?? {});
        if (parentsData && assemblyParents.length > 1) {
            const parents = parentsData.multi_parent_items
                .map((data) => {
                    // The parent is always the second last element in the array
                    return data[data.length - 2];
                })
                .filter(isPresent);

            return assemblyParents
                .map(([parentId, quantity]) => {
                    const parent = parents.find((parent) => parent.id === parentId);
                    if (parent && currentParentAssemblyId !== parent.id) return { ...parent, quantity };
                    return undefined;
                })
                .filter(isPresent)
                .sort((a, b) => compareByString(a.designator, b.designator));
        }
        return [];
    }, [assembly?.parents, parentsData, currentParentAssemblyId]);

    const sortedSubassemblies = React.useMemo(() => {
        return subAssemblies.sort((d1, d2) => compareByString(d1.designator, d2.designator));
    }, [subAssemblies]);

    const viewContext: ViewContext = React.useMemo(() => {
        return {
            type: 'WithinRfQ',
            rfqId,
        };
    }, [rfqId]);

    if (!assembly || !parentsData) {
        return null;
    }

    const { bom_items: bomItems, design_items: designItems, subassemblies } = assembly;

    return (
        <Box
            style={{
                background: colorSystem.neutral.white,
                height: '100%',
                boxSizing: 'border-box',
                padding: 20,
                gap: 20,
                display: 'flex',
                flexDirection: 'column',
            }}
        >
            {!isBomMainPage && <BomOverallRisk viewContext={viewContext} assemblyId={assembly.id} />}
            <Container>
                <Text variant="h4">
                    <Trans>Assembly</Trans>
                </Text>
                <Flexbox gap={4}>
                    {assembly.designator !== 'BOM' && (
                        <Tag
                            label={
                                <TransEnum text={assembly.type.type} translations={assemblyTypePublicTranslations} />
                            }
                            color="neutral"
                            attention="low"
                        />
                    )}
                    <Tag
                        label={<TransEnum text={assembly.industry} translations={assemblyIndustryTranslations} />}
                        color="neutral"
                        attention="low"
                    />
                </Flexbox>
                {!isBomMainPage && (
                    <>
                        <AssemblyItem
                            tooltip={t`An item on a BOM that represents an aggregated set of design items with identical part options.`}
                            label={t`BOM items`}
                            count={bomItems.length}
                        />
                        <AssemblyItem
                            tooltip={t`A component with a designator used in an assembly. We distinguish between off-the-shelf components (that can have multiple part options) and custom components (like a PCB or a mechanical part).`}
                            label={t`Design items`}
                            count={designItems.items.length}
                        />
                    </>
                )}
                <AssemblyItem
                    tooltip={t`A group of design items that are assembled together to form a larger component or system, such as a printed circuit board assembly (PCBA) or a box build.`}
                    label={t`Subassemblies`}
                    count={subassemblies.items.length}
                />
            </Container>
            <Container>
                <Text variant="h4">
                    {isBomMainPage ? <Trans>Top level assemblies</Trans> : <Trans>Subassemblies</Trans>}
                </Text>
                {sortedSubassemblies.length === 0 && (
                    <Text>
                        <Trans>No subassemblies</Trans>
                    </Text>
                )}
                {sortedSubassemblies.map((subassembly) => {
                    return (
                        <SubAssembly rfqId={rfqId} key={subassembly.id} assembly={subassembly} parentId={assemblyId} />
                    );
                })}
            </Container>
            {otherParents.length > 0 && (
                <Container>
                    <Text variant="h4">
                        <Trans>Used in</Trans>
                    </Text>
                    {otherParents.map((parent) => {
                        return (
                            <ParentAssembly
                                key={parent.id}
                                rfqId={rfqId}
                                parentId={parent.id}
                                quantity={parent.quantity}
                                designator={parent.designator}
                            />
                        );
                    })}
                </Container>
            )}
        </Box>
    );
};

const ParentAssembly = ({
    rfqId,
    parentId,
    quantity,
    designator,
}: {
    rfqId: string;
    parentId: string;
    quantity: number;
    designator: string;
}) => {
    const history = useHistory();

    const handleSubassemblyClick = React.useCallback(() => {
        history.push(getSpecificAssemblyPath({ rfqId, assemblyId: parentId, currentParentAssemblyId: undefined }));
    }, [history, parentId, rfqId]);

    return (
        <Flexbox key={parentId} justifyContent="space-between">
            <Flexbox gap={4} alignItems={'center'}>
                <LinkText
                    variant="h4"
                    color={colorSystem.neutral[6]}
                    id={parentId}
                    onClick={() => handleSubassemblyClick()}
                >
                    {designator}
                </LinkText>
            </Flexbox>
            <Text>{quantity}</Text>
        </Flexbox>
    );
};

const SubAssembly = ({ assembly, parentId, rfqId }: { assembly: AssemblyDTO; parentId: string; rfqId: string }) => {
    const history = useHistory();

    let quantity = assembly.parents[parentId] ?? 1;

    const handleSubassemblyClick = React.useCallback(
        (data: AssemblyDTO) => {
            history.push(getSpecificAssemblyPath({ rfqId, assemblyId: data.id, currentParentAssemblyId: parentId }));
        },
        [history, parentId, rfqId],
    );

    return (
        <Flexbox justifyContent="space-between">
            <Flexbox gap={4} key={assembly.id} alignItems={'center'}>
                <AvatarContainer overallRisk={assembly.overall_risk} />
                <LinkText
                    variant="h4"
                    color={colorSystem.neutral[6]}
                    id={assembly.id}
                    onClick={() => handleSubassemblyClick(assembly)}
                >
                    {assembly.designator}
                </LinkText>
            </Flexbox>
            <Text>{quantity}</Text>
        </Flexbox>
    );
};

const AssemblyItem = ({ label, count, tooltip }: { label: string; count: number; tooltip: string }) => {
    return (
        <Flexbox justifyContent="space-between">
            <Flexbox gap={4} alignItems="center">
                <Tooltip title={tooltip} placement={'top'}>
                    <InfoRounded fontSize="inherit" style={{ color: colorSystem.neutral[5] }} />
                </Tooltip>
                <Text>{label}</Text>
            </Flexbox>
            <Text>{count}</Text>
        </Flexbox>
    );
};

const Container = styled('div')({
    display: 'flex',
    height: 'auto',
    background: colorSystem.neutral.white,
    flexDirection: 'column',
    border: `1px solid ${colorSystem.neutral[2]}`,
    padding: 12,
    borderRadius: 8,
    gap: 12,
    overflow: 'clip',
});

const LinkText = styled(Text)({
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    maxWidth: 130,
    '&:hover': {
        cursor: 'pointer',
    },
});

export const BomOverallRisk = ({ assemblyId, viewContext }: { assemblyId: string; viewContext: ViewContext }) => {
    const { data } = useDescendantsSummary(assemblyId, viewContext);
    const [isOpen, setIsOpen] = React.useState(false);
    const toggleIsOpen = () => {
        setIsOpen((isOpen) => !isOpen);
    };
    if (!data) return null;

    return (
        <OverallRiskContainer overallRisk={data.overall_risk}>
            <div>
                <StyledOverallRiskHeader
                    onClick={toggleIsOpen}
                    justifyContent="space-between"
                    justifyItems={'center'}
                    alignItems={'center'}
                >
                    <Flexbox gap={8} alignItems={'center'}>
                        <AvatarContainer overallRisk={data.overall_risk} />
                        {overallRiskToTitleAndExplanation[data.overall_risk].title}
                    </Flexbox>
                    {isOpen ? (
                        <ExpandMore
                            style={{
                                color: colorSystem.neutral[7],
                            }}
                        />
                    ) : (
                        <ChevronRight
                            style={{
                                color: colorSystem.neutral[7],
                            }}
                        />
                    )}
                </StyledOverallRiskHeader>
                <Collapse collapsedSize={0} in={isOpen}>
                    <ExpandedExplanation overallRisk={data.overall_risk} />
                </Collapse>
            </div>
        </OverallRiskContainer>
    );
};

const OverallRiskContainer = ({ overallRisk, children }: React.PropsWithChildren<{ overallRisk: OverallRisk }>) => {
    const riskToBackgroundColor: Record<OverallRisk, string> = {
        [OverallRisk.LowRisk]: colorSystem.green['1'],
        [OverallRisk.MediumLowRisk]: colorSystem.green['1'],
        [OverallRisk.MediumRisk]: colorSystem.yellow['1'],
        [OverallRisk.MediumHighRisk]: colorSystem.red['1'],
        [OverallRisk.HighRisk]: colorSystem.red['2'],
    };

    const riskToBorderColor: Record<OverallRisk, string> = {
        [OverallRisk.LowRisk]: colorSystem.green['2'],
        [OverallRisk.MediumLowRisk]: colorSystem.green['2'],
        [OverallRisk.MediumRisk]: colorSystem.yellow['2'],
        [OverallRisk.MediumHighRisk]: colorSystem.red['2'],
        [OverallRisk.HighRisk]: colorSystem.red['3'],
    };

    return (
        <StyledOverallRiskContainer
            style={{
                backgroundColor: riskToBackgroundColor[overallRisk],
                borderColor: riskToBorderColor[overallRisk],
            }}
        >
            {children}
        </StyledOverallRiskContainer>
    );
};

const StyledOverallRiskHeader = styled(Flexbox)({
    padding: '12px 10px',
    cursor: 'pointer',
});

const StyledOverallRiskContainer = styled(Container)({
    padding: 0,
});

const StyledOverallRiskAvatar = styled(Avatar)({
    height: '22px',
    width: '22px',
    ...h5,
});

const AvatarContainer = ({ overallRisk }: { overallRisk: OverallRisk }) => {
    const scoreLetterMapping: Record<OverallRisk, string> = {
        [OverallRisk.LowRisk]: 'A',
        [OverallRisk.MediumLowRisk]: 'B',
        [OverallRisk.MediumRisk]: 'C',
        [OverallRisk.MediumHighRisk]: 'D',
        [OverallRisk.HighRisk]: 'E',
    };
    const scoreColorMapping: Record<OverallRisk, string> = {
        [OverallRisk.LowRisk]: colorSystem.green['7'],
        [OverallRisk.MediumLowRisk]: colorSystem.green['5'],
        [OverallRisk.MediumRisk]: colorSystem.yellow['6'],
        [OverallRisk.MediumHighRisk]: colorSystem.red['5'],
        [OverallRisk.HighRisk]: colorSystem.red['7'],
    };
    return (
        <StyledOverallRiskAvatar
            variant="rounded"
            style={{
                backgroundColor: scoreColorMapping[overallRisk],
            }}
        >
            {scoreLetterMapping[overallRisk]}
        </StyledOverallRiskAvatar>
    );
};

const ExplanationDescription = ({ children }: { children: JSX.Element }) => {
    return (
        <Text variant={'body-small'} color={colorSystem.neutral[8]}>
            {children}
        </Text>
    );
};

const overallRiskToTitleAndExplanation: Record<OverallRisk, { title: JSX.Element; description: JSX.Element }> = {
    [OverallRisk.LowRisk]: {
        title: (
            <Text variant="h4">
                <Trans>Low risk</Trans>
            </Text>
        ),
        description: (
            <ExplanationDescription>
                <Trans>
                    The BOM Items in this assembly are REACH and RoHS compliant with an active lifecycle and have
                    approved part options that are available.
                </Trans>
            </ExplanationDescription>
        ),
    },
    [OverallRisk.MediumLowRisk]: {
        title: (
            <Text variant="h4">
                <Trans>Medium low risk</Trans>
            </Text>
        ),
        description: (
            <ExplanationDescription>
                <Trans>
                    The BOM Items in this assembly are REACH and RoHS compliant with an active lifecycle but may have
                    insufficient stock.
                </Trans>
            </ExplanationDescription>
        ),
    },
    [OverallRisk.MediumRisk]: {
        title: (
            <Text variant="h4">
                <Trans>Medium risk</Trans>
            </Text>
        ),
        description: (
            <ExplanationDescription>
                <Trans>
                    The BOM Items in this assembly may have to be reviewed for compliance & lifecycle but have approved
                    part options that are available.
                </Trans>
            </ExplanationDescription>
        ),
    },
    [OverallRisk.MediumHighRisk]: {
        title: (
            <Text variant="h4">
                <Trans>Medium high risk</Trans>
            </Text>
        ),
        description: (
            <ExplanationDescription>
                <Trans>
                    The BOM Items in this assembly may have to be reviewed for compliance & lifecycle and may have
                    insufficient stock or no approved part options.
                </Trans>
            </ExplanationDescription>
        ),
    },
    [OverallRisk.HighRisk]: {
        title: (
            <Text variant="h4">
                <Trans>High risk</Trans>
            </Text>
        ),
        description: (
            <ExplanationDescription>
                <Trans>
                    The BOM Items in this assembly may have lifecycles that are inactive or obsolete, may not be REACH
                    or RoHS compliant, and may have insufficient stock or no approved part options.
                </Trans>
            </ExplanationDescription>
        ),
    },
};

const Explanation = styled('div')({
    backgroundColor: 'white',
    padding: '8px 12px',
});

const ExpandedExplanation = ({ overallRisk }: { overallRisk: OverallRisk }) => {
    const [isOpen, setIsOpen] = React.useState(false);
    const handleClose = () => {
        setIsOpen(() => false);
    };

    const handleOpen = () => {
        setIsOpen(() => true);
    };
    return (
        <Explanation>
            {overallRiskToTitleAndExplanation[overallRisk].description}
            <StyledLearnMoreContainer>
                <Text variant={'h5'} color={colorSystem.neutral[7]} onClick={handleOpen}>
                    <Trans>Learn more</Trans>
                </Text>
            </StyledLearnMoreContainer>
            <RiskModal isOpen={isOpen} close={handleClose} />
        </Explanation>
    );
};

const StyledLearnMoreContainer = styled('div')({
    marginTop: 8,
    display: 'flex',
    justifyContent: 'flex-end',
    cursor: 'pointer',
});

const RiskModal = ({ isOpen, close }: { isOpen: boolean; close: () => void }) => {
    return (
        <Dialog open={isOpen} onClose={close} keepMounted PaperComponent={StyledDialogPaper}>
            <StyledDialogContainer>
                <Flexbox alignItems={'center'} justifyContent={'space-between'}>
                    <Text variant={'h3'}>
                        <Trans>Overall risk</Trans>
                    </Text>
                    <DefaultCloseButton onClick={close} />
                </Flexbox>
                <StyledDialogContent>
                    {typeSafeObjectKeys(OverallRisk).map((score) => {
                        const overallRisk = OverallRisk[score];
                        return <RiskTitleAndExplanation key={score} overallRisk={overallRisk} />;
                    })}
                </StyledDialogContent>
            </StyledDialogContainer>
        </Dialog>
    );
};

const StyledDialogPaper = styled(Paper)({
    borderRadius: '8px',
});

const RiskTitleAndExplanation = ({ overallRisk }: { overallRisk: OverallRisk }) => {
    return (
        <StyledRiskTitleAndExplanationContainer className={'title_and_description'}>
            <StyledRiskTitle gap={8} alignItems={'center'}>
                <AvatarContainer overallRisk={overallRisk} />
                {overallRiskToTitleAndExplanation[overallRisk].title}
            </StyledRiskTitle>
            {overallRiskToTitleAndExplanation[overallRisk].description}
        </StyledRiskTitleAndExplanationContainer>
    );
};

const StyledRiskTitleAndExplanationContainer = styled('div')({
    padding: '16px',
});
const StyledRiskTitle = styled(Flexbox)({
    marginBottom: '8px',
});
const StyledDialogContainer = styled('div')({
    padding: '24px',
    backgroundColor: colorSystem.neutral['1'],
    maxWidth: '480px',
});

const StyledDialogContent = styled(DialogContent)({
    padding: 0,
    marginTop: '25px',
    background: colorSystem.neutral.white,
    border: `1px solid ${colorSystem.neutral[2]}`,
    borderRadius: '8px',
    '& .title_and_description:not(:last-child)': {
        borderBottom: `1px solid ${colorSystem.neutral[2]}`,
    },
});
