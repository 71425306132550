import { t } from '@lingui/macro';
import { assertUnreachable, isPresent } from '@luminovo/commons';
import {
    FullPart,
    isCustomComponentFull,
    isCustomFullPart,
    isGenericFullPart,
    isOtsComponentFull,
    isOtsFullPart,
} from '@luminovo/http-client';

export function formatPart(part: FullPart | undefined | null): string {
    if (!isPresent(part)) {
        return '';
    }

    if (isOtsFullPart(part)) {
        return `${part.mpn}, ${part.manufacturer.name}`;
    }

    if (isOtsComponentFull(part)) {
        return `${t`IPN`}: ${part.id}`;
    }

    if (isCustomComponentFull(part)) {
        return `${t`Custom component`}: ${part.id}`;
    }

    if (isGenericFullPart(part)) {
        return `${t`Generic`}: ${part.content.type}`;
    }

    if (isCustomFullPart(part)) {
        return `${t`Custom`}: ${part.type.name}`;
    }

    assertUnreachable(part);
}
