import { assertUnreachable } from '@luminovo/commons';
import { SecondaryIconButton } from '@luminovo/design-system';
import {
    FullPart,
    isCustomComponentFull,
    isCustomFullPart,
    isGenericFullPart,
    isOtsComponentFull,
    isOtsFullPart,
} from '@luminovo/http-client';
import { DescriptionRounded } from '@mui/icons-material';
import { useCallback } from 'react';
import { TableCell } from './TableCell';

const DatasheetIconButton = ({ url }: { url: string }) => {
    const handleClick = useCallback(
        (e) => {
            e.stopPropagation();
            if (!url) {
                return;
            }
            window.open(url, '_blank', 'noopener,noreferrer');
        },
        [url],
    );

    return (
        <SecondaryIconButton size="small" onClick={handleClick}>
            <DescriptionRounded fontSize="inherit" />
        </SecondaryIconButton>
    );
};

export const RenderDatasheet = ({ part, onClick }: { part: FullPart; onClick?: () => void }): JSX.Element => {
    if (isOtsFullPart(part)) {
        const url = part.datasheet_url;
        if (!url) {
            return <TableCell onClick={onClick}>-</TableCell>;
        }

        return (
            <TableCell style={{ padding: 0 }} onClick={onClick}>
                <DatasheetIconButton url={url} />
            </TableCell>
        );
    }
    if (isOtsComponentFull(part) || isCustomComponentFull(part)) {
        return (
            <TableCell onClick={onClick} isComponentRemoved={part.state === 'Removed'}>
                -
            </TableCell>
        );
    }
    if (isGenericFullPart(part) || isCustomFullPart(part)) {
        return <TableCell onClick={onClick}>-</TableCell>;
    }

    assertUnreachable(part);
};
