/* eslint-disable spellcheck/spell-checker */
import { typeSafeObjectKeys } from '@luminovo/commons';
import {
    PCBV2,
    PCBV2BoardPropertiesRuntype,
    PCBV2File,
    PCBV2LayerStackPropertiesRuntype,
    PCBV2MechanicalPropertiesRuntype,
    PCBV2SpecificationCapabilities,
} from '@luminovo/http-client';
import { PcbAttribute, Region } from '@luminovo/pdf-extractor';
import React from 'react';
import { Reflect } from 'runtypes';
import { useHttpQuery } from '../../http/useHttpQuery';

type Sections = keyof PCBV2SpecificationCapabilities;

type CapabilityValue<
    TSection extends Sections,
    TCapability extends keyof PCBV2SpecificationCapabilities[TSection],
> = PCBV2SpecificationCapabilities[TSection][TCapability] extends { default: infer T } ? T : never;

interface AbstractCapability<
    TSection extends Sections,
    TCapability extends keyof PCBV2SpecificationCapabilities[TSection] & string,
> {
    section: TSection;
    capabilityName: TCapability;
    name: `${TSection}.${TCapability}`;
    type: Reflect;
    restrictions: PCBV2SpecificationCapabilities[TSection][TCapability];
    include: false | 'basic' | 'advanced';

    /**
     * If the value was extracted from the pdf, this field will be set.
     */
    extractedFromPdf?: {
        /**
         * The actual extracted value.
         */
        value: CapabilityValue<TSection, TCapability>;
        /**
         * A URL pointing to the pdf file from which the value was extracted.
         */
        file: PCBV2File;
        /**
         * The region in the pdf from which the value was extracted.
         */
        regions: Region<PcbAttribute>[];
    };
}

type BoardCapability = AbstractCapability<'board', keyof PCBV2SpecificationCapabilities['board']>;
type LayerStackCapability = AbstractCapability<'layerStack', keyof PCBV2SpecificationCapabilities['layerStack']>;
type MechanicalCapability = AbstractCapability<'mechanical', keyof PCBV2SpecificationCapabilities['mechanical']>;

export type Capability = BoardCapability | LayerStackCapability | MechanicalCapability;

// field sorted in this order
const pcbFieldsBasic: Capability['capabilityName'][] = [
    'layerstackType',
    'layercount',
    'minViaDiameter',
    'boardHeight',
    'boardWidth',
    'outerCopperThickness',
    'innerCopperThickness',
    'minOuterLayerStructure',
    'minInnerLayerStructure',
    'soldermaskColor',
    'soldermaskSide',
    'silkscreenColor',
    'silkscreenSide',
    'surfaceFinish',
    'enigThickness',
    'ipc600Class',
    'baseMaterial',
    'tgValue',
    'finalThickness',
    'eTest',
    'ulMarkingType',
    'blindVias',
    'buriedVias',
];

const pcbFieldsAdvanced: Capability['capabilityName'][] = [
    'viaFilling',
    'edgeMetalization',
    'pressFit',
    'impedanceTested',
    'peelableMask',
    'chamfering',
    'maxXOutsAllowed',
    'zAxisMilling',
    'hardGold',
    'ulLayerStack',
    'halogenFree',
];

function flattenCapabilities(pcbCapabilities: PCBV2SpecificationCapabilities | undefined): Capability[] {
    if (!pcbCapabilities) {
        return [];
    }
    const { board, layerStack, mechanical } = pcbCapabilities;
    function isIncluded(capabilityName: Capability['capabilityName']): false | 'basic' | 'advanced' {
        if (pcbFieldsAdvanced.includes(capabilityName)) {
            return 'advanced';
        }
        if (pcbFieldsBasic.includes(capabilityName)) {
            return 'basic';
        }
        return false;
    }

    const boardCapabilities: BoardCapability[] = typeSafeObjectKeys(board).map((capabilityName) => {
        return {
            capabilityName,
            name: `board.${capabilityName}`,
            section: 'board',
            type: PCBV2BoardPropertiesRuntype.fields[capabilityName].underlying,
            restrictions: board[capabilityName],
            include: isIncluded(capabilityName),
        } as const;
    });

    const layerStackCapabilities: LayerStackCapability[] = typeSafeObjectKeys(layerStack).map((capabilityName) => {
        return {
            capabilityName,
            name: `layerStack.${capabilityName}`,
            section: 'layerStack',
            type: PCBV2LayerStackPropertiesRuntype.fields[capabilityName].underlying,
            restrictions: layerStack[capabilityName],
            include: isIncluded(capabilityName),
        } as const;
    });

    const mechanicalCapabilities: MechanicalCapability[] = typeSafeObjectKeys(mechanical).map((capabilityName) => {
        return {
            capabilityName,
            name: `mechanical.${capabilityName}`,
            section: 'mechanical',
            type: PCBV2MechanicalPropertiesRuntype.fields[capabilityName].underlying,
            restrictions: mechanical[capabilityName],
            include: isIncluded(capabilityName),
        } as const;
    });
    const capabilities = [...boardCapabilities, ...layerStackCapabilities, ...mechanicalCapabilities];

    // sort the capabilities according to the order specified in pcbFieldsBasic and pcbFieldsAdvanced
    return pcbFieldsBasic.concat(pcbFieldsAdvanced).flatMap((field) => {
        const capability = capabilities.find((c) => c.capabilityName === field);
        return capability ? [capability] : [];
    });
}

export type PcbCapabilitiesType = {
    basic: Capability[];
    advanced: Capability[];
    fields: Capability[];
};

export const usePcbCapabilities = ({ pcb }: { pcb: PCBV2 }): PcbCapabilitiesType => {
    const { data: capabilitiesObject } = useHttpQuery('GET /ems/pcb/v2/pcbs/:pcbId/capabilities', {
        pathParams: {
            pcbId: pcb.id,
        },
    });

    const pcbCapabilities = React.useMemo(() => {
        const capabilities = flattenCapabilities(capabilitiesObject).map((c): Capability => {
            return {
                ...c,
                extractedFromPdf: undefined,
            };
        });
        const pcbBasicCapabilities = capabilities.filter((c) => c.include === 'basic');
        const pcbAdvancedCapabilities = capabilities.filter((c) => c.include === 'advanced');

        return {
            basic: pcbBasicCapabilities,
            advanced: pcbAdvancedCapabilities,
            fields: [...pcbBasicCapabilities, ...pcbAdvancedCapabilities],
        };
    }, [capabilitiesObject]);

    return pcbCapabilities;
};

export const PCB_TYPE_VALUE_FIELDS: Capability['capabilityName'][] = ['layerstackType'];

export const EXTRACTED_VALUES_FIELDS: Capability['capabilityName'][] = [
    'layercount',
    'minViaDiameter',
    'boardHeight',
    'boardWidth',
    'minOuterLayerStructure',
    'minInnerLayerStructure',
    'blindVias',
    'buriedVias',
];

export const STACK_UP_VALUE_FIELDS: Capability['capabilityName'][] = [
    'outerCopperThickness',
    'innerCopperThickness',
    'baseMaterial',
    'tgValue',
    'finalThickness',
];

export const STACK_UP_ADVANCED_VALUE_FIELDS: Capability['capabilityName'][] = ['viaFilling', 'ulLayerStack'];

// This logic should move to backend.
export const REQUIRED_PCB_FIELDS: Capability['capabilityName'][] = [
    'layercount',
    'minViaDiameter',
    'boardHeight',
    'boardWidth',
    'outerCopperThickness',
    'innerCopperThickness',
    'minOuterLayerStructure',
    'minInnerLayerStructure',
    'soldermaskColor',
    'soldermaskSide',
    'silkscreenColor',
    'silkscreenSide',
    'surfaceFinish',
    'ipc600Class',
    'baseMaterial',
    'finalThickness',
];
