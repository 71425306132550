import { t, Trans } from '@lingui/macro';
import { colorSystem, Flexbox, RightBoxDrawer, Tag, TertiaryButton, Text } from '@luminovo/design-system';
import {
    GenericCapacitor,
    GenericFullPart,
    GenericPart,
    GenericPartTypes,
    GenericResistor,
    OtsFullPart,
    PackageDTO,
    RfqContext,
} from '@luminovo/http-client';
import { Edit } from '@mui/icons-material';
import { Tooltip } from '@mui/material';
import React from 'react';
import { AddGenericCapacitorForm } from '../../../modules/DesignItem/components/AddGenericCapacitorForm';
import {
    createGenericCapacitorFormInitialValues,
    createGenericResistorFormInitialValues,
} from '../../../modules/DesignItem/components/AddGenericPartFunctions';
import { AddGenericResistorForm } from '../../../modules/DesignItem/components/AddGenericResistorForm';
import { usePartPackages } from '../../../resources/part/partHandler';
import {
    formatCapacitance,
    formatDielectricMaterial,
    formatPowerRating,
    formatResistance,
    formatTemperatureCoefficient,
    formatTolerance,
    formatVoltageRating,
} from '../../../utils/converterUtils';
import { useDrawerContext } from '../../contexts/ModalContext';
import { OTSPartCard } from '../OTSPart/OTSPartCard';
import { OTSPartDetailsMounting } from '../OTSPart/OTSPartDetailsMounting';
import { HeadingWithValue } from '../PartCardDetailsComponents';

const MAX_MPN_MATCH_LIST_RESULTS = 50;

export function useGenericPartDetailsDrawer() {
    const { closeDrawer, setDrawer } = useDrawerContext();

    return {
        openDrawer: ({
            genericPart,
            mpnMatches,
            isEditEnable,
            onAddAlsoRemove,
            rfqContext,
        }: {
            genericPart: GenericPart;
            mpnMatches: OtsFullPart[];
            isEditEnable: boolean;
            onAddAlsoRemove: (newPart: GenericFullPart) => void;
            rfqContext: RfqContext;
        }) => {
            setDrawer(
                <RightBoxDrawer onClose={closeDrawer}>
                    <Flexbox padding="32px" minWidth="400px">
                        {genericPart.type === GenericPartTypes.Resistor && (
                            <GenericResistorCardDetails
                                genericPart={genericPart}
                                mpnMatches={mpnMatches}
                                isEditable={isEditEnable}
                                rfqContext={rfqContext}
                                onAddAlsoRemove={onAddAlsoRemove}
                                onSettled={closeDrawer}
                            />
                        )}
                        {genericPart.type === GenericPartTypes.Capacitor && (
                            <GenericCapacitorCardDetails
                                genericPart={genericPart}
                                mpnMatches={mpnMatches}
                                isEditable={isEditEnable}
                                rfqContext={rfqContext}
                                onAddAlsoRemove={onAddAlsoRemove}
                                onSettled={closeDrawer}
                            />
                        )}
                    </Flexbox>
                </RightBoxDrawer>,
            );
        },
    };
}

type GenericPartCardDetailsProps<P> = {
    genericPart: P;
    mpnMatches: OtsFullPart[];
    isEditable: boolean;
    onAddAlsoRemove: (newPart: GenericFullPart) => void;
    rfqContext: RfqContext;
    onSettled: () => void;
};

const GenericResistorCardDetails = ({
    genericPart,
    mpnMatches,
    isEditable,
    onAddAlsoRemove,
    rfqContext,
    onSettled,
}: GenericPartCardDetailsProps<GenericResistor>): JSX.Element => {
    const [isEditForm, setIsEditForm] = React.useState(false);
    const { data: allPackages = [] } = usePartPackages('generic-part-creatable');

    const {
        resistance,
        tolerance,
        power_rating: powerRating,
        voltage_rating: voltageRating,
        temperature_coefficient: tempCoefficient,
        package_id: packageId,
    } = genericPart.technical_parameters;

    const packageForResistor = allPackages.find((p) => p.id === packageId) ?? null;

    const isIncompleteResistor = resistance === null || packageId === null;

    if (isEditForm) {
        return (
            <Flexbox flexDirection="column" gap={32} width="100%">
                <Text variant="h2">
                    <Trans>Edit generic part</Trans>
                </Text>

                <AddGenericResistorForm
                    initialValues={createGenericResistorFormInitialValues(genericPart, packageForResistor)}
                    onGenericPartCreation={onAddAlsoRemove}
                    rfqContext={rfqContext}
                    onSettled={onSettled}
                />
            </Flexbox>
        );
    }

    return (
        <Flexbox flexDirection="column" gap={32} width="100%">
            <Flexbox flexDirection="column" gap={8}>
                <Text variant="h4" color={colorSystem.neutral[5]}>
                    <Trans>Generic part</Trans>
                </Text>
                <Flexbox justifyContent="space-between">
                    <Text variant="h2">
                        <Trans>Resistor</Trans>
                    </Text>
                    {(isEditable || isIncompleteResistor) && ( // Needed so the edit of generic parts isn't possible outside of bom
                        <Tooltip title={t`Edit generic resistor`} placement="top" arrow>
                            <TertiaryButton
                                size="medium"
                                onClick={() => setIsEditForm(true)}
                                disabled={!isEditable}
                                startIcon={<Edit fontSize="inherit" />}
                            >
                                <Trans>Edit</Trans>
                            </TertiaryButton>
                        </Tooltip>
                    )}
                </Flexbox>
                {isIncompleteResistor && (
                    <span>
                        <Tag color="red" label={t`Incomplete`} />
                    </span>
                )}
            </Flexbox>
            <OTSPartDetailsMounting packageData={packageForResistor} />
            <HeadingWithValue heading={t`Resistance`}>
                {resistance ? formatResistance(resistance) : <Trans>Missing</Trans>}
            </HeadingWithValue>
            <HeadingWithValue heading={t`Tolerance`}>{tolerance && `≤ ${formatTolerance(tolerance)}`}</HeadingWithValue>
            <HeadingWithValue heading={t`Power Rating`}>
                {powerRating && `≥ ${formatPowerRating(powerRating)}`}
            </HeadingWithValue>
            <HeadingWithValue heading={t`Voltage Rating`}>
                {voltageRating && `≥ ${formatVoltageRating(voltageRating)}`}
            </HeadingWithValue>
            <HeadingWithValue heading={t`Temperature Coefficient`}>
                {tempCoefficient && `≤ ${formatTemperatureCoefficient(tempCoefficient)}`}
            </HeadingWithValue>
            <HeadingWithValue heading={t`MPN matches`}>
                <Flexbox flexDirection="column" gap="4px">
                    {mpnMatches.length === 0 && <Tag color="red" label={t`No matches`} />}
                    {mpnMatches.length > 0 &&
                        mpnMatches
                            .slice(0, MAX_MPN_MATCH_LIST_RESULTS)
                            .map((part, index) => <OTSPartCard part={part} rfqContext={rfqContext} key={index} />)}
                </Flexbox>
            </HeadingWithValue>
        </Flexbox>
    );
};

const GenericCapacitorCardDetails = ({
    genericPart,
    mpnMatches,
    isEditable,
    onAddAlsoRemove,
    rfqContext,
    onSettled,
}: GenericPartCardDetailsProps<GenericCapacitor>): JSX.Element => {
    const [isEditForm, setIsEditForm] = React.useState(false);
    const { data: allPackages = [] } = usePartPackages('generic-part-creatable');

    const {
        capacitance,
        dielectric: dielectricMaterial,
        voltage_rating: voltageRating,
        tolerance,
        package_id: packageId,
    } = genericPart.technical_parameters;

    const packageForCapacitor: PackageDTO | null = allPackages.find((p) => p.id === packageId) ?? null;

    const isIncompleteCapacitor = capacitance === null || packageId === null;

    if (isEditForm) {
        return (
            <Flexbox flexDirection="column" gap={32} width="100%">
                <Text variant="h2">
                    <Trans>Edit generic part</Trans>
                </Text>

                <AddGenericCapacitorForm
                    initialValues={createGenericCapacitorFormInitialValues(genericPart, packageForCapacitor)}
                    onGenericPartCreation={onAddAlsoRemove}
                    rfqContext={rfqContext}
                    onSettled={onSettled}
                />
            </Flexbox>
        );
    }

    return (
        <Flexbox flexDirection="column" gap={32} width="100%">
            <Flexbox flexDirection="column" gap={8}>
                <Text variant="h4" color={colorSystem.neutral[5]}>
                    <Trans>Generic part</Trans>
                </Text>

                <Flexbox justifyContent="space-between">
                    <Text variant="h2">
                        <Trans>Capacitor</Trans>
                    </Text>
                    {(isEditable || isIncompleteCapacitor) && ( // Needed so the edit of generic parts isn't possible outside of bom
                        <Tooltip title={t`Edit generic capacitor`} placement="top" arrow>
                            <TertiaryButton
                                size="medium"
                                onClick={() => setIsEditForm(true)}
                                disabled={!isEditable}
                                startIcon={<Edit fontSize="inherit" />}
                            >
                                <Trans>Edit</Trans>
                            </TertiaryButton>
                        </Tooltip>
                    )}
                </Flexbox>
                {isIncompleteCapacitor && (
                    <span>
                        <Tag color="red" label={t`Incomplete`} />
                    </span>
                )}
            </Flexbox>
            <OTSPartDetailsMounting packageData={packageForCapacitor} />
            <HeadingWithValue heading={t`Capacitance`}>
                {capacitance ? formatCapacitance(capacitance) : <Trans>Missing</Trans>}
            </HeadingWithValue>
            <HeadingWithValue heading={t`Tolerance`}>{tolerance && `≤ ${formatTolerance(tolerance)}`}</HeadingWithValue>
            <HeadingWithValue heading={t`Dielectric`}>
                {dielectricMaterial && `${formatDielectricMaterial(dielectricMaterial)}`}
            </HeadingWithValue>
            <HeadingWithValue heading={t`Voltage Rating`}>
                {voltageRating && `≥ ${formatVoltageRating(voltageRating)}`}
            </HeadingWithValue>
            <HeadingWithValue heading={t`MPN matches`}>
                <Flexbox flexDirection="column" gap="4px">
                    {mpnMatches.length === 0 && <Tag color="red" label={t`No matches`} />}
                    {mpnMatches.length > 0 &&
                        mpnMatches
                            .slice(0, MAX_MPN_MATCH_LIST_RESULTS)
                            .map((part, index) => <OTSPartCard part={part} rfqContext={rfqContext} key={index} />)}
                </Flexbox>
            </HeadingWithValue>
        </Flexbox>
    );
};
