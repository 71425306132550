/* eslint-disable camelcase */
import { t } from '@lingui/macro';
import { Currency } from '@luminovo/commons';
import { ExtractRequestBody } from '@luminovo/http-client';
import { UniversalImporter } from '@luminovo/universal-importer';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router';
import { useHttpMutation } from '../../resources/mutation/useHttpMutation';
import { route } from '../../utils/routes';
import { formatError } from '../Error/formatError';

export function InventoryOfferImporter() {
    const { enqueueSnackbar } = useSnackbar();
    const history = useHistory();
    const { mutateAsync: importComponents } = useHttpMutation('POST /offers/import', { snackbarMessage: null });

    return (
        <UniversalImporter
            title={t`Inventory offer importer`}
            batchSize={Infinity}
            hrefBack={route('/parts/components/ipn')}
            onImportDone={() => {
                enqueueSnackbar(t`Offers imported successfully`, {
                    variant: 'success',
                    anchorOrigin: {
                        horizontal: 'center',
                        vertical: 'top',
                    },
                });
                history.goBack();
            }}
            onImportBatch={async (batch) => {
                const requestBody: ExtractRequestBody<'POST /offers/import'> = batch.map((row) => {
                    return {
                        part: {
                            internal_part_number: row.data.ipn,
                        },
                        availability: {
                            available_stock: row.data.available_stock,
                            total_stock: row.data.total_stock,
                        },
                        notes: row.data.notes,
                        packaging: row.data.packaging,
                        supplier: {
                            type: 'Internal',
                        },
                        prices: [
                            {
                                unit_price: {
                                    currency: Currency.EUR,
                                    amount: '0',
                                },
                            },
                        ],
                    };
                });

                return importComponents({
                    requestBody,
                })
                    .then((results) => {
                        return batch.map((batchItem) => {
                            const resultItem = results.find(
                                (resultItem) => batchItem.data.ipn === resultItem.part?.internal_part_number,
                            );

                            if (!resultItem) {
                                return {
                                    success: false as const,
                                    message: t`Unknown error`,
                                };
                            }

                            if (resultItem.status > 299) {
                                return {
                                    success: false as const,
                                    message: resultItem.description ?? t`Unknown error`,
                                };
                            }

                            return {
                                success: true as const,
                            };
                        });
                    })
                    .catch((error) => {
                        return batch.map((row) => {
                            return {
                                success: false as const,
                                message: formatError(error),
                            };
                        });
                    });
            }}
            config={{
                fields: [
                    {
                        id: 'ipn' as const,
                        columnIndices: [],
                        required: true,
                        parser: { type: 'ipn', options: { ipns: [] } },
                        label: t`IPN`,
                        description: t`The internal part number`,
                    },
                    {
                        id: 'available_stock' as const,
                        columnIndices: [],
                        required: false,
                        defaultValue: { id: 0, label: `0`, description: '' },
                        parser: { type: 'number', options: { min: 0 } },
                        label: t`Available stock`,
                        description: t`How much stock is available to use`,
                    },
                    {
                        id: 'total_stock' as const,
                        columnIndices: [],
                        required: false,
                        defaultValue: { id: null, label: t`Unknown`, description: '' },
                        parser: { type: 'number', options: { min: 0 } },
                        label: t`Total stock`,
                        description: t`The total amount of stock. Some of it might be reserved or not available for use`,
                    },
                    {
                        id: 'packaging' as const,
                        columnIndices: [],
                        required: false,
                        parser: {
                            type: 'packaging',
                            options: {},
                        },
                        label: t`Packaging`,
                        defaultValue: { id: null, label: t`Unknown`, description: '' },
                        description: t`The packaging the offer comes in`,
                    },
                    {
                        id: 'notes' as const,
                        columnIndices: [],
                        required: false,
                        parser: { type: 'notes', options: {} },
                        label: t`Notes`,
                        defaultValue: { id: '', label: '-', description: '' },
                        description: t`Any additional information you want to add`,
                    },
                ],
            }}
        />
    );
}
