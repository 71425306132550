/* eslint-disable camelcase */
import * as r from 'runtypes';
import { runtypeFromEnum } from '../../utils/typingUtils';
import { ParentsDTORuntype } from '../backendTypes';
import { HistoryRecordResponseRuntype } from '../changeHistory';
import { endpoint } from '../http/endpoint';
import { CustomPartOfferResultResponseRuntype, PCBV2Runtype } from '../pcb';
import { AssemblyStateRuntype } from '../rfq';
import { RfQContextQueryParamsRuntype } from '../rfqContext';
import {
    AssemblyDTORuntype,
    AssemblyDataDTORuntype,
    AssemblyFormRequest,
    AssemblyImportRuntype,
    AssemblyMonitoringRuntype,
    AssemblyOverviewFilterRequestRuntype,
    AssemblyOverviewResponseRuntype,
    AssemblyResourcesRuntype,
    AssemblyResponseRuntype,
    DescendantsDTONewRuntype,
    DescendantsSummaryDTORuntype,
    GuidanceTextRuntype,
    UploadedFileType,
} from './assemblyBackendTypes';

export const assemblyEndpoints = {
    'POST /assemblies/paginated': endpoint({
        description:
            'Returns a paginated list of assemblies. The assemblies are listed in descending order of creation date',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: r.Record({
            page_size: r.Number,
            page_params: r.Unknown.Or(r.Undefined),
            filters: r.Array(AssemblyOverviewFilterRequestRuntype),
        }),
        responseBody: AssemblyOverviewResponseRuntype,
    }),
    'GET /assemblies/:assemblyId/data': endpoint({
        description: 'Returns assembly data (cheap endpoint if you dont need anything else)',
        pathParams: r.Record({ assemblyId: r.String }),
        queryParams: r.Undefined,
        requestBody: r.Undefined,
        responseBody: AssemblyDataDTORuntype,
    }),
    'GET /assemblies/:assemblyId/assembly-descendants': endpoint({
        description:
            'Returns assembly descendants of a given assembly. (cheap endpoint if you only need the assembly data)',
        pathParams: r.Record({ assemblyId: r.String }),
        queryParams: r.Undefined,
        requestBody: r.Undefined,
        responseBody: r.Record({
            items: r.Array(AssemblyDataDTORuntype),
        }),
    }),

    'GET /assemblies/:assemblyId/descendants': endpoint({
        description: 'Returns all the descendants of the given assembly (e.g. the design items and sub-assemblies)',
        pathParams: r.Record({ assemblyId: r.String }),
        queryParams: r.Undefined,
        requestBody: r.Undefined,
        responseBody: r.Record({ data: DescendantsDTONewRuntype }),
    }),

    'POST /assemblies/bulk': endpoint({
        description:
            'Fetches a list of assemblies given their ID. The with_aggregations flag determines whether just the assembly data is returned, or whether its returned together with aggregated data from its BOM Items and subassemblies',
        pathParams: r.Undefined,
        queryParams: r.Record({
            with_aggregations: r.Boolean.optional(),
        }),
        requestBody: r.Record({ ids: r.Array(r.String) }),
        responseBody: r.Record({ items: r.Array(AssemblyResponseRuntype) }),
    }),

    'GET /assemblies/:assemblyId/descendants-summary': endpoint({
        description: 'Returns a summary of the descendants of a given assembly',
        pathParams: r.Record({ assemblyId: r.String }),
        queryParams: r.Record({ viewed_in_assembly_overview: r.Boolean }),
        requestBody: r.Undefined,
        responseBody: r.Record({ data: DescendantsSummaryDTORuntype }),
    }),

    'GET /assemblies/:id/descendants-approved-off-the-shelf-parts-ids-without-emission-data': endpoint({
        description: 'Returns array of OTS part IDs, for which we request emission data',
        pathParams: r.Record({ id: r.String }),
        queryParams: RfQContextQueryParamsRuntype,
        requestBody: r.Undefined,
        responseBody: r.Record({
            ids: r.Array(r.String),
        }),
    }),

    'POST /assemblies/:id/pcb': endpoint({
        description: 'Creates PCB and links it to an assembly.',
        pathParams: r.Record({ id: r.String }),
        queryParams: r.Record({ without_files: r.Boolean.optional() }),
        requestBody: r.Undefined,
        responseBody: PCBV2Runtype,
    }),

    'POST /assemblies/:id/pcb/:pcbId/offer-state': endpoint({
        description: 'pcb price radar',
        pathParams: r.Record({ id: r.String, pcbId: r.String }),
        queryParams: r.Undefined,
        requestBody: r.Undefined,
        responseBody: CustomPartOfferResultResponseRuntype,
    }),

    'GET /assemblies/:assemblyId/resources': endpoint({
        description: 'Returns the resources (both CAD and additional files) of a given assembly',
        pathParams: r.Record({ assemblyId: r.String }),
        queryParams: r.Undefined,
        requestBody: r.Undefined,
        responseBody: AssemblyResourcesRuntype,
    }),

    'GET /assemblies/:assemblyId/upload/cad': endpoint({
        description: 'Returns an Azure url to upload the CAD file given an assembly',
        pathParams: r.Record({ assemblyId: r.String }),
        queryParams: r.Undefined,
        requestBody: r.Undefined,
        responseBody: r.Record({
            data: r.Record({
                url: r.String,
            }),
        }),
        invalidates: ['GET /assemblies/:assemblyId/resources', 'POST /rfqs/:rfqId/customer-portal'],
    }),

    'GET /assemblies/:assemblyId/upload/other': endpoint({
        description: 'Returns an Azure url to upload the additional files given an assembly',
        pathParams: r.Record({ assemblyId: r.String }),
        queryParams: r.Undefined,
        requestBody: r.Undefined,
        responseBody: r.Record({
            data: r.Record({
                url: r.String,
            }),
        }),
        invalidates: ['GET /assemblies/:assemblyId/resources', 'POST /rfqs/:rfqId/customer-portal'],
    }),

    'DELETE /assemblies/:assemblyId/delete/cad': endpoint({
        description: 'Deletes a CAD file given its file name and assembly',
        pathParams: r.Record({ assemblyId: r.String }),
        // eslint-disable-next-line camelcase
        queryParams: r.Record({ blob_name: r.String }),
        requestBody: r.Undefined,
        responseBody: r.String,
    }),

    'DELETE /assemblies/:assemblyId/delete/other': endpoint({
        description: 'Deletes an additional file given its file name and assembly',
        pathParams: r.Record({ assemblyId: r.String }),
        // eslint-disable-next-line camelcase
        queryParams: r.Record({ blob_name: r.String }),
        requestBody: r.Undefined,
        responseBody: r.String,
    }),
    'POST /assemblies': endpoint({
        description: 'Creates an assembly',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: AssemblyFormRequest,
        responseBody: r.Record({ data: AssemblyDTORuntype }),
        invalidates: [
            'GET /rfqs',
            'GET /rfqs/:rfqId',
            'GET /assemblies/:assemblyId/descendants',
            'GET /assemblies/:assemblyId/descendants-summary',
            'GET /assemblies/:assemblyId/resources',
            'POST /design-items/bulk',
            'GET /assemblies/:assemblyId/parents',
            'GET /assemblies/:assemblyId/aggregate-quantity',
        ],
    }),

    'GET /assemblies/guidance-text': endpoint({
        description: 'Gets the guidance text displayed in the files upload for all assemblies',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: r.Undefined,
        responseBody: GuidanceTextRuntype,
    }),

    'PATCH /assemblies/guidance-text': endpoint({
        description: 'Updates the guidance text displayed in the files upload for all assemblies',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: GuidanceTextRuntype,
        responseBody: GuidanceTextRuntype,
    }),

    'DELETE /assemblies/:id/pcb': endpoint({
        description: 'Delete PCB for given assembly id.',
        pathParams: r.Record({ id: r.String }),
        queryParams: r.Undefined,
        requestBody: r.Undefined,
        responseBody: r.Unknown,
        removes: [
            'GET /ems/pcb/v2/pcbs/:pcbId',
            'GET /assemblies/:assemblyId/descendants',
            'POST /assemblies/:id/pcb/:pcbId/offer-state',
        ],
    }),
    'DELETE /assemblies/:id/bom-design-items': endpoint({
        description: 'Delete BOM design items in assembly',
        pathParams: r.Record({ id: r.String }),
        queryParams: r.Undefined,
        requestBody: r.Undefined,
        responseBody: r.Record({
            deleted: r.Number,
        }),
        invalidates: [
            'GET /assemblies/:assemblyId/descendants',
            'GET /assemblies/:assemblyId/descendants-summary',
            'GET /assemblies/:id/history',
            'POST /sourcing-scenarios/bulk',
            'POST /sourcing-scenarios/sourcing-full/bulk',
            'GET /assemblies/:id/bom-file/resources',
            'POST /design-items/part-alternatives/bulk',
            'POST /design-items/many',
            'GET /assemblies/:assemblyId/state',
            'POST /design-items/history',
        ],
    }),

    'POST /assemblies/:id/duplicate': endpoint({
        // Its supposed to duplicate (top-level) assembly and its subassemblies and other sub entities
        // when we move away from "Assembly belongs-to Rfq" design
        description: 'Duplicates rfq',
        pathParams: r.Record({ id: r.String }),
        queryParams: r.Undefined,
        // eslint-disable-next-line camelcase
        requestBody: r.Record({ new_rfq_name: r.String.optional() }),
        responseBody: r.Record({
            rfq_id: r.String,
            assemblies: r.Array(r.String),
            warning: r.Literal('CouldNotDuplicateAllPcbs').optional(),
        }),
    }),

    'GET /assemblies/:id/bom-file/resources': endpoint({
        description: 'Returns a string of resources for a BOM file of a given assembly',
        pathParams: r.Record({ id: r.String }),
        queryParams: r.Undefined,
        requestBody: r.Undefined,
        responseBody: r.Record({
            items: r.Array(r.String),
        }),
    }),
    'GET /assemblies/:id/history': endpoint({
        description: 'Returns a list of assembly history events',
        pathParams: r.Record({ id: r.String }),
        queryParams: r.Record({
            include_design_items: r.Boolean,
        }),
        requestBody: r.Undefined,
        responseBody: r.Array(HistoryRecordResponseRuntype),
    }),
    'POST /assemblies/:id/history/file-uploaded': endpoint({
        description: 'Creates an event on the assembly when a file is uploaded',
        pathParams: r.Record({ id: r.String }),
        queryParams: r.Undefined,
        requestBody: r.Record({
            file_type: runtypeFromEnum(UploadedFileType),
            file_name: r.String,
        }),
        responseBody: r.Unknown,
    }),
    'PATCH /assemblies/:assemblyId': endpoint({
        description: 'Updates an assembly',
        pathParams: r.Record({ assemblyId: r.String }),
        queryParams: r.Undefined,
        requestBody: AssemblyFormRequest,
        responseBody: r.Record({
            data: AssemblyDTORuntype,
        }),
        invalidates: [
            'GET /rfqs',
            'GET /rfqs/:rfqId',
            'GET /assemblies/:assemblyId/descendants',
            'GET /assemblies/:assemblyId/descendants-summary',
            'GET /assemblies/:assemblyId/resources',
            'POST /design-items/bulk',
            'GET /assemblies/:assemblyId/parents',
            'GET /assemblies/:assemblyId/aggregate-quantity',
        ],
    }),

    'GET /assemblies/:assemblyId/monitoring': endpoint({
        description: 'Returns the monitoring data of a given assembly',
        pathParams: r.Record({ assemblyId: r.String }),
        queryParams: r.Undefined,
        requestBody: r.Undefined,
        responseBody: AssemblyMonitoringRuntype,
    }),

    'POST /assemblies/:assemblyId/monitoring': endpoint({
        description: 'Updates the monitoring data of a given assembly',
        pathParams: r.Record({ assemblyId: r.String }),
        queryParams: r.Undefined,
        requestBody: r.Record({
            frequency: r.Union(r.Literal('Daily'), r.Literal('Weekly'), r.Literal('Monthly'), r.Literal('Inactive')),
            interests: r.Record({
                lifecycle: r.Boolean,
                compliance: r.Boolean,
            }),
            users: r.Array(r.String),
        }),
        responseBody: AssemblyMonitoringRuntype,
    }),

    'POST /assemblies/:assemblyId/monitoring/test': endpoint({
        description: 'Sends out a test assembly parts changes email to the current user',
        pathParams: r.Record({ assemblyId: r.String }),
        queryParams: r.Undefined,
        requestBody: r.Undefined,
        responseBody: r.Null,
    }),

    'GET /assemblies/:assemblyId/tree': endpoint({
        description: 'Returns entire BOM tree of assembly identifiers, which the assembly is part of',
        pathParams: r.Record({ assemblyId: r.String }),
        queryParams: r.Undefined,
        requestBody: r.Undefined,
        responseBody: r.Record({
            items: r.Array(r.String),
        }),
    }),
    'GET /assemblies/:assemblyId/parents': endpoint({
        description: 'Returns all parent assemblies of a given assembly',
        pathParams: r.Record({ assemblyId: r.String }),
        queryParams: r.Undefined,
        requestBody: r.Undefined,
        responseBody: ParentsDTORuntype,
    }),
    'DELETE /assemblies/:assemblyId': endpoint({
        description: 'Deletes an assembly',
        pathParams: r.Record({ assemblyId: r.String }),
        queryParams: r.Undefined,
        requestBody: r.Undefined,
        responseBody: r.Record({
            deleted: r.Number,
        }),
    }),

    'GET /assemblies/:assemblyId/state': endpoint({
        description: 'Get the state of an assembly as an EMS',
        pathParams: r.Record({ assemblyId: r.String }),
        queryParams: r.Undefined,
        requestBody: r.Undefined,
        responseBody: AssemblyStateRuntype,
    }),

    'GET /assemblies/:assemblyId/aggregate-quantity': endpoint({
        description: 'Get the total quantity of an Assembly inside a Sourcing Scenario',
        pathParams: r.Record({ assemblyId: r.String }),
        queryParams: r.Record({ rfq_id: r.String }),
        requestBody: r.Undefined,
        responseBody: r.Record({
            // string is sourcingScenarioId, number is the quantity
            quantities: r.Dictionary(r.Number, r.String),
        }),
    }),

    'POST /assemblies/import': endpoint({
        description: 'Imports a list of assemblies',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: AssemblyImportRuntype,
        responseBody: r.Unknown,
    }),
};
