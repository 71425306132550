import { t } from '@lingui/macro';
import { colorSystem, PrimaryButton, SecondaryIconButton, Text } from '@luminovo/design-system';
import { Delete, DoneAll, Undo, ZoomIn, ZoomOut, ZoomOutMap } from '@mui/icons-material';
import { Tooltip } from '@mui/material';
import { usePdfViewerState } from '../hooks/usePdfViewerState';
import { formatAttribute } from '../model/RegionNetwork/formatAttribute';

export function PdfViewerActions({
    setScale,
    style,
    actions,
}: {
    setScale: React.Dispatch<React.SetStateAction<number>>;
    style?: React.CSSProperties;
    actions?: React.ReactNode;
}): JSX.Element {
    return (
        <>
            <div
                style={{
                    position: 'fixed',
                    display: 'flex',
                    flexDirection: 'row',
                    gap: 8,
                    top: 8,
                    left: 8,
                    zIndex: 2,
                    ...style,
                }}
            >
                <ButtonZoom setScale={setScale} />
                <ButtonUndo />
                <ButtonSuggestions />
                <InspectLabel />
                {actions}
            </div>
        </>
    );
}

function ButtonZoom({ setScale }: { setScale: React.Dispatch<React.SetStateAction<number>> }) {
    return (
        <>
            <Tooltip title={t`Zoom out`}>
                <SecondaryIconButton size="medium" onClick={() => setScale((scale) => scale - 0.1)}>
                    <ZoomOut fontSize="inherit" />
                </SecondaryIconButton>
            </Tooltip>
            <Tooltip title={t`Zoom 100%`}>
                <SecondaryIconButton size="medium" onClick={() => setScale(1)}>
                    <ZoomOutMap fontSize="inherit" />
                </SecondaryIconButton>
            </Tooltip>
            <Tooltip title={t`Zoom in`}>
                <SecondaryIconButton size="medium" onClick={() => setScale((scale) => scale + 0.1)}>
                    <ZoomIn fontSize="inherit" />
                </SecondaryIconButton>
            </Tooltip>
        </>
    );
}

function InspectLabel(): JSX.Element {
    const [state] = usePdfViewerState();

    const { mode } = state;
    if (mode.type !== 'inspect') {
        return <></>;
    }
    const { attribute } = mode;

    return (
        <Text
            style={{
                padding: '4px 8px',
                color: colorSystem.neutral.white,
                background: colorSystem.neutral[9],
                borderRadius: 4,
                display: 'inline-flex',
                alignItems: 'center',
                gap: 16,
                boxShadow: 'var(--shadow-elevation-low)',
            }}
        >
            {formatAttribute(attribute)}
        </Text>
    );
}

function ButtonUndo() {
    const [state, dispatch] = usePdfViewerState();

    if (!state.previousState) {
        return null;
    }
    return (
        <Tooltip title={t`Undo last action`}>
            <SecondaryIconButton size="medium" onClick={() => dispatch({ type: 'historyUndo' })}>
                <Undo fontSize="inherit" />
            </SecondaryIconButton>
        </Tooltip>
    );
}

function ButtonSuggestions() {
    const [state, dispatch] = usePdfViewerState();
    const suggestions = state.regs.findLinks({ type: 'suggestion' });
    if (suggestions.length === 0) {
        return null;
    }

    return (
        <>
            <Tooltip title="Remove suggestions (Esc)">
                <SecondaryIconButton size="medium" onClick={() => dispatch({ type: 'escape' })}>
                    <Delete fontSize="inherit" />
                </SecondaryIconButton>
            </Tooltip>
            <PrimaryButton
                size="medium"
                onClick={() => {
                    dispatch({
                        type: 'approveSuggestions',
                    });
                }}
                startIcon={<DoneAll />}
            >
                Approve {suggestions.length} suggestions
            </PrimaryButton>
        </>
    );
}
