import { t } from '@lingui/macro';
import { colorSystem } from '@luminovo/design-system';
import { Box } from '@mui/material';
import React from 'react';
import {
    Capability,
    STACK_UP_ADVANCED_VALUE_FIELDS,
    STACK_UP_VALUE_FIELDS,
} from '../../../../resources/pcb/pcbFunctions';
import { CollapsibleSection } from '../../components/CollapsibleSection';
import { PcbFormItem } from '../../components/PcbFormItem';
import { StackUpMessageBox } from './StackUpMessageBox';

export type FormCapabilities = {
    basic: Capability[];
    advanced: Capability[];
    fields: Capability[];
};

export const usePcbCapabilitiesFormValues = (
    pcbCapabilities: FormCapabilities,
): {
    stackUpValues: Capability[];
    advancedStackUpValues: Capability[];
} => {
    return React.useMemo(() => {
        const stackUpValues = [];
        const advancedStackUpValues = [];
        for (const capability of pcbCapabilities.fields) {
            if (STACK_UP_VALUE_FIELDS.includes(capability.capabilityName)) {
                stackUpValues.push(capability);
            } else if (STACK_UP_ADVANCED_VALUE_FIELDS.includes(capability.capabilityName)) {
                advancedStackUpValues.push(capability);
            }
        }

        return {
            stackUpValues,
            advancedStackUpValues,
        };
    }, [pcbCapabilities]);
};

export const DefaultStackUpForm = ({
    pcbCapabilities,
    isEditing,
}: {
    pcbCapabilities: FormCapabilities;
    isEditing: boolean;
}) => {
    const { advancedStackUpValues, stackUpValues } = usePcbCapabilitiesFormValues(pcbCapabilities);
    return (
        <>
            <Box
                style={{
                    borderRadius: '8px',
                    backgroundColor: colorSystem.neutral.white,
                }}
            >
                <CollapsibleSection
                    id={'stack-up-fields'}
                    label={t`Parameters`}
                    isOpenByDefault={true}
                    shouldCollapseButtonBeShown={true}
                >
                    <StackUpMessageBox
                        title={t`Default values`}
                        id="default-stack-up-announcement-box"
                        text={t`We set some values by default. Check them before saving the specification.`}
                    />
                    {stackUpValues.map((capability, i) => (
                        <PcbFormItem key={i} capability={capability} disabled={!isEditing} style={{ width: '100%' }} />
                    ))}
                </CollapsibleSection>
            </Box>

            <Box
                style={{
                    borderRadius: '8px',
                    backgroundColor: colorSystem.neutral.white,
                }}
            >
                <CollapsibleSection
                    id={'advanced-stack-up-fields'}
                    label={t`Advanced options`}
                    isOpenByDefault={true}
                    shouldCollapseButtonBeShown={true}
                >
                    {advancedStackUpValues.map((capability, i) => (
                        <PcbFormItem key={i} capability={capability} disabled={!isEditing} style={{ width: '100%' }} />
                    ))}
                </CollapsibleSection>
            </Box>
        </>
    );
};
