import { plural, t } from '@lingui/macro';
import { compareByString, isPresent } from '@luminovo/commons';
import {
    DriverStatusDTO,
    DriverStatusDesignItem,
    MissingOfferDriverStatusDTO,
    SourcingScenarioDTO,
    WarningDriverStatusDTO,
} from '@luminovo/http-client';
import { useSourcingScenarios } from '../../../resources/sourcingScenario/sourcingScenarioHandlers';
import { assertUnreachable } from '../../../utils/typingUtils';
import { getUniqueDesignItems } from './manufacturingUtils';

function extractMissingOfferInformation(driverStatus: MissingOfferDriverStatusDTO): {
    designItems: DriverStatusDesignItem[];
    sourcingScenarioIds: string[];
} {
    const designItemsAndSourcingInfo = driverStatus.data;
    const designItems = designItemsAndSourcingInfo.map((data) => data.design_item).filter(isPresent);
    const sourcingIds = designItemsAndSourcingInfo.map((data) => data.sourcing_scenario_id);

    return {
        designItems: getUniqueDesignItems(designItems),
        sourcingScenarioIds: [...new Set(sourcingIds)],
    };
}

function extractDesignItems(driverStatus: WarningDriverStatusDTO): DriverStatusDesignItem[] {
    switch (driverStatus.data.type) {
        case 'MissingDesignItemInformation':
            const allDesignItems: DriverStatusDesignItem[] = driverStatus.data.data;
            return getUniqueDesignItems(allDesignItems);
        case 'MissingPnpInformation':
            const missingPnpItems: DriverStatusDesignItem[] = driverStatus.data.data;
            return getUniqueDesignItems(missingPnpItems);
        case 'MissingOffer':
            const { designItems } = extractMissingOfferInformation(driverStatus.data);
            return designItems;

        case 'AssemblyNotPCBA':
            //FIXME: check if no design items are associated with an assembly.
            return [];
        default:
            assertUnreachable(driverStatus.data);
    }
}

//https://stackoverflow.com/a/40329084
const joinWithAndAtEnd = (stringArray: string[]) =>
    stringArray.reduce((p, d, i) => p + (i === stringArray.length - 1 ? ` ${t`and`} ` : ', ') + d);

export const getDesignItemsWarningString = (driverStatus: WarningDriverStatusDTO) => {
    const designators: string[] = extractDesignItems(driverStatus)
        .map((designItem) => designItem.designator)
        .filter(isPresent);

    if (designators.length === 0) {
        return t`Some information is missing to calculate the driver count`;
    } else {
        const designatorString = joinWithAndAtEnd(designators.sort(compareByString));
        const designItemString = plural(designators.length, {
            one: `design item`,
            other: `design items`,
        });
        return t`Driver count incomplete. Please check ${designItemString} ${designatorString} for missing information.`;
    }
};

export const useSourcingScenariosForDriver = (
    driverStatus: DriverStatusDTO,
): { data: SourcingScenarioDTO[] | undefined; isLoading: boolean } => {
    const sourcingScenarioIds =
        driverStatus.type === 'Warning' && driverStatus.data.type === 'MissingOffer'
            ? extractMissingOfferInformation(driverStatus.data).sourcingScenarioIds
            : [];

    return useSourcingScenarios(sourcingScenarioIds);
};

export const getSourcingScenarioInformation = (
    driverStatus: DriverStatusDTO,
    sourcingScenarioDTOs: SourcingScenarioDTO[],
): {
    formattedSourcingScenarioNames: string;
    sourcingScenarioIds: string[];
} => {
    const sourcingScenarioIds =
        driverStatus.type === 'Warning' && driverStatus.data.type === 'MissingOffer'
            ? extractMissingOfferInformation(driverStatus.data).sourcingScenarioIds
            : [];

    const sourcingScenarioNames = sourcingScenarioDTOs
        .map((sourcingScenario) => sourcingScenario.name)
        .filter(isPresent);

    return {
        formattedSourcingScenarioNames:
            sourcingScenarioNames.length < 1 ? '...' : joinWithAndAtEnd(sourcingScenarioNames),
        sourcingScenarioIds,
    };
};
