import { t } from '@lingui/macro';
import { formatLongDateTime, isPresent } from '@luminovo/commons';
import { AvatarIcon, Dropzone, Flexbox, Text, Tooltip, colorSystem } from '@luminovo/design-system';
import { QuoteTrackingDTO } from '@luminovo/http-client';
import { styled } from '@mui/material';
import React, { PropsWithChildren } from 'react';
import ConfirmationDialogBox from '../../../../components/dialogBox/ConfirmationDialogBox';
import { useHttpFileUpload } from '../../../../resources/http/useHttpFileUpload';
import { useHttpMutation } from '../../../../resources/mutation/useHttpMutation';
import { getFileNameFromResourcesString } from '../../../../utils/stringFunctions';
import { useIsQuoteTrackingEditable } from '../../../SupplierPortal/SupplierQuotePage/model/utils';
import { QuoteTrackingMetricSettings, QuoteTrackingSingeMetricCard } from './QuoteTrackingMetricCards';

const HeaderLine: React.FunctionComponent<PropsWithChildren<{ label: string }>> = ({ label, children }) => {
    return (
        <Flexbox flexDirection={'column'}>
            <Text variant="h5" color={colorSystem.neutral[6]}>
                {label}
            </Text>
            <Text
                variant="body"
                color={colorSystem.neutral[9]}
                style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}
            >
                {children}
            </Text>
        </Flexbox>
    );
};

const SentOnLine: React.FunctionComponent<{ quoteTracking: QuoteTrackingDTO }> = ({ quoteTracking }) => {
    return (
        <HeaderLine label={t`Sent on`}>
            {isPresent(quoteTracking.creation_date) ? formatLongDateTime(quoteTracking.creation_date) : '-'}
        </HeaderLine>
    );
};

const SentToLine: React.FunctionComponent<{ quoteTracking: QuoteTrackingDTO }> = ({ quoteTracking }) => {
    if (quoteTracking.supplier_contacts.length === 0) {
        return null;
    }

    return (
        <HeaderLine label={t`Sent to`}>
            <Flexbox gap={'4px'} flexWrap={'wrap'} paddingTop={'4px'}>
                {quoteTracking.supplier_contacts.map((contact) => (
                    <Flexbox gap={'4px'} key={contact.id} alignItems={'center'}>
                        <AvatarIcon
                            size="small"
                            user={{
                                firstName: contact.first_name,
                                lastName: contact.last_name,
                                email: contact.email,
                            }}
                        />
                        <Tooltip title={contact.email}>
                            <Text variant="body-small">
                                {contact.first_name} {contact.last_name}
                            </Text>
                        </Tooltip>
                    </Flexbox>
                ))}
            </Flexbox>
        </HeaderLine>
    );
};

const QuoteNumber: React.FunctionComponent<{ quoteTracking: QuoteTrackingDTO }> = ({ quoteTracking }) => {
    return <HeaderLine label={t`Quote number`}>{quoteTracking.number}</HeaderLine>;
};

const ReceviedDateLine: React.FunctionComponent<{ quoteTracking: QuoteTrackingDTO }> = ({ quoteTracking }) => {
    return (
        <HeaderLine label={t`Received on`}>
            {isPresent(quoteTracking.received_date) ? formatLongDateTime(quoteTracking.received_date) : '-'}
        </HeaderLine>
    );
};

const DropzoneContainer = styled(Flexbox)({
    flexDirection: 'column',
    width: '100%',
    gap: '8px',
});

const DropzoneTitle: React.FunctionComponent<React.PropsWithChildren<{}>> = ({ children }) => {
    return <Text variant="h4">{children}</Text>;
};

export const AdditionalFiles: React.FunctionComponent<{
    quoteTracking: QuoteTrackingDTO;
    isFileUploadRequired?: boolean;
}> = ({ quoteTracking, isFileUploadRequired }) => {
    const isEditable = useIsQuoteTrackingEditable(quoteTracking);

    const { files } = quoteTracking;
    const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] = React.useState<
        { isOpen: false } | { isOpen: true; onConfirm: () => void }
    >({ isOpen: false });

    const { mutateAsync: mutateAsyncUpload, isLoading: isUploading } = useHttpFileUpload(
        'GET /quote-tracking/:id/additional-files/upload-link',
        (response) => response.data.url,
        {
            snackbarMessage: t`Files uploaded successfully`,
        },
    );
    const { mutateAsync: mutateAsyncDelete, isLoading: isDeleting } = useHttpMutation(
        'DELETE /quote-tracking/:id/additional-files',
        {
            snackbarMessage: null,
        },
    );

    return (
        <>
            <Dropzone
                title={t`Additional files`}
                subHeader={
                    isFileUploadRequired === false
                        ? t`You can upload additional files to your quote (e.g. terms and conditions)`
                        : t`Uploading files related to the quote is mandatory. Please upload before proceeding.`
                }
                onDropAccepted={(files) => mutateAsyncUpload({ pathParams: { id: quoteTracking.id }, files })}
                multiple={true}
                disabled={!isEditable}
                isLoading={isUploading}
                accept={null}
                persistentFiles={files.map((file) => ({
                    name: getFileNameFromResourcesString(file.name),
                    onClick: () => window.open(file.url, '_blank'),
                    onDelete: () => {
                        setIsConfirmationDialogOpen({
                            isOpen: true,
                            onConfirm: () =>
                                mutateAsyncDelete({
                                    pathParams: { id: quoteTracking.id },
                                    requestBody: { file_name: file.name },
                                }),
                        });
                    },
                    disabled: isDeleting || !isEditable,
                }))}
                overrides={{
                    Container: DropzoneContainer,
                    Title: DropzoneTitle,
                }}
            />
            <ConfirmationDialogBox
                title={t`Delete file`}
                text={t`This file will be permanently removed. Are you sure you want to proceed?`}
                isDialogOpen={isConfirmationDialogOpen.isOpen}
                onReject={() => setIsConfirmationDialogOpen({ isOpen: false })}
                onConfirm={isConfirmationDialogOpen.isOpen ? isConfirmationDialogOpen.onConfirm : () => {}}
                deleteText={t`Yes, delete`}
            />
        </>
    );
};

export const QuoteTrackingSummary: React.FunctionComponent<{
    quoteTracking: QuoteTrackingDTO;
    metricSettings: QuoteTrackingMetricSettings;
}> = ({ quoteTracking, metricSettings }) => {
    return (
        <Flexbox
            flexDirection={'column'}
            gap={'20px'}
            bgcolor={colorSystem.neutral.white}
            padding={'24px'}
            borderRadius={'12px'}
            height={'fit-content'}
        >
            <QuoteTrackingSingeMetricCard quoteTrackingDTO={quoteTracking} metricSettings={metricSettings} />
            <SentToLine quoteTracking={quoteTracking} />
            <QuoteNumber quoteTracking={quoteTracking} />
            <SentOnLine quoteTracking={quoteTracking} />
            <ReceviedDateLine quoteTracking={quoteTracking} />
            <AdditionalFiles quoteTracking={quoteTracking} />
        </Flexbox>
    );
};
