import { t, Trans } from '@lingui/macro';
import { colorSystem, Flexbox, ReleaseTag, Text, Toolbar } from '@luminovo/design-system';
import { Box, Grid } from '@mui/material';
import { PageLayout } from '../../components/PageLayout';
import { SpinnerWithBackdrop } from '../../components/Spinners';
import { route, UrlParams } from '../../utils/routes';
import { AssemblyOverviewCalculationsCard } from '../Manufacturing/AssemblyOverview/AssemblyOverviewCard';
import { useAssemblyOverviewPage } from '../Manufacturing/AssemblyOverview/useAssemblyOverviewPage';
import { ExportQuotationButton } from './QuotationExport/useExportQuotationDialog';

export const CalculationPage = (props: UrlParams<'/rfqs/:rfqId/calculation'>): JSX.Element => {
    const { rfqId } = props.pathParams;
    const { assemblyOverviews, isLoading } = useAssemblyOverviewPage(rfqId);

    return (
        <PageLayout
            removeHubspotPaddingFix
            header={
                <Toolbar
                    breadcrumbs={[
                        {
                            href: route(`/rfqs/:rfqId/calculation`, {
                                rfqId,
                            }),
                            title: t`Calculation`,
                        },
                    ]}
                >
                    <ReleaseTag label={t`NEW`} color={'violet'} />
                    <ExportQuotationButton rfqId={rfqId} />
                </Toolbar>
            }
            layout={'full-width'}
            style={{ backgroundColor: colorSystem.neutral[1] }}
        >
            <Flexbox gap={16} flexDirection={'column'}>
                <Text variant="h1">
                    <Trans>Assembly overview </Trans>
                </Text>
                {isLoading ? (
                    <Box marginTop={'20px'}>
                        <SpinnerWithBackdrop noBackdrop={true} />
                    </Box>
                ) : (
                    <>
                        <Text>
                            <Trans>Choose the assembly for calculation</Trans>
                        </Text>
                        <Grid container spacing={2}>
                            {assemblyOverviews.map((assembly, i) => (
                                <Grid item key={i}>
                                    <AssemblyOverviewCalculationsCard {...assembly} />
                                </Grid>
                            ))}
                        </Grid>
                    </>
                )}
            </Flexbox>
        </PageLayout>
    );
};
