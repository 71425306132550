import { Box, BoxProps } from '@mui/material';
import { styled } from '@mui/styles';
import * as React from 'react';

export interface FlexboxProps extends BoxProps {
    gap?: string | number;
}

export const Flexbox: React.ComponentType<FlexboxProps> = styled(({ gap, style = {}, ...rest }) => {
    return <Box style={{ gap, ...style }} {...rest} />;
})({
    display: 'flex',
});
