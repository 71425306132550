import { t, Trans } from '@lingui/macro';
import { formatCurrency } from '@luminovo/commons';
import { Flexbox, TertiaryButton } from '@luminovo/design-system';
import { AutomaticDetailsDTO, PackageMountingEnum } from '@luminovo/http-client';
import { packageMountingEnumTranslations, packagingTranslations } from '@luminovo/sourcing-core';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { Chip, Typography } from '@mui/material';
import React, { useState } from 'react';
import { transEnum } from '../../../components/localization/TransEnum';
import { Skeleton } from '../../../components/Spinners';
import { useIsOverflow } from '../../../components/TooltipOnOverflow';
import {
    aggregationTypePublicTranslations,
    aggregationValuePublicTranslations,
    attributeReferencePublicTranslations,
    conditionTranslations,
    enumFilterOperatorsPublicTranslations,
    FilterValueOptionTranslations,
    numberFilterOperatorsPublicTranslations,
} from '../../../resources/driver/driverBackendTypes';
import { usePartPackages } from '../../../resources/part/partHandler';
import { colorSystem } from '../../../themes';
import { assertUnreachable, nthValueInArray } from '../../../utils/typingUtils';
import { convertFilterFormulaDataToFormValues, DriverFilter } from '../DriverCreation';
import { allPackagesPackageValue } from '../shared/allPackagesPackageValue';
const TableTag = ({ text }: { text: string }): JSX.Element => {
    return (
        <Chip
            label={text}
            style={{
                backgroundColor: colorSystem.neutral[1],
                color: colorSystem.primary[8],
                borderRadius: '2px',
                border: `1px solid ${colorSystem.neutral[2]}`,
                padding: `2px 8px`,
                fontWeight: 600,
            }}
        />
    );
};

const NormalText = ({ text }: { text: string }): JSX.Element => {
    return (
        <Typography variant="body1" style={{ wordBreak: 'keep-all', whiteSpace: 'nowrap' }}>
            {text}
        </Typography>
    );
};

const GreyText = ({ text }: { text: string }): JSX.Element => {
    return (
        <Typography
            variant="body1"
            style={{ color: colorSystem.neutral[6], wordBreak: 'keep-all', whiteSpace: 'nowrap' }}
        >
            {text}
        </Typography>
    );
};

const MountingPackageTag = ({ packageValues }: { packageValues: string[] }): JSX.Element => {
    const { data: allPackages = [] } = usePartPackages();

    if (nthValueInArray(packageValues, 0) === allPackagesPackageValue.name) return <></>;
    const getPrefix = (index: number) => {
        if (packageValues.length < 1 || index === packageValues.length - 1) {
            return '';
        } else return ',';
    };
    return (
        <>
            <GreyText text={transEnum('And', conditionTranslations).toUpperCase()} />
            <TableTag text={t`Package`} />
            <NormalText text={t`is`} />
            {packageValues.map((packageValue, index) => {
                const foundPackage = allPackages.find((p) => p.name === packageValue);

                return (
                    <React.Fragment key={index}>
                        <TableTag
                            text={
                                foundPackage?.name
                                    ? foundPackage.name
                                    : nthValueInArray(foundPackage?.aliases ?? [], 0) ?? ''
                            }
                        />
                        <NormalText text={getPrefix(index)} />
                    </React.Fragment>
                );
            })}
        </>
    );
};

const FilterValueTag = ({ data }: { data: DriverFilter }): JSX.Element => {
    const { type, value } = data.filter;

    switch (type) {
        case 'CustomPartType':
        case 'OfferType':
        case 'PnpSide':
            return <TableTag text={transEnum(value, FilterValueOptionTranslations)} />;
        case 'MaterialPrice':
            return <TableTag text={formatCurrency(value.amount, value.currency)} />;
        case 'PartNumberOfPins':
            return <TableTag text={value.toString()} />;
        case 'Packaging':
            return <TableTag text={transEnum(value, packagingTranslations)} />;
        case 'Package':
            const mounting = value.mounting;
            switch (mounting) {
                case PackageMountingEnum.PressFit:
                    return <TableTag text={transEnum(mounting, packageMountingEnumTranslations)} />;
                case PackageMountingEnum.SMT:
                case PackageMountingEnum.THT:
                case PackageMountingEnum.Other:
                    const packages = 'packages' in value ? value.packages : [];
                    return (
                        <>
                            <TableTag text={transEnum(mounting, packageMountingEnumTranslations)} />
                            {packages.length !== 0 && <MountingPackageTag packageValues={packages} />}
                        </>
                    );
                default:
                    assertUnreachable(mounting);
            }
        /* eslint-disable-next-line no-fallthrough */
        default:
            assertUnreachable(type);
    }
};

const FilterText = ({ driverFilters }: { driverFilters: DriverFilter[] }): JSX.Element => {
    return (
        <>
            {driverFilters.map((formula, i) => {
                return (
                    <React.Fragment key={i}>
                        {i !== 0 && (
                            <GreyText text={transEnum(formula.joinWithPrevious, conditionTranslations).toUpperCase()} />
                        )}
                        <TableTag text={transEnum(formula.filter.type, attributeReferencePublicTranslations)} />
                        <NormalText
                            text={transEnum(formula.filter.operator.value, {
                                ...enumFilterOperatorsPublicTranslations,
                                ...numberFilterOperatorsPublicTranslations,
                            }).toLowerCase()}
                        />
                        <FilterValueTag data={formula} />
                    </React.Fragment>
                );
            })}
        </>
    );
};

export const CalculationTextWrapper = ({
    automaticDetails,
}: {
    automaticDetails: AutomaticDetailsDTO | undefined;
}): JSX.Element => {
    const packages = usePartPackages();

    if (packages.isLoading) {
        return <Skeleton />;
    } else if (automaticDetails === undefined) {
        return <></>;
    } else {
        const data: DriverFilter[] = convertFilterFormulaDataToFormValues(automaticDetails.filter_formula);
        // this should be called after the skeleton otherwise the packages are not found.

        //key required to ensure that when the data changes, the calculation wrapper is set as collapsed.
        return <CalculationText key={JSON.stringify(data)} automaticDetails={automaticDetails} driverFilters={data} />;
    }
};
export const CalculationText = ({
    automaticDetails,
    driverFilters,
}: {
    automaticDetails: AutomaticDetailsDTO;
    driverFilters: DriverFilter[];
}): JSX.Element => {
    const [isCellCollapsed, setIsCellCollapsed] = useState(true);

    const ref = React.useRef<HTMLDivElement>(null);
    const isOverflow = useIsOverflow(ref);

    return (
        <Flexbox>
            {/* The following would be better if it used the wonderful Flexbox component. However, this does not have a ref associated
            with it with material ui version 4. This can be fixed when we upgrade to v5. https://github.com/mui-org/material-ui/issues/17010#issuecomment-724187064
            */}
            <div
                style={{
                    display: 'flex',
                    gap: '8px',
                    flexWrap: isCellCollapsed ? 'nowrap' : 'wrap',
                    overflow: isCellCollapsed ? 'hidden' : 'visible',
                }}
                ref={ref}
            >
                <NormalText text={transEnum(automaticDetails.aggregation.type, aggregationTypePublicTranslations)} />
                <TableTag text={transEnum(automaticDetails.aggregation.value, aggregationValuePublicTranslations)} />
                {automaticDetails.filter_formula.length > 0 && <NormalText text={t`Where`} />}
                <FilterText driverFilters={driverFilters} />
            </div>
            {isOverflow && (
                <TertiaryButton
                    style={{
                        maxHeight: '40px',
                        marginLeft: '16px',
                        paddingTop: '0px',
                        paddingBottom: '0px',
                        marginTop: '-4px',
                        minWidth: 'inherit',
                    }}
                    onClick={() => setIsCellCollapsed((s) => !s)}
                >
                    {isCellCollapsed && (
                        <Flexbox>
                            <ExpandMore />
                            <Typography>
                                <Trans>Expand</Trans>
                            </Typography>
                        </Flexbox>
                    )}
                </TertiaryButton>
            )}
            {!isCellCollapsed && (
                <TertiaryButton
                    style={{
                        maxHeight: '40px',
                        marginLeft: '16px',
                        paddingTop: '0px',
                        paddingBottom: '0px',
                        marginTop: '-4px',
                        minWidth: 'inherit',
                    }}
                    onClick={() => setIsCellCollapsed((s) => !s)}
                >
                    <Flexbox>
                        <ExpandLess />
                        <Typography>
                            <Trans>Collapse</Trans>
                        </Typography>
                    </Flexbox>
                </TertiaryButton>
            )}
        </Flexbox>
    );
};
