import { getLocale } from '../i18n';
import { isPresent } from '../typingUtils';

const enLongDateFormatter = new Intl.DateTimeFormat('en-GB', { month: 'short', year: 'numeric', day: 'numeric' });
const deLongDateFormatter = new Intl.DateTimeFormat('de', { month: 'short', year: 'numeric', day: 'numeric' });
const esLongDateFormatter = new Intl.DateTimeFormat('es', { month: 'short', year: 'numeric', day: 'numeric' });
const frLongDateFormatter = new Intl.DateTimeFormat('fr', { month: 'short', year: 'numeric', day: 'numeric' });
function getLongDateFormatter(): Intl.DateTimeFormat {
    switch (getLocale()) {
        case 'en':
            return enLongDateFormatter;
        case 'de':
            return deLongDateFormatter;
        case 'es':
            return esLongDateFormatter;
        case 'fr':
            return frLongDateFormatter;
    }
}

/**
 * Examples:
 * - en: 28 May 2021
 * - de: 28. Mai 2021
 */
export const formatToLongDate = (date: string | Date | undefined | null, options?: { ifAbsent?: string }): string => {
    if (!isPresent(date)) {
        return options?.ifAbsent ?? '-';
    }

    const d = date instanceof Date ? date : new Date(date);
    if (isNaN(d.getTime())) {
        return options?.ifAbsent ?? '-';
    }

    return getLongDateFormatter().format(d);
};
