import { assertPresent } from '@luminovo/commons';
import React from 'react';
import { Capability, PcbCapabilitiesType } from '../../../../../../resources/pcb/utils/usePcbCapabilities';
import { useExtractedPcbSpecification } from './useExtractedPcbSpecification';

export const useExtractedPcbCapabilities = ({
    pcbId,
    pcbCapabilities,
}: {
    pcbId: string;
    pcbCapabilities: PcbCapabilitiesType;
}) => {
    const { data: converted } = useExtractedPcbSpecification({ pcbId });

    const extractedCapability = React.useMemo(() => {
        return converted !== undefined ? converted.extracted : {};
    }, [converted]);
    const extractedFile = converted?.file;

    const result = React.useMemo(() => {
        const extractedBasicCapabilities: Capability[] = pcbCapabilities.basic
            .map((c): Capability => {
                const ec = extractedCapability[c.capabilityName];

                return {
                    ...c,
                    //@ts-ignore
                    extractedFromPdf:
                        ec === undefined
                            ? undefined
                            : {
                                  value: ec.value,
                                  file: assertPresent(extractedFile),
                                  regions: ec.regions,
                              },
                };
            })
            .filter((c) => c.extractedFromPdf !== undefined);

        const extractedAdvancedCapabilities: Capability[] = pcbCapabilities.advanced
            .map((c): Capability => {
                const ec = extractedCapability[c.capabilityName];

                return {
                    ...c,
                    //@ts-ignore
                    extractedFromPdf:
                        ec === undefined
                            ? undefined
                            : {
                                  value: ec.value,
                                  file: assertPresent(extractedFile),
                                  regions: ec.regions,
                              },
                };
            })
            .filter((c) => c.extractedFromPdf !== undefined);

        return {
            basic: extractedBasicCapabilities,
            advanced: extractedAdvancedCapabilities,
            fields: [...extractedBasicCapabilities, ...extractedAdvancedCapabilities],
        };
    }, [extractedCapability, extractedFile, pcbCapabilities.advanced, pcbCapabilities.basic]);

    return result;
};
