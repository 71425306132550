import { t, Trans } from '@lingui/macro';
import { Permission } from '@luminovo/auth';
import { colorSystem, Column, Row, RowCheckbox, SecondaryIconButton } from '@luminovo/design-system';
import { GenericFullPart, IpnWithMatchesFullPart, OtsFullPart } from '@luminovo/http-client';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import RemoveRoundedIcon from '@mui/icons-material/RemoveRounded';
import { TableCell, Tooltip } from '@mui/material';
import { useCallback } from 'react';
import { PartSuggestionAvailabilityView } from '../../../../components/PartAvailabilityView';
import {
    RenderDescription,
    RenderManufacturer,
    RenderMpn,
    RenderPackage,
    StickyTableCell,
} from '../../../../components/partColumns';
import { RenderDatasheet } from '../../../../components/partColumns/RenderDatasheet';
import {
    StickyLeftHeaderTableCell,
    StickyRightHeaderTableCell,
} from '../../../../components/partColumns/StickyHeadTableCell';
import { PartComplianceView } from '../../../../components/PartComplianceView';
import { PartLifecycleView } from '../../../../components/PartLifecycleView';
import { BomItem } from '../../../../resources/designItem/bomItemFrontendTypes';
import { SectionOfScreen } from '../../../../resources/part/partFrontendTypes';
import { designatorQtyMismatchTooltip } from '../AddParts/hasDesignatorOrQtyMismatch';
import { ipnLinkingDialog, useIpnLinkingDialog } from '../LinkPartsDialog/useIpnLinkingDialog';
import { OtsPartSearchTableContext, OtsPartSearchTableData } from './otsPartSearchTypes';

export const columnSelection: Column<OtsPartSearchTableData, OtsPartSearchTableContext> = {
    id: `ots-search-selection`,
    label: '',
    overrides: {
        HeaderTableCell: StickyLeftHeaderTableCell,
    },
    render: (data, sharedContext) => {
        const disabledTooltip =
            sharedContext.existingPartIds && sharedContext.existingPartIds.includes(data.data.part.id)
                ? t`Part already added`
                : '';
        return (
            <RowCheckbox
                data={data}
                maxSelectableRows={3}
                isDisabled={
                    sharedContext.existingPartIds ? sharedContext.existingPartIds.includes(data.data.part.id) : false
                }
                disabledPopupText={disabledTooltip}
                style={{
                    position: 'sticky',
                    left: 0,
                    background: colorSystem.neutral.white,
                    boxShadow: `inset -1px 0px 0px 0px ${colorSystem.neutral[2]}`,
                }}
            />
        );
    },
};

export const columnMpn = {
    id: `ots-search-mpn`,
    label: <Trans>MPN</Trans>,
    render: ({ data }: Row<OtsPartSearchTableData>): JSX.Element => {
        return <RenderMpn part={data.part} candidateMpns={data.mpnTerms} />;
    },
};

export const columnManufacturer = {
    id: `ots-search-manufacturer`,
    label: <Trans>Manufacturer</Trans>,
    render: ({ data }: Row<OtsPartSearchTableData>): JSX.Element => {
        return <RenderManufacturer part={data.part} />;
    },
};

export const columnDescription = {
    id: `ots-search-description`,
    label: <Trans>Description</Trans>,
    render: ({ data }: Row<OtsPartSearchTableData>): JSX.Element => {
        return <RenderDescription part={data.part} />;
    },
};

export const columnPackage = {
    id: `ots-search-package`,
    label: <Trans>Package</Trans>,
    render: ({ data }: Row<OtsPartSearchTableData>): JSX.Element => {
        return <RenderPackage part={data.part} />;
    },
};

export const columnDatasheet = {
    id: `ots-search-datasheet`,
    label: '',
    render: ({ data }: Row<OtsPartSearchTableData>): JSX.Element => {
        return <RenderDatasheet part={data.part} />;
    },
};

export const columnAvailability = {
    id: `ots-search-availability`,
    label: <Trans>Availability</Trans>,
    render: (
        { data }: Row<OtsPartSearchTableData>,
        { bomItem, rfqId, assemblyId }: OtsPartSearchTableContext,
    ): JSX.Element => {
        return (
            <TableCell>
                <PartSuggestionAvailabilityView
                    bomItem={bomItem}
                    part={data.part}
                    rfqId={rfqId}
                    assemblyId={assemblyId}
                />
            </TableCell>
        );
    },
};

export const columnLifecycle = {
    id: `ots-search-lifecycle`,
    label: <Trans>Lifecycle</Trans>,
    render: ({ data }: Row<OtsPartSearchTableData>): JSX.Element => {
        return (
            <TableCell>
                <PartLifecycleView part={data.part} />
            </TableCell>
        );
    },
};

export const columnCompliance = {
    id: `ots-search-compliance`,
    label: <Trans>Compliance</Trans>,
    render: ({ data }: Row<OtsPartSearchTableData>, { assemblyIndustry }: OtsPartSearchTableContext): JSX.Element => {
        return (
            <TableCell>
                <PartComplianceView part={data.part} assemblyIndustry={assemblyIndustry} />
            </TableCell>
        );
    },
};

const RenderAddIconButton = ({
    bomItem,
    part,
    onAddPart,
    isSubmitting,
    permissions,
    hasDesignatorQuantityMismatch,
}: {
    bomItem: BomItem;
    part: OtsFullPart;
    onAddPart: (data: OtsFullPart | GenericFullPart | IpnWithMatchesFullPart, sectionOfScreen: SectionOfScreen) => void;
    isSubmitting: boolean;
    permissions: Permission[];
    hasDesignatorQuantityMismatch: boolean;
}) => {
    const { openDialog } = useIpnLinkingDialog();

    const handleAddPart = useCallback(
        (e) => {
            e.stopPropagation();
            const ipnLinkingData = ipnLinkingDialog({
                partOptions: bomItem.parts,
                partToAddAsPartOption: part,
                permissions,
            });

            if (ipnLinkingData.shouldOpenIpnLinkingDialog) {
                const { ipn, partsToLink, partToAddAsPartOption } = ipnLinkingData;
                return openDialog({ ipn, partsToLink, addPart: onAddPart, partToAddAsPartOption });
            }

            return onAddPart(part, 'mpnSearch');
        },
        [onAddPart, part, openDialog, bomItem.parts, permissions],
    );

    return (
        <Tooltip title={hasDesignatorQuantityMismatch ? designatorQtyMismatchTooltip() : ''}>
            <span>
                <SecondaryIconButton
                    disabled={isSubmitting || hasDesignatorQuantityMismatch}
                    disableTouchRipple
                    onClick={handleAddPart}
                    size="small"
                >
                    <AddRoundedIcon fontSize="inherit" />
                </SecondaryIconButton>
            </span>
        </Tooltip>
    );
};

export const columnActions = {
    id: `ots-search-actions`,
    label: <Trans>Actions</Trans>,
    overrides: {
        HeaderTableCell: StickyRightHeaderTableCell,
    },
    render: (
        { data }: Row<OtsPartSearchTableData>,
        {
            isSubmitting,
            handleAddPart,
            handleRemovePart,
            existingPartIds,
            bomItem,
            permissions,
            hasDesignatorQuantityMismatch,
        }: OtsPartSearchTableContext,
    ): JSX.Element => {
        return (
            <StickyTableCell>
                {existingPartIds?.includes(data.part.id) ? (
                    <Tooltip title={hasDesignatorQuantityMismatch ? designatorQtyMismatchTooltip() : ''}>
                        <span>
                            <SecondaryIconButton
                                disabled={isSubmitting || hasDesignatorQuantityMismatch}
                                disableTouchRipple
                                onClick={(e) => {
                                    e.stopPropagation();
                                    handleRemovePart(data.part.id);
                                }}
                                size="small"
                            >
                                <RemoveRoundedIcon fontSize="inherit" />
                            </SecondaryIconButton>
                        </span>
                    </Tooltip>
                ) : (
                    <RenderAddIconButton
                        bomItem={bomItem}
                        part={data.part}
                        onAddPart={handleAddPart}
                        isSubmitting={isSubmitting}
                        permissions={permissions}
                        hasDesignatorQuantityMismatch={hasDesignatorQuantityMismatch}
                    />
                )}
            </StickyTableCell>
        );
    },
};
