import { Box, BoxProps } from '@mui/material';
import * as React from 'react';

export function ItemDetailsLayout({ children, ...props }: React.PropsWithChildren<BoxProps>) {
    const itemsWidth = 'max(20vw, 300px)';
    return (
        <Box
            display="grid"
            gridTemplateColumns={`${itemsWidth} calc(100vw - ${itemsWidth} - 8px)`}
            maxWidth="100vw"
            boxSizing="border-box"
            {...props}
        >
            {children}
        </Box>
    );
}
