import { CircularProgress, styled } from '@mui/material';
import * as React from 'react';
import { colorSystem } from '../../theme';
import { Switch } from '../Switch';
import { Text } from '../Text';

const CIRCULAR_PROGRESS_SIZE = 15;
const PADDING = 4;

export function SwitchableChip({
    checked,
    label,
    isLoading,
    ContainerProps,
}: {
    checked: boolean;
    label: string;
    isLoading?: boolean;
    ContainerProps?: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;
}): JSX.Element {
    return (
        <StyledSpan {...ContainerProps}>
            <GreenSwitch checked={checked} disabled={isLoading} />
            <Text
                variant="h5"
                style={{
                    marginRight: isLoading ? PADDING : PADDING * 2 + CIRCULAR_PROGRESS_SIZE,
                }}
            >
                {label}
            </Text>
            {isLoading && <CircularProgress size={CIRCULAR_PROGRESS_SIZE} />}
        </StyledSpan>
    );
}

const GreenSwitch = styled(Switch)({
    padding: 0,
    '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: 2,
        transitionDuration: '300ms',
        '&:hover': {
            '& + .MuiSwitch-track': {
                backgroundColor: colorSystem.neutral[7],
            },
        },
        '&.Mui-checked': {
            color: colorSystem.neutral.white,
            '& + .MuiSwitch-track': {
                backgroundColor: colorSystem.green[5],
                opacity: 1,
                border: 0,
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 1,
            },
        },
        '&.Mui-checked:hover': {
            '& + .MuiSwitch-track': {
                backgroundColor: colorSystem.green[7],
            },
        },
        '&.Mui-disabled .MuiSwitch-thumb': {
            color: colorSystem.neutral.white,
        },
        '&.Mui-disabled + .MuiSwitch-track': {
            opacity: 1,
            backgroundColor: colorSystem.neutral[3],
        },
    },
});

const StyledSpan = styled('span')({
    border: `1px solid ${colorSystem.neutral[2]}`,
    borderRadius: 20,
    padding: '6px 10px',
    display: 'inline-flex',
    alignItems: 'center',
    gap: 4,
    transition: 'border-color 0.2s ease-in',
    '&:hover': {
        cursor: 'pointer',
        border: `1px solid ${colorSystem.neutral[8]} !important`,
    },
});
