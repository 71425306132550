import { t } from '@lingui/macro';
import { isPresent } from '@luminovo/commons';
import { CenteredLayout } from '@luminovo/design-system';
import { CustomLinkedPartDTO, CustomPriceType, QuantityUnit, RfqContext } from '@luminovo/http-client';
import { CircularProgress } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { useHttpMutation } from '../../../resources/mutation/useHttpMutation';
import { useGlobalCurrency } from '../../../resources/organizationSettings/currencySettingsHandler';
import { useNonExcludedSupplierAndStockLocations } from '../../../resources/supplierAndStockLocation/supplierAndStockLocationHandler';
import { CustomPartOfferForm, CustomPartOfferFormValues } from './CustomPartOfferForm';
import { convertToCustomPartOfferInputDTO } from './converters';

export function AddCustomPartOfferForm({
    rfqId,
    linkedPart,
}: {
    rfqId: string;
    linkedPart: CustomLinkedPartDTO;
}): JSX.Element {
    const rfqContext: RfqContext = { type: 'WithinRfQ', rfq_id: rfqId };

    const history = useHistory();
    const { preferredCurrency } = useGlobalCurrency();
    const { data: supplierAndStockLocations } = useNonExcludedSupplierAndStockLocations();

    const { mutateAsync: mutateAsyncCustomPartOffer } = useHttpMutation('POST /offers/custom-part', {
        snackbarMessage: t`Manual offer successfully created`,
        onSuccess: () => history.goBack(),
    });

    if (!isPresent(supplierAndStockLocations)) {
        return (
            <CenteredLayout height={'30vh'}>
                <CircularProgress />
            </CenteredLayout>
        );
    }

    const onSubmitCallback = async (values: CustomPartOfferFormValues) => {
        const requestBody = convertToCustomPartOfferInputDTO(values);
        await mutateAsyncCustomPartOffer({ requestBody });
    };

    const defaultSupplierAndStockLocation = supplierAndStockLocations[0];

    const defaultValues: CustomPartOfferFormValues = {
        linkedPart,
        priceType: CustomPriceType.QuotePrice,
        supplierAndStockLocation: defaultSupplierAndStockLocation,
        currency: preferredCurrency,
        quantityUnit: {
            unit: QuantityUnit.Pieces,
            quantity: 1,
        },
        pricePoints: [
            {
                quantity: 1,
                amount: '0',
                lead_time_days: undefined,
            },
        ],
        validUntilDate: undefined,
        oneTimeCosts: [],
        notes: undefined,
    };

    return <CustomPartOfferForm rfqContext={rfqContext} onSubmit={onSubmitCallback} defaultValues={defaultValues} />;
}
