import { DemandResponseDTO } from '@luminovo/http-client';
import { getSupplierOrRecipientNumber } from './getSupplierOrRecipientNumber';
import { splitDemandIntoDays } from './splitDemandIntoDays';
import { CompanyRole, QuantityKind, TableDemand, Timeframe } from './types';

type QuantityMap = { [key: string]: number };

const aggregateQuantities = ({
    demandsSplitByDays,
    quantityType,
    company,
    timeFrame,
}: {
    demandsSplitByDays: DemandResponseDTO[];
    quantityType: QuantityKind;
    company: CompanyRole;
    timeFrame: Timeframe;
}): { [supplierOrRecipientNumber: string]: QuantityMap } => {
    return demandsSplitByDays.reduce(
        (acc, demand) => {
            const supplierOrRecipientNumber = getSupplierOrRecipientNumber(demand, company);
            const date = demand.delivery_start_date;
            const quantity = demand[quantityType].quantity;
            const timeKey = getTimeKey(date, timeFrame);

            acc[supplierOrRecipientNumber] = acc[supplierOrRecipientNumber] || {};
            acc[supplierOrRecipientNumber][timeKey] = (acc[supplierOrRecipientNumber][timeKey] || 0) + quantity;

            return acc;
        },
        {} as { [supplierOrRecipientNumber: string]: QuantityMap },
    );
};

const getTimeKey = (date: string, timeFrame: Timeframe): string => {
    const [year, month] = date.split('-');
    return timeFrame === 'month' ? `${year}-${month}` : year;
};

export const groupDemandBySupplierOrRecipientAndTimeframe = ({
    demands,
    quantityType,
    timeFrame,
    company,
}: {
    demands: DemandResponseDTO[];
    quantityType: QuantityKind;
    timeFrame: Timeframe;
    company: CompanyRole;
}): TableDemand[] => {
    const demandsSplitByDays = demands.flatMap((demand) => splitDemandIntoDays(demand, quantityType));
    const aggregatedQuantities = aggregateQuantities({ demandsSplitByDays, quantityType, company, timeFrame });

    return Object.entries(aggregatedQuantities).flatMap(([supplierOrRecipientNumber, timeQuantities]) =>
        Object.entries(timeQuantities).map(([timeKey, totalQuantity]) => ({
            demand: totalQuantity.toString(),
            demandDate: timeKey,
            supplierOrRecipient: supplierOrRecipientNumber,
        })),
    );
};
