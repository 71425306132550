import { ManufacturerDTO } from '@luminovo/http-client';
import { parse } from '@luminovo/parsers';
import type { ParseResult, Parser } from '../types';
import { applyParser } from './applyParser';

export interface Opts {
    manufacturers: ManufacturerDTO[];
}

export const parseManufacturerName: Parser<string, Opts> = async function (
    cells,
    opts,
    field,
): Promise<ParseResult<string>> {
    const possibleNames = opts.manufacturers.flatMap((man) => {
        return [{ name: man.name }, ...man.alternative_names.map((name) => ({ name }))];
    });

    return applyParser({
        cells,
        field,
        formatValue: (x) => x,
        parser: parse.manufacturerName({ manufacturers: possibleNames }),
        alternatives: opts.manufacturers.map((manufacturer) => ({
            id: manufacturer.name,
            label: manufacturer.name,
            description: manufacturer.alternative_names.join(', '),
        })),
    });
};
