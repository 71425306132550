import { t } from '@lingui/macro';
import { CenteredLayout, Flexbox, MaxWidthLayout, Message, NonIdealState } from '@luminovo/design-system';
import { LinearProgress } from '@mui/material';
import { useUniversalImporter } from '../../context';
import { useRunParsers } from '../../hooks/useRunParsers';
import { TableCheckRows } from './TableCheckRows';

export function StepCheck(): JSX.Element {
    const { state } = useUniversalImporter();
    const { refetch } = useRunParsers();
    const { importerTable } = state;

    if (!importerTable) {
        return (
            <CenteredLayout>
                <NonIdealState
                    title={t`Import failed`}
                    description={t`Your file failed to import`}
                    action={{
                        children: t`Retry`,
                        onClick: () => {
                            refetch();
                        },
                    }}
                />
            </CenteredLayout>
        );
    }

    return (
        <MaxWidthLayout sx={{ position: 'relative', height: '100%' }}>
            <LinearProgress
                sx={{ position: 'absolute', left: 0, top: 0, width: '100%' }}
                variant="determinate"
                value={importerTable.getReadyForPreviewPercentage()}
            />
            <Flexbox flexDirection={'column'} gap="16px" height={'100%'}>
                <ErrorsBox />

                <TableCheckRows rows={importerTable.getRows()} />
            </Flexbox>
        </MaxWidthLayout>
    );
}

function ErrorsBox() {
    const { state } = useUniversalImporter();
    const duplicates = state.importerTable?.getUniqueColumnsWithDuplicates() ?? [];
    if (duplicates.length === 0) {
        return null;
    }
    const first = duplicates[0];
    const field = state.config.fields.find((field) => field.columnIndices.includes(first.columnIndex));

    const totalOtherDuplicates = duplicates.reduce((acc, curr) => acc + curr.duplicates, 0) - first.duplicates;

    return (
        <Message
            title={t`Duplicate values`}
            attention="low"
            size="small"
            message={
                t`Unique column "${field?.label}" contains ${first.duplicates - 1} duplicates for "${first.text}"` +
                '. ' +
                (totalOtherDuplicates > 0 ? t`(+${totalOtherDuplicates} more duplicates)` : '')
            }
            variant="red"
        />
    );
}
