import { t, Trans } from '@lingui/macro';
import { formatDecimal, isPresent } from '@luminovo/commons';
import { Chip, colorSystem, Flexbox, SecondaryButton, Text } from '@luminovo/design-system';
import { FullSourcingDTO, SourcingScenarioDTO } from '@luminovo/http-client';
import { isSolutionInNeedOfUpdate, visibleSolutionPreferences } from '@luminovo/sourcing-core';
import { Sync } from '@mui/icons-material';
import { Box } from '@mui/material';
import React from 'react';
import { AlertLoadingRfqOffers } from '../../../../components/Alerts';
import { useAssembly } from '../../../../resources/assembly/assemblyHandler';
import { useForcedOfferUpdate } from '../../../../resources/rfq/rfqHandler';

function AssemblyCountChip({ assemblyId, quantity }: { assemblyId: string; quantity: number }) {
    const { data: assembly } = useAssembly(assemblyId);
    if (!assembly) {
        return null;
    }
    return <Chip color="neutral" label={`${formatDecimal(quantity)}x ${assembly.designator}`} />;
}

const NotUpToDateOfferInformation: React.FunctionComponent<{
    rfqId: string;
    fullSourcingDTO?: FullSourcingDTO;
}> = ({ rfqId, fullSourcingDTO }): JSX.Element => {
    const { mutateAsync, isLoading } = useForcedOfferUpdate(rfqId);

    const count = fullSourcingDTO?.solution_configurations_sourcing.items.filter((item) => {
        return isSolutionInNeedOfUpdate({ solutionTags: item.effective_solution?.tags ?? [] });
    }).length;

    if (!isPresent(fullSourcingDTO) || count === 0) {
        return <></>;
    }

    return (
        <Flexbox gap={8} alignItems={'center'} justifyContent={'flex-end'}>
            <Text variant="body" color={colorSystem.neutral[7]}>
                {t`${count} of your selected solutions are not up to date.`}
            </Text>
            <SecondaryButton size="small" onClick={() => mutateAsync()} isLoading={isLoading} startIcon={<Sync />}>
                <Trans>Update offers</Trans>
            </SecondaryButton>
        </Flexbox>
    );
};
export const SourcingConfigurationContentHeader: React.FunctionComponent<{
    rfqId: string;
    sourcingScenarioDTO: SourcingScenarioDTO;
    fullSourcingDTO?: FullSourcingDTO;
}> = ({ rfqId, sourcingScenarioDTO, fullSourcingDTO }): JSX.Element => {
    const solutionPreferences = visibleSolutionPreferences(sourcingScenarioDTO.solution_preference);

    return (
        <Flexbox gap={40} justifyContent={'space-between'} alignItems={'center'}>
            <Flexbox flexDirection={'column'} gap={10}>
                <Flexbox gap={12} alignItems={'center'} flexWrap={'wrap'}>
                    <Text variant="h3">{sourcingScenarioDTO.name}</Text>
                    {sourcingScenarioDTO.assembly_quantities.items.map(({ assembly: assemblyId, quantity }) => (
                        <AssemblyCountChip key={assemblyId} assemblyId={assemblyId} quantity={quantity} />
                    ))}
                </Flexbox>
                <Text variant="body" color={colorSystem.neutral[7]}>
                    {solutionPreferences.join(' • ')}
                </Text>
            </Flexbox>
            <Box width={'50%'}>
                <AlertLoadingRfqOffers
                    rfqId={rfqId}
                    partIds={null}
                    Placeholder={() => <NotUpToDateOfferInformation rfqId={rfqId} fullSourcingDTO={fullSourcingDTO} />}
                />
            </Box>
        </Flexbox>
    );
};
