import { t } from '@lingui/macro';
import { isPresent } from '@luminovo/commons';
import {
    ArrowDownwardRounded,
    ArrowUpwardRounded,
    Close,
    FilterListRounded,
    InfoRounded,
    NorthRounded,
    SouthRounded,
} from '@mui/icons-material';
import { ListItem, Menu, TableCell, TableRow, Tooltip, styled } from '@mui/material';
import { Column, Header, Table, flexRender } from '@tanstack/react-table';
import React from 'react';
import { colorSystem } from '../../theme';
import { Flexbox } from '../Flexbox';
import { MenuItem } from '../MenuItem';
import { Text } from '../Text';
import { SortIcon } from '../icons';
import {
    FilterArrIncludesSome,
    FilterEnumEqualsAny,
    FilterInDateRange,
    FilterInMonetaryValueRange,
    FilterInNumberRange,
    FilterIncludesString,
} from './Filters';
import { getCommonPinningStyles } from './utils';

export function getFixedHeaderContent<TData>({ table }: { table: Table<TData> }): JSX.Element[] {
    return table.getHeaderGroups().map((headerGroup) => (
        <TableRow key={headerGroup.id}>
            {headerGroup.headers.map((header) => (
                <TableHeaderCell key={header.id} header={header} table={table} />
            ))}
        </TableRow>
    ));
}

const menuStyle = {
    cursor: 'pointer',
    userSelect: 'none',
    color: 'inherit',
} as const;

function TableHeaderCell<TData>({
    header,
    table,
}: {
    header: Header<TData, unknown>;
    table: Table<TData>;
}): JSX.Element {
    const [anchorEl, setAnchorEl] = React.useState<Element | undefined>(undefined);
    const hasColumnMenu = header.column.getCanSort() || header.column.getCanFilter();

    const handleOnClick = React.useCallback(
        (e: React.MouseEvent<HTMLDivElement>) => {
            if (isPresent(anchorEl) || !hasColumnMenu) {
                return;
            }
            setAnchorEl(e.currentTarget);
        },
        [anchorEl, hasColumnMenu],
    );

    const justifyContentMap = {
        left: 'flex-start',
        center: 'center',
        right: 'flex-end',
    } as const;

    return (
        <TableCell
            variant="head"
            colSpan={header.colSpan}
            style={{
                ...getCommonPinningStyles(header.column),
                // WORKAROUND: The backgroundColor should be set on the TableCell, but the pinning style overrides it.
                backgroundColor: colorSystem.neutral[0],
                opacity: 1,
            }}
        >
            {header.isPlaceholder ? null : (
                <Flexbox
                    onClick={handleOnClick}
                    alignItems={'center'}
                    style={hasColumnMenu ? menuStyle : undefined}
                    justifyContent={justifyContentMap[header.column.columnDef.meta?.align ?? 'left']}
                >
                    <Text variant={'inherit'} showEllipsis={true}>
                        {flexRender(header.column.columnDef.header, header.getContext())}
                    </Text>
                    <DescriptionIcon column={header.column} />
                    <ActionIcon column={header.column} />
                    <ColumnMenu column={header.column} table={table} anchorEl={anchorEl} setAnchorEl={setAnchorEl} />
                </Flexbox>
            )}
        </TableCell>
    );
}

export function ColumnMenu<TData>({
    column,
    table,
    anchorEl,
    setAnchorEl,
}: {
    column: Column<TData, unknown>;
    table: Table<TData>;
    anchorEl: Element | undefined;
    setAnchorEl: (el: Element | undefined) => void;
}): JSX.Element {
    const sharedContext = table.options.meta?.sharedContext;

    const handleClose = () => {
        setAnchorEl(undefined);
    };

    const handleClearSort = () => {
        column.clearSorting();
        handleClose();
    };

    const handleSortAsc = () => {
        column.toggleSorting(false);
        handleClose();
    };

    const handleSortDesc = () => {
        column.toggleSorting(true);
        handleClose();
    };

    const dataType = column.columnDef.meta?.dataType;

    if ((!column.getCanFilter() && !column.getCanSort() && !column.getCanPin()) || !isPresent(dataType)) {
        return <></>;
    }

    const ascLabel = {
        text: t`Sort A to Z`,
        number: t`Sort smallest to largest`,
        enum: t`Sort A to Z`,
        array: t`Sort A to Z`,
        date: t`Sort oldest to newest`,
        monetaryValue: t`Sort smallest to largest`,
        generic: t`Sort A to Z`,
    };

    const descLabel = {
        text: t`Sort Z to A`,
        number: t`Sort largest to smallest`,
        enum: t`Sort Z to A`,
        array: t`Sort Z to A`,
        date: t`Sort newest to oldest`,
        monetaryValue: t`Sort largest to smallest`,
        generic: t`Sort Z to A`,
    };

    return (
        <Menu anchorEl={anchorEl} open={isPresent(anchorEl)} onClose={handleClose} keepMounted={false} variant="menu">
            <FilterIncludesStringMenuItem column={column} onClose={handleClose} />
            <FilterInNumberRangeMenuItem column={column} onClose={handleClose} />
            <FilterInMonetaryValueRangeMenuItem column={column} onClose={handleClose} />
            <FilterInDateRangeMenuItem column={column} onClose={handleClose} />
            <FilterEnumEqualsAnyMenuItem column={column} sharedContext={sharedContext} />
            <FilterArrIncludesSomeMenuItem column={column} sharedContext={sharedContext} />

            {column.getCanSort() && (
                <MenuItem
                    label={ascLabel[dataType]}
                    onClick={handleSortAsc}
                    startIcon={<NorthRounded />}
                    disabled={column.getIsSorted() === 'asc'}
                />
            )}

            {column.getCanSort() && (
                <MenuItem
                    label={descLabel[dataType]}
                    onClick={handleSortDesc}
                    startIcon={<SouthRounded />}
                    disabled={column.getIsSorted() === 'desc'}
                />
            )}

            {column.getCanSort() && column.getIsSorted() && (
                <MenuItem
                    label={t`Clear sorting`}
                    variant={'destructive'}
                    startIcon={<Close />}
                    onClick={handleClearSort}
                    disabled={!Boolean(column.getIsSorted())}
                />
            )}
        </Menu>
    );
}

function FilterIncludesStringMenuItem<TData>({
    column,
    onClose,
}: {
    column: Column<TData, unknown>;
    onClose: () => void;
}) {
    if (!column.getCanFilter() || column.columnDef.filterFn !== 'includesString') {
        return null;
    }

    return (
        <ListItem
            style={{ backgroundColor: colorSystem.neutral.white }}
            divider={column.getCanSort()}
            onKeyDown={(e) => e.stopPropagation()}
        >
            <FilterIncludesString column={column} onClose={onClose} />
        </ListItem>
    );
}

function FilterInNumberRangeMenuItem<TData>({
    column,
    onClose,
}: {
    column: Column<TData, unknown>;
    onClose: () => void;
}) {
    if (!column.getCanFilter() || column.columnDef.filterFn !== 'inNumberRange') {
        return null;
    }

    return (
        <ListItem
            style={{ backgroundColor: colorSystem.neutral.white }}
            divider={column.getCanSort()}
            onKeyDown={(e) => e.stopPropagation()}
        >
            <FilterInNumberRange column={column} onClose={onClose} />
        </ListItem>
    );
}

function FilterInMonetaryValueRangeMenuItem<TData>({
    column,
    onClose,
}: {
    column: Column<TData, unknown>;
    onClose: () => void;
}) {
    if (!column.getCanFilter() || column.columnDef.filterFn !== 'inMonetaryValueRange') {
        return null;
    }

    return (
        <ListItem
            style={{ backgroundColor: colorSystem.neutral.white }}
            divider={column.getCanSort()}
            onKeyDown={(e) => e.stopPropagation()}
        >
            <FilterInMonetaryValueRange column={column} onClose={onClose} />
        </ListItem>
    );
}

function FilterInDateRangeMenuItem<TData>({
    column,
    onClose,
}: {
    column: Column<TData, unknown>;
    onClose: () => void;
}) {
    if (!column.getCanFilter() || column.columnDef.filterFn !== 'inDateRange') {
        return null;
    }

    return (
        <ListItem
            style={{ backgroundColor: colorSystem.neutral.white }}
            divider={column.getCanSort()}
            onKeyDown={(e) => e.stopPropagation()}
        >
            <FilterInDateRange column={column} onClose={onClose} />
        </ListItem>
    );
}

function FilterEnumEqualsAnyMenuItem<TData, TSharedContext>({
    column,
    sharedContext,
}: {
    column: Column<TData, unknown>;
    sharedContext: TSharedContext;
}) {
    if (!column.getCanFilter() || column.columnDef.filterFn !== 'equalsAny') {
        return null;
    }

    return (
        <ListItem
            style={{ backgroundColor: colorSystem.neutral.white }}
            divider={column.getCanSort()}
            onKeyDown={(e) => e.stopPropagation()}
        >
            <Flexbox gap={8} flexDirection={'column'} style={{ width: '100%' }}>
                <Text variant={'body-small'} color={colorSystem.neutral[6]}>
                    {t`${column.columnDef.meta?.label()} is`}
                </Text>
                <FilterEnumEqualsAny column={column} sharedContext={sharedContext} />
            </Flexbox>
        </ListItem>
    );
}

function FilterArrIncludesSomeMenuItem<TData, TSharedContext>({
    column,
    sharedContext,
}: {
    column: Column<TData, unknown>;
    sharedContext: TSharedContext;
}) {
    if (!column.getCanFilter() || column.columnDef.filterFn !== 'arrIncludesSome') {
        return null;
    }

    return (
        <ListItem
            style={{ backgroundColor: colorSystem.neutral.white }}
            divider={column.getCanSort()}
            onKeyDown={(e) => e.stopPropagation()}
        >
            <Flexbox gap={8} flexDirection={'column'} style={{ width: '100%' }}>
                <Text variant={'body-small'} color={colorSystem.neutral[6]}>
                    {t`${column.columnDef.meta?.label()} is`}
                </Text>
                <FilterArrIncludesSome column={column} sharedContext={sharedContext} />
            </Flexbox>
        </ListItem>
    );
}

function DescriptionIcon<TData>({ column }: { column: Column<TData, unknown> }) {
    if (column.columnDef.meta?.description === undefined) {
        return null;
    }

    return (
        <Tooltip title={column.columnDef.meta.description()} arrow>
            <StyledHelpOutlineRounded />
        </Tooltip>
    );
}

function ActionIcon<TData>({ column }: { column: Column<TData, unknown> }) {
    if (column.getIsFiltered()) {
        return <ActiveFilterIcon />;
    }

    if (column.getIsSorted() === 'asc') {
        return <ActiveUpSortIcon />;
    }
    if (column.getIsSorted() === 'desc') {
        return <ActiveDownSortIcon />;
    }

    if (column.getCanFilter()) {
        return <StyledFilterIcon />;
    }

    if (column.getCanSort()) {
        return <StyledSortIcon />;
    }

    return null;
}

const StyledHelpOutlineRounded = styled(InfoRounded)(() => ({
    fontSize: 16,
    color: colorSystem.neutral[5],
    verticalAlign: 'middle',
    padding: '2px',
    marginLeft: '4px',
}));

const StyledSortIcon = styled(SortIcon)(() => ({
    verticalAlign: 'middle',
    backgroundColor: colorSystem.neutral[1],
    borderRadius: '50%',
    padding: '2px',
    marginLeft: '4px',
    '&:hover': {
        cursor: 'pointer',
        backgroundColor: colorSystem.neutral[2],
    },
}));

const ActiveUpSortIcon = styled(ArrowUpwardRounded)(() => ({
    fontSize: 16,
    verticalAlign: 'middle',
    backgroundColor: colorSystem.primary[2],
    color: colorSystem.primary[6],
    borderRadius: '50%',
    padding: '2px',
    marginLeft: '4px',
    '&:hover': {
        cursor: 'pointer',
        backgroundColor: colorSystem.primary[3],
        color: colorSystem.primary[7],
    },
}));

const ActiveDownSortIcon = styled(ArrowDownwardRounded)(() => ({
    fontSize: 16,
    verticalAlign: 'middle',
    backgroundColor: colorSystem.primary[2],
    color: colorSystem.primary[6],
    borderRadius: '50%',
    padding: '2px',
    marginLeft: '4px',
    '&:hover': {
        cursor: 'pointer',
        backgroundColor: colorSystem.primary[3],
        color: colorSystem.primary[7],
    },
}));

const StyledFilterIcon = styled(FilterListRounded)(() => ({
    fontSize: 16,
    verticalAlign: 'middle',
    backgroundColor: colorSystem.neutral[1],
    borderRadius: '50%',
    padding: '2px',
    marginLeft: '4px',
    '&:hover': {
        cursor: 'pointer',
        backgroundColor: colorSystem.neutral[2],
    },
}));

const ActiveFilterIcon = styled(FilterListRounded)(() => ({
    fontSize: 16,
    verticalAlign: 'middle',
    backgroundColor: colorSystem.primary[2],
    color: colorSystem.primary[6],
    borderRadius: '50%',
    padding: '2px',
    marginLeft: '4px',
    '&:hover': {
        cursor: 'pointer',
        backgroundColor: colorSystem.primary[3],
        color: colorSystem.primary[7],
    },
}));
