import { Trans } from '@lingui/macro';
import { formatInteger, formatToLongDate } from '@luminovo/commons';
import { ButtonGroup, ButtonGroupItem, Flexbox, Text, colorSystem } from '@luminovo/design-system';
import { DemandDateFilter, QuantityKind, SupplierOrRecipientQuantity, Timeframe } from '@luminovo/manufacturing-core';
import { Box, Skeleton } from '@mui/material';
import { useState } from 'react';
import { DemandData } from '../../resources/demand/demandHandler';
import { DemandSummaryTableDynamic, transformYearDemandToTable } from './DemandSummaryTable';
import { useFilterDemandDialog } from './FilterDemandDialog';

type SupplierRecipientButtonGroup = 'supplier' | 'recipient';
type YearlyMonthlyButtonGroup = Timeframe;
type QuantityTypeButtonGroup = QuantityKind;

export type ButtonGroupsReturnType = {
    supplierRecipient: SupplierRecipientButtonGroup;
    handleSupplierRecipientClick: (newValue: SupplierRecipientButtonGroup) => void;
    yearlyMonthly: YearlyMonthlyButtonGroup;
    handleYearlyMonthlyClick: (newValue: YearlyMonthlyButtonGroup) => void;
    quantityType: QuantityTypeButtonGroup;
    handleQuantityTypeClick: (newValue: QuantityTypeButtonGroup) => void;
};

export const useButtonGroups = (): ButtonGroupsReturnType => {
    const [supplierRecipient, setSupplierRecipient] = useState<SupplierRecipientButtonGroup>('supplier');
    const handleSupplierRecipientClick = (newValue: SupplierRecipientButtonGroup) => {
        setSupplierRecipient(newValue);
    };

    const [yearlyMonthly, setYearlyMonthly] = useState<YearlyMonthlyButtonGroup>('month');
    const handleYearlyMonthlyClick = (newValue: YearlyMonthlyButtonGroup) => {
        setYearlyMonthly(newValue);
    };

    const [quantityType, setQuantityType] = useState<QuantityTypeButtonGroup>('net_quantity');
    const handleQuantityTypeClick = (newValue: QuantityTypeButtonGroup) => {
        setQuantityType(newValue);
    };

    return {
        supplierRecipient,
        handleSupplierRecipientClick,
        yearlyMonthly,
        handleYearlyMonthlyClick,
        quantityType,
        handleQuantityTypeClick,
    };
};

const ButtonGroups = ({ buttonGroupsReturnType }: { buttonGroupsReturnType: ButtonGroupsReturnType }): JSX.Element => {
    const {
        supplierRecipient,
        handleSupplierRecipientClick,
        yearlyMonthly,
        handleYearlyMonthlyClick,
        quantityType,
        handleQuantityTypeClick,
    } = buttonGroupsReturnType;

    return (
        <Flexbox gap={'20px'}>
            <ButtonGroup size="small">
                <ButtonGroupItem
                    key={'supplier'}
                    selected={supplierRecipient === 'supplier'}
                    onClick={() => handleSupplierRecipientClick('supplier')}
                >
                    <Trans>Supplier</Trans>
                </ButtonGroupItem>
                <ButtonGroupItem
                    key={'recipient'}
                    selected={supplierRecipient === 'recipient'}
                    onClick={() => handleSupplierRecipientClick('recipient')}
                >
                    <Trans>Recipient</Trans>
                </ButtonGroupItem>
            </ButtonGroup>
            <ButtonGroup size="small">
                <ButtonGroupItem
                    key={'yearly'}
                    selected={yearlyMonthly === 'year'}
                    onClick={() => handleYearlyMonthlyClick('year')}
                >
                    <Trans>Yearly</Trans>
                </ButtonGroupItem>
                <ButtonGroupItem
                    key={'monthly'}
                    selected={yearlyMonthly === 'month'}
                    onClick={() => handleYearlyMonthlyClick('month')}
                >
                    <Trans>Monthly</Trans>
                </ButtonGroupItem>
            </ButtonGroup>
            <ButtonGroup size="small">
                <ButtonGroupItem
                    key={'net'}
                    selected={quantityType === 'net_quantity'}
                    onClick={() => handleQuantityTypeClick('net_quantity')}
                >
                    <Trans>Net</Trans>
                </ButtonGroupItem>
                <ButtonGroupItem
                    key={'gross'}
                    selected={quantityType === 'gross_quantity'}
                    onClick={() => handleQuantityTypeClick('gross_quantity')}
                >
                    <Trans>Gross</Trans>
                </ButtonGroupItem>
            </ButtonGroup>
        </Flexbox>
    );
};

const DateFilter = ({
    startDate,
    endDate,
    onEditClick,
}: {
    startDate: Date;
    endDate: Date;
    onEditClick: () => void;
}) => {
    return (
        <DemandDateFilter
            selectedStartDate={formatToLongDate(startDate)}
            selectedEndDate={formatToLongDate(endDate)}
            editButtonProps={{ onEditClick: () => onEditClick() }}
        />
    );
};

const SuppliersOrRecipientsHorizontalList = ({
    suppliersOrRecipients,
}: {
    suppliersOrRecipients: SupplierOrRecipientQuantity[];
}) => {
    return (
        <Flexbox gap={'40px'}>
            {suppliersOrRecipients.map((supplierOrRecipient) => (
                <Flexbox key={supplierOrRecipient.supplierOrRecipientNumber} flexDirection={'column'}>
                    <Flexbox alignItems={'center'} gap={4}>
                        <Text variant="body-small" color={colorSystem.neutral[8]}>
                            {supplierOrRecipient.supplierOrRecipientNumber}
                        </Text>
                    </Flexbox>
                    <Text variant="h4" color={colorSystem.neutral[9]}>
                        {formatInteger(supplierOrRecipient.totalQuantity)}
                    </Text>
                </Flexbox>
            ))}
        </Flexbox>
    );
};

export const DemandSummary = ({
    buttonGroups,
    demandData,
}: {
    buttonGroups: ButtonGroupsReturnType;
    demandData: DemandData | undefined;
}) => {
    const [startDate, setStartDate] = useState(new Date('2020-01-01'));
    const [endDate, setEndDate] = useState(new Date('2030-01-01'));

    const updateDateRange = (start: Date, end: Date) => {
        setStartDate(start);
        setEndDate(end);
    };
    const openDialog = useFilterDemandDialog({
        startDate,
        endDate,
        submit: updateDateRange,
    });
    const { table, tableFields } = transformYearDemandToTable(
        demandData ? demandData.supplierOrRecipientQuantitiesByMonthYear : [],
        buttonGroups.yearlyMonthly,
    );

    return (
        <Flexbox
            sx={{
                backgroundColor: colorSystem.neutral.white,
                paddingY: '24px',
                paddingX: '20px',
                height: '100%',
            }}
            flexDirection={'column'}
            gap={'16px'}
        >
            <Flexbox>
                <Text color={colorSystem.neutral[8]} variant="h3">
                    <Trans>Summary</Trans>
                </Text>
            </Flexbox>
            <Flexbox justifyContent={'space-between'}>
                <ButtonGroups buttonGroupsReturnType={buttonGroups} />
                <DateFilter startDate={startDate} endDate={endDate} onEditClick={openDialog} />
            </Flexbox>
            {demandData ? (
                <>
                    <Flexbox flexDirection={'column'}>
                        <Text color={colorSystem.neutral[7]} variant="h5">
                            <Trans>Total demand</Trans>
                        </Text>
                        <Text color={colorSystem.neutral[9]} variant="h1">
                            {formatInteger(demandData.totalQuantity)}
                        </Text>
                    </Flexbox>

                    <SuppliersOrRecipientsHorizontalList
                        suppliersOrRecipients={demandData.supplierOrRecipientQuantities}
                    />

                    <Box sx={{ height: '200px', marginTop: '28px' }}>
                        {table.length > 0 && <DemandSummaryTableDynamic table={table} fields={tableFields} />}
                    </Box>
                </>
            ) : (
                <Flexbox flexDirection={'column'} gap={12}>
                    <Skeleton variant="rectangular" height={50} width={200} />
                    <Skeleton variant="rectangular" height={100} />
                </Flexbox>
            )}
        </Flexbox>
    );
};
