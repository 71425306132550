import { Trans, t } from '@lingui/macro';
import { assertPresent } from '@luminovo/commons';
import { InboxRounded } from '@mui/icons-material';
import { CircularProgress, TableBody, TableCell, TableRow } from '@mui/material';
import { Table } from '@tanstack/react-table';
import React from 'react';
import { TableComponents } from 'react-virtuoso';
import { colorSystem } from '../../theme';
import { Flexbox } from '../Flexbox';
import { NonIdealState } from '../NonIdealState';
import { PrimaryButton } from '../buttons';
import { CenteredLayout } from '../layout/CenteredLayout';
import { FilterChips } from './TanStackMenuBar';
import { TanStackTableContext } from './type';

export const DefaultOuterEmptyPlaceholder: TableComponents<
    unknown,
    TanStackTableContext<unknown>
>['EmptyPlaceholder'] = ({ context }) => {
    const { table, EmptyPlaceholder = DefaultInnerEmptyPlaceholder, isLoading } = assertPresent(context);
    const globalFilter = table.getState().globalFilter;

    const hasGlobalFilter = Boolean(globalFilter);
    const hasColumnFilters = table.getState().columnFilters.length > 0;

    const handleClearFilters = React.useCallback(() => {
        table.resetGlobalFilter();
        table.resetColumnFilters(true);
    }, [table]);

    return (
        <TableBody>
            <TableRow style={{ borderColor: colorSystem.neutral.white }}>
                <TableCell
                    colSpan={table.getAllLeafColumns().filter((c) => c.getIsVisible()).length}
                    style={{ backgroundColor: colorSystem.neutral.white }}
                >
                    <CenteredLayout style={{ height: '100%', minHeight: '200px' }}>
                        {isLoading ? (
                            <CircularProgress />
                        ) : hasGlobalFilter ? (
                            <NoMatchesWithGlobalFilter
                                globalFilter={globalFilter}
                                handleClearFilters={handleClearFilters}
                            />
                        ) : hasColumnFilters ? (
                            <NoMatchWithColumnFilters table={table} handleClearFilters={handleClearFilters} />
                        ) : (
                            <EmptyPlaceholder table={table} sharedContext={table.options.meta?.sharedContext} />
                        )}
                    </CenteredLayout>
                </TableCell>
            </TableRow>
        </TableBody>
    );
};

const NoMatchesWithGlobalFilter: React.FunctionComponent<{ globalFilter: string; handleClearFilters: () => void }> = ({
    globalFilter,
    handleClearFilters,
}) => (
    <NonIdealState
        title={t`No match`}
        description={<Trans>No exact matches for "{globalFilter}"</Trans>}
        action={{
            onClick: handleClearFilters,
            children: t`Clear filters`,
        }}
    />
);

const NoMatchWithColumnFilters: React.FunctionComponent<{
    handleClearFilters: () => void;
    table: Table<unknown>;
}> = ({ handleClearFilters, table }) => {
    const CustomActionButton = React.useCallback(
        () => (
            <Flexbox flexDirection={'column'} gap={'12px'} flexWrap={'wrap'}>
                <FilterChips table={table} />
                <span>
                    <PrimaryButton size="small" onClick={handleClearFilters}>
                        {t`Clear filters`}
                    </PrimaryButton>
                </span>
            </Flexbox>
        ),
        [table, handleClearFilters],
    );

    return (
        <NonIdealState
            title={t`No items match your filters`}
            description={t`Try adjusting or clearing your filters to see more results.`}
            style={{ maxWidth: '600px' }}
            overrides={{ ActionButton: CustomActionButton }}
        />
    );
};

const DefaultInnerEmptyPlaceholder: React.FunctionComponent = () => (
    <NonIdealState Icon={InboxRounded} title={t`It's empty here`} description={t`We have no data to display yet.`} />
);
