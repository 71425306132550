import { plural, t } from '@lingui/macro';
import { Message } from '@luminovo/design-system';
import { PCBV2File } from '@luminovo/http-client';
import { Capability } from '../../../../resources/pcb/pcbFunctions';
import { useShowPcbPdfAnalysisMessageModal } from '../PcbAnalysis/utils/useShowPcbPdfAnalysisMessageModal';
import { usePdfViewerDrawer } from './usePdfViewerDrawer';

export function PdfExtractionMessage({
    capabilities,
    pcbId,
    files,
}: {
    capabilities: Capability[];
    pcbId: string;
    files: PCBV2File[];
}) {
    const extractedFromPdf = capabilities.filter((cap) => cap.extractedFromPdf);
    const { openDrawer } = usePdfViewerDrawer();
    const { userHasSeenModal, setUserAgreesToExtraction, userAgreesToExtraction } =
        useShowPcbPdfAnalysisMessageModal(pcbId);

    const handleClose = () => {
        setUserAgreesToExtraction(false);
    };

    if (extractedFromPdf.length === 0 || (userHasSeenModal && userAgreesToExtraction === false)) {
        return <></>;
    }

    const extractedValues = extractedFromPdf.length;
    const pluralizedFieldText = plural(extractedValues, {
        one: `field was`,
        other: `fields were`,
    });

    return (
        <Message
            attention="high"
            size="large"
            variant="primary"
            title={t`PDF extraction`}
            // eslint-disable-next-line spellcheck/spell-checker
            message={t`${extractedValues} ${pluralizedFieldText} automatically extracted from uploaded PDFs`}
            onClose={handleClose}
            action={{
                label: t`Open PDF`,
                onClick: () => {
                    const file = files.find((file) => file.name === extractedFromPdf[0].extractedFromPdf?.file.name);

                    openDrawer({
                        selectedPdf: {
                            fileName: file?.path ?? '',
                            regions: extractedFromPdf.flatMap((cap) => cap.extractedFromPdf?.regions ?? []),
                        },
                    });
                },
            }}
        />
    );
}
