import { t } from '@lingui/macro';
import { FieldNumericControlled, Text } from '@luminovo/design-system';
import { InputAdornment } from '@mui/material';
import { Control, Path, useWatch } from 'react-hook-form';
import { PanelFormState } from '../utils/types';

export const PcbPanelNumberInput = ({
    required = true,
    endAdornmentText = t`mm`,
    control,
    name,
    min,
    max,
    disabled,
    onChangeCallback,
}: {
    required?: boolean;
    endAdornmentText?: string;
    min?: number;
    max?: number;
    control: Control<PanelFormState>;
    name: Path<PanelFormState>;
    disabled?: boolean;
    onChangeCallback?: () => void;
}) => {
    const value = useWatch({
        control,
        name,
    });
    if (disabled) {
        return (
            <Text variant="body">
                {value} {endAdornmentText}
            </Text>
        );
    }
    return (
        <FieldNumericControlled
            required={required}
            control={control}
            name={name}
            min={min}
            max={max}
            FieldProps={{
                disabled,
                placeholder: '0',
                fullWidth: true,
                size: 'small',
                InputProps: {
                    endAdornment: endAdornmentText ? (
                        <InputAdornment position="end">{endAdornmentText}</InputAdornment>
                    ) : undefined,
                },
                onInput: () => {
                    onChangeCallback && onChangeCallback();
                },
            }}
        />
    );
};
