import { t, Trans } from '@lingui/macro';
import { useHasPermission } from '@luminovo/auth';
import { assertUnreachable, Currency, formatRelativeTime, id, isPresent } from '@luminovo/commons';
import {
    colorSystem,
    DestructiveTertiaryIconButton,
    FieldDateControlled,
    FieldNumericControlled,
    FieldSelectControlled,
    FieldSelectCreatableControlled,
    FieldTextControlled,
    Flexbox,
    FormItem,
    FormSection,
    SecondaryButton,
    Text,
} from '@luminovo/design-system';
import {
    CustomLinkedPartDTO,
    CustomPricePointInputDTO,
    CustomPriceType,
    OneTimeCostInputDTO,
    Option,
    QuantityUnit,
    QuantityUnitDTO,
    RegionsEnum,
    RfqContext,
    SupplierAndStockLocationDTO,
    SupplierPartType,
    SupplierPreference,
    SupplierType,
} from '@luminovo/http-client';
import {
    formatSupplierAndStockLocationDTO,
    QuantityUnitRecordPlural,
    QuantityUnitRecordSingular,
} from '@luminovo/sourcing-core';
import { Add, Close } from '@mui/icons-material';
import { Box, Divider, InputAdornment, Typography } from '@mui/material';
import * as React from 'react';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import { useHistory } from 'react-router';
import { FormContainer } from '../../../components/formLayouts/FormContainer';
import { SubmitButton } from '../../../components/formLayouts/SubmitButton';
import { transEnum } from '../../../components/localization/TransEnum';
import { PartCard } from '../../../components/partSpecificationCards/PartCard';
import { LoadingText } from '../../../components/Spinners';
import { inputCurrenciesPublicTranslations } from '../../../resources/currencyInputTypes';
import { customPriceTypeTranslations } from '../../../resources/offer/i18n';
import { useCustomComponent, useCustomPart } from '../../../resources/part/partHandler';
import { useNonExcludedSupplierAndStockLocations } from '../../../resources/supplierAndStockLocation/supplierAndStockLocationHandler';
import { useDialogAddSupplierAndStockLocation } from '../../SupplierManagement/components/SupplierDialogs/AddSupplierDialog';

export type CustomPartOfferFormValues = {
    linkedPart: CustomLinkedPartDTO;
    supplierAndStockLocation: SupplierAndStockLocationDTO;
    quantityUnit: QuantityUnitDTO;
    currency: Currency;
    pricePoints: CustomPricePointInputDTO[];
    oneTimeCosts: OneTimeCostInputDTO[];
    validUntilDate?: string;
    notes?: Option<string>;
    priceType: CustomPriceType;
};

function FormItemPriceType() {
    const { control } = useFormContext<CustomPartOfferFormValues>();
    return (
        <FormItem label={t`Type`} required>
            <FieldSelectControlled
                control={control}
                name="priceType"
                required
                FieldProps={{
                    options: Object.values(CustomPriceType),
                    getOptionLabel: (option) => transEnum(option, customPriceTypeTranslations),
                    disableClearable: true,
                }}
            />
        </FormItem>
    );
}

export function CustomPartOfferForm({
    onSubmit,
    defaultValues,
    rfqContext,
}: {
    onSubmit: (form: CustomPartOfferFormValues) => Promise<void>;
    defaultValues: CustomPartOfferFormValues;
    rfqContext: RfqContext;
}) {
    return (
        <FormContainer defaultValues={defaultValues} onSubmit={onSubmit}>
            <Flexbox gap={32} flexDirection="column">
                <FormSection title={t`Offer information`}>
                    <FormItemLinkedPart rfqContext={rfqContext} />
                    <FormItemSupplier />
                    <FormItemPriceType />
                    <FormItemUnitOfMeasurement />
                </FormSection>
                <Divider />
                <FormSection title={t`Pricing information`}>
                    <FormItemCurrency />
                    <FormItemPricePoints />
                    <FormItemOneTimeCosts />
                </FormSection>
                <Divider />
                <FormSection title={t`Offer validity`}>
                    <FormItemValidUntilDate />
                </FormSection>
                <Divider />
                <FormSection title={t`Additional information`}>
                    <FormItemNotes />
                </FormSection>
                <Divider />
                <Flexbox flexDirection="row" gap={8} justifyContent="flex-end">
                    <CancelButton />
                    <SubmitButton />
                </Flexbox>
            </Flexbox>
        </FormContainer>
    );
}

function CancelButton() {
    const history = useHistory();
    const handleClose = React.useCallback(() => {
        history.goBack();
    }, [history]);
    return (
        <SecondaryButton onClick={handleClose}>
            <Trans>Cancel</Trans>
        </SecondaryButton>
    );
}

function FormItemLinkedPart({ rfqContext }: { rfqContext: RfqContext }) {
    const { control } = useFormContext<CustomPartOfferFormValues>();
    const linkedPart = useWatch({ control, name: 'linkedPart' });

    if (linkedPart.type === 'CustomPart') {
        return <FormItemCustomPart partId={linkedPart.id} rfqContext={rfqContext} />;
    }

    if (linkedPart.type === 'CustomComponent') {
        return <FormItemCustomComponent ipnId={linkedPart.id} rfqContext={rfqContext} />;
    }

    assertUnreachable(linkedPart);
}

function FormItemCustomPart({ partId, rfqContext }: { partId: string; rfqContext: RfqContext }) {
    const { data: part } = useCustomPart(partId);

    return (
        <FormItem label={t`Part`}>
            <PartCard part={part} rfqContext={rfqContext} collapsed={true} />
        </FormItem>
    );
}

function FormItemCustomComponent({ ipnId, rfqContext }: { ipnId: string; rfqContext: RfqContext }) {
    const { data: customComponent } = useCustomComponent(ipnId, rfqContext);

    return (
        <FormItem label={t`Custom component`}>
            <PartCard part={customComponent} rfqContext={rfqContext} collapsed={true} />
        </FormItem>
    );
}

function FormItemSupplier() {
    const { control, setValue } = useFormContext<CustomPartOfferFormValues>();

    const hasCreateSupplierPermission = useHasPermission(['create:supplier']);
    const { data: supplierAndStockLocations = [], isLoading } = useNonExcludedSupplierAndStockLocations();
    const { openDialog } = useDialogAddSupplierAndStockLocation({
        onSuccessCallback: (s) => {
            setValue('supplierAndStockLocation', s, { shouldValidate: true });
        },
        disableSupplierPreferenceField: true,
    });

    if (isLoading) {
        return <LoadingText />;
    }

    return (
        <FormItem label={t`Supplier`} required>
            <FieldSelectCreatableControlled
                control={control}
                name="supplierAndStockLocation"
                required
                FieldProps={{
                    id: id('sourcing/input_supplier'),
                    options: supplierAndStockLocations,
                    getOptionKey: (option) => option.id,
                    getOptionLabel: (option) => formatSupplierAndStockLocationDTO(option),
                    renderOption: (option) => {
                        return (
                            <Flexbox flexDirection={'column'} gap={4} maxWidth={'100%'}>
                                <Text variant={'body'}>{formatSupplierAndStockLocationDTO(option)}</Text>
                                <Text variant={'body-small'} color={colorSystem.neutral[7]}>
                                    {option.supplier_number}
                                </Text>
                            </Flexbox>
                        );
                    },
                    isOptionEqualToValue: (option, value) => option.id === value.id,
                    action: {
                        label: t`Add new supplier`,
                        disabled: !hasCreateSupplierPermission,
                        onClick: (newValue) => {
                            openDialog({
                                name: newValue,
                                stockLocation: RegionsEnum.Unknown,
                                supplierNumber: '',
                                supplierPreference: SupplierPreference.NotApproved,
                                supplierType: SupplierType.Manufacturer,
                                supplierPartType: SupplierPartType.Custom,
                            });
                        },
                    },
                }}
            />
        </FormItem>
    );
}

function FormItemUnitOfMeasurement() {
    const { control } = useFormContext<CustomPartOfferFormValues>();

    return (
        <FormItem
            label={t`Unit of measurement`}
            required
            description={
                <Trans>
                    All other quantities are multiplied by the unit of measurement to obtain the total quantity. For
                    example, if the unit of measurement is “10 meters” and you have “2” in stock, then we calculate this
                    as 2 * 10 meters = 20 meters in stock
                </Trans>
            }
        >
            <Box display={'grid'} gridTemplateColumns="1fr 120px" columnGap={8}>
                <FieldNumericControlled control={control} name="quantityUnit.quantity" required={true} min={1} />
                <FieldSelectControlled
                    control={control}
                    name="quantityUnit.unit"
                    FieldProps={{
                        disableClearable: true,
                        options: Object.values(QuantityUnit),
                        getOptionLabel: (option) => transEnum(option, QuantityUnitRecordSingular),
                    }}
                />
            </Box>
        </FormItem>
    );
}

function FormItemCurrency() {
    const { control } = useFormContext<CustomPartOfferFormValues>();
    return (
        <FormItem label={t`Currency`} required>
            <FieldSelectControlled
                control={control}
                name="currency"
                required
                FieldProps={{
                    options: Object.values(Currency),
                    getOptionLabel: (option) => transEnum(option, inputCurrenciesPublicTranslations),
                    disableClearable: true,
                }}
            />
        </FormItem>
    );
}

function FormItemPricePoints() {
    const { control } = useFormContext<CustomPartOfferFormValues>();
    const currency = useWatch({ control, name: 'currency' });
    const unitOfMeasurement = useWatch({ control, name: 'quantityUnit.quantity' });
    const unit = useWatch({ control, name: 'quantityUnit.unit' });
    const disabled = !isPresent(unitOfMeasurement) || isNaN(unitOfMeasurement);

    const { fields, append, remove } = useFieldArray({ control, name: 'pricePoints' });

    return (
        <FormItem label={t`Price points`}>
            <Box
                display={'grid'}
                alignItems="center"
                gridTemplateColumns={'1fr 1fr 1fr auto'}
                rowGap={'16px'}
                columnGap={'16px'}
            >
                <Typography color="textSecondary">
                    <Trans>Quantity</Trans>*
                </Typography>
                <Typography color="textSecondary">
                    <Trans>Unit price</Trans>*
                </Typography>
                <Typography color="textSecondary">
                    <Trans>Lead time (days)</Trans>
                </Typography>

                <span />
                {fields.map((item, index) => {
                    return (
                        <React.Fragment key={`${index}-${JSON.stringify(item)}`}>
                            <FieldNumericControlled
                                control={control}
                                name={`pricePoints.${index}.quantity`}
                                exclusiveMin={0}
                                required
                                FieldProps={{
                                    placeholder: t`Quantity`,
                                    InputProps: {
                                        endAdornment:
                                            disabled || unitOfMeasurement === 1 ? undefined : (
                                                <InputAdornment position="end">
                                                    {`× ${unitOfMeasurement} ${transEnum(
                                                        unit,
                                                        QuantityUnitRecordPlural,
                                                    )}`}
                                                </InputAdornment>
                                            ),
                                    },
                                }}
                            />
                            <FieldNumericControlled
                                control={control}
                                name={`pricePoints.${index}.amount`}
                                exclusiveMin={0}
                                required
                                FieldProps={{
                                    InputProps: {
                                        endAdornment: <InputAdornment position="end">{currency}</InputAdornment>,
                                    },
                                }}
                            />
                            <FieldNumericControlled
                                control={control}
                                name={`pricePoints.${index}.lead_time_days`}
                                isInteger={true}
                                min={1}
                                FieldProps={{
                                    placeholder: t`Full days`,
                                }}
                            />
                            <DestructiveTertiaryIconButton
                                onClick={() => remove(index)}
                                size="medium"
                                disabled={fields.length < 2}
                            >
                                <Close fontSize="inherit" />
                            </DestructiveTertiaryIconButton>
                        </React.Fragment>
                    );
                })}
                <SecondaryButton
                    startIcon={<Add />}
                    onClick={() => {
                        append({
                            quantity: fields.map((x) => x.quantity).reduce((a, b) => Math.max(a, b), 1),
                            amount: '0',
                            lead_time_days: undefined,
                        });
                    }}
                    size="medium"
                >
                    <Trans>Add price point</Trans>
                </SecondaryButton>
            </Box>
        </FormItem>
    );
}

function FormItemOneTimeCosts() {
    const { control } = useFormContext<CustomPartOfferFormValues>();
    const currency = useWatch({ control, name: 'currency' });

    const { fields, append, remove } = useFieldArray({ control, name: 'oneTimeCosts' });

    return (
        <FormItem label={t`One-time costs`}>
            <Box
                display={'grid'}
                alignItems="center"
                gridTemplateColumns={'1fr 1fr 1fr auto'}
                rowGap={'16px'}
                columnGap={'16px'}
            >
                <Typography color="textSecondary">
                    <Trans>Cost</Trans>*
                </Typography>
                <Typography color="textSecondary">
                    <Trans>Description</Trans>
                </Typography>
                <span />
                <span />
                {fields.map((item, index) => {
                    return (
                        <React.Fragment key={`${index}-${JSON.stringify(item)}`}>
                            <FieldNumericControlled
                                control={control}
                                name={`oneTimeCosts.${index}.amount`}
                                exclusiveMin={0}
                                required
                                FieldProps={{
                                    InputProps: {
                                        endAdornment: <InputAdornment position="end">{currency}</InputAdornment>,
                                    },
                                }}
                            />
                            <FieldTextControlled control={control} name={`oneTimeCosts.${index}.description`} />
                            <span />
                            <DestructiveTertiaryIconButton onClick={() => remove(index)} size="medium">
                                <Close fontSize="inherit" />
                            </DestructiveTertiaryIconButton>
                        </React.Fragment>
                    );
                })}
                <SecondaryButton
                    startIcon={<Add />}
                    onClick={() => {
                        append({
                            amount: String(1),
                            description: undefined,
                        });
                    }}
                    size="medium"
                >
                    <Trans>Add one-time cost</Trans>
                </SecondaryButton>
            </Box>
        </FormItem>
    );
}

function FormItemValidUntilDate() {
    const { control } = useFormContext<CustomPartOfferFormValues>();
    const validUntilDate = useWatch({ control, name: 'validUntilDate' });

    return (
        <FormItem label={t`Valid until`}>
            <FieldDateControlled control={control} name={'validUntilDate'} />
            {validUntilDate && <Text variant="body">{formatRelativeTime(validUntilDate)}.</Text>}
        </FormItem>
    );
}

function FormItemNotes() {
    const { control } = useFormContext<CustomPartOfferFormValues>();
    return (
        <FormItem label={t`Notes`}>
            <FieldTextControlled control={control} name="notes" FieldProps={{ multiline: true, minRows: 2 }} />
        </FormItem>
    );
}
