import { Trans } from '@lingui/macro';
import { Text } from '@luminovo/design-system';
import { Lock } from '@mui/icons-material';
import { Box, CircularProgress, styled } from '@mui/material';
import * as Sentry from '@sentry/react';
import * as React from 'react';
import { ErrorFallback } from '../../../../components/errorHandlers/ErrorBoundary';
import { useHasPermission } from '../../../../permissions/useHasPermission';
import { colorSystem } from '../../../../themes';

const StyledGrid = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    padding: `2rem`,
    backgroundColor: colorSystem.neutral.white,
    marginTop: '0px',
    gap: 24,
});

export const SettingsContainer: React.FunctionComponent<{
    title: string | JSX.Element;
    fullWidth?: boolean;
    style?: React.CSSProperties;
}> = (props) => {
    const isAllowedToEdit = useHasPermission(['edit:organization_settings']);
    return (
        <StyledGrid
            display="flex"
            flexDirection="column"
            maxWidth={props.fullWidth ? undefined : 1400}
            style={props.style}
        >
            <Text variant="h1">{props.title}</Text>
            {!isAllowedToEdit && <ReadOnlyAccessAlert />}

            <Sentry.ErrorBoundary fallback={ErrorFallback}>
                <React.Suspense fallback={<CircularProgress />}>{props.children}</React.Suspense>
            </Sentry.ErrorBoundary>
        </StyledGrid>
    );
};

export function ReadOnlyAccessAlert() {
    return (
        <Box
            style={{
                display: 'inline-flex',
                background: colorSystem.yellow[1],
                border: `1px solid ${colorSystem.yellow[7]}`,
                padding: `8px`,
                borderRadius: 4,
                color: colorSystem.yellow[8],
                alignItems: 'center',
                gap: 9,
            }}
        >
            <Lock />
            <Text color="inherit">
                <b>
                    <Trans>Read-only access</Trans>
                </b>
                : &nbsp;
                <Trans>You don't have permissions to edit the settings.</Trans>{' '}
                <Trans>Contact an admin to get access.</Trans>
            </Text>
        </Box>
    );
}
