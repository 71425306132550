import {
    MonetaryValue,
    compareByString,
    extractAmountFromMonetaryValue,
    isEqual,
    isPresent,
    throwErrorUnlessProduction,
} from '@luminovo/commons';
import { rankItem, rankings } from '@tanstack/match-sorter-utils';
import { Column, ColumnDef, FilterFn, SortingFn, Table } from '@tanstack/react-table';
import { colorSystem } from '../../../theme';
import { compareByNumber } from '../../DataTable';
import { UseTanStackTableStateProps } from '../type';
import { URLStorage } from './useTanStackPersistedState';

export function createFuzzyFilter<TData>(columns: ColumnDef<TData, any>[]): FilterFn<TData> {
    return (row, columnId, filterValue) => {
        const columnDef = columns.find((col) => col.id === columnId);

        if (!columnDef) {
            throwErrorUnlessProduction(`Column with id ${columnId} not found in the columns array.`);
            return false;
        }

        const rawValue = row.getValue(columnId);
        const { dataType, equalsAny, arrIncludesSome } = columnDef.meta ?? {};

        let itemRank = undefined;
        switch (dataType) {
            case 'text':
                itemRank = rankItem(rawValue, filterValue, { threshold: rankings.CONTAINS });
                break;
            case 'number':
                itemRank = rankItem(rawValue, filterValue, { threshold: rankings.CONTAINS });
                break;
            case 'enum':
                itemRank = rankItem(equalsAny?.getOptionLabel(rawValue), filterValue, { threshold: rankings.CONTAINS });
                break;
            case 'array':
                if (!Array.isArray(rawValue)) {
                    return false;
                }

                itemRank = rankItem(
                    rawValue.map((value) => arrIncludesSome?.getOptionLabel(value)),
                    filterValue,
                    {
                        threshold: rankings.CONTAINS,
                    },
                );
                break;
            default:
                return false;
        }

        // Store the itemRank info
        row.columnFiltersMeta[columnId] = {
            itemRank,
        };

        // Return if the item should be filtered in/out
        return itemRank.passed;
    };
}

/**
 * @ 2024-03-14 TANSTACK
 * https://tanstack.com/table/v8/docs/framework/react/examples/column-pinning-sticky
 */
//These are the important styles to make sticky column pinning work!
//Apply styles like this using your CSS strategy of choice with this kind of logic to head cells, data cells, footer cells, etc.
//View the index.css file for more needed styles such as border-collapse: separate
export function getCommonPinningStyles<TData>(column: Column<TData>): React.CSSProperties {
    const isPinned = column.getIsPinned();
    const isLastLeftPinnedColumn = isPinned === 'left' && column.getIsLastColumn('left');
    const isFirstRightPinnedColumn = isPinned === 'right' && column.getIsFirstColumn('right');

    return {
        boxShadow: isLastLeftPinnedColumn
            ? `-2px 0 4px -4px ${colorSystem.neutral[6]} inset`
            : isFirstRightPinnedColumn
              ? `2px 0 4px -4px ${colorSystem.neutral[6]} inset`
              : undefined,
        left: isPinned === 'left' ? `${column.getStart('left')}px` : undefined,
        right: isPinned === 'right' ? `${column.getAfter('right')}px` : undefined,
        opacity: 1,
        position: isPinned ? 'sticky' : 'relative',
        width: column.getSize(),
        zIndex: isPinned ? 1 : 0,
        backgroundColor: isPinned ? colorSystem.neutral.white : undefined,
    };
}

export const equalsAnyFilter: FilterFn<any> = (row, columnId, filterValue) => {
    return filterValue.some((val: any) => isEqual(val, row.getValue(columnId)));
};

export const inMonetaryValueRangeFilter: FilterFn<MonetaryValue | null | undefined> = (row, columnId, filterValue) => {
    let [min, max] = filterValue;

    const rowValue = extractAmountFromMonetaryValue(row.getValue<MonetaryValue | null | undefined>(columnId));
    return rowValue >= (min ?? -Infinity) && rowValue <= (max ?? Infinity);
};

export const inDateRange: FilterFn<string | null | undefined> = (row, columnId, filterValue) => {
    const [min, max] = filterValue;
    const rowValue = row.getValue<string | null | undefined>(columnId);

    // Helper function to parse date strings
    const parseDate = (dateString: string | null | undefined, option: { ifAbsent: number }): number => {
        const d = new Date(Date.parse(dateString ?? ''));
        if (isNaN(d.getTime())) {
            return option.ifAbsent;
        }
        return d.getTime();
    };

    const rowDate = parseDate(rowValue, { ifAbsent: NaN });
    const minDate = parseDate(min, { ifAbsent: -Infinity });
    const maxDate = parseDate(max, { ifAbsent: Infinity });

    return rowDate >= minDate && rowDate <= maxDate;
};
export const monetaryValueSortingFn: SortingFn<MonetaryValue | null | undefined> = (rowA, rowB, columnId) => {
    return compareByNumber(extractAmountFromMonetaryValue)(rowA.getValue(columnId), rowB.getValue(columnId));
};

export const createPositionBasedSortFunction = <T>({
    options,
    getOptionLabel,
}: {
    options: T[] | undefined | Function;
    getOptionLabel: (opt: T) => string;
}): SortingFn<T> => {
    if (Array.isArray(options)) {
        return (rowA, rowB, columnId) => {
            const a = rowA.getValue(columnId) as T;
            const b = rowB.getValue(columnId) as T;

            return options.indexOf(a) - options.indexOf(b);
        };
    } else {
        return (rowA, rowB, columnId) => {
            const a = rowA.getValue(columnId) as T;
            const b = rowB.getValue(columnId) as T;

            return compareByString(getOptionLabel(a), getOptionLabel(b));
        };
    }
};

export const parseOptionsValuesOrFunc = <T, U>(fn: ((arg: U) => T) | T, arg: U): T =>
    fn instanceof Function ? fn(arg) : fn;

export function resetTable<TData>(table: Table<TData>): void {
    const {
        columnFilters = [],
        columnOrder = [],
        columnPinning = {},
        columnVisibility = {},
        globalFilter = '',
        rowSelection = {},
        sorting = [],
    } = table.options.meta?.defautlInitalState ?? {};

    table.setColumnFilters(columnFilters);
    table.setColumnOrder(columnOrder);
    table.setColumnPinning(columnPinning);
    table.setColumnVisibility(columnVisibility);
    table.setGlobalFilter(globalFilter);
    table.setRowSelection(rowSelection);
    table.setSorting(sorting);

    new URLStorage().clear();

    Object.keys(sessionStorage).forEach((key) => {
        const { enablePersistentRowSelection, enableSaveAsDefault } = table.options.meta ?? {};

        if (isPresent(table.options.meta) && key.includes(table.options.meta.columnsKey)) {
            sessionStorage.removeItem(key);
        }

        if (enablePersistentRowSelection && key.includes(enablePersistentRowSelection)) {
            sessionStorage.removeItem(key);
        }

        if (enableSaveAsDefault && key.includes(getSaveAsDefaultConfig(enableSaveAsDefault).key)) {
            sessionStorage.removeItem(key);
        }
    });

    Object.keys(localStorage).forEach((key) => {
        const { enablePersistentRowSelection, enableSaveAsDefault } = table.options.meta ?? {};

        if (isPresent(table.options.meta) && key.includes(table.options.meta.columnsKey)) {
            localStorage.removeItem(key);
        }

        if (enablePersistentRowSelection && key.includes(enablePersistentRowSelection)) {
            localStorage.removeItem(key);
        }

        if (enableSaveAsDefault && key.includes(getSaveAsDefaultConfig(enableSaveAsDefault).key)) {
            localStorage.removeItem(key);
        }
    });
}

export function getSaveAsDefaultConfig(
    enableSaveAsDefault: UseTanStackTableStateProps<unknown>['enableSaveAsDefault'],
): {
    storage: Storage;
    key: string;
    enabled: boolean;
} {
    if (typeof enableSaveAsDefault === 'object' && enableSaveAsDefault !== null) {
        return {
            storage: enableSaveAsDefault.storage === 'local' ? localStorage : sessionStorage,
            key: enableSaveAsDefault.key,
            enabled: true,
        };
    } else if (typeof enableSaveAsDefault === 'string') {
        return {
            storage: sessionStorage,
            key: enableSaveAsDefault,
            enabled: true,
        };
    } else {
        return {
            storage: sessionStorage,
            key: '',
            enabled: false,
        };
    }
}
