import { formatDecimal } from '@luminovo/commons';
import { parse } from '@luminovo/parsers';
import type { ParseResult, Parser } from '../types';
import { applyParser } from './applyParser';

export interface Opts {
    min?: number;
    decimalSeparator?: '.' | ',';
}

export const parseNumber: Parser<number, Opts> = async function (cells, opts, field): Promise<ParseResult<number>> {
    return applyParser({
        cells,
        field,
        formatValue: (num) => formatDecimal(num, { maximumFractionDigits: 10 }),
        parser: parse.number({ decimalSeparator: opts.decimalSeparator ?? '.', errorIf: {} }),
    });
};
