import { useAuth0 } from '@auth0/auth0-react';
import { CenteredLayout } from '@luminovo/design-system';
import * as React from 'react';
import { SpinnerWithBackdrop } from '../Spinners';
import { Auth0UserContext } from './CreateContext';

export function useAuth0User() {
    const user = React.useContext(Auth0UserContext);
    if (!user) {
        throw new Error("Auth0 user is not present, make sure you've setup the Auth0UserContextProvider correctly.");
    }
    return user;
}

export function Auth0UserContextProvider(props: React.PropsWithChildren<{}>) {
    const { isLoading, user, error, loginWithRedirect } = useAuth0();

    if (isLoading) {
        return (
            <CenteredLayout>
                <SpinnerWithBackdrop noBackdrop={true} />
            </CenteredLayout>
        );
    }

    if (error || !user) {
        // eslint-disable-next-line camelcase
        loginWithRedirect({ login_hint: user?.email });
        return (
            <CenteredLayout>
                <SpinnerWithBackdrop noBackdrop={true} />
            </CenteredLayout>
        );
    }

    return <Auth0UserContext.Provider value={user}>{props.children}</Auth0UserContext.Provider>;
}
