/* eslint-disable camelcase */

import * as r from 'runtypes';
import { endpoint } from '../http/endpoint';
import {
    DemandsExportRequestBodyRuntype,
    DemandsExportResponseRuntype,
    MultiStatusRuntype,
    PlannedOrderCreationRequestRuntype,
    SourceListImportArrayRuntype,
    SourceListImportResponseRuntype,
} from './plannedOrderBackendTypes';

export const plannedOrderEndpoints = {
    'POST /demand/import': endpoint({
        description: 'Posts demands',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: PlannedOrderCreationRequestRuntype,
        responseBody: r.Array(MultiStatusRuntype),
    }),
    'POST /demand/export': endpoint({
        description: 'Fetches demands',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: DemandsExportRequestBodyRuntype,
        responseBody: DemandsExportResponseRuntype,
    }),
    'POST /demand/export-csv': endpoint({
        description: 'Fetches as a CSV',
        pathParams: r.Undefined,
        queryParams: r.Record({
            target_date: r.String,
        }),
        requestBody: DemandsExportRequestBodyRuntype,
        //csv string
        responseBody: r.String,
    }),
    'POST /source-list/import': endpoint({
        description: 'Imports a source list',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: SourceListImportArrayRuntype,
        responseBody: SourceListImportResponseRuntype,
    }),
};
