import { getLocale } from '@luminovo/commons';
import { CountryAlpha2Code } from '@luminovo/http-client';

export const translateCountryFromLocale = (country: CountryAlpha2Code, locale: string): string => {
    const name = new Intl.DisplayNames([locale], { type: 'region' }).of(country);
    if (name === undefined) {
        throw new Error(`Country ${country} is not supported in locale ${locale}`);
    } else {
        return name;
    }
};

export const translateCountry = (country: CountryAlpha2Code) => {
    const locale = getLocale();
    return translateCountryFromLocale(country, locale);
};
