import { Trans } from '@lingui/macro';
import { InputAdornment, styled } from '@mui/material';
import React from 'react';
import { colorSystem } from '../../../theme';
import { Flexbox } from '../../Flexbox';
import { Text } from '../../Text';
import { PrimaryButton } from '../../buttons';
import { BaseField } from '../../forms/BaseField';
import { OperatorNumeric, ParameterOption, ParametricSearchState } from '../types';
import { InteractiveMenu } from './InteractiveMenu';

export function MenuOperatorNumberList<T, TAutocompleteState>({
    onSelect,
    onCancel,
    onSearch,
    operator,
    parameter,
    autocompleteState,
    state,
}: {
    state: ParametricSearchState<T, TAutocompleteState>;
    onSelect: (argument: number, label?: string | JSX.Element) => void;
    onCancel: () => void;
    onSearch: (query: string) => void;
    operator: OperatorNumeric<T, TAutocompleteState>;
    parameter: ParameterOption<T, TAutocompleteState>;
    /**
     * The autocomplete state is an abstract type which the `operator.options` function can take as argument
     * to extract the options that are currently available given the user's context.
     */
    autocompleteState?: TAutocompleteState;
}): JSX.Element {
    if (!operator.binned) {
        throw new Error('MenuOperatorNumberList can only be used with binned=true');
    }
    const vals = operator.options(autocompleteState) ?? [];
    const defaultNumber = vals[0]?.value ?? 0;
    const [number, setNumber] = React.useState<number>(defaultNumber);

    const handleSelect = () => onSelect(number, operator.fieldConverter.formatValue(number) + parameter.unit ?? '');

    return (
        <Container
            data-testid="MenuOperatorNumberList"
            flexDirection={'column'}
            onKeyUp={(e) => {
                if (e.key === 'Enter' && number !== undefined) {
                    handleSelect();
                }
                if (e.key === 'Escape') {
                    onCancel();
                }
            }}
        >
            <InteractiveMenu
                inputRef={state.inputRef}
                items={operator.options(autocompleteState).map((item) => {
                    return {
                        label: String(item.label ?? item.value),
                        id: String(item.value),
                        value: item.value,
                        count: item.count,
                    };
                })}
                onSelect={onSelect}
                onSearch={onSearch}
                query={state.query}
                title={
                    <Flexbox alignItems={'center'} justifyContent="space-between">
                        <Text variant="h5">{parameter.label ?? parameter.field}</Text>
                    </Flexbox>
                }
                showIcons={false}
            />

            <Flexbox
                style={{ borderTop: `1px solid ${colorSystem.neutral[1]}` }}
                padding="16px"
                gap={8}
                alignItems={'center'}
            >
                <BaseField
                    converter={operator.fieldConverter}
                    autoFocus
                    fullWidth
                    value={number}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment style={{ marginTop: 2 }} position="start">
                                {operator.op}
                            </InputAdornment>
                        ),
                        endAdornment: parameter.unit ? (
                            <InputAdornment position="end">{parameter.unit}</InputAdornment>
                        ) : undefined,
                    }}
                    onChange={(e) => setNumber(e)}
                />
                <PrimaryButton onClick={() => handleSelect()}>
                    <Trans>Apply</Trans>
                </PrimaryButton>
            </Flexbox>
        </Container>
    );
}

const Container = styled(Flexbox)({
    gap: 8,
    padding: 0,
    background: colorSystem.neutral.white,
    flexDirection: 'column',
    width: '280px',
});
