/* eslint-disable camelcase */
import { throwErrorUnlessProduction } from '@luminovo/commons';
import { PerPcbPanelDTO, PerSourcingScenarioPanelDTO, SourcingScenarioDTO } from '@luminovo/http-client';
import { PanelFormData, PanelFormState, ScopeType } from './types';

const defaultPanelData: PanelFormData = {
    row_count: 1,
    column_count: 1,
    vertical_spacing_in_mm: '0',
    horizontal_spacing_in_mm: '0',
    min_milling_distance_in_mm: '0',
    padding: {
        bottom_in_mm: '0',
        left_in_mm: '0',
        right_in_mm: '0',
        top_in_mm: '0',
    },

    depanelization: 'VCut',
    sourcingScenarioId: undefined,
    panel_preference: null,
    max_x_outs: undefined,
    id: undefined,
};

export const getPanelFormInitialValues = ({
    scope,
    perPcbSavedData = [],
    sourcingScenarios = [],
    perScenarioSavedData = [],
}: {
    scope?: ScopeType;
    perPcbSavedData: PerPcbPanelDTO[];
    sourcingScenarios?: SourcingScenarioDTO[];
    perScenarioSavedData: PerSourcingScenarioPanelDTO[];
}): {
    formValue: PanelFormState;
} => {
    if (scope === 'PerPcb') {
        if (perPcbSavedData !== undefined && perPcbSavedData.length) {
            // There should be exactly one element in perPcbSavedData and it should be of type 'PerPcb'
            if (perPcbSavedData.length > 1) {
                throwErrorUnlessProduction(`Unexpected length ${perPcbSavedData.length} in perPcbSavedData`);
            }
            const { data } = perPcbSavedData[0];

            return {
                formValue: {
                    scope: 'PerPcb',
                    data: [
                        {
                            row_count: data.panel_details.row_count,
                            column_count: data.panel_details.column_count,
                            horizontal_spacing_in_mm: data.panel_details.horizontal_spacing_in_mm,
                            vertical_spacing_in_mm: data.panel_details.vertical_spacing_in_mm,
                            min_milling_distance_in_mm: data.panel_details.min_milling_distance_in_mm,
                            padding: data.panel_details.padding,
                            depanelization: data.panel_details.depanelization,
                            sourcingScenarioId: undefined,
                            panel_preference: data.panel_details.panel_preference ?? null,
                            max_x_outs: data.panel_details.max_x_outs ?? undefined,
                            id: data.panel_details.id,
                        },
                    ],
                },
            };
        } else {
            return {
                formValue: {
                    scope: 'PerPcb',
                    data: [defaultPanelData],
                },
            };
        }
    }

    return {
        formValue: {
            scope: 'PerSourcingScenario',
            data: sourcingScenarios.map((sourcingScenario) => {
                const savedData = perScenarioSavedData.find((s) => s.data.sourcing_scenario === sourcingScenario.id);
                if (!savedData) {
                    return {
                        ...defaultPanelData,
                        sourcingScenarioId: sourcingScenario.id,
                    };
                }
                const data = savedData.data.panel_details;
                return {
                    ...data,
                    sourcingScenarioId: sourcingScenario.id,
                };
            }),
        },
    };
};
