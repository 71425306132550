import { Trans, t } from '@lingui/macro';
import { Dialog, DialogActions, DialogContent, Flexbox, PrimaryButton, Text } from '@luminovo/design-system';
import { PCBFileTypes, PCBV2 } from '@luminovo/http-client';
import { Box } from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import React from 'react';
import { FormProvider } from 'react-hook-form';
import { Prompt } from 'react-router';
import { useFindPcb, useMutationUpdatePcbSpecification } from '../../../resources/pcb/pcbHandlers';
import { ViewContext } from '../../Bom/components/ModuleTableData';
import { FormActionToolbar } from '../components/FormActionToolbar';
import { PcbPdfExtractionModal } from '../components/PcbAnalysis/PcbPdfSpecificationAnalysisModal';
import { PcbCapabilityCheckWidget } from '../components/PcbCapabilityCheckWidget';
import { PcbLoading } from '../components/PcbLoading';
import { PcbPageLayout } from '../components/PcbMainLayout';
import { PcbModuleContextProvider } from '../components/PcbModuleContextProvider';
import { PcbSidebarLayout } from '../components/PcbSidebarLayout';
import { useShouldShowPDFExtraction } from '../utils/pcbFeatureFlags';
import { usePcbFormData } from '../utils/usePcbFormData';
import { CustomStackUpFileContextProvider } from './components/CustomStackUpForm';
import { StackUpForm } from './components/StackUpForm';
import { StackUpRenderer } from './components/StackUpRenderer';
import { StackUpViewMode } from './utils/types';

type PageProps = { assemblyId: string; rfqId: string; isEditable: boolean; viewContext: ViewContext };

function StackUpNavigationBlockerModal({
    showMessageModal,
    setShowMessageModal,
}: {
    showMessageModal: boolean;
    setShowMessageModal: (showMessageModal: boolean) => void;
}) {
    return (
        <Dialog open={showMessageModal} maxWidth={'xs'} onClose={() => {}}>
            <DialogContent>
                <Flexbox flexDirection={'column'} gap={'8px'}>
                    <Text variant="h2" style={{ textAlign: 'center' }}>
                        <Trans>Upload custom stackup</Trans>
                    </Text>
                    <Text>
                        <Trans>
                            Please upload the custom stackup file and save it before you proceed with the next steps
                        </Trans>
                    </Text>
                </Flexbox>
            </DialogContent>
            <DialogActions style={{ justifyContent: 'center' }}>
                <PrimaryButton
                    size="medium"
                    onClick={() => {
                        setShowMessageModal(false);
                    }}
                >
                    <Trans>Continue</Trans>
                </PrimaryButton>
            </DialogActions>
        </Dialog>
    );
}

function StackUp({ pcb, isEditable, rfqId, assemblyId, viewContext }: PageProps & { pcb: PCBV2 }) {
    const [isEditing, setIsEditing] = React.useState(false);
    const [showMessageModal, setShowMessageModal] = React.useState(false);

    // Determine if the PCB has custom stackup files
    const stackUpFiles = pcb.files?.filter((file) => file.fileType.fileType === PCBFileTypes.STACK_UP) ?? [];
    const hasCustomStackUpFiles = stackUpFiles.length > 0;
    const [activeTab, setActiveTab] = React.useState<StackUpViewMode>(() => {
        return hasCustomStackUpFiles ? 'custom' : 'default';
    });

    const { useFormReturn, pcbCapabilities, defaultValues, extractedValues, extractedPcbCapabilities } = usePcbFormData(
        {
            pcb,
        },
    );
    const { mutateAsync, isLoading: isSaving } = useMutationUpdatePcbSpecification({
        pcbId: pcb.id,
        specificationId: pcb.specifications[0].id,
    });

    const { reset, setValue } = useFormReturn;

    const handleCancel = async () => {
        reset(defaultValues);
        setActiveTab(hasCustomStackUpFiles ? 'custom' : 'default');
        setIsEditing(false);
    };

    const handleSubmit = useFormReturn.handleSubmit(async (form) => {
        // If the active tab is 'custom', check that there are stackup files before saving the form
        // else show the modal
        if (activeTab === 'custom' && !hasCustomStackUpFiles) {
            setShowMessageModal(true);
            return;
        }

        const mutateData = await mutateAsync({ settings: form });
        enqueueSnackbar(t`Stackup specification saved.`, { variant: 'success' });
        setIsEditing(false);
        return mutateData;
    });

    const handleEdit = async () => {
        setIsEditing(true);
    };

    const handleExtractionModalClose = (value: boolean) => {
        if (value) {
            setIsEditing(true);
            setValue('board', extractedValues.board, { shouldDirty: true });
            setValue('layerStack', extractedValues.layerStack, { shouldDirty: true });
        }
    };

    const { shouldShowPDFExtraction } = useShouldShowPDFExtraction();

    return (
        <PcbSidebarLayout viewContext={viewContext} assemblyId={assemblyId} rfqId={rfqId} pcb={pcb}>
            <PcbModuleContextProvider>
                <FormProvider {...useFormReturn}>
                    {shouldShowPDFExtraction && (
                        <PcbPdfExtractionModal
                            pcb={pcb}
                            handleClose={handleExtractionModalClose}
                            extractedPcbCapabilities={extractedPcbCapabilities}
                        />
                    )}
                    <Prompt
                        message={t`This form has unsaved changes. Are you sure you want to lose your progress?`}
                        when={useFormReturn.formState.isDirty}
                    />
                    <FormActionToolbar
                        pageTitle={t`Stackup`}
                        formId={'pcb-stackup-form'}
                        disabled={isSaving}
                        isEditing={isEditing}
                        isEditable={isEditable}
                        submitButtonOptions={{
                            onClick: handleSubmit,
                        }}
                        cancelButtonOptions={{
                            onClick: handleCancel,
                        }}
                        editButtonOptions={{
                            onClick: handleEdit,
                        }}
                    />
                    <CustomStackUpFileContextProvider>
                        <PcbPageLayout>
                            <StackUpForm
                                pcb={pcb}
                                isEditing={isEditing}
                                pcbCapabilities={pcbCapabilities}
                                activeTab={activeTab}
                                setActiveTab={setActiveTab}
                            />

                            <Box
                                style={{
                                    height: 'calc(100vh - 155px)',
                                    boxSizing: 'border-box',
                                }}
                                position={'relative'}
                            >
                                <StackUpRenderer
                                    pcb={pcb}
                                    isEditing={isEditing}
                                    assemblyId={assemblyId}
                                    activeTab={activeTab}
                                    rfqId={rfqId}
                                />
                                {activeTab === 'default' && (
                                    <PcbCapabilityCheckWidget
                                        pcb={pcb}
                                        rfqId={rfqId}
                                        assemblyId={assemblyId}
                                        isRfqEditable={isEditable}
                                    />
                                )}
                            </Box>
                        </PcbPageLayout>
                    </CustomStackUpFileContextProvider>

                    <StackUpNavigationBlockerModal
                        showMessageModal={showMessageModal}
                        setShowMessageModal={setShowMessageModal}
                    />
                </FormProvider>
            </PcbModuleContextProvider>
        </PcbSidebarLayout>
    );
}

function StackUpWrapper({ assemblyId, ...restPageProps }: PageProps) {
    const { data: pcb } = useFindPcb({ assemblyId });

    if (!pcb) {
        return null;
    }

    return <StackUp assemblyId={assemblyId} pcb={pcb} {...restPageProps} />;
}

export function StackUpTab(props: PageProps) {
    return (
        <PcbLoading {...props}>
            <StackUpWrapper {...props} />
        </PcbLoading>
    );
}
