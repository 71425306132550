import { t } from '@lingui/macro';
import { SupplierAndStockLocationDTO, SupplierTag } from '@luminovo/http-client';
import type { ParsedValue, Parser } from '../types';

const allDigits = /^\d+$/;

export interface Opts {
    /**
     * List of suppliers to compare the supplier number against.
     */
    suppliers: SupplierAndStockLocationDTO[];
    /**
     * If true, a warning will be shown if the supplier number is currently linked to a system supplier.
     */
    warnOnSystemSupplier?: boolean;
}

export const parseSupplierNumber: Parser<string, Opts> = async (
    [cell],
    { suppliers, warnOnSystemSupplier = true },
    field,
) => {
    cell = cell.trim();
    const alternatives = suppliers.map(toParsedValue);

    if (cell.length === 0 && field.defaultValue) {
        // Honestly, I'm not sure if we will ever support default values for supplier numbers
        // but I'm keeping it here for consistency and completeness
        return {
            status: 'done',
            value: field.defaultValue,
            message: t`Using default value`,
            alternatives,
        };
    }

    if (cell.length === 0) {
        return {
            status: 'error',
            message: t`The supplier number is required`,
            alternatives,
        };
    }

    const found = alternatives.find((alt) => {
        const supplierNumberMatch = alt.label && alt.label === cell;
        return supplierNumberMatch;
    });

    if (found && found.isSystem && warnOnSystemSupplier) {
        return {
            status: 'warning',
            value: found,
            existing: false,
            alternatives,
            message: `This supplier number is currently linked to the system supplier ${found.description}. Importing this row will insert a new supplier and unlink the number from the system supplier.`,
        };
    }

    if (found) {
        return {
            status: 'done',
            value: found,
            existing: true,
            alternatives,
        };
    }

    if (!allDigits.test(cell)) {
        return {
            status: 'warning',
            message: t`The supplier number is usually a number. Are you sure this is correct?`,
            alternatives,
            value: {
                id: cell,
                label: cell,
                description: t`NEW`,
                existing: false,
            },
        };
    }

    return {
        value: {
            id: cell,
            label: cell,
            description: t`NEW`,
            existing: false,
        },
        status: 'done',
        alternatives,
    };
};

function toParsedValue(supplier: SupplierAndStockLocationDTO): ParsedValue<string> & { isSystem: boolean } {
    return {
        id: supplier.supplier_number ?? '',
        label: String(supplier.supplier_number),
        description: supplier.supplier.name,
        existing: true,
        href: 'https://luminovo.luminovo.com/suppliers/' + supplier.id,
        isSystem: supplier.tags.some((s) => s.tag === SupplierTag.System),
    };
}
