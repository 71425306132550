import { Trans, t } from '@lingui/macro';
import { DiamondIcon, Flexbox, Tag, Text } from '@luminovo/design-system';
import { CandidateCpn, CustomComponentFull, CustomFullPart, RfqContext } from '@luminovo/http-client';
import { Typography } from '@mui/material';
import { CSSProperties, memo } from 'react';
import { colorSystem } from '../../../themes';
import { highlightCpnRevisionMatches } from '../../../utils/highlighting/cpnHighlighting';
import { highlightIPNMatches } from '../../../utils/highlighting/ipnHighlighting';
import { HighlightViaIpn, IpnCardSection } from '../Ipn/IpnCard';
import { LightTypography, useIpnTooltip } from '../Ipn/IpnCardFunctions';
import { PreferredTags } from '../Ipn/PreferredTags';
import { PartCardLayout } from '../PartCardLayout';
import { DetailsButton, HighlightedValue } from '../PartSpecificationCardFields';
import { useCustomComponentDrawer } from './useCustomComponentDrawer';

interface CustomComponentCardProps {
    component: CustomComponentFull;
    rfqContext: RfqContext;
    cardStyle?: CSSProperties;
    searchQuery?: string;
    collapsed?: boolean;
    isFuzzyResult?: boolean;
    candidateCpns?: CandidateCpn[];
}

const CustomComponentCardNotMemoized = ({
    component,
    rfqContext,
    cardStyle,
    searchQuery = '',
    collapsed = false,
    isFuzzyResult = false,
    candidateCpns,
}: CustomComponentCardProps): JSX.Element => {
    const { id, cpns, state, matches, is_restricted_to_customers: isRestricted, description } = component;
    const { openDrawer } = useCustomComponentDrawer();
    const ipnTooltip = useIpnTooltip();
    const ipnFragments = highlightIPNMatches(id, searchQuery);
    const hasLinkedParts = matches.length > 0;
    const isPartActive = state === 'Active';

    const { cpnFragments, revisionFragments } = highlightCpnRevisionMatches({
        cpns,
        searchQuery,
        candidateCpns,
    });

    return (
        <PartCardLayout
            cardStyle={cardStyle}
            disableOnHover={true}
            tooltip={ipnTooltip}
            headerSection={
                <>
                    <Flexbox alignItems="center" gap={8}>
                        <LightTypography variant="h5">IPN</LightTypography>
                        <HighlightedValue fragments={ipnFragments} />
                        {cpns.length > 0 && (
                            <Flexbox gap={4} alignItems="center">
                                <LightTypography variant="h5">CPN</LightTypography>
                                <HighlightedValue fragments={cpnFragments} />
                                {revisionFragments.length > 0 && <Text>•</Text>}
                                <HighlightedValue fragments={revisionFragments} />
                                {cpns.length > 1 && (
                                    <Text variant="body-small" style={{ marginLeft: '4px' }}>
                                        +{cpns.length - 1}
                                    </Text>
                                )}
                            </Flexbox>
                        )}
                    </Flexbox>
                    <Flexbox gap={8} alignItems="center">
                        {isFuzzyResult && <Tag color="yellow" attention="low" label={t`Fuzzy`} />}
                        {isPartActive ? (
                            <>
                                <Flexbox gap={4}>
                                    {isRestricted && <Tag color="violet" attention="low" label={'Restricted'} />}
                                    <PreferredTags part={component} />
                                    {!hasLinkedParts && (
                                        <Tag color="yellow" attention="low" label={t`No linked parts`} />
                                    )}
                                </Flexbox>
                            </>
                        ) : (
                            <Tag color="red" attention="low" label={t`Removed`} />
                        )}
                    </Flexbox>
                </>
            }
            headerHover={<DetailsButton handleClick={() => openDrawer({ componentId: id, rfqContext })} />}
            footerSection={
                <>
                    <Flexbox flexDirection={'column'} paddingY={'8px'} gap={16}>
                        {hasLinkedParts ? (
                            <IpnCardSection
                                title={t`Linked parts`}
                                icon={<DiamondIcon fontSize="small" color={colorSystem.green[5]} />}
                            >
                                <Flexbox flexDirection={'column'} gap={8}>
                                    {matches.map((part, i) => {
                                        return <CustomLinkedPart part={part} key={i} query={searchQuery} />;
                                    })}
                                </Flexbox>
                            </IpnCardSection>
                        ) : (
                            <LightTypography variant="subtitle1">
                                <Trans>No parts could be linked automatically based on synced ERP data.</Trans>
                            </LightTypography>
                        )}
                        {!hasLinkedParts && !collapsed && component.description !== '' && (
                            <IpnCardSection
                                title={t`Synced ERP data`}
                                icon={<DiamondIcon fontSize="small" color={colorSystem.neutral[5]} />}
                            >
                                <Flexbox flexDirection={'column'} gap={8}>
                                    <Flexbox flexDirection={'column'} gap={4}>
                                        <Typography variant="subtitle1">
                                            <HighlightViaIpn text={description} query={searchQuery} />
                                        </Typography>
                                    </Flexbox>
                                </Flexbox>
                            </IpnCardSection>
                        )}
                    </Flexbox>
                </>
            }
        />
    );
};

const CustomLinkedPart = ({ part, query }: { part: CustomFullPart; query: string }): JSX.Element => {
    const { type, description } = part;

    if (type && description) {
        return (
            <Typography variant="subtitle1" color={'textPrimary'}>
                <HighlightViaIpn text={type.name} query={query} />, <HighlightViaIpn text={description} query={query} />
            </Typography>
        );
    }

    return (
        <Typography variant="subtitle1" color={'textPrimary'}>
            <HighlightViaIpn text={type.name} query={query} />
            <HighlightViaIpn text={description ?? ''} query={query} />
        </Typography>
    );
};

export const CustomComponentCard = memo(CustomComponentCardNotMemoized);
