/* eslint-disable spellcheck/spell-checker */
import { detectLanguageEnum } from '@luminovo/commons';
import { colorSystem, createTheme } from '@luminovo/design-system';

export { HEADINGS_WEIGHT, NOTO_SANS, colorSystem } from '@luminovo/design-system';

export const TOOLBAR_HEIGHT = '64px';

export const SPACING_EXTRA_SMALL = '0.25rem';
export const SPACING_SMALL = '0.5rem';
export const SPACING_MEDIUM = '1rem';

export const themeLuminovo = createTheme(detectLanguageEnum());

export const CARD_SHADOW = `none`;
//this is Luminovo GREY2 with 10% saturation

export const GREY_BORDER = `1px solid ${colorSystem.neutral[2]}`;

export const PADDING_Y_DASHBOARD_BOX = 3;
export const PADDING_X_DASHBOARD_BOX = 6;

export const CIRCULAR_PROGRESS_BUTTON_SIZE = 24;

export const PART_SPECIFICATION_CARD_WIDTH = '30rem';
export const PART_CARD_ROW_HEIGHT = '32px';
export const WIDTH_DASHBOARD_MENU = '11.3rem';
