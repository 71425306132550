import { HistoryRecordResponseDTO } from '@luminovo/http-client';
import Assembly, { AssemblyIcon } from './Assembly';
import BomItemDesignators, { BomItemDesignatorsTimelineIcon } from './BomItemDesignators';
import Comment, { CommentIcon } from './Comment';
import CustomComponent, { CustomComponentTimelineIcon } from './CustomComponent';
import CustomPart, { CustomPartTimelineIcon } from './CustomPart';
import DesignItemsExtracted, { DesignItemsExtractedIcon } from './DesignItemsExtracted';
import Designators, { DesignatorsTimelineIcon } from './Designators';
import DoNotPlace, { DoNotPlaceTimeLineDot } from './DoNotPlace';
import File, { FileIcon } from './File';
import GenericPart, { GenericPartTimelineIcon } from './GenericPart';
import IgnoreManufacturerDataWarning, { IgnoreManufacturerDataWarningLineDot } from './IgnoreManufacturerDataWarning';
import InternalPartNumber, { InternalPartNumberTimelineIcon } from './Ipn';
import ManufacturerFree, { ManufactureFreeTimelineIcon } from './ManufacturerFree';
import Notes, { NotesTimelineIcon } from './Notes';
import OffTheShelfPart, { OffTheShelfPartTimelineIcon } from './OffTheShelfPart';
import PCB, { PCBIcon } from './PCB';
import ParentAssembly, { ParentAssemblyIcon } from './ParentAssembly';
import QuantityUnit, { QuantityUnitTimelineIcon } from './QuantityUnit';
import SourcedBy, { SourcedByTimelineIcon } from './SourcedBy';
import SubAssembly, { SubAssemblyIcon } from './SubAssembly';
import SubAssemblyQuantityChanged from './SubAssemblyQuantityChanged';
import { TimelineEditIcon, TimelineItemWrapper } from './Utils';

export default function TimeLineItem({
    data,
    isTheLastElement,
}: {
    data: HistoryRecordResponseDTO;
    isTheLastElement: boolean;
}) {
    switch (data.data.type) {
        case 'BomItem':
            return (
                <TimelineItemWrapper
                    breadCrumb={data.bread_crumb}
                    timelineIcon={<BomItemDesignatorsTimelineIcon operation={data.operation} />}
                    isLastItem={isTheLastElement}
                    createdAt={data.created_at}
                >
                    <BomItemDesignators
                        designators={data.data.data.designators}
                        operation={data.operation}
                        actor={data.created_by}
                    />
                </TimelineItemWrapper>
            );
        case 'Comment':
            return (
                <TimelineItemWrapper
                    breadCrumb={data.bread_crumb}
                    timelineIcon={<CommentIcon operation={data.operation} />}
                    isLastItem={isTheLastElement}
                    createdAt={data.created_at}
                >
                    <Comment actor={data.created_by} data={data.data.data} operation={data.operation} />
                </TimelineItemWrapper>
            );
        case 'CustomPart':
            return (
                <TimelineItemWrapper
                    breadCrumb={data.bread_crumb}
                    isLastItem={isTheLastElement}
                    timelineIcon={
                        <CustomPartTimelineIcon operation={data.operation} approvalStatus={data.data.data.approval} />
                    }
                    createdAt={data.created_at}
                >
                    <CustomPart operation={data.operation} actor={data.created_by} data={data.data.data} />
                </TimelineItemWrapper>
            );
        case 'CustomComponent':
            return (
                <TimelineItemWrapper
                    breadCrumb={data.bread_crumb}
                    isLastItem={isTheLastElement}
                    timelineIcon={
                        <CustomComponentTimelineIcon
                            operation={data.operation}
                            approvalStatus={data.data.data.approval}
                        />
                    }
                    createdAt={data.created_at}
                >
                    <CustomComponent operation={data.operation} actor={data.created_by} data={data.data.data} />
                </TimelineItemWrapper>
            );
        case 'Dnp':
            return (
                <TimelineItemWrapper
                    breadCrumb={data.bread_crumb}
                    timelineIcon={<DoNotPlaceTimeLineDot />}
                    isLastItem={isTheLastElement}
                    createdAt={data.created_at}
                >
                    <DoNotPlace flag={data.data.data} operation={data.operation} actor={data.created_by} />
                </TimelineItemWrapper>
            );
        case 'IgnoreManufacturerDataWarning':
            return (
                <TimelineItemWrapper
                    breadCrumb={data.bread_crumb}
                    timelineIcon={<IgnoreManufacturerDataWarningLineDot />}
                    isLastItem={isTheLastElement}
                    createdAt={data.created_at}
                >
                    <IgnoreManufacturerDataWarning
                        flag={data.data.data}
                        operation={data.operation}
                        actor={data.created_by}
                    />
                </TimelineItemWrapper>
            );
        case 'GenericPart':
            return (
                <TimelineItemWrapper
                    breadCrumb={data.bread_crumb}
                    timelineIcon={
                        <GenericPartTimelineIcon operation={data.operation} approvalStatus={data.data.data.approval} />
                    }
                    isLastItem={isTheLastElement}
                    createdAt={data.created_at}
                >
                    <GenericPart operation={data.operation} data={data.data.data} actor={data.created_by} />
                </TimelineItemWrapper>
            );

        case 'Ipn':
            return (
                <TimelineItemWrapper
                    breadCrumb={data.bread_crumb}
                    isLastItem={isTheLastElement}
                    timelineIcon={<InternalPartNumberTimelineIcon data={data.data.data} operation={data.operation} />}
                    createdAt={data.created_at}
                >
                    <InternalPartNumber data={data.data.data} actor={data.created_by} operation={data.operation} />
                </TimelineItemWrapper>
            );
        case 'ManufacturerFree':
            return (
                <TimelineItemWrapper
                    breadCrumb={data.bread_crumb}
                    timelineIcon={<ManufactureFreeTimelineIcon marked={data.data.data.marked} />}
                    isLastItem={isTheLastElement}
                    createdAt={data.created_at}
                >
                    <ManufacturerFree flag={data.data.data} actor={data.created_by} operation={data.operation} />
                </TimelineItemWrapper>
            );

        case 'Note':
            return (
                <TimelineItemWrapper
                    breadCrumb={data.bread_crumb}
                    timelineIcon={<NotesTimelineIcon operation={data.operation} />}
                    isLastItem={isTheLastElement}
                    createdAt={data.created_at}
                >
                    <Notes note={data.data.data} actor={data.created_by} operation={data.operation} />
                </TimelineItemWrapper>
            );
        case 'OtsPart':
            return (
                <TimelineItemWrapper
                    breadCrumb={data.bread_crumb}
                    timelineIcon={
                        <OffTheShelfPartTimelineIcon
                            operation={data.operation}
                            approvalStatus={data.data.data.approval}
                        />
                    }
                    isLastItem={isTheLastElement}
                    createdAt={data.created_at}
                >
                    <OffTheShelfPart actor={data.created_by} data={data.data.data} operation={data.operation} />
                </TimelineItemWrapper>
            );
        case 'SourcedBy':
            return (
                <TimelineItemWrapper
                    breadCrumb={data.bread_crumb}
                    timelineIcon={<SourcedByTimelineIcon operation={data.operation} />}
                    isLastItem={isTheLastElement}
                    createdAt={data.created_at}
                >
                    <SourcedBy actor={data.created_by} flag={data.data.data} operation={data.operation} />
                </TimelineItemWrapper>
            );
        case 'Unit':
            return (
                <TimelineItemWrapper
                    breadCrumb={data.bread_crumb}
                    timelineIcon={<QuantityUnitTimelineIcon operation={data.operation} />}
                    isLastItem={isTheLastElement}
                    createdAt={data.created_at}
                >
                    <QuantityUnit operation={data.operation} data={data.data.data} actor={data.created_by} />
                </TimelineItemWrapper>
            );
        case 'Designators':
            return (
                <TimelineItemWrapper
                    breadCrumb={data.bread_crumb}
                    timelineIcon={<DesignatorsTimelineIcon operation={data.operation} />}
                    isLastItem={isTheLastElement}
                    createdAt={data.created_at}
                >
                    <Designators
                        designators={data.data.data.designators}
                        operation={data.operation}
                        actor={data.created_by}
                    />
                </TimelineItemWrapper>
            );
        case 'Assembly':
            return (
                <TimelineItemWrapper
                    breadCrumb={data.bread_crumb}
                    timelineIcon={<AssemblyIcon operation={data.operation} />}
                    isLastItem={isTheLastElement}
                    createdAt={data.created_at}
                >
                    <Assembly operation={data.operation} actor={data.created_by} assemblyName={data.data.data} />
                </TimelineItemWrapper>
            );
        case 'ParentAssembly':
            return (
                <TimelineItemWrapper
                    breadCrumb={data.bread_crumb}
                    timelineIcon={<ParentAssemblyIcon operation={data.operation} />}
                    isLastItem={isTheLastElement}
                    createdAt={data.created_at}
                >
                    <ParentAssembly assemblyName={data.data.data} actor={data.created_by} operation={data.operation} />
                </TimelineItemWrapper>
            );
        case 'SubAssembly':
            return (
                <TimelineItemWrapper
                    breadCrumb={data.bread_crumb}
                    timelineIcon={<SubAssemblyIcon operation={data.operation} />}
                    isLastItem={isTheLastElement}
                    createdAt={data.created_at}
                >
                    <SubAssembly operation={data.operation} actor={data.created_by} assemblyName={data.data.data} />
                </TimelineItemWrapper>
            );
        case 'File':
            return (
                <TimelineItemWrapper
                    breadCrumb={data.bread_crumb}
                    timelineIcon={<FileIcon operation={data.operation} />}
                    isLastItem={isTheLastElement}
                    createdAt={data.created_at}
                >
                    <File
                        actor={data.created_by}
                        operation={data.operation}
                        fileType={data.data.data.file_type}
                        fileName={data.data.data.file_name ?? undefined}
                    />
                </TimelineItemWrapper>
            );
        case 'PCB':
            return (
                <TimelineItemWrapper
                    breadCrumb={data.bread_crumb}
                    timelineIcon={<PCBIcon operation={data.operation} />}
                    isLastItem={isTheLastElement}
                    createdAt={data.created_at}
                >
                    <PCB actor={data.created_by} operation={data.operation} />
                </TimelineItemWrapper>
            );
        case 'DesignItemsExtracted':
            return (
                <TimelineItemWrapper
                    breadCrumb={data.bread_crumb}
                    timelineIcon={<DesignItemsExtractedIcon />}
                    isLastItem={isTheLastElement}
                    createdAt={data.created_at}
                >
                    <DesignItemsExtracted numberOfDesignItems={data.data.data} />
                </TimelineItemWrapper>
            );

        case 'SubAssemblyQuantityChanged':
            return (
                <TimelineItemWrapper
                    breadCrumb={data.bread_crumb}
                    timelineIcon={<TimelineEditIcon />}
                    isLastItem={isTheLastElement}
                    createdAt={data.created_at}
                >
                    <SubAssemblyQuantityChanged data={data.data.data} actor={data.created_by} />
                </TimelineItemWrapper>
            );

        default:
            return null;
    }
}
