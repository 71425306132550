import { assertUnreachable, uniqBy } from '@luminovo/commons';
import {
    ApprovalStatus,
    CustomComponentFull,
    CustomFullPart,
    GenericFullPart,
    IpnWithMatchesFullPart,
    OtsFullPart,
    PartSpecificationTypes,
    SolutionConfigurationSourcingDTO,
} from '@luminovo/http-client';

export function extractStandardPartOptions(
    { specification }: Pick<SolutionConfigurationSourcingDTO, 'specification'>,
    options: { filterByStatus: ApprovalStatus | false },
): Array<GenericFullPart | IpnWithMatchesFullPart | OtsFullPart> {
    const { filterByStatus } = options;

    if (!specification?.data) {
        return [];
    }

    if (specification.type === PartSpecificationTypes.Custom) {
        return [];
    }

    if (specification.type === PartSpecificationTypes.OffTheShelf) {
        return uniqBy(
            specification.data.part_options
                .filter((option) => filterByStatus === false || option.approval_status === filterByStatus)
                .map((option) => option.part.data),
            (part) => part.id,
        );
    }

    assertUnreachable(specification);
}

export function extractCustomPartOptions(
    { specification }: Pick<SolutionConfigurationSourcingDTO, 'specification'>,
    options: { filterByStatus: ApprovalStatus | false },
): Array<CustomFullPart | CustomComponentFull> {
    const { filterByStatus } = options;

    if (!specification?.data) {
        return [];
    }

    if (specification.type === PartSpecificationTypes.OffTheShelf) {
        return [];
    }

    if (specification.type === PartSpecificationTypes.Custom) {
        return uniqBy(
            specification.data
                .filter((option) => filterByStatus === false || option.approval_status === filterByStatus)
                .map((option) => option.part.data),
            (part) => part.id,
        );
    }

    assertUnreachable(specification);
}

export function extractPartOptions(
    { specification }: Pick<SolutionConfigurationSourcingDTO, 'specification'>,
    options: { filterByStatus: ApprovalStatus | false },
): Array<GenericFullPart | IpnWithMatchesFullPart | OtsFullPart | CustomFullPart | CustomComponentFull> {
    return [
        ...extractStandardPartOptions({ specification }, options),
        ...extractCustomPartOptions({ specification }, options),
    ];
}
