import { BrokenImageIcon, colorSystem, Flexbox } from '@luminovo/design-system';
import { CircularProgress, Typography } from '@mui/material';
import React from 'react';

export const PreviewLoading: React.FunctionComponent = () => {
    return (
        <div
            style={{
                minHeight: '100%',
                width: '100%',
                background: colorSystem.neutral[3],
                placeItems: 'center',
                display: 'grid',
            }}
        >
            <CircularProgress />
        </div>
    );
};

type PlaceholdersProps = {
    title: string;
    description: string;
    children?: React.ReactNode;
};
export const PreviewLoadingError: React.FunctionComponent<PlaceholdersProps> = ({ title, description, children }) => {
    return (
        <Flexbox width={'100%'} height={'100%'} alignItems={'center'} justifyContent={'center'}>
            <Flexbox flexDirection={'column'} gap={16} alignItems="center" width="490px" textAlign={'center'}>
                <BrokenImageIcon />
                <Typography variant="h2" style={{ color: colorSystem.neutral[7] }}>
                    {title}
                </Typography>
                <Typography variant="body1" style={{ color: colorSystem.neutral[7] }}>
                    {description}
                </Typography>
                {children}
            </Flexbox>
        </Flexbox>
    );
};
