import { Flexbox, SecondaryButton } from '@luminovo/design-system';
import {
    AssemblyImportDTO,
    PlannedOrdersCreationRequestDTO,
    SitePostDTO,
    SupplierAndStockLocationPostDTO,
} from '@luminovo/http-client';
import { useHttpMutation } from '../../../../resources/mutation/useHttpMutation';
import { mockDemandEnvironment } from './mockDemandEnvironment/mockDemandEnvironment';
import { MockDemandEnvironment } from './mockTypes';
import { sourceListMock } from './sourceList/sourceListMocks';

const PostAssembliesButton = ({ assemblies }: { assemblies: AssemblyImportDTO }) => {
    const { mutateAsync } = useHttpMutation('POST /assemblies/import', {
        snackbarMessage: 'Assemblies imported successfully',
    });

    return (
        <SecondaryButton
            onClick={() =>
                mutateAsync(
                    {
                        requestBody: assemblies,
                    },
                    {},
                )
            }
        >
            Import assemblies
        </SecondaryButton>
    );
};

const PostDemandsButton = ({ demands }: { demands: PlannedOrdersCreationRequestDTO['items'] }) => {
    const { mutateAsync } = useHttpMutation('POST /demand/import', {
        snackbarMessage: 'Demand imported successfully',
    });

    return (
        <SecondaryButton
            onClick={() =>
                mutateAsync(
                    {
                        requestBody: { items: demands, context: '' },
                    },
                    {},
                )
            }
        >
            Import demand
        </SecondaryButton>
    );
};

const PostSupplier = ({ suppliers }: { suppliers: SupplierAndStockLocationPostDTO[] }) => {
    const { mutateAsync } = useHttpMutation('POST /suppliers-and-stock-locations', {
        snackbarMessage: 'Supplier imported successfully',
    });

    return (
        <SecondaryButton
            onClick={() => {
                suppliers.forEach((supplier) => {
                    mutateAsync(
                        {
                            requestBody: supplier,
                        },
                        {},
                    );
                });
            }}
        >
            New supplier(s)
        </SecondaryButton>
    );
};

const PostSite = ({ sites }: { sites: SitePostDTO[] }) => {
    const { mutateAsync } = useHttpMutation('POST /organization-settings/sites', {
        snackbarMessage: 'Site imported successfully',
    });

    return (
        <SecondaryButton
            onClick={() => {
                sites.forEach((site) => {
                    mutateAsync(
                        {
                            requestBody: site,
                        },
                        {},
                    );
                });
            }}
        >
            Import sites
        </SecondaryButton>
    );
};

const PostSourceList = () => {
    const { mutateAsync } = useHttpMutation('POST /source-list/import', {
        snackbarMessage: 'Source list imported successfully',
    });

    return (
        <SecondaryButton
            onClick={() =>
                mutateAsync(
                    {
                        requestBody: sourceListMock,
                    },
                    {},
                )
            }
        >
            Import source list
        </SecondaryButton>
    );
};

export const ButtonsForMocks = ({
    demandEnvironment = mockDemandEnvironment,
}: {
    demandEnvironment?: MockDemandEnvironment;
}) => {
    const { suppliers, sites, demands, assemblies } = demandEnvironment;
    return (
        <Flexbox flexDirection={'row'} gap={4}>
            <PostSite sites={sites} />
            <PostSupplier suppliers={suppliers} />
            <PostAssembliesButton assemblies={assemblies} />
            <PostDemandsButton demands={demands} />
            <PostSourceList />
        </Flexbox>
    );
};
