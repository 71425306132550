import { t } from '@lingui/macro';
import { assertUnreachable } from '@luminovo/commons';
import { Checkbox, Tag, TanStackTable, Tooltip, createColumnHelper, useTanStackTable } from '@luminovo/design-system';
import React from 'react';
import { formatImportStatus } from '../../../../model/formatImportStatus';
import { formatLinkStatus } from '../../../../model/formatLinkStatus';
import { getCellValue } from '../../../../model/getCellValue';
import { ImporterRow, LinkStatus } from '../../../../types';
import { useUniversalImporter } from '../../context';

export function TablePreview({ rows }: { rows: ImporterRow[] }): JSX.Element {
    const { state } = useUniversalImporter();
    const { config } = state;

    const fields = config.fields;

    const columnFields = React.useMemo(
        () =>
            fields.map((field) => {
                return columnHelper.enum((row) => getCellValue(row, field)?.label, {
                    id: field.id,
                    label: () => field.label ?? field.id,
                    size: 200,
                    getOptionLabel: (value) => value ?? '',
                });
            }),
        [fields],
    );

    const statusColumn = state.importerTable?.getRows().some((r) => r.import) ? columnImportStatus : columnStatus;

    const columns = React.useMemo(() => [columnSelection, ...columnFields, statusColumn], [columnFields, statusColumn]);

    const tableState = useTanStackTable({
        columns,
        data: rows,
        enableColumnOrdering: false,
    });

    return <TanStackTable size="small" table={tableState.table} overrides={{}} enableMenuBar={false} />;
}

const columnHelper = createColumnHelper<ImporterRow>();

const statuses: LinkStatus[] = ['skipped', 'insert', 'update'];

const columnStatus = columnHelper.enum(
    (row) => {
        return row.record?.action ?? 'skipped';
    },
    {
        quickFilters: statuses.map((status) => ({
            value: [status],
            label: () => formatLinkStatus(status),
            showCount: true,
        })),
        options: statuses,
        label: () => t`Status`,
        id: 'status',
        enableColumnFilter: true,
        enableSorting: false,
        initialPinning: 'right',
        cell: (opt) => {
            const importStatus = opt.row.original.import;
            if (importStatus) {
                if (importStatus.success) {
                    return <Tag attention="low" color="green" label={'Success'} />;
                }
                return (
                    <Tooltip placement="left" title={importStatus.message}>
                        <span>
                            <Tag attention="low" color="red" label={'Error'} />
                        </span>
                    </Tooltip>
                );
            }

            const status = opt.getValue();

            const statusIcon = () => {
                if (status === 'skipped') {
                    return <Tag attention="low" color="neutral" label={'Excluded'} />;
                }
                if (status === 'insert') {
                    return <Tag attention="low" color="violet" label={'New'} />;
                }
                if (status === 'update') {
                    return <Tag attention="low" color="blue" label={'Update'} />;
                }
                assertUnreachable(status);
            };

            return <>{statusIcon()}</>;
        },
        getOptionLabel: (status: LinkStatus) => {
            return formatLinkStatus(status);
        },
        size: 100,
    },
);

const importStatuses = [true, false, undefined];

const columnImportStatus = columnHelper.enum(
    (row) => {
        return row.import?.success;
    },
    {
        quickFilters: importStatuses.map((status) => ({
            value: [status],
            label: () => formatImportStatus(status),
            showCount: true,
        })),
        options: importStatuses,
        label: () => t`Status`,
        id: 'status',
        enableColumnFilter: true,
        enableSorting: false,
        initialPinning: 'right',
        cell: (opt) => {
            const importStatus = opt.row.original.import;
            if (!importStatus) {
                return <></>;
            }
            if (importStatus.success) {
                return <Tag attention="low" color="green" label={'Success'} />;
            }
            return (
                <Tooltip placement="left" title={importStatus.message}>
                    <span>
                        <Tag attention="low" color="red" label={'Error'} />
                    </span>
                </Tooltip>
            );
        },
        getOptionLabel: (status: (typeof importStatuses)[number]) => {
            return formatImportStatus(status);
        },
        size: 100,
    },
);

const columnSelection = columnHelper.enum((row) => (row.record?.action ?? 'skipped') === 'skipped', {
    id: 'Include',
    size: 50,
    label: () => t`Include`,
    getOptionLabel: (skipped: boolean) => (skipped ? t`Exclude` : t`Include`),
    initialPinning: 'left',
    enableColumnFilter: false,
    enableSorting: false,
    cell: function Render({ row, getValue }) {
        const index = row.original.index;
        const { dispatch, state } = useUniversalImporter();
        const skipCurrentRow = getValue();

        return (
            <>
                <Checkbox
                    size="small"
                    disabled={row.original.status === 'error' || row.original.status === 'skipped'}
                    checked={!skipCurrentRow}
                    onChange={() => {
                        dispatch({ type: 'setImporterTable', table: state.importerTable?.skipRow(index) });
                    }}
                />
            </>
        );
    },
    header: () => t`Include`,
});
