import { SingleDemand } from '../mockTypes';

export const customerDemands: SingleDemand[] = [
    {
        assemblyIpn: 'Assembly-1',
        quantity: 436,
        deliveryDate: '2022-12-12',
        productionStartDate: '2022-11-16',
        supplier: { kind: 'external', supplier_number: '62093' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-1',
        quantity: 960,
        deliveryDate: '2023-01-30',
        productionStartDate: '2023-01-05',
        supplier: { kind: 'external', supplier_number: '62093' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-1',
        quantity: 960,
        deliveryDate: '2023-01-30',
        productionStartDate: '2023-01-05',
        supplier: { kind: 'external', supplier_number: '62093' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-1',
        quantity: 960,
        deliveryDate: '2023-01-30',
        productionStartDate: '2023-01-05',
        supplier: { kind: 'external', supplier_number: '62093' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-1',
        quantity: 960,
        deliveryDate: '2023-01-30',
        productionStartDate: '2023-01-05',
        supplier: { kind: 'external', supplier_number: '62093' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-1',
        quantity: 960,
        deliveryDate: '2023-01-30',
        productionStartDate: '2023-01-05',
        supplier: { kind: 'external', supplier_number: '62093' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-1',
        quantity: 960,
        deliveryDate: '2023-02-01',
        productionStartDate: '2023-01-05',
        supplier: { kind: 'external', supplier_number: '62093' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-1',
        quantity: 960,
        deliveryDate: '2023-02-22',
        productionStartDate: '2023-01-27',
        supplier: { kind: 'external', supplier_number: '62093' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-1',
        quantity: 960,
        deliveryDate: '2023-03-08',
        productionStartDate: '2023-02-10',
        supplier: { kind: 'external', supplier_number: '62093' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-1',
        quantity: 960,
        deliveryDate: '2023-03-22',
        productionStartDate: '2023-02-24',
        supplier: { kind: 'external', supplier_number: '62093' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-1',
        quantity: 960,
        deliveryDate: '2023-04-12',
        productionStartDate: '2023-03-17',
        supplier: { kind: 'external', supplier_number: '62093' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-1',
        quantity: 960,
        deliveryDate: '2023-04-26',
        productionStartDate: '2023-03-31',
        supplier: { kind: 'external', supplier_number: '62093' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-1',
        quantity: 960,
        deliveryDate: '2023-05-15',
        productionStartDate: '2023-04-20',
        supplier: { kind: 'external', supplier_number: '62093' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-1',
        quantity: 960,
        deliveryDate: '2023-06-05',
        productionStartDate: '2023-05-11',
        supplier: { kind: 'external', supplier_number: '62093' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-1',
        quantity: 960,
        deliveryDate: '2023-06-21',
        productionStartDate: '2023-05-26',
        supplier: { kind: 'external', supplier_number: '62093' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-1',
        quantity: 960,
        deliveryDate: '2023-07-05',
        productionStartDate: '2023-06-07',
        supplier: { kind: 'external', supplier_number: '62093' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-1',
        quantity: 960,
        deliveryDate: '2023-07-26',
        productionStartDate: '2023-06-30',
        supplier: { kind: 'external', supplier_number: '62093' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-1',
        quantity: 960,
        deliveryDate: '2023-08-09',
        productionStartDate: '2023-07-14',
        supplier: { kind: 'external', supplier_number: '62093' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-1',
        quantity: 960,
        deliveryDate: '2023-08-30',
        productionStartDate: '2023-08-04',
        supplier: { kind: 'external', supplier_number: '62093' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-1',
        quantity: 960,
        deliveryDate: '2023-09-13',
        productionStartDate: '2023-08-18',
        supplier: { kind: 'external', supplier_number: '62093' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-1',
        quantity: 960,
        deliveryDate: '2023-10-04',
        productionStartDate: '2023-09-08',
        supplier: { kind: 'external', supplier_number: '62093' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-1',
        quantity: 960,
        deliveryDate: '2023-10-18',
        productionStartDate: '2023-09-22',
        supplier: { kind: 'external', supplier_number: '62093' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-1',
        quantity: 960,
        deliveryDate: '2023-11-08',
        productionStartDate: '2023-10-13',
        supplier: { kind: 'external', supplier_number: '62093' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-1',
        quantity: 960,
        deliveryDate: '2023-11-22',
        productionStartDate: '2023-10-27',
        supplier: { kind: 'external', supplier_number: '62093' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-1',
        quantity: 960,
        deliveryDate: '2023-12-06',
        productionStartDate: '2023-11-10',
        supplier: { kind: 'external', supplier_number: '62093' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 3400,
        deliveryDate: '2023-01-30',
        productionStartDate: '2023-01-05',
        supplier: { kind: 'external', supplier_number: '62093' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 3400,
        deliveryDate: '2023-01-30',
        productionStartDate: '2023-01-05',
        supplier: { kind: 'external', supplier_number: '62093' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 3400,
        deliveryDate: '2023-02-07',
        productionStartDate: '2023-01-13',
        supplier: { kind: 'external', supplier_number: '62093' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 3400,
        deliveryDate: '2023-03-01',
        productionStartDate: '2023-02-03',
        supplier: { kind: 'external', supplier_number: '62093' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 3400,
        deliveryDate: '2023-03-22',
        productionStartDate: '2023-02-24',
        supplier: { kind: 'external', supplier_number: '62093' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 3400,
        deliveryDate: '2023-04-05',
        productionStartDate: '2023-03-10',
        supplier: { kind: 'external', supplier_number: '62093' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 3400,
        deliveryDate: '2023-04-27',
        productionStartDate: '2023-03-31',
        supplier: { kind: 'external', supplier_number: '62093' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 3400,
        deliveryDate: '2023-05-16',
        productionStartDate: '2023-04-21',
        supplier: { kind: 'external', supplier_number: '62093' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 3400,
        deliveryDate: '2023-06-01',
        productionStartDate: '2023-05-05',
        supplier: { kind: 'external', supplier_number: '62093' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 3400,
        deliveryDate: '2023-06-21',
        productionStartDate: '2023-05-26',
        supplier: { kind: 'external', supplier_number: '62093' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 3400,
        deliveryDate: '2023-07-05',
        productionStartDate: '2023-06-07',
        supplier: { kind: 'external', supplier_number: '62093' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 3400,
        deliveryDate: '2023-07-20',
        productionStartDate: '2023-06-23',
        supplier: { kind: 'external', supplier_number: '62093' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 3400,
        deliveryDate: '2023-08-03',
        productionStartDate: '2023-07-07',
        supplier: { kind: 'external', supplier_number: '62093' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 3400,
        deliveryDate: '2023-08-23',
        productionStartDate: '2023-07-28',
        supplier: { kind: 'external', supplier_number: '62093' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 3400,
        deliveryDate: '2023-09-07',
        productionStartDate: '2023-08-11',
        supplier: { kind: 'external', supplier_number: '62093' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 3400,
        deliveryDate: '2023-09-21',
        productionStartDate: '2023-08-25',
        supplier: { kind: 'external', supplier_number: '62093' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 3400,
        deliveryDate: '2023-10-11',
        productionStartDate: '2023-09-15',
        supplier: { kind: 'external', supplier_number: '62093' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 3400,
        deliveryDate: '2023-10-26',
        productionStartDate: '2023-09-29',
        supplier: { kind: 'external', supplier_number: '62093' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 3400,
        deliveryDate: '2023-11-15',
        productionStartDate: '2023-10-20',
        supplier: { kind: 'external', supplier_number: '62093' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 3400,
        deliveryDate: '2023-12-07',
        productionStartDate: '2023-11-10',
        supplier: { kind: 'external', supplier_number: '62093' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 234,
        deliveryDate: '2023-01-27',
        productionStartDate: '2023-01-20',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 234,
        deliveryDate: '2023-01-27',
        productionStartDate: '2023-01-20',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 234,
        deliveryDate: '2023-01-27',
        productionStartDate: '2023-01-20',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 234,
        deliveryDate: '2023-01-27',
        productionStartDate: '2023-01-20',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 2304,
        deliveryDate: '2023-01-30',
        productionStartDate: '2023-01-05',
        supplier: { kind: 'external', supplier_number: '62093' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 234,
        deliveryDate: '2023-02-10',
        productionStartDate: '2023-02-03',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 234,
        deliveryDate: '2023-02-10',
        productionStartDate: '2023-02-03',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 234,
        deliveryDate: '2023-02-10',
        productionStartDate: '2023-02-03',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 234,
        deliveryDate: '2023-02-10',
        productionStartDate: '2023-02-03',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 234,
        deliveryDate: '2023-02-20',
        productionStartDate: '2023-02-13',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 234,
        deliveryDate: '2023-02-20',
        productionStartDate: '2023-02-13',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 234,
        deliveryDate: '2023-02-20',
        productionStartDate: '2023-02-13',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 234,
        deliveryDate: '2023-02-20',
        productionStartDate: '2023-02-13',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 234,
        deliveryDate: '2023-02-20',
        productionStartDate: '2023-02-13',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 2304,
        deliveryDate: '2023-02-20',
        productionStartDate: '2023-01-26',
        supplier: { kind: 'external', supplier_number: '62093' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 234,
        deliveryDate: '2023-03-06',
        productionStartDate: '2023-02-27',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 234,
        deliveryDate: '2023-03-06',
        productionStartDate: '2023-02-27',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 234,
        deliveryDate: '2023-03-06',
        productionStartDate: '2023-02-27',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 234,
        deliveryDate: '2023-03-10',
        productionStartDate: '2023-03-03',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 234,
        deliveryDate: '2023-03-10',
        productionStartDate: '2023-03-03',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 234,
        deliveryDate: '2023-03-10',
        productionStartDate: '2023-03-03',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 234,
        deliveryDate: '2023-03-10',
        productionStartDate: '2023-03-03',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 234,
        deliveryDate: '2023-03-10',
        productionStartDate: '2023-03-03',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 234,
        deliveryDate: '2023-03-10',
        productionStartDate: '2023-03-03',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 234,
        deliveryDate: '2023-03-17',
        productionStartDate: '2023-03-10',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 2304,
        deliveryDate: '2023-03-17',
        productionStartDate: '2023-02-20',
        supplier: { kind: 'external', supplier_number: '62093' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 234,
        deliveryDate: '2023-03-27',
        productionStartDate: '2023-03-20',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 234,
        deliveryDate: '2023-03-27',
        productionStartDate: '2023-03-20',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 234,
        deliveryDate: '2023-03-31',
        productionStartDate: '2023-03-24',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 234,
        deliveryDate: '2023-03-31',
        productionStartDate: '2023-03-24',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 234,
        deliveryDate: '2023-03-31',
        productionStartDate: '2023-03-24',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 234,
        deliveryDate: '2023-03-31',
        productionStartDate: '2023-03-24',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 234,
        deliveryDate: '2023-03-31',
        productionStartDate: '2023-03-24',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 234,
        deliveryDate: '2023-03-31',
        productionStartDate: '2023-03-24',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 234,
        deliveryDate: '2023-03-31',
        productionStartDate: '2023-03-24',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 234,
        deliveryDate: '2023-03-31',
        productionStartDate: '2023-03-24',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 2304,
        deliveryDate: '2023-03-31',
        productionStartDate: '2023-03-06',
        supplier: { kind: 'external', supplier_number: '62093' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 234,
        deliveryDate: '2023-04-17',
        productionStartDate: '2023-04-06',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 234,
        deliveryDate: '2023-04-17',
        productionStartDate: '2023-04-06',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 234,
        deliveryDate: '2023-04-17',
        productionStartDate: '2023-04-06',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 234,
        deliveryDate: '2023-04-17',
        productionStartDate: '2023-04-06',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 234,
        deliveryDate: '2023-04-17',
        productionStartDate: '2023-04-06',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 234,
        deliveryDate: '2023-04-17',
        productionStartDate: '2023-04-06',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 234,
        deliveryDate: '2023-04-17',
        productionStartDate: '2023-04-06',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 234,
        deliveryDate: '2023-04-17',
        productionStartDate: '2023-04-06',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 234,
        deliveryDate: '2023-04-24',
        productionStartDate: '2023-04-17',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 234,
        deliveryDate: '2023-04-24',
        productionStartDate: '2023-04-17',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 2304,
        deliveryDate: '2023-04-24',
        productionStartDate: '2023-03-30',
        supplier: { kind: 'external', supplier_number: '62093' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 234,
        deliveryDate: '2023-05-05',
        productionStartDate: '2023-04-27',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 234,
        deliveryDate: '2023-05-05',
        productionStartDate: '2023-04-27',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 234,
        deliveryDate: '2023-05-05',
        productionStartDate: '2023-04-27',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 234,
        deliveryDate: '2023-05-05',
        productionStartDate: '2023-04-27',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 234,
        deliveryDate: '2023-05-05',
        productionStartDate: '2023-04-27',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 234,
        deliveryDate: '2023-05-05',
        productionStartDate: '2023-04-27',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 234,
        deliveryDate: '2023-05-05',
        productionStartDate: '2023-04-27',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 234,
        deliveryDate: '2023-05-05',
        productionStartDate: '2023-04-27',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 234,
        deliveryDate: '2023-05-05',
        productionStartDate: '2023-04-27',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 234,
        deliveryDate: '2023-05-16',
        productionStartDate: '2023-05-09',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 2304,
        deliveryDate: '2023-05-16',
        productionStartDate: '2023-04-21',
        supplier: { kind: 'external', supplier_number: '62093' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 234,
        deliveryDate: '2023-05-25',
        productionStartDate: '2023-05-16',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 234,
        deliveryDate: '2023-05-25',
        productionStartDate: '2023-05-16',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 234,
        deliveryDate: '2023-05-25',
        productionStartDate: '2023-05-16',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 234,
        deliveryDate: '2023-05-26',
        productionStartDate: '2023-05-17',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 234,
        deliveryDate: '2023-05-26',
        productionStartDate: '2023-05-17',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 234,
        deliveryDate: '2023-05-26',
        productionStartDate: '2023-05-17',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 234,
        deliveryDate: '2023-05-26',
        productionStartDate: '2023-05-17',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 234,
        deliveryDate: '2023-05-26',
        productionStartDate: '2023-05-17',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 234,
        deliveryDate: '2023-05-26',
        productionStartDate: '2023-05-17',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 234,
        deliveryDate: '2023-05-26',
        productionStartDate: '2023-05-17',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 2304,
        deliveryDate: '2023-05-26',
        productionStartDate: '2023-04-28',
        supplier: { kind: 'external', supplier_number: '62093' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 234,
        deliveryDate: '2023-06-12',
        productionStartDate: '2023-06-01',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 234,
        deliveryDate: '2023-06-12',
        productionStartDate: '2023-06-01',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 234,
        deliveryDate: '2023-06-16',
        productionStartDate: '2023-06-07',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 234,
        deliveryDate: '2023-06-16',
        productionStartDate: '2023-06-07',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 234,
        deliveryDate: '2023-06-16',
        productionStartDate: '2023-06-07',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 234,
        deliveryDate: '2023-06-16',
        productionStartDate: '2023-06-07',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 234,
        deliveryDate: '2023-06-16',
        productionStartDate: '2023-06-07',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 234,
        deliveryDate: '2023-06-16',
        productionStartDate: '2023-06-07',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 234,
        deliveryDate: '2023-06-16',
        productionStartDate: '2023-06-07',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 234,
        deliveryDate: '2023-06-16',
        productionStartDate: '2023-06-07',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 2304,
        deliveryDate: '2023-06-23',
        productionStartDate: '2023-05-26',
        supplier: { kind: 'external', supplier_number: '62093' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 234,
        deliveryDate: '2023-06-30',
        productionStartDate: '2023-06-23',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 234,
        deliveryDate: '2023-06-30',
        productionStartDate: '2023-06-23',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 234,
        deliveryDate: '2023-06-30',
        productionStartDate: '2023-06-23',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 234,
        deliveryDate: '2023-06-30',
        productionStartDate: '2023-06-23',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 234,
        deliveryDate: '2023-06-30',
        productionStartDate: '2023-06-23',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 234,
        deliveryDate: '2023-06-30',
        productionStartDate: '2023-06-23',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 234,
        deliveryDate: '2023-06-30',
        productionStartDate: '2023-06-23',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 234,
        deliveryDate: '2023-07-07',
        productionStartDate: '2023-06-30',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 234,
        deliveryDate: '2023-07-07',
        productionStartDate: '2023-06-30',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 2304,
        deliveryDate: '2023-07-07',
        productionStartDate: '2023-06-12',
        supplier: { kind: 'external', supplier_number: '62093' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 234,
        deliveryDate: '2023-07-14',
        productionStartDate: '2023-07-07',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 234,
        deliveryDate: '2023-07-14',
        productionStartDate: '2023-07-07',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 234,
        deliveryDate: '2023-07-14',
        productionStartDate: '2023-07-07',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 234,
        deliveryDate: '2023-07-14',
        productionStartDate: '2023-07-07',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 234,
        deliveryDate: '2023-07-14',
        productionStartDate: '2023-07-07',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 234,
        deliveryDate: '2023-07-21',
        productionStartDate: '2023-07-14',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 234,
        deliveryDate: '2023-07-21',
        productionStartDate: '2023-07-14',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 234,
        deliveryDate: '2023-07-21',
        productionStartDate: '2023-07-14',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 234,
        deliveryDate: '2023-07-21',
        productionStartDate: '2023-07-14',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 234,
        deliveryDate: '2023-07-21',
        productionStartDate: '2023-07-14',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 2304,
        deliveryDate: '2023-07-21',
        productionStartDate: '2023-06-26',
        supplier: { kind: 'external', supplier_number: '62093' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 234,
        deliveryDate: '2023-07-31',
        productionStartDate: '2023-07-24',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 234,
        deliveryDate: '2023-08-04',
        productionStartDate: '2023-07-28',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 234,
        deliveryDate: '2023-08-04',
        productionStartDate: '2023-07-28',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 234,
        deliveryDate: '2023-08-04',
        productionStartDate: '2023-07-28',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 234,
        deliveryDate: '2023-08-04',
        productionStartDate: '2023-07-28',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 234,
        deliveryDate: '2023-08-04',
        productionStartDate: '2023-07-28',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 234,
        deliveryDate: '2023-08-04',
        productionStartDate: '2023-07-28',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 234,
        deliveryDate: '2023-08-04',
        productionStartDate: '2023-07-28',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 234,
        deliveryDate: '2023-08-04',
        productionStartDate: '2023-07-28',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 234,
        deliveryDate: '2023-08-11',
        productionStartDate: '2023-08-04',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 2304,
        deliveryDate: '2023-08-11',
        productionStartDate: '2023-07-17',
        supplier: { kind: 'external', supplier_number: '62093' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 234,
        deliveryDate: '2023-08-18',
        productionStartDate: '2023-08-11',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 234,
        deliveryDate: '2023-08-18',
        productionStartDate: '2023-08-11',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 234,
        deliveryDate: '2023-08-18',
        productionStartDate: '2023-08-11',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 234,
        deliveryDate: '2023-08-18',
        productionStartDate: '2023-08-11',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 234,
        deliveryDate: '2023-08-18',
        productionStartDate: '2023-08-11',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 234,
        deliveryDate: '2023-08-25',
        productionStartDate: '2023-08-18',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 234,
        deliveryDate: '2023-08-25',
        productionStartDate: '2023-08-18',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 234,
        deliveryDate: '2023-08-25',
        productionStartDate: '2023-08-18',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 234,
        deliveryDate: '2023-08-25',
        productionStartDate: '2023-08-18',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 234,
        deliveryDate: '2023-08-25',
        productionStartDate: '2023-08-18',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 2304,
        deliveryDate: '2023-08-25',
        productionStartDate: '2023-07-31',
        supplier: { kind: 'external', supplier_number: '62093' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 234,
        deliveryDate: '2023-09-04',
        productionStartDate: '2023-08-28',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 234,
        deliveryDate: '2023-09-08',
        productionStartDate: '2023-09-01',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 234,
        deliveryDate: '2023-09-08',
        productionStartDate: '2023-09-01',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 234,
        deliveryDate: '2023-09-08',
        productionStartDate: '2023-09-01',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 234,
        deliveryDate: '2023-09-08',
        productionStartDate: '2023-09-01',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 234,
        deliveryDate: '2023-09-08',
        productionStartDate: '2023-09-01',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 234,
        deliveryDate: '2023-09-08',
        productionStartDate: '2023-09-01',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 234,
        deliveryDate: '2023-09-08',
        productionStartDate: '2023-09-01',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 234,
        deliveryDate: '2023-09-08',
        productionStartDate: '2023-09-01',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 234,
        deliveryDate: '2023-09-15',
        productionStartDate: '2023-09-08',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 2304,
        deliveryDate: '2023-09-15',
        productionStartDate: '2023-08-21',
        supplier: { kind: 'external', supplier_number: '62093' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 234,
        deliveryDate: '2023-09-15',
        productionStartDate: '2023-09-08',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 234,
        deliveryDate: '2023-09-25',
        productionStartDate: '2023-09-18',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 234,
        deliveryDate: '2023-09-25',
        productionStartDate: '2023-09-18',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 234,
        deliveryDate: '2023-09-25',
        productionStartDate: '2023-09-18',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 234,
        deliveryDate: '2023-09-25',
        productionStartDate: '2023-09-18',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 234,
        deliveryDate: '2023-09-25',
        productionStartDate: '2023-09-18',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 234,
        deliveryDate: '2023-09-25',
        productionStartDate: '2023-09-18',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 234,
        deliveryDate: '2023-09-25',
        productionStartDate: '2023-09-18',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 234,
        deliveryDate: '2023-09-25',
        productionStartDate: '2023-09-18',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 234,
        deliveryDate: '2023-09-29',
        productionStartDate: '2023-09-22',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 2304,
        deliveryDate: '2023-09-29',
        productionStartDate: '2023-09-04',
        supplier: { kind: 'external', supplier_number: '62093' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 234,
        deliveryDate: '2023-10-13',
        productionStartDate: '2023-10-06',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 234,
        deliveryDate: '2023-10-13',
        productionStartDate: '2023-10-06',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 234,
        deliveryDate: '2023-10-13',
        productionStartDate: '2023-10-06',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 234,
        deliveryDate: '2023-10-13',
        productionStartDate: '2023-10-06',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 234,
        deliveryDate: '2023-10-13',
        productionStartDate: '2023-10-06',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 234,
        deliveryDate: '2023-10-13',
        productionStartDate: '2023-10-06',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 234,
        deliveryDate: '2023-10-13',
        productionStartDate: '2023-10-06',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 234,
        deliveryDate: '2023-10-13',
        productionStartDate: '2023-10-06',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 234,
        deliveryDate: '2023-10-13',
        productionStartDate: '2023-10-06',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 2304,
        deliveryDate: '2023-10-13',
        productionStartDate: '2023-09-18',
        supplier: { kind: 'external', supplier_number: '62093' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 234,
        deliveryDate: '2023-10-26',
        productionStartDate: '2023-10-19',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 234,
        deliveryDate: '2023-10-26',
        productionStartDate: '2023-10-19',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 234,
        deliveryDate: '2023-10-27',
        productionStartDate: '2023-10-20',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 234,
        deliveryDate: '2023-10-27',
        productionStartDate: '2023-10-20',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 234,
        deliveryDate: '2023-11-03',
        productionStartDate: '2023-10-26',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 234,
        deliveryDate: '2023-11-03',
        productionStartDate: '2023-10-26',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 234,
        deliveryDate: '2023-11-03',
        productionStartDate: '2023-10-26',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 234,
        deliveryDate: '2023-11-03',
        productionStartDate: '2023-10-26',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 234,
        deliveryDate: '2023-11-03',
        productionStartDate: '2023-10-26',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 234,
        deliveryDate: '2023-11-03',
        productionStartDate: '2023-10-26',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 2304,
        deliveryDate: '2023-11-03',
        productionStartDate: '2023-10-09',
        supplier: { kind: 'external', supplier_number: '62093' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 234,
        deliveryDate: '2023-11-17',
        productionStartDate: '2023-11-10',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 234,
        deliveryDate: '2023-11-17',
        productionStartDate: '2023-11-10',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 234,
        deliveryDate: '2023-11-24',
        productionStartDate: '2023-11-17',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 234,
        deliveryDate: '2023-11-24',
        productionStartDate: '2023-11-17',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 234,
        deliveryDate: '2023-11-24',
        productionStartDate: '2023-11-17',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 234,
        deliveryDate: '2023-11-24',
        productionStartDate: '2023-11-17',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 234,
        deliveryDate: '2023-11-24',
        productionStartDate: '2023-11-17',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 234,
        deliveryDate: '2023-11-24',
        productionStartDate: '2023-11-17',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 234,
        deliveryDate: '2023-12-01',
        productionStartDate: '2023-11-24',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 234,
        deliveryDate: '2023-12-01',
        productionStartDate: '2023-11-24',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 2304,
        deliveryDate: '2023-12-01',
        productionStartDate: '2023-11-06',
        supplier: { kind: 'external', supplier_number: '62093' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-4',
        quantity: 720,
        deliveryDate: '2023-01-17',
        productionStartDate: '2023-01-05',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-4',
        quantity: 720,
        deliveryDate: '2023-02-27',
        productionStartDate: '2023-02-16',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-4',
        quantity: 720,
        deliveryDate: '2023-02-27',
        productionStartDate: '2023-02-16',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-4',
        quantity: 720,
        deliveryDate: '2023-02-27',
        productionStartDate: '2023-02-16',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-4',
        quantity: 720,
        deliveryDate: '2023-02-27',
        productionStartDate: '2023-02-16',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-4',
        quantity: 720,
        deliveryDate: '2023-03-08',
        productionStartDate: '2023-02-27',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-4',
        quantity: 720,
        deliveryDate: '2023-03-08',
        productionStartDate: '2023-02-27',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-4',
        quantity: 720,
        deliveryDate: '2023-03-15',
        productionStartDate: '2023-03-06',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-4',
        quantity: 720,
        deliveryDate: '2023-03-15',
        productionStartDate: '2023-03-06',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-4',
        quantity: 720,
        deliveryDate: '2023-03-15',
        productionStartDate: '2023-03-06',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-4',
        quantity: 720,
        deliveryDate: '2023-03-22',
        productionStartDate: '2023-03-13',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-4',
        quantity: 720,
        deliveryDate: '2023-03-22',
        productionStartDate: '2023-03-13',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-4',
        quantity: 720,
        deliveryDate: '2023-03-28',
        productionStartDate: '2023-03-17',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-4',
        quantity: 720,
        deliveryDate: '2023-03-28',
        productionStartDate: '2023-03-17',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-4',
        quantity: 720,
        deliveryDate: '2023-03-28',
        productionStartDate: '2023-03-17',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-4',
        quantity: 720,
        deliveryDate: '2023-04-03',
        productionStartDate: '2023-03-23',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-4',
        quantity: 720,
        deliveryDate: '2023-04-03',
        productionStartDate: '2023-03-23',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-4',
        quantity: 720,
        deliveryDate: '2023-04-03',
        productionStartDate: '2023-03-23',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-4',
        quantity: 720,
        deliveryDate: '2023-04-03',
        productionStartDate: '2023-03-23',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-4',
        quantity: 720,
        deliveryDate: '2023-04-03',
        productionStartDate: '2023-03-23',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-4',
        quantity: 720,
        deliveryDate: '2023-04-12',
        productionStartDate: '2023-03-30',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-4',
        quantity: 720,
        deliveryDate: '2023-04-12',
        productionStartDate: '2023-03-30',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-4',
        quantity: 720,
        deliveryDate: '2023-04-12',
        productionStartDate: '2023-03-30',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-4',
        quantity: 720,
        deliveryDate: '2023-04-19',
        productionStartDate: '2023-04-06',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-4',
        quantity: 720,
        deliveryDate: '2023-04-19',
        productionStartDate: '2023-04-06',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-4',
        quantity: 720,
        deliveryDate: '2023-04-25',
        productionStartDate: '2023-04-14',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-4',
        quantity: 720,
        deliveryDate: '2023-04-25',
        productionStartDate: '2023-04-14',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-4',
        quantity: 720,
        deliveryDate: '2023-04-25',
        productionStartDate: '2023-04-14',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-4',
        quantity: 720,
        deliveryDate: '2023-05-03',
        productionStartDate: '2023-04-21',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-4',
        quantity: 720,
        deliveryDate: '2023-05-03',
        productionStartDate: '2023-04-21',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-4',
        quantity: 720,
        deliveryDate: '2023-05-08',
        productionStartDate: '2023-04-26',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-4',
        quantity: 720,
        deliveryDate: '2023-05-08',
        productionStartDate: '2023-04-26',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-4',
        quantity: 720,
        deliveryDate: '2023-05-08',
        productionStartDate: '2023-04-26',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-4',
        quantity: 720,
        deliveryDate: '2023-05-15',
        productionStartDate: '2023-05-04',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-4',
        quantity: 720,
        deliveryDate: '2023-05-15',
        productionStartDate: '2023-05-04',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-4',
        quantity: 720,
        deliveryDate: '2023-05-23',
        productionStartDate: '2023-05-10',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-4',
        quantity: 720,
        deliveryDate: '2023-05-23',
        productionStartDate: '2023-05-10',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-4',
        quantity: 720,
        deliveryDate: '2023-05-23',
        productionStartDate: '2023-05-10',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-4',
        quantity: 720,
        deliveryDate: '2023-05-23',
        productionStartDate: '2023-05-10',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-4',
        quantity: 720,
        deliveryDate: '2023-05-23',
        productionStartDate: '2023-05-10',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-4',
        quantity: 720,
        deliveryDate: '2023-05-23',
        productionStartDate: '2023-05-10',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-4',
        quantity: 720,
        deliveryDate: '2023-06-05',
        productionStartDate: '2023-05-24',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-4',
        quantity: 720,
        deliveryDate: '2023-06-05',
        productionStartDate: '2023-05-24',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-4',
        quantity: 720,
        deliveryDate: '2023-06-05',
        productionStartDate: '2023-05-24',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-4',
        quantity: 720,
        deliveryDate: '2023-06-14',
        productionStartDate: '2023-06-01',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-4',
        quantity: 720,
        deliveryDate: '2023-06-14',
        productionStartDate: '2023-06-01',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-4',
        quantity: 720,
        deliveryDate: '2023-06-14',
        productionStartDate: '2023-06-01',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-4',
        quantity: 720,
        deliveryDate: '2023-06-21',
        productionStartDate: '2023-06-12',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-4',
        quantity: 720,
        deliveryDate: '2023-06-21',
        productionStartDate: '2023-06-12',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-4',
        quantity: 720,
        deliveryDate: '2023-06-21',
        productionStartDate: '2023-06-12',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-4',
        quantity: 720,
        deliveryDate: '2023-06-28',
        productionStartDate: '2023-06-19',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-4',
        quantity: 720,
        deliveryDate: '2023-06-28',
        productionStartDate: '2023-06-19',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-4',
        quantity: 720,
        deliveryDate: '2023-06-28',
        productionStartDate: '2023-06-19',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-4',
        quantity: 720,
        deliveryDate: '2023-07-05',
        productionStartDate: '2023-06-26',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-4',
        quantity: 720,
        deliveryDate: '2023-07-05',
        productionStartDate: '2023-06-26',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-4',
        quantity: 720,
        deliveryDate: '2023-07-05',
        productionStartDate: '2023-06-26',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-4',
        quantity: 720,
        deliveryDate: '2023-07-05',
        productionStartDate: '2023-06-26',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-4',
        quantity: 720,
        deliveryDate: '2023-07-12',
        productionStartDate: '2023-07-03',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-4',
        quantity: 720,
        deliveryDate: '2023-07-12',
        productionStartDate: '2023-07-03',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-4',
        quantity: 720,
        deliveryDate: '2023-07-12',
        productionStartDate: '2023-07-03',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-4',
        quantity: 720,
        deliveryDate: '2023-07-19',
        productionStartDate: '2023-07-10',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-4',
        quantity: 720,
        deliveryDate: '2023-07-19',
        productionStartDate: '2023-07-10',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-4',
        quantity: 720,
        deliveryDate: '2023-07-19',
        productionStartDate: '2023-07-10',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-4',
        quantity: 720,
        deliveryDate: '2023-07-26',
        productionStartDate: '2023-07-17',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-4',
        quantity: 720,
        deliveryDate: '2023-07-26',
        productionStartDate: '2023-07-17',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-4',
        quantity: 720,
        deliveryDate: '2023-07-26',
        productionStartDate: '2023-07-17',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-4',
        quantity: 720,
        deliveryDate: '2023-08-02',
        productionStartDate: '2023-07-24',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-4',
        quantity: 720,
        deliveryDate: '2023-08-02',
        productionStartDate: '2023-07-24',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-4',
        quantity: 720,
        deliveryDate: '2023-08-02',
        productionStartDate: '2023-07-24',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-4',
        quantity: 720,
        deliveryDate: '2023-08-09',
        productionStartDate: '2023-07-31',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-4',
        quantity: 720,
        deliveryDate: '2023-08-09',
        productionStartDate: '2023-07-31',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-4',
        quantity: 720,
        deliveryDate: '2023-08-09',
        productionStartDate: '2023-07-31',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-4',
        quantity: 720,
        deliveryDate: '2023-08-16',
        productionStartDate: '2023-08-07',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-4',
        quantity: 720,
        deliveryDate: '2023-08-16',
        productionStartDate: '2023-08-07',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-4',
        quantity: 720,
        deliveryDate: '2023-08-16',
        productionStartDate: '2023-08-07',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-4',
        quantity: 720,
        deliveryDate: '2023-08-23',
        productionStartDate: '2023-08-14',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-4',
        quantity: 720,
        deliveryDate: '2023-08-23',
        productionStartDate: '2023-08-14',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-4',
        quantity: 720,
        deliveryDate: '2023-08-23',
        productionStartDate: '2023-08-14',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-4',
        quantity: 720,
        deliveryDate: '2023-08-23',
        productionStartDate: '2023-08-14',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-4',
        quantity: 720,
        deliveryDate: '2023-08-30',
        productionStartDate: '2023-08-21',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-4',
        quantity: 720,
        deliveryDate: '2023-08-30',
        productionStartDate: '2023-08-21',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-4',
        quantity: 720,
        deliveryDate: '2023-08-30',
        productionStartDate: '2023-08-21',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-4',
        quantity: 720,
        deliveryDate: '2023-09-06',
        productionStartDate: '2023-08-28',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-4',
        quantity: 720,
        deliveryDate: '2023-09-06',
        productionStartDate: '2023-08-28',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-4',
        quantity: 720,
        deliveryDate: '2023-09-06',
        productionStartDate: '2023-08-28',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-4',
        quantity: 720,
        deliveryDate: '2023-09-13',
        productionStartDate: '2023-09-04',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-4',
        quantity: 720,
        deliveryDate: '2023-09-13',
        productionStartDate: '2023-09-04',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-4',
        quantity: 720,
        deliveryDate: '2023-09-13',
        productionStartDate: '2023-09-04',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-4',
        quantity: 720,
        deliveryDate: '2023-09-20',
        productionStartDate: '2023-09-11',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-4',
        quantity: 720,
        deliveryDate: '2023-09-20',
        productionStartDate: '2023-09-11',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-4',
        quantity: 720,
        deliveryDate: '2023-09-20',
        productionStartDate: '2023-09-11',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-4',
        quantity: 720,
        deliveryDate: '2023-09-26',
        productionStartDate: '2023-09-15',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-4',
        quantity: 720,
        deliveryDate: '2023-09-26',
        productionStartDate: '2023-09-15',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-4',
        quantity: 720,
        deliveryDate: '2023-09-26',
        productionStartDate: '2023-09-15',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-4',
        quantity: 720,
        deliveryDate: '2023-10-04',
        productionStartDate: '2023-09-22',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-4',
        quantity: 720,
        deliveryDate: '2023-10-04',
        productionStartDate: '2023-09-22',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-4',
        quantity: 720,
        deliveryDate: '2023-10-04',
        productionStartDate: '2023-09-22',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-4',
        quantity: 720,
        deliveryDate: '2023-10-11',
        productionStartDate: '2023-09-29',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-4',
        quantity: 720,
        deliveryDate: '2023-10-11',
        productionStartDate: '2023-09-29',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-4',
        quantity: 720,
        deliveryDate: '2023-10-11',
        productionStartDate: '2023-09-29',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-4',
        quantity: 720,
        deliveryDate: '2023-10-11',
        productionStartDate: '2023-09-29',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-4',
        quantity: 720,
        deliveryDate: '2023-10-18',
        productionStartDate: '2023-10-09',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-4',
        quantity: 720,
        deliveryDate: '2023-10-18',
        productionStartDate: '2023-10-09',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-4',
        quantity: 720,
        deliveryDate: '2023-10-18',
        productionStartDate: '2023-10-09',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-4',
        quantity: 720,
        deliveryDate: '2023-10-24',
        productionStartDate: '2023-10-13',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-4',
        quantity: 720,
        deliveryDate: '2023-10-24',
        productionStartDate: '2023-10-13',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-4',
        quantity: 720,
        deliveryDate: '2023-10-24',
        productionStartDate: '2023-10-13',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-4',
        quantity: 720,
        deliveryDate: '2023-10-31',
        productionStartDate: '2023-10-20',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-4',
        quantity: 720,
        deliveryDate: '2023-10-31',
        productionStartDate: '2023-10-20',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-4',
        quantity: 720,
        deliveryDate: '2023-10-31',
        productionStartDate: '2023-10-20',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-4',
        quantity: 720,
        deliveryDate: '2023-11-08',
        productionStartDate: '2023-10-27',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-4',
        quantity: 720,
        deliveryDate: '2023-11-08',
        productionStartDate: '2023-10-27',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-4',
        quantity: 720,
        deliveryDate: '2023-11-08',
        productionStartDate: '2023-10-27',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-4',
        quantity: 720,
        deliveryDate: '2023-11-08',
        productionStartDate: '2023-10-27',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-4',
        quantity: 720,
        deliveryDate: '2023-11-15',
        productionStartDate: '2023-11-06',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-4',
        quantity: 720,
        deliveryDate: '2023-11-15',
        productionStartDate: '2023-11-06',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-4',
        quantity: 720,
        deliveryDate: '2023-11-15',
        productionStartDate: '2023-11-06',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-4',
        quantity: 720,
        deliveryDate: '2023-11-22',
        productionStartDate: '2023-11-13',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-4',
        quantity: 720,
        deliveryDate: '2023-11-22',
        productionStartDate: '2023-11-13',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-4',
        quantity: 720,
        deliveryDate: '2023-11-22',
        productionStartDate: '2023-11-13',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-4',
        quantity: 720,
        deliveryDate: '2023-11-22',
        productionStartDate: '2023-11-13',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-4',
        quantity: 720,
        deliveryDate: '2023-11-29',
        productionStartDate: '2023-11-20',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-4',
        quantity: 720,
        deliveryDate: '2023-11-29',
        productionStartDate: '2023-11-20',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-4',
        quantity: 720,
        deliveryDate: '2023-11-29',
        productionStartDate: '2023-11-20',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-4',
        quantity: 720,
        deliveryDate: '2023-12-06',
        productionStartDate: '2023-11-27',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-4',
        quantity: 720,
        deliveryDate: '2023-12-06',
        productionStartDate: '2023-11-27',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-4',
        quantity: 720,
        deliveryDate: '2023-12-13',
        productionStartDate: '2023-12-04',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-5',
        quantity: 936,
        deliveryDate: '2023-02-02',
        productionStartDate: '2023-01-24',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-5',
        quantity: 936,
        deliveryDate: '2023-02-10',
        productionStartDate: '2023-02-01',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-5',
        quantity: 936,
        deliveryDate: '2023-02-24',
        productionStartDate: '2023-02-15',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-5',
        quantity: 936,
        deliveryDate: '2023-03-02',
        productionStartDate: '2023-02-21',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-5',
        quantity: 936,
        deliveryDate: '2023-03-02',
        productionStartDate: '2023-02-21',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-5',
        quantity: 936,
        deliveryDate: '2023-03-23',
        productionStartDate: '2023-03-14',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-5',
        quantity: 936,
        deliveryDate: '2023-03-23',
        productionStartDate: '2023-03-14',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-5',
        quantity: 936,
        deliveryDate: '2023-04-05',
        productionStartDate: '2023-03-27',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-5',
        quantity: 936,
        deliveryDate: '2023-04-05',
        productionStartDate: '2023-03-27',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-5',
        quantity: 936,
        deliveryDate: '2023-04-14',
        productionStartDate: '2023-04-03',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-5',
        quantity: 936,
        deliveryDate: '2023-04-26',
        productionStartDate: '2023-04-17',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-5',
        quantity: 936,
        deliveryDate: '2023-04-26',
        productionStartDate: '2023-04-17',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-5',
        quantity: 936,
        deliveryDate: '2023-05-15',
        productionStartDate: '2023-05-04',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-5',
        quantity: 936,
        deliveryDate: '2023-05-16',
        productionStartDate: '2023-05-05',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-5',
        quantity: 936,
        deliveryDate: '2023-05-16',
        productionStartDate: '2023-05-05',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-5',
        quantity: 936,
        deliveryDate: '2023-06-06',
        productionStartDate: '2023-05-25',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-5',
        quantity: 936,
        deliveryDate: '2023-06-06',
        productionStartDate: '2023-05-25',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-5',
        quantity: 936,
        deliveryDate: '2023-06-22',
        productionStartDate: '2023-06-13',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-5',
        quantity: 936,
        deliveryDate: '2023-06-22',
        productionStartDate: '2023-06-13',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-5',
        quantity: 936,
        deliveryDate: '2023-06-29',
        productionStartDate: '2023-06-20',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-5',
        quantity: 936,
        deliveryDate: '2023-07-06',
        productionStartDate: '2023-06-27',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-5',
        quantity: 936,
        deliveryDate: '2023-07-13',
        productionStartDate: '2023-07-04',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-5',
        quantity: 936,
        deliveryDate: '2023-07-27',
        productionStartDate: '2023-07-18',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-5',
        quantity: 936,
        deliveryDate: '2023-07-27',
        productionStartDate: '2023-07-18',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-5',
        quantity: 936,
        deliveryDate: '2023-08-03',
        productionStartDate: '2023-07-25',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-5',
        quantity: 936,
        deliveryDate: '2023-08-10',
        productionStartDate: '2023-08-01',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-5',
        quantity: 936,
        deliveryDate: '2023-08-17',
        productionStartDate: '2023-08-08',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-5',
        quantity: 936,
        deliveryDate: '2023-08-31',
        productionStartDate: '2023-08-22',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-5',
        quantity: 936,
        deliveryDate: '2023-08-31',
        productionStartDate: '2023-08-22',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-5',
        quantity: 936,
        deliveryDate: '2023-09-07',
        productionStartDate: '2023-08-29',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-5',
        quantity: 936,
        deliveryDate: '2023-09-15',
        productionStartDate: '2023-09-06',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-5',
        quantity: 936,
        deliveryDate: '2023-09-15',
        productionStartDate: '2023-09-06',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-5',
        quantity: 936,
        deliveryDate: '2023-10-05',
        productionStartDate: '2023-09-25',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-5',
        quantity: 936,
        deliveryDate: '2023-10-05',
        productionStartDate: '2023-09-25',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-5',
        quantity: 936,
        deliveryDate: '2023-10-18',
        productionStartDate: '2023-10-09',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-5',
        quantity: 936,
        deliveryDate: '2023-10-25',
        productionStartDate: '2023-10-16',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-5',
        quantity: 936,
        deliveryDate: '2023-10-25',
        productionStartDate: '2023-10-16',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-5',
        quantity: 936,
        deliveryDate: '2023-11-16',
        productionStartDate: '2023-11-07',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-5',
        quantity: 936,
        deliveryDate: '2023-11-16',
        productionStartDate: '2023-11-07',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-1',
        quantity: 960,
        deliveryDate: '2024-07-18',
        productionStartDate: '2024-07-03',
        supplier: { kind: 'external', supplier_number: '62093' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-1',
        quantity: 960,
        deliveryDate: '2024-07-24',
        productionStartDate: '2024-07-09',
        supplier: { kind: 'external', supplier_number: '62093' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-1',
        quantity: 960,
        deliveryDate: '2024-08-07',
        productionStartDate: '2024-07-23',
        supplier: { kind: 'external', supplier_number: '62093' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-1',
        quantity: 960,
        deliveryDate: '2024-08-14',
        productionStartDate: '2024-07-30',
        supplier: { kind: 'external', supplier_number: '62093' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-1',
        quantity: 960,
        deliveryDate: '2024-08-28',
        productionStartDate: '2024-08-13',
        supplier: { kind: 'external', supplier_number: '62093' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-1',
        quantity: 960,
        deliveryDate: '2024-09-11',
        productionStartDate: '2024-08-27',
        supplier: { kind: 'external', supplier_number: '62093' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-1',
        quantity: 960,
        deliveryDate: '2024-09-18',
        productionStartDate: '2024-09-03',
        supplier: { kind: 'external', supplier_number: '62093' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-1',
        quantity: 960,
        deliveryDate: '2024-10-01',
        productionStartDate: '2024-09-16',
        supplier: { kind: 'external', supplier_number: '62093' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-1',
        quantity: 960,
        deliveryDate: '2024-10-09',
        productionStartDate: '2024-09-24',
        supplier: { kind: 'external', supplier_number: '62093' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-1',
        quantity: 960,
        deliveryDate: '2024-10-29',
        productionStartDate: '2024-10-14',
        supplier: { kind: 'external', supplier_number: '62093' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-1',
        quantity: 960,
        deliveryDate: '2024-11-06',
        productionStartDate: '2024-10-22',
        supplier: { kind: 'external', supplier_number: '62093' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-1',
        quantity: 960,
        deliveryDate: '2024-11-20',
        productionStartDate: '2024-11-05',
        supplier: { kind: 'external', supplier_number: '62093' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-1',
        quantity: 960,
        deliveryDate: '2024-11-20',
        productionStartDate: '2024-11-05',
        supplier: { kind: 'external', supplier_number: '62093' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-1',
        quantity: 960,
        deliveryDate: '2024-12-12',
        productionStartDate: '2024-11-27',
        supplier: { kind: 'external', supplier_number: '62093' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-1',
        quantity: 960,
        deliveryDate: '2024-12-18',
        productionStartDate: '2024-12-03',
        supplier: { kind: 'external', supplier_number: '62093' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-1',
        quantity: 960,
        deliveryDate: '2025-01-08',
        productionStartDate: '2024-12-23',
        supplier: { kind: 'external', supplier_number: '62093' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-1',
        quantity: 960,
        deliveryDate: '2025-01-15',
        productionStartDate: '2024-12-30',
        supplier: { kind: 'external', supplier_number: '62093' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-1',
        quantity: 960,
        deliveryDate: '2025-01-15',
        productionStartDate: '2024-12-30',
        supplier: { kind: 'external', supplier_number: '62093' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-1',
        quantity: 960,
        deliveryDate: '2025-01-22',
        productionStartDate: '2025-01-07',
        supplier: { kind: 'external', supplier_number: '62093' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-1',
        quantity: 960,
        deliveryDate: '2025-02-05',
        productionStartDate: '2025-01-21',
        supplier: { kind: 'external', supplier_number: '62093' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-1',
        quantity: 960,
        deliveryDate: '2025-02-12',
        productionStartDate: '2025-01-28',
        supplier: { kind: 'external', supplier_number: '62093' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-1',
        quantity: 960,
        deliveryDate: '2025-02-12',
        productionStartDate: '2025-01-28',
        supplier: { kind: 'external', supplier_number: '62093' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-1',
        quantity: 960,
        deliveryDate: '2025-02-12',
        productionStartDate: '2025-01-28',
        supplier: { kind: 'external', supplier_number: '62093' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-1',
        quantity: 960,
        deliveryDate: '2025-03-13',
        productionStartDate: '2025-02-26',
        supplier: { kind: 'external', supplier_number: '62093' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-1',
        quantity: 960,
        deliveryDate: '2025-03-13',
        productionStartDate: '2025-02-26',
        supplier: { kind: 'external', supplier_number: '62093' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-1',
        quantity: 960,
        deliveryDate: '2025-03-20',
        productionStartDate: '2025-03-05',
        supplier: { kind: 'external', supplier_number: '62093' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-1',
        quantity: 960,
        deliveryDate: '2025-04-10',
        productionStartDate: '2025-03-26',
        supplier: { kind: 'external', supplier_number: '62093' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-1',
        quantity: 960,
        deliveryDate: '2025-04-10',
        productionStartDate: '2025-03-26',
        supplier: { kind: 'external', supplier_number: '62093' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-1',
        quantity: 960,
        deliveryDate: '2025-05-09',
        productionStartDate: '2025-04-24',
        supplier: { kind: 'external', supplier_number: '62093' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-1',
        quantity: 960,
        deliveryDate: '2025-05-09',
        productionStartDate: '2025-04-24',
        supplier: { kind: 'external', supplier_number: '62093' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-1',
        quantity: 960,
        deliveryDate: '2025-05-15',
        productionStartDate: '2025-04-30',
        supplier: { kind: 'external', supplier_number: '62093' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-1',
        quantity: 960,
        deliveryDate: '2025-06-11',
        productionStartDate: '2025-05-27',
        supplier: { kind: 'external', supplier_number: '62093' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-1',
        quantity: 960,
        deliveryDate: '2025-06-16',
        productionStartDate: '2025-05-28',
        supplier: { kind: 'external', supplier_number: '62093' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-1',
        quantity: 960,
        deliveryDate: '2025-07-15',
        productionStartDate: '2025-06-30',
        supplier: { kind: 'external', supplier_number: '62093' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-1',
        quantity: 960,
        deliveryDate: '2025-07-17',
        productionStartDate: '2025-07-02',
        supplier: { kind: 'external', supplier_number: '62093' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-1',
        quantity: 960,
        deliveryDate: '2025-07-17',
        productionStartDate: '2025-07-02',
        supplier: { kind: 'external', supplier_number: '62093' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-1',
        quantity: 960,
        deliveryDate: '2025-08-15',
        productionStartDate: '2025-07-31',
        supplier: { kind: 'external', supplier_number: '62093' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-1',
        quantity: 960,
        deliveryDate: '2025-08-15',
        productionStartDate: '2025-07-31',
        supplier: { kind: 'external', supplier_number: '62093' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-1',
        quantity: 960,
        deliveryDate: '2025-08-20',
        productionStartDate: '2025-08-05',
        supplier: { kind: 'external', supplier_number: '62093' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-1',
        quantity: 960,
        deliveryDate: '2025-09-17',
        productionStartDate: '2025-09-02',
        supplier: { kind: 'external', supplier_number: '62093' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-1',
        quantity: 960,
        deliveryDate: '2025-09-17',
        productionStartDate: '2025-09-02',
        supplier: { kind: 'external', supplier_number: '62093' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-1',
        quantity: 960,
        deliveryDate: '2025-10-14',
        productionStartDate: '2025-09-29',
        supplier: { kind: 'external', supplier_number: '62093' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-1',
        quantity: 960,
        deliveryDate: '2025-10-16',
        productionStartDate: '2025-10-01',
        supplier: { kind: 'external', supplier_number: '62093' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-1',
        quantity: 960,
        deliveryDate: '2025-10-16',
        productionStartDate: '2025-10-01',
        supplier: { kind: 'external', supplier_number: '62093' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-1',
        quantity: 960,
        deliveryDate: '2025-11-14',
        productionStartDate: '2025-10-30',
        supplier: { kind: 'external', supplier_number: '62093' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-6',
        quantity: 2160,
        deliveryDate: '2024-07-22',
        productionStartDate: '2024-07-08',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0300',
    },
    {
        assemblyIpn: 'Assembly-6',
        quantity: 2160,
        deliveryDate: '2024-08-05',
        productionStartDate: '2024-07-22',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0300',
    },
    {
        assemblyIpn: 'Assembly-6',
        quantity: 2160,
        deliveryDate: '2024-08-16',
        productionStartDate: '2024-08-02',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0300',
    },
    {
        assemblyIpn: 'Assembly-6',
        quantity: 2160,
        deliveryDate: '2024-08-30',
        productionStartDate: '2024-08-15',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0300',
    },
    {
        assemblyIpn: 'Assembly-6',
        quantity: 2160,
        deliveryDate: '2024-09-06',
        productionStartDate: '2024-08-23',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0300',
    },
    {
        assemblyIpn: 'Assembly-6',
        quantity: 2160,
        deliveryDate: '2024-09-23',
        productionStartDate: '2024-09-09',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0300',
    },
    {
        assemblyIpn: 'Assembly-6',
        quantity: 2160,
        deliveryDate: '2024-09-27',
        productionStartDate: '2024-09-13',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0300',
    },
    {
        assemblyIpn: 'Assembly-6',
        quantity: 2160,
        deliveryDate: '2024-10-28',
        productionStartDate: '2024-10-11',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0300',
    },
    {
        assemblyIpn: 'Assembly-6',
        quantity: 2160,
        deliveryDate: '2024-10-31',
        productionStartDate: '2024-10-16',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0300',
    },
    {
        assemblyIpn: 'Assembly-6',
        quantity: 2160,
        deliveryDate: '2024-11-11',
        productionStartDate: '2024-10-25',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0300',
    },
    {
        assemblyIpn: 'Assembly-6',
        quantity: 2160,
        deliveryDate: '2024-11-25',
        productionStartDate: '2024-11-11',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0300',
    },
    {
        assemblyIpn: 'Assembly-6',
        quantity: 2160,
        deliveryDate: '2024-11-29',
        productionStartDate: '2024-11-15',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0300',
    },
    {
        assemblyIpn: 'Assembly-6',
        quantity: 2160,
        deliveryDate: '2024-12-20',
        productionStartDate: '2024-12-06',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0300',
    },
    {
        assemblyIpn: 'Assembly-6',
        quantity: 2160,
        deliveryDate: '2024-12-30',
        productionStartDate: '2024-12-11',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0300',
    },
    {
        assemblyIpn: 'Assembly-6',
        quantity: 2160,
        deliveryDate: '2025-01-13',
        productionStartDate: '2024-12-27',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0300',
    },
    {
        assemblyIpn: 'Assembly-6',
        quantity: 2160,
        deliveryDate: '2025-01-27',
        productionStartDate: '2025-01-13',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0300',
    },
    {
        assemblyIpn: 'Assembly-6',
        quantity: 2160,
        deliveryDate: '2025-01-27',
        productionStartDate: '2025-01-13',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0300',
    },
    {
        assemblyIpn: 'Assembly-6',
        quantity: 2160,
        deliveryDate: '2025-01-27',
        productionStartDate: '2025-01-13',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0300',
    },
    {
        assemblyIpn: 'Assembly-6',
        quantity: 2160,
        deliveryDate: '2025-02-21',
        productionStartDate: '2025-02-07',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0300',
    },
    {
        assemblyIpn: 'Assembly-6',
        quantity: 2160,
        deliveryDate: '2025-02-21',
        productionStartDate: '2025-02-07',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0300',
    },
    {
        assemblyIpn: 'Assembly-6',
        quantity: 2160,
        deliveryDate: '2025-02-21',
        productionStartDate: '2025-02-07',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0300',
    },
    {
        assemblyIpn: 'Assembly-6',
        quantity: 2160,
        deliveryDate: '2025-03-25',
        productionStartDate: '2025-03-11',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0300',
    },
    {
        assemblyIpn: 'Assembly-6',
        quantity: 2160,
        deliveryDate: '2025-03-25',
        productionStartDate: '2025-03-11',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0300',
    },
    {
        assemblyIpn: 'Assembly-6',
        quantity: 2160,
        deliveryDate: '2025-04-24',
        productionStartDate: '2025-04-08',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0300',
    },
    {
        assemblyIpn: 'Assembly-6',
        quantity: 2160,
        deliveryDate: '2025-04-24',
        productionStartDate: '2025-04-08',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0300',
    },
    {
        assemblyIpn: 'Assembly-6',
        quantity: 2160,
        deliveryDate: '2025-04-24',
        productionStartDate: '2025-04-08',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0300',
    },
    {
        assemblyIpn: 'Assembly-6',
        quantity: 2160,
        deliveryDate: '2025-05-23',
        productionStartDate: '2025-05-09',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0300',
    },
    {
        assemblyIpn: 'Assembly-6',
        quantity: 2160,
        deliveryDate: '2025-05-23',
        productionStartDate: '2025-05-09',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0300',
    },
    {
        assemblyIpn: 'Assembly-6',
        quantity: 2160,
        deliveryDate: '2025-06-27',
        productionStartDate: '2025-06-13',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0300',
    },
    {
        assemblyIpn: 'Assembly-6',
        quantity: 2160,
        deliveryDate: '2025-06-27',
        productionStartDate: '2025-06-13',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0300',
    },
    {
        assemblyIpn: 'Assembly-6',
        quantity: 2160,
        deliveryDate: '2025-06-27',
        productionStartDate: '2025-06-13',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0300',
    },
    {
        assemblyIpn: 'Assembly-6',
        quantity: 2160,
        deliveryDate: '2025-07-28',
        productionStartDate: '2025-07-14',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0300',
    },
    {
        assemblyIpn: 'Assembly-6',
        quantity: 2160,
        deliveryDate: '2025-07-28',
        productionStartDate: '2025-07-14',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0300',
    },
    {
        assemblyIpn: 'Assembly-6',
        quantity: 2160,
        deliveryDate: '2025-07-28',
        productionStartDate: '2025-07-14',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0300',
    },
    {
        assemblyIpn: 'Assembly-6',
        quantity: 2160,
        deliveryDate: '2025-08-25',
        productionStartDate: '2025-08-08',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0300',
    },
    {
        assemblyIpn: 'Assembly-6',
        quantity: 2160,
        deliveryDate: '2025-08-25',
        productionStartDate: '2025-08-08',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0300',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 2304,
        deliveryDate: '2024-07-18',
        productionStartDate: '2024-07-03',
        supplier: { kind: 'external', supplier_number: '62093' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 468,
        deliveryDate: '2024-07-18',
        productionStartDate: '2024-07-11',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 468,
        deliveryDate: '2024-07-23',
        productionStartDate: '2024-07-16',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 468,
        deliveryDate: '2024-07-23',
        productionStartDate: '2024-07-16',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 468,
        deliveryDate: '2024-07-26',
        productionStartDate: '2024-07-19',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 468,
        deliveryDate: '2024-07-26',
        productionStartDate: '2024-07-19',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 2304,
        deliveryDate: '2024-08-02',
        productionStartDate: '2024-07-18',
        supplier: { kind: 'external', supplier_number: '62093' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 468,
        deliveryDate: '2024-08-16',
        productionStartDate: '2024-08-09',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 468,
        deliveryDate: '2024-08-16',
        productionStartDate: '2024-08-09',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 468,
        deliveryDate: '2024-08-16',
        productionStartDate: '2024-08-09',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 468,
        deliveryDate: '2024-08-16',
        productionStartDate: '2024-08-09',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 468,
        deliveryDate: '2024-08-27',
        productionStartDate: '2024-08-20',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 2304,
        deliveryDate: '2024-08-27',
        productionStartDate: '2024-08-12',
        supplier: { kind: 'external', supplier_number: '62093' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 468,
        deliveryDate: '2024-09-13',
        productionStartDate: '2024-09-06',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 468,
        deliveryDate: '2024-09-13',
        productionStartDate: '2024-09-06',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 468,
        deliveryDate: '2024-09-13',
        productionStartDate: '2024-09-06',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 468,
        deliveryDate: '2024-09-13',
        productionStartDate: '2024-09-06',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 468,
        deliveryDate: '2024-09-13',
        productionStartDate: '2024-09-06',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 2304,
        deliveryDate: '2024-09-26',
        productionStartDate: '2024-09-11',
        supplier: { kind: 'external', supplier_number: '62093' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 468,
        deliveryDate: '2024-10-11',
        productionStartDate: '2024-10-04',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 468,
        deliveryDate: '2024-10-11',
        productionStartDate: '2024-10-04',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 468,
        deliveryDate: '2024-10-11',
        productionStartDate: '2024-10-04',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 468,
        deliveryDate: '2024-10-22',
        productionStartDate: '2024-10-15',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 468,
        deliveryDate: '2024-10-22',
        productionStartDate: '2024-10-15',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 2304,
        deliveryDate: '2024-10-22',
        productionStartDate: '2024-10-07',
        supplier: { kind: 'external', supplier_number: '62093' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 468,
        deliveryDate: '2024-11-07',
        productionStartDate: '2024-10-30',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 468,
        deliveryDate: '2024-11-08',
        productionStartDate: '2024-10-31',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 468,
        deliveryDate: '2024-11-08',
        productionStartDate: '2024-10-31',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 468,
        deliveryDate: '2024-11-08',
        productionStartDate: '2024-10-31',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 468,
        deliveryDate: '2024-11-15',
        productionStartDate: '2024-11-08',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 2304,
        deliveryDate: '2024-11-22',
        productionStartDate: '2024-11-07',
        supplier: { kind: 'external', supplier_number: '62093' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 468,
        deliveryDate: '2024-11-29',
        productionStartDate: '2024-11-22',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 468,
        deliveryDate: '2024-12-06',
        productionStartDate: '2024-11-29',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 468,
        deliveryDate: '2024-12-06',
        productionStartDate: '2024-11-29',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 468,
        deliveryDate: '2024-12-06',
        productionStartDate: '2024-11-29',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 2304,
        deliveryDate: '2024-12-06',
        productionStartDate: '2024-11-21',
        supplier: { kind: 'external', supplier_number: '62093' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 468,
        deliveryDate: '2024-12-19',
        productionStartDate: '2024-12-12',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 468,
        deliveryDate: '2024-12-19',
        productionStartDate: '2024-12-12',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 468,
        deliveryDate: '2025-01-02',
        productionStartDate: '2024-12-19',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 468,
        deliveryDate: '2025-01-02',
        productionStartDate: '2024-12-19',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 468,
        deliveryDate: '2025-01-02',
        productionStartDate: '2024-12-19',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 2304,
        deliveryDate: '2025-01-02',
        productionStartDate: '2024-12-18',
        supplier: { kind: 'external', supplier_number: '62093' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 468,
        deliveryDate: '2025-01-17',
        productionStartDate: '2025-01-10',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 468,
        deliveryDate: '2025-01-17',
        productionStartDate: '2025-01-10',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 468,
        deliveryDate: '2025-01-17',
        productionStartDate: '2025-01-10',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 468,
        deliveryDate: '2025-01-17',
        productionStartDate: '2025-01-10',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 468,
        deliveryDate: '2025-01-17',
        productionStartDate: '2025-01-10',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 2304,
        deliveryDate: '2025-01-28',
        productionStartDate: '2025-01-13',
        supplier: { kind: 'external', supplier_number: '62093' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 468,
        deliveryDate: '2025-02-07',
        productionStartDate: '2025-01-31',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 468,
        deliveryDate: '2025-02-07',
        productionStartDate: '2025-01-31',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 468,
        deliveryDate: '2025-02-07',
        productionStartDate: '2025-01-31',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 468,
        deliveryDate: '2025-02-07',
        productionStartDate: '2025-01-31',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 468,
        deliveryDate: '2025-02-07',
        productionStartDate: '2025-01-31',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 2304,
        deliveryDate: '2025-02-07',
        productionStartDate: '2025-01-23',
        supplier: { kind: 'external', supplier_number: '62093' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 468,
        deliveryDate: '2025-02-14',
        productionStartDate: '2025-02-07',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 468,
        deliveryDate: '2025-02-14',
        productionStartDate: '2025-02-07',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 468,
        deliveryDate: '2025-02-14',
        productionStartDate: '2025-02-07',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 468,
        deliveryDate: '2025-02-14',
        productionStartDate: '2025-02-07',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 468,
        deliveryDate: '2025-02-14',
        productionStartDate: '2025-02-07',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 2304,
        deliveryDate: '2025-03-12',
        productionStartDate: '2025-02-25',
        supplier: { kind: 'external', supplier_number: '62093' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 468,
        deliveryDate: '2025-03-17',
        productionStartDate: '2025-03-10',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 468,
        deliveryDate: '2025-03-17',
        productionStartDate: '2025-03-10',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 468,
        deliveryDate: '2025-03-17',
        productionStartDate: '2025-03-10',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 468,
        deliveryDate: '2025-03-17',
        productionStartDate: '2025-03-10',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 468,
        deliveryDate: '2025-03-17',
        productionStartDate: '2025-03-10',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 2304,
        deliveryDate: '2025-03-17',
        productionStartDate: '2025-02-28',
        supplier: { kind: 'external', supplier_number: '62093' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 468,
        deliveryDate: '2025-04-10',
        productionStartDate: '2025-04-03',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 468,
        deliveryDate: '2025-04-10',
        productionStartDate: '2025-04-03',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 468,
        deliveryDate: '2025-04-10',
        productionStartDate: '2025-04-03',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 468,
        deliveryDate: '2025-04-10',
        productionStartDate: '2025-04-03',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 468,
        deliveryDate: '2025-04-10',
        productionStartDate: '2025-04-03',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 2304,
        deliveryDate: '2025-04-10',
        productionStartDate: '2025-03-26',
        supplier: { kind: 'external', supplier_number: '62093' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 468,
        deliveryDate: '2025-04-10',
        productionStartDate: '2025-04-03',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 468,
        deliveryDate: '2025-04-17',
        productionStartDate: '2025-04-10',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 468,
        deliveryDate: '2025-05-13',
        productionStartDate: '2025-05-06',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 468,
        deliveryDate: '2025-05-13',
        productionStartDate: '2025-05-06',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 468,
        deliveryDate: '2025-05-13',
        productionStartDate: '2025-05-06',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 2304,
        deliveryDate: '2025-05-13',
        productionStartDate: '2025-04-28',
        supplier: { kind: 'external', supplier_number: '62093' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 468,
        deliveryDate: '2025-05-13',
        productionStartDate: '2025-05-06',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 468,
        deliveryDate: '2025-05-13',
        productionStartDate: '2025-05-06',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 468,
        deliveryDate: '2025-05-13',
        productionStartDate: '2025-05-06',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 468,
        deliveryDate: '2025-05-13',
        productionStartDate: '2025-05-06',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 468,
        deliveryDate: '2025-05-13',
        productionStartDate: '2025-05-06',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 2304,
        deliveryDate: '2025-05-21',
        productionStartDate: '2025-05-06',
        supplier: { kind: 'external', supplier_number: '62093' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 468,
        deliveryDate: '2025-06-12',
        productionStartDate: '2025-06-04',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 468,
        deliveryDate: '2025-06-12',
        productionStartDate: '2025-06-04',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 468,
        deliveryDate: '2025-06-12',
        productionStartDate: '2025-06-04',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 468,
        deliveryDate: '2025-06-12',
        productionStartDate: '2025-06-04',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 468,
        deliveryDate: '2025-06-12',
        productionStartDate: '2025-06-04',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 2304,
        deliveryDate: '2025-06-12',
        productionStartDate: '2025-05-28',
        supplier: { kind: 'external', supplier_number: '62093' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 468,
        deliveryDate: '2025-07-17',
        productionStartDate: '2025-07-10',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 468,
        deliveryDate: '2025-07-17',
        productionStartDate: '2025-07-10',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 468,
        deliveryDate: '2025-07-17',
        productionStartDate: '2025-07-10',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 468,
        deliveryDate: '2025-07-17',
        productionStartDate: '2025-07-10',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 468,
        deliveryDate: '2025-07-17',
        productionStartDate: '2025-07-10',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 2304,
        deliveryDate: '2025-07-17',
        productionStartDate: '2025-07-02',
        supplier: { kind: 'external', supplier_number: '62093' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 468,
        deliveryDate: '2025-07-17',
        productionStartDate: '2025-07-10',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 468,
        deliveryDate: '2025-07-17',
        productionStartDate: '2025-07-10',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 468,
        deliveryDate: '2025-08-11',
        productionStartDate: '2025-08-04',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 468,
        deliveryDate: '2025-08-11',
        productionStartDate: '2025-08-04',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 468,
        deliveryDate: '2025-08-11',
        productionStartDate: '2025-08-04',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 2304,
        deliveryDate: '2025-08-11',
        productionStartDate: '2025-07-25',
        supplier: { kind: 'external', supplier_number: '62093' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 468,
        deliveryDate: '2025-08-11',
        productionStartDate: '2025-08-04',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 468,
        deliveryDate: '2025-08-11',
        productionStartDate: '2025-08-04',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 468,
        deliveryDate: '2025-08-11',
        productionStartDate: '2025-08-04',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 468,
        deliveryDate: '2025-08-11',
        productionStartDate: '2025-08-04',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 468,
        deliveryDate: '2025-08-11',
        productionStartDate: '2025-08-04',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 2304,
        deliveryDate: '2025-08-22',
        productionStartDate: '2025-08-07',
        supplier: { kind: 'external', supplier_number: '62093' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 468,
        deliveryDate: '2025-09-16',
        productionStartDate: '2025-09-09',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 468,
        deliveryDate: '2025-09-16',
        productionStartDate: '2025-09-09',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 468,
        deliveryDate: '2025-09-16',
        productionStartDate: '2025-09-09',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 468,
        deliveryDate: '2025-09-16',
        productionStartDate: '2025-09-09',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 2304,
        deliveryDate: '2025-09-16',
        productionStartDate: '2025-09-01',
        supplier: { kind: 'external', supplier_number: '62093' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 468,
        deliveryDate: '2025-10-22',
        productionStartDate: '2025-10-15',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 468,
        deliveryDate: '2025-11-21',
        productionStartDate: '2025-11-14',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 468,
        deliveryDate: '2025-12-15',
        productionStartDate: '2025-12-08',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 3402,
        deliveryDate: '2024-07-31',
        productionStartDate: '2024-07-16',
        supplier: { kind: 'external', supplier_number: '62093' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2024-08-22',
        productionStartDate: '2024-08-01',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2024-08-28',
        productionStartDate: '2024-08-07',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2024-08-28',
        productionStartDate: '2024-08-07',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2024-08-28',
        productionStartDate: '2024-08-07',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2024-08-30',
        productionStartDate: '2024-08-09',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2024-08-30',
        productionStartDate: '2024-08-09',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2024-08-30',
        productionStartDate: '2024-08-09',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2024-08-30',
        productionStartDate: '2024-08-09',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2024-09-11',
        productionStartDate: '2024-08-21',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2024-09-11',
        productionStartDate: '2024-08-21',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2024-09-11',
        productionStartDate: '2024-08-21',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2024-09-11',
        productionStartDate: '2024-08-21',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2024-09-11',
        productionStartDate: '2024-08-21',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2024-09-18',
        productionStartDate: '2024-08-28',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2024-09-18',
        productionStartDate: '2024-08-28',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 3402,
        deliveryDate: '2024-09-18',
        productionStartDate: '2024-09-03',
        supplier: { kind: 'external', supplier_number: '62093' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2024-10-16',
        productionStartDate: '2024-09-24',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2024-10-16',
        productionStartDate: '2024-09-24',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2024-10-22',
        productionStartDate: '2024-09-30',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2024-10-22',
        productionStartDate: '2024-09-30',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2024-10-22',
        productionStartDate: '2024-09-30',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2024-10-22',
        productionStartDate: '2024-09-30',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2024-10-25',
        productionStartDate: '2024-10-04',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2024-10-25',
        productionStartDate: '2024-10-04',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2024-10-25',
        productionStartDate: '2024-10-04',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2024-10-25',
        productionStartDate: '2024-10-04',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2024-10-25',
        productionStartDate: '2024-10-04',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2024-10-25',
        productionStartDate: '2024-10-04',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2024-11-06',
        productionStartDate: '2024-10-15',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2024-11-06',
        productionStartDate: '2024-10-15',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 3402,
        deliveryDate: '2024-11-06',
        productionStartDate: '2024-10-22',
        supplier: { kind: 'external', supplier_number: '62093' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2024-11-27',
        productionStartDate: '2024-11-06',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2024-11-27',
        productionStartDate: '2024-11-06',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2024-11-27',
        productionStartDate: '2024-11-06',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2024-12-04',
        productionStartDate: '2024-11-13',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2024-12-04',
        productionStartDate: '2024-11-13',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2024-12-04',
        productionStartDate: '2024-11-13',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2024-12-04',
        productionStartDate: '2024-11-13',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2024-12-04',
        productionStartDate: '2024-11-13',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2024-12-11',
        productionStartDate: '2024-11-20',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2024-12-11',
        productionStartDate: '2024-11-20',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2024-12-11',
        productionStartDate: '2024-11-20',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2024-12-11',
        productionStartDate: '2024-11-20',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2024-12-11',
        productionStartDate: '2024-11-20',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2024-12-13',
        productionStartDate: '2024-11-22',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 3402,
        deliveryDate: '2024-12-13',
        productionStartDate: '2024-11-28',
        supplier: { kind: 'external', supplier_number: '62093' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2025-01-08',
        productionStartDate: '2024-12-10',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2025-01-15',
        productionStartDate: '2024-12-17',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2025-01-15',
        productionStartDate: '2024-12-17',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2025-01-15',
        productionStartDate: '2024-12-17',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2025-01-15',
        productionStartDate: '2024-12-17',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2025-01-15',
        productionStartDate: '2024-12-17',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2025-01-22',
        productionStartDate: '2024-12-27',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2025-01-22',
        productionStartDate: '2024-12-27',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2025-01-22',
        productionStartDate: '2024-12-27',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2025-01-22',
        productionStartDate: '2024-12-27',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2025-01-27',
        productionStartDate: '2025-01-03',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2025-01-27',
        productionStartDate: '2025-01-03',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2025-01-27',
        productionStartDate: '2025-01-03',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2025-01-27',
        productionStartDate: '2025-01-03',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 3402,
        deliveryDate: '2025-01-27',
        productionStartDate: '2025-01-10',
        supplier: { kind: 'external', supplier_number: '62093' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2025-02-19',
        productionStartDate: '2025-01-29',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2025-02-19',
        productionStartDate: '2025-01-29',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2025-02-19',
        productionStartDate: '2025-01-29',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2025-02-19',
        productionStartDate: '2025-01-29',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2025-02-19',
        productionStartDate: '2025-01-29',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2025-02-19',
        productionStartDate: '2025-01-29',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2025-02-19',
        productionStartDate: '2025-01-29',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2025-02-19',
        productionStartDate: '2025-01-29',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2025-02-19',
        productionStartDate: '2025-01-29',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2025-02-19',
        productionStartDate: '2025-01-29',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2025-02-19',
        productionStartDate: '2025-01-29',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2025-02-19',
        productionStartDate: '2025-01-29',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2025-02-19',
        productionStartDate: '2025-01-29',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2025-02-19',
        productionStartDate: '2025-01-29',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 3402,
        deliveryDate: '2025-02-19',
        productionStartDate: '2025-02-04',
        supplier: { kind: 'external', supplier_number: '62093' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2025-03-20',
        productionStartDate: '2025-02-27',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2025-03-20',
        productionStartDate: '2025-02-27',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2025-03-20',
        productionStartDate: '2025-02-27',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2025-03-20',
        productionStartDate: '2025-02-27',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2025-03-20',
        productionStartDate: '2025-02-27',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2025-03-20',
        productionStartDate: '2025-02-27',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2025-03-20',
        productionStartDate: '2025-02-27',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2025-03-20',
        productionStartDate: '2025-02-27',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2025-03-20',
        productionStartDate: '2025-02-27',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2025-03-20',
        productionStartDate: '2025-02-27',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2025-03-20',
        productionStartDate: '2025-02-27',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2025-03-20',
        productionStartDate: '2025-02-27',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2025-03-20',
        productionStartDate: '2025-02-27',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2025-03-20',
        productionStartDate: '2025-02-27',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2025-03-20',
        productionStartDate: '2025-02-27',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 3402,
        deliveryDate: '2025-03-27',
        productionStartDate: '2025-03-12',
        supplier: { kind: 'external', supplier_number: '62093' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2025-04-17',
        productionStartDate: '2025-03-27',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2025-04-17',
        productionStartDate: '2025-03-27',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2025-04-17',
        productionStartDate: '2025-03-27',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2025-04-17',
        productionStartDate: '2025-03-27',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2025-04-17',
        productionStartDate: '2025-03-27',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2025-04-17',
        productionStartDate: '2025-03-27',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2025-04-17',
        productionStartDate: '2025-03-27',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2025-04-17',
        productionStartDate: '2025-03-27',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2025-05-16',
        productionStartDate: '2025-04-24',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2025-05-16',
        productionStartDate: '2025-04-24',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2025-05-16',
        productionStartDate: '2025-04-24',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2025-05-16',
        productionStartDate: '2025-04-24',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2025-05-16',
        productionStartDate: '2025-04-24',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2025-05-16',
        productionStartDate: '2025-04-24',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 3402,
        deliveryDate: '2025-05-16',
        productionStartDate: '2025-04-30',
        supplier: { kind: 'external', supplier_number: '62093' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2025-05-16',
        productionStartDate: '2025-04-24',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2025-05-16',
        productionStartDate: '2025-04-24',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2025-06-16',
        productionStartDate: '2025-05-21',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2025-06-16',
        productionStartDate: '2025-05-21',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2025-06-16',
        productionStartDate: '2025-05-21',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2025-06-16',
        productionStartDate: '2025-05-21',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2025-06-16',
        productionStartDate: '2025-05-21',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2025-06-16',
        productionStartDate: '2025-05-21',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2025-06-16',
        productionStartDate: '2025-05-21',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2025-06-16',
        productionStartDate: '2025-05-21',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2025-06-16',
        productionStartDate: '2025-05-21',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2025-06-16',
        productionStartDate: '2025-05-21',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2025-06-16',
        productionStartDate: '2025-05-21',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2025-06-16',
        productionStartDate: '2025-05-21',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 3402,
        deliveryDate: '2025-06-16',
        productionStartDate: '2025-05-28',
        supplier: { kind: 'external', supplier_number: '62093' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2025-07-22',
        productionStartDate: '2025-07-01',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2025-07-22',
        productionStartDate: '2025-07-01',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2025-07-22',
        productionStartDate: '2025-07-01',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2025-07-22',
        productionStartDate: '2025-07-01',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2025-07-22',
        productionStartDate: '2025-07-01',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2025-07-22',
        productionStartDate: '2025-07-01',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2025-07-22',
        productionStartDate: '2025-07-01',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2025-07-22',
        productionStartDate: '2025-07-01',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2025-07-22',
        productionStartDate: '2025-07-01',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2025-07-22',
        productionStartDate: '2025-07-01',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2025-07-22',
        productionStartDate: '2025-07-01',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2025-07-22',
        productionStartDate: '2025-07-01',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2025-07-22',
        productionStartDate: '2025-07-01',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2025-07-22',
        productionStartDate: '2025-07-01',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 3402,
        deliveryDate: '2025-07-22',
        productionStartDate: '2025-07-07',
        supplier: { kind: 'external', supplier_number: '62093' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2025-08-20',
        productionStartDate: '2025-07-30',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2025-08-20',
        productionStartDate: '2025-07-30',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2025-08-20',
        productionStartDate: '2025-07-30',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2025-08-20',
        productionStartDate: '2025-07-30',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2025-08-20',
        productionStartDate: '2025-07-30',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2025-08-20',
        productionStartDate: '2025-07-30',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2025-08-20',
        productionStartDate: '2025-07-30',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2025-08-20',
        productionStartDate: '2025-07-30',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2025-08-20',
        productionStartDate: '2025-07-30',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2025-08-20',
        productionStartDate: '2025-07-30',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2025-08-27',
        productionStartDate: '2025-08-06',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2025-08-27',
        productionStartDate: '2025-08-06',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2025-09-19',
        productionStartDate: '2025-08-29',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2025-09-19',
        productionStartDate: '2025-08-29',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 3402,
        deliveryDate: '2025-09-19',
        productionStartDate: '2025-09-04',
        supplier: { kind: 'external', supplier_number: '62093' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2025-09-19',
        productionStartDate: '2025-08-29',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2025-09-19',
        productionStartDate: '2025-08-29',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2025-09-19',
        productionStartDate: '2025-08-29',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2025-09-19',
        productionStartDate: '2025-08-29',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2025-09-19',
        productionStartDate: '2025-08-29',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2025-09-19',
        productionStartDate: '2025-08-29',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2025-10-27',
        productionStartDate: '2025-10-06',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2025-11-26',
        productionStartDate: '2025-11-05',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2025-11-26',
        productionStartDate: '2025-11-05',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2025-12-18',
        productionStartDate: '2025-11-27',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2025-12-18',
        productionStartDate: '2025-11-27',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-5',
        quantity: 936,
        deliveryDate: '2024-07-16',
        productionStartDate: '2024-07-03',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-5',
        quantity: 936,
        deliveryDate: '2024-07-16',
        productionStartDate: '2024-07-03',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-5',
        quantity: 936,
        deliveryDate: '2024-07-18',
        productionStartDate: '2024-07-05',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-5',
        quantity: 936,
        deliveryDate: '2024-08-08',
        productionStartDate: '2024-07-26',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-5',
        quantity: 936,
        deliveryDate: '2024-08-08',
        productionStartDate: '2024-07-26',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-5',
        quantity: 936,
        deliveryDate: '2024-09-05',
        productionStartDate: '2024-08-23',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-5',
        quantity: 936,
        deliveryDate: '2024-09-05',
        productionStartDate: '2024-08-23',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-5',
        quantity: 936,
        deliveryDate: '2024-09-05',
        productionStartDate: '2024-08-23',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-5',
        quantity: 936,
        deliveryDate: '2024-10-02',
        productionStartDate: '2024-09-19',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-5',
        quantity: 936,
        deliveryDate: '2024-10-14',
        productionStartDate: '2024-09-30',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-5',
        quantity: 936,
        deliveryDate: '2024-10-29',
        productionStartDate: '2024-10-16',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-5',
        quantity: 936,
        deliveryDate: '2024-10-30',
        productionStartDate: '2024-10-17',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-5',
        quantity: 936,
        deliveryDate: '2024-11-07',
        productionStartDate: '2024-10-24',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-5',
        quantity: 936,
        deliveryDate: '2024-11-28',
        productionStartDate: '2024-11-15',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-5',
        quantity: 936,
        deliveryDate: '2024-11-28',
        productionStartDate: '2024-11-15',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-5',
        quantity: 936,
        deliveryDate: '2024-12-11',
        productionStartDate: '2024-11-28',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-5',
        quantity: 936,
        deliveryDate: '2024-12-18',
        productionStartDate: '2024-12-05',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-5',
        quantity: 936,
        deliveryDate: '2025-01-09',
        productionStartDate: '2024-12-19',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-5',
        quantity: 936,
        deliveryDate: '2025-01-09',
        productionStartDate: '2024-12-19',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-5',
        quantity: 936,
        deliveryDate: '2025-01-09',
        productionStartDate: '2024-12-19',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-5',
        quantity: 936,
        deliveryDate: '2025-01-30',
        productionStartDate: '2025-01-17',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-5',
        quantity: 936,
        deliveryDate: '2025-01-30',
        productionStartDate: '2025-01-17',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-5',
        quantity: 936,
        deliveryDate: '2025-02-06',
        productionStartDate: '2025-01-24',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-5',
        quantity: 936,
        deliveryDate: '2025-02-06',
        productionStartDate: '2025-01-24',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-5',
        quantity: 936,
        deliveryDate: '2025-02-06',
        productionStartDate: '2025-01-24',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-5',
        quantity: 936,
        deliveryDate: '2025-03-07',
        productionStartDate: '2025-02-24',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-5',
        quantity: 936,
        deliveryDate: '2025-03-07',
        productionStartDate: '2025-02-24',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-5',
        quantity: 936,
        deliveryDate: '2025-04-02',
        productionStartDate: '2025-03-20',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-5',
        quantity: 936,
        deliveryDate: '2025-04-02',
        productionStartDate: '2025-03-20',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-5',
        quantity: 936,
        deliveryDate: '2025-04-02',
        productionStartDate: '2025-03-20',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-5',
        quantity: 936,
        deliveryDate: '2025-04-09',
        productionStartDate: '2025-03-27',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-5',
        quantity: 936,
        deliveryDate: '2025-05-05',
        productionStartDate: '2025-04-17',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-5',
        quantity: 936,
        deliveryDate: '2025-05-05',
        productionStartDate: '2025-04-17',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-5',
        quantity: 936,
        deliveryDate: '2025-05-05',
        productionStartDate: '2025-04-17',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-5',
        quantity: 936,
        deliveryDate: '2025-05-05',
        productionStartDate: '2025-04-17',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-5',
        quantity: 936,
        deliveryDate: '2025-06-03',
        productionStartDate: '2025-05-19',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-5',
        quantity: 936,
        deliveryDate: '2025-06-03',
        productionStartDate: '2025-05-19',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-5',
        quantity: 936,
        deliveryDate: '2025-07-09',
        productionStartDate: '2025-06-26',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-5',
        quantity: 936,
        deliveryDate: '2025-07-09',
        productionStartDate: '2025-06-26',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-5',
        quantity: 936,
        deliveryDate: '2025-07-09',
        productionStartDate: '2025-06-26',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-5',
        quantity: 936,
        deliveryDate: '2025-07-09',
        productionStartDate: '2025-06-26',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-5',
        quantity: 936,
        deliveryDate: '2025-08-01',
        productionStartDate: '2025-07-21',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-5',
        quantity: 936,
        deliveryDate: '2025-08-01',
        productionStartDate: '2025-07-21',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-5',
        quantity: 936,
        deliveryDate: '2025-08-01',
        productionStartDate: '2025-07-21',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-5',
        quantity: 936,
        deliveryDate: '2025-08-01',
        productionStartDate: '2025-07-21',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-5',
        quantity: 936,
        deliveryDate: '2025-09-08',
        productionStartDate: '2025-08-26',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-5',
        quantity: 936,
        deliveryDate: '2025-09-08',
        productionStartDate: '2025-08-26',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-5',
        quantity: 936,
        deliveryDate: '2025-11-13',
        productionStartDate: '2025-10-31',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-7',
        quantity: 480,
        deliveryDate: '2024-08-01',
        productionStartDate: '2024-07-24',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-7',
        quantity: 480,
        deliveryDate: '2024-08-07',
        productionStartDate: '2024-07-30',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-7',
        quantity: 480,
        deliveryDate: '2024-08-07',
        productionStartDate: '2024-07-30',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-7',
        quantity: 480,
        deliveryDate: '2024-08-07',
        productionStartDate: '2024-07-30',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-7',
        quantity: 480,
        deliveryDate: '2024-08-21',
        productionStartDate: '2024-08-13',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-7',
        quantity: 480,
        deliveryDate: '2024-08-21',
        productionStartDate: '2024-08-13',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-7',
        quantity: 480,
        deliveryDate: '2024-08-21',
        productionStartDate: '2024-08-13',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-7',
        quantity: 480,
        deliveryDate: '2024-08-28',
        productionStartDate: '2024-08-20',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-7',
        quantity: 480,
        deliveryDate: '2024-09-24',
        productionStartDate: '2024-09-16',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-7',
        quantity: 480,
        deliveryDate: '2024-09-30',
        productionStartDate: '2024-09-20',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-7',
        quantity: 480,
        deliveryDate: '2024-09-30',
        productionStartDate: '2024-09-20',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-7',
        quantity: 480,
        deliveryDate: '2024-09-30',
        productionStartDate: '2024-09-20',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-7',
        quantity: 480,
        deliveryDate: '2024-09-30',
        productionStartDate: '2024-09-20',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-7',
        quantity: 480,
        deliveryDate: '2024-09-30',
        productionStartDate: '2024-09-20',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-7',
        quantity: 480,
        deliveryDate: '2024-10-15',
        productionStartDate: '2024-10-07',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-7',
        quantity: 480,
        deliveryDate: '2024-11-06',
        productionStartDate: '2024-10-28',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-7',
        quantity: 480,
        deliveryDate: '2024-11-13',
        productionStartDate: '2024-11-05',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-7',
        quantity: 480,
        deliveryDate: '2024-11-13',
        productionStartDate: '2024-11-05',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-7',
        quantity: 480,
        deliveryDate: '2024-11-13',
        productionStartDate: '2024-11-05',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-7',
        quantity: 480,
        deliveryDate: '2024-11-20',
        productionStartDate: '2024-11-12',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-7',
        quantity: 480,
        deliveryDate: '2024-11-20',
        productionStartDate: '2024-11-12',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-7',
        quantity: 480,
        deliveryDate: '2024-11-20',
        productionStartDate: '2024-11-12',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-7',
        quantity: 480,
        deliveryDate: '2024-12-17',
        productionStartDate: '2024-12-09',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-7',
        quantity: 480,
        deliveryDate: '2024-12-17',
        productionStartDate: '2024-12-09',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-7',
        quantity: 480,
        deliveryDate: '2024-12-17',
        productionStartDate: '2024-12-09',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-7',
        quantity: 480,
        deliveryDate: '2024-12-27',
        productionStartDate: '2024-12-16',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-7',
        quantity: 480,
        deliveryDate: '2024-12-27',
        productionStartDate: '2024-12-16',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-7',
        quantity: 480,
        deliveryDate: '2025-01-03',
        productionStartDate: '2024-12-19',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-7',
        quantity: 480,
        deliveryDate: '2025-01-03',
        productionStartDate: '2024-12-19',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-7',
        quantity: 480,
        deliveryDate: '2025-01-29',
        productionStartDate: '2025-01-21',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-7',
        quantity: 480,
        deliveryDate: '2025-01-29',
        productionStartDate: '2025-01-21',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-7',
        quantity: 480,
        deliveryDate: '2025-01-29',
        productionStartDate: '2025-01-21',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-7',
        quantity: 480,
        deliveryDate: '2025-01-29',
        productionStartDate: '2025-01-21',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-7',
        quantity: 480,
        deliveryDate: '2025-01-29',
        productionStartDate: '2025-01-21',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-7',
        quantity: 480,
        deliveryDate: '2025-01-29',
        productionStartDate: '2025-01-21',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-7',
        quantity: 480,
        deliveryDate: '2025-01-29',
        productionStartDate: '2025-01-21',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-7',
        quantity: 480,
        deliveryDate: '2025-02-27',
        productionStartDate: '2025-02-19',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-7',
        quantity: 480,
        deliveryDate: '2025-02-27',
        productionStartDate: '2025-02-19',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-7',
        quantity: 480,
        deliveryDate: '2025-02-27',
        productionStartDate: '2025-02-19',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-7',
        quantity: 480,
        deliveryDate: '2025-02-27',
        productionStartDate: '2025-02-19',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-7',
        quantity: 480,
        deliveryDate: '2025-02-27',
        productionStartDate: '2025-02-19',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-7',
        quantity: 480,
        deliveryDate: '2025-02-27',
        productionStartDate: '2025-02-19',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-7',
        quantity: 480,
        deliveryDate: '2025-02-27',
        productionStartDate: '2025-02-19',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-7',
        quantity: 480,
        deliveryDate: '2025-03-27',
        productionStartDate: '2025-03-19',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-7',
        quantity: 480,
        deliveryDate: '2025-03-27',
        productionStartDate: '2025-03-19',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-7',
        quantity: 480,
        deliveryDate: '2025-03-27',
        productionStartDate: '2025-03-19',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-7',
        quantity: 480,
        deliveryDate: '2025-03-27',
        productionStartDate: '2025-03-19',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-7',
        quantity: 480,
        deliveryDate: '2025-04-24',
        productionStartDate: '2025-04-14',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-7',
        quantity: 480,
        deliveryDate: '2025-04-24',
        productionStartDate: '2025-04-14',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-7',
        quantity: 480,
        deliveryDate: '2025-04-24',
        productionStartDate: '2025-04-14',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-7',
        quantity: 480,
        deliveryDate: '2025-04-24',
        productionStartDate: '2025-04-14',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-7',
        quantity: 480,
        deliveryDate: '2025-05-21',
        productionStartDate: '2025-05-13',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-7',
        quantity: 480,
        deliveryDate: '2025-05-21',
        productionStartDate: '2025-05-13',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-7',
        quantity: 480,
        deliveryDate: '2025-05-21',
        productionStartDate: '2025-05-13',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-7',
        quantity: 480,
        deliveryDate: '2025-05-21',
        productionStartDate: '2025-05-13',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-7',
        quantity: 480,
        deliveryDate: '2025-05-21',
        productionStartDate: '2025-05-13',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-7',
        quantity: 480,
        deliveryDate: '2025-05-21',
        productionStartDate: '2025-05-13',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-7',
        quantity: 480,
        deliveryDate: '2025-07-01',
        productionStartDate: '2025-06-23',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-7',
        quantity: 480,
        deliveryDate: '2025-07-01',
        productionStartDate: '2025-06-23',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-7',
        quantity: 480,
        deliveryDate: '2025-07-01',
        productionStartDate: '2025-06-23',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-7',
        quantity: 480,
        deliveryDate: '2025-07-01',
        productionStartDate: '2025-06-23',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-7',
        quantity: 480,
        deliveryDate: '2025-07-01',
        productionStartDate: '2025-06-23',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-7',
        quantity: 480,
        deliveryDate: '2025-07-01',
        productionStartDate: '2025-06-23',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-7',
        quantity: 480,
        deliveryDate: '2025-07-01',
        productionStartDate: '2025-06-23',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-7',
        quantity: 480,
        deliveryDate: '2025-07-30',
        productionStartDate: '2025-07-22',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-7',
        quantity: 480,
        deliveryDate: '2025-07-30',
        productionStartDate: '2025-07-22',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-7',
        quantity: 480,
        deliveryDate: '2025-07-30',
        productionStartDate: '2025-07-22',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-7',
        quantity: 480,
        deliveryDate: '2025-07-30',
        productionStartDate: '2025-07-22',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-7',
        quantity: 480,
        deliveryDate: '2025-07-30',
        productionStartDate: '2025-07-22',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-7',
        quantity: 480,
        deliveryDate: '2025-08-06',
        productionStartDate: '2025-07-29',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-7',
        quantity: 480,
        deliveryDate: '2025-08-29',
        productionStartDate: '2025-08-21',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-7',
        quantity: 480,
        deliveryDate: '2025-08-29',
        productionStartDate: '2025-08-21',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-7',
        quantity: 480,
        deliveryDate: '2025-08-29',
        productionStartDate: '2025-08-21',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-7',
        quantity: 480,
        deliveryDate: '2025-08-29',
        productionStartDate: '2025-08-21',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-7',
        quantity: 480,
        deliveryDate: '2025-10-06',
        productionStartDate: '2025-09-25',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-7',
        quantity: 480,
        deliveryDate: '2025-11-05',
        productionStartDate: '2025-10-28',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-7',
        quantity: 480,
        deliveryDate: '2025-11-27',
        productionStartDate: '2025-11-19',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 2304,
        deliveryDate: '2024-07-19',
        productionStartDate: '2024-07-04',
        supplier: { kind: 'external', supplier_number: '62093' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 468,
        deliveryDate: '2024-08-05',
        productionStartDate: '2024-07-29',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 468,
        deliveryDate: '2024-08-05',
        productionStartDate: '2024-07-29',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 2304,
        deliveryDate: '2024-08-12',
        productionStartDate: '2024-07-26',
        supplier: { kind: 'external', supplier_number: '62093' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 468,
        deliveryDate: '2024-09-06',
        productionStartDate: '2024-08-30',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 468,
        deliveryDate: '2024-09-06',
        productionStartDate: '2024-08-30',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 468,
        deliveryDate: '2024-09-13',
        productionStartDate: '2024-09-06',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 468,
        deliveryDate: '2024-09-13',
        productionStartDate: '2024-09-06',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 468,
        deliveryDate: '2024-09-13',
        productionStartDate: '2024-09-06',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 2304,
        deliveryDate: '2024-09-13',
        productionStartDate: '2024-08-29',
        supplier: { kind: 'external', supplier_number: '62093' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 468,
        deliveryDate: '2024-10-14',
        productionStartDate: '2024-10-07',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 468,
        deliveryDate: '2024-10-14',
        productionStartDate: '2024-10-07',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 468,
        deliveryDate: '2024-10-14',
        productionStartDate: '2024-10-07',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 468,
        deliveryDate: '2024-10-14',
        productionStartDate: '2024-10-07',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 468,
        deliveryDate: '2024-10-24',
        productionStartDate: '2024-10-17',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 2304,
        deliveryDate: '2024-10-24',
        productionStartDate: '2024-10-09',
        supplier: { kind: 'external', supplier_number: '62093' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 468,
        deliveryDate: '2024-11-14',
        productionStartDate: '2024-11-07',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 468,
        deliveryDate: '2024-11-15',
        productionStartDate: '2024-11-08',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 468,
        deliveryDate: '2024-11-15',
        productionStartDate: '2024-11-08',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 468,
        deliveryDate: '2024-11-22',
        productionStartDate: '2024-11-15',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 2304,
        deliveryDate: '2024-11-22',
        productionStartDate: '2024-11-07',
        supplier: { kind: 'external', supplier_number: '62093' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 468,
        deliveryDate: '2024-12-06',
        productionStartDate: '2024-11-29',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 468,
        deliveryDate: '2024-12-06',
        productionStartDate: '2024-11-29',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 468,
        deliveryDate: '2024-12-06',
        productionStartDate: '2024-11-29',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 468,
        deliveryDate: '2024-12-16',
        productionStartDate: '2024-12-09',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 468,
        deliveryDate: '2024-12-16',
        productionStartDate: '2024-12-09',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 2304,
        deliveryDate: '2024-12-16',
        productionStartDate: '2024-11-29',
        supplier: { kind: 'external', supplier_number: '62093' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 468,
        deliveryDate: '2025-01-10',
        productionStartDate: '2025-01-02',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 468,
        deliveryDate: '2025-01-17',
        productionStartDate: '2025-01-10',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 468,
        deliveryDate: '2025-01-17',
        productionStartDate: '2025-01-10',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 468,
        deliveryDate: '2025-01-17',
        productionStartDate: '2025-01-10',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 468,
        deliveryDate: '2025-01-17',
        productionStartDate: '2025-01-10',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 2304,
        deliveryDate: '2025-01-17',
        productionStartDate: '2025-01-02',
        supplier: { kind: 'external', supplier_number: '62093' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 468,
        deliveryDate: '2025-02-07',
        productionStartDate: '2025-01-31',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 468,
        deliveryDate: '2025-02-14',
        productionStartDate: '2025-02-07',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 468,
        deliveryDate: '2025-02-14',
        productionStartDate: '2025-02-07',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 468,
        deliveryDate: '2025-02-14',
        productionStartDate: '2025-02-07',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 468,
        deliveryDate: '2025-02-14',
        productionStartDate: '2025-02-07',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 2304,
        deliveryDate: '2025-02-14',
        productionStartDate: '2025-01-30',
        supplier: { kind: 'external', supplier_number: '62093' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 468,
        deliveryDate: '2025-03-07',
        productionStartDate: '2025-02-28',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 468,
        deliveryDate: '2025-03-14',
        productionStartDate: '2025-03-07',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 468,
        deliveryDate: '2025-03-14',
        productionStartDate: '2025-03-07',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 468,
        deliveryDate: '2025-03-14',
        productionStartDate: '2025-03-07',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 468,
        deliveryDate: '2025-03-14',
        productionStartDate: '2025-03-07',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 2304,
        deliveryDate: '2025-03-14',
        productionStartDate: '2025-02-27',
        supplier: { kind: 'external', supplier_number: '62093' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 468,
        deliveryDate: '2025-04-03',
        productionStartDate: '2025-03-27',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 468,
        deliveryDate: '2025-04-11',
        productionStartDate: '2025-04-04',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 468,
        deliveryDate: '2025-04-11',
        productionStartDate: '2025-04-04',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 468,
        deliveryDate: '2025-04-11',
        productionStartDate: '2025-04-04',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 468,
        deliveryDate: '2025-04-17',
        productionStartDate: '2025-04-10',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 2304,
        deliveryDate: '2025-04-17',
        productionStartDate: '2025-04-02',
        supplier: { kind: 'external', supplier_number: '62093' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 468,
        deliveryDate: '2025-05-09',
        productionStartDate: '2025-05-02',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 468,
        deliveryDate: '2025-05-09',
        productionStartDate: '2025-05-02',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 468,
        deliveryDate: '2025-05-09',
        productionStartDate: '2025-05-02',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 468,
        deliveryDate: '2025-05-16',
        productionStartDate: '2025-05-09',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 468,
        deliveryDate: '2025-05-16',
        productionStartDate: '2025-05-09',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 2304,
        deliveryDate: '2025-05-27',
        productionStartDate: '2025-05-12',
        supplier: { kind: 'external', supplier_number: '62093' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 468,
        deliveryDate: '2025-06-05',
        productionStartDate: '2025-05-27',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 468,
        deliveryDate: '2025-06-12',
        productionStartDate: '2025-06-04',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 468,
        deliveryDate: '2025-06-12',
        productionStartDate: '2025-06-04',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 468,
        deliveryDate: '2025-06-12',
        productionStartDate: '2025-06-04',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 468,
        deliveryDate: '2025-06-23',
        productionStartDate: '2025-06-12',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 2304,
        deliveryDate: '2025-06-23',
        productionStartDate: '2025-06-06',
        supplier: { kind: 'external', supplier_number: '62093' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 468,
        deliveryDate: '2025-07-11',
        productionStartDate: '2025-07-04',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 468,
        deliveryDate: '2025-07-11',
        productionStartDate: '2025-07-04',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 468,
        deliveryDate: '2025-07-18',
        productionStartDate: '2025-07-11',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 468,
        deliveryDate: '2025-07-25',
        productionStartDate: '2025-07-18',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 468,
        deliveryDate: '2025-07-25',
        productionStartDate: '2025-07-18',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 2304,
        deliveryDate: '2025-08-01',
        productionStartDate: '2025-07-17',
        supplier: { kind: 'external', supplier_number: '62093' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 468,
        deliveryDate: '2025-08-14',
        productionStartDate: '2025-08-07',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 468,
        deliveryDate: '2025-08-22',
        productionStartDate: '2025-08-15',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 468,
        deliveryDate: '2025-08-22',
        productionStartDate: '2025-08-15',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 468,
        deliveryDate: '2025-08-22',
        productionStartDate: '2025-08-15',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 468,
        deliveryDate: '2025-08-29',
        productionStartDate: '2025-08-22',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 2304,
        deliveryDate: '2025-08-29',
        productionStartDate: '2025-08-14',
        supplier: { kind: 'external', supplier_number: '62093' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 468,
        deliveryDate: '2025-09-12',
        productionStartDate: '2025-09-05',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 468,
        deliveryDate: '2025-09-12',
        productionStartDate: '2025-09-05',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 468,
        deliveryDate: '2025-09-12',
        productionStartDate: '2025-09-05',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 468,
        deliveryDate: '2025-09-25',
        productionStartDate: '2025-09-18',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 468,
        deliveryDate: '2025-10-02',
        productionStartDate: '2025-09-25',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 2304,
        deliveryDate: '2025-10-02',
        productionStartDate: '2025-09-17',
        supplier: { kind: 'external', supplier_number: '62093' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 468,
        deliveryDate: '2025-10-10',
        productionStartDate: '2025-10-02',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 468,
        deliveryDate: '2025-10-10',
        productionStartDate: '2025-10-02',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 468,
        deliveryDate: '2025-10-10',
        productionStartDate: '2025-10-02',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 468,
        deliveryDate: '2025-10-10',
        productionStartDate: '2025-10-02',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 468,
        deliveryDate: '2025-10-17',
        productionStartDate: '2025-10-10',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 2304,
        deliveryDate: '2025-10-17',
        productionStartDate: '2025-10-02',
        supplier: { kind: 'external', supplier_number: '62093' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 468,
        deliveryDate: '2025-11-14',
        productionStartDate: '2025-11-07',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 468,
        deliveryDate: '2025-11-14',
        productionStartDate: '2025-11-07',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 468,
        deliveryDate: '2025-11-14',
        productionStartDate: '2025-11-07',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 468,
        deliveryDate: '2025-11-14',
        productionStartDate: '2025-11-07',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 468,
        deliveryDate: '2025-11-14',
        productionStartDate: '2025-11-07',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 2304,
        deliveryDate: '2025-11-14',
        productionStartDate: '2025-10-30',
        supplier: { kind: 'external', supplier_number: '62093' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 468,
        deliveryDate: '2025-12-10',
        productionStartDate: '2025-12-03',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 468,
        deliveryDate: '2025-12-10',
        productionStartDate: '2025-12-03',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 468,
        deliveryDate: '2025-12-10',
        productionStartDate: '2025-12-03',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 468,
        deliveryDate: '2025-12-10',
        productionStartDate: '2025-12-03',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 2304,
        deliveryDate: '2025-12-10',
        productionStartDate: '2025-11-25',
        supplier: { kind: 'external', supplier_number: '62093' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 468,
        deliveryDate: '2026-01-16',
        productionStartDate: '2026-01-09',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 468,
        deliveryDate: '2026-01-16',
        productionStartDate: '2026-01-09',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 468,
        deliveryDate: '2026-01-16',
        productionStartDate: '2026-01-09',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 468,
        deliveryDate: '2026-01-16',
        productionStartDate: '2026-01-09',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 468,
        deliveryDate: '2026-01-16',
        productionStartDate: '2026-01-09',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 2304,
        deliveryDate: '2026-01-16',
        productionStartDate: '2025-12-30',
        supplier: { kind: 'external', supplier_number: '62093' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 468,
        deliveryDate: '2026-02-10',
        productionStartDate: '2026-02-03',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 468,
        deliveryDate: '2026-02-10',
        productionStartDate: '2026-02-03',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 468,
        deliveryDate: '2026-02-10',
        productionStartDate: '2026-02-03',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 468,
        deliveryDate: '2026-02-13',
        productionStartDate: '2026-02-06',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 468,
        deliveryDate: '2026-02-13',
        productionStartDate: '2026-02-06',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 2304,
        deliveryDate: '2026-02-13',
        productionStartDate: '2026-01-29',
        supplier: { kind: 'external', supplier_number: '62093' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 468,
        deliveryDate: '2026-03-17',
        productionStartDate: '2026-03-10',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 468,
        deliveryDate: '2026-03-17',
        productionStartDate: '2026-03-10',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 468,
        deliveryDate: '2026-03-17',
        productionStartDate: '2026-03-10',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 468,
        deliveryDate: '2026-03-17',
        productionStartDate: '2026-03-10',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 468,
        deliveryDate: '2026-03-17',
        productionStartDate: '2026-03-10',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 2304,
        deliveryDate: '2026-03-17',
        productionStartDate: '2026-03-02',
        supplier: { kind: 'external', supplier_number: '62093' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 468,
        deliveryDate: '2026-04-13',
        productionStartDate: '2026-04-02',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 468,
        deliveryDate: '2026-04-13',
        productionStartDate: '2026-04-02',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 468,
        deliveryDate: '2026-04-13',
        productionStartDate: '2026-04-02',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 468,
        deliveryDate: '2026-04-13',
        productionStartDate: '2026-04-02',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 468,
        deliveryDate: '2026-04-13',
        productionStartDate: '2026-04-02',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 2304,
        deliveryDate: '2026-05-12',
        productionStartDate: '2026-04-27',
        supplier: { kind: 'external', supplier_number: '62093' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 468,
        deliveryDate: '2026-05-12',
        productionStartDate: '2026-05-05',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 468,
        deliveryDate: '2026-05-12',
        productionStartDate: '2026-05-05',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 468,
        deliveryDate: '2026-05-12',
        productionStartDate: '2026-05-05',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 468,
        deliveryDate: '2026-05-12',
        productionStartDate: '2026-05-05',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-3',
        quantity: 468,
        deliveryDate: '2026-05-12',
        productionStartDate: '2026-05-05',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2024-08-28',
        productionStartDate: '2024-08-07',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2024-08-30',
        productionStartDate: '2024-08-09',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2024-08-30',
        productionStartDate: '2024-08-09',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2024-09-11',
        productionStartDate: '2024-08-21',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2024-09-11',
        productionStartDate: '2024-08-21',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2024-09-11',
        productionStartDate: '2024-08-21',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2024-09-18',
        productionStartDate: '2024-08-28',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2024-09-18',
        productionStartDate: '2024-08-28',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2024-09-18',
        productionStartDate: '2024-08-28',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 3402,
        deliveryDate: '2024-09-18',
        productionStartDate: '2024-09-03',
        supplier: { kind: 'external', supplier_number: '62093' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2024-10-25',
        productionStartDate: '2024-10-04',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2024-10-25',
        productionStartDate: '2024-10-04',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2024-10-25',
        productionStartDate: '2024-10-04',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2024-10-31',
        productionStartDate: '2024-10-10',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2024-10-31',
        productionStartDate: '2024-10-10',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2024-10-31',
        productionStartDate: '2024-10-10',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2024-11-12',
        productionStartDate: '2024-10-21',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2024-11-12',
        productionStartDate: '2024-10-21',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2024-11-12',
        productionStartDate: '2024-10-21',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2024-11-19',
        productionStartDate: '2024-10-28',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2024-11-19',
        productionStartDate: '2024-10-28',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2024-11-19',
        productionStartDate: '2024-10-28',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2024-11-19',
        productionStartDate: '2024-10-28',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2024-11-19',
        productionStartDate: '2024-10-28',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 3402,
        deliveryDate: '2024-11-27',
        productionStartDate: '2024-11-12',
        supplier: { kind: 'external', supplier_number: '62093' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2024-12-16',
        productionStartDate: '2024-11-25',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2024-12-16',
        productionStartDate: '2024-11-25',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2024-12-27',
        productionStartDate: '2024-12-03',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2024-12-27',
        productionStartDate: '2024-12-03',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2024-12-27',
        productionStartDate: '2024-12-03',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2025-01-08',
        productionStartDate: '2024-12-10',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2025-01-08',
        productionStartDate: '2024-12-10',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2025-01-08',
        productionStartDate: '2024-12-10',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2025-01-15',
        productionStartDate: '2024-12-17',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2025-01-15',
        productionStartDate: '2024-12-17',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2025-01-15',
        productionStartDate: '2024-12-17',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2025-01-22',
        productionStartDate: '2024-12-27',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2025-01-22',
        productionStartDate: '2024-12-27',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2025-01-22',
        productionStartDate: '2024-12-27',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 3402,
        deliveryDate: '2025-01-22',
        productionStartDate: '2025-01-07',
        supplier: { kind: 'external', supplier_number: '62093' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2025-02-19',
        productionStartDate: '2025-01-29',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2025-02-19',
        productionStartDate: '2025-01-29',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2025-02-19',
        productionStartDate: '2025-01-29',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2025-02-19',
        productionStartDate: '2025-01-29',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2025-02-21',
        productionStartDate: '2025-01-31',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2025-02-21',
        productionStartDate: '2025-01-31',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2025-02-21',
        productionStartDate: '2025-01-31',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2025-03-05',
        productionStartDate: '2025-02-12',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2025-03-05',
        productionStartDate: '2025-02-12',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2025-03-05',
        productionStartDate: '2025-02-12',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2025-03-12',
        productionStartDate: '2025-02-19',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2025-03-12',
        productionStartDate: '2025-02-19',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2025-03-12',
        productionStartDate: '2025-02-19',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2025-03-19',
        productionStartDate: '2025-02-26',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 3402,
        deliveryDate: '2025-03-19',
        productionStartDate: '2025-03-04',
        supplier: { kind: 'external', supplier_number: '62093' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2025-04-17',
        productionStartDate: '2025-03-27',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2025-04-17',
        productionStartDate: '2025-03-27',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2025-04-17',
        productionStartDate: '2025-03-27',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2025-04-17',
        productionStartDate: '2025-03-27',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2025-04-17',
        productionStartDate: '2025-03-27',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2025-04-29',
        productionStartDate: '2025-04-04',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2025-04-29',
        productionStartDate: '2025-04-04',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2025-05-07',
        productionStartDate: '2025-04-11',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2025-05-07',
        productionStartDate: '2025-04-11',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2025-05-07',
        productionStartDate: '2025-04-11',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2025-05-07',
        productionStartDate: '2025-04-11',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2025-05-14',
        productionStartDate: '2025-04-22',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2025-05-14',
        productionStartDate: '2025-04-22',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2025-05-19',
        productionStartDate: '2025-04-25',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2025-05-19',
        productionStartDate: '2025-04-25',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 3402,
        deliveryDate: '2025-05-19',
        productionStartDate: '2025-05-02',
        supplier: { kind: 'external', supplier_number: '62093' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2025-06-17',
        productionStartDate: '2025-05-22',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2025-06-25',
        productionStartDate: '2025-05-28',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2025-06-25',
        productionStartDate: '2025-05-28',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2025-06-25',
        productionStartDate: '2025-05-28',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2025-06-25',
        productionStartDate: '2025-05-28',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2025-07-02',
        productionStartDate: '2025-06-06',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2025-07-09',
        productionStartDate: '2025-06-16',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2025-07-09',
        productionStartDate: '2025-06-16',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2025-07-09',
        productionStartDate: '2025-06-16',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2025-07-16',
        productionStartDate: '2025-06-25',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2025-07-16',
        productionStartDate: '2025-06-25',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2025-07-16',
        productionStartDate: '2025-06-25',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2025-07-22',
        productionStartDate: '2025-07-01',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2025-07-22',
        productionStartDate: '2025-07-01',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 3402,
        deliveryDate: '2025-07-22',
        productionStartDate: '2025-07-07',
        supplier: { kind: 'external', supplier_number: '62093' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2025-08-27',
        productionStartDate: '2025-08-06',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2025-08-27',
        productionStartDate: '2025-08-06',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2025-08-27',
        productionStartDate: '2025-08-06',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2025-09-03',
        productionStartDate: '2025-08-13',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2025-09-03',
        productionStartDate: '2025-08-13',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2025-09-03',
        productionStartDate: '2025-08-13',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2025-09-10',
        productionStartDate: '2025-08-20',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2025-09-10',
        productionStartDate: '2025-08-20',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2025-09-10',
        productionStartDate: '2025-08-20',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2025-09-17',
        productionStartDate: '2025-08-27',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2025-09-17',
        productionStartDate: '2025-08-27',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2025-09-19',
        productionStartDate: '2025-08-29',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2025-09-19',
        productionStartDate: '2025-08-29',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2025-09-19',
        productionStartDate: '2025-08-29',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 3402,
        deliveryDate: '2025-09-19',
        productionStartDate: '2025-09-04',
        supplier: { kind: 'external', supplier_number: '62093' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2025-10-22',
        productionStartDate: '2025-09-30',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2025-10-22',
        productionStartDate: '2025-09-30',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2025-10-22',
        productionStartDate: '2025-09-30',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2025-10-22',
        productionStartDate: '2025-09-30',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2025-10-22',
        productionStartDate: '2025-09-30',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2025-10-22',
        productionStartDate: '2025-09-30',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2025-10-22',
        productionStartDate: '2025-09-30',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2025-10-22',
        productionStartDate: '2025-09-30',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2025-10-22',
        productionStartDate: '2025-09-30',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2025-11-19',
        productionStartDate: '2025-10-29',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2025-11-19',
        productionStartDate: '2025-10-29',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2025-11-19',
        productionStartDate: '2025-10-29',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2025-11-19',
        productionStartDate: '2025-10-29',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2025-11-19',
        productionStartDate: '2025-10-29',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 3402,
        deliveryDate: '2025-11-19',
        productionStartDate: '2025-11-04',
        supplier: { kind: 'external', supplier_number: '62093' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2025-12-16',
        productionStartDate: '2025-11-25',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2025-12-16',
        productionStartDate: '2025-11-25',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2025-12-16',
        productionStartDate: '2025-11-25',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2025-12-16',
        productionStartDate: '2025-11-25',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2025-12-16',
        productionStartDate: '2025-11-25',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2025-12-16',
        productionStartDate: '2025-11-25',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2025-12-16',
        productionStartDate: '2025-11-25',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2026-01-21',
        productionStartDate: '2025-12-23',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2026-01-21',
        productionStartDate: '2025-12-23',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2026-01-21',
        productionStartDate: '2025-12-23',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2026-01-21',
        productionStartDate: '2025-12-23',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2026-01-21',
        productionStartDate: '2025-12-23',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2026-01-21',
        productionStartDate: '2025-12-23',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2026-01-21',
        productionStartDate: '2025-12-23',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 3402,
        deliveryDate: '2026-01-21',
        productionStartDate: '2026-01-05',
        supplier: { kind: 'external', supplier_number: '62093' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2026-02-18',
        productionStartDate: '2026-01-28',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2026-02-18',
        productionStartDate: '2026-01-28',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2026-02-18',
        productionStartDate: '2026-01-28',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2026-02-18',
        productionStartDate: '2026-01-28',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2026-02-18',
        productionStartDate: '2026-01-28',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2026-02-18',
        productionStartDate: '2026-01-28',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2026-03-20',
        productionStartDate: '2026-02-27',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2026-03-20',
        productionStartDate: '2026-02-27',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2026-03-20',
        productionStartDate: '2026-02-27',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2026-03-20',
        productionStartDate: '2026-02-27',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2026-03-20',
        productionStartDate: '2026-02-27',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2026-03-20',
        productionStartDate: '2026-02-27',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2026-03-20',
        productionStartDate: '2026-02-27',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2026-03-20',
        productionStartDate: '2026-02-27',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2026-03-20',
        productionStartDate: '2026-02-27',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 3402,
        deliveryDate: '2026-03-20',
        productionStartDate: '2026-03-05',
        supplier: { kind: 'external', supplier_number: '62093' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2026-04-21',
        productionStartDate: '2026-03-27',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2026-04-21',
        productionStartDate: '2026-03-27',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2026-04-21',
        productionStartDate: '2026-03-27',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2026-05-19',
        productionStartDate: '2026-04-23',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2026-05-19',
        productionStartDate: '2026-04-23',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2026-05-19',
        productionStartDate: '2026-04-23',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2026-05-19',
        productionStartDate: '2026-04-23',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2026-05-19',
        productionStartDate: '2026-04-23',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2026-05-19',
        productionStartDate: '2026-04-23',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2026-05-19',
        productionStartDate: '2026-04-23',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2026-05-19',
        productionStartDate: '2026-04-23',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2026-05-19',
        productionStartDate: '2026-04-23',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2026-05-19',
        productionStartDate: '2026-04-23',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 240,
        deliveryDate: '2026-05-19',
        productionStartDate: '2026-04-23',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-2',
        quantity: 3402,
        deliveryDate: '2026-05-19',
        productionStartDate: '2026-05-04',
        supplier: { kind: 'external', supplier_number: '62093' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-5',
        quantity: 936,
        deliveryDate: '2024-07-26',
        productionStartDate: '2024-07-15',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-5',
        quantity: 936,
        deliveryDate: '2024-08-29',
        productionStartDate: '2024-08-16',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-5',
        quantity: 936,
        deliveryDate: '2024-09-05',
        productionStartDate: '2024-08-23',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-5',
        quantity: 936,
        deliveryDate: '2024-09-05',
        productionStartDate: '2024-08-23',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-5',
        quantity: 936,
        deliveryDate: '2024-10-04',
        productionStartDate: '2024-09-20',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-5',
        quantity: 936,
        deliveryDate: '2024-10-04',
        productionStartDate: '2024-09-20',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-5',
        quantity: 936,
        deliveryDate: '2024-11-06',
        productionStartDate: '2024-10-23',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-5',
        quantity: 936,
        deliveryDate: '2024-11-07',
        productionStartDate: '2024-10-24',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-5',
        quantity: 936,
        deliveryDate: '2024-11-28',
        productionStartDate: '2024-11-15',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-5',
        quantity: 936,
        deliveryDate: '2024-11-28',
        productionStartDate: '2024-11-15',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-5',
        quantity: 936,
        deliveryDate: '2024-12-06',
        productionStartDate: '2024-11-25',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-5',
        quantity: 936,
        deliveryDate: '2025-01-09',
        productionStartDate: '2024-12-19',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-5',
        quantity: 936,
        deliveryDate: '2025-01-09',
        productionStartDate: '2024-12-19',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-5',
        quantity: 936,
        deliveryDate: '2025-01-30',
        productionStartDate: '2025-01-17',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-5',
        quantity: 936,
        deliveryDate: '2025-02-06',
        productionStartDate: '2025-01-24',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-5',
        quantity: 936,
        deliveryDate: '2025-02-06',
        productionStartDate: '2025-01-24',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-5',
        quantity: 936,
        deliveryDate: '2025-03-06',
        productionStartDate: '2025-02-21',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-5',
        quantity: 936,
        deliveryDate: '2025-03-06',
        productionStartDate: '2025-02-21',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-5',
        quantity: 936,
        deliveryDate: '2025-03-26',
        productionStartDate: '2025-03-13',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-5',
        quantity: 936,
        deliveryDate: '2025-04-03',
        productionStartDate: '2025-03-21',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-5',
        quantity: 936,
        deliveryDate: '2025-04-09',
        productionStartDate: '2025-03-27',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-5',
        quantity: 936,
        deliveryDate: '2025-04-30',
        productionStartDate: '2025-04-15',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-5',
        quantity: 936,
        deliveryDate: '2025-05-08',
        productionStartDate: '2025-04-24',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-5',
        quantity: 936,
        deliveryDate: '2025-05-26',
        productionStartDate: '2025-05-13',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-5',
        quantity: 936,
        deliveryDate: '2025-06-03',
        productionStartDate: '2025-05-19',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-5',
        quantity: 936,
        deliveryDate: '2025-06-11',
        productionStartDate: '2025-05-26',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-5',
        quantity: 936,
        deliveryDate: '2025-07-03',
        productionStartDate: '2025-06-18',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-5',
        quantity: 936,
        deliveryDate: '2025-07-17',
        productionStartDate: '2025-07-04',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-5',
        quantity: 936,
        deliveryDate: '2025-08-06',
        productionStartDate: '2025-07-24',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-5',
        quantity: 936,
        deliveryDate: '2025-08-14',
        productionStartDate: '2025-08-01',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-5',
        quantity: 936,
        deliveryDate: '2025-08-21',
        productionStartDate: '2025-08-08',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-5',
        quantity: 936,
        deliveryDate: '2025-09-04',
        productionStartDate: '2025-08-22',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-5',
        quantity: 936,
        deliveryDate: '2025-09-17',
        productionStartDate: '2025-09-04',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-5',
        quantity: 936,
        deliveryDate: '2025-10-01',
        productionStartDate: '2025-09-18',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-5',
        quantity: 936,
        deliveryDate: '2025-10-01',
        productionStartDate: '2025-09-18',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-5',
        quantity: 936,
        deliveryDate: '2025-10-09',
        productionStartDate: '2025-09-25',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-5',
        quantity: 936,
        deliveryDate: '2025-11-06',
        productionStartDate: '2025-10-24',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-5',
        quantity: 936,
        deliveryDate: '2025-11-06',
        productionStartDate: '2025-10-24',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-5',
        quantity: 936,
        deliveryDate: '2025-12-02',
        productionStartDate: '2025-11-19',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-5',
        quantity: 936,
        deliveryDate: '2025-12-02',
        productionStartDate: '2025-11-19',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-5',
        quantity: 936,
        deliveryDate: '2026-01-08',
        productionStartDate: '2025-12-18',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-5',
        quantity: 936,
        deliveryDate: '2026-01-08',
        productionStartDate: '2025-12-18',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-5',
        quantity: 936,
        deliveryDate: '2026-01-08',
        productionStartDate: '2025-12-18',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-5',
        quantity: 936,
        deliveryDate: '2026-02-02',
        productionStartDate: '2026-01-20',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-5',
        quantity: 936,
        deliveryDate: '2026-02-05',
        productionStartDate: '2026-01-23',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-5',
        quantity: 936,
        deliveryDate: '2026-03-09',
        productionStartDate: '2026-02-24',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-5',
        quantity: 936,
        deliveryDate: '2026-03-09',
        productionStartDate: '2026-02-24',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-5',
        quantity: 936,
        deliveryDate: '2026-03-09',
        productionStartDate: '2026-02-24',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-5',
        quantity: 936,
        deliveryDate: '2026-04-01',
        productionStartDate: '2026-03-19',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-5',
        quantity: 936,
        deliveryDate: '2026-04-01',
        productionStartDate: '2026-03-19',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-5',
        quantity: 936,
        deliveryDate: '2026-05-04',
        productionStartDate: '2026-04-20',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-5',
        quantity: 936,
        deliveryDate: '2026-05-04',
        productionStartDate: '2026-04-20',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-5',
        quantity: 936,
        deliveryDate: '2026-05-04',
        productionStartDate: '2026-04-20',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-7',
        quantity: 480,
        deliveryDate: '2024-08-07',
        productionStartDate: '2024-07-30',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-7',
        quantity: 480,
        deliveryDate: '2024-08-07',
        productionStartDate: '2024-07-30',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-7',
        quantity: 480,
        deliveryDate: '2024-08-21',
        productionStartDate: '2024-08-13',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-7',
        quantity: 480,
        deliveryDate: '2024-08-28',
        productionStartDate: '2024-08-20',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-7',
        quantity: 480,
        deliveryDate: '2024-08-28',
        productionStartDate: '2024-08-20',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-7',
        quantity: 480,
        deliveryDate: '2024-10-04',
        productionStartDate: '2024-09-25',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-7',
        quantity: 480,
        deliveryDate: '2024-10-10',
        productionStartDate: '2024-10-01',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-7',
        quantity: 480,
        deliveryDate: '2024-10-10',
        productionStartDate: '2024-10-01',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-7',
        quantity: 480,
        deliveryDate: '2024-10-21',
        productionStartDate: '2024-10-11',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-7',
        quantity: 480,
        deliveryDate: '2024-10-28',
        productionStartDate: '2024-10-18',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-7',
        quantity: 480,
        deliveryDate: '2024-10-28',
        productionStartDate: '2024-10-18',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-7',
        quantity: 480,
        deliveryDate: '2024-10-28',
        productionStartDate: '2024-10-18',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-7',
        quantity: 480,
        deliveryDate: '2024-11-25',
        productionStartDate: '2024-11-15',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-7',
        quantity: 480,
        deliveryDate: '2024-12-03',
        productionStartDate: '2024-11-25',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-7',
        quantity: 480,
        deliveryDate: '2024-12-10',
        productionStartDate: '2024-12-02',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-7',
        quantity: 480,
        deliveryDate: '2024-12-10',
        productionStartDate: '2024-12-02',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-7',
        quantity: 480,
        deliveryDate: '2024-12-17',
        productionStartDate: '2024-12-09',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-7',
        quantity: 480,
        deliveryDate: '2024-12-27',
        productionStartDate: '2024-12-16',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-7',
        quantity: 480,
        deliveryDate: '2024-12-27',
        productionStartDate: '2024-12-16',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-7',
        quantity: 480,
        deliveryDate: '2025-01-29',
        productionStartDate: '2025-01-21',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-7',
        quantity: 480,
        deliveryDate: '2025-01-29',
        productionStartDate: '2025-01-21',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-7',
        quantity: 480,
        deliveryDate: '2025-01-29',
        productionStartDate: '2025-01-21',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-7',
        quantity: 480,
        deliveryDate: '2025-02-12',
        productionStartDate: '2025-02-04',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-7',
        quantity: 480,
        deliveryDate: '2025-02-12',
        productionStartDate: '2025-02-04',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-7',
        quantity: 480,
        deliveryDate: '2025-02-19',
        productionStartDate: '2025-02-11',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-7',
        quantity: 480,
        deliveryDate: '2025-02-26',
        productionStartDate: '2025-02-18',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-7',
        quantity: 480,
        deliveryDate: '2025-03-27',
        productionStartDate: '2025-03-19',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-7',
        quantity: 480,
        deliveryDate: '2025-03-27',
        productionStartDate: '2025-03-19',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-7',
        quantity: 480,
        deliveryDate: '2025-04-04',
        productionStartDate: '2025-03-27',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-7',
        quantity: 480,
        deliveryDate: '2025-04-11',
        productionStartDate: '2025-04-03',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-7',
        quantity: 480,
        deliveryDate: '2025-04-11',
        productionStartDate: '2025-04-03',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-7',
        quantity: 480,
        deliveryDate: '2025-04-22',
        productionStartDate: '2025-04-10',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-7',
        quantity: 480,
        deliveryDate: '2025-04-22',
        productionStartDate: '2025-04-10',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-7',
        quantity: 480,
        deliveryDate: '2025-05-22',
        productionStartDate: '2025-05-14',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-7',
        quantity: 480,
        deliveryDate: '2025-05-28',
        productionStartDate: '2025-05-20',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-7',
        quantity: 480,
        deliveryDate: '2025-05-28',
        productionStartDate: '2025-05-20',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-7',
        quantity: 480,
        deliveryDate: '2025-06-16',
        productionStartDate: '2025-06-05',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-7',
        quantity: 480,
        deliveryDate: '2025-06-16',
        productionStartDate: '2025-06-05',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-7',
        quantity: 480,
        deliveryDate: '2025-06-25',
        productionStartDate: '2025-06-13',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-7',
        quantity: 480,
        deliveryDate: '2025-07-01',
        productionStartDate: '2025-06-23',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-7',
        quantity: 480,
        deliveryDate: '2025-08-06',
        productionStartDate: '2025-07-29',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-7',
        quantity: 480,
        deliveryDate: '2025-08-06',
        productionStartDate: '2025-07-29',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-7',
        quantity: 480,
        deliveryDate: '2025-08-13',
        productionStartDate: '2025-08-05',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-7',
        quantity: 480,
        deliveryDate: '2025-08-20',
        productionStartDate: '2025-08-12',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-7',
        quantity: 480,
        deliveryDate: '2025-08-20',
        productionStartDate: '2025-08-12',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-7',
        quantity: 480,
        deliveryDate: '2025-08-27',
        productionStartDate: '2025-08-19',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-7',
        quantity: 480,
        deliveryDate: '2025-08-27',
        productionStartDate: '2025-08-19',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-7',
        quantity: 480,
        deliveryDate: '2025-09-30',
        productionStartDate: '2025-09-22',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-7',
        quantity: 480,
        deliveryDate: '2025-09-30',
        productionStartDate: '2025-09-22',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-7',
        quantity: 480,
        deliveryDate: '2025-09-30',
        productionStartDate: '2025-09-22',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-7',
        quantity: 480,
        deliveryDate: '2025-09-30',
        productionStartDate: '2025-09-22',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-7',
        quantity: 480,
        deliveryDate: '2025-09-30',
        productionStartDate: '2025-09-22',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-7',
        quantity: 480,
        deliveryDate: '2025-10-29',
        productionStartDate: '2025-10-21',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-7',
        quantity: 480,
        deliveryDate: '2025-10-29',
        productionStartDate: '2025-10-21',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-7',
        quantity: 480,
        deliveryDate: '2025-11-25',
        productionStartDate: '2025-11-17',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-7',
        quantity: 480,
        deliveryDate: '2025-11-25',
        productionStartDate: '2025-11-17',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-7',
        quantity: 480,
        deliveryDate: '2025-11-25',
        productionStartDate: '2025-11-17',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-7',
        quantity: 480,
        deliveryDate: '2025-11-25',
        productionStartDate: '2025-11-17',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-7',
        quantity: 480,
        deliveryDate: '2025-12-23',
        productionStartDate: '2025-12-15',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-7',
        quantity: 480,
        deliveryDate: '2025-12-23',
        productionStartDate: '2025-12-15',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-7',
        quantity: 480,
        deliveryDate: '2025-12-23',
        productionStartDate: '2025-12-15',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-7',
        quantity: 480,
        deliveryDate: '2026-01-28',
        productionStartDate: '2026-01-20',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-7',
        quantity: 480,
        deliveryDate: '2026-01-28',
        productionStartDate: '2026-01-20',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-7',
        quantity: 480,
        deliveryDate: '2026-01-28',
        productionStartDate: '2026-01-20',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-7',
        quantity: 480,
        deliveryDate: '2026-02-27',
        productionStartDate: '2026-02-19',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-7',
        quantity: 480,
        deliveryDate: '2026-02-27',
        productionStartDate: '2026-02-19',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-7',
        quantity: 480,
        deliveryDate: '2026-02-27',
        productionStartDate: '2026-02-19',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-7',
        quantity: 480,
        deliveryDate: '2026-02-27',
        productionStartDate: '2026-02-19',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-7',
        quantity: 480,
        deliveryDate: '2026-02-27',
        productionStartDate: '2026-02-19',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-7',
        quantity: 480,
        deliveryDate: '2026-03-27',
        productionStartDate: '2026-03-19',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-7',
        quantity: 480,
        deliveryDate: '2026-04-23',
        productionStartDate: '2026-04-15',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-7',
        quantity: 480,
        deliveryDate: '2026-04-23',
        productionStartDate: '2026-04-15',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-7',
        quantity: 480,
        deliveryDate: '2026-04-23',
        productionStartDate: '2026-04-15',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-7',
        quantity: 480,
        deliveryDate: '2026-04-23',
        productionStartDate: '2026-04-15',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-7',
        quantity: 480,
        deliveryDate: '2026-04-23',
        productionStartDate: '2026-04-15',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-7',
        quantity: 480,
        deliveryDate: '2026-04-23',
        productionStartDate: '2026-04-15',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-6',
        quantity: 2160,
        deliveryDate: '2024-07-26',
        productionStartDate: '2024-07-12',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0300',
    },
    {
        assemblyIpn: 'Assembly-6',
        quantity: 2160,
        deliveryDate: '2024-08-02',
        productionStartDate: '2024-07-19',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0300',
    },
    {
        assemblyIpn: 'Assembly-6',
        quantity: 2160,
        deliveryDate: '2024-08-02',
        productionStartDate: '2024-07-19',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0300',
    },
    {
        assemblyIpn: 'Assembly-6',
        quantity: 2160,
        deliveryDate: '2024-08-26',
        productionStartDate: '2024-08-09',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0300',
    },
    {
        assemblyIpn: 'Assembly-6',
        quantity: 2160,
        deliveryDate: '2024-08-30',
        productionStartDate: '2024-08-15',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0300',
    },
    {
        assemblyIpn: 'Assembly-6',
        quantity: 2160,
        deliveryDate: '2024-08-30',
        productionStartDate: '2024-08-15',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0300',
    },
    {
        assemblyIpn: 'Assembly-6',
        quantity: 2160,
        deliveryDate: '2024-09-23',
        productionStartDate: '2024-09-09',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0300',
    },
    {
        assemblyIpn: 'Assembly-6',
        quantity: 2160,
        deliveryDate: '2024-10-03',
        productionStartDate: '2024-09-19',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0300',
    },
    {
        assemblyIpn: 'Assembly-6',
        quantity: 2160,
        deliveryDate: '2024-10-21',
        productionStartDate: '2024-10-07',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0300',
    },
    {
        assemblyIpn: 'Assembly-6',
        quantity: 2160,
        deliveryDate: '2024-10-31',
        productionStartDate: '2024-10-16',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0300',
    },
    {
        assemblyIpn: 'Assembly-6',
        quantity: 2160,
        deliveryDate: '2024-11-11',
        productionStartDate: '2024-10-25',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0300',
    },
    {
        assemblyIpn: 'Assembly-6',
        quantity: 2160,
        deliveryDate: '2024-11-25',
        productionStartDate: '2024-11-11',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0300',
    },
    {
        assemblyIpn: 'Assembly-6',
        quantity: 2160,
        deliveryDate: '2024-11-25',
        productionStartDate: '2024-11-11',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0300',
    },
    {
        assemblyIpn: 'Assembly-6',
        quantity: 2160,
        deliveryDate: '2024-12-12',
        productionStartDate: '2024-11-28',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0300',
    },
    {
        assemblyIpn: 'Assembly-6',
        quantity: 2160,
        deliveryDate: '2025-01-03',
        productionStartDate: '2024-12-16',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0300',
    },
    {
        assemblyIpn: 'Assembly-6',
        quantity: 2160,
        deliveryDate: '2025-01-20',
        productionStartDate: '2025-01-06',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0300',
    },
    {
        assemblyIpn: 'Assembly-6',
        quantity: 2160,
        deliveryDate: '2025-01-31',
        productionStartDate: '2025-01-17',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0300',
    },
    {
        assemblyIpn: 'Assembly-6',
        quantity: 2160,
        deliveryDate: '2025-01-31',
        productionStartDate: '2025-01-17',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0300',
    },
    {
        assemblyIpn: 'Assembly-6',
        quantity: 2160,
        deliveryDate: '2025-02-17',
        productionStartDate: '2025-02-03',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0300',
    },
    {
        assemblyIpn: 'Assembly-6',
        quantity: 2160,
        deliveryDate: '2025-03-03',
        productionStartDate: '2025-02-17',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0300',
    },
    {
        assemblyIpn: 'Assembly-6',
        quantity: 2160,
        deliveryDate: '2025-03-10',
        productionStartDate: '2025-02-24',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0300',
    },
    {
        assemblyIpn: 'Assembly-6',
        quantity: 2160,
        deliveryDate: '2025-03-27',
        productionStartDate: '2025-03-13',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0300',
    },
    {
        assemblyIpn: 'Assembly-6',
        quantity: 2160,
        deliveryDate: '2025-04-11',
        productionStartDate: '2025-03-28',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0300',
    },
    {
        assemblyIpn: 'Assembly-6',
        quantity: 2160,
        deliveryDate: '2025-04-17',
        productionStartDate: '2025-04-03',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0300',
    },
    {
        assemblyIpn: 'Assembly-6',
        quantity: 2160,
        deliveryDate: '2025-05-08',
        productionStartDate: '2025-04-23',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0300',
    },
    {
        assemblyIpn: 'Assembly-6',
        quantity: 2160,
        deliveryDate: '2025-05-29',
        productionStartDate: '2025-05-15',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0300',
    },
    {
        assemblyIpn: 'Assembly-6',
        quantity: 2160,
        deliveryDate: '2025-06-16',
        productionStartDate: '2025-05-30',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0300',
    },
    {
        assemblyIpn: 'Assembly-6',
        quantity: 2160,
        deliveryDate: '2025-07-07',
        productionStartDate: '2025-06-23',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0300',
    },
    {
        assemblyIpn: 'Assembly-6',
        quantity: 2160,
        deliveryDate: '2025-08-01',
        productionStartDate: '2025-07-18',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0300',
    },
    {
        assemblyIpn: 'Assembly-6',
        quantity: 2160,
        deliveryDate: '2025-08-28',
        productionStartDate: '2025-08-13',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0300',
    },
    {
        assemblyIpn: 'Assembly-6',
        quantity: 2160,
        deliveryDate: '2025-09-05',
        productionStartDate: '2025-08-22',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0300',
    },
    {
        assemblyIpn: 'Assembly-6',
        quantity: 2160,
        deliveryDate: '2025-09-29',
        productionStartDate: '2025-09-15',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0300',
    },
    {
        assemblyIpn: 'Assembly-6',
        quantity: 2160,
        deliveryDate: '2025-09-29',
        productionStartDate: '2025-09-15',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0300',
    },
    {
        assemblyIpn: 'Assembly-6',
        quantity: 2160,
        deliveryDate: '2025-10-27',
        productionStartDate: '2025-10-10',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0300',
    },
    {
        assemblyIpn: 'Assembly-6',
        quantity: 2160,
        deliveryDate: '2025-10-27',
        productionStartDate: '2025-10-10',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0300',
    },
    {
        assemblyIpn: 'Assembly-6',
        quantity: 2160,
        deliveryDate: '2025-11-21',
        productionStartDate: '2025-11-07',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0300',
    },
    {
        assemblyIpn: 'Assembly-6',
        quantity: 2160,
        deliveryDate: '2025-12-29',
        productionStartDate: '2025-12-10',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0300',
    },
    {
        assemblyIpn: 'Assembly-6',
        quantity: 2160,
        deliveryDate: '2025-12-29',
        productionStartDate: '2025-12-10',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0300',
    },
    {
        assemblyIpn: 'Assembly-6',
        quantity: 2160,
        deliveryDate: '2026-01-26',
        productionStartDate: '2026-01-12',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0300',
    },
    {
        assemblyIpn: 'Assembly-6',
        quantity: 2160,
        deliveryDate: '2026-01-26',
        productionStartDate: '2026-01-12',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0300',
    },
    {
        assemblyIpn: 'Assembly-6',
        quantity: 2160,
        deliveryDate: '2026-02-23',
        productionStartDate: '2026-02-09',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0300',
    },
    {
        assemblyIpn: 'Assembly-6',
        quantity: 2160,
        deliveryDate: '2026-02-23',
        productionStartDate: '2026-02-09',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0300',
    },
    {
        assemblyIpn: 'Assembly-6',
        quantity: 2160,
        deliveryDate: '2026-03-27',
        productionStartDate: '2026-03-13',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0300',
    },
    {
        assemblyIpn: 'Assembly-6',
        quantity: 2160,
        deliveryDate: '2026-04-23',
        productionStartDate: '2026-04-09',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0300',
    },
    {
        assemblyIpn: 'Assembly-6',
        quantity: 2160,
        deliveryDate: '2026-04-23',
        productionStartDate: '2026-04-09',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0300',
    },
    {
        assemblyIpn: 'Assembly-6',
        quantity: 2160,
        deliveryDate: '2026-05-28',
        productionStartDate: '2026-05-13',
        supplier: { kind: 'internal', site_number: '0100' },
        recipient: '0300',
    },
    {
        assemblyIpn: 'Assembly-1',
        quantity: 960,
        deliveryDate: '2024-08-14',
        productionStartDate: '2024-07-30',
        supplier: { kind: 'external', supplier_number: '62093' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-1',
        quantity: 960,
        deliveryDate: '2024-09-04',
        productionStartDate: '2024-08-20',
        supplier: { kind: 'external', supplier_number: '62093' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-1',
        quantity: 960,
        deliveryDate: '2024-09-25',
        productionStartDate: '2024-09-10',
        supplier: { kind: 'external', supplier_number: '62093' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-1',
        quantity: 960,
        deliveryDate: '2024-10-17',
        productionStartDate: '2024-10-02',
        supplier: { kind: 'external', supplier_number: '62093' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-1',
        quantity: 960,
        deliveryDate: '2024-10-29',
        productionStartDate: '2024-10-14',
        supplier: { kind: 'external', supplier_number: '62093' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-1',
        quantity: 960,
        deliveryDate: '2024-11-13',
        productionStartDate: '2024-10-29',
        supplier: { kind: 'external', supplier_number: '62093' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-1',
        quantity: 960,
        deliveryDate: '2024-11-27',
        productionStartDate: '2024-11-12',
        supplier: { kind: 'external', supplier_number: '62093' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-1',
        quantity: 960,
        deliveryDate: '2024-12-11',
        productionStartDate: '2024-11-26',
        supplier: { kind: 'external', supplier_number: '62093' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-1',
        quantity: 960,
        deliveryDate: '2024-12-18',
        productionStartDate: '2024-12-03',
        supplier: { kind: 'external', supplier_number: '62093' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-1',
        quantity: 960,
        deliveryDate: '2025-01-08',
        productionStartDate: '2024-12-23',
        supplier: { kind: 'external', supplier_number: '62093' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-1',
        quantity: 960,
        deliveryDate: '2025-01-22',
        productionStartDate: '2025-01-07',
        supplier: { kind: 'external', supplier_number: '62093' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-1',
        quantity: 960,
        deliveryDate: '2025-02-05',
        productionStartDate: '2025-01-21',
        supplier: { kind: 'external', supplier_number: '62093' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-1',
        quantity: 960,
        deliveryDate: '2025-02-12',
        productionStartDate: '2025-01-28',
        supplier: { kind: 'external', supplier_number: '62093' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-1',
        quantity: 960,
        deliveryDate: '2025-02-26',
        productionStartDate: '2025-02-11',
        supplier: { kind: 'external', supplier_number: '62093' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-1',
        quantity: 960,
        deliveryDate: '2025-03-12',
        productionStartDate: '2025-02-25',
        supplier: { kind: 'external', supplier_number: '62093' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-1',
        quantity: 960,
        deliveryDate: '2025-03-20',
        productionStartDate: '2025-03-05',
        supplier: { kind: 'external', supplier_number: '62093' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-1',
        quantity: 960,
        deliveryDate: '2025-04-02',
        productionStartDate: '2025-03-18',
        supplier: { kind: 'external', supplier_number: '62093' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-1',
        quantity: 960,
        deliveryDate: '2025-04-15',
        productionStartDate: '2025-03-31',
        supplier: { kind: 'external', supplier_number: '62093' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-1',
        quantity: 960,
        deliveryDate: '2025-04-29',
        productionStartDate: '2025-04-14',
        supplier: { kind: 'external', supplier_number: '62093' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-1',
        quantity: 960,
        deliveryDate: '2025-05-07',
        productionStartDate: '2025-04-22',
        supplier: { kind: 'external', supplier_number: '62093' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-1',
        quantity: 960,
        deliveryDate: '2025-05-21',
        productionStartDate: '2025-05-06',
        supplier: { kind: 'external', supplier_number: '62093' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-1',
        quantity: 960,
        deliveryDate: '2025-06-04',
        productionStartDate: '2025-05-20',
        supplier: { kind: 'external', supplier_number: '62093' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-1',
        quantity: 960,
        deliveryDate: '2025-06-16',
        productionStartDate: '2025-05-28',
        supplier: { kind: 'external', supplier_number: '62093' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-1',
        quantity: 960,
        deliveryDate: '2025-07-02',
        productionStartDate: '2025-06-17',
        supplier: { kind: 'external', supplier_number: '62093' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-1',
        quantity: 960,
        deliveryDate: '2025-07-16',
        productionStartDate: '2025-07-01',
        supplier: { kind: 'external', supplier_number: '62093' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-1',
        quantity: 960,
        deliveryDate: '2025-07-30',
        productionStartDate: '2025-07-15',
        supplier: { kind: 'external', supplier_number: '62093' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-1',
        quantity: 960,
        deliveryDate: '2025-08-06',
        productionStartDate: '2025-07-22',
        supplier: { kind: 'external', supplier_number: '62093' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-1',
        quantity: 960,
        deliveryDate: '2025-08-20',
        productionStartDate: '2025-08-05',
        supplier: { kind: 'external', supplier_number: '62093' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-1',
        quantity: 960,
        deliveryDate: '2025-09-03',
        productionStartDate: '2025-08-19',
        supplier: { kind: 'external', supplier_number: '62093' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-1',
        quantity: 960,
        deliveryDate: '2025-09-17',
        productionStartDate: '2025-09-02',
        supplier: { kind: 'external', supplier_number: '62093' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-1',
        quantity: 960,
        deliveryDate: '2025-09-24',
        productionStartDate: '2025-09-09',
        supplier: { kind: 'external', supplier_number: '62093' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-1',
        quantity: 960,
        deliveryDate: '2025-09-30',
        productionStartDate: '2025-09-15',
        supplier: { kind: 'external', supplier_number: '62093' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-1',
        quantity: 960,
        deliveryDate: '2025-10-08',
        productionStartDate: '2025-09-23',
        supplier: { kind: 'external', supplier_number: '62093' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-1',
        quantity: 960,
        deliveryDate: '2025-10-15',
        productionStartDate: '2025-09-30',
        supplier: { kind: 'external', supplier_number: '62093' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-1',
        quantity: 960,
        deliveryDate: '2025-10-22',
        productionStartDate: '2025-10-07',
        supplier: { kind: 'external', supplier_number: '62093' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-1',
        quantity: 960,
        deliveryDate: '2025-11-11',
        productionStartDate: '2025-10-27',
        supplier: { kind: 'external', supplier_number: '62093' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-1',
        quantity: 960,
        deliveryDate: '2025-11-19',
        productionStartDate: '2025-11-04',
        supplier: { kind: 'external', supplier_number: '62093' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-1',
        quantity: 960,
        deliveryDate: '2025-11-19',
        productionStartDate: '2025-11-04',
        supplier: { kind: 'external', supplier_number: '62093' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-1',
        quantity: 960,
        deliveryDate: '2025-12-12',
        productionStartDate: '2025-11-27',
        supplier: { kind: 'external', supplier_number: '62093' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-1',
        quantity: 960,
        deliveryDate: '2025-12-17',
        productionStartDate: '2025-12-02',
        supplier: { kind: 'external', supplier_number: '62093' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-1',
        quantity: 960,
        deliveryDate: '2026-01-14',
        productionStartDate: '2025-12-30',
        supplier: { kind: 'external', supplier_number: '62093' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-1',
        quantity: 960,
        deliveryDate: '2026-01-21',
        productionStartDate: '2026-01-05',
        supplier: { kind: 'external', supplier_number: '62093' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-1',
        quantity: 960,
        deliveryDate: '2026-01-21',
        productionStartDate: '2026-01-05',
        supplier: { kind: 'external', supplier_number: '62093' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-1',
        quantity: 960,
        deliveryDate: '2026-02-11',
        productionStartDate: '2026-01-27',
        supplier: { kind: 'external', supplier_number: '62093' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-1',
        quantity: 960,
        deliveryDate: '2026-02-18',
        productionStartDate: '2026-02-03',
        supplier: { kind: 'external', supplier_number: '62093' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-1',
        quantity: 960,
        deliveryDate: '2026-03-12',
        productionStartDate: '2026-02-25',
        supplier: { kind: 'external', supplier_number: '62093' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-1',
        quantity: 960,
        deliveryDate: '2026-03-18',
        productionStartDate: '2026-03-03',
        supplier: { kind: 'external', supplier_number: '62093' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-1',
        quantity: 960,
        deliveryDate: '2026-04-13',
        productionStartDate: '2026-03-27',
        supplier: { kind: 'external', supplier_number: '62093' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-1',
        quantity: 960,
        deliveryDate: '2026-04-17',
        productionStartDate: '2026-04-02',
        supplier: { kind: 'external', supplier_number: '62093' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-1',
        quantity: 960,
        deliveryDate: '2026-04-17',
        productionStartDate: '2026-04-02',
        supplier: { kind: 'external', supplier_number: '62093' },
        recipient: '0100',
    },
    {
        assemblyIpn: 'Assembly-1',
        quantity: 960,
        deliveryDate: '2026-05-13',
        productionStartDate: '2026-04-28',
        supplier: { kind: 'external', supplier_number: '62093' },
        recipient: '0100',
    },
];
