import { PCBV2, PanelDetailsPostDTO } from '@luminovo/http-client';
import { isValidPanelDetails } from './isValidPanelDetails';

export const getPanelDimension = (
    panelDetails: PanelDetailsPostDTO,
    pcb: PCBV2,
): {
    x: number | null;
    y: number | null;
} => {
    const { isValid, severity } = isValidPanelDetails(panelDetails);
    if (isValid === false && severity === 'error') {
        return {
            x: null,
            y: null,
        };
    }

    const paddingLeft = parseInt(panelDetails.padding.left_in_mm);
    const paddingRight = parseInt(panelDetails.padding.right_in_mm);
    const paddingTop = parseInt(panelDetails.padding.top_in_mm);
    const paddingBottom = parseInt(panelDetails.padding.bottom_in_mm);
    const horizontalSpacing = parseInt(panelDetails.horizontal_spacing_in_mm);
    const verticalSpacing = parseInt(panelDetails.vertical_spacing_in_mm);
    const rowCount = panelDetails.row_count;
    const columnCount = panelDetails.column_count;
    const boardWidth = pcb.properties.board.boardWidth ?? 0;
    const boardHeight = pcb.properties.board.boardHeight ?? 0;

    const paddingXAxis = paddingLeft + paddingRight;
    const paddingYAxis = paddingTop + paddingBottom;
    const netWidth = columnCount * (boardWidth + horizontalSpacing) - horizontalSpacing;
    const netHeight = rowCount * (boardHeight + verticalSpacing) - verticalSpacing;

    return {
        x: netWidth + paddingXAxis,
        y: netHeight + paddingYAxis,
    };
};
