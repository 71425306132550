import { getToken } from '@luminovo/auth';
import { isPresent } from '@luminovo/commons';
import {
    EndpointRegistry,
    extractEndpointsToBeInvalidated,
    extractEndpointsToBeRemoved,
    http,
    HttpOptions,
    RegisteredHttpEndpoint,
} from '@luminovo/http-client';
import { useMutation, UseMutationOptions, UseMutationResult, useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import * as r from 'runtypes';
import { useCurrentUserDetailsContext } from '../../components/contexts/CurrentUserDetailsContext';
import { useDebugErrorHandler } from '../http/debugErrorHandler';
import { httpQueryKey } from '../http/httpQueryKey';

type HttpOptionsWithFiles<T extends RegisteredHttpEndpoint> = HttpOptions<T> & { files: Array<File> | File };

type Metadata = {
    /*
     * The user id who uploaded the file.
     */
    uploaded_by: string;
};

export function useAzureBlobMetadata(): Metadata {
    const { user } = useCurrentUserDetailsContext();
    return {
        uploaded_by: user.id,
    };
}
/*
 * Known Issue: the `metadata` is irgnored because it triggers a CORS issue.
 * The root cause is unclear at this time, and resolving this is not a high priority task.
 *
 * However, the metadata is retained in the code for potential future use, once the CORS issue is addressed.
 */
export async function uploadFileToBlobStorage(file: File, sasUrl: string, metadata: Metadata | undefined) {
    const { ContainerClient } = await import('@azure/storage-blob');

    const blobName = sanitizeFileName(file.name);

    const containerClient = new ContainerClient(sasUrl);
    const blockBlobClient = containerClient.getBlockBlobClient(blobName);

    await blockBlobClient.uploadData(file);
}

export function sanitizeFileName(filename: string): string {
    return filename.replace(/[^a-zA-Z0-9_.-]/g, '_');
}

/**
 * A custom hook for making type-safe HTTP mutation requests with file uploads
 */
export function useHttpFileUpload<T extends RegisteredHttpEndpoint>(
    endpoint: T,
    getUrl: (response: r.Static<EndpointRegistry[T]['responseBody']>) => string,
    options: {
        snackbarMessage: string | null;
        onSuccess?: UseMutationOptions<
            r.Static<EndpointRegistry[T]['responseBody']>,
            unknown,
            HttpOptionsWithFiles<T>,
            unknown
        >['onSuccess'];
        /**
         * When set to true we automaticaly invalidate and remove all inactive queries.
         *
         * @default true
         */
        enableInvalidateAllQueries?: boolean;
        /**
         * If true, multiple files can be uploaded at once.
         * @default true
         */
        multiple?: boolean;
        /**
         * Maximum number of files that can be uploaded at once.
         */
        maxFiles?: number;
    },
): UseMutationResult<r.Static<EndpointRegistry[T]['responseBody']>, unknown, HttpOptionsWithFiles<T>, unknown> {
    const metadata = useAzureBlobMetadata();
    const {
        snackbarMessage,
        onSuccess = () => {},
        enableInvalidateAllQueries = true,
        multiple = true,
        maxFiles = Infinity,
    } = options;
    const { enqueueSnackbar } = useSnackbar();
    const onError = useDebugErrorHandler();
    const queryClient = useQueryClient();

    return useMutation({
        mutationKey: httpQueryKey(endpoint),
        mutationFn: async (variables: HttpOptionsWithFiles<T>) => {
            const response = await http(endpoint, variables, getToken());

            if (Array.isArray(variables.files) && variables.files.length > maxFiles) {
                throw new Error(`Too many files uploaded. Maximum number of files is ${maxFiles}`);
            }
            if (!multiple && Array.isArray(variables.files) && variables.files.length > 1) {
                throw new Error('Only one file can be uploaded at once');
            }

            const files = Array.isArray(variables.files) ? variables.files : [variables.files];
            await Promise.all(files.map((file) => uploadFileToBlobStorage(file, getUrl(response), metadata)));

            return response;
        },
        onSuccess: async (data, variables, context) => {
            await queryClient.removeQueries({ type: 'inactive' });
            if (enableInvalidateAllQueries) {
                await queryClient.invalidateQueries();
            }

            await Promise.allSettled(
                extractEndpointsToBeRemoved(endpoint).map((endpoint) =>
                    queryClient.removeQueries({
                        queryKey: [endpoint],
                    }),
                ),
            );

            await Promise.allSettled(
                extractEndpointsToBeInvalidated(endpoint).map((endpoint) =>
                    queryClient.invalidateQueries({
                        queryKey: [endpoint],
                    }),
                ),
            );

            if (isPresent(snackbarMessage)) {
                enqueueSnackbar(snackbarMessage, {
                    variant: 'success',
                    anchorOrigin: { vertical: 'top', horizontal: 'center' },
                    autoHideDuration: 2000,
                });
            }

            await onSuccess(data, variables, context);
        },
        onError,
    });
}
