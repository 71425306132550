import { BACKEND_BASE } from '@luminovo/http-client';

/**
 * Can pass around state when routing using location's "state" attribute.
 * Define a type for all things that we pass around for better support with typescript.
 * E.g: const history = useHistory<RoutesHistoryStateProps | undefined>
 *     can then use history.location.state.fromUrl
 */
export interface RoutesHistoryStateProps {
    fromSourcing?: boolean;
    rfqId?: string;
    sourcingScenarioId?: string;
    previousUrl?: string;
}

const AUDIENCE = 'https://luminovo.ai/api';

export const ORIGIN = window.location.origin;
export const FRONTEND_APP_FRONTEND_FRIENDLY_ERROR_VERSION: 'true' | 'false' = 'false';
export const TOKEN_OPTIONS = { audience: AUDIENCE };

export const PART_SEARCH_MPN = BACKEND_BASE + '/parts/off-the-shelf/search/mpn';

export const NOT_FOUND_TEXT = 'Not Found';
