import { Trans } from '@lingui/macro';
import { SecondaryButton } from '@luminovo/design-system';
import { Delete } from '@mui/icons-material';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { usePageParams } from '../../../../resources/hooks';
import { route } from '../../../../utils/routes';
import {
    DeleteManufacturingScenarioConfirmationDialog,
    useDeleteManufacturingScenario,
} from '../../DeleteManufacturingScenario';

export const DeleteManufacturingScenarioButton = ({
    manufacturingScenarioId,
}: {
    manufacturingScenarioId: string;
}): JSX.Element => {
    const [isDialogOpen, setIsDialogOpen] = React.useState<boolean>(false);
    const { manufacturingAssemblyDetailsId, rfqId } =
        usePageParams<'/rfqs/:rfqId/manufacturing/:manufacturingAssemblyDetailsId'>();
    const history = useHistory();
    function openDialog() {
        setIsDialogOpen(true);
    }

    function closeDialog() {
        setIsDialogOpen(false);
    }
    const { mutateAsyncDeleteManufacturingScenario: deleteOnClick } = useDeleteManufacturingScenario(
        manufacturingScenarioId,
        () => {
            history.push(
                route(`/rfqs/:rfqId/manufacturing/:manufacturingAssemblyDetailsId`, {
                    rfqId,
                    manufacturingAssemblyDetailsId,
                }),
            );
        },
    );

    return (
        <>
            <SecondaryButton onClick={openDialog} startIcon={<Delete />} size="medium">
                <Trans>Delete</Trans>
            </SecondaryButton>
            <DeleteManufacturingScenarioConfirmationDialog
                isDialogOpen={isDialogOpen}
                closeDialog={closeDialog}
                deleteOnClick={() => deleteOnClick({ shouldInvalidateTableCache: false })}
            />
        </>
    );
};
