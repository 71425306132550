import { t, Trans } from '@lingui/macro';
import { Check as CheckIcon } from '@mui/icons-material';
import { Input, Menu, MenuItem } from '@mui/material';
import { styled } from '@mui/styles';
import * as React from 'react';
import { Action, Column, PersistentTableState } from './types';

const SelectedColumnIcon = styled(({ visible, ...rest }) => <CheckIcon {...rest} />)((props: { visible: boolean }) => {
    return {
        marginRight: 8,
        visibility: props.visible ? 'visible' : 'hidden',
    };
});

export function ColumnMenu<TRowData, TContext>({
    selectedColumn: column,
    anchorEl,
    isOpen,
    onClose,
    state,
    dispatch,
    sharedContext,
}: {
    selectedColumn?: Column<TRowData, TContext>;
    anchorEl: Element | null;
    isOpen: boolean;
    onClose: () => void;
    state: PersistentTableState;
    dispatch: React.Dispatch<Action>;
    sharedContext: TContext;
}): JSX.Element {
    const { comparators = [], filters = [], searchable, id, renderClearFiltersItem = true } = column ?? {};
    const hasComparators = comparators.length > 0;
    const hasFilters = filters.length > 0;
    const isSearchable = !!searchable;
    const searchPlaceholder = searchable?.placeholder ?? t`Type to search`;
    const columnId = id!;
    const query = (state.queries ?? {})[columnId] ?? '';

    return (
        <Menu anchorEl={anchorEl} open={isOpen} onClose={onClose} keepMounted variant="menu">
            {isSearchable && (
                <MenuItem key="Search" onKeyDown={(e) => e.stopPropagation()}>
                    <Input
                        autoFocus
                        fullWidth
                        placeholder={searchPlaceholder}
                        value={query}
                        onChange={(e) => {
                            dispatch({
                                type: 'set-query',
                                columnId,
                                query: e.target.value,
                            });
                        }}
                    />
                </MenuItem>
            )}

            {hasComparators && (
                <MenuItem key={`Sorting`} disabled>
                    <Trans>Sort by</Trans>
                </MenuItem>
            )}

            {comparators.map((comparator) => {
                const isSelected = state.selectedComparatorIds.includes(comparator.id);
                return (
                    <MenuItem
                        selected={isSelected}
                        key={`comparator-${comparator.id}`}
                        style={isSelected ? { fontWeight: 'bold' } : {}}
                        onClick={() => dispatch({ type: 'toggle-comparator', comparatorId: comparator.id })}
                    >
                        <SelectedColumnIcon visible={isSelected} /> {comparator.label}
                    </MenuItem>
                );
            })}

            {hasComparators && (
                <MenuItem onClick={() => dispatch({ type: 'clear-comparators' })}>
                    <Trans>Clear sorting</Trans>
                </MenuItem>
            )}

            {hasFilters && (
                <MenuItem key={`Filters`} disabled>
                    <Trans>Filter by</Trans>
                </MenuItem>
            )}

            {filters.map((filter) => {
                if ('renderFilter' in filter) {
                    return filter.renderFilter(sharedContext);
                }
                const isSelected = state.selectedFilterIds.includes(filter.id);
                return (
                    <MenuItem
                        key={`filter-${filter.id}`}
                        onClick={() => dispatch({ type: 'toggle-filter', filterId: filter.id })}
                    >
                        <SelectedColumnIcon visible={isSelected} /> {filter.label}
                    </MenuItem>
                );
            })}
            {hasFilters && renderClearFiltersItem && (
                <MenuItem onClick={() => dispatch({ type: 'clear-filters' })}>
                    <Trans>Clear filters</Trans>
                </MenuItem>
            )}
        </Menu>
    );
}
