import { t, Trans } from '@lingui/macro';
import { assertUnreachable } from '@luminovo/commons';
import { createColumnHelper, PrimaryButton, TanStackTable, useTanStackTable } from '@luminovo/design-system';
import { DemandOriginExportDTO, DemandResponseDTO } from '@luminovo/http-client';
import { QuantityKind } from '@luminovo/manufacturing-core';
import { formatQuantity } from '@luminovo/sourcing-core';
import { Upload } from '@mui/icons-material';
import { useHistory } from 'react-router';
import { useHttpQuery } from '../../../../../resources/http/useHttpQuery';
import { route } from '../../../../../utils/routes';
import { SupplierOrRecipientCell } from './SupplierOrRecipientCell';

const ImportDemandsButton = () => {
    const history = useHistory();
    return (
        <PrimaryButton
            onClick={() => {
                history.push(route('/parts/demand/demand-importer'));
            }}
            size="medium"
            startIcon={<Upload />}
        >
            <Trans>Import demand</Trans>
        </PrimaryButton>
    );
};

function displayDemandOrigin(origin: DemandOriginExportDTO): string {
    switch (origin) {
        case 'imported':
            return t`Imported`;
        case 'calculated':
            return t`Calculated`;
        default:
            assertUnreachable(origin);
    }
}

function convertToDemand(data: DemandResponseDTO, quantityType: 'net_quantity' | 'gross_quantity'): Demand {
    return {
        startDate: data.delivery_start_date,
        endDate: data.delivery_end_date,
        supplier: data.supplier.kind === 'external' ? data.supplier.supplier_number : data.supplier.site_number,
        recipient: data.recipient.kind === 'external' ? data.recipient.supplier_number : data.recipient.site_number,
        quantity: formatQuantity(data[quantityType]),
        origin: displayDemandOrigin(data.origin),
    };
}

interface Demand {
    startDate: string;
    endDate: string;
    supplier: string;
    recipient: string;
    quantity: string;
    origin: string;
}

const columnHelper = createColumnHelper<Demand>();

const columns = [
    columnHelper.date('startDate', {
        label: () => t`Start date`,
        size: 150,
        cell: (info) => info.getValue(),
    }),
    columnHelper.date('endDate', {
        label: () => t`End date`,
        size: 150,
        cell: (info) => info.getValue(),
    }),
    columnHelper.enum('supplier', {
        label: () => t`Supplier number`,
        getOptionLabel: (option) => option,
        size: 150,
        cell: (info) => <SupplierOrRecipientCell title={info.getValue()} />,
    }),
    columnHelper.enum('recipient', {
        label: () => t`Recipient number`,
        getOptionLabel: (option) => option,
        size: 150,
        cell: (info) => <SupplierOrRecipientCell title={info.getValue()} />,
    }),
    columnHelper.number('quantity', {
        label: () => t`Quantity`,
        size: 150,
        cell: (info) => info.getValue(),
    }),
    columnHelper.enum('origin', {
        label: () => t`Origin`,
        getOptionLabel: (option) => option,
        size: 150,
        cell: (info) => info.getValue(),
    }),
];

export const DemandTable = ({ ipnId, demandType }: { ipnId: string; demandType: QuantityKind }): JSX.Element => {
    const { data } = useHttpQuery(
        'POST /demand/export',
        {
            requestBody: {
                ipns: [ipnId],
            },
        },
        {
            select: (data) => data.demands.map((data) => convertToDemand(data, demandType)),
        },
    );

    const { table } = useTanStackTable({
        data,
        columns,
        enableColumnHiding: true,
        enableColumnOrdering: true,
    });

    return <TanStackTable table={table} ActionButton={ImportDemandsButton} />;
};
