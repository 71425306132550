import type { ImporterField, ParseResult, ParsedValue, Parser } from '../types';

export interface ParseEnumOptions<T> {
    cases: Array<{
        matches: string[];
        value: ParsedValue<T>;
    }>;
    caseSensitive: boolean;
}

export const parseEnum: Parser<unknown, ParseEnumOptions<unknown>> = async function parseEnum<T>(
    [cell]: string[],
    { cases, caseSensitive }: ParseEnumOptions<T>,
    field: ImporterField<T>,
): Promise<ParseResult<T>> {
    const alternatives = cases.map(({ value }) => value);

    // If the cell is empty and there is a default value, use it
    if (!cell && field.defaultValue) {
        return {
            status: 'done',
            message: `No value provided, will use default value: ${field.defaultValue.label}`,
            value: field.defaultValue,
            alternatives,
        };
    }

    const isEqual = caseSensitive
        ? (a: string = '', b: string = '') => a === b
        : (a: string = '', b: string = '') => a.toLocaleLowerCase() === b.toLocaleLowerCase();

    const exactMatch = cases.find(({ matches }) => matches.some((match) => isEqual(match, cell)));

    if (exactMatch) {
        return {
            status: 'done',
            value: exactMatch.value,
            alternatives,
        };
    }

    return {
        status: 'error',
        alternatives: alternatives,
        message: `No exact match found for ${cell}`,
    };
};
