import { t } from '@lingui/macro';
import { formatToIso8601Date, isPresent, throwErrorUnlessProduction } from '@luminovo/commons';
import { InputAdornment } from '@mui/material';
import { Column } from '@tanstack/react-table';
import { colorSystem } from '../../../theme';
import { Flexbox } from '../../Flexbox';
import { SearchInput } from '../../SearchField';
import { fieldConverterDate } from '../../forms/FieldDate/FieldDate';
import { createDateValidator } from '../../forms/FieldDate/FieldDateControlled';

export type DateRange = [string | null, string | null];

export function FilterInDateRange<TData>({
    column,
    onClose,
}: {
    column: Column<TData, unknown>;
    onClose: () => void;
}): JSX.Element | null {
    const unsafeValue = column.getFilterValue();

    if (!column.getCanFilter() || column.columnDef.filterFn !== 'inDateRange') {
        return null;
    }

    if (
        unsafeValue === undefined ||
        (Array.isArray(unsafeValue) &&
            unsafeValue.every((v) => typeof v === 'string' || !isPresent(v)) &&
            unsafeValue.length === 2)
    ) {
        const [minValue, maxValue] = (unsafeValue ?? [null, null]) as DateRange;

        const handleMinChange = (value: string | undefined) => {
            const dateValue = formatToIso8601Date(value, { ifAbsent: '' });
            column.setFilterValue((old: DateRange | undefined) => {
                const max = old?.[1];
                if (!Boolean(dateValue) && !isPresent(max)) {
                    return undefined;
                }
                return [dateValue ?? null, max ?? null];
            });
        };

        const handleMaxChange = (value: string | undefined) => {
            const dateValue = formatToIso8601Date(value, { ifAbsent: '' });
            column.setFilterValue((old: DateRange | undefined) => {
                const min = old?.[0];
                if (!Boolean(dateValue) && !isPresent(min)) {
                    return undefined;
                }
                return [min ?? null, dateValue ?? null];
            });
        };

        return (
            <Flexbox gap={4}>
                <SearchInput
                    value={String(minValue ?? '')}
                    onChange={handleMinChange}
                    debounceWait={50}
                    onKeyEnter={onClose}
                    onClear={() => handleMinChange(undefined)}
                    style={{ backgroundColor: colorSystem.neutral.white, width: '168px' }}
                    formatter={{
                        converter: fieldConverterDate,
                        validation: createDateValidator({ required: false }),
                    }}
                    InputProps={{
                        type: 'date',
                        startAdornment: <InputAdornment position="start">{t`Min`}</InputAdornment>,
                    }}
                />
                <SearchInput
                    value={String(maxValue ?? '')}
                    onChange={handleMaxChange}
                    debounceWait={50}
                    onKeyEnter={onClose}
                    onClear={() => handleMaxChange(undefined)}
                    style={{ backgroundColor: colorSystem.neutral.white, width: '168px' }}
                    formatter={{
                        converter: fieldConverterDate,
                        validation: createDateValidator({ required: false }),
                    }}
                    InputProps={{
                        type: 'date',
                        startAdornment: <InputAdornment position="start">{t`Max`}</InputAdornment>,
                    }}
                />
            </Flexbox>
        );
    }

    throwErrorUnlessProduction(new Error(`Invalid filter value for in range filter: ${unsafeValue}`));
    return null;
}
