import { t } from '@lingui/macro';
import { assertUnreachable, LexoRank, LexoRanking } from '@luminovo/commons';
import {
    AvailableSpaceContainer,
    CenteredLayout,
    Column,
    columnWidth,
    Row,
    ScrollableTableContainer,
} from '@luminovo/design-system';
import {
    DriverDetailsType,
    DriverFilterDTO,
    UserDriverDetailsBulkPatchDTO,
    UserDriverDetailsDTO,
} from '@luminovo/http-client';
import { RightAlignedStickyTableCell, TooltipText } from '@luminovo/manufacturing-core';
import { CircularProgress, TableCell, Typography } from '@mui/material';
import { transEnum, TransEnum } from '../../../components/localization/TransEnum';
import {
    aggregationTypePublicTranslations,
    aggregationValuePublicTranslations,
    attributeReferencePublicTranslations,
    driverTypeEnumPublicTranslations,
    enumFilterOperatorsPublicTranslations,
    numberFilterOperatorsPublicTranslations,
} from '../../../resources/driver/driverBackendTypes';
import { useHttpQuery } from '../../../resources/http/useHttpQuery';
import { TextCell } from '../ActivitiesTable/activitiesTableComponents';
import { NotPerPanelIconAndText, PerPanelIconAndText } from '../shared/icons';
import { nameColumn, statusColumn } from '../shared/sharedManufacturingColumns';
import { CalculationTextWrapper } from './CalculationColumn';

import { SortableDataTable, useSortableDataTableState } from '@luminovo/manufacturing-core';
import { useSnackbar } from 'notistack';
import { useMutationBulkUpdateDrivers, useMutationUpdateDriver } from '../../../resources/driver/driverHandler';
import { manufacturingScenarioTemplateStatusEnumPublicTranslations } from '../../../resources/manufacturingScenarioTemplates/manufacturingScenarioTemplatesFrontendTypes';
import { useCanEditManufacturingDatabase } from '../utils/useCanEditManufacturingDatabase';
import { DriverMenuComponent } from './driversTableComponents';

export function getFormulaDetails(allFilters: DriverFilterDTO[] | undefined): string[] {
    if (allFilters === undefined) return [];
    return allFilters.reduce((result: string[], filter: DriverFilterDTO) => {
        const formula = filter.filter;
        switch (formula.type) {
            case 'AnyOf':
                return [
                    ...result,
                    transEnum(formula.filter.operator, enumFilterOperatorsPublicTranslations),
                    ...formula.filter.rhs.map((rhs) =>
                        transEnum(rhs.attribute_reference, attributeReferencePublicTranslations),
                    ),
                ];
            case 'Enum':
                return [
                    ...result,
                    transEnum(formula.filter.operator, enumFilterOperatorsPublicTranslations),
                    transEnum(formula.filter.rhs.attribute_reference, attributeReferencePublicTranslations),
                ];
            case 'Monetary':
                return [
                    ...result,
                    transEnum(formula.filter.operator, numberFilterOperatorsPublicTranslations),
                    transEnum(formula.filter.attribute, attributeReferencePublicTranslations),
                    formula.filter.rhs.currency,
                ];
            case 'Number':
                return [
                    ...result,
                    transEnum(formula.filter.operator, numberFilterOperatorsPublicTranslations),
                    transEnum(formula.filter.attribute, attributeReferencePublicTranslations),
                    formula.filter.rhs,
                ];
            default:
                return assertUnreachable(formula);
        }
    }, []);
}

function getDriverDetails(driver: UserDriverDetailsDTO): string[] {
    const driverType = transEnum(driver.details.type, driverTypeEnumPublicTranslations);
    if (driver.details.type === 'Manual') {
        return [driverType];
    }
    if (driver.details.type === 'Automatic') {
        if (driver.details.automatic_details === undefined) return [driverType];
        return [
            driverType,
            transEnum(driver.details.automatic_details.aggregation.type, aggregationTypePublicTranslations),
            transEnum(driver.details.automatic_details.aggregation.value, aggregationValuePublicTranslations),
            ...getFormulaDetails(driver.details.automatic_details.filter_formula),
        ];
    }
    return [];
}

function computeNewLexoRank(items: UserDriverDetailsDTO[], oldIndex: number, newIndex: number): LexoRank | undefined {
    // Case 1: Move before first item
    if (newIndex === 0) {
        return LexoRanking.beforeFirstLexoRank(items[0].lexorank);
    }

    // Case 2: Move after last item
    const lastIndex = items.length - 1;
    if (newIndex === lastIndex) {
        return LexoRanking.afterLastLexoRank(items[lastIndex].lexorank);
    }

    // Case 3: Move between two items
    const [lowerIndex, upperIndex] = newIndex >= oldIndex ? [newIndex, newIndex + 1] : [newIndex - 1, newIndex];
    const lowerLexoRank = items[lowerIndex].lexorank;
    const upperLexoRank = items[upperIndex].lexorank;
    return LexoRanking.between(lowerLexoRank, upperLexoRank);
}

const DriversTable = ({ query }: { query: string }): JSX.Element => {
    const { data: sortedDrivers = [], isLoading } = useHttpQuery(
        'GET /user-drivers',
        {},
        { select: (drivers) => drivers.data },
    );
    const canEditManufacturingDatabase = useCanEditManufacturingDatabase();

    const columns: Column<UserDriverDetailsDTO>[] = [
        statusColumn,
        nameColumn,
        {
            label: t`Per panel`,
            id: `perPanel`,
            render: ({ data: rowData }: Row<UserDriverDetailsDTO>) => (
                <TextCell firstRow={rowData.is_per_panel ? <PerPanelIconAndText /> : <NotPerPanelIconAndText />} />
            ),
            width: columnWidth.small,
            filters: [
                {
                    id: 'per panel=true',
                    label: t`Yes`,
                    predicate: (driver): boolean => driver.is_per_panel,
                },
                {
                    id: 'per panel=false',
                    label: t`No`,
                    predicate: (driver): boolean => !driver.is_per_panel,
                },
            ],
        },
        {
            label: t`Type`,
            id: `type`,
            render: ({ data: rowData }: Row<UserDriverDetailsDTO>) => (
                <TextCell
                    firstRow={
                        <Typography>
                            <TransEnum text={rowData.details.type} translations={driverTypeEnumPublicTranslations} />
                        </Typography>
                    }
                />
            ),
            width: columnWidth.small,
            filters: [
                {
                    id: 'driver type=manual',
                    label: t`Manual`,
                    predicate: (driver): boolean => driver.details.type === 'Manual',
                },
                {
                    id: 'driver type=automatic',
                    label: t`Automatic`,
                    predicate: (driver): boolean => driver.details.type === 'Automatic',
                },
            ],
        },
        {
            label: t`Calculation`,
            id: `calculation`,
            render: ({ data: rowData }: Row<UserDriverDetailsDTO>) => (
                <TextCell
                    firstRow={
                        rowData.details.type === DriverDetailsType.Manual ? (
                            ''
                        ) : (
                            <CalculationTextWrapper automaticDetails={rowData.details.automatic_details} />
                        )
                    }
                />
            ),
            width: columnWidth.extraLarge,
        },
        {
            label: t`Notes`,
            id: `notes`,
            render: ({ data: rowData }: Row<UserDriverDetailsDTO>) => (
                <TableCell>
                    <TooltipText text={rowData.notes ?? '-'} maxWidth={'220px'} minCharactersWithTooltip={3} />
                </TableCell>
            ),
            width: columnWidth.extraLarge,
            searchable: { searchBy: (rowData): string => rowData.notes ?? '' },
        },
        {
            label: '', // intentionally blank
            id: `driversActionsMenu`,
            renderHead: () => <RightAlignedStickyTableCell />,
            render: ({ data: rowData }: Row<UserDriverDetailsDTO>) =>
                canEditManufacturingDatabase ? (
                    // because driver details can only be contained in a user type, we can safely cast it to a user type
                    <DriverMenuComponent driverId={{ value: rowData.id, type: 'User' }} status={rowData.status} />
                ) : (
                    <TableCell />
                ),
            width: columnWidth.small,
        },
    ];

    const idExtractor = (driver: UserDriverDetailsDTO): string => driver.id;

    const sortableTableState = useSortableDataTableState<UserDriverDetailsDTO>({
        columns,
        items: sortedDrivers ?? [],
        persistenceId: 'driversTable',
        selectionOptions: { idExtractor },
        query,
        searchOptions: {
            idExtractor,
            contentSearchOptions: {
                indexingStrategy: 'data-content',
                indexItemData: (item) => [
                    ...getDriverDetails(item),
                    item.name,
                    transEnum(item.status, manufacturingScenarioTemplateStatusEnumPublicTranslations),
                    item.notes ?? '',
                ],
            },
        },
    });

    const { enqueueSnackbar } = useSnackbar();
    const enqueueErrorSnackbar = () => {
        enqueueSnackbar(t`Failed to update driver order`, { variant: 'error' });
    };
    const { mutateAsync: updateDriver } = useMutationUpdateDriver(enqueueErrorSnackbar);
    const { mutateAsync: bulkUpdateDrivers, isError: isRebalancingError } = useMutationBulkUpdateDrivers();

    const balanceLexoRanks = async (drivers: UserDriverDetailsDTO[]) => {
        const driverUpdates = LexoRanking.balance(drivers).map((driver) => {
            return {
                id: driver.id,
                update: {
                    lexorank: driver.lexorank,
                },
            };
        });

        const updates: UserDriverDetailsBulkPatchDTO = {
            drivers: driverUpdates,
        };
        return await bulkUpdateDrivers({ updates });
    };

    if (isLoading) {
        return (
            <CenteredLayout height={'30vh'}>
                <CircularProgress />
            </CenteredLayout>
        );
    }

    const onDragEndCallback = async (
        newlySortedDrivers: UserDriverDetailsDTO[],
        oldIndex: number,
        newIndex: number,
    ): Promise<void> => {
        if (!canEditManufacturingDatabase) {
            enqueueSnackbar(t`The order cannot be updated with the lite version of manufacturing.`, {
                variant: 'error',
            });
            return;
        }

        const updatedLexoRank = computeNewLexoRank(sortedDrivers, oldIndex, newIndex);

        if (!updatedLexoRank) {
            // If the new lexo rank is undefined, we start rebalancing. We are using the already
            // sorted array that's passed to the callback. That way we don't need another
            // call to update the lexo rank of the driver at 'oldIndex'.
            await balanceLexoRanks(newlySortedDrivers);
            if (isRebalancingError) {
                enqueueErrorSnackbar();
            }
            return;
        }

        const draggedItem = sortedDrivers[oldIndex];
        await updateDriver({ update: { lexorank: updatedLexoRank }, driverId: draggedItem.id });
    };

    return (
        <SortableDataTable
            idExtractor={idExtractor}
            sortableTableState={sortableTableState}
            onDragEndCallback={onDragEndCallback}
            dataTableProps={{
                size: 'medium',
                overrides: { Container: AvailableSpaceContainer, TableContainer: ScrollableTableContainer },
            }}
        />
    );
};

export default DriversTable;
