import { IpnWithMatchesFullPart, isOtsComponentFull, isOtsFullPart, OtsFullPart } from '@luminovo/http-client';

export const findComparableOtsPart = (part: OtsFullPart | IpnWithMatchesFullPart): OtsFullPart => {
    if (isOtsComponentFull(part)) {
        const otsLinkedParts: OtsFullPart[] = part.matches.reduce((arr: OtsFullPart[], part) => {
            if (isOtsFullPart(part.part.data)) {
                arr.push(part.part.data);
            }
            return arr;
        }, []);

        return otsLinkedParts[0];
    }
    return part;
};
