import { Trans } from '@lingui/macro';
import { SkeletonProps } from '@mui/lab';
import { Backdrop, Box, CircularProgress, Skeleton as MuiSkeleton, styled, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { themeLuminovo } from '../themes';
import { logToExternalErrorHandlers } from '../utils/customConsole';
import { SadBox } from './errorHandlers/SadBox';

const StyledBackdrop = styled(Backdrop)({
    backgroundColor: themeLuminovo.palette.background.default,
});

const SECONDS_TO_CALL_SENTRY = 30;
const SECONDS_TO_SHOW_ERROR_FALLBACK = SECONDS_TO_CALL_SENTRY * 3;

const useSpinnerFallback = () => {
    const [showErrorFallback, setShowErrorFallback] = useState(false);

    useEffect(() => {
        const captureErrorToSentry = () => {
            logToExternalErrorHandlers(new Error(`Spinner shown for ${SECONDS_TO_CALL_SENTRY} seconds.`));
        };
        const timer = setTimeout(captureErrorToSentry, SECONDS_TO_CALL_SENTRY * 1000);
        return () => {
            clearTimeout(timer);
        };
    });

    useEffect(() => {
        const timer = setTimeout(() => setShowErrorFallback(true), SECONDS_TO_SHOW_ERROR_FALLBACK * 1000);
        return () => {
            clearTimeout(timer);
        };
    }, []);
    return { showErrorFallback: showErrorFallback };
};

export const ErrorText = ({ text }: { text?: string }): JSX.Element => (
    <Typography color="error"> {text ? text : <Trans>Error fetching information</Trans>}</Typography>
);

export const SpinnerWithBackdrop = ({ noBackdrop, size }: { noBackdrop?: boolean; size?: number | string }) => {
    const { showErrorFallback } = useSpinnerFallback();
    return (
        <>
            {noBackdrop ? (
                <Box style={{ textAlign: 'center' }}>
                    {showErrorFallback ? <ErrorText /> : <CircularProgress size={size ? size : 40} color="primary" />}
                </Box>
            ) : (
                <StyledBackdrop open={true}>
                    {showErrorFallback ? <SadBox /> : <CircularProgress color="primary" size={size ? size : 40} />}
                </StyledBackdrop>
            )}
        </>
    );
};

export const LoadingText = () => {
    const { showErrorFallback } = useSpinnerFallback();
    return (
        <>
            {showErrorFallback ? (
                <ErrorText />
            ) : (
                <Typography>
                    <Trans>Loading...</Trans>
                </Typography>
            )}
        </>
    );
};

export const Skeleton: React.FunctionComponent<SkeletonProps> = (props) => {
    const { showErrorFallback } = useSpinnerFallback();
    return <>{showErrorFallback ? <ErrorText /> : <MuiSkeleton {...props} />}</>;
};
