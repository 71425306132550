import { t } from '@lingui/macro';
import { isPresent } from '@luminovo/commons';
import { runImporter } from '../../model/runImporter';
import { ImportBatchHandler, ImporterConfig, Table } from '../../types';
import { Stepper } from '../Stepper';
import { useStepperState } from '../Stepper/Stepper';
import { Step } from '../Stepper/types';
import { StepCheck } from './components/check/StepCheck';
import { StepCheckHelpDialog } from './components/check/StepCheckHelpDialog';
import { StepLinkColumns } from './components/link/StepLinkColumns';
import { StepPreview } from './components/preview/StepPreview';
import { StepPreviewHelpDialog } from './components/preview/StepPreviewHelpDialog';
import { StepUploadDropzone } from './components/upload/StepUploadDropzone';
import { StepUploadHelpDialog } from './components/upload/StepUploadHelpDialog';
import { UniversalImporterProvider, useUniversalImporter } from './context';
import { useValidateGlobalFields } from './hooks/useValidateGlobalFields';

export interface UniversalImporterProps<TConfig extends ImporterConfig = ImporterConfig> {
    title?: string;
    hrefBack?: string;
    config: TConfig;
    initialTable?: Table;
    onImportBatch: ImportBatchHandler<TConfig>;
    onImportDone?: () => void;
    batchSize?: number;
}

export function UniversalImporter<TConfig extends ImporterConfig>(props: UniversalImporterProps<TConfig>): JSX.Element {
    return (
        <UniversalImporterProvider config={props.config} initialTable={props.initialTable}>
            <UniversalImporterInner {...props} />
        </UniversalImporterProvider>
    );
}

function UniversalImporterInner<TConfig extends ImporterConfig>({
    title = t`Upload file`,
    hrefBack = '/',
    onImportBatch,
    onImportDone,
    batchSize = 100,
}: UniversalImporterProps<TConfig>): JSX.Element {
    const { state, dispatch } = useUniversalImporter();
    const isGlobalFormValid = useValidateGlobalFields();
    const { table, config, importerTable } = state;
    const isImportFailed = importerTable?.getRows().some((r) => r.import?.success === false);

    const isReadyForPreview = importerTable?.isReadyForPreview() ?? false;
    const isReadyForImport = importerTable?.isReadyForImport() ?? false;
    const areGlobalFieldsDefined = config.globalFields !== undefined && config.globalFields.length > 0;
    const { stepIndex, setStepIndex } = useStepperState(areGlobalFieldsDefined ? 5 : 4);

    const allColumnsMapped = config.fields
        .filter((f) => f.required)
        .every((field) => {
            return field.columnIndices.length > 0;
        });

    const steps: Step[] = [
        {
            label: t`Upload`,
            content: <StepUploadDropzone onStepIndexChange={setStepIndex} />,
            enabled: !isImportFailed,
            isDone: table !== undefined,

            helpDialog: {
                title: title,
                body: <StepUploadHelpDialog />,
            },
        },
        {
            label: t`Map columns`,
            content: <StepLinkColumns />,
            enabled: Boolean(table) && !isImportFailed,
            isDone: allColumnsMapped && isGlobalFormValid,
        },
        {
            label: t`Check`,
            content: <StepCheck />,
            enabled: allColumnsMapped && !isImportFailed && isGlobalFormValid,
            isDone: isReadyForPreview,
            helpDialog: {
                title: t`Fix errors to continue`,
                body: <StepCheckHelpDialog />,
            },
        },
        {
            label: t`Preview`,
            content: <StepPreview setStepIndex={setStepIndex} />,
            enabled: isReadyForPreview && !isImportFailed && isGlobalFormValid,
            isDone: isReadyForImport && !isImportFailed,
            helpDialog: {
                title: t`Preview changes`,
                body: <StepPreviewHelpDialog />,
            },
        },
    ].filter(isPresent);

    return (
        <Stepper
            hrefBack={hrefBack}
            title={title}
            onSubmit={async () => {
                let table = state.importerTable;
                const globalFields = state.config.globalFields;
                if (!table) {
                    return;
                }
                for await (const intermediateResult of runImporter({
                    batchSize,
                    table,
                    onImportBatch,
                    globalFields,
                })) {
                    dispatch({ type: 'setImporterTable', table: intermediateResult });
                    table = intermediateResult;
                }
                if (table.getImportStatusCount().error === 0) {
                    onImportDone?.();
                }
            }}
            steps={steps}
            stepIndex={stepIndex}
            onStepIndexChange={(e) => {
                setStepIndex(e);
            }}
        />
    );
}
