import { inRange, isPresent } from '@luminovo/commons';
import { Flexbox, colorSystem } from '@luminovo/design-system';
import { styled } from '@mui/material';
import React from 'react';
import { PCBFileWithId } from '../../LayerAssignment/utils/layerAssignmentsUtils';

const RowBoxWrapper = styled(Flexbox)({
    height: '99px',
    flexDirection: 'column',
    boxSizing: 'border-box',
    alignItems: 'center',
    justifyContent: 'center',
    borderWidth: '1px',
    borderColor: colorSystem.neutral[4],
    borderBottomStyle: 'dashed',
});

const DrillSliderBackground: React.FunctionComponent<{
    isFirstRow: boolean;
    isLastRow: boolean;
}> = ({ isFirstRow, isLastRow, children }) => {
    const commonStyles: React.CSSProperties = {
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        width: '40px',
        backgroundColor: colorSystem.neutral[2],
        borderRadius: '0px',
        borderColor: colorSystem.neutral[6],
        borderStyle: 'none dashed none dashed',
    };

    if (isFirstRow || isLastRow) {
        return (
            <Flexbox
                flexDirection={'column'}
                style={{
                    ...commonStyles,
                    height: '70%',
                    borderRadius: isFirstRow ? '20px 20px 0px 0px' : '0px 0px 20px 20px',
                    borderStyle: isFirstRow ? 'dashed dashed none dashed' : 'none dashed dashed dashed',
                }}
            >
                {children}
            </Flexbox>
        );
    }

    return (
        <Flexbox
            flexDirection={'column'}
            style={{
                ...commonStyles,
            }}
        >
            {children}
        </Flexbox>
    );
};

const Span = styled('span')(({ isSelected, disabled }: { isSelected: boolean; disabled: boolean }) => ({
    backgroundColor: '#FFFF',
    border: isSelected ? `1px solid ${colorSystem.primary[6]}` : `2px dashed ${colorSystem.primary[5]}`,
    boxShadow: isSelected
        ? `0px 3px 5px -1px rgba(0, 0, 0, 0.1), 0px 6px 10px rgba(0, 0, 0, 0.07), 0px 1px 18px rgba(0, 0, 0, 0.06)`
        : '',
    height: '20px',
    width: '20px',
    borderRadius: '50%',
    cursor: disabled ? 'default' : 'pointer',
    '&:hover': {
        background: disabled ? '' : colorSystem.primary[1],
    },
}));

const RowButton: React.FunctionComponent<{
    isSelected: boolean;
    disabled?: boolean;
    onClick: () => void;
    style?: React.CSSProperties;
}> = ({ isSelected, disabled = false, onClick, style }) => {
    return (
        <Span
            isSelected={isSelected}
            disabled={disabled}
            onClick={() => !disabled && onClick()}
            style={{
                ...style,
            }}
        />
    );
};

export const DrillRow: React.FunctionComponent<{
    index: number;
    drillFile: PCBFileWithId;
    disabled?: boolean;
    onSelect: (index: number, drillFile: PCBFileWithId) => void;
    style?: React.CSSProperties;
}> = ({ index, drillFile, disabled = false, onSelect, style }) => {
    const isFirstRow = index + 1 === drillFile.fileType.from;
    const isLastRow = index + 1 === drillFile.fileType.to;
    const isDrillFileInRange =
        isPresent(drillFile.fileType.from) && isPresent(drillFile.fileType.to)
            ? inRange(index + 1, drillFile.fileType.from, drillFile.fileType.to + 1)
            : false;

    if (isPresent(drillFile.fileType.from) && isPresent(drillFile.fileType.to)) {
        return (
            <RowBoxWrapper
                style={{
                    justifyContent: isFirstRow ? 'end' : isLastRow ? 'start' : 'center',
                    ...style,
                }}
            >
                {isDrillFileInRange && (
                    <DrillSliderBackground isFirstRow={isFirstRow} isLastRow={isLastRow}>
                        {(isFirstRow || isLastRow) && (
                            <RowButton
                                disabled={disabled}
                                onClick={() => onSelect(index, drillFile)}
                                isSelected={true}
                            />
                        )}
                    </DrillSliderBackground>
                )}
            </RowBoxWrapper>
        );
    }

    return (
        <RowBoxWrapper style={style}>
            <RowButton
                onClick={() => onSelect(index, drillFile)}
                isSelected={isFirstRow || isLastRow}
                disabled={disabled || isFirstRow || isLastRow}
                style={{
                    display:
                        isPresent(drillFile.fileType.from) && drillFile.fileType.from > index + 1 ? 'none' : 'inherit',
                }}
            />
        </RowBoxWrapper>
    );
};
