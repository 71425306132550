import { Trans } from '@lingui/macro';
import { ActorResponseRuntypeDTO, HistoryOperation } from '@luminovo/http-client';
import { isActorDefined } from '../utils';
import { ActionPerformedBy, TimelineAddIcon, TimelineContentContainer, TimelineEditIcon, TimelineText } from './Utils';

export function AssemblyIcon({ operation }: { operation: HistoryOperation }) {
    if (operation === HistoryOperation.ManualAdd) return <TimelineAddIcon />;
    return <TimelineEditIcon />;
}

type Props = {
    actor: ActorResponseRuntypeDTO;
    assemblyName: string;
    operation: HistoryOperation;
};

function useAssemblyAction(operation: HistoryOperation, actor: ActorResponseRuntypeDTO, assemblyName: string) {
    if (!isActorDefined(actor)) return null;
    switch (operation) {
        case HistoryOperation.ManualAdd:
            return (
                <TimelineText>
                    <Trans>
                        Assembly <b>{assemblyName}</b> created
                    </Trans>{' '}
                    <ActionPerformedBy actor={actor} />
                </TimelineText>
            );
        case HistoryOperation.ManualUpdate:
            return (
                <TimelineText>
                    <Trans>
                        Assembly name changed to <b>{assemblyName}</b>
                    </Trans>{' '}
                    <ActionPerformedBy actor={actor} />
                </TimelineText>
            );
        default:
            return null;
    }
}

export default function Assembly({ actor, assemblyName, operation }: Props) {
    const action = useAssemblyAction(operation, actor, assemblyName);
    if (!action) return null;
    return <TimelineContentContainer>{action}</TimelineContentContainer>;
}
