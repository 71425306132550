import { omit } from '@luminovo/commons';
import * as React from 'react';
import { Checkbox, CheckboxProps } from '../../Checkbox/Checkbox';

export type FieldCheckboxProps = Omit<CheckboxProps, 'checked' | 'onChange'> & {
    value: boolean;

    onChange: (value: boolean) => void;
};

function FieldCheckboxInner(
    { value, onChange, placeholder, ...rest }: FieldCheckboxProps,
    outerRef: React.ForwardedRef<HTMLButtonElement>,
): JSX.Element {
    return (
        <Checkbox
            ref={outerRef}
            checked={value}
            onChange={(_, value) => onChange(value)}
            {...omit(rest, 'error', 'helperText')}
        />
    );
}

// To restrict the type of props that can be passed into the FieldCheckboxInner, https://stackoverflow.com/a/58473012
declare module 'react' {
    function forwardRef<T, P = {}>(
        render: (props: P, ref: React.Ref<T>) => React.ReactElement | null,
    ): (props: P & React.RefAttributes<T>) => React.ReactElement | null;
}
export const FieldCheckbox = React.forwardRef(FieldCheckboxInner);
