import { Trans, t } from '@lingui/macro';
import { Dropzone, Flexbox, ReleaseTag, SecondaryButton, Text, colorSystem } from '@luminovo/design-system';
import React from 'react';
import { useMutationCreatePCBWithoutFile, useMutationUploadPcbFiles } from '../../../../../resources/pcb/pcbHandlers';
import { ViewContext } from '../../../../Bom/components/ModuleTableData';

interface Props {
    assemblyId: string;
    pcbId?: string;
    rfqId: string;
    isPcbOptional: boolean;
    viewContext: ViewContext;
}

const FileExtensionsInfo: React.FunctionComponent = () => (
    <Flexbox alignItems="center" gap={'8px'}>
        <Text variant={'body-small'} color={colorSystem.neutral[6]}>
            <Trans> We support Gerber X2, Excellon, and ODB++</Trans>
        </Text>
        <ReleaseTag color="violet" label={t`BETA`} />
    </Flexbox>
);

export function UploadPcbFilesDropzone({ assemblyId, pcbId, rfqId, isPcbOptional, viewContext }: Props) {
    const { mutateAsync: createPCBWithFile, isLoading } = useMutationUploadPcbFiles(assemblyId, rfqId, pcbId);
    const { mutateAsync: createPCBWithoutFile } = useMutationCreatePCBWithoutFile(
        viewContext,
        assemblyId,
        rfqId,
        pcbId,
    );

    return (
        <Dropzone
            title={t`PCB files`}
            subHeader={t`We also support archives (zip, tar, etc.) containing the files.`}
            onDropAccepted={(files) => createPCBWithFile(files)}
            multiple={true}
            isLoading={isLoading}
            accept={null}
            overrides={{ FileExtensionsInfo }}
            extraContent={
                <>
                    {isPcbOptional && (
                        <SecondaryButton size={'medium'} onClick={() => createPCBWithoutFile()}>
                            <Trans>Proceed without files</Trans>
                        </SecondaryButton>
                    )}
                </>
            }
        />
    );
}
