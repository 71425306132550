import { Trans, t } from '@lingui/macro';
import { assertPresent, compareByString } from '@luminovo/commons';
import { Add, Close, FilterListRounded } from '@mui/icons-material';
import { Badge, ListItem, Menu } from '@mui/material';
import { Column, ColumnFilter, Table } from '@tanstack/react-table';
import React from 'react';
import { colorSystem } from '../../../theme';
import { Flexbox } from '../../Flexbox';
import { MenuItem } from '../../MenuItem';
import { SearchInput } from '../../SearchField';
import { Text } from '../../Text';
import { Tooltip } from '../../Tooltip';
import { DestructiveTertiaryIconButton, SecondaryButton } from '../../buttons';
import {
    FilterArrIncludesSome,
    FilterArrIncludesSomeField,
    FilterEnumEqualsAny,
    FilterEnumEqualsAnyField,
    FilterInDateRange,
    FilterInMonetaryValueRange,
    FilterInNumberRange,
    FilterIncludesString,
} from '../Filters';

export type FilterMenuState =
    | {
          type: 'None';
          anchorEl: undefined;
      }
    | {
          type: 'AddFilter';
          anchorEl: Element;
      }
    | {
          type: 'ColumnFilter';
          anchorEl: Element;
          column: Column<any, unknown>;
      }
    | {
          type: 'FilterManager';
          anchorEl: Element;
      };

export function FilterMenu<TData>({ table }: { table: Table<TData> }): JSX.Element {
    const [menuState, setMenuState] = React.useState<FilterMenuState>({ type: 'None', anchorEl: undefined });

    const filterCount = table.getAllLeafColumns().filter((col) => col.getIsFiltered()).length;
    const hasFiltersEnabled = table.getAllLeafColumns().some((col) => col.getCanFilter());

    const handleOnClick = React.useCallback(
        (e: React.MouseEvent<HTMLButtonElement>) => {
            if (menuState.type !== 'None') {
                return;
            }
            if (filterCount === 0) {
                setMenuState({ type: 'AddFilter', anchorEl: e.currentTarget });
            } else {
                setMenuState({ type: 'FilterManager', anchorEl: e.currentTarget });
            }
        },
        [menuState, filterCount, setMenuState],
    );

    const handleOpenAddFilterMenu = () => {
        if (menuState.type === 'None') {
            return;
        }
        setMenuState({ type: 'AddFilter', anchorEl: menuState.anchorEl });
    };

    const handleOpenColumnFilter = (column: Column<any, unknown>) => {
        if (menuState.type === 'None') {
            return;
        }
        setMenuState({ type: 'ColumnFilter', anchorEl: menuState.anchorEl, column });
    };

    const handleClose = () => {
        setMenuState({ type: 'None', anchorEl: undefined });
    };

    if (!hasFiltersEnabled) {
        return <></>;
    }

    return (
        <>
            <Badge badgeContent={filterCount} color={'primary'}>
                <SecondaryButton size={'medium'} onClick={handleOnClick} startIcon={<FilterListRounded />}>
                    <Trans>Filter</Trans>
                </SecondaryButton>
            </Badge>

            <Menu
                anchorEl={menuState.anchorEl}
                open={menuState.type === 'FilterManager'}
                onClose={handleClose}
                variant="menu"
                keepMounted
            >
                <FilterManager table={table} onClose={handleClose} />
                <AddFilterMenuItem
                    table={table}
                    menuState={menuState}
                    onClick={handleOpenAddFilterMenu}
                    onColumnFilter={handleOpenColumnFilter}
                    onClose={handleClose}
                />
                <DeleteFilterMenuItem table={table} onClose={handleClose} />
            </Menu>

            <ColumFilterMenu menuState={menuState} onClose={handleClose} table={table} />
        </>
    );
}

function FilterManager<TData>({ table, onClose }: { table: Table<TData>; onClose: () => void }): JSX.Element {
    const filters = table.getState().columnFilters;

    return (
        <ListItem
            style={{ backgroundColor: colorSystem.neutral.white, padding: '8px' }}
            divider={true}
            onKeyDown={(e) => e.stopPropagation()}
        >
            <Flexbox flexDirection={'column'} gap={8}>
                {filters.map((columFilter) => (
                    <FilterManagerItem key={columFilter.id} columFilter={columFilter} table={table} onClose={onClose} />
                ))}
                {filters.length === 0 && (
                    <Text variant={'body-small'} color={colorSystem.neutral[6]}>
                        {t`No filters applied`}
                    </Text>
                )}
            </Flexbox>
        </ListItem>
    );
}

function FilterManagerItem<TData>({
    columFilter,
    table,
    onClose,
}: {
    columFilter: ColumnFilter;
    table: Table<TData>;
    onClose: () => void;
}) {
    const sharedContext = table.options.meta?.sharedContext;
    const column = assertPresent(table.getColumn(columFilter.id));
    const onlyHasOneFilter = table.getAllLeafColumns().filter((col) => col.getIsFiltered()).length === 1;

    const handleRemoveFilter = () => {
        column.setFilterValue(undefined);
        if (onlyHasOneFilter) {
            onClose();
        }
    };

    return (
        <Flexbox gap={12} justifyContent={'space-between'} alignItems={'center'} width={'100%'}>
            <Flexbox justifyContent={'space-between'} minWidth={'160px'} width={'100%'} alignItems={'center'}>
                {column.columnDef.filterFn === 'includesString' && (
                    <Flexbox gap={8} alignItems={'center'}>
                        <Text
                            variant={'body-small'}
                            style={{ whiteSpace: 'nowrap' }}
                        >{t`${column.columnDef.meta?.label()} contains`}</Text>
                        <FilterIncludesString column={column} onClose={onClose} />
                    </Flexbox>
                )}

                {column.columnDef.filterFn === 'inNumberRange' && (
                    <Flexbox gap={8} alignItems={'center'}>
                        <Text
                            variant={'body-small'}
                            style={{ whiteSpace: 'nowrap' }}
                        >{t`${column.columnDef.meta?.label()} is between`}</Text>
                        <FilterInNumberRange column={column} onClose={onClose} />
                    </Flexbox>
                )}
                {column.columnDef.filterFn === 'inMonetaryValueRange' && (
                    <Flexbox gap={8} alignItems={'center'}>
                        <Text
                            variant={'body-small'}
                            style={{ whiteSpace: 'nowrap' }}
                        >{t`${column.columnDef.meta?.label()} is between`}</Text>
                        <FilterInMonetaryValueRange column={column} onClose={onClose} />
                    </Flexbox>
                )}
                {column.columnDef.filterFn === 'inDateRange' && (
                    <Flexbox gap={8} alignItems={'center'}>
                        <Text
                            variant={'body-small'}
                            style={{ whiteSpace: 'nowrap' }}
                        >{t`${column.columnDef.meta?.label()} is between`}</Text>
                        <FilterInDateRange column={column} onClose={onClose} />
                    </Flexbox>
                )}
                {column.columnDef.filterFn === 'equalsAny' && (
                    <Flexbox gap={8} alignItems={'center'}>
                        <Text
                            variant={'body-small'}
                            style={{ whiteSpace: 'nowrap' }}
                        >{t`${column.columnDef.meta?.label()} is`}</Text>
                        <FilterEnumEqualsAnyField column={column} sharedContext={sharedContext} />
                    </Flexbox>
                )}
                {column.columnDef.filterFn === 'arrIncludesSome' && (
                    <Flexbox gap={8} alignItems={'center'}>
                        <Text
                            variant={'body-small'}
                            style={{ whiteSpace: 'nowrap' }}
                        >{t`${column.columnDef.meta?.label()} is`}</Text>
                        <FilterArrIncludesSomeField column={column} sharedContext={sharedContext} />
                    </Flexbox>
                )}
            </Flexbox>

            <Tooltip title={t`Remove filter rule`} placement="left">
                <DestructiveTertiaryIconButton size={'small'} onClick={handleRemoveFilter}>
                    <Close fontSize={'inherit'} />
                </DestructiveTertiaryIconButton>
            </Tooltip>
        </Flexbox>
    );
}

function AddFilterMenuItem<TData>({
    table,
    menuState,
    onClick,
    onColumnFilter,
    onClose,
}: {
    table: Table<TData>;
    menuState: FilterMenuState;
    onClick: () => void;
    onColumnFilter: (column: Column<any, unknown>) => void;
    onClose: () => void;
}): JSX.Element {
    const [query, setQuery] = React.useState<string>('');

    const columns = table
        .getAllLeafColumns()
        .filter((col) => col.getCanFilter())
        .filter((col) => !col.getIsFiltered())
        .filter((col) => col.columnDef.meta?.label().toLocaleLowerCase().includes(query))
        .sort((a, b) => compareByString(a.columnDef.meta?.label() ?? '', b.columnDef.meta?.label() ?? ''));

    return (
        <>
            <MenuItem label={t`Add filter`} onClick={onClick} startIcon={<Add />} />

            <Menu
                anchorEl={menuState.anchorEl}
                open={menuState.type === 'AddFilter'}
                onClose={onClose}
                variant="menu"
                keepMounted
            >
                <ListItem style={{ backgroundColor: colorSystem.neutral.white }} onKeyDown={(e) => e.stopPropagation()}>
                    <SearchInput
                        placeholder={t`Filter by...`}
                        value={query}
                        onChange={(value) => setQuery(value)}
                        onClear={() => setQuery('')}
                        style={{ backgroundColor: colorSystem.neutral.white }}
                    />
                </ListItem>

                {columns.map((column) => (
                    <MenuItem
                        label={column.columnDef.meta?.label() ?? t`Unknown`}
                        key={column.id}
                        onClick={() => onColumnFilter(column)}
                    />
                ))}

                {columns.length === 0 && (
                    <ListItem style={{ backgroundColor: colorSystem.neutral.white }}>
                        <Text variant={'body-small'} color={colorSystem.neutral[6]}>
                            <Trans>No results</Trans>
                        </Text>
                    </ListItem>
                )}
            </Menu>
        </>
    );
}

export function ColumFilterMenu<TData>({
    menuState,
    onClose,
    table,
}: {
    menuState: FilterMenuState;
    onClose: () => void;
    table: Table<TData>;
}): JSX.Element {
    const sharedContext = table.options.meta?.sharedContext;
    if (menuState.type !== 'ColumnFilter') {
        return <></>;
    }
    const { column } = menuState;

    return (
        <Menu
            anchorEl={menuState.anchorEl}
            open={menuState.type === 'ColumnFilter'}
            onClose={onClose}
            variant="menu"
            keepMounted
        >
            {column.columnDef.filterFn === 'includesString' && (
                <ListItem style={{ backgroundColor: colorSystem.neutral.white }}>
                    <Flexbox gap={8} flexDirection={'column'}>
                        <Text variant={'body-small'} color={colorSystem.neutral[6]} style={{ whiteSpace: 'nowrap' }}>
                            {t`${column.columnDef.meta?.label()} contains`}
                        </Text>
                        <FilterIncludesString column={column} onClose={onClose} />
                    </Flexbox>
                </ListItem>
            )}

            {column.columnDef.filterFn === 'inNumberRange' && (
                <ListItem style={{ backgroundColor: colorSystem.neutral.white }}>
                    <Flexbox gap={8} flexDirection={'column'}>
                        <Text variant={'body-small'} color={colorSystem.neutral[6]} style={{ whiteSpace: 'nowrap' }}>
                            {t`${column.columnDef.meta?.label()} is between`}
                        </Text>
                        <FilterInNumberRange column={column} onClose={onClose} />
                    </Flexbox>
                </ListItem>
            )}

            {column.columnDef.filterFn === 'inMonetaryValueRange' && (
                <ListItem style={{ backgroundColor: colorSystem.neutral.white }}>
                    <Flexbox gap={8} flexDirection={'column'}>
                        <Text variant={'body-small'} color={colorSystem.neutral[6]} style={{ whiteSpace: 'nowrap' }}>
                            {t`${column.columnDef.meta?.label()} is between`}
                        </Text>
                        <FilterInMonetaryValueRange column={column} onClose={onClose} />
                    </Flexbox>
                </ListItem>
            )}

            {column.columnDef.filterFn === 'inDateRange' && (
                <ListItem style={{ backgroundColor: colorSystem.neutral.white }}>
                    <Flexbox gap={8} flexDirection={'column'}>
                        <Text variant={'body-small'} color={colorSystem.neutral[6]} style={{ whiteSpace: 'nowrap' }}>
                            {t`${column.columnDef.meta?.label()} is between`}
                        </Text>
                        <FilterInDateRange column={column} onClose={onClose} />
                    </Flexbox>
                </ListItem>
            )}

            {column.columnDef.filterFn === 'equalsAny' && (
                <ListItem style={{ backgroundColor: colorSystem.neutral.white }}>
                    <Flexbox gap={8} flexDirection={'column'}>
                        <Text variant={'body-small'} color={colorSystem.neutral[6]} style={{ whiteSpace: 'nowrap' }}>
                            {t`${column.columnDef.meta?.label()} is`}
                        </Text>
                        <FilterEnumEqualsAny column={column} sharedContext={sharedContext} />
                    </Flexbox>
                </ListItem>
            )}

            {column.columnDef.filterFn === 'arrIncludesSome' && (
                <ListItem style={{ backgroundColor: colorSystem.neutral.white }}>
                    <Flexbox gap={8} flexDirection={'column'}>
                        <Text variant={'body-small'} color={colorSystem.neutral[6]} style={{ whiteSpace: 'nowrap' }}>
                            {t`${column.columnDef.meta?.label()} is`}
                        </Text>
                        <FilterArrIncludesSome column={column} sharedContext={sharedContext} />
                    </Flexbox>
                </ListItem>
            )}
        </Menu>
    );
}

function DeleteFilterMenuItem<TData>({ table, onClose }: { table: Table<TData>; onClose: () => void }): JSX.Element {
    const hasFiltered = table.getAllLeafColumns().some((col) => col.getIsFiltered());

    const handleDeleteFilter = () => {
        table
            .getAllLeafColumns()
            .filter((col) => col.getCanFilter())
            .forEach((col) => col.setFilterValue(undefined));
        onClose();
    };

    return (
        <MenuItem
            label={t`Clear filter`}
            variant={'destructive'}
            startIcon={<Close />}
            onClick={handleDeleteFilter}
            disabled={!hasFiltered}
        />
    );
}
