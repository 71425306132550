import { t, Trans } from '@lingui/macro';
import { assertUnreachable, isPresent, uniqBy } from '@luminovo/commons';
import { colorSystem, Flexbox, TertiaryButton, Text, Tooltip } from '@luminovo/design-system';
import { isOtsFullPart, SolutionConfigurationDTO } from '@luminovo/http-client';
import {
    HeaderItemLeadTime,
    HeaderItemLinkedLocation,
    HeaderItemManufacturer,
    HeaderItemMpn,
    HeaderItemNotes,
    HeaderItemOfferNumber,
    HeaderItemPackage,
    HeaderItemPanelSpecification,
    HeaderItemPcbFiles,
    HeaderItemSku,
    OfferChips,
    OfferCostBreakdown,
} from '@luminovo/sourcing-core';
import { Info } from '@mui/icons-material';
import { useDownloadPcbOrderFiles } from '../../../../resources/export/exportHandler';
import { useHttpQuery } from '../../../../resources/http/useHttpQuery';
import { useSourcingFull } from '../../../../resources/sourcingScenario/sourcingScenarioHandlers';
import { getPanelDimension } from '../../../Pcb/PanelizationTab/utils/getPanelDimension';
import { useOfferDrawer } from '../OfferDrawer';
import { CustomPartSolutionTableData, SolutionTableData } from '../types';
import { ButtonSolutionSelection } from './ButtonSolutionSelection';
import { ManualCostButton } from './ManualCostButton';
import { ManualSolutionStatusBox } from './ManualSolutionStatusBox';
import { SectionApprovedParts } from './SectionApprovedParts';
import { SolutionConfigurationNote } from './SolutionConfigurationNote';
import { SolutionConfigurationQuantity } from './SolutionConfigurationQuantity';

function OfferInformation({ selected }: { selected: SolutionTableData }) {
    switch (selected.partType) {
        case 'OffTheShelf':
            return (
                <Flexbox flexDirection={'column'} gap={12}>
                    <HeaderItemLinkedLocation linkedLocation={selected.linkedLocation} />
                    {isOtsFullPart(selected.linkedPart) && (
                        <>
                            <HeaderItemMpn otsPart={selected.linkedPart} />
                            <HeaderItemManufacturer otsPart={selected.linkedPart} />
                        </>
                    )}
                    <HeaderItemSku offer={selected.offer} />
                    <HeaderItemPackage offer={selected.offer} />
                    <HeaderItemLeadTime solution={selected.solution} />
                    <HeaderItemNotes offer={selected.offer} />
                </Flexbox>
            );
        case 'InternalPartNumber':
            return (
                <Flexbox flexDirection={'column'} gap={12}>
                    <HeaderItemLinkedLocation linkedLocation={selected.linkedLocation} />
                    {isOtsFullPart(selected.linkedPart) && (
                        <>
                            <HeaderItemMpn otsPart={selected.linkedPart} />
                            <HeaderItemManufacturer otsPart={selected.linkedPart} />
                        </>
                    )}
                    <HeaderItemSku offer={selected.offer} />
                    <HeaderItemPackage offer={selected.offer} />
                    <HeaderItemLeadTime solution={selected.solution} />
                    <HeaderItemNotes offer={selected.offer} />
                </Flexbox>
            );
        case 'CustomPart':
            return <CustomPartOfferInformation selected={selected} />;
        case 'CustomComponent':
            return (
                <Flexbox flexDirection={'column'} gap={12}>
                    {selected.linkedLocation && <HeaderItemLinkedLocation linkedLocation={selected.linkedLocation} />}
                    <HeaderItemLeadTime solution={selected.solution} />
                    <HeaderItemOfferNumber offer={selected.offer} />
                    <HeaderItemNotes offer={selected.offer} />
                </Flexbox>
            );
        default:
            assertUnreachable(selected);
    }
}

const CustomPartOfferInformation = ({ selected }: { selected: CustomPartSolutionTableData }) => {
    const { mutateAsync, isLoading } = useDownloadPcbOrderFiles(
        selected.offer.id,
        selected.solutionConfiguration.sourcing_scenario,
    );

    const { data: pcb } = useHttpQuery(
        'GET /ems/pcb/v2/pcbs/:pcbId',
        {
            pathParams: { pcbId: selected.offer.specification?.data.pcb ?? '' },
        },
        {
            enabled: isPresent(selected.offer.specification?.data.pcb),
        },
    );
    const panel = selected.offer.specification?.data.panel;
    const panelDimensions = pcb && panel ? getPanelDimension(panel, pcb) : undefined;

    return (
        <Flexbox flexDirection={'column'} gap={12}>
            {selected.linkedLocation && <HeaderItemLinkedLocation linkedLocation={selected.linkedLocation} />}
            <HeaderItemLeadTime solution={selected.solution} />
            <HeaderItemOfferNumber offer={selected.offer} />
            <HeaderItemPcbFiles
                offer={selected.offer}
                part={selected.linkedPart}
                onClick={() => mutateAsync()}
                isLoading={isLoading}
            />
            {panel ? (
                <HeaderItemPanelSpecification panel={panel} panelDimensions={panelDimensions} />
            ) : (
                <HeaderItemNotes offer={selected.offer} />
            )}
        </Flexbox>
    );
};

export function SelectedSolutionSidebar({
    selected,
    rfqId,
    solutionConfiguration,
}: {
    selected: SolutionTableData | null;
    rfqId: string;
    solutionConfiguration: SolutionConfigurationDTO;
}) {
    const { openOfferDrawer } = useOfferDrawer();
    const { data: fullSourcingDTO } = useSourcingFull(solutionConfiguration?.sourcing_scenario);

    if (!isPresent(selected)) {
        return (
            <Flexbox
                flexDirection={'column'}
                style={{
                    border: `1px solid ${colorSystem.neutral[2]}`,
                    borderRadius: 8,
                    height: 'fit-content',
                    position: 'sticky',
                    top: 16,
                }}
            >
                <Flexbox style={{ padding: 20 }} flexDirection="column" gap={16}>
                    <Flexbox justifyContent="space-between" alignItems="center">
                        <Text variant="h2">
                            <Trans>Solution manager</Trans>
                        </Text>
                    </Flexbox>

                    <SolutionConfigurationQuantity solution={null} solutionConfiguration={solutionConfiguration} />

                    <SectionApprovedParts
                        rfqId={rfqId}
                        solutionConfigurationId={solutionConfiguration.id}
                        quoteTrackings={uniqBy(
                            fullSourcingDTO?.quotes_by_solution_config[solutionConfiguration.id] ?? [],
                            (x) => x.id,
                        )}
                    />
                </Flexbox>

                <Flexbox padding={'12px 22px'} style={{ borderTop: `1px solid ${colorSystem.neutral[2]}` }}>
                    <SolutionConfigurationNote solutionConfiguration={solutionConfiguration} />
                </Flexbox>
            </Flexbox>
        );
    }

    return (
        <Flexbox
            flexDirection={'column'}
            style={{
                border: `1px solid ${colorSystem.neutral[2]}`,
                borderRadius: 8,
                height: 'fit-content',
                position: 'sticky',
                top: 16,
            }}
        >
            <Flexbox style={{ padding: 20 }} flexDirection="column" gap={16}>
                <Flexbox justifyContent="space-between" alignItems="center">
                    <Text variant="h2">
                        <Trans>Solution manager</Trans>
                    </Text>
                </Flexbox>

                <Flexbox flexDirection="column" gap={4}>
                    <Text color={colorSystem.neutral[6]} variant="h4">
                        <Trans>Solution status</Trans>
                    </Text>
                    <ManualSolutionStatusBox
                        solutionConfiguration={solutionConfiguration}
                        solution={selected.solution}
                    />
                </Flexbox>

                <SolutionConfigurationQuantity
                    solution={selected?.solution ?? null}
                    solutionConfiguration={solutionConfiguration}
                />

                <SectionApprovedParts
                    rfqId={rfqId}
                    solutionConfigurationId={solutionConfiguration.id}
                    quoteTrackings={uniqBy(
                        fullSourcingDTO?.quotes_by_solution_config[solutionConfiguration.id] ?? [],
                        (x) => x.id,
                    )}
                />
            </Flexbox>

            <Flexbox padding={'12px 22px'} style={{ borderTop: `1px solid ${colorSystem.neutral[2]}` }}>
                <SolutionConfigurationNote solutionConfiguration={solutionConfiguration} />
            </Flexbox>

            <Flexbox
                flexDirection={'column'}
                style={{
                    background: colorSystem.neutral[0],
                    borderTop: `1px solid ${colorSystem.neutral[2]}`,
                    padding: 20,
                    paddingBottom: 8,
                    gap: 12,
                }}
            >
                <Flexbox flexWrap={'wrap'} gap={16} alignItems={'center'} justifyContent="space-between">
                    <TertiaryButton
                        onClick={() => {
                            openOfferDrawer({
                                offer: selected.offer,
                                rfqContext: { type: 'WithinRfQ', rfq_id: rfqId },
                                solution: selected.solution,
                                solutionConfigurationId: selected.solutionConfiguration.id,
                            });
                        }}
                        style={{ whiteSpace: 'nowrap', wordBreak: 'keep-all', paddingLeft: 0 }}
                    >
                        <Trans>Selected solution</Trans>
                    </TertiaryButton>
                    <span style={{ flexGrow: 1 }} />
                    <span style={{ textAlign: 'right', position: 'relative' }}>
                        <ButtonSolutionSelection
                            solution={selected?.solution}
                            solutionConfigurationDTO={solutionConfiguration}
                        />
                        <Tooltip
                            title={t`When you choose Automatic, we'll pick the best solution for you based on your preferences. But, if the offers change, the best option may also change. If you want to stick with a certain option, switch to Manual.`}
                        >
                            <Info
                                style={{ color: colorSystem.neutral[6], position: 'absolute', top: -12, right: -12 }}
                            />
                        </Tooltip>
                    </span>
                </Flexbox>

                <OfferChips solution={selected.solution} />
                <OfferInformation selected={selected} />
            </Flexbox>
            <Flexbox
                padding={'12px 22px'}
                style={{
                    borderTop: `1px solid ${colorSystem.neutral[2]}`,
                    borderBottom: `1px solid ${colorSystem.neutral[2]}`,
                }}
            >
                <ManualCostButton solutionConfiguration={solutionConfiguration} solution={selected.solution} />
            </Flexbox>
            <OfferCostBreakdown
                isAlwaysExpanded={false}
                solution={selected.solution}
                showEffectiveTotalPrice={true}
                offer={selected.offer}
            />
        </Flexbox>
    );
}
