import { t } from '@lingui/macro';
import { assertUnreachable, formatDays } from '@luminovo/commons';
import { Availability, AvailabilityType } from '@luminovo/http-client';

export function formatAvailability(availability: null | undefined | Availability): string {
    if (!availability) {
        return t`Unknown`;
    }
    switch (availability.type) {
        case AvailabilityType.LeadTime:
        case AvailabilityType.OnOrder:
        case AvailabilityType.PriceBreakLeadTime:
            return formatDays(availability.days);
        case AvailabilityType.OnOrderWithUnknownLeadTime:
            // Using message id directly as we want different German translations
            // of "On order" in different contexts.
            return t({ id: 'on.order.indicator', message: 'On order', comment: 'On order with unknown lead time' });
        case AvailabilityType.Stock:
            return t`In stock`;
        default:
            /* c8 ignore next */
            assertUnreachable(availability);
    }
}
