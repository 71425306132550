import { plural, t } from '@lingui/macro';
import { isPresent } from '@luminovo/commons';
import {
    CandidateCpn,
    ConflictingTechnicalParameters,
    PartOptionExtractionReason,
    PartOptionExtractionReasonEnum,
    PartOptionExtractionReasonWithoutContextEnum,
    PartSuggestionReason,
    PartSuggestionReasonEnum,
    formatCandidateCpn,
    isCandidateCpn,
    isConflictingTechnicalParameters,
} from '@luminovo/http-client';

export function getFormattedReasons(
    reasons: PartOptionExtractionReason[] | PartSuggestionReason[],
    candidate: String | CandidateCpn | undefined | null,
) {
    return reasons
        .map((reason) => getReasonString(reason, candidate))
        .filter(isPresent)
        .map((reason) => `\n• ${reason}`);
}

function getReasonString(
    reason: PartSuggestionReason | PartOptionExtractionReason,
    candidate: String | CandidateCpn | undefined | null,
) {
    const context = 'context' in reason ? (reason.context ? reason.context : '') : '';
    const conflicts = isConflictingTechnicalParameters(context) ? formatCoflictingTechnicalParameters(context) : '';
    switch (reason.name) {
        case PartOptionExtractionReasonEnum.MatchingTechSpecsWithConflicts:
            return t`Matching technical parameters, but some have conflicts: ${conflicts}`;
        case PartOptionExtractionReasonEnum.MatchingManufacturerAlternativeName:
            return t`Manufacturer matching with alternative name ${context}`;
        case PartOptionExtractionReasonEnum.MatchingManufacturer:
            return t`Manufacturer ${context} matches`;
        case PartOptionExtractionReasonEnum.QuoteImport:
            return t`Part added based on a supplier's suggestion`;
        case PartOptionExtractionReasonWithoutContextEnum.MatchingMpn:
            return t`MPN ${candidate} matches`;
        case PartOptionExtractionReasonWithoutContextEnum.NoManufacturerGiven:
            return t`No manufacturer found in the BOM`;
        case PartOptionExtractionReasonWithoutContextEnum.MatchingIpn:
            return t`IPN ${candidate} matches`;
        case PartOptionExtractionReasonWithoutContextEnum.MatchingCpn:
            const cpn = isCandidateCpn(candidate) ? formatCandidateCpn(candidate) : '';
            return t`Customer part number ${cpn} matches`;
        case PartOptionExtractionReasonWithoutContextEnum.MatchingSpn:
            return t`Supplier part number ${candidate} matches`;
        case PartOptionExtractionReasonWithoutContextEnum.MatchingMpnLinkedToIpn:
            return t`MPN ${candidate} matches and is linked to IPN part option`;
        case PartOptionExtractionReasonWithoutContextEnum.MatchedPartsReplacedByIpn:
            return t`Matched parts replaced by IPN with the same linked parts`;
        case PartOptionExtractionReasonWithoutContextEnum.ManyMatchingIpn:
            return t`There is more than one IPN result for IPN ${candidate}`;
        case PartOptionExtractionReasonWithoutContextEnum.ManyMatchingSpn:
            return t`There is more than one SPN result for SPN ${candidate}`;
        case PartOptionExtractionReasonWithoutContextEnum.IpnRemoved:
            return t`IPN ${candidate} was removed and is therefore set to rejected`;
        case PartOptionExtractionReasonWithoutContextEnum.MatchingCpnButRevisionUnclear:
            return t`Multiple CPNs with different revisions matching but no revision is specified`;
        case PartOptionExtractionReasonWithoutContextEnum.MatchingIpnButDifferentCpn:
            return t`IPN ${candidate} matches but there is a CPN that points to a different IPN`;
        case PartOptionExtractionReasonWithoutContextEnum.MatchingCpnButDifferentIpn:
            const candidateCpnFormatted = isCandidateCpn(candidate) ? formatCandidateCpn(candidate) : '';
            return t`CPN ${candidateCpnFormatted} matches but there is another IPN that is different to the one of the CPN`;
        case PartOptionExtractionReasonWithoutContextEnum.MatchingSpnButDifferentCpn:
            return t`SPN ${candidate} matches but there is a CPN that points to a different IPN`;
        case PartOptionExtractionReasonWithoutContextEnum.MatchingSpnButDifferentIpn:
            return t`SPN ${candidate} matches but there is another IPN that is different to the one of the SPN`;
        case PartOptionExtractionReasonWithoutContextEnum.ExplicitlyManufacturerFree:
            return t`Explicit manufacturer-free indication found`;
        case PartOptionExtractionReasonWithoutContextEnum.MatchingTechSpecs:
            return t`Technical specifications are matching`;
        case PartOptionExtractionReasonWithoutContextEnum.ApprovalStatusChangedInPreviousImport:
            return t`Approval status was adjusted to status of previous import`;
        case PartSuggestionReasonEnum.PartialMpn:
            const mpn = candidate ? candidate : '';
            return t`MPN ${mpn} in the BOM is only partially matched`;
        case PartSuggestionReasonEnum.ManufacturerMismatch:
            return t`Manufacturer ${context} in the BOM does not match that of the part`;
        case PartSuggestionReasonEnum.CandidateMpnMatchedButNoManufacturerGiven:
            return t`MPN in the BOM matches ${candidate}, but there is additional data in the cell given that could be relevant for the MPN matching, therefore preventing auto-approval`;
        case PartSuggestionReasonEnum.GenericPartOnlySuggestedBecauseNonGenericPartOptionExists:
            return t`Another, non-generic, part option was extracted and the BOM item therefore not considered manufacturer-free`;
        case PartSuggestionReasonEnum.GenericPartOnlySuggestedBecauseManufacturerGiven:
            return t`Manufacturer ${context} found, the BOM item is therefore not manufacturer-free`;
        case PartSuggestionReasonEnum.GenericPartOnlySuggestedBecauseIpnGiven:
            return t`IPN ${context} found, the BOM item is therefore not considered manufacturer-free`;
        case PartSuggestionReasonEnum.GenericPartOnlySuggestedBecauseCpnGiven:
            const candidateCpn = isCandidateCpn(candidate) ? formatCandidateCpn(candidate) : '';
            return t`CPN ${candidateCpn} found, the BOM item is therefore not considered manufacturer-free`;
        case PartSuggestionReasonEnum.GenericPartOnlySuggestedBecauseMpnGiven:
            return t`MPN ${context} found, the BOM item is therefore not considered manufacturer-free`;
        case PartSuggestionReasonEnum.GenericPartMissingRequiredParameter:
            return t`Generic part is missing the required parameter ${context}`;
        case PartSuggestionReasonEnum.GenericPartOnlySuggestedBecauseExplicitlyNotManufacturerFree:
            return t`MPN or manufacturer found but no explicit manufacturer-free indication found`;
        case PartSuggestionReasonEnum.Deprecated_PartOnlySuggestedBecauseExplicitlyManufacturerFreeAndGenericPart:
            return t`Explicit manufacturer-free indication found`;
        case PartSuggestionReasonEnum.IpnSuggestedBasedOnMpnCandidate:
            return t`IPN found based on MPN candidate ${candidate} from the BOM`;
        case PartSuggestionReasonEnum.IpnSuggestedBasedOnLinkedOrSuggestedParts:
            return t`IPN has the same linked or suggested generic or Off-the-shelf part as part option`;
        case PartSuggestionReasonEnum.GenericPartTechnicalParametersMatchThisIpn:
            return t`IPN has the same technical parameters as the generic part`;
        case PartSuggestionReasonEnum.PreviousImportCustomPartSpecification:
            return t`Automatically extracted parts are only suggested because the previous import of the same data uses a custom part specification`;
        case PartSuggestionReasonEnum.PreviousImportRemovedPartOption:
            return t`Part was removed as part option in previous import`;
        case PartSuggestionReasonEnum.PreviousImportManuallyAddedPartOption:
            return t`Part was manually added in previous import with approval status ${context}`;
        case PartSuggestionReasonEnum.PreviousImportAutomaticallyAddedPartOption:
            return t`Part was automatically added in previous import with approval status ${context}`;
        case PartSuggestionReasonEnum.PreviousImportPreviouslyAddedPartOption:
            return t`Part was added in previous import with approval status ${context}`;
        case PartOptionExtractionReasonWithoutContextEnum.AssemblyImport:
            return t`Part was automatically added during the assembly import`;
        case PartSuggestionReasonEnum.ConflictingPartSpecificationTypes:
            return t`The part is only suggested because it is an Off-the-shelf part but the referenced IPNs ${reason.context.join(
                ', ',
            )} are custom components.`;
        default:
            return undefined;
    }
}

function formatCoflictingTechnicalParameters(conflicts: ConflictingTechnicalParameters): string {
    const entries: string[] = [];
    const {
        capacitances,
        resistances,
        tolerances,
        power_ratings: powerRatings,
        voltage_ratings: voltageRatings,
        packages,
        dielectrics,
        temperature_coefficients: temperatureCoefficients,
    } = conflicts;
    if (capacitances.length > 0) {
        entries.push(
            // eslint-disable-next-line spellcheck/spell-checker
            t`${plural(capacitances.length, { one: 'capacitance', other: 'capacitances' })}: ${capacitances.join(
                ', ',
            )}`,
        );
    }
    if (resistances.length > 0) {
        entries.push(
            t`${plural(resistances.length, { one: 'resistance', other: 'resistances' })}: ${resistances.join(', ')}`,
        );
    }
    if (tolerances.length > 0) {
        entries.push(
            t`${plural(tolerances.length, { one: 'tolerance', other: 'tolerances' })}: ${tolerances.join(', ')}`,
        );
    }
    if (powerRatings.length > 0) {
        entries.push(
            t`${plural(powerRatings.length, { one: 'power rating', other: 'power ratings' })}: ${powerRatings.join(
                ', ',
            )}`,
        );
    }
    if (voltageRatings.length > 0) {
        entries.push(
            t`${plural(voltageRatings.length, {
                one: 'voltage rating',
                other: 'voltage ratings',
            })}: ${voltageRatings.join(', ')}`,
        );
    }
    if (temperatureCoefficients.length > 0) {
        entries.push(
            t`${plural(temperatureCoefficients.length, {
                one: 'temperature coefficient',
                other: 'temperature coefficients',
            })}: ${temperatureCoefficients.join(', ')}`,
        );
    }
    if (packages.length > 0) {
        entries.push(t`${plural(packages.length, { one: 'package', other: 'packages' })}: ${packages.join(', ')}`);
    }
    if (dielectrics.length > 0) {
        entries.push(
            t`${plural(dielectrics.length, { one: 'dielectric', other: 'dielectrics' })}: ${dielectrics.join(', ')}`,
        );
    }
    return entries.join(', ');
}
