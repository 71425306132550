import { t } from '@lingui/macro';
import {
    compareByDate,
    compareByStringKey,
    formatDecimal,
    formatMonetaryValue,
    formatToLongDate,
    isPresent,
    transEnum,
    uniq,
} from '@luminovo/commons';
import {
    Flexbox,
    TanStackTable,
    TanStackTableProps,
    Text,
    chainComparators,
    createColumnHelper,
    useTanStackTable,
} from '@luminovo/design-system';
import {
    AssemblyResponseDTO,
    FullSourcingDTO,
    SolutionStatus,
    SourcingPreferenceOptions,
    SourcingScenarioDTO,
} from '@luminovo/http-client';
import {
    SolutionStatusCircleIcon,
    formatAvailability,
    formatSolutionStatus,
    leadTimeDaysExtractor,
    sourcingPreferenceTranslations,
} from '@luminovo/sourcing-core';
import { Skeleton } from '@mui/material';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { LabelScenarioOrderSize } from '../../../../components/LabelScenarioOrderSize';
import { useAssemblies } from '../../../../resources/assembly/assemblyHandler';
import { calculateTotalOrderSize } from '../../../../resources/sourcingScenario/calculateTotalOrderSize';
import {
    useSourcingFullBulk,
    useSourcingScenarios,
} from '../../../../resources/sourcingScenario/sourcingScenarioHandlers';
import { assertPresent } from '../../../../utils/assertPresent';
import { route } from '../../../../utils/routes';
import { StatusSummaries } from '../SourcingScenarios/StatusSummaries';
import {
    SourcingScenarioTableSharedContext,
    SourcingScenarioType,
    SourcingScenariosTableWithSubRowsType,
} from './utils/types';

const getAccessorKey = (row: SourcingScenariosTableWithSubRowsType, cb: (scenario: SourcingScenarioType) => any) => {
    // When the row type is 'assembly', return the assembly designator
    if (row.type === 'assembly') {
        return row.assembly.designator;
    } else {
        return cb(row);
    }
};

const columnHelper = createColumnHelper<SourcingScenariosTableWithSubRowsType>();

const columns = [
    columnHelper.text((row) => getAccessorKey(row, (row) => row.sourcingData.sourcingScenario.name), {
        id: 'name',
        size: 160,
        label: () => t`Name`,
        cell: ({ row }) => {
            if (row.original.type === 'assembly') {
                return row.original.assembly.designator;
            } else return row.original.sourcingData.sourcingScenario.name;
        },
    }),

    columnHelper.number(
        (row) => getAccessorKey(row, (row) => calculateTotalOrderSize(row.sourcingData.sourcingScenario)),
        {
            id: 'orderSize',
            size: 100,
            label: () => t`Order size`,
            cell: ({ row }) =>
                row.original.type === 'assembly' ? (
                    <></>
                ) : (
                    <LabelScenarioOrderSize sourcingScenario={row.original.sourcingData.sourcingScenario} />
                ),
        },
    ),

    columnHelper.enum(
        (row) =>
            getAccessorKey(
                row,
                (row) => row.sourcingData.sourcingScenario.solution_preference.lead_time_preference.type,
            ),
        {
            id: 'LeadTimePreference',
            size: 120,
            label: () => t`Lead time preference`,
            options: [
                SourcingPreferenceOptions.Fastest,
                SourcingPreferenceOptions.BestPrice,
                SourcingPreferenceOptions.BestPriceBy,
            ],
            getOptionLabel: (opt) => transEnum(opt, sourcingPreferenceTranslations),
            cell: ({ row }) => {
                if (row.original.type === 'assembly') {
                    return <></>;
                } else {
                    const { type, target } =
                        row.original.sourcingData.sourcingScenario.solution_preference.lead_time_preference;
                    const bestDateBy =
                        type === SourcingPreferenceOptions.BestPriceBy && target ? formatToLongDate(target) : '';

                    return (
                        <Text variant={'inherit'} showEllipsis={true} style={{ display: 'block' }}>
                            {`${transEnum(type, sourcingPreferenceTranslations)} ${bestDateBy}`.trim()}
                        </Text>
                    );
                }
            },
            sortingFn: chainComparators(
                compareByStringKey((row) =>
                    row.original.type === 'assembly'
                        ? ''
                        : transEnum(
                              row.original.sourcingData.sourcingScenario.solution_preference.lead_time_preference.type,
                              sourcingPreferenceTranslations,
                          ),
                ),
                compareByDate((row) =>
                    row.original.type === 'assembly'
                        ? ''
                        : row.original.sourcingData.sourcingScenario.solution_preference.lead_time_preference.target ??
                          '',
                ),
            ),
        },
    ),

    columnHelper.monetaryValue((row) => getAccessorKey(row, (row) => row.sourcingData.sourcingFull?.total_unit_price), {
        id: 'unitPrice',
        size: 100,
        label: () => t`Unit price`,
        cell: ({ row, getValue }) => {
            if (row.original.type === 'assembly') {
                return <></>;
            } else {
                return !isPresent(row.original.sourcingData.sourcingFull) ? (
                    <Skeleton />
                ) : (
                    formatMonetaryValue(getValue())
                );
            }
        },
    }),

    columnHelper.monetaryValue(
        (row) => getAccessorKey(row, (row) => row.sourcingData.sourcingFull?.total_one_time_costs),
        {
            id: 'OneTimeCosts',
            size: 100,
            label: () => t`One-time costs`,
            cell: ({ row, getValue }) => {
                if (row.original.type === 'assembly') {
                    return <></>;
                } else {
                    return !isPresent(row.original.sourcingData.sourcingFull) ? (
                        <Skeleton />
                    ) : (
                        formatMonetaryValue(getValue())
                    );
                }
            },
        },
    ),

    columnHelper.monetaryValue((row) => getAccessorKey(row, (row) => row.sourcingData.sourcingFull?.total_price), {
        id: 'totalPrice',
        size: 100,
        label: () => t`Total price`,
        cell: ({ row, getValue }) => {
            if (row.original.type === 'assembly') {
                return <></>;
            } else {
                return !isPresent(row.original.sourcingData.sourcingFull) ? (
                    <Skeleton />
                ) : (
                    formatMonetaryValue(getValue())
                );
            }
        },
    }),

    columnHelper.monetaryValue(
        (row) => getAccessorKey(row, (row) => row.sourcingData.sourcingFull?.total_excess_material),
        {
            id: 'excessMaterial',
            size: 100,
            label: () => t`Excess material`,
            cell: ({ row, getValue }) => {
                if (row.original.type === 'assembly') {
                    return <></>;
                } else {
                    return !isPresent(row.original.sourcingData.sourcingFull) ? (
                        <Skeleton />
                    ) : (
                        formatMonetaryValue(getValue())
                    );
                }
            },
        },
    ),

    columnHelper.number(
        (row) =>
            getAccessorKey(row, (row) =>
                leadTimeDaysExtractor(row.sourcingData.sourcingFull?.total_availability ?? null),
            ),
        {
            id: 'leadTime',
            label: () => t`Lead time`,
            size: 90,
            cell: ({ row }) => {
                if (row.original.type === 'assembly') {
                    return <></>;
                } else {
                    return !isPresent(row.original.sourcingData.sourcingFull) ? (
                        <Skeleton />
                    ) : (
                        formatAvailability(row.original.sourcingData.sourcingFull.total_availability)
                    );
                }
            },
        },
    ),

    columnHelper.array(
        (row) =>
            getAccessorKey(row, (row) => {
                return uniq([
                    ...Array(row.sourcingData.sourcingFull?.status_count.number_of_ok).fill(SolutionStatus.Good),
                    ...Array(row.sourcingData.sourcingFull?.status_count.number_of_warning).fill(
                        SolutionStatus.Warning,
                    ),
                    ...Array(row.sourcingData.sourcingFull?.status_count.number_of_error).fill(SolutionStatus.Error),
                ]);
            }),
        {
            id: 'solutionStatus',
            size: 180,
            align: 'center',
            enableSorting: false,
            options: Object.values(SolutionStatus),
            getOptionLabel: (status) => formatSolutionStatus(status),
            label: () => t`Solution status`,
            cell: ({ row }) => {
                if (row.original.type === 'assembly') {
                    const sourcingFull = row.original.scenarios
                        .map((s) => (s.type === 'assembly' ? undefined : s.sourcingData.sourcingFull))
                        .filter(isPresent);
                    return <AssemblyStatusSummaries sourcingFull={sourcingFull} />;
                } else {
                    return !isPresent(row.original.sourcingData.sourcingFull) ? (
                        <Skeleton />
                    ) : (
                        <StatusSummaries sourcingFull={row.original.sourcingData.sourcingFull} />
                    );
                }
            },
        },
    ),

    columnHelper.monetaryValue(
        (row) => getAccessorKey(row, (row) => row.sourcingData.sourcingFull?.total_scrap_costs),
        {
            id: 'totalScrapCosts',
            size: 100,
            label: () => t`Scrap costs`,
            initialVisibility: false,
            cell: ({ row }) => {
                if (row.original.type === 'assembly') {
                    return <></>;
                } else {
                    return !isPresent(row.original.sourcingData.sourcingFull) ? (
                        <Skeleton />
                    ) : (
                        formatMonetaryValue(row.original.sourcingData.sourcingFull.total_scrap_costs)
                    );
                }
            },
        },
    ),
];

const AssemblyStatusSummaries: React.FunctionComponent<{
    sourcingFull: FullSourcingDTO[];
}> = ({ sourcingFull }) => {
    const { numberOfSuccess, numberOfWarnings, numberOfErrors } = sourcingFull.reduce(
        (acc, full) => {
            return {
                numberOfSuccess: acc.numberOfSuccess + full.status_count.number_of_ok,
                numberOfWarnings: acc.numberOfWarnings + full.status_count.number_of_warning,
                numberOfErrors: acc.numberOfErrors + full.status_count.number_of_error,
            };
        },
        { numberOfSuccess: 0, numberOfWarnings: 0, numberOfErrors: 0 },
    );

    const statusCounts: Array<{ status: SolutionStatus; count: number }> = [
        { status: SolutionStatus.Good, count: numberOfSuccess },
        { status: SolutionStatus.Warning, count: numberOfWarnings },
        { status: SolutionStatus.Error, count: numberOfErrors },
    ];

    return (
        <Flexbox gap={16} justifyContent={'center'}>
            {statusCounts.map(({ status, count }) => {
                return (
                    <Flexbox key={status} alignItems="center" gap={4}>
                        <SolutionStatusCircleIcon status={status} />
                        <Text variant={'body-semibold'}>{formatDecimal(count)}</Text>
                    </Flexbox>
                );
            })}
        </Flexbox>
    );
};

export type AssemblyWithSourcingScenarios = AssemblyResponseDTO & {
    sourcingScenarios: SourcingScenarioDTO[];
};

export const useGroupScenariosByAssembly = (
    sourcingScenarioIds: string[],
): {
    isLoading: boolean;
    data: AssemblyWithSourcingScenarios[] | undefined;
} => {
    const group = new Map<string, SourcingScenarioDTO[]>();
    const { data: sourcingScenariosDTOs, isLoading: isLoadingSourcingScenario } =
        useSourcingScenarios(sourcingScenarioIds);
    (sourcingScenariosDTOs ?? []).forEach((sourcingScenario) => {
        const assemblyIds = sourcingScenario?.assembly_quantities.items.map((item) => item.assembly);
        if (assemblyIds) {
            assemblyIds.forEach((assemblyId) => {
                if (group.has(assemblyId) === false) {
                    group.set(assemblyId, []);
                }
                group.get(assemblyId)?.push(sourcingScenario);
            });
        }
    });

    const assemblyIds = Array.from(group.keys());
    const { data: assemblyDetails, isLoading: isLoadingAssembly } = useAssemblies(assemblyIds);

    if (isLoadingAssembly || isLoadingSourcingScenario || !isPresent(assemblyDetails))
        return {
            isLoading: true,
            data: undefined,
        };

    const result = Array.from(group, ([assemblyId, scenarios]) => ({
        ...assertPresent(assemblyDetails.find((a) => a.id === assemblyId)),
        sourcingScenarios: scenarios,
    }));

    return {
        isLoading: false,
        data: result,
    };
};

export const useSourcingScenariosGroupedByAssembly = (
    sourcingScenarioIds: string[],
): SourcingScenariosTableWithSubRowsType[] | undefined => {
    const { data: fullSourcingDTOs } = useSourcingFullBulk(sourcingScenarioIds);
    const { data: sourcingScenarioGroupedData, isLoading } = useGroupScenariosByAssembly(sourcingScenarioIds);

    if (isLoading || !isPresent(sourcingScenarioGroupedData)) return undefined;

    const result: SourcingScenariosTableWithSubRowsType[] = sourcingScenarioGroupedData.map(
        ({ sourcingScenarios, ...assembly }) => ({
            type: 'assembly',
            assembly,
            scenarios: sourcingScenarios.map((s) => ({
                type: 'sourcingScenario',
                sourcingData: {
                    sourcingScenario: s,
                    sourcingFull: fullSourcingDTOs?.find((full) => full.sourcing_scenario_id === s.id),
                },
                scenarios: undefined,
            })),
        }),
    );

    return result;
};

export const AssemblyCentricSourcingScenarioTable: React.FunctionComponent<{
    sourcingScenarioIds: string[];
    columnsKey: string;
    rfqId: string;
    ActionButton?: TanStackTableProps<
        SourcingScenariosTableWithSubRowsType,
        SourcingScenarioTableSharedContext
    >['ActionButton'];
}> = ({ sourcingScenarioIds, columnsKey, rfqId, ActionButton }) => {
    const groupedData = useSourcingScenariosGroupedByAssembly(sourcingScenarioIds);

    const history = useHistory();

    const { table } = useTanStackTable({
        columns,
        data: groupedData,
        columnsKey,
        enableColumnHiding: true,
        enableColumnOrdering: true,
        getSubRows: (row) => row.scenarios,
        sharedContext: {
            rfqId: rfqId,
        },
        onRowClick: (row) => {
            if (row.original.type === 'sourcingScenario') {
                history.push(
                    route(`/rfqs/:rfqId/sourcing/scenarios/:sourcingScenarioId`, {
                        rfqId: row.original.sourcingData.sourcingScenario.rfq,
                        sourcingScenarioId: row.original.sourcingData.sourcingScenario.id,
                    }),
                );
            } else {
                history.push(
                    route('/rfqs/:rfqId/sourcing/assembly/:assemblyId', {
                        rfqId,
                        assemblyId: row.original.assembly.id,
                    }),
                );
            }
        },
    });

    return <TanStackTable table={table} size="medium" ActionButton={ActionButton} />;
};
