import * as r from 'runtypes';
import { IpnWithMatchesFullPartRuntype } from '../internalPartNumber';
import {
    GenericFullPartRuntype,
    OtsFullPartRuntype,
    PartSuggestionOriginRuntype,
    TechnicalParametersRuntype,
} from '../part';

export type PartSuggestionFull = r.Static<typeof PartSuggestionFullRuntype>;
export const PartSuggestionFullRuntype = r.Union(
    r.Record({
        type: r.Literal('Generic'),
        part: GenericFullPartRuntype,
        origin: PartSuggestionOriginRuntype.nullable().optional(),
    }),
    r.Record({
        type: r.Literal('OffTheShelf'),
        part: OtsFullPartRuntype,
        origin: PartSuggestionOriginRuntype.nullable().optional(),
    }),
    r.Record({
        type: r.Literal('Component'),
        part: IpnWithMatchesFullPartRuntype,
        origin: PartSuggestionOriginRuntype.nullable().optional(),
    }),
    r.Record({
        type: r.Literal('IncompleteGeneric'),
        part: TechnicalParametersRuntype,
        origin: PartSuggestionOriginRuntype.nullable().optional(),
    }),
);
