import { Trans } from '@lingui/macro';
import { usePermissions } from '@luminovo/auth';
import { id, isPresent } from '@luminovo/commons';
import {
    ButtonGroup,
    ButtonGroupItem,
    Flexbox,
    SecondaryButton,
    TableState,
    useDataTableState,
} from '@luminovo/design-system';
import {
    AssemblyIndustry,
    GenericFullPart,
    IpnWithMatchesFullPart,
    OtsFullPart,
    PartAlternative,
    PartAlternativeSimilarityEnum,
    PartSuggestionFull,
    isOtsComponentFull,
    isOtsFullPart,
} from '@luminovo/http-client';
import { partAlternativesIdExtractor as idExtractor } from '@luminovo/sourcing-core';
import React, { useMemo, useState } from 'react';
import { useFormState } from 'react-hook-form';
import { useHistory } from 'react-router';
import { BomItemComparePartsDialog } from '../../../../components/CompareItemsDialog/BomItemComparisonDialog/BomItemComparePartsDialog';
import { ComparePartsMessage } from '../../../../components/CompareItemsDialog/BomItemComparisonDialog/ComparePartsMessage';
import { BomItem } from '../../../../resources/designItem/bomItemFrontendTypes';
import { SectionOfScreen } from '../../../../resources/part/partFrontendTypes';
import { useIpnLinkingDialog } from '../LinkPartsDialog/useIpnLinkingDialog';
import { PartAlternativesTable } from './PartAlternatives/PartAlternativesTable';
import { getPartAlternativesColumns } from './PartAlternatives/columns';
import { PartAlternativeContext } from './PartAlternatives/partAlternativesTypes';
import { PartialMatchesTable } from './PartialMatches/PartialMatchesTable';
import { useHasDesignatorQuantityMismatch } from './hasDesignatorOrQtyMismatch';

type SuggestionType = 'partialMatches' | 'partAlternatives';

type PartSuggestionProps = {
    partialMatches: PartSuggestionFull[];
    isSuggestionsLoading: boolean;
    partAlternatives: PartAlternative[];
    handleAddPartOption: (
        newPart: GenericFullPart | OtsFullPart | IpnWithMatchesFullPart,
        sectionOfScreen: SectionOfScreen,
        alternativeSimilarity?: PartAlternativeSimilarityEnum,
    ) => void;
    rfqId: string;
    assemblyId: string;
    bomItem: BomItem;
    assemblyIndustry?: AssemblyIndustry;
};

export const getApprovedIpnOrOtsParts = (bomItem: BomItem): (OtsFullPart | IpnWithMatchesFullPart)[] => {
    return bomItem.approvedPartOptions.filter((part): part is OtsFullPart | IpnWithMatchesFullPart => {
        if (isOtsFullPart(part)) {
            return true;
        }
        if (isOtsComponentFull(part)) {
            return part.matches.some((match) => isOtsFullPart(match.part.data));
        }
        return false;
    });
};

const selectionOptions = {
    idExtractor,
};

export const PartSuggestions = React.memo(function PartSuggestions({
    partialMatches,
    isSuggestionsLoading,
    partAlternatives,
    bomItem,
    handleAddPartOption,
    rfqId,
    assemblyId,
    assemblyIndustry,
}: PartSuggestionProps) {
    const history = useHistory();
    const { permissions } = usePermissions();
    const { openDialog: openIpnLinkingDialog } = useIpnLinkingDialog();
    const [suggestionsToShow, setSuggestionsToShow] = useState<SuggestionType>(
        partialMatches.length === 0 && partAlternatives.length !== 0 && !isSuggestionsLoading
            ? 'partAlternatives'
            : 'partialMatches',
    );
    const [isPartSelectionActive, setPartSelectionActive] = useState(false);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const { isSubmitting } = useFormState();
    const hasDesignatorQuantityMismatch = useHasDesignatorQuantityMismatch();

    const approvedIpnOrOtsParts = getApprovedIpnOrOtsParts(bomItem);

    const isCompareEnabled = approvedIpnOrOtsParts.length > 0 && partAlternatives.length > 0;

    const partAlternativeContext: PartAlternativeContext = useMemo(
        () => ({
            rfqContext: { type: 'WithinRfQ', rfq_id: rfqId },
            bomItem,
            rfqId,
            assemblyId,
            handleAddPartOption,
            permissions,
            openIpnLinkingDialog,
            hasDesignatorQuantityMismatch,
            isSubmitting,
            assemblyIndustry,
        }),
        [
            rfqId,
            bomItem,
            assemblyId,
            handleAddPartOption,
            permissions,
            openIpnLinkingDialog,
            hasDesignatorQuantityMismatch,
            isSubmitting,
            assemblyIndustry,
        ],
    );

    const columns = useMemo(() => getPartAlternativesColumns({ isPartSelectionActive }), [isPartSelectionActive]);
    const partAlternativesTableState: TableState<PartAlternative, PartAlternativeContext> = useDataTableState({
        columns,
        items: partAlternatives,
        paginationOptions: { showPagination: false },
        persistenceId: `part-alternatives.${history.location.pathname}`,
        sharedContext: partAlternativeContext,
        selectionOptions,
    });
    const selectedPartAlternatives = partAlternativesTableState.state.selectedIds
        .map((id) => partAlternatives.find((item) => idExtractor(item) === id))
        .filter(isPresent);

    return (
        <>
            {bomItem.showPartAlternatives && (
                <>
                    {isPartSelectionActive ? (
                        <ComparePartsMessage
                            isCompareEnabled={isCompareEnabled}
                            countOfSelectedParts={selectedPartAlternatives.length}
                            setPartSelectionActive={setPartSelectionActive}
                            clearSelection={() => partAlternativesTableState.dispatch({ type: 'clear-selected-items' })}
                            setIsDialogOpen={setIsDialogOpen}
                            bomItem={bomItem}
                        />
                    ) : (
                        <Flexbox justifyContent="space-between">
                            <ButtonGroup size="large">
                                <ButtonGroupItem
                                    selected={suggestionsToShow === 'partialMatches'}
                                    onClick={() => setSuggestionsToShow('partialMatches')}
                                    count={partialMatches.length}
                                    id={id('design/button_partial_matches')}
                                >
                                    <Trans>Partial matches</Trans>
                                </ButtonGroupItem>
                                <ButtonGroupItem
                                    selected={suggestionsToShow === 'partAlternatives'}
                                    onClick={() => setSuggestionsToShow('partAlternatives')}
                                    count={partAlternatives.length}
                                    id={id('design/button_alternative_parts')}
                                >
                                    <Trans>Part alternatives</Trans>
                                </ButtonGroupItem>
                            </ButtonGroup>
                            {suggestionsToShow === 'partAlternatives' && (
                                <SecondaryButton
                                    size="medium"
                                    onClick={() => {
                                        if (partAlternatives.length === 1) {
                                            return setIsDialogOpen(true);
                                        }
                                        return setPartSelectionActive(true);
                                    }}
                                    disabled={!isCompareEnabled}
                                    id={id('design/button_compare_parts')}
                                >
                                    <Trans>Compare</Trans>
                                </SecondaryButton>
                            )}
                        </Flexbox>
                    )}
                </>
            )}
            {suggestionsToShow === 'partialMatches' && (
                <PartialMatchesTable
                    assemblyId={assemblyId}
                    partialMatches={partialMatches}
                    handleAddPartOption={handleAddPartOption}
                    rfqId={rfqId}
                    bomItem={bomItem}
                    assemblyIndustry={assemblyIndustry}
                    hasDesignatorQuantityMismatch={hasDesignatorQuantityMismatch}
                    isSubmitting={isSubmitting}
                />
            )}
            {suggestionsToShow === 'partAlternatives' && bomItem.showPartAlternatives && (
                <PartAlternativesTable tableState={partAlternativesTableState} partAlternatives={partAlternatives} />
            )}
            <BomItemComparePartsDialog
                assemblyId={assemblyId}
                isOpen={isDialogOpen}
                onClose={() => setIsDialogOpen(false)}
                partOptions={approvedIpnOrOtsParts}
                partAlternatives={partAlternatives.length === 1 ? partAlternatives : selectedPartAlternatives}
                rfqId={rfqId}
                bomItem={bomItem}
                handleAddPartOption={handleAddPartOption}
                partAlternativesTableState={partAlternativesTableState}
                assemblyIndustry={assemblyIndustry}
            />
        </>
    );
});
