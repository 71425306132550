import { t } from '@lingui/macro';
import { colorSystem, Flexbox } from '@luminovo/design-system';
import { PCBV2 } from '@luminovo/http-client';
import { LinearProgress } from '@mui/material';
import { useSnackbar } from 'notistack';
import React from 'react';
import { isMainLifecycleError } from '../../../../resources/pcb/pcbFunctions';
import { usePcbAnalysisProgress } from '../../utils/usePcbAnalysisProgress';
import { PcbAnalysisNotification } from './components/PcbAnalysisNotification';
import { getPcbAnalysisId } from './utils/getPcbAnalysisId';
import { useShowPcbAnalysisNotification } from './utils/useShowPcbAnalysisNotification';

export function PcbAnalysisProgressBar({ pcb, assemblyId, rfqId }: { pcb: PCBV2; assemblyId: string; rfqId: string }) {
    const { percentageComplete, isPcbAnalysisDone } = usePcbAnalysisProgress(pcb);
    const isPcbAnalysisError = isMainLifecycleError(pcb);

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const { showNotification, setShowNotification } = useShowPcbAnalysisNotification(pcb);

    const pcbAnalysisId = getPcbAnalysisId(pcb.id);

    React.useEffect(() => {
        if (isPcbAnalysisError) {
            enqueueSnackbar(t`An error occurred during analysis, please contact support`, {
                variant: 'error',
                key: pcbAnalysisId,
                preventDuplicate: true,
            });
        } else if (showNotification) {
            enqueueSnackbar(
                <PcbAnalysisNotification
                    rfqId={rfqId}
                    assemblyId={assemblyId}
                    onClose={() => {
                        setShowNotification('false');
                        closeSnackbar(pcbAnalysisId);
                    }}
                />,
                {
                    persist: showNotification,
                    preventDuplicate: true,
                    key: pcbAnalysisId,
                    style: {
                        background: 'transparent',
                        width: '500px',
                        padding: 0,
                    },
                },
            );
        } else {
            closeSnackbar(pcbAnalysisId);
        }

        return () => {};
    }, [
        assemblyId,
        closeSnackbar,
        enqueueSnackbar,
        pcbAnalysisId,
        rfqId,
        setShowNotification,
        showNotification,
        isPcbAnalysisError,
    ]);

    if (isPcbAnalysisDone) {
        return null;
    }

    return (
        <Flexbox
            position={'sticky'}
            top={0}
            flexDirection={'column'}
            gap={8}
            style={{ background: colorSystem.neutral.white, zIndex: 2 }}
        >
            <LinearProgress
                variant="determinate"
                value={percentageComplete}
                color={isPcbAnalysisError ? 'error' : 'primary'}
            />
        </Flexbox>
    );
}
