import { InfoRounded } from '@mui/icons-material';
import { Skeleton, styled } from '@mui/material';
import { colorSystem, focusedStyles, hoveredStyles } from '../../theme';
import { Flexbox, FlexboxProps } from '../Flexbox';
import { Text } from '../Text';
import { Tooltip } from '../Tooltip';

export interface SummaryCardProps extends FlexboxProps {
    title: string;
    value: string | JSX.Element;
    tooltip?: string;
    description?: string;
    isSelected?: boolean;
    isLoading?: boolean;
}

export function SummaryCard({
    title,
    value,
    description,
    tooltip,
    isSelected,
    isLoading,
    ...boxProps
}: SummaryCardProps): JSX.Element {
    switch (!!description) {
        case true:
            return (
                <LargeSummaryCard
                    title={title}
                    value={value}
                    description={description}
                    tooltip={tooltip}
                    isSelected={isSelected}
                    isLoading={isLoading}
                    {...boxProps}
                />
            );
        case false:
            return (
                <SmallSummaryCard
                    title={title}
                    value={value}
                    tooltip={tooltip}
                    isSelected={isSelected}
                    isLoading={isLoading}
                    {...boxProps}
                />
            );
    }
}

const LargeSummaryCard = ({
    title,
    value,
    description,
    tooltip,
    isSelected,
    isLoading,
    ...boxProps
}: SummaryCardProps) => {
    const isSelectable = isSelected !== undefined;
    switch (isSelectable) {
        case true:
            if (isLoading) {
                return (
                    <LargeContainerSelectable
                        {...boxProps}
                        style={isSelected ? { ...focusedStyles, ...boxProps.style } : { ...boxProps.style }}
                    >
                        <LoadingContainer description={description} />
                    </LargeContainerSelectable>
                );
            }
            return (
                <LargeContainerSelectable
                    {...boxProps}
                    style={isSelected ? { ...focusedStyles, ...boxProps.style } : { ...boxProps.style }}
                >
                    <Flexbox gap="8px" flexDirection="column">
                        <Title title={title} tooltip={tooltip} />
                        <Text variant="h1" color={colorSystem.neutral[9]}>
                            {value}
                        </Text>
                    </Flexbox>
                    <Text variant="body-small" color={colorSystem.neutral[8]}>
                        {description}
                    </Text>
                </LargeContainerSelectable>
            );
        case false:
            if (isLoading) {
                return (
                    <LargeContainer {...boxProps}>
                        <LoadingContainer description={description} />
                    </LargeContainer>
                );
            }
            return (
                <LargeContainer {...boxProps}>
                    <Flexbox gap="8px" flexDirection="column">
                        <Title title={title} tooltip={tooltip} />
                        <Text variant="h1" color={colorSystem.neutral[9]}>
                            {value}
                        </Text>
                    </Flexbox>
                    <Text variant="body-small" color={colorSystem.neutral[8]}>
                        {description}
                    </Text>
                </LargeContainer>
            );
    }
};

const SmallSummaryCard = ({ title, value, tooltip, isSelected, isLoading }: SummaryCardProps) => {
    const isSelectable = isSelected !== undefined;
    switch (isSelectable) {
        case true:
            if (isLoading) {
                return (
                    <SmallContainerSelectable style={isSelected ? { ...focusedStyles } : {}}>
                        <LoadingContainer />
                    </SmallContainerSelectable>
                );
            }
            return (
                <SmallContainerSelectable style={isSelected ? { ...focusedStyles } : {}}>
                    <Title title={title} tooltip={tooltip} />
                    <Text variant="h1" color={colorSystem.neutral[9]}>
                        {value}
                    </Text>
                </SmallContainerSelectable>
            );
        case false:
            if (isLoading) {
                return (
                    <SmallContainer>
                        <LoadingContainer />
                    </SmallContainer>
                );
            }
            return (
                <SmallContainer>
                    <Title title={title} tooltip={tooltip} />
                    <Text variant="h1" color={colorSystem.neutral[9]}>
                        {value}
                    </Text>
                </SmallContainer>
            );
    }
};

const Title = ({ title, tooltip }: { title: string; tooltip?: string }) => {
    return (
        <Flexbox gap="4px">
            <Text variant="h4" color={colorSystem.neutral[7]}>
                {title}
            </Text>
            {tooltip && (
                <Tooltip title={tooltip}>
                    <InfoRounded fontSize="inherit" style={{ color: colorSystem.neutral[5] }} />
                </Tooltip>
            )}
        </Flexbox>
    );
};

const LoadingContainer = ({ description }: { description?: string }) => {
    return (
        <>
            <Skeleton height="20px" width="45%" />
            <Skeleton height="24px" width="70%" />
            {description && <Skeleton height="20px" width="100%" />}
        </>
    );
};

const Container = styled(Flexbox)({
    background: colorSystem.neutral[0],
    border: `1px solid ${colorSystem.neutral[2]}`,
    borderRadius: '8px',
    width: '100%',
    flexDirection: 'column',
    gap: '8px',
});

const SmallContainer = styled(Container)({
    padding: '20px 20px 24px 20px',
});

const SmallContainerSelectable = styled(SmallContainer)({
    background: colorSystem.neutral.white,
    cursor: 'pointer',
    '&:hover': {
        ...hoveredStyles,
    },
});

const LargeContainer = styled(Container)({
    padding: '20px',
    gap: '4px',
});

const LargeContainerSelectable = styled(LargeContainer)({
    background: colorSystem.neutral.white,
    cursor: 'pointer',
    '&:hover': {
        ...hoveredStyles,
    },
});
