import { t, Trans } from '@lingui/macro';
import { Flexbox, Tag, Text } from '@luminovo/design-system';
import { Tooltip } from '@mui/material';

export const DialogTitle = ({ isReusedColumnMapping }: { isReusedColumnMapping: boolean }) => {
    return (
        <Flexbox flex={1} alignItems={'center'} gap={8}>
            <Text variant="h1">
                <Trans>Column mapping</Trans>
            </Text>
            {isReusedColumnMapping && (
                <Tooltip
                    title={t`The column mapping was automatically inferred from the previous upload of your company were a BOM with the identical Excel header was imported.`}
                >
                    <span>
                        <Tag color="primary" label={t`Inferred from previous upload`} />
                    </span>
                </Tooltip>
            )}
        </Flexbox>
    );
};
