import { t } from '@lingui/macro';
import { isPresent } from '@luminovo/commons';
import { Flexbox, Text, Tooltip, colorSystem } from '@luminovo/design-system';
import { InfoRounded } from '@mui/icons-material';
import { Skeleton } from '../../../../../components/Spinners';
import { useParentsIdentifiers } from '../../../../../resources/assembly/assemblyHandler';

export function AssemblyPath({ assemblyId }: { assemblyId: string }) {
    const { data, isLoading } = useParentsIdentifiers(assemblyId);

    if (isLoading || !isPresent(data)) {
        return <Skeleton width="200px" />;
    }

    return (
        <Flexbox gap={2}>
            <Text color={colorSystem.neutral[8]}>
                {(data.multi_parent_items ?? [[]])[0].map((parent) => parent.designator).join(' > ')}
            </Text>
            {data.multi_parent_items.length > 1 && (
                <Tooltip title={t`There are multiple paths for this subassembly. Only the first one is shown.`}>
                    <InfoRounded fontSize="inherit" style={{ color: colorSystem.neutral[6] }} />
                </Tooltip>
            )}
        </Flexbox>
    );
}
