import { t, Trans } from '@lingui/macro';
import { colorSystem, Flexbox, SecondaryButton, SecondaryIconButton, Text, Tooltip } from '@luminovo/design-system';
import { RfQContextQueryParams } from '@luminovo/http-client';
import { Sync } from '@mui/icons-material';
import { useMemo } from 'react';
import { EmissionsTooltip } from '../../../../components/PartEmissionsView/EmissionsTooltip';
import { EmissionsView } from '../../../../components/PartEmissionsView/EmissionsView';
import { useGetEmissionsQuotaFull } from '../../../../components/PartEmissionsView/useGetEmissionsQuota';
import { useDescendantsSummary } from '../../../../resources/assembly/assemblyHandler';
import { ViewContext } from '../ModuleTableData';
import { usePartEmissionRequestModal } from './usePartEmissionRequestModal';

export const AssemblyEmissionsView = ({
    viewContext,
    assemblyId,
}: {
    viewContext: ViewContext;
    assemblyId: string;
}) => {
    const rfqId = viewContext.rfqId;
    const rfqContext: RfQContextQueryParams = useMemo(() => {
        if (rfqId) {
            return {
                rfq_context: 'WithinRfQ',
                rfq_id: rfqId,
            };
        } else {
            return { rfq_context: 'OutsideRfQ' };
        }
    }, [rfqId]);

    const { openDialog } = usePartEmissionRequestModal({ assemblyId, rfqContext });
    const { otsPartIds } = useGetEmissionsQuotaFull({ assemblyId, rfqContext });

    const { data: descendantsSummary } = useDescendantsSummary(assemblyId, viewContext);

    if (!descendantsSummary || !descendantsSummary.emission_data) {
        return (
            <Flexbox gap="8px" alignItems="center">
                <EmissionsTooltip>
                    <span>
                        <Flexbox gap="4px" alignItems="center">
                            <Text variant="h4">-</Text>
                            <Text variant="h4" color={colorSystem.neutral[6]}>
                                kgCO2e
                            </Text>
                        </Flexbox>
                    </span>
                </EmissionsTooltip>
                <Tooltip title={otsPartIds?.length === 0 ? t`Emissions data requested` : ''}>
                    <span>
                        <SecondaryButton
                            title="Request"
                            size="small"
                            onClick={openDialog}
                            disabled={otsPartIds ? otsPartIds.length === 0 : true}
                        >
                            <Trans>Request</Trans>
                        </SecondaryButton>
                    </span>
                </Tooltip>
            </Flexbox>
        );
    }

    const hasIncompleteEmissionsData = descendantsSummary.emission_data.kind === 'Gte';
    const min = Number(descendantsSummary.emission_data.product_phase_gwp_in_kg_co2e_min);
    const max = Number(descendantsSummary.emission_data.product_phase_gwp_in_kg_co2e_max);

    return (
        <Flexbox gap="8px" alignItems="center">
            <EmissionsTooltip hasIncompleteData={hasIncompleteEmissionsData}>
                <span>
                    <Flexbox gap="4px" alignItems="center">
                        <Text variant="h4">
                            <EmissionsView min={min} max={max} hasIncompleteData={hasIncompleteEmissionsData} />
                        </Text>
                        <Text variant="h4" color={colorSystem.neutral[6]}>
                            kgCO2e
                        </Text>
                    </Flexbox>
                </span>
            </EmissionsTooltip>
            <Tooltip title={otsPartIds?.length === 0 ? t`Emissions data is up-to-date` : ''}>
                <span>
                    <SecondaryIconButton
                        onClick={openDialog}
                        className="btn-actions"
                        size="medium"
                        disabled={otsPartIds ? otsPartIds.length === 0 : true}
                        style={{ width: 20, height: 20 }}
                    >
                        <Sync fontSize="inherit" />
                    </SecondaryIconButton>
                </span>
            </Tooltip>
        </Flexbox>
    );
};
