import { ComparableResult } from '@luminovo/design-system';
import { QuoteTrackingLight, QuoteTrackingState } from '@luminovo/http-client';

function getStatePriority(state: QuoteTrackingState): number {
    switch (state) {
        case QuoteTrackingState.Received:
            return 1;
        case QuoteTrackingState.Pending:
            return 2;
        case QuoteTrackingState.Overdue:
            return 3;
        case QuoteTrackingState.Discarded:
            return 4;
        case QuoteTrackingState.FailedToSend:
            return 5;
    }
}

export const compareByQuoteTrackingState = (
    a: Pick<QuoteTrackingLight, 'state'>,
    b: Pick<QuoteTrackingLight, 'state'>,
): ComparableResult => {
    const priorityA = getStatePriority(a.state);
    const priorityB = getStatePriority(b.state);

    if (priorityA < priorityB) {
        return -1;
    } else if (priorityA > priorityB) {
        return 1;
    } else {
        return 0;
    }
};
