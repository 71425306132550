import { isPresent } from '@luminovo/commons';
import { turnKeywordsIntoRegexRespectingWordBoundaries } from '../../framework/regexHelper';
import { PcbAttribute } from '../PcbAttribute';

export const isAllowedForTGValue = (value: number): boolean => {
    const minTGValue = 90;
    const maxTGValue = 300;
    return value >= minTGValue && value <= maxTGValue;
};

// TG150 or ≥150°C
const tgRegexes = ['TG:?\\s?(\\d{2,3})(?:HF)?', '(?:min\\.?|≥)\\s?(\\d{2,3})\\s?°C'];

export function parseTgValue(str: string): PcbAttribute | undefined {
    const tgValues = tgRegexes
        .map((regexString) => {
            const numberToParse = str.match(turnKeywordsIntoRegexRespectingWordBoundaries([regexString]))?.[1];
            if (numberToParse) {
                return parseInt(numberToParse);
            }
            return undefined;
        })
        .filter(isPresent);
    if (tgValues.length === 1 && isAllowedForTGValue(tgValues[0])) {
        return {
            attr: 'tgValue',
            value: tgValues[0],
            confidence: 1,
        };
    }
    return undefined;
}
