/* eslint-disable camelcase */
import { t } from '@lingui/macro';
import { compareByString, formatDecimal, isPresent, transEnum, uniq, uniqBy } from '@luminovo/commons';
import {
    FieldController,
    FieldDateControlled,
    FieldMultiSelectControlled,
    FieldNumericControlled,
    FieldSelectControlled,
    FieldSelectCreatableControlled,
    FieldText,
    FieldTextControlled,
    Flexbox,
    FormItem,
    FormSection,
    Text,
} from '@luminovo/design-system';
import { ComplianceStatus, LifecycleEnum, PackageMountingEnum, Qualification } from '@luminovo/http-client';
import { formatQualification, packageMountingEnumTranslations } from '@luminovo/sourcing-core';
import React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { CancelButton } from '../../../../../../components/formLayouts/CancelButton';
import { SubmitButton } from '../../../../../../components/formLayouts/SubmitButton';
import { ComplianceStatusTranslations } from '../../../../../../resources/part/i18n';
import { lifecycleEnumPublicTranslations } from '../../../../../../resources/part/partFrontendTypes';
import { usePartPackages } from '../../../../../../resources/part/partHandler';
import { validateUrl } from '../../../../../../utils/yupValidationUtils';
import { ManufacturerAutocomplete } from './components/ManufacturerAutocomplete';
import { PartCategorySelectControlled } from './components/PartCategorySelectControlled';
import { OtsFormValues } from './components/formValues';

const FormItemPart: React.FunctionComponent<{ isEditPartForm: boolean }> = ({ isEditPartForm }): JSX.Element => {
    const { control, getValues } = useFormContext<OtsFormValues>();

    return (
        <>
            <FormItem label={t`Manufacturer`} required>
                {isEditPartForm ? <Text>{getValues('manufacturerDto.name')}</Text> : <ManufacturerAutocomplete />}
            </FormItem>
            <FormItem label={t`MPN`} required>
                {isEditPartForm ? (
                    <Text>{getValues('mpn')}</Text>
                ) : (
                    <FieldTextControlled
                        name={'mpn'}
                        control={control}
                        required={true}
                        FieldProps={{ placeholder: t`Enter MPN, e.g. ERJ-3EKF56R2V` }}
                    />
                )}
            </FormItem>
            <FormItem label={t`Part category`} required>
                <PartCategorySelectControlled />
            </FormItem>
            <FormItem label={t`Description`}>
                <FieldController name={'description'} control={control} Field={FieldText} />
            </FormItem>
        </>
    );
};

const FormItemDatasheetUrl: React.FunctionComponent = (): JSX.Element => {
    const { control } = useFormContext<OtsFormValues>();

    return (
        <FormItem label={t`Datasheet URL`}>
            <FieldController name={'datasheet_url'} control={control} Field={FieldText} validate={validateUrl} />
        </FormItem>
    );
};

const FormItemPartUrl: React.FunctionComponent = (): JSX.Element => {
    const { control } = useFormContext<OtsFormValues>();

    return (
        <FormItem label={t`Part URL`}>
            <FieldController
                name={'manufacturer_product_url'}
                control={control}
                Field={FieldText}
                validate={validateUrl}
            />
        </FormItem>
    );
};

const FormItemPackage: React.FunctionComponent = (): JSX.Element => {
    const { control, setValue } = useFormContext<OtsFormValues>();

    const { data: packageData = [] } = usePartPackages('user-selectable');
    const mounting = useWatch({ control, name: 'mounting' });
    const numberOfPins = useWatch({ control, name: 'number_of_pins' });

    const sortedPackageOptions = packageData.sort((a, b) => compareByString(a.name ?? '', b.name ?? ''));
    const knownNumberOfPins = sortedPackageOptions
        .filter((p) => p.mounting === mounting)
        .some((p) => p.number_of_pins === numberOfPins);

    // Special handling for the "Other" mounting option, because it allows creating new packages.
    const [packageNameOptionsForOthers, setPackageNameOptionsForOthers] = React.useState(() =>
        uniqBy(
            sortedPackageOptions.filter((p) => p.mounting === PackageMountingEnum.Other),
            (p) => p.name,
        ).map((p) => p.name),
    );

    return (
        <>
            <FormItem label={t`Mounting`}>
                <FieldSelectControlled
                    control={control}
                    name="mounting"
                    FieldProps={{
                        options: Object.values(PackageMountingEnum),
                        getOptionLabel: (option) => transEnum(option, packageMountingEnumTranslations),
                        placeholder: t`Unknown`,
                        afterChange: () => {
                            setValue('package_name', null);
                            setValue('number_of_pins', null);
                        },
                    }}
                />
            </FormItem>
            <FormItem label={t`Name`}>
                {mounting !== PackageMountingEnum.Other && (
                    <FieldSelectControlled
                        control={control}
                        name="package_name"
                        FieldProps={{
                            options: uniq(
                                sortedPackageOptions.filter((p) => p.mounting === mounting).map((p) => p.name),
                            ),
                            getOptionLabel: (option) => option ?? t`Unknown`,
                            placeholder: t`Unknown`,
                        }}
                    />
                )}
                {mounting === PackageMountingEnum.Other && (
                    <FieldSelectCreatableControlled
                        control={control}
                        name="package_name"
                        FieldProps={{
                            options: packageNameOptionsForOthers,
                            getOptionLabel: (option) => option ?? t`Unknown`,
                            placeholder: t`Unknown`,
                            action: {
                                label: t`Create new package`,
                                onClick: (value) => {
                                    setPackageNameOptionsForOthers((prev) => [...prev, value]);
                                    setValue('package_name', value);
                                },
                            },
                        }}
                    />
                )}
            </FormItem>

            <FormItem label={t`Number of pins`}>
                <FieldNumericControlled
                    control={control}
                    name={'number_of_pins'}
                    isInteger
                    min={0}
                    FieldProps={{
                        helperText:
                            !knownNumberOfPins && isPresent(numberOfPins)
                                ? t`Will create a new package with ${formatDecimal(numberOfPins)} pins`
                                : undefined,
                        placeholder: t`Unknown`,
                    }}
                />
            </FormItem>
        </>
    );
};

export const FormItemCompliance: React.FunctionComponent = (): JSX.Element => {
    const { control } = useFormContext<OtsFormValues>();

    return (
        <>
            <FormItem label={'RoHS'}>
                <FieldSelectControlled
                    control={control}
                    name={'rohs_compliant'}
                    FieldProps={{
                        options: Object.values(ComplianceStatus),
                        getOptionLabel: (option) => transEnum(option, ComplianceStatusTranslations),
                        disableClearable: true,
                    }}
                />
            </FormItem>
            <FormItem label={'REACH'}>
                <FieldSelectControlled
                    control={control}
                    name={'reach_compliant'}
                    FieldProps={{
                        options: Object.values(ComplianceStatus),
                        getOptionLabel: (option) => transEnum(option, ComplianceStatusTranslations),
                        disableClearable: true,
                    }}
                />
            </FormItem>
        </>
    );
};

const FormItemLifecycle: React.FunctionComponent = (): JSX.Element => {
    const { control } = useFormContext<OtsFormValues>();
    const lifecycleStatus = useWatch({ control, name: 'lifecycle_status' });

    return (
        <FormItem label={t`Lifecycle`}>
            <FieldSelectControlled
                control={control}
                name={'lifecycle_status'}
                FieldProps={{
                    options: Object.values(LifecycleEnum),
                    getOptionLabel: (option) => transEnum(option, lifecycleEnumPublicTranslations),
                    disableClearable: true,
                }}
            />
            {lifecycleStatus === LifecycleEnum.EndOfLife && (
                <FormItem label={t`Last time buy date`}>
                    <FieldDateControlled name={'last_buy_date'} control={control} />
                </FormItem>
            )}
        </FormItem>
    );
};

const FormItemQualifications: React.FunctionComponent = (): JSX.Element => {
    const { control } = useFormContext<OtsFormValues>();

    return (
        <FormItem label={t`Qualifications`}>
            <FieldMultiSelectControlled
                control={control}
                name={'qualifications'}
                FieldProps={{
                    getOptionKey: (option) => option,
                    options: Object.values(Qualification),
                    getOptionLabel: (option) => formatQualification(option),
                    disableClearable: true,
                }}
            />
        </FormItem>
    );
};

export function OtsPartForm({ onCancel, isEditPartForm }: { onCancel: () => void; isEditPartForm: boolean }) {
    return (
        <Flexbox flexDirection={'column'}>
            <Flexbox flexDirection={'column'} paddingRight={'180px'}>
                <FormSection title={t`Part overview`}>
                    <FormItemPart isEditPartForm={isEditPartForm} />
                    <FormItemDatasheetUrl />
                    <FormItemPartUrl />
                </FormSection>
                <FormSection title={t`Package`}>
                    <FormItemPackage />
                </FormSection>
                <FormSection title={t`Compliance & lifecycle`}>
                    <FormItemCompliance />
                    <FormItemLifecycle />
                    <FormItemQualifications />
                </FormSection>
            </Flexbox>

            <Flexbox gap={8} paddingTop={'24px'} justifyContent={'flex-end'}>
                <CancelButton onClick={onCancel} />
                <SubmitButton />
            </Flexbox>
        </Flexbox>
    );
}
