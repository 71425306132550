import { throwErrorUnlessProduction } from '@luminovo/commons';
import { colorSystem, Flexbox, Select, Tag, Text } from '@luminovo/design-system';
import { PCBFileTypes } from '@luminovo/http-client';
import { MenuItem, styled } from '@mui/material';
import React from 'react';
import { transEnum } from '../../../../../components/localization/TransEnum';
import { drillTypesTranslations } from '../../LayerAssignment/utils/i18n';
import { PCBFileWithId } from '../../LayerAssignment/utils/layerAssignmentsUtils';

const DRILL_TYPES_FOR_SELECT: Array<PCBFileTypes> = [PCBFileTypes.PHDRILL, PCBFileTypes.NPHDRILL, PCBFileTypes.DRILL];

const StyledSelect = styled(Select)({
    height: '24px',
    padding: '0px',
    width: 'fit-content',
});

const renderDrillValue = (value: unknown): React.ReactNode => {
    if (typeof value === 'string' && value !== '') {
        return transEnum(value, drillTypesTranslations);
    }
    // Code shouldn't come here.
    throwErrorUnlessProduction(`Drill value doesn't match ${value}`);
};

export const DrillFileHeader: React.FunctionComponent<{
    drillFile: PCBFileWithId;
    disabled?: boolean;
    style?: React.CSSProperties;
    onChange: (fileType: PCBFileTypes) => void;
}> = ({ drillFile, disabled = false, onChange, style }) => {
    return (
        <Flexbox
            flexDirection={'column'}
            gap={'8px'}
            style={{ backgroundColor: colorSystem.neutral.white, padding: '12px 16px', borderRadius: '4px', ...style }}
        >
            <Text variant="h5" style={{ color: colorSystem.neutral[8] }}>
                {drillFile.name}
            </Text>

            <StyledSelect
                size="small"
                disabled={disabled}
                value={drillFile.fileType.fileType}
                style={{ width: 'fit-content', border: 0 }}
                renderValue={renderDrillValue}
                MenuProps={{
                    anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
                    transformOrigin: { vertical: 'top', horizontal: 'left' },
                }}
            >
                {DRILL_TYPES_FOR_SELECT.map((drillType: PCBFileTypes) => (
                    <MenuItem key={drillType} value={drillType} onClick={() => onChange(drillType)}>
                        <Tag color="violet" label={transEnum(drillType, drillTypesTranslations)} />
                    </MenuItem>
                ))}
            </StyledSelect>
        </Flexbox>
    );
};
