import { Trans } from '@lingui/macro';
import { formatToLongDate } from '@luminovo/commons';
import { colorSystem, Flexbox, Tag, TertiaryButton, Text, Tooltip } from '@luminovo/design-system';

type EditButtonProps =
    | {
          onEditClick: () => void;
          isEditButtonDisabled?: false | undefined;
      }
    | {
          tooltip: string;
          isEditButtonDisabled: true;
      };

export const DemandDateFilter = ({
    selectedStartDate,
    selectedEndDate,
    editButtonProps,
}: {
    selectedStartDate: string;
    selectedEndDate: string;
    editButtonProps: EditButtonProps;
}): JSX.Element => {
    return (
        <Flexbox gap={8} alignItems="center">
            <Text variant="body-small" color={colorSystem.neutral[7]}>
                <Trans>Show demand between</Trans>
            </Text>
            <Tag label={formatToLongDate(selectedStartDate)} color="neutral" attention="low" />
            <Text variant="body-small" color={colorSystem.neutral[7]}>
                <Trans>and</Trans>
            </Text>
            <Tag label={formatToLongDate(selectedEndDate)} color="neutral" attention="low" />
            {editButtonProps.isEditButtonDisabled && (
                <Tooltip title={editButtonProps.tooltip}>
                    <div>
                        <TertiaryButton size="small" disabled={true}>
                            <Trans>Edit</Trans>
                        </TertiaryButton>
                    </div>
                </Tooltip>
            )}
            {!editButtonProps.isEditButtonDisabled && (
                <TertiaryButton size="small" disabled={false} onClick={editButtonProps.onEditClick}>
                    <Trans>Edit</Trans>
                </TertiaryButton>
            )}
        </Flexbox>
    );
};
