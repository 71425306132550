import { AssemblyImportDTO, SingleAssemblyImportDTO } from '@luminovo/http-client';
import { MOCK_CUSTOMER_NUMBER } from './mockNames';

const createNewAssembly = ({ assemblyIpn }: { assemblyIpn: string }): SingleAssemblyImportDTO => {
    /* eslint-disable camelcase */
    return {
        bom_items: [
            {
                designators: ['C1'],
                quantity: 10,
                unit: 'Pieces',
                part_options: [
                    {
                        internal_part_number: {
                            value: '0-001',
                        },
                    },
                ],
            },
            {
                designators: ['C2'],
                quantity: 20,
                unit: 'Pieces',
                part_options: [
                    {
                        internal_part_number: {
                            value: '0-002',
                        },
                    },
                ],
            },
        ],
        customer_part_numbers: [
            {
                customer: {
                    number: MOCK_CUSTOMER_NUMBER,
                },
            },
        ],
        internal_part_number: {
            value: assemblyIpn,
        },
        type: 'PCBA',
    };
};

const createNewAssemblies = ({ numberOfAssemblies }: { numberOfAssemblies: number }): AssemblyImportDTO => {
    const assemblies = [];
    for (let i = 1; i < numberOfAssemblies; i++) {
        assemblies.push(createNewAssembly({ assemblyIpn: `Assembly-${i}` }));
    }
    return assemblies;
};
export const numberOfAssemblies = 8;
export const assemblies = createNewAssemblies({ numberOfAssemblies });
