import { SearchField } from '../../../PartLibrary/PartSearchPage/PartSearchInput';
import { UseComponentsSearchStateResult } from './ErpParametricSearchFunctions';

export function buildFiltersFromSearchState(searchState: UseComponentsSearchStateResult) {
    return searchState.state.selectedBlocks.map((block) => {
        if (typeof block.parameter === 'string') {
            block.parameter = block.parameter.trim();
        }
        if (block.field === SearchField.mpn) {
            return { ...block, op: 'Is', parameter: block.parameter };
        }
        if (block.op === '≥') {
            return { ...block, op: '>=', parameter: Number(block.parameter) };
        }
        if (block.op === '≤') {
            return { ...block, op: '<=', parameter: Number(block.parameter) };
        }
        if (block.op === '<' || block.op === '>' || block.op === '=') {
            return { ...block, parameter: Number(block.parameter) };
        }
        if (block.op === 'is') {
            return { ...block, op: 'Is', parameter: block.parameter };
        }
        if (block.op === 'is not') {
            return { ...block, op: 'IsNot', parameter: block.parameter };
        }
        if (block.op === 'starts-with') {
            return { ...block, op: 'StartsWith', parameter: block.parameter };
        }
        return block;
    });
}
