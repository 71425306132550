import { PcbAttribute } from '../PcbAttribute';
import { parseMillimeterWithPlusMinus } from './parseLength';
import { parseFinalThickness, parseFinalThicknessKeyword } from './parseThickness';

// const toleranceKeywords = ['Tolerance', 'Toleranz', 'Dicke'];
const percentageRegex = new RegExp('(?<![^\\s=])(?:\\+?\\/?-?)(\\d{1,2})(?:\\s?%)(?![^\\s,$])', 'i');

/**
 * Parses a percentage value from a string and returns it as a fraction (e.g. 0.1 for 10%).
 * @param str The string to parse.
 * @returns The percentage value as a fraction, or undefined if no percentage value was found.
 */
export function parsePercentage(str: string): number | undefined {
    // .[1] because [0] is the full match and [1] is the first matched group from the regex
    const percentageToParse = str.match(percentageRegex)?.[1];
    if (!percentageToParse) {
        return undefined;
    }
    const percentage = parseInt(percentageToParse);
    if (isNaN(percentage)) {
        return undefined;
    }
    return percentage / 100;
}

const allowedRelativeTolerance = [0.05, 0.1, 0.15];
const maxDelta = 0.025;
function matchToRelativeTolerance(absoluteTolerance: number, finalThickness: number): number | undefined {
    // divide the absolute by final and then check if it's closer than maxdelta to an allowedrelativetolerance, if yes return the closest allowedrelativetolerance otherwise undefined
    const relativeTolerance = absoluteTolerance / finalThickness;
    const closestAllowedRelativeTolerance = allowedRelativeTolerance.reduce((prev, curr) => {
        return Math.abs(curr - relativeTolerance) < Math.abs(prev - relativeTolerance) ? curr : prev;
    });
    return Math.abs(closestAllowedRelativeTolerance - relativeTolerance) < maxDelta
        ? closestAllowedRelativeTolerance
        : undefined;
}

export function parseLayerstackThicknessTolerance(str: string): PcbAttribute | undefined {
    const finalThicknessKeyword = parseFinalThicknessKeyword(str);
    const percentage = parsePercentage(str);
    if (finalThicknessKeyword && percentage) {
        return {
            attr: 'layerstackThicknessTolerance',
            value: percentage,
            confidence: finalThicknessKeyword.confidence ?? 1,
        };
    }
    const finalThickness = parseFinalThickness(str);
    const absoluteToleranceinMillimeter = parseMillimeterWithPlusMinus(str);
    if (finalThickness && absoluteToleranceinMillimeter) {
        const relativeTolerance = matchToRelativeTolerance(
            absoluteToleranceinMillimeter.value as number,
            finalThickness.value as number,
        );
        if (relativeTolerance) {
            return {
                attr: 'layerstackThicknessTolerance',
                value: relativeTolerance,
                confidence: finalThickness.confidence ?? 1,
            };
        }
    }
    return undefined;
}
