import { t } from '@lingui/macro';
import { TertiaryIconButton } from '@luminovo/design-system';
import { Close as CloseIcon } from '@mui/icons-material';
import { AppBar, Toolbar } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';
import ConfirmationDialogBox from '../../../components/dialogBox/ConfirmationDialogBox';
import { themeLuminovo } from '../../../themes';
import { ColumnMappingGuidanceBanner } from './ColumnMappingGuidance/ColumnMappingGuidanceBanner';

const BOX_SHADOW =
    '0px 2px 4px -1px rgb(0 0 0 / 1%), 0px 4px 5px 0px rgb(0 0 0 / 1%), 0px 1px 10px 0px rgb(0 0 0 / 1%)';

const StyledAppBar = styled(AppBar)({
    backgroundColor: themeLuminovo.palette.background.default,
    // eslint-disable-next-line spellcheck/spell-checker
    boxShadow: BOX_SHADOW,
    zIndex: 2,
});

export const DialogAppBar = ({
    title,
    actionButton,
    onClose,
    informationLabel,
}: {
    title: JSX.Element;
    actionButton: JSX.Element;
    onClose: () => void;
    informationLabel?: JSX.Element;
}) => {
    const [isDialogOpen, setIsDialogOpen] = React.useState<boolean>(false);

    function openDialog() {
        setIsDialogOpen(true);
    }

    function closeDialog() {
        setIsDialogOpen(false);
    }
    return (
        <StyledAppBar position={'fixed'}>
            <Toolbar style={{ padding: ' 0 32px' }}>
                <TertiaryIconButton edge="start" onClick={openDialog}>
                    <CloseIcon fontSize="inherit" />
                </TertiaryIconButton>
                <ConfirmationDialogBox
                    text={t`Are you sure you want to exit the BOM importer? All progress will be lost.`}
                    title={t`Exit BOM Importer`}
                    onConfirm={onClose}
                    onReject={closeDialog}
                    isDialogOpen={isDialogOpen}
                    deleteText={t`Yes, exit`}
                />
                {title}
                {informationLabel}
                <ColumnMappingGuidanceBanner />
                {actionButton}
            </Toolbar>
        </StyledAppBar>
    );
};
