import { DemandResponseDTO } from '@luminovo/http-client';
import { getSupplierOrRecipientNumber } from './getSupplierOrRecipientNumber';
import { CompanyRole, QuantityKind, SupplierOrRecipientQuantity } from './types';

export const selectTotalSupplierOrRecipientQuantitiesFromDemands = (
    demands: DemandResponseDTO[],
    quantityType: QuantityKind,
    companyRole: CompanyRole,
): Array<SupplierOrRecipientQuantity> => {
    const supplierOrRecipientQuantities: { [supplierOrRecipientNumber: string]: number } = {};
    demands.forEach((demand) => {
        const supplierOrRecipientNumber = getSupplierOrRecipientNumber(demand, companyRole);
        supplierOrRecipientQuantities[supplierOrRecipientNumber] =
            (supplierOrRecipientQuantities[supplierOrRecipientNumber] || 0) + demand[quantityType].quantity;
    });

    return Object.entries(supplierOrRecipientQuantities).map(([supplierOrRecipientNumber, totalQuantity]) => ({
        supplierOrRecipientNumber,
        totalQuantity,
    }));
};
