import { colorSystem, Flexbox, Text } from '@luminovo/design-system';
import { styled, SvgIconProps, Tooltip } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';

const StyledLink = styled(Link)({
    textDecoration: 'none',
    color: colorSystem.neutral[6],
    '&:hover': {
        color: colorSystem.primary[6],
    },
});
// both borderWidth and boxShadow are needed to make the border appear on top of the container's border
// while not overflowing the container
const StyledActiveLink = styled(StyledLink)({
    color: colorSystem.primary[6],
});

interface SidebarLinkProps extends React.ComponentProps<typeof Link> {
    isActive: boolean;
    disabled?: boolean;
    LinkBodyProps: React.ComponentProps<typeof LinkBody>;
}

const LinkBody = ({
    Icon,
    text,
    isOpen,
    style,
    hasNotification,
}: {
    Icon: React.ComponentType<SvgIconProps>;
    text: string;
    isOpen: boolean;
    hasNotification?: boolean;
    style?: React.CSSProperties;
}) => {
    return (
        <Flexbox
            flexDirection={'column'}
            style={{
                display: 'flex',
                flexDirection: 'row',
                gap: '8px',
                alignItems: 'center',
                justifyContent: 'flex-start',
                padding: '10px',
                borderRadius: '8px',
                position: 'relative',
                ...style,
            }}
        >
            <Tooltip title={text} disableHoverListener={isOpen}>
                <Icon style={{ fontSize: '20px' }} />
            </Tooltip>
            {isOpen && (
                <Text variant="h5" style={{ color: 'inherit', whiteSpace: 'nowrap', overflow: 'hidden' }}>
                    {text}
                </Text>
            )}
            {hasNotification && (
                <span
                    style={{
                        width: '6px',
                        height: '6px',
                        backgroundColor: colorSystem.red[5],
                        borderRadius: '50%',
                        alignSelf: 'start',
                        marginLeft: '-6px', // Due to the 8px gap in the flex, we want the notification bubble to be 2px to the left of the text
                        position: isOpen ? 'relative' : 'absolute',
                        top: isOpen ? '0px' : '8px',
                        right: isOpen ? '0px' : '5px',
                    }}
                />
            )}
        </Flexbox>
    );
};

export function SidebarLink({
    isActive: isSelected,
    disabled = false,
    onClick,
    LinkBodyProps,
    ...props
}: SidebarLinkProps) {
    if (disabled) {
        return (
            <LinkBody
                {...LinkBodyProps}
                style={{
                    color: colorSystem.neutral[4],
                    borderRight: `1px solid transparent`,
                    width: '100%',
                }}
            />
        );
    }

    if (isSelected) {
        return (
            <StyledActiveLink onClick={onClick} {...props}>
                <LinkBody
                    {...LinkBodyProps}
                    style={{
                        backgroundColor: colorSystem.primary[1],
                    }}
                />
            </StyledActiveLink>
        );
    }

    return (
        <StyledLink onClick={onClick} {...props}>
            <LinkBody {...LinkBodyProps} />
        </StyledLink>
    );
}
