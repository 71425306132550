import { Chip, Flexbox } from '@luminovo/design-system';
import { ComponentSpecificationWithFullPackage, SpecificationsConflict } from '@luminovo/http-client';
import { PackageChip } from '@luminovo/sourcing-core';
import {
    formatCapacitance,
    formatDielectricMaterial,
    formatPowerRating,
    formatResistance,
    formatTemperatureCoefficient,
    formatTolerance,
    formatVoltageRating,
} from '../utils/converterUtils';
import { assertUnreachable } from '../utils/typingUtils';

export const TechParamsMultiChip = ({
    componentSpecification,
    conflicts,
    flexWrap = 'wrap',
    hidePackage = false,
    disabled,
}: {
    componentSpecification: ComponentSpecificationWithFullPackage;
    conflicts?: SpecificationsConflict;
    flexWrap?: 'wrap' | 'nowrap';
    hidePackage?: boolean;
    disabled?: boolean;
}) => {
    const functionSpecification = componentSpecification.function_specification;
    const formfit = componentSpecification.form_and_fit;
    if (!functionSpecification) {
        if (formfit && !hidePackage) {
            return (
                <Flexbox gap={5}>
                    <PackageChip formfit={formfit} />
                </Flexbox>
            );
        } else {
            return null;
        }
    }

    if (functionSpecification.type === 'Resistor') {
        const techParams = functionSpecification.technical_parameters;
        return (
            <Flexbox gap="4px" flexWrap={flexWrap}>
                {functionSpecification && (
                    <Chip color={'neutral'} label={functionSpecification.type || ''} disabled={disabled} />
                )}
                {techParams && techParams.resistance && (
                    <ConflictColouredChip
                        conflicts={conflicts}
                        param={'resistance'}
                        label={formatResistance(techParams.resistance)}
                        disabled={disabled}
                    />
                )}
                {techParams && techParams.power_rating && (
                    <ConflictColouredChip
                        conflicts={conflicts}
                        param={'power_rating'}
                        label={formatPowerRating(techParams.power_rating)}
                        disabled={disabled}
                    />
                )}
                {techParams && techParams.voltage_rating && (
                    <ConflictColouredChip
                        conflicts={conflicts}
                        param={'voltage_rating'}
                        label={formatVoltageRating(techParams.voltage_rating)}
                        disabled={disabled}
                    />
                )}
                {techParams && techParams.temperature_coefficient && (
                    <ConflictColouredChip
                        conflicts={conflicts}
                        param={'temperature_coefficient'}
                        label={formatTemperatureCoefficient(techParams.temperature_coefficient)}
                        disabled={disabled}
                    />
                )}
                {techParams && techParams.tolerance && (
                    <ConflictColouredChip
                        conflicts={conflicts}
                        param={'tolerance'}
                        label={formatTolerance(techParams.tolerance)}
                        disabled={disabled}
                    />
                )}
                {formfit && !hidePackage && <PackageChip formfit={formfit} conflicts={conflicts?.form_and_fit} />}
            </Flexbox>
        );
    }
    if (functionSpecification.type === 'Capacitor') {
        const techParams = functionSpecification.technical_parameters;
        return (
            <Flexbox gap="4px" flexWrap={flexWrap}>
                {functionSpecification && (
                    <Chip color={'neutral'} label={functionSpecification.type || ''} disabled={disabled} />
                )}
                {techParams && techParams.capacitance && (
                    <ConflictColouredChip
                        conflicts={conflicts}
                        param={'capacitance'}
                        label={formatCapacitance(techParams.capacitance)}
                        disabled={disabled}
                    />
                )}
                {techParams && techParams.voltage_rating && (
                    <ConflictColouredChip
                        conflicts={conflicts}
                        param={'voltage_rating'}
                        label={formatVoltageRating(techParams.voltage_rating)}
                        disabled={disabled}
                    />
                )}
                {techParams && techParams.tolerance && (
                    <ConflictColouredChip
                        conflicts={conflicts}
                        param={'tolerance'}
                        label={formatTolerance(techParams.tolerance)}
                        disabled={disabled}
                    />
                )}
                {techParams && techParams.dielectric && (
                    <ConflictColouredChip
                        conflicts={conflicts}
                        param={'dielectric'}
                        label={formatDielectricMaterial(techParams.dielectric)}
                        disabled={disabled}
                    />
                )}
                {formfit && !hidePackage && <PackageChip formfit={formfit} conflicts={conflicts?.form_and_fit} />}
            </Flexbox>
        );
    }
    if (functionSpecification.type === 'Unsupported') {
        const techParams = functionSpecification.technical_parameters;
        return (
            <Flexbox gap="4px" flexWrap={flexWrap}>
                {techParams && techParams.part_type && (
                    <Chip color="neutral" label={techParams.part_type} disabled={disabled} />
                )}
                {formfit && !hidePackage && <PackageChip formfit={formfit} conflicts={conflicts?.form_and_fit} />}
            </Flexbox>
        );
    }
    assertUnreachable(functionSpecification);
};

function getChipColourFromConflicts(param: string, conflicts?: SpecificationsConflict): 'yellow' | 'neutral' {
    if (conflicts?.function_specification_keys?.includes(param)) {
        return 'yellow';
    }
    return 'neutral';
}

function ConflictColouredChip({
    conflicts,
    param,
    label,
    disabled,
}: {
    conflicts?: SpecificationsConflict;
    param: string;
    label: string;
    disabled?: boolean;
}) {
    return <Chip color={getChipColourFromConflicts(param, conflicts)} label={label} disabled={disabled} />;
}
