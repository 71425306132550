import { SourceListImportArrayDTO, SourceListImportDTO } from '@luminovo/http-client';
import { MOCK_SITE_NUMBER, MOCK_SUPPLIER_NUMBER_1 } from '../mockNames';

const createSourceList = (): SourceListImportDTO[] => {
    /* eslint-disable camelcase */
    return [
        {
            internal_part_number: {
                value: '0-001',
            },
            start_date: '2024-01-31',
            supplier_number: MOCK_SUPPLIER_NUMBER_1,
        },
        {
            internal_part_number: {
                value: '0-002',
            },
            start_date: '2024-01-31',
            supplier_number: MOCK_SITE_NUMBER,
        },
    ];
};
/* eslint-enable camelcase */

export const sourceListMock: SourceListImportArrayDTO = createSourceList();
