import { Trans } from '@lingui/macro';
import {
    DataTable,
    DefaultStyledTable,
    TableState,
    Text,
    colorSystem,
    useDataTableState,
} from '@luminovo/design-system';
import {
    AssemblyIndustry,
    GenericFullPart,
    IpnWithMatchesFullPart,
    OtsFullPart,
    PartSuggestionFull,
    RfqContext,
} from '@luminovo/http-client';
import { styled } from '@mui/material';
import { useCallback, useMemo } from 'react';
import { useHistory } from 'react-router';
import { hasPartsWithCpns } from '../../../../../components/partColumns/hasPartsWithCpns';
import { useGenericPartDetailsDrawer } from '../../../../../components/partSpecificationCards/GenericPart/GenericPartCardDetails';
import { useIpnDetailsDrawer } from '../../../../../components/partSpecificationCards/Ipn/useIpnDetailsDrawer';
import { useOtsPartDetailsDrawer } from '../../../../../components/partSpecificationCards/OTSPart/useOtsPartDetailsDrawer';
import { BomItem } from '../../../../../resources/designItem/bomItemFrontendTypes';
import { SectionOfScreen } from '../../../../../resources/part/partFrontendTypes';
import { useIpnLinkingDialog } from '../../LinkPartsDialog/useIpnLinkingDialog';
import { TableContainer } from '../TableContainer';
import { getPartialMatchesColumns, useGenericPartEditDrawer } from './columns';
import { PartialMatchesContext } from './partialMatchesTypes';

export const PartialMatchesTable = ({
    partialMatches,
    handleAddPartOption,
    rfqId,
    bomItem,
    assemblyId,
    assemblyIndustry,
    hasDesignatorQuantityMismatch,
    isSubmitting,
}: {
    partialMatches: PartSuggestionFull[];
    handleAddPartOption: (
        newPart: GenericFullPart | OtsFullPart | IpnWithMatchesFullPart,
        origin: SectionOfScreen,
    ) => void;
    rfqId: string;
    bomItem: BomItem;
    assemblyId: string;
    assemblyIndustry?: AssemblyIndustry;
    hasDesignatorQuantityMismatch: boolean;
    isSubmitting: boolean;
}): JSX.Element => {
    const history = useHistory();
    const rfqContext: RfqContext = useMemo(() => ({ type: 'WithinRfQ', rfq_id: rfqId }), [rfqId]);
    const { openDialog: openIpnLinkingDialog } = useIpnLinkingDialog();
    const { openDrawer: openOtsDrawer } = useOtsPartDetailsDrawer();
    const { openDrawer: openIpnDrawer } = useIpnDetailsDrawer();
    const { openDrawer: openGenericPartDrawer } = useGenericPartDetailsDrawer();
    const { openDrawer: openEditGenericPartDrawer } = useGenericPartEditDrawer({
        rfqContext,
        onGenericPartCreation: handleAddPartOption,
    });

    const sharedContext: PartialMatchesContext = useMemo(() => {
        return {
            rfqContext,
            bomItem,
            rfqId,
            assemblyId,
            handleAddPartOption,
            assemblyIndustry,
            openIpnLinkingDialog,
            hasDesignatorQuantityMismatch,
            isSubmitting,
        };
    }, [
        rfqContext,
        bomItem,
        rfqId,
        assemblyId,
        handleAddPartOption,
        assemblyIndustry,
        openIpnLinkingDialog,
        hasDesignatorQuantityMismatch,
        isSubmitting,
    ]);

    const hasPartOptionsWithCpns = useMemo(
        () =>
            hasPartsWithCpns({
                parts: partialMatches.map((partialMatch) => partialMatch.part),
            }),
        [partialMatches],
    );

    const columns = useMemo(() => getPartialMatchesColumns({ hasPartOptionsWithCpns }), [hasPartOptionsWithCpns]);
    const tableState: TableState<PartSuggestionFull, PartialMatchesContext> = useDataTableState({
        columns,
        items: partialMatches,
        paginationOptions: {
            showPagination: false,
        },
        persistenceId: `partial-matches.${history.location.pathname}`,
        sharedContext,
    });

    const handleRowClick = useCallback(
        (suggestion: PartSuggestionFull) => {
            if (suggestion.type === 'OffTheShelf') {
                return openOtsDrawer({ part: suggestion.part, rfqContext });
            }
            if (suggestion.type === 'Component') {
                return openIpnDrawer({ ipnId: suggestion.part.id, rfqContext });
            }
            if (suggestion.type === 'Generic') {
                return openGenericPartDrawer({
                    genericPart: suggestion.part.content,
                    mpnMatches: suggestion.part.matches,
                    isEditEnable: true,
                    onAddAlsoRemove: (part) => handleAddPartOption(part, 'partialMatches'),
                    rfqContext,
                });
            }
            if (suggestion.type === 'IncompleteGeneric') {
                return openEditGenericPartDrawer({ part: suggestion.part });
            }
        },
        [
            handleAddPartOption,
            openEditGenericPartDrawer,
            openGenericPartDrawer,
            openIpnDrawer,
            openOtsDrawer,
            rfqContext,
        ],
    );

    if (partialMatches.length === 0) {
        return (
            <Text variant="h5" color={colorSystem.neutral[6]}>
                <Trans>No partial part matches found</Trans>
            </Text>
        );
    }

    return (
        <DataTable
            tableState={tableState}
            overrides={{ Container: StyledTableContainer, Table: StyledTable }}
            onItemClick={handleRowClick}
            stickyHeader={false}
            size="medium"
        />
    );
};

// The table & its container are rotated by 180 degrees so that the scrollbar is moved to the top of the table.
const rotate180degrees = 'rotateX(180deg)';

const StyledTableContainer = styled(TableContainer)({
    overflow: 'invisible',
    transform: rotate180degrees,
});

const StyledTable = styled(DefaultStyledTable)({
    transform: rotate180degrees,
});
