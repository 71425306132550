import {
    BomImporterBackendInputDesignItem,
    BomImporterLine,
    ExcelLineType,
    QuantityUnit,
    QuantityUnitDTO,
} from '@luminovo/http-client';
import { isPresent } from './isPresent';

/* eslint-disable camelcase*/
const convertLineToDesignItemsInput = (
    line: BomImporterLine,
    assemblyId: string,
    excel_lines_index: number,
): { designItems: BomImporterBackendInputDesignItem[]; excel_lines: ExcelLineType } => {
    const bomItem = line.bom_item;
    const notes = line.bom_item?.notes ?? '';
    const partSuggestions = bomItem?.part_suggestions ?? [];
    const excel_lines = line.excel_origins.map((origin) => ({
        line_number: origin.line_number,
        raw_original_line: origin.raw_original_line,
    }));
    if (bomItem === null) {
        return {
            designItems: [
                {
                    design_item_input: {
                        assembly: assemblyId,
                        designator: null,
                        quantity: { quantity: 1, unit: QuantityUnit.Pieces },
                        do_not_place: false,
                        notes: notes,
                        part_specification: null,
                        part_suggestions: partSuggestions,
                        is_consigned: false,
                    },
                    excel_lines_index,
                },
            ],
            excel_lines,
        };
    }

    if (bomItem.designators.length === 0) {
        return {
            designItems: [
                {
                    design_item_input: {
                        assembly: assemblyId,
                        designator: null,
                        quantity: { quantity: 1, unit: QuantityUnit.Pieces },
                        do_not_place: bomItem.dnp,
                        notes: notes,
                        part_specification: bomItem.part_specification,
                        part_suggestions: partSuggestions,
                        is_consigned: bomItem.is_consigned,
                    },
                    excel_lines_index,
                },
            ],
            excel_lines,
        };
    }
    const quantity: QuantityUnitDTO | null = isPresent(bomItem.quantity_per_designator)
        ? { quantity: bomItem.quantity_per_designator, unit: bomItem.unit }
        : null;
    return {
        designItems: bomItem.designators.map((designator) => {
            return {
                design_item_input: {
                    assembly: assemblyId,
                    designator: designator,
                    quantity,
                    do_not_place: bomItem.dnp,
                    notes: notes,
                    part_specification: bomItem.part_specification,
                    part_suggestions: partSuggestions,
                    is_consigned: bomItem.is_consigned,
                },
                excel_lines_index,
            };
        }),
        excel_lines,
    };
};

export const convertToDesignItemInputs = (
    bomLines: BomImporterLine[],
    assemblyId: string,
): { design_items: BomImporterBackendInputDesignItem[]; excel_lines: ExcelLineType[] } => {
    return bomLines.reduce(
        (agg: { design_items: BomImporterBackendInputDesignItem[]; excel_lines: ExcelLineType[] }, line, idx) => {
            const { designItems, excel_lines } = convertLineToDesignItemsInput(line, assemblyId, idx);
            agg.design_items = agg.design_items.concat(designItems);
            agg.excel_lines.push(excel_lines);
            return agg;
        },
        { design_items: [], excel_lines: [] },
    );
};
/* eslint-enable camelcase*/
