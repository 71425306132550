import { Plural, t, Trans } from '@lingui/macro';
import { isPresent } from '@luminovo/commons';
import { colorSystem, Flexbox, Message, Tag, TertiaryButton, Text, Tooltip } from '@luminovo/design-system';
import { IpnWithMatchesFullPart, RfqContext } from '@luminovo/http-client';
import { PreferredIpnTag, TimeStamp } from '@luminovo/sourcing-core';
import { Launch, ListRounded, Numbers } from '@mui/icons-material';
import { CircularProgress } from '@mui/material';
import { GenericPartCard } from '../../../../components/partSpecificationCards/GenericPart/GenericPartCard';
import { CpnsTable } from '../../../../components/partSpecificationCards/Ipn/CpnsTable';
import {
    getComponentSpecificationsChips,
    renderComponentSpecificationWarning,
} from '../../../../components/partSpecificationCards/Ipn/IpnCardDetails';
import { OTSPartCard } from '../../../../components/partSpecificationCards/OTSPart/OTSPartCard';
import { TechParamsMultiChip } from '../../../../components/TechParamsMultiChip';
import { useIpn } from '../../../../resources/part/partHandler';
import { useHasCustomers } from '../../../../utils/featureFlags';
import { route } from '../../../../utils/routes';

const DetailsSection: React.FunctionComponent<{
    label: string | JSX.Element;
}> = ({ label, children }) => {
    return (
        <Flexbox flexDirection={'column'} gap={12}>
            <Text variant="h4" color={colorSystem.neutral[7]}>
                {label}
            </Text>
            {children}
        </Flexbox>
    );
};

const LabelWithText = ({ label, text }: { label: string; text: string }): JSX.Element => {
    return (
        <Flexbox justifyContent={'space-between'} gap={24}>
            <Text variant={'body-small-semibold'} color={colorSystem.neutral[7]}>
                {label}
            </Text>
            <Text variant={'body-small'} style={{ textAlign: 'right' }}>
                {text}
            </Text>
        </Flexbox>
    );
};

const ComponentSpecificationsInformation: React.FunctionComponent<{
    ipn: IpnWithMatchesFullPart;
}> = ({ ipn }) => {
    return (
        <>
            {isPresent(ipn.component_specifications?.conflicts) &&
                isPresent(ipn.component_specifications) &&
                renderComponentSpecificationWarning(ipn.component_specifications.conflicts)}
            {isPresent(ipn.component_specification) && (
                <TechParamsMultiChip
                    componentSpecification={ipn.component_specification}
                    conflicts={ipn.component_specifications?.conflicts}
                />
            )}
        </>
    );
};

const OpenIpnPageButton: React.FunctionComponent<{
    ipnId: string;
}> = ({ ipnId }) => {
    return (
        <Tooltip title={t`Open IPN details page`} placement="top" arrow>
            <TertiaryButton
                size="small"
                startIcon={<Launch />}
                onClick={() =>
                    window.open(
                        route('/parts/components/ipn', {}, { ipnId, q: undefined }),
                        '_blank',
                        'noopener noreferrer',
                    )
                }
            >
                <Trans>Open IPN page</Trans>
            </TertiaryButton>
        </Tooltip>
    );
};

export const IpnDetailsPanel = ({
    ipnId,
    rfqContext,
    BackButton,
    showOpenIpnPageButton,
    showCpnsTable = true,
}: {
    ipnId: string;
    rfqContext: RfqContext;
    BackButton: React.ComponentType;
    showOpenIpnPageButton: boolean;
    showCpnsTable?: boolean;
}): JSX.Element => {
    const { hasCustomers } = useHasCustomers();
    const { data: ipn, isLoading } = useIpn(ipnId, rfqContext, { refetchOnWindowFocus: true });

    const suggestionsLength = ipn?.suggestions.length ?? 0;

    if (isLoading || !ipn) {
        return (
            <Flexbox height={'80vh'} justifyContent="center" alignItems="center">
                <CircularProgress />
            </Flexbox>
        );
    }

    const suggestionsMessage = (
        <Plural
            value={suggestionsLength}
            one={t`No parts could be linked automatically based on synced ERP data. There is ${suggestionsLength} suggestion. We only use MPN and manufacturer data for automatically linking parts.`}
            other={t`No parts could be linked automatically based on synced ERP data. There are ${suggestionsLength} suggestions, however. We only use MPN and manufacturer data for automatically linking parts.`}
        />
    );

    return (
        <Flexbox flexDirection="column" gap={24} style={{ backgroundColor: 'inherit' }}>
            <Flexbox justifyContent={'space-between'} alignItems={'center'}>
                <BackButton />
                <Flexbox gap={12}>{showOpenIpnPageButton && <OpenIpnPageButton ipnId={ipnId} />}</Flexbox>
            </Flexbox>

            <Flexbox flexDirection="column" justifyContent="center" gap={16}>
                <Flexbox gap={4}>
                    <Text variant="h2" color={colorSystem.neutral[5]}>
                        IPN
                    </Text>
                    <Text variant="h2" color={colorSystem.neutral[8]}>
                        {ipn.id}
                    </Text>
                    {ipn.is_preferred && <PreferredIpnTag styles={{ height: '20px', alignSelf: 'center' }} />}
                </Flexbox>

                <Flexbox gap={8}>
                    <TimeStamp title={t`Last import`} time={ipn.last_imported_at} />
                    <TimeStamp title={t`Last change`} time={ipn.updated_at} />
                </Flexbox>
            </Flexbox>

            <DetailsSection
                label={
                    <Flexbox justifyContent={'space-between'} alignItems={'center'}>
                        <Text variant="h4" color={colorSystem.neutral[7]}>
                            <Trans>Specification</Trans>
                        </Text>
                        {getComponentSpecificationsChips(ipn)}
                    </Flexbox>
                }
            >
                <ComponentSpecificationsInformation ipn={ipn} />
            </DetailsSection>

            <DetailsSection
                label={
                    <Flexbox gap={4} alignItems={'center'}>
                        <ListRounded
                            fontSize="small"
                            style={{ color: ipn.matches.length > 0 ? colorSystem.green[5] : colorSystem.red[5] }}
                        />
                        <Text variant="h4" color={colorSystem.neutral[7]}>
                            <Trans>Linked parts</Trans>
                        </Text>
                    </Flexbox>
                }
            >
                <Flexbox flexDirection={'column'} gap={8}>
                    {ipn.matches.length === 0 && (
                        <Message
                            size="large"
                            attention="low"
                            variant={'red'}
                            title={t`No linked parts`}
                            message={suggestionsMessage}
                        />
                    )}
                    {ipn.matches.map((part) => {
                        return part.part.type === 'OffTheShelf' ? (
                            <OTSPartCard key={part.part.data.id} part={part.part.data} rfqContext={rfqContext} />
                        ) : (
                            <GenericPartCard key={part.part.data.id} part={part.part.data} rfqContext={rfqContext} />
                        );
                    })}
                </Flexbox>
            </DetailsSection>

            <DetailsSection
                label={
                    <Flexbox gap={4} alignItems={'center'}>
                        <ListRounded fontSize="small" style={{ color: colorSystem.neutral[5] }} />
                        <Text variant="h4" color={colorSystem.neutral[7]}>
                            <Trans>Synced ERP data</Trans>
                        </Text>
                    </Flexbox>
                }
            >
                <Flexbox flexDirection={'column'} gap={16}>
                    {ipn.part_specifications.map((data, i) => {
                        return (
                            <Flexbox
                                flexDirection={'column'}
                                gap={8}
                                key={i}
                                bgcolor={colorSystem.neutral.white}
                                padding={'4px'}
                                borderRadius={'4px'}
                                border={`1px solid ${colorSystem.neutral[3]}`}
                            >
                                <LabelWithText label={t`MPN`} text={data.mpn ?? '-'} />
                                <LabelWithText label={t`Manufacturer`} text={data.manufacturer ?? '-'} />
                                <LabelWithText label={t`Description`} text={data.description ?? '-'} />
                                <LabelWithText label={t`Package`} text={data.package ?? '-'} />
                                <LabelWithText label={t`Part type`} text={data.part_type ?? '-'} />
                            </Flexbox>
                        );
                    })}
                    {ipn.spns.map((data, i) => {
                        return (
                            <Flexbox flexDirection={'column'} gap={8} key={i}>
                                <LabelWithText label={t`SPN`} text={data.supplier_part_number ?? '-'} />
                                <LabelWithText label={t`Supplier`} text={data.supplier ?? '-'} />
                            </Flexbox>
                        );
                    })}
                </Flexbox>
            </DetailsSection>

            {ipn.cpns.length > 0 && showCpnsTable && (
                <DetailsSection
                    label={
                        <Flexbox gap={4} alignItems="center">
                            <Numbers fontSize="small" style={{ color: colorSystem.neutral[5] }} />
                            <Text variant="h4" color={colorSystem.neutral[7]}>
                                {hasCustomers ? t`Customer part numbers` : t`Company part numbers`}
                            </Text>
                            <Flexbox flex={1} />
                            {ipn.is_restricted_to_customers && (
                                <Tag color="violet" attention="low" label={'Restricted'} />
                            )}
                        </Flexbox>
                    }
                >
                    <CpnsTable cpns={ipn.cpns} isRestrictedToCustomers={ipn.is_restricted_to_customers} />
                </DetailsSection>
            )}
        </Flexbox>
    );
};
