import { BrokenImageRounded } from '@mui/icons-material';
import { Box, BoxProps, styled } from '@mui/material';
import React from 'react';
import { colorSystem } from '../../theme';
import { Dialog } from '../Dialog';

export interface ThumbnailProps extends BoxProps {
    src?: string;
    width?: string;
    height?: string;
    borderRadius?: string;
    fallback?: JSX.Element;
    ImageProps?: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;
}

export function Thumbnail({ src, fallback, width, height, ImageProps, ...rest }: ThumbnailProps): JSX.Element {
    const [isDialogOpen, setOpen] = React.useState(false);
    const state = useThumbnailState(src);

    console.log('state', state);

    if (state.status === 'error') {
        return fallback ?? <DefaultFallback width={width} height={height} />;
    }

    return (
        <>
            {isDialogOpen && (
                <Dialog maxWidth="md" open={true} title="" onClose={() => setOpen(false)}>
                    <img style={{ maxHeight: '70vh', objectFit: 'contain' }} src={src} />
                </Dialog>
            )}
            <Container
                sx={{
                    width,
                    height,
                    minWidth: width,
                }}
                onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setOpen(true);
                }}
                {...rest}
            >
                {state.status === 'ok' && (
                    <div
                        {...ImageProps}
                        data-testid="thumbnail-image"
                        style={{
                            ...ImageProps?.style,
                            width: '100%',
                            height: '100%',
                            backgroundImage: `url(${state.src})`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat',
                        }}
                    />
                )}
            </Container>
        </>
    );
}

function useThumbnailState(src?: string) {
    const [state, setState] = React.useState<
        { status: 'loading' } | { status: 'ok'; src: string } | { status: 'error' }
    >({ status: 'loading' });

    React.useEffect(() => {
        if (!src) {
            return;
        }
        const img = new Image();
        img.onerror = () => {
            setState({ status: 'error' });
        };
        img.onload = () => {
            setState({ status: 'ok', src });
        };
        img.src = src;
    }, [src]);

    return state;
}

const Container = styled(Box)({
    borderRadius: '4px',
    cursor: 'pointer',
});

function DefaultFallback({ width, height }: { width?: string; height?: string }) {
    return (
        <div
            data-testid="broken-image-icon"
            style={{
                display: 'grid',
                placeItems: 'center',
                width,
                height,
                background: colorSystem.neutral[1],
                border: `1px solid ${colorSystem.neutral[2]}`,
                borderRadius: '4px',
            }}
        >
            <BrokenImageRounded style={{ color: colorSystem.neutral[5] }} />
        </div>
    );
}
