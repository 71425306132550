import { LumiQuoteRoute } from '../../../../../utils/routes';

export const PCBRoutesExceptSpecification: LumiQuoteRoute[] = [
    '/rfqs/:rfqId/bom/assembly/:assemblyId/pcb/cad-viewer',
    '/rfqs/:rfqId/bom/assembly/:assemblyId/pcb/stackup',
    '/rfqs/:rfqId/bom/assembly/:assemblyId/pcb/files',
    '/rfqs/:rfqId/bom/assembly/:assemblyId/pcb/drills',
    '/rfqs/:rfqId/bom/assembly/:assemblyId/pcb/panelization',
];

export const PCBRoutes: LumiQuoteRoute[] = [
    '/rfqs/:rfqId/bom/assembly/:assemblyId/pcb/cad-viewer',
    '/rfqs/:rfqId/bom/assembly/:assemblyId/pcb/specification',
    '/rfqs/:rfqId/bom/assembly/:assemblyId/pcb/stackup',
    '/rfqs/:rfqId/bom/assembly/:assemblyId/pcb/files',
    '/rfqs/:rfqId/bom/assembly/:assemblyId/pcb/drills',
    '/rfqs/:rfqId/bom/assembly/:assemblyId/pcb/panelization',
];
