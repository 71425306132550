import { t } from '@lingui/macro';
import { assertUnreachable, compareByStringKey } from '@luminovo/commons';
import { Flexbox, ReleaseTag, Tag } from '@luminovo/design-system';
import { BulkPnpItemsResponseDTO, PnpItemDTO } from '@luminovo/http-client';
import { ImporterCard } from '@luminovo/manufacturing-core';
import { PnpFileActionButton } from './PnpFileActionButton';
import { PnpTable } from './PnpTable';
import { PnpTableItem, Side } from './types';

const convertSide = (side: PnpItemDTO['side']): Side => {
    switch (side) {
        case 'Top':
            return 'Top';
        case 'Bottom':
            return 'Bottom';
        case null:
            return 'Missing';
        default:
            assertUnreachable(side);
    }
};

const convertColumnMappingToTableColumns = (
    columnMapping: BulkPnpItemsResponseDTO,
    allBomDesignators: string[],
): PnpTableItem[] => {
    const pnpItems: PnpTableItem[] = columnMapping.items
        .filter((d) => allBomDesignators.includes(d.designator))
        .map((item) => {
            return {
                designator: item.designator,
                side: convertSide(item.side),
                mounting: item.mounting === null ? 'Missing' : item.mounting,
            };
        });

    return pnpItems.sort(compareByStringKey((s) => s.designator));
};

export function PnpViewer({
    columnMapping,
    allBomDesignators,
    assemblyId,
    isRfqEditable,
}: {
    columnMapping: BulkPnpItemsResponseDTO;
    assemblyId: string;
    allBomDesignators: string[];
    isRfqEditable: boolean;
}) {
    const items = convertColumnMappingToTableColumns(columnMapping, allBomDesignators);
    return (
        <ImporterCard
            heading={t`Pick and place`}
            ActionButton={<PnpFileActionButton assemblyId={assemblyId} isRfqEditable={isRfqEditable} />}
            Tag={
                <Flexbox flexDirection={'row'} gap={12}>
                    <ReleaseTag label={'New'} color={'violet'} />{' '}
                    <Tag attention="low" color="neutral" label={t`Matched with BOM`} />
                </Flexbox>
            }
        >
            <div
                style={{
                    width: '100%',
                }}
            >
                <PnpTable items={items} />
            </div>
        </ImporterCard>
    );
}
