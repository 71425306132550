import { FiberManualRecord } from '@mui/icons-material';
import { Chip as MuiChip, ChipProps as MuiChipProps } from '@mui/material';
import { styled } from '@mui/styles';
import React from 'react';
import { colorSystem } from '../../theme';
import { Flexbox } from '../Flexbox';
import { Text } from '../Text';

export interface StatusChipProps extends Omit<MuiChipProps, 'color'> {
    label: React.ReactNode;
    color: keyof typeof colorSystem;
    disabled?: boolean;
    enableHoverEffect?: boolean;
    showEllipsis?: boolean;
    overrides?: {
        Container?: React.ComponentType<MuiChipProps>;
        Icon?: React.ComponentType<React.SVGProps<SVGSVGElement>>;
    };
}

const commonStyle = {
    cursor: 'inherit',
    width: 'fit-content',
    height: 'fit-content',
    minHeight: 22,
    borderRadius: 24,
    border: `1px solid ${colorSystem.neutral[2]}`,
    background: colorSystem.neutral.white,
    '& .MuiChip-labelSmall': {
        paddingLeft: '6px',
        paddingRight: '6px',
    },
};

export const StatusChipDefaultContainer = styled(MuiChip)(commonStyle);

const HoverContainer = styled(MuiChip)({
    ...commonStyle,
    '&:hover': { background: colorSystem.neutral[1] },
});

interface ChipLabelProps {
    label: React.ReactNode;
    color: keyof typeof colorSystem;
    disabled: boolean;
    showEllipsis?: boolean;
    Icon?: React.ComponentType<React.SVGProps<SVGSVGElement>>;
}

const ChipLabel: React.FunctionComponent<ChipLabelProps> = ({
    label,
    color,
    disabled,
    showEllipsis,
    Icon = FiberManualRecord,
}) => (
    <Flexbox alignItems="center" gap={4}>
        <Icon
            style={{
                fontSize: '9px',
                color: disabled ? colorSystem[color][2] : colorSystem[color][5],
            }}
        />
        <Text
            variant="body-small"
            showEllipsis={showEllipsis}
            color={disabled ? colorSystem.neutral[6] : colorSystem.neutral[8]}
        >
            {label}
        </Text>
    </Flexbox>
);

export const StatusChip = React.forwardRef<HTMLDivElement, StatusChipProps>(
    (
        { label, color, disabled = false, showEllipsis, enableHoverEffect = false, overrides = {}, ...muiChipProps },
        ref,
    ) => {
        const Container = overrides.Container || (enableHoverEffect ? HoverContainer : StatusChipDefaultContainer);

        return (
            <Container
                ref={ref}
                label={
                    <ChipLabel
                        label={label}
                        color={color}
                        disabled={disabled}
                        showEllipsis={showEllipsis}
                        Icon={overrides.Icon}
                    />
                }
                {...muiChipProps}
            />
        );
    },
);
