import * as r from 'runtypes';
import { endpoint } from '../http/endpoint';
import { generalOfferEndpointsToInvalidate } from '../offer/offerEndpoints';
import {
    SingelSolutionConfigurationPatchResponseDTORuntype,
    SolutionConfigurationDTORuntype,
    SolutionConfigurationPatchDTORuntype,
    SolutionConfigurationsBulkPatchDTORuntype,
} from './solutionConfigurationBackendTypes';

/* eslint-disable camelcase */

export const solutionConfigurationEndpoints = {
    'GET /solution-configurations/:solutionConfigurationId': endpoint({
        description: 'GET a single solution configuration',
        pathParams: r.Record({ solutionConfigurationId: r.String }),
        queryParams: r.Undefined,
        requestBody: r.Undefined,
        responseBody: r.Record({
            data: SolutionConfigurationDTORuntype,
        }),
    }),

    'GET /solution-configurations': endpoint({
        description: 'Returns an array of solution configurations from a sourcing scenario',
        pathParams: r.Undefined,
        queryParams: r.Record({
            sourcing_scenario: r.String,
        }),
        requestBody: r.Undefined,
        responseBody: r.Record({
            items: r.Array(SolutionConfigurationDTORuntype),
        }),
    }),

    'PATCH /solution-configurations/:solutionConfigurationId': endpoint({
        description: 'Updates a solution configuration',
        pathParams: r.Record({ solutionConfigurationId: r.String }),
        queryParams: r.Undefined,
        requestBody: SolutionConfigurationPatchDTORuntype,
        responseBody: r.Record({
            data: SingelSolutionConfigurationPatchResponseDTORuntype,
        }),
        invalidates: generalOfferEndpointsToInvalidate,
    }),

    'PATCH /solution-configurations': endpoint({
        description: 'Updates multiple solution configurations',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: SolutionConfigurationsBulkPatchDTORuntype,
        responseBody: r.Record({
            items: r.Array(SingelSolutionConfigurationPatchResponseDTORuntype),
        }),
        invalidates: generalOfferEndpointsToInvalidate,
    }),
};
