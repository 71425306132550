import { isPresent } from '@luminovo/commons';
import React, { useRef, useState } from 'react';
import { NOTO_SANS, POPPINS, SOURCE_CODE_PRO, colorSystem } from '../../theme';
import { Tooltip } from '../Tooltip';

export type FontVariant =
    | 'h1'
    | 'h2'
    | 'h3'
    | 'h4'
    | 'h5'
    | 'body'
    | 'body-semibold'
    | 'body-small'
    | 'body-small-semibold'
    | 'caption'
    | 'code'
    | 'inherit';

export interface Props extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLSpanElement>, HTMLSpanElement> {
    variant?: FontVariant;
    color?: string;
    /**
     * If true, adds a tooltip title to the ellipsis text to reveal what the full text is past the ellipsis.
     */
    showEllipsis?: boolean;
}

const variantStyles: Record<FontVariant, React.CSSProperties> = {
    h1: {
        fontFamily: POPPINS,
        fontWeight: 600,
        fontSize: '20px',
        lineHeight: '24px',
        color: colorSystem.neutral[8],
    },
    h2: {
        fontFamily: POPPINS,
        fontWeight: 600,
        fontSize: '18px',
        lineHeight: '24px',
        color: colorSystem.neutral[8],
    },
    h3: {
        fontFamily: POPPINS,
        fontWeight: 600,
        fontSize: '16px',
        lineHeight: '20px',
        color: colorSystem.neutral[8],
    },
    h4: {
        fontFamily: POPPINS,
        fontWeight: 600,
        fontSize: '14px',
        lineHeight: '20px',
        color: colorSystem.neutral[8],
    },
    h5: {
        fontFamily: POPPINS,
        fontWeight: 600,
        fontSize: '12px',
        lineHeight: '16px',
        color: colorSystem.neutral[8],
    },
    body: {
        fontFamily: NOTO_SANS,
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '20px',
        color: colorSystem.neutral[9],
    },
    'body-semibold': {
        fontFamily: NOTO_SANS,
        fontWeight: 600,
        fontSize: '14px',
        lineHeight: '20px',
        color: colorSystem.neutral[9],
    },
    'body-small': {
        fontFamily: NOTO_SANS,
        fontWeight: 400,
        fontSize: '12px',
        lineHeight: '16px',
        color: colorSystem.neutral[9],
    },
    'body-small-semibold': {
        fontFamily: NOTO_SANS,
        fontWeight: 600,
        fontSize: '12px',
        lineHeight: '16px',
        color: colorSystem.neutral[9],
    },
    caption: {
        fontFamily: NOTO_SANS,
        fontWeight: 400,
        fontSize: '10px',
        lineHeight: '12px',
        color: colorSystem.neutral[9],
    },
    code: {
        fontFamily: SOURCE_CODE_PRO,
        fontWeight: 500,
        fontSize: '14px',
        lineHeight: '24px',
        color: colorSystem.blue[6],
    },
    inherit: {},
};

export const ellipsisDefaultStyle: React.CSSProperties = {
    overflow: 'hidden',
    overflowWrap: 'break-word',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    wordBreak: 'break-all',
};

const TextWithEllipsisTooltip = ({ style, ...props }: Props) => {
    const [hasOverflowingChildren, setHasOverflowingChildren] = useState(false);
    const [text, setText] = useState<string | null>(null);
    const ref = useRef<HTMLDivElement>(null);

    const handleMouseEnter = React.useCallback(() => {
        if (!isPresent(ref.current)) {
            return;
        }

        const { offsetWidth, scrollWidth, textContent } = ref.current;
        const isOverflowing = offsetWidth < scrollWidth;

        setHasOverflowingChildren(isOverflowing);
        setText((prevText) => (prevText !== textContent ? textContent : prevText));
    }, [setHasOverflowingChildren, setText]);

    if (hasOverflowingChildren && isPresent(text)) {
        return (
            <Tooltip title={text}>
                <span
                    ref={ref}
                    style={{ ...ellipsisDefaultStyle, ...style }}
                    onMouseEnter={handleMouseEnter}
                    {...props}
                />
            </Tooltip>
        );
    }

    return <span ref={ref} style={{ ...ellipsisDefaultStyle, ...style }} onMouseEnter={handleMouseEnter} {...props} />;
};

export const Text = React.forwardRef<HTMLSpanElement, Props>(function Text(
    { variant = 'body', color = variantStyles[variant].color, showEllipsis, style, ...props },
    ref,
) {
    const Wrapper = showEllipsis ? TextWithEllipsisTooltip : 'span';

    return <Wrapper ref={ref} style={{ ...variantStyles[variant], color, ...style }} {...props} />;
});
