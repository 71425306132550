/* eslint-disable spellcheck/spell-checker */
import { Trans, t } from '@lingui/macro';
import { transEnum } from '@luminovo/commons';
import {
    Dropzone,
    FieldSelectControlled,
    Flexbox,
    FormItem,
    Message,
    Text,
    colorSystem,
} from '@luminovo/design-system';
import { Box, styled } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { depanelizationTranslations } from '../../../Settings/OrganizationSettings/pages/PcbPanelPreferencesPage/utils/i18n';
import { CollapsibleSection } from '../../components/CollapsibleSection';
import { PanelFormState } from '../utils/types';
import { PcbPanelNumberInput } from './PcbPanelNumberInput';

export const CustomPanelForm = ({ isEditing }: { isEditing: boolean }) => {
    const { control } = useFormContext<PanelFormState>();

    return (
        <Flexbox flexDirection="column" gap={16}>
            <Message
                variant="blue"
                attention="high"
                size="large"
                title={t`What is existing panel?`}
                message={t`The uploaded Gerber file is already configured as a shipping panel. Below you can specify its parameters.`}
                onClose={() => {}}
            />
            <Box style={{ borderRadius: '8px', backgroundColor: colorSystem.neutral.white }}>
                <CollapsibleSection label={t`Parameters`} ContentStyle={{ width: '92%' }}>
                    <Box
                        display={'grid'}
                        flexDirection={'column'}
                        gridTemplateColumns={'1fr 1fr'}
                        gap={'24px'}
                        width={'100%'}
                    >
                        <FormItem label={t`Number of PCBs X-axis`} LabelProps={{ variant: 'h5' }}>
                            <PcbPanelNumberInput
                                control={control}
                                // @ts-ignore
                                name="existingPanelData.maxNumberOfPcbsXAxis"
                                min={1}
                                endAdornmentText={t`boards`}
                                disabled={!isEditing}
                            />
                        </FormItem>
                        <FormItem label={t`Number of PCBs Y-axis`} LabelProps={{ variant: 'h5' }}>
                            <PcbPanelNumberInput
                                control={control}
                                // @ts-ignore
                                name="existingPanelData.maxNumberOfPcbsYAxis"
                                min={1}
                                endAdornmentText={t`boards`}
                                disabled={!isEditing}
                            />
                        </FormItem>
                        <FormItem label={t`Panel size X-axis`} LabelProps={{ variant: 'h5' }}>
                            <PcbPanelNumberInput
                                control={control}
                                // @ts-ignore
                                name="existingPanelData.panelSizeXAxis"
                                min={1}
                                disabled={!isEditing}
                            />
                        </FormItem>
                        <FormItem label={t`Panel size Y-axis`} LabelProps={{ variant: 'h5' }}>
                            <PcbPanelNumberInput
                                control={control}
                                // @ts-ignore
                                name="existingPanelData.panelSizeYAxis"
                                min={1}
                                disabled={!isEditing}
                            />
                        </FormItem>
                        <FormItem label={t`Depanelization type`} LabelProps={{ variant: 'h5' }}>
                            <FieldSelectControlled
                                control={control}
                                // @ts-ignore
                                name={'existingPanelData.depanelizationType'}
                                FieldProps={{
                                    options: ['Milling', 'VCut'],
                                    getOptionLabel: (x) => transEnum(x, depanelizationTranslations),
                                }}
                            />
                        </FormItem>
                        <FormItem
                            label={t`Panel specification file`}
                            LabelProps={{ variant: 'h5' }}
                            style={{ gridColumn: 'span 2' }}
                        >
                            <Text variant="caption">
                                <Trans>
                                    Please upload the panel specification file here. For any additional files, use the
                                    file manager.
                                </Trans>
                            </Text>
                            <Dropzone
                                title={''}
                                onDropAccepted={(files) => {}}
                                multiple={true}
                                accept={null}
                                overrides={{
                                    Container: DropzoneContainer,
                                    FileExtensionsInfo,
                                }}
                            />
                        </FormItem>
                    </Box>
                </CollapsibleSection>
            </Box>
        </Flexbox>
    );
};

const FileExtensionsInfo = () => {
    return (
        <Text variant="caption" style={{ textAlign: 'center', color: colorSystem.neutral[6] }}>
            <Trans>You can attach your PDF panel specification here</Trans>{' '}
        </Text>
    );
};

const DropzoneContainer = styled(Flexbox)({
    flexDirection: 'column',
    width: '100%',
});
