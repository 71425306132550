import { Trans } from '@lingui/macro';
import { difference } from '@luminovo/commons';
import { Chip, Flexbox, Text, TextField, colorSystem } from '@luminovo/design-system';
import { Cancel } from '@mui/icons-material';
import { Autocomplete, Box } from '@mui/material';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { alphaNumericalStringSort } from '../../../../../utils/stringFunctions';
import { BomItemFormState } from '../../BomItemFormState';
import { EditBomItemHeaderAction, EditBomItemHeaderState } from '../reducer';

export const DesignatorsField = React.memo(
    ({
        state,
        dispatch,
        siblingDesignators,
    }: {
        state: EditBomItemHeaderState;
        dispatch: React.Dispatch<EditBomItemHeaderAction>;
        siblingDesignators: string[];
    }) => {
        const { watch } = useFormContext<BomItemFormState>();
        const values = watch();
        const designatorsToRemove = difference(values.designator, state.designators);
        const designatorsToAdd = difference(state.designators, values.designator);

        return (
            <Flexbox flexDirection="column" gap="8px">
                <Autocomplete
                    multiple
                    freeSolo={true}
                    size="small"
                    limitTags={5}
                    value={state.designators.sort(alphaNumericalStringSort)}
                    renderTags={(value, getTagProps) =>
                        value.map((designator, index) => (
                            <Box marginRight="4px" key={index}>
                                <Chip
                                    {...getTagProps({ index })}
                                    label={designator}
                                    color={designatorsToAdd.includes(designator) ? 'green' : 'neutral'}
                                    deleteIcon={<Cancel style={{ color: colorSystem.primary[7] }} />}
                                    onDelete={() =>
                                        dispatch({ type: 'removeDesignator', designator, siblingDesignators })
                                    }
                                />
                            </Box>
                        ))
                    }
                    renderOption={(option): JSX.Element => <span>{option}</span>}
                    options={[]}
                    getOptionLabel={(option): string => option}
                    renderInput={(params): JSX.Element => (
                        <TextField
                            {...params}
                            size="small"
                            style={{ width: '324px' }}
                            error={state.errors.designators.isError}
                            helperText={state.errors.designators.message}
                        />
                    )}
                    // the following is needed to allow commaa as a delimiter https://github.com/mui-org/material-ui/issues/18563#issuecomment-826661485
                    autoSelect
                    onInputChange={(event, newInputValue) => {
                        //https://stackoverflow.com/a/42066698
                        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
                        const target = event.target as HTMLInputElement;
                        if (newInputValue.endsWith(',')) {
                            target.blur();
                            target.focus();
                        }
                    }}
                    onChange={(_, data): void => {
                        dispatch({ type: 'setDesignators', designators: data, siblingDesignators });
                    }}
                />
                {designatorsToRemove.length > 0 && (
                    <Flexbox alignItems="center" gap="4px">
                        <Text variant="body-small">
                            <Trans>To be deleted</Trans>&nbsp;
                        </Text>
                        {designatorsToRemove.map((designator: string, index: number) => (
                            <Chip
                                color="red"
                                label={designator}
                                key={index}
                                onDelete={() => {
                                    dispatch({
                                        type: 'addDesignator',
                                        designators: [designator],
                                        siblingDesignators,
                                    });
                                }}
                            />
                        ))}
                    </Flexbox>
                )}
            </Flexbox>
        );
    },
);
