import { addDays, calculateDaysBetweenDates } from '@luminovo/commons';
import { DemandResponseDTO } from '@luminovo/http-client';
import { formatNaiveDate } from '../../formatters';
import { QuantityKind } from './types';

interface DemandDistribution {
    [date: string]: number;
}

function distributeDemandEvenlyAcrossDays(totalDemand: number, startDate: Date, endDate: Date): DemandDistribution {
    const totalDays = calculateDaysBetweenDates(startDate, endDate, 'inclusive');
    const interval = totalDays / totalDemand;
    const demandDistribution: DemandDistribution = {};

    for (let i = 0; i < totalDays; i++) {
        const currentDate = addDays(i, startDate);
        demandDistribution[formatNaiveDate(currentDate.toString())] = 0;
    }

    for (let i = 0; i < totalDemand; i++) {
        const dayNumber = Math.min(Math.floor(i * interval), totalDays - 1);
        const demandDate = formatNaiveDate(addDays(dayNumber, startDate).toString());
        demandDistribution[demandDate] += 1;
    }

    return demandDistribution;
}

export const splitDemandIntoDays = (demand: DemandResponseDTO, quantityType: QuantityKind): DemandResponseDTO[] => {
    const totalQuantity = demand[quantityType].quantity;
    const startDate = new Date(demand.delivery_start_date);
    const endDate = new Date(demand.delivery_end_date);

    if (isNaN(startDate.getTime()) || isNaN(endDate.getTime()) || startDate > endDate) {
        throw new Error('Invalid date range');
    }

    const demandDistribution = distributeDemandEvenlyAcrossDays(totalQuantity, startDate, endDate);
    const res = Object.keys(demandDistribution).map((date) => ({
        ...demand,
        [quantityType]: {
            ...demand[quantityType],
            quantity: demandDistribution[date],
        },
        delivery_start_date: date,
        delivery_end_date: date,
    }));
    return res;
};
