import { Trans } from '@lingui/macro';
import React from 'react';
import { Text } from '../../Text';
import { DestructivePrimaryButton, SecondaryButton } from '../../buttons';
import { Dialog } from '../Dialog';
import { DialogActions } from '../DialogActions';
import { DialogContent } from '../DialogContent';
import { DialogTitle } from '../DialogTitle';

export function useConfirmationDialog({
    title,
    description,
    onConfirmation,
}: {
    onConfirmation: () => void;
    title: string;
    description: string;
}): { modal: JSX.Element; setOpen: React.Dispatch<React.SetStateAction<boolean>>; open: boolean } {
    const [open, setOpen] = React.useState(false);

    const modal = (
        <Dialog maxWidth="xs" open={open} onClose={() => setOpen(false)}>
            <DialogTitle title={title} handleClose={() => setOpen(false)} />
            <DialogContent>
                <Text>{description}</Text>
            </DialogContent>
            <DialogActions>
                <SecondaryButton autoFocus size="medium" onClick={() => setOpen(false)}>
                    <Trans>Cancel</Trans>
                </SecondaryButton>
                <DestructivePrimaryButton
                    size="medium"
                    onClick={() => {
                        setOpen(false);
                        onConfirmation();
                    }}
                >
                    <Trans>Yes, I'm sure</Trans>
                </DestructivePrimaryButton>
            </DialogActions>
        </Dialog>
    );

    return {
        modal,
        setOpen,
        open,
    };
}
