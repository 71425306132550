import { BaseParser, ParseResult, Parser } from './Parser';

export interface NumberProps {
    decimalSeparator: '.' | ',';

    warnIf?: {
        isFloat?: boolean;
        isNegative?: boolean;
    };

    errorIf?: {
        isNegative?: boolean;
    };
}

export function number(props: NumberProps): Parser<number> {
    return BaseParser.of((input): ParseResult<number> => {
        return parseNumber(input, props);
    });
}

// When changing this regex, I suggest testing it out here: https://myregexp.com/
const regexCommaSeparator = /^[+-]?\d{1,3}((\.\d{3})|\d+)*(,\d*)?([eE][+-]?\d+)?/;
const regexDotSeparator = /^[+-]?\d{1,3}(([,]\d{3})|\d+)*(\.\d*)?([eE][+-]?\d+)?/;

export function parseNumber(input: string, { decimalSeparator, warnIf, errorIf }: NumberProps): ParseResult<number> {
    if (input.length === 0) {
        return {
            ok: false,
            expected: 'a non empty string',
        };
    }

    const regexPattern = decimalSeparator === '.' ? regexDotSeparator : regexCommaSeparator;

    const match = input.match(regexPattern);

    if (!match) {
        return {
            ok: false,
            expected: 'a number',
        };
    }

    const matchedNumber = match[0];

    const thousandsSeparator = decimalSeparator === '.' ? ',' : '.';

    const value = parseFloat(matchedNumber.replaceAll(thousandsSeparator, '').replaceAll(decimalSeparator, '.'));

    let message: string | undefined = undefined;
    if (warnIf?.isFloat && !Number.isInteger(value)) {
        message = `The number ${value} is not an integer.`;
    }

    if (warnIf?.isNegative && value < 0) {
        message = `The number ${value} is negative.`;
    }

    if (errorIf?.isNegative && value < 0) {
        return {
            ok: false,
            expected: 'a positive number',
        };
    }

    return {
        ok: true,
        value,
        rest: input.slice(matchedNumber.length),
        message,
    };
}
