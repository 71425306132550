import { Trans } from '@lingui/macro';
import { Flexbox, Tab, Tabs, Text } from '@luminovo/design-system';
import { BomItemApprovalStatus } from '@luminovo/http-client';
import { styled } from '@mui/material';
import React from 'react';
import { iconForBomItemApprovalStatusWithTooltip } from '../../../components/Icons/icons';
import { id } from '../../../utils/ids';
import { ModuleTableData, groupModuleTableDataByStatus } from '../../Bom/components/ModuleTableData';
type StatusFiltersProps = {
    selectedStatus?: BomItemApprovalStatus;
    items: ModuleTableData[];
    onSelectStatus: (status: BomItemApprovalStatus | undefined) => void;
};

const statuses = [
    {
        id: id('design/button_filter_by_all'),
        status: undefined,
        visible: true,
    },
    {
        id: id('design/button_filter_by_approved'),
        status: BomItemApprovalStatus.Approved,
        visible: true,
    },
    {
        id: id('design/button_filter_by_dnp'),
        status: BomItemApprovalStatus.DNP,
        visible: true,
    },
    {
        id: id('design/button_filter_by_pending'),
        status: BomItemApprovalStatus.Pending,
        visible: true,
    },
    {
        id: id('design/button_filter_by_rejected'),
        status: BomItemApprovalStatus.Rejected,
        visible: true,
    },
];
export function StatusFilters({ selectedStatus, items, onSelectStatus }: StatusFiltersProps) {
    const bomItemsByStatus = React.useMemo(() => groupModuleTableDataByStatus(items), [items]);

    return (
        <Flexbox flexDirection="column">
            <StyledTabs size="small" value={selectedStatus ?? 'All'}>
                {statuses
                    .filter(({ visible }) => visible)
                    .map(({ status, id }, index) => {
                        return (
                            <Tab
                                id={id}
                                onClick={() => onSelectStatus(status)}
                                key={`${status}-${index}`}
                                value={status ?? 'All'}
                                icon={
                                    status ? (
                                        iconForBomItemApprovalStatusWithTooltip(status)
                                    ) : (
                                        <Text variant="h4">
                                            <Trans>All</Trans>
                                        </Text>
                                    )
                                }
                                label={
                                    <Text
                                        variant="h4"
                                        color={'inherit'}
                                        style={{
                                            overflow: 'hidden',
                                            whiteSpace: 'nowrap',
                                            textOverflow: 'ellipsis',
                                            maxWidth: '30px',
                                        }}
                                    >
                                        {status ? bomItemsByStatus[status]?.length : items.length}
                                    </Text>
                                }
                            />
                        );
                    })}
            </StyledTabs>
        </Flexbox>
    );
}

const StyledTabs = styled(Tabs)({
    '& .MuiTabs-flexContainer': {
        gap: '12px',
        justifyContent: 'space-between',
        width: '100%',
    },
});
