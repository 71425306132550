import { Trans, plural, t } from '@lingui/macro';
import { uniqBy } from '@luminovo/commons';
import {
    Flexbox,
    Tag,
    TanStackTable,
    Text,
    colorSystem,
    createColumnHelper,
    useTanStackTable,
} from '@luminovo/design-system';
import {
    CustomComponentFull,
    CustomFullPart,
    CustomOptionOfferDTO,
    GenericFullPart,
    IpnWithMatchesFullPart,
    OtsFullPart,
    QuoteTrackingDTO,
    StandardPartOfferDTO,
} from '@luminovo/http-client';
import { LabelPart, formatPart } from '@luminovo/sourcing-core';
import React from 'react';
import { useQuoteTrackings } from '../../../../../resources/quoteTracking/quoteTrackingHandler';
import { QuoteTrackingMetricSettings } from '../QuoteTrackingMetricCards';

type QuotedCustomPartOfferTableItem = {
    requestedPart: OtsFullPart | IpnWithMatchesFullPart | GenericFullPart | CustomFullPart | CustomComponentFull;
    quoteOffers: (StandardPartOfferDTO | CustomOptionOfferDTO)[];
    otherOffers: (StandardPartOfferDTO | CustomOptionOfferDTO)[];
    otherRequestedParts: (
        | OtsFullPart
        | IpnWithMatchesFullPart
        | GenericFullPart
        | CustomFullPart
        | CustomComponentFull
    )[];
};

type QuotedCustomPartOfferSharedContext = {
    metricSettings: QuoteTrackingMetricSettings;
};

const columnHelper = createColumnHelper<QuotedCustomPartOfferTableItem, QuotedCustomPartOfferSharedContext>();

const columns = [
    columnHelper.enum((row) => formatPart(row.requestedPart), {
        id: 'part',
        size: 180,
        label: () => t`Part`,
        getOptionLabel: (option) => option,
        cell: ({ row }) => <LabelPart part={row.original.requestedPart} />,
    }),
    columnHelper.number((row) => Number(row.quoteOffers.length), {
        id: 'quoteStatus',
        size: 100,
        label: () => t`Quote status`,
        align: 'left',
        cell: ({ getValue }) => {
            if (getValue() === 0) {
                return (
                    <Text color={colorSystem.neutral[6]}>
                        <Trans>No offers</Trans>
                    </Text>
                );
            }
            return (
                <Tag
                    attention={'low'}
                    color="green"
                    label={plural(getValue(), {
                        one: `${getValue()} offer received`,
                        other: `${getValue()} offers received`,
                    })}
                />
            );
        },
    }),
    columnHelper.number((row) => row.otherRequestedParts.length, {
        id: 'otherRequestedParts',
        size: 180,
        label: () => t`Other quotes`,
        align: 'left',
        cell: ({ getValue, row }) => {
            if (getValue() === 0) {
                return (
                    <Text color={colorSystem.neutral[6]}>
                        <Trans>No offers</Trans>
                    </Text>
                );
            }

            const pendingRequests = row.original.otherRequestedParts.length - row.original.otherOffers.length;
            return (
                <Flexbox gap={'4px'}>
                    {/* Check if there are any offers or adjusted pending requests and build sentences accordingly */}
                    {(row.original.otherOffers.length > 0 || pendingRequests > 0) && (
                        <Text color={colorSystem.neutral[6]}>
                            {row.original.otherOffers.length > 0 &&
                                plural(row.original.otherOffers.length, {
                                    one: `${row.original.otherOffers.length} offer received`,
                                    other: `${row.original.otherOffers.length} offers received`,
                                })}

                            {/* Add a separator if both conditions are true */}
                            {row.original.otherOffers.length > 0 && pendingRequests > 0 ? ' and ' : ''}

                            {/* Plural support for requests pending */}
                            {pendingRequests > 0 &&
                                plural(pendingRequests, {
                                    one: `${pendingRequests} request pending`,
                                    other: `${pendingRequests} requests pending`,
                                })}
                        </Text>
                    )}
                </Flexbox>
            );
        },
    }),
];

export const RequestedPartsTab: React.FunctionComponent<{
    quoteTracking: QuoteTrackingDTO;
    metricSettings: QuoteTrackingMetricSettings;
}> = ({ quoteTracking, metricSettings }) => {
    const { data: quoteTrackingDTOs = [], isLoading } = useQuoteTrackings(quoteTracking.rfq);

    const otherQuoteTrackings = quoteTrackingDTOs?.filter(
        (quoteTrackingDTO) => quoteTrackingDTO.id !== quoteTracking.id,
    );
    const currentAllOffer = uniqBy(
        [...quoteTracking.standard_part_offers, ...quoteTracking.custom_part_offers],
        (q) => q.id,
    );
    const currentRequestedParts = uniqBy(
        [...quoteTracking.request_standard_parts, ...quoteTracking.request_custom_parts],
        (q) => q.kind.id,
    );

    const otherAllOffer = otherQuoteTrackings.flatMap((quoteTrackingDTO) =>
        uniqBy([...quoteTrackingDTO.standard_part_offers, ...quoteTrackingDTO.custom_part_offers], (q) => q.id),
    );

    const otherAllRequestedParts = otherQuoteTrackings.flatMap((quoteTrackingDTO) =>
        uniqBy(
            [...quoteTrackingDTO.request_standard_parts, ...quoteTrackingDTO.request_custom_parts],
            (q) => q.kind.id,
        ),
    );

    // TODO something is broken here, the requested parts are not being filtered correctly
    const data: QuotedCustomPartOfferTableItem[] = currentRequestedParts.map(
        (requestedPart): QuotedCustomPartOfferTableItem => {
            const quoteOffers = currentAllOffer.filter((offer) => requestedPart.kind.id === offer.linked_part.id);
            const otherOffers = otherAllOffer.filter((offer) => requestedPart.kind.id === offer.linked_part.id);
            const otherRequestedParts = otherAllRequestedParts
                .filter((part) => requestedPart.kind.id === part.kind.id)
                .map((p) => p.kind);
            return { requestedPart: requestedPart.kind, quoteOffers, otherOffers, otherRequestedParts };
        },
    );

    const { table } = useTanStackTable({
        columns,
        data,
        isLoading,
        sharedContext: { metricSettings },
    });

    return (
        <Flexbox flexDirection={'column'} gap={'12px'} height={'65vh'}>
            <TanStackTable table={table} size={'medium'} />
        </Flexbox>
    );
};
