import { uniqBy } from '@luminovo/commons';
import { AssemblyDTO, DescendantsDTO, DesignItemOriginTypes, QuantityUnit } from '@luminovo/http-client';
import { AssemblyTableData, BomItemTableData, ModuleTableData, isAssemblyTableData, isBomItemTableData } from '.';
import { BomItem } from '../../../../resources/designItem/bomItemFrontendTypes';
import { alphaNumericalStringSort } from '../../../../utils/stringFunctions';
import { getAllBomItemFilters } from './filters';

type BomNestedTableProps = {
    assemblyId: string;
    bomItems: { bomItems?: BomItem[]; descendants?: DescendantsDTO };
    rfqId?: string;
};
export const sortAssemblyTableData = (data: ModuleTableData[]) => {
    const dataSubassemblies: AssemblyTableData[] = data.filter(isAssemblyTableData);
    const dataManualOrigin: BomItemTableData[] = data
        .filter(isBomItemTableData)
        .filter((c) => c.origin.type === DesignItemOriginTypes.Manual);
    const dataExcelOrigin: BomItemTableData[] = data
        .filter(isBomItemTableData)
        .filter((c) => c.origin.type === DesignItemOriginTypes.ExcelFile);
    const dataPCBOrigin: BomItemTableData[] = data
        .filter(isBomItemTableData)
        .filter((c) => c.origin.type === DesignItemOriginTypes.PCB);

    const dataAssemblyImportOrigin: BomItemTableData[] = data
        .filter(isBomItemTableData)
        .filter((c) => c.origin.type === DesignItemOriginTypes.AssemblyImport);

    const sortedDataSubassemblies = dataSubassemblies.sort((d1, d2) =>
        alphaNumericalStringSort(d1.designator, d2.designator),
    );

    const sortedDataManualOrigin = dataManualOrigin.sort((d1, d2) =>
        // Designator is guaranteed to have at least 1 element here, as a BOM item has to have at least 1 designator.
        alphaNumericalStringSort(d1.designator[0], d2.designator[0]),
    );

    const sortedDataExcelOrigin = dataExcelOrigin.sort((d1, d2) => {
        if (d1.origin.originalRowNumber !== undefined && d2.origin.originalRowNumber !== undefined) {
            return d1.origin.originalRowNumber > d2.origin.originalRowNumber ? 1 : -1;
        }
        return alphaNumericalStringSort(d1.designator[0], d2.designator[0]);
    });

    const concatenatedArray: ModuleTableData[] = [
        ...sortedDataSubassemblies,
        ...dataPCBOrigin,
        ...sortedDataManualOrigin,
        ...sortedDataExcelOrigin,
        ...dataAssemblyImportOrigin,
    ];
    return uniqBy(concatenatedArray, (s) => s.id);
};
export const sortModuleTableData = (
    assemblyBomItems: ModuleTableData[],
    assemblyModules: ModuleTableData[],
): ModuleTableData[] => {
    const modules = assemblyBomItems.concat(assemblyModules);
    return sortAssemblyTableData(modules);
};
const convertAssemblyToModule = (assembly: AssemblyDTO): ModuleTableData => {
    return {
        id: assembly.id,
        notes: assembly.notes || '',
        rfqId: assembly.rfq,
        type: assembly.type.type,
        designator: assembly.designator,
        quantity: {
            quantity: 1,
            unit: QuantityUnit.Pieces,
        },
        subAssemblies: assembly.subassemblies.items.map((subAssembly) => subAssembly.assembly_id),
        designItems: assembly.design_items.items,
        origin: {
            type: DesignItemOriginTypes.Manual,
            bomFileIds: undefined,
            originalRowNumber: undefined,
            originalRowNumbers: [],
        },
        approvalStatus: assembly.approval_status,
        moduleType: 'assembly',
        reachCompliant: assembly.reach_compliant,
        rohsCompliant: assembly.rohs_compliant,
        aecqCompliant: assembly.aecq_compliant,
        lifecycleStatus: assembly.lifecycle_status,
        availability: assembly.availability,
        yearsToEndOfLife: assembly.years_to_end_of_life,
        emissionData: assembly.emission_data,
    };
};
const convertAssembliesToModules = (assemblies: AssemblyDTO[]): ModuleTableData[] => {
    return assemblies.map((assembly) => {
        return convertAssemblyToModule(assembly);
    });
};
/**
 * Extracts BOM item data from BOM items and descendants to become data table items
 * @param {object} BOMData - Data needed to be filtered/updated to become the bom table items
 * @returns items used for the bom data table
 */
export const getBomNestedTableItems = ({
    assemblyId,
    bomItems: { bomItems, descendants },
    rfqId,
}: BomNestedTableProps): ModuleTableData[] => {
    const assemblyBomItems: BomItem[] = bomItems ? bomItems.filter((m) => m.parentId === assemblyId) : [];
    const assemblyModules: ModuleTableData[] = descendants
        ? convertAssembliesToModules(
              descendants.assemblies.filter((assembly) => assemblyId in assembly.parents), // only show direct sub assemblies,
          )
        : [];

    const isAutomotiveAssembly = descendants ? descendants.parent.industry === 'Auto' : false;
    const bomItemModules = convertBomItemsToModuleTableData(assemblyBomItems, isAutomotiveAssembly, rfqId);

    return sortModuleTableData(bomItemModules, assemblyModules);
};

export function convertBomItemsToModuleTableData(
    bomItems: BomItem[],
    isAutomotiveAssembly: boolean,
    rfqId?: string,
): ModuleTableData[] {
    return bomItems.map((bomItem) => {
        const filterIds = getAllBomItemFilters(bomItem, { isAutomotiveAssembly });
        return {
            ...bomItem,
            moduleType: 'bomItem',
            rfqId,
            filterIds,
        };
    });
}
