import { t } from '@lingui/macro';
import { getLocale } from '../i18n';

const SECONDS_IN_MINUTE = 60;
const SECONDS_IN_HOUR = 3600;
const SECONDS_IN_DAY = 86400;

const enRelativeTimeFormat = new Intl.RelativeTimeFormat('en', { numeric: 'auto' });
const deRelativeTimeFormat = new Intl.RelativeTimeFormat('de', { numeric: 'auto' });
const esRelativeTimeFormat = new Intl.RelativeTimeFormat('es', { numeric: 'auto' });
const frRelativeTimeFormat = new Intl.RelativeTimeFormat('fr', { numeric: 'auto' });
function getRelativeTimeFormat(): Intl.RelativeTimeFormat {
    switch (getLocale()) {
        case 'en':
            return enRelativeTimeFormat;
        case 'de':
            return deRelativeTimeFormat;
        case 'es':
            return esRelativeTimeFormat;
        case 'fr':
            return frRelativeTimeFormat;
    }
}

function roundDiff(value: number): number {
    if (isPastDiff(value) || isFutureDiff(value)) {
        return Math.ceil(value);
    }

    return 0;
}

function isPastDiff(value: number): boolean {
    return value <= -1;
}

function isFutureDiff(value: number): boolean {
    return value >= 1;
}

function isNonZeroDiff(value: number): boolean {
    return isPastDiff(value) || isFutureDiff(value);
}

/**
 *  * Formats a date as a relative time string, such as "5 minutes ago" or "in 3 day".
 */
export function formatRelativeTime(input: Date | string): string {
    const today = new Date();
    const date = new Date(input);
    const diffTime = date.getTime() - today.getTime();
    const diffSeconds = roundDiff(diffTime / 1000);
    const diffDays = roundDiff(diffSeconds / SECONDS_IN_DAY);
    const diffHours = roundDiff(diffSeconds / SECONDS_IN_HOUR);
    const diffMinutes = roundDiff(diffSeconds / SECONDS_IN_MINUTE);

    if (isNonZeroDiff(diffDays)) {
        return getRelativeTimeFormat().format(diffDays, 'day');
    }

    if (isNonZeroDiff(diffHours)) {
        return getRelativeTimeFormat().format(diffHours, 'hour');
    }

    if (isNonZeroDiff(diffMinutes)) {
        return getRelativeTimeFormat().format(diffMinutes, 'minute');
    }

    if (isPastDiff(diffSeconds)) {
        return t`a few seconds ago`;
    }

    if (isFutureDiff(diffSeconds)) {
        return t`in a few seconds`;
    }

    return t`just now`;
}
