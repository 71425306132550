import {
    CompanyRole,
    QuantityKind,
    SupplierOrRecipientQuantity,
    TableDemand,
    Timeframe,
    groupDemandBySupplierOrRecipientAndTimeframe,
    selectTotalQuantityFromDemands,
    selectTotalSupplierOrRecipientQuantitiesFromDemands,
} from '@luminovo/manufacturing-core';
import { useHttpQuery } from '../http/useHttpQuery';

export interface DemandData {
    totalQuantity: number;
    supplierOrRecipientQuantities: SupplierOrRecipientQuantity[];
    supplierOrRecipientQuantitiesByMonthYear: TableDemand[];
}

export const useDemandData = ({
    assemblyId,
    quantityType,
    timeframe,
    company,
}: {
    assemblyId: string;
    quantityType: QuantityKind;
    timeframe: Timeframe;
    company: CompanyRole;
}) => {
    return useHttpQuery(
        'POST /demand/export',
        {
            requestBody: {
                ipns: [assemblyId],
            },
        },
        {
            select: (data): DemandData => {
                return {
                    totalQuantity: selectTotalQuantityFromDemands(data.demands, quantityType),
                    supplierOrRecipientQuantities: selectTotalSupplierOrRecipientQuantitiesFromDemands(
                        data.demands,
                        quantityType,
                        company,
                    ),
                    supplierOrRecipientQuantitiesByMonthYear: groupDemandBySupplierOrRecipientAndTimeframe({
                        demands: data.demands,
                        quantityType,
                        timeFrame: timeframe,
                        company,
                    }),
                };
            },
        },
    );
};
