import { t, Trans } from '@lingui/macro';
import { assertUnreachable, formatDays } from '@luminovo/commons';
import { chainComparators, compareByNumber } from '@luminovo/design-system';
import { AvailabilityType } from '@luminovo/http-client';
import { formatAvailabilityType } from '@luminovo/sourcing-core';
import { HorizontalStackedBarChart, palette } from '@luminovo/viz';
import { ChartSpec, defaultGroupingFunctions } from './ChartSpec';

type Keys = 'stock' | 'leadTime' | 'onOrder' | 'unknown' | 'consigned' | 'noOffers';
type Datum = { label: string; id: string[] } & Record<Keys, number>;

const keys: Keys[] = ['leadTime', 'stock', 'onOrder', 'unknown', 'consigned', 'noOffers'];

export const chartSpecLeadTime: ChartSpec<Keys, Datum> = {
    id: 'leadTime',
    title: <Trans>Lead time by</Trans>,
    keys,
    aggregate(a, b) {
        return {
            id: a.id.concat(b.id),
            label: a.label,
            stock: Math.max(a.stock, b.stock),
            leadTime: Math.max(a.leadTime, b.leadTime),
            noOffers: Math.max(a.noOffers, b.noOffers),
            onOrder: Math.max(a.onOrder, b.onOrder),
            consigned: Math.max(a.consigned, b.consigned),
            unknown: Math.max(a.unknown, b.unknown),
        };
    },
    groupBy: defaultGroupingFunctions,
    map: (datum, extractLabel): Datum => {
        const id = [datum.solutionConfigurationSourcing.id];
        const label = extractLabel(datum);
        if (datum.solutionConfigurationSourcing.is_consigned) {
            return {
                id,
                label,
                consigned: 1,
                stock: 0,
                leadTime: 0,
                noOffers: 0,
                onOrder: 0,
                unknown: 0,
            };
        }
        if (!datum.solution) {
            return {
                id,
                label,
                noOffers: 1,
                consigned: 0,
                leadTime: 0,
                onOrder: 0,
                stock: 0,
                unknown: 0,
            };
        }

        const availability = datum.solution?.availability;
        const shippingTimeInDays = availability?.shipping_time_in_days ?? 0;

        if (!availability || availability.type === AvailabilityType.OnOrderWithUnknownLeadTime) {
            return {
                id,
                label,
                noOffers: 0,
                consigned: 0,
                stock: 0,
                leadTime: 0,
                onOrder: 0,
                unknown: 1,
            };
        }
        if (
            availability.type === AvailabilityType.LeadTime ||
            availability.type === AvailabilityType.PriceBreakLeadTime
        ) {
            return {
                id,
                label,
                noOffers: 0,
                consigned: 0,
                stock: 0,
                leadTime: availability.days + shippingTimeInDays,
                onOrder: 0,
                unknown: 0,
            };
        }
        if (availability.type === AvailabilityType.Stock) {
            return {
                id,
                label,
                noOffers: 0,
                consigned: 0,
                stock: 1,
                leadTime: 0 + shippingTimeInDays,
                onOrder: 0,
                unknown: 0,
            };
        }
        if (availability.type === AvailabilityType.OnOrder) {
            return {
                id,
                label,
                noOffers: 0,
                consigned: 0,
                stock: 0,
                leadTime: 0,
                onOrder: availability.days + shippingTimeInDays,
                unknown: 0,
            };
        }
        assertUnreachable(availability);
    },
    orderBy: chainComparators(
        compareByNumber((item) => -item.leadTime),
        compareByNumber((item) => -item.onOrder),
        compareByNumber((item) => -item.stock),
        compareByNumber((item) => -item.unknown),
        compareByNumber((item) => item.noOffers),
        compareByNumber((item) => item.consigned),
    ),
    render: ChartLeadTime,
};

function ChartLeadTime({ data, onSelectDatum }: { data: Datum[]; keys: Keys[]; onSelectDatum(datum: Datum): void }) {
    return (
        <HorizontalStackedBarChart<Keys, Datum>
            keys={keys}
            data={data}
            formatValue={(x) => formatDays(x)}
            formatKey={(key) => {
                if (key === 'noOffers') {
                    return t`No offers`;
                }
                if (key === 'consigned') {
                    return t`Consigned`;
                }
                if (key === 'leadTime') {
                    return formatAvailabilityType(AvailabilityType.LeadTime);
                }
                if (key === 'onOrder') {
                    return formatAvailabilityType(AvailabilityType.OnOrder);
                }
                if (key === 'stock') {
                    return formatAvailabilityType(AvailabilityType.Stock);
                }
                if (key === 'unknown') {
                    return t`Unknown`;
                }
                assertUnreachable(key);
            }}
            isMissingData={(datum, key: Keys) => {
                if (datum.noOffers > 0 && key === 'noOffers') {
                    return true;
                }
                if (datum.consigned && key === 'consigned') {
                    return true;
                }
                if (datum.stock && key === 'stock') {
                    return true;
                }
                if (datum.unknown && key === 'unknown') {
                    return true;
                }
                return false;
            }}
            getColor={getColor}
            onBarClick={onSelectDatum}
            width={800}
        />
    );
}

const getColor = (key: Keys): string => {
    if (key === 'noOffers') {
        return palette.error.high;
    }
    if (key === 'consigned') {
        return palette.ok.high;
    }
    if (key === 'stock') {
        return palette.default[2];
    }
    if (key === 'leadTime') {
        return palette.default[0];
    }
    if (key === 'onOrder') {
        return palette.other[0];
    }
    if (key === 'unknown') {
        return palette.error.medium;
    }

    assertUnreachable(key);
};
