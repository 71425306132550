import { ToggleButtonGroupProps } from '@mui/lab';
import { ToggleButtonGroup, styled } from '@mui/material';
import { forwardRef } from 'react';
import { colorSystem } from '../../theme';

const DefaultToggleButton = styled(ToggleButtonGroup)({
    height: 32,
    backgroundColor: colorSystem.neutral[2],
    padding: 4,
    gap: 4,
    boxSizing: 'border-box',
});

export const ToggleButton = forwardRef(function ToggleButton(
    { ...props }: ToggleButtonGroupProps,
    ref: React.ForwardedRef<unknown>,
): JSX.Element {
    return (
        <DefaultToggleButton ref={ref} {...props}>
            {props.children}
        </DefaultToggleButton>
    );
});
