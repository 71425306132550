import { DisplayableColumnIds } from '@luminovo/http-client';
import { assertUnreachable } from '../../../../utils/typingUtils';
import { ColumnTag, ColumnTagsSheetsAction, ColumnTagsSheetsState, TaggedSheet } from './types';

const uniquelyAssignableTags: DisplayableColumnIds[] = ['quantity', 'designators'];

function updateSheetTags(
    sheets: TaggedSheet[],
    sheetIndex: number,
    label: string,
    mapper: (tag: ColumnTag) => ColumnTag,
): TaggedSheet[] {
    const updatedSheets = [...sheets];
    updatedSheets[sheetIndex].tags = sheets[sheetIndex].tags.map((tag) => {
        if (tag.label !== label) {
            return tag;
        }

        return mapper(tag);
    });
    return updatedSheets;
}

export function isTagAssignedToColumn(tag: ColumnTag, columnId: string) {
    return tag.associatedTo.some((col) => col.id === columnId);
}

export function isTagSelectedByOtherColumn(tag: ColumnTag, columnId: string) {
    if (!uniquelyAssignableTags.includes(tag.id)) {
        return undefined;
    }
    return tag.associatedTo.find((col) => col.id !== columnId);
}
export function getSelectedOptions(state: ColumnTagsSheetsState, columnId: string) {
    return state.sheets[state.selectedSheet].tags.filter((tag) => isTagAssignedToColumn(tag, columnId));
}

export function reducer(state: ColumnTagsSheetsState, action: ColumnTagsSheetsAction): ColumnTagsSheetsState {
    if (action.type === 'assign-tag') {
        const incomingTag = action.tag;
        const sheets = updateSheetTags(state.sheets, state.selectedSheet, incomingTag.label, (tag) => {
            return {
                ...tag,
                associatedTo: [...tag.associatedTo, action.column],
            };
        });

        return { ...state, sheets, hasChanged: true };
    }
    if (action.type === 'remove-tag') {
        const incomingTag = action.tag;
        const sheets = updateSheetTags(state.sheets, state.selectedSheet, incomingTag.label, (tag) => {
            return {
                ...tag,
                associatedTo: tag.associatedTo.filter((col) => col.id !== action.column.id),
            };
        });
        return { ...state, sheets, hasChanged: true };
    }
    if (action.type === 'set-initial-state') {
        return action.state;
    }
    if (action.type === 'set-current-sheet') {
        return {
            ...state,
            selectedSheet: action.index,
            hasChanged: true,
        };
    }
    if (action.type === 'display-column-mapping') {
        return state;
    }
    assertUnreachable(action);
}
