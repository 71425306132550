import { t } from '@lingui/macro';
import { id } from '@luminovo/commons';
import {
    Flexbox,
    StickyLayout,
    TertiaryIconButton,
    Text,
    colorSystem,
    usePersistedState,
} from '@luminovo/design-system';
import { PCBV2, UserType } from '@luminovo/http-client';
import {
    BorderOuterRounded,
    DescriptionRounded,
    FolderRounded,
    HdrWeakRounded,
    KeyboardTabRounded,
    LayersRounded,
    Memory,
} from '@mui/icons-material';
import { useLocation } from 'react-router';
import { useUserType } from '../../../../components/contexts/CurrentUserDetailsContext';
import {
    PCBTabTypes,
    expandPcbSidebarAnalytics,
    pcbTabChangeAnalytics,
} from '../../../../resources/pcb/analytics/analytic';
import { isPcbSetupWithoutFiles } from '../../../../resources/pcb/pcbFunctions';
import { findRouteId, route } from '../../../../utils/routes';
import { ViewContext } from '../../../Bom/components/ModuleTableData';
import { useShowPcbAnalysisNotification } from '../PcbAnalysis';
import { SidebarLink } from './components/SidebarLink';

export function PcbSidebar({
    assemblyId,
    rfqId,
    pcb,
    viewContext,
}: {
    assemblyId: string;
    rfqId: string;
    pcb: PCBV2;
    viewContext: ViewContext;
}) {
    const { pathname } = useLocation();
    const [showPcbSidebar, setShowPcbSidebar] = usePersistedState<'true' | 'false'>(
        'pcb-side-bar',
        'false',
        localStorage,
    );

    const { showNotificationIcon: showPcbAnalysisNotification, setShowNotification } =
        useShowPcbAnalysisNotification(pcb);

    const routeId = findRouteId(pathname);

    const isWithoutFiles = isPcbSetupWithoutFiles(pcb);

    const userType = useUserType();
    const isCustomer = userType === UserType.Customer;

    const isOpen = showPcbSidebar === 'true';
    const sidebarWidth = isOpen ? '200px' : '40px';

    const handleTogglePcbSidebar = () => {
        if (isOpen) {
            setShowPcbSidebar('false');
            return;
        }

        setShowPcbSidebar('true');
        expandPcbSidebarAnalytics({ assemblyId, pcbId: pcb.id, rfqId });
    };

    const onClick = (tab: PCBTabTypes) => {
        pcbTabChangeAnalytics({ pcbId: pcb.id, rfqId, assemblyId }, tab);
    };

    const isCadViewerLinkActive =
        routeId === '/rfqs/:rfqId/bom/assembly/:assemblyId/pcb/cad-viewer' ||
        routeId === '/assemblies/:assemblyId/pcb/cad-viewer';

    const cadViewerLink =
        viewContext.type === 'WithinRfQ'
            ? route('/rfqs/:rfqId/bom/assembly/:assemblyId/pcb/cad-viewer', { rfqId, assemblyId })
            : route('/assemblies/:assemblyId/pcb/cad-viewer', { assemblyId }, { rfqId });

    const isPCBSPecificationLinkActive =
        routeId === '/rfqs/:rfqId/bom/assembly/:assemblyId/pcb/specification' ||
        routeId === '/assemblies/:assemblyId/pcb/specification';

    const pcbSpecificationLink =
        viewContext.type === 'WithinRfQ'
            ? route('/rfqs/:rfqId/bom/assembly/:assemblyId/pcb/specification', { rfqId, assemblyId })
            : route('/assemblies/:assemblyId/pcb/specification', { assemblyId }, { rfqId });

    const isPcbStackUpLinkActive =
        routeId === '/rfqs/:rfqId/bom/assembly/:assemblyId/pcb/stackup' ||
        routeId === '/assemblies/:assemblyId/pcb/stackup';

    const pcbStackUpLink =
        viewContext.type === 'WithinRfQ'
            ? route('/rfqs/:rfqId/bom/assembly/:assemblyId/pcb/stackup', { rfqId, assemblyId })
            : route('/assemblies/:assemblyId/pcb/stackup', { assemblyId }, { rfqId });

    const isPcbCadViewerLinkActive =
        routeId === '/rfqs/:rfqId/bom/assembly/:assemblyId/pcb/cad-viewer' ||
        routeId === '/assemblies/:assemblyId/pcb/cad-viewer';

    const pcbCadViewerLink =
        viewContext.type === 'WithinRfQ'
            ? route('/rfqs/:rfqId/bom/assembly/:assemblyId/pcb/cad-viewer', { rfqId, assemblyId })
            : route('/assemblies/:assemblyId/pcb/cad-viewer', { assemblyId }, { rfqId });

    const isPcbFileLinkActive =
        routeId === '/rfqs/:rfqId/bom/assembly/:assemblyId/pcb/files' ||
        routeId === '/assemblies/:assemblyId/pcb/files';

    const pcbFileLink =
        viewContext.type === 'WithinRfQ'
            ? route('/rfqs/:rfqId/bom/assembly/:assemblyId/pcb/files', { rfqId, assemblyId })
            : route('/assemblies/:assemblyId/pcb/files', { assemblyId }, { rfqId });

    const isPCBDrillsLinkActive =
        routeId === '/rfqs/:rfqId/bom/assembly/:assemblyId/pcb/drills' ||
        routeId === '/assemblies/:assemblyId/pcb/drills';

    const pcbDrillsLink =
        viewContext.type === 'WithinRfQ'
            ? route('/rfqs/:rfqId/bom/assembly/:assemblyId/pcb/drills', { rfqId, assemblyId })
            : route('/assemblies/:assemblyId/pcb/drills', { assemblyId }, { rfqId });

    const isPcbPanelizationLinkActive =
        routeId === '/rfqs/:rfqId/bom/assembly/:assemblyId/pcb/panelization' ||
        routeId === '/assemblies/:assemblyId/pcb/panelization';

    const pcbPanelizationLink =
        viewContext.type === 'WithinRfQ'
            ? route('/rfqs/:rfqId/bom/assembly/:assemblyId/pcb/panelization', { rfqId, assemblyId })
            : route('/assemblies/:assemblyId/pcb/panelization', { assemblyId }, { rfqId });
    return (
        <StickyLayout
            id={'pcb-side-bar'}
            style={{
                borderRight: `1px solid ${colorSystem.neutral[2]}`,
                backgroundColor: 'white',
                zIndex: 2,
                gap: '12px',
                height: '100%',
                paddingInline: '16px',
                width: sidebarWidth,
                transition: 'all 0.2s ease-in',
            }}
        >
            <Flexbox justifyContent={'space-between'} alignItems={'center'} style={{ marginBlockStart: '6px' }}>
                {isOpen && <Text variant={'h2'}>{t`PCB`}</Text>}
                <TertiaryIconButton onClick={handleTogglePcbSidebar}>
                    <KeyboardTabRounded
                        style={{ transform: isOpen ? 'rotate(180deg)' : undefined }}
                        fontSize="inherit"
                    />
                </TertiaryIconButton>
            </Flexbox>

            {/* On customer portal, the CAD viewer link should be in the third position */}
            {isCustomer === false && (
                <SidebarLink
                    disabled={isWithoutFiles}
                    onClick={() => onClick(PCBTabTypes.CADViewer)}
                    isActive={isCadViewerLinkActive}
                    to={cadViewerLink}
                    id={'pcb-cad-viewer-tab'}
                    LinkBodyProps={{
                        Icon: Memory,
                        text: t`CAD viewer`,
                        isOpen,
                    }}
                />
            )}

            <SidebarLink
                onClick={() => {
                    onClick(PCBTabTypes.Specification);
                    setShowNotification('false');
                }}
                isActive={isPCBSPecificationLinkActive}
                to={pcbSpecificationLink}
                id={'pcb-general-tab'}
                LinkBodyProps={{
                    Icon: DescriptionRounded,
                    text: t`PCB specification`,
                    isOpen,
                    hasNotification: showPcbAnalysisNotification,
                }}
            />

            <SidebarLink
                onClick={() => onClick(PCBTabTypes.StackUp)}
                isActive={isPcbStackUpLinkActive}
                to={pcbStackUpLink}
                id={'pcb-stack-up-tab'}
                LinkBodyProps={{
                    Icon: LayersRounded,
                    text: t`Stackup`,
                    isOpen,
                }}
            />

            {isCustomer === true && (
                <SidebarLink
                    disabled={isWithoutFiles}
                    onClick={() => onClick(PCBTabTypes.CADViewer)}
                    isActive={isPcbCadViewerLinkActive}
                    to={pcbCadViewerLink}
                    id={'pcb-cad-viewer-tab'}
                    LinkBodyProps={{
                        Icon: Memory,
                        text: t`CAD viewer`,
                        isOpen,
                    }}
                />
            )}

            <SidebarLink
                onClick={() => onClick(PCBTabTypes.LayerStack)}
                isActive={isPcbFileLinkActive}
                to={pcbFileLink}
                id={'pcb-layer-tab'}
                LinkBodyProps={{
                    Icon: FolderRounded,
                    text: t`File manager`,
                    isOpen,
                }}
            />

            <SidebarLink
                disabled={isWithoutFiles}
                onClick={() => onClick(PCBTabTypes.Drills)}
                isActive={isPCBDrillsLinkActive}
                to={pcbDrillsLink}
                id={id('design/pcb-drills-tab')}
                LinkBodyProps={{
                    Icon: HdrWeakRounded,
                    text: t`Drill manager`,
                    isOpen,
                }}
            />

            {isCustomer === false && (
                <SidebarLink
                    onClick={() => onClick(PCBTabTypes.Panelization)}
                    isActive={isPcbPanelizationLinkActive}
                    to={pcbPanelizationLink}
                    id={id('design/pcb-panelization-tab')}
                    LinkBodyProps={{
                        Icon: BorderOuterRounded,
                        text: t`Shipping panel`,
                        isOpen,
                    }}
                />
            )}
        </StickyLayout>
    );
}
