import { t } from '@lingui/macro';
import { Currency, assertUnreachable, uniqueBy } from '@luminovo/commons';
import { FormulaCostDTO, UnitCostDTO } from '@luminovo/http-client';
import { assertPresent } from '../../../../../utils/assertPresent';
import { assertStringEquality } from '../../../../../utils/assertStringEquality';
import { nthValueInArray } from '../../../../../utils/typingUtils';
import {
    BaseCellProperties,
    CellBlank,
    CellBreakdown,
    CellBuffer,
    CostTypeFormulaFixed,
    CostTypeFormulaFraction,
    DynamicCostCell,
    FixedCostCell,
} from '../../types/cellTypes';
import { ColumnConfig, ProjectCostBreakdownConfig } from '../../types/conversionTypes';
import { ExtraField } from '../../types/extraFieldType';
import { RowId, uniqueRowId } from '../../types/rowIdTypes';
import {
    Row,
    RowAction,
    RowBreakdown,
    RowBuffer,
    RowDynamic,
    RowFixed,
    RowFixedPercentage,
    RowHeader,
    Style,
} from '../../types/rowTypes';
import { ExtraCost, TableColumn } from '../../types/tableColumnTypes';
import { convertDynamicCell } from './convertScenarioCostToTableColumns';

const convertBlankCell = (baseCell: BaseCellProperties): CellBlank => {
    return {
        type: 'blank',
        orderSize: baseCell.orderSize,
        batchSize: baseCell.batchSize,
        sourcingTotalAvailability: baseCell.sourcingTotalAvailability,
        sourcingCombinationId: baseCell.sourcingCombinationId,
        preferredCurrency: baseCell.preferredCurrency,
        manufacturingLeadTime: baseCell.manufacturingLeadTime,
    };
};

const emptyBreakdownCell = (baseCell: BaseCellProperties): CellBreakdown => {
    return {
        type: 'breakdown',
        cost: undefined,
        ...baseCell,
    };
};

const getExtraCostNames = (extraCosts: ExtraCost[]): string[] => {
    return extraCosts.map((extraCost) => {
        return extraCost.name;
    });
};

enum Sections {
    materialCostSection,
    manufacturingCostSection,
    otherCostSection,
    additionalProfitDiscountSection,
    postProfitCostsSection,
    projectCostsSection,
    summarySection,
}

const getExtraCostNamesOfMaterialCostSection = (columns: TableColumn[]): string[] => {
    return getExtraCostNames(
        nthValueInArray(columns, 0)?.sections[Sections.materialCostSection].customCosts.extraCosts ?? [],
    );
};

const getExtraCostNamesOfManufacturingCostSection = (columns: TableColumn[]): string[] => {
    return getExtraCostNames(
        nthValueInArray(columns, 0)?.sections[Sections.manufacturingCostSection].customCosts.extraCosts ?? [],
    );
};

const getExtraCostNamesOfAdditionalOtherCostSection = (columns: TableColumn[]): string[] => {
    return getExtraCostNames(nthValueInArray(columns, 0)?.sections[Sections.otherCostSection].value ?? []);
};

const getExtraCostNamesOfAdditionalProfitAndDiscountSection = (columns: TableColumn[]): string[] => {
    return getExtraCostNames(nthValueInArray(columns, 0)?.sections[Sections.postProfitCostsSection].value ?? []);
};
const convertBufferCell = (baseCell: BaseCellProperties): CellBuffer => {
    return {
        type: 'buffer',
        orderSize: baseCell.orderSize,
        batchSize: baseCell.batchSize,
        sourcingTotalAvailability: baseCell.sourcingTotalAvailability,
        manufacturingLeadTime: baseCell.manufacturingLeadTime,
        sourcingCombinationId: baseCell.sourcingCombinationId,
        preferredCurrency: baseCell.preferredCurrency,
    };
};

const convertTableColumnsToMaterialCostRows = (
    columns: TableColumn[],
    preferredCurrency: Currency,
    isCalculationTemplateApplied: boolean,
): Row[] => {
    const header: RowHeader = {
        type: 'header',
        rowActions: [],
        style: 'header',
        label: t`Material cost`,
        id: 'material-cost-header',
        cells: columns.map((column) => {
            return convertBlankCell({
                orderSize: column.orderSize,
                batchSize: column.batchSize,
                sourcingTotalAvailability: column.leadTime,
                manufacturingLeadTime: column.manufacturingLeadTime,
                sourcingCombinationId: column.sourcingCombinationId,
                preferredCurrency,
            });
        }),
    };

    const materialCostRow: RowFixed = {
        type: 'fixed',
        rowActions: [
            {
                type: 'add',
                insertRow: insertDynamicRow(
                    columns,
                    'material-cost-extra',
                    preferredCurrency,
                    getExtraCostNamesOfMaterialCostSection(columns),
                ),
                existingNames: getExtraCostNamesOfMaterialCostSection(columns),
            },
        ],
        style: 'normal',
        label: t`Cost`,
        id: 'material-cost-cost',
        cells: columns.map((column) => {
            return {
                type: 'fixed',
                batchSize: column.batchSize,
                orderSize: column.orderSize,
                sourcingTotalAvailability: column.leadTime,
                manufacturingLeadTime: column.manufacturingLeadTime,
                leadTimeInDays: column.leadTime?.type === 'LeadTimeDays' ? column.leadTime.days : undefined,
                unitCost: column.sections[Sections.materialCostSection].cost.unitCost,
                totalCost: column.sections[Sections.materialCostSection].cost.totalCost,
                sourcingCombinationId: column.sourcingCombinationId,
                preferredCurrency,
            };
        }),
    };

    const allExtraCosts = (): RowDynamic[] => {
        const firstRowExtraCosts =
            nthValueInArray(columns, 0)?.sections[Sections.materialCostSection].customCosts.extraCosts ?? [];

        return firstRowExtraCosts.map((extraCost, i): RowDynamic => {
            return {
                type: 'dynamic',
                style: 'normal',
                label: extraCost.name,
                id: `material-cost-extra-${uniqueRowId()}`,
                rowActions: createRowActionsForExtraCost({
                    columns,
                    preferredCurrency,
                    rowName: 'material-cost-extra',
                    isRowLocked: extraCost.cost.isLocked,
                    existingNames: getExtraCostNamesOfMaterialCostSection(columns),
                }),
                cells: columns.map((column) => {
                    assertStringEquality(
                        column.sections[Sections.materialCostSection].customCosts.extraCosts[i].name,
                        extraCost.name,
                    );
                    return assertPresent(column.sections[Sections.materialCostSection].customCosts.extraCosts[i].cost);
                }),
            };
        });
    };

    const extraCostRows = allExtraCosts();

    const profitCells = columns.map((column) => {
        return column.sections[Sections.materialCostSection].customCosts.profit;
    });
    const discountCells = columns.map((column) => {
        return column.sections[Sections.materialCostSection].customCosts.discount;
    });
    const shouldHideProfitRow = profitCells.filter((cell) => cell.shouldHideCell).length === profitCells.length;
    const shouldHideDiscountRow = discountCells.filter((cell) => cell.shouldHideCell).length === discountCells.length;
    const shouldHideTotalCostRow = extraCostRows.length < 1 && isCalculationTemplateApplied;
    const shouldHidePriceRow = shouldHideProfitRow && shouldHideDiscountRow;

    const totalCostRow: RowFixed = {
        type: 'fixed',
        rowActions: [],
        style: shouldHideTotalCostRow ? 'hidden' : 'bold',
        label: t`Total cost`,
        id: 'material-cost-subtotal-cost',
        cells: columns.map((column) => {
            return column.sections[Sections.materialCostSection].subTotalCost;
        }),
    };

    const discountRow: RowDynamic = {
        type: 'dynamic',
        rowActions: [],
        style: shouldHideDiscountRow ? 'hidden' : 'normal',
        label: t`Discount`,
        id: 'material-cost-discount',
        cells: discountCells,
    };

    const profitRow: RowDynamic = {
        type: 'dynamic',
        rowActions: [],
        style: shouldHideProfitRow ? 'hidden' : 'normal',
        label: t`Profit`,
        id: 'material-cost-profit',
        cells: profitCells,
    };

    const priceRow: RowFixed = {
        type: 'fixed',
        rowActions: [],
        style: shouldHidePriceRow ? 'hidden' : 'blue',
        label: t`Price`,
        id: 'material-cost-price',
        cells: columns.map((column) => {
            return column.sections[Sections.materialCostSection].price;
        }),
    };

    return [header, materialCostRow, ...extraCostRows, totalCostRow, profitRow, discountRow, priceRow];
};

const convertTableColumnsToManufacturingCostRows = (
    columns: TableColumn[],
    preferredCurrency: Currency,
    isCalculationTemplateApplied: boolean,
): Row[] => {
    const header: RowHeader = {
        type: 'header',
        rowActions: [],
        style: 'header',
        label: t`Manufacturing cost`,
        id: 'manufacturing-cost-header',
        cells: columns.map((column) => {
            return convertBlankCell({
                orderSize: column.orderSize,
                batchSize: column.batchSize,
                manufacturingLeadTime: column.manufacturingLeadTime,
                sourcingTotalAvailability: column.leadTime,
                sourcingCombinationId: column.sourcingCombinationId,
                preferredCurrency,
            });
        }),
    };

    const manufacturingCostRow: RowFixed = {
        type: 'fixed',
        rowActions: [
            {
                type: 'add',
                insertRow: insertDynamicRow(
                    columns,
                    'manufacturing-cost-extra',
                    preferredCurrency,
                    getExtraCostNamesOfManufacturingCostSection(columns),
                ),
                existingNames: getExtraCostNamesOfManufacturingCostSection(columns),
            },
        ],
        style: 'normal',
        label: t`Cost`,
        id: 'manufacturing-cost-cost',
        cells: columns.map((column) => {
            return {
                type: 'fixed',
                batchSize: column.batchSize,
                orderSize: column.orderSize,
                sourcingTotalAvailability: column.leadTime,
                manufacturingLeadTime: column.manufacturingLeadTime,
                leadTimeInDays: column.leadTime?.type === 'LeadTimeDays' ? column.leadTime.days : undefined,
                totalCost: column.sections[Sections.manufacturingCostSection].cost.totalCost,
                unitCost: column.sections[Sections.manufacturingCostSection].cost.unitCost,
                sourcingCombinationId: column.sourcingCombinationId,
                preferredCurrency,
            };
        }),
    };

    const allExtraCosts = (): RowDynamic[] => {
        const manufacturingExtraCosts =
            nthValueInArray(columns, 0)?.sections[Sections.manufacturingCostSection].customCosts.extraCosts ?? [];
        return manufacturingExtraCosts.map((extraCost, i): RowDynamic => {
            return {
                type: 'dynamic',
                style: 'normal',
                label: extraCost.name,
                id: `manufacturing-cost-extra-${uniqueRowId()}`,
                rowActions: createRowActionsForExtraCost({
                    columns,
                    preferredCurrency,
                    rowName: 'manufacturing-cost-extra',
                    isRowLocked: extraCost.cost.isLocked,
                    existingNames: getExtraCostNamesOfManufacturingCostSection(columns),
                }),
                cells: columns.map((column) => {
                    assertStringEquality(
                        column.sections[Sections.manufacturingCostSection].customCosts.extraCosts[i].name,
                        extraCost.name,
                    );
                    return assertPresent(
                        column.sections[Sections.manufacturingCostSection].customCosts.extraCosts[i].cost,
                    );
                }),
            };
        });
    };

    const extraCostRows = allExtraCosts();
    const profitCells = columns.map((column) => {
        return column.sections[Sections.manufacturingCostSection].customCosts.profit;
    });
    const discountCells = columns.map((column) => {
        return column.sections[Sections.manufacturingCostSection].customCosts.discount;
    });
    const shouldHideProfitRow = profitCells.filter((cell) => cell.shouldHideCell).length === profitCells.length;
    const shouldHideDiscountRow = discountCells.filter((cell) => cell.shouldHideCell).length === discountCells.length;
    const shouldHideTotalCostRow = extraCostRows.length < 1 && isCalculationTemplateApplied;
    const shouldHidePriceRow = shouldHideProfitRow && shouldHideDiscountRow;

    const totalCostRow: RowFixed = {
        type: 'fixed',
        rowActions: [],
        style: shouldHideTotalCostRow ? 'hidden' : 'bold',
        label: t`Total cost`,
        id: 'manufacturing-cost-subtotal-cost',
        cells: columns.map((column) => {
            return column.sections[Sections.manufacturingCostSection].subTotalCost;
        }),
    };

    const discountRow: RowDynamic = {
        type: 'dynamic',
        rowActions: [],
        style: shouldHideDiscountRow ? 'hidden' : 'bold',
        label: t`Discount`,
        id: 'manufacturing-cost-discount',
        cells: discountCells,
    };

    const profitRow: RowDynamic = {
        type: 'dynamic',
        rowActions: [],
        style: shouldHideProfitRow ? 'hidden' : 'bold',
        label: t`Profit`,
        id: 'manufacturing-cost-profit',
        cells: profitCells,
    };

    const priceRow: RowFixed = {
        type: 'fixed',
        rowActions: [],
        style: shouldHidePriceRow ? 'hidden' : 'blue',
        label: t`Price`,
        id: 'manufacturing-cost-price',
        cells: columns.map((column) => {
            return column.sections[Sections.manufacturingCostSection].price;
        }),
    };

    return [header, manufacturingCostRow, ...extraCostRows, totalCostRow, profitRow, discountRow, priceRow];
};

const convertTableColumnsToOtherCostsRows = (
    columns: TableColumn[],
    preferredCurrency: Currency,
    isCalculationTemplateApplied: boolean,
): Row[] => {
    const header: RowHeader = {
        type: 'header',
        rowActions: [
            {
                type: 'add',
                insertRow: insertDynamicRow(
                    columns,
                    'additional-other-costs-extra',
                    preferredCurrency,
                    getExtraCostNamesOfAdditionalOtherCostSection(columns),
                ),
                existingNames: getExtraCostNamesOfAdditionalOtherCostSection(columns),
            },
        ],
        style: 'header',
        label: t`Other costs`,
        id: 'additional-other-costs-header',
        cells: columns.map((column) => {
            return convertBlankCell({
                orderSize: column.orderSize,
                batchSize: column.batchSize,
                manufacturingLeadTime: column.manufacturingLeadTime,
                sourcingTotalAvailability: column.leadTime,
                sourcingCombinationId: column.sourcingCombinationId,
                preferredCurrency,
            });
        }),
    };

    const allOtherCosts = (): RowDynamic[] => {
        const firstRowExtraCosts = nthValueInArray(columns, 0)?.sections[Sections.otherCostSection].value ?? [];
        return firstRowExtraCosts.map((extraCost, i): RowDynamic => {
            return {
                type: 'dynamic',
                style: 'normal',
                label: extraCost.name,
                id: `additional-other-costs-extra-${uniqueRowId()}`,
                rowActions: createRowActionsForExtraCost({
                    columns,
                    preferredCurrency,
                    rowName: 'additional-other-costs-extra',
                    isRowLocked: extraCost.cost.isLocked,
                    existingNames: getExtraCostNamesOfAdditionalOtherCostSection(columns),
                }),
                cells: columns.map((column) => {
                    assertStringEquality(column.sections[Sections.otherCostSection].value[i].name, extraCost.name);
                    return assertPresent(column.sections[Sections.otherCostSection].value[i].cost);
                }),
            };
        });
    };

    const otherCostRows = allOtherCosts();
    if (otherCostRows.length < 1 && isCalculationTemplateApplied) {
        return [];
    }

    return [header, ...otherCostRows];
};

const generateRowHeader = ({
    columns,
    label,
    id,
    rowActions = [],
    rowStyle = 'header',
    preferredCurrency,
}: {
    columns: TableColumn[];
    label: string;
    id: RowId;
    rowActions?: RowAction[];
    rowStyle?: 'header' | 'hidden';
    preferredCurrency: Currency;
}): RowHeader => {
    return {
        type: 'header',
        rowActions,
        style: rowStyle,
        label: label,
        id: id,
        cells: columns.map((column) => {
            return convertBlankCell({
                orderSize: column.orderSize,
                batchSize: column.batchSize,
                manufacturingLeadTime: column.manufacturingLeadTime,
                sourcingTotalAvailability: column.leadTime,
                sourcingCombinationId: column.sourcingCombinationId,
                preferredCurrency,
            });
        }),
    };
};

const generateRowBuffer = ({
    columns,
    id,
    preferredCurrency,
    rowStyle = 'buffer',
}: {
    columns: TableColumn[];
    id: RowId;
    preferredCurrency: Currency;
    rowStyle?: 'buffer' | 'hidden';
}): RowBuffer => {
    return {
        type: 'buffer',
        style: rowStyle,
        label: ``,
        id: id,
        rowActions: [],
        cells: columns.map((column) => {
            return convertBufferCell({
                orderSize: column.orderSize,
                batchSize: column.batchSize,
                sourcingTotalAvailability: column.leadTime,
                manufacturingLeadTime: column.manufacturingLeadTime,
                sourcingCombinationId: column.sourcingCombinationId,
                preferredCurrency,
            });
        }),
    };
};

const convertTableColumnsToAdditionalProfitDiscountRows = (
    columns: TableColumn[],
    preferredCurrency: Currency,
): Row[] => {
    const profitCells = columns.map((column) => {
        return column.sections[Sections.additionalProfitDiscountSection].profit;
    });
    const discountCells = columns.map((column) => {
        return column.sections[Sections.additionalProfitDiscountSection].discount;
    });
    const shouldHideProfitRow = profitCells.filter((cell) => cell.shouldHideCell).length === profitCells.length;
    const shouldHideDiscountRow = discountCells.filter((cell) => cell.shouldHideCell).length === discountCells.length;
    const header: RowHeader = generateRowHeader({
        columns,
        label: t`Additional profit/discount`,
        id: 'additional-profit-header',
        preferredCurrency,
    });
    const profitRow: RowDynamic = {
        type: 'dynamic',
        label: t`Profit`,
        rowActions: [],
        id: 'additional-profit-profit',
        style: shouldHideProfitRow ? 'hidden' : 'normal',
        cells: profitCells,
    };
    const discountRow: RowDynamic = {
        type: 'dynamic',
        label: t`Discount`,
        rowActions: [],
        id: 'additional-profit-discount',
        style: shouldHideDiscountRow ? 'hidden' : 'normal',
        cells: discountCells,
    };

    if (shouldHideDiscountRow && shouldHideProfitRow) {
        return [profitRow, discountRow];
    }

    return [header, profitRow, discountRow];
};

const generateFixedFormulaCost = (cost: CostTypeFormulaFixed): FormulaCostDTO => {
    return {
        type: 'Formula',
        data: {
            formula: cost.isOverwritten
                ? {
                      type: 'Overridden',
                      data: {
                          /* eslint-disable-next-line camelcase */
                          manually_overridden: cost.value,
                          calculated: {
                              type: 'Fixed',
                              data: {
                                  script: cost.script,
                                  currency: cost.currency,
                              },
                          },
                      },
                  }
                : {
                      type: 'Evaluated',
                      data: {
                          calculated: {
                              type: 'Fixed',
                              data: {
                                  script: cost.script,
                                  currency: cost.currency,
                              },
                          },
                      },
                  },
            result: cost.result === 'Ok' ? { result: 'Ok', data: cost.value } : { result: 'ScriptError' },
            statuses: cost.statuses,
        },
    };
};

const generateFractionFormulaCost = (cost: CostTypeFormulaFraction): FormulaCostDTO => {
    return {
        type: 'Formula',
        data: {
            formula: cost.isOverwritten
                ? {
                      type: 'Overridden',
                      data: {
                          /* eslint-disable-next-line camelcase */
                          manually_overridden: cost.value,
                          calculated: {
                              type: 'Fraction',
                              data: {
                                  script: cost.script,
                              },
                          },
                      },
                  }
                : {
                      type: 'Evaluated',
                      data: {
                          calculated: {
                              type: 'Fraction',
                              data: {
                                  script: cost.script,
                              },
                          },
                      },
                  },
            result: cost.result === 'Ok' ? { result: 'Ok', data: cost.value } : { result: 'ScriptError' },
            statuses: cost.statuses,
        },
    };
};

const renameToCalculationRow = (dynamicCostCell: DynamicCostCell): UnitCostDTO => {
    const cost = dynamicCostCell.costSpecification;
    switch (cost.type) {
        case 'fixed':
            return {
                type: 'Fixed',
                data: {
                    amount: cost.value.amount,
                    currency: cost.value.currency,
                },
            };
        case 'fraction':
            return {
                type: 'Fraction',
                data: cost.value,
            };
        case 'formula-fixed':
            return generateFixedFormulaCost(cost);
        case 'formula-fraction':
            return generateFractionFormulaCost(cost);
        default:
            assertUnreachable(cost);
    }
};

const mapExtraFieldToSection = (columns: TableColumn[], index: number, extraField: ExtraField): ExtraCost[] => {
    switch (extraField) {
        case 'additional-other-costs-extra':
            return columns[index].sections[Sections.otherCostSection].value;
        case 'additional-post-profit-extra':
            throw new Error('additional-post-profit-extra not expected here');
        case 'material-cost-extra':
            return columns[index].sections[Sections.materialCostSection].customCosts.extraCosts;
        case 'manufacturing-cost-extra':
            return columns[index].sections[Sections.manufacturingCostSection].customCosts.extraCosts;
        default:
            assertUnreachable(extraField);
    }
};

const updateDynamicRow = (
    columns: TableColumn[],
    rowName: ExtraField,
    preferredCurrency: Currency,
    existingNames: string[],
): ((newRowName: string, oldRowName: string) => Row) => {
    return (newRowName: string, oldRowName: string) => ({
        type: 'dynamic',
        id: `${rowName}-${uniqueRowId()}`,
        rowActions: [
            {
                type: 'add',
                insertRow: insertDynamicRow(columns, rowName, preferredCurrency, existingNames),
                existingNames,
            },
            { type: 'remove' },
            {
                type: 'update',
                updateRow: updateDynamicRow(columns, rowName, preferredCurrency, existingNames),
                existingNames,
            },
        ],
        style: 'normal',
        label: newRowName,
        cells: columns.map((column, i) => {
            const cost = assertPresent(
                mapExtraFieldToSection(columns, i, rowName).find((cost) => cost.name === oldRowName),
            ).cost;
            return convertDynamicCell(
                /* eslint-disable */
                {
                    unit_cost_value: {
                        amount: cost.unitCostValue.amount,
                        currency: cost.unitCostValue.currency,
                    },
                    unit_cost_fraction: cost.costFraction,
                    total_cost_value: {
                        amount: cost.totalCostValue.amount,
                        currency: cost.totalCostValue.currency,
                    },
                    cost_specification: renameToCalculationRow(cost),
                    is_locked: false,
                },
                /* eslint-enable */
                {
                    orderSize: column.orderSize,
                    batchSize: column.batchSize,
                    sourcingTotalAvailability: column.leadTime,
                    manufacturingLeadTime: column.manufacturingLeadTime,
                    sourcingCombinationId: column.sourcingCombinationId,
                    preferredCurrency,
                },
            );
        }),
    });
};

const insertDynamicRow = (
    columns: TableColumn[],
    rowName: ExtraField,
    preferredCurrency: Currency,
    existingNames: string[],
): ((name: string) => Row) => {
    return (name: string) => ({
        type: 'dynamic',
        id: `${rowName}-${uniqueRowId()}`,
        rowActions: [
            {
                type: 'add',
                insertRow: insertDynamicRow(columns, rowName, preferredCurrency, existingNames),
                existingNames,
            },
            { type: 'remove' },
            {
                type: 'update',
                updateRow: updateDynamicRow(columns, rowName, preferredCurrency, existingNames),
                existingNames,
            },
        ],
        style: 'normal',
        label: name,
        cells: columns.map((column) => {
            return convertDynamicCell(
                /* eslint-disable */
                {
                    unit_cost_value: {
                        amount: '0',
                        currency: Currency.EUR,
                    },
                    unit_cost_fraction: '0',
                    total_cost_value: {
                        amount: '0',
                        currency: Currency.EUR,
                    },
                    cost_specification: {
                        type: 'Fixed',
                        data: {
                            amount: '0',
                            currency: Currency.EUR,
                        },
                    },
                    is_locked: false,
                },
                /* eslint-enable */
                {
                    orderSize: column.orderSize,
                    batchSize: column.batchSize,
                    manufacturingLeadTime: column.manufacturingLeadTime,
                    sourcingTotalAvailability: column.leadTime,
                    sourcingCombinationId: column.sourcingCombinationId,
                    preferredCurrency,
                },
            );
        }),
    });
};

const createRowActionsForExtraCost = ({
    columns,
    preferredCurrency,
    rowName,
    isRowLocked,
    existingNames,
}: {
    columns: TableColumn[];
    preferredCurrency: Currency;
    rowName: ExtraField;
    isRowLocked: boolean;
    existingNames: string[];
}): RowAction[] => {
    const rowActionsIsUnlocked: RowAction[] = [
        {
            type: 'add',
            insertRow: insertDynamicRow(columns, rowName, preferredCurrency, existingNames),
            existingNames,
        },
        { type: 'remove' },
        {
            type: 'update',
            updateRow: updateDynamicRow(columns, rowName, preferredCurrency, existingNames),
            existingNames,
        },
    ];

    const rowActionsIsLocked: RowAction[] = [
        {
            type: 'add',
            insertRow: insertDynamicRow(columns, rowName, preferredCurrency, existingNames),
            existingNames,
        },
    ];

    return isRowLocked ? rowActionsIsLocked : rowActionsIsUnlocked;
};

const convertTableColumnsToPostProfitCostsRows = (
    columns: TableColumn[],
    preferredCurrency: Currency,
    isCalculationTemplateApplied: boolean,
): Row[] => {
    const header: RowHeader = generateRowHeader({
        columns,
        label: t`Post-profit costs`,
        id: 'additional-other-costs-header',
        rowActions: [
            {
                type: 'add',
                insertRow: insertDynamicRow(
                    columns,
                    'additional-post-profit-extra',
                    preferredCurrency,
                    getExtraCostNamesOfAdditionalProfitAndDiscountSection(columns),
                ),
                existingNames: getExtraCostNamesOfAdditionalProfitAndDiscountSection(columns),
            },
        ],
        preferredCurrency,
    });
    const allOtherCosts = (): RowDynamic[] => {
        const firstRowExtraCosts = nthValueInArray(columns, 0)?.sections[Sections.postProfitCostsSection].value ?? [];
        return firstRowExtraCosts.map((extraCost, i): RowDynamic => {
            const isRowLocked = extraCost.cost.isLocked;
            return {
                type: 'dynamic',
                style: 'normal',
                id: `additional-post-profit-extra-${uniqueRowId()}`,
                label: extraCost.name,
                rowActions: createRowActionsForExtraCost({
                    columns,
                    preferredCurrency,
                    rowName: 'additional-post-profit-extra',
                    isRowLocked,
                    existingNames: getExtraCostNamesOfAdditionalProfitAndDiscountSection(columns),
                }),
                cells: columns.map((column) => {
                    assertStringEquality(
                        column.sections[Sections.postProfitCostsSection].value[i].name,
                        extraCost.name,
                    );
                    return assertPresent(column.sections[Sections.postProfitCostsSection].value[i].cost);
                }),
            };
        });
    };

    const postProfitCostRows = allOtherCosts();
    if (postProfitCostRows.length < 1 && isCalculationTemplateApplied) {
        return [];
    }

    return [header, ...postProfitCostRows];
};

const createFixedRow = ({
    label,
    cells,
    id,
    style = 'normal',
    rowActions = [],
}: {
    label: string;
    cells: FixedCostCell[];
    id: RowId;
    style?: Style;
    rowActions?: RowAction[];
}): RowFixed => {
    return {
        type: 'fixed',
        label: label,
        id,
        rowActions,
        style,
        cells,
    };
};

const createBreakdownRow = ({
    label,
    cells,
    id,
    style,
}: {
    label: string;
    cells: CellBreakdown[];
    id: RowId;
    style: RowBreakdown['style'];
}): RowBreakdown => {
    return {
        type: 'breakdown',
        label: label,
        id,
        rowActions: [],
        style,
        cells,
    };
};

const convertProjectCostsRows = (
    columns: TableColumn[],
    visibility: 'hidden' | 'visible',
    preferredCurrency: Currency,
    projectCostBreakdownConfig: ProjectCostBreakdownConfig,
): Row[] => {
    const profitCells = columns.map((column) => {
        return column.sections[Sections.projectCostsSection].profit;
    });

    const shouldHideProfitRow = profitCells.filter((cell) => cell.shouldHideCell).length === profitCells.length;

    const header: RowHeader = generateRowHeader({
        columns,
        label: t`Project costs`,
        id: 'project-cost-header',
        rowStyle: visibility === 'visible' ? 'header' : 'hidden',
        preferredCurrency,
    });
    const cost: RowFixed = createFixedRow({
        label: t`Cost`,
        id: 'project-cost-cost',
        cells: columns.map((column) => column.sections[Sections.projectCostsSection].cost.cost),
        style: visibility === 'visible' ? 'normal' : 'hidden',
        rowActions: [
            {
                type: 'toggleExpansion',
                onToggle: projectCostBreakdownConfig.toggleVisibility,
                isExpanded: projectCostBreakdownConfig.isVisible,
            },
        ],
    });

    const uniqueActivities = uniqueBy(
        columns.map((column) => column.sections[Sections.projectCostsSection].cost.activities).flat(),
        (activity) => activity.id,
    );
    const activityCosts = uniqueActivities.map((activity) =>
        createBreakdownRow({
            label: activity.name,
            id: `project-cost-activity-${activity.id}`,
            cells: columns.map(
                (column) =>
                    column.sections[Sections.projectCostsSection].cost.activityCosts[activity.id] ??
                    emptyBreakdownCell({
                        batchSize: column.batchSize,
                        orderSize: column.orderSize,
                        sourcingTotalAvailability: column.leadTime,
                        manufacturingLeadTime: column.manufacturingLeadTime,
                        sourcingCombinationId: column.sourcingCombinationId,
                        preferredCurrency,
                    }),
            ),
            style: visibility === 'visible' ? 'breakdown' : 'hidden',
        }),
    );

    const uniqueExpenses = uniqueBy(
        columns.map((column) => column.sections[Sections.projectCostsSection].cost.expenses).flat(),
        (expense) => expense.id,
    );
    const expenseCosts = uniqueExpenses.map((expense) =>
        createBreakdownRow({
            label: expense.name,
            id: `project-cost-expense-${expense.id}`,
            cells: columns.map(
                (column) =>
                    column.sections[Sections.projectCostsSection].cost.expenseCosts[expense.id] ??
                    emptyBreakdownCell({
                        batchSize: column.batchSize,
                        orderSize: column.orderSize,
                        sourcingTotalAvailability: column.leadTime,
                        manufacturingLeadTime: column.manufacturingLeadTime,
                        sourcingCombinationId: column.sourcingCombinationId,
                        preferredCurrency,
                    }),
            ),
            style: visibility === 'visible' ? 'breakdown' : 'hidden',
        }),
    );

    const price: RowFixed = createFixedRow({
        label: t`Price`,
        id: 'project-cost-price',
        cells: columns.map((column) => column.sections[Sections.projectCostsSection].price),
        style: visibility === 'visible' && !shouldHideProfitRow ? 'normal' : 'hidden',
    });
    const profit: RowDynamic = {
        type: 'dynamic',
        label: t`Profit`,
        id: 'project-cost-profit',
        cells: profitCells,
        rowActions: [],
        style: visibility === 'visible' && !shouldHideProfitRow ? 'normal' : 'hidden',
    };
    const bufferRow: RowBuffer = generateRowBuffer({
        columns,
        id: 'project-cost-buffer',
        preferredCurrency,
        rowStyle: visibility === 'visible' ? 'buffer' : 'hidden',
    });

    const projectCostBreakdownRows = projectCostBreakdownConfig.isVisible ? [...activityCosts, ...expenseCosts] : [];
    return [header, cost, ...projectCostBreakdownRows, profit, price, bufferRow];
};

const convertTableColumnsToSummaryCostRows = (columns: TableColumn[], preferredCurrency: Currency): Row[] => {
    const header: RowHeader = generateRowHeader({
        columns,
        label: t`Summary`,
        id: 'summary-header',
        preferredCurrency,
    });
    const totalCost: RowFixed = createFixedRow({
        label: t`Total cost`,
        id: 'summary-totalCost',
        cells: columns.map((column) => column.sections[Sections.summarySection].totalCost),
    });
    const totalProfit: RowFixedPercentage = {
        type: 'fixed-percentage',
        label: t`Total profit`,
        id: 'summary-totalProfit',
        cells: columns.map((column) => column.sections[Sections.summarySection].totalProfit),
        rowActions: [],
        style: 'normal',
    };
    const totalPrice: RowFixed = createFixedRow({
        label: t`Total price`,
        id: 'summary-price',
        cells: columns.map((column) => column.sections[Sections.summarySection].price),
        style: 'blue',
    });
    const bufferRow: RowBuffer = generateRowBuffer({
        columns,
        id: 'summary-buffer',
        preferredCurrency,
    });
    return [header, totalCost, totalProfit, totalPrice, bufferRow];
};

export const convertTableColumnsToRows = ({
    columns,
    calculationAssemblyDetails,
    preferredCurrency,
    isCalculationWithoutManufacturing,
    projectCostBreakdownConfig,
}: ColumnConfig): Row[] => {
    const { isProjectCostsSeparateFromManufacturingCost, isCalculationTemplateApplied } = calculationAssemblyDetails;
    const materialCostRows = convertTableColumnsToMaterialCostRows(
        columns,
        preferredCurrency,
        isCalculationTemplateApplied,
    );
    const manufacturingCostRows = convertTableColumnsToManufacturingCostRows(
        columns,
        preferredCurrency,
        isCalculationTemplateApplied,
    );
    const otherCostRows = convertTableColumnsToOtherCostsRows(columns, preferredCurrency, isCalculationTemplateApplied);
    const additionalProfitDiscountRows = convertTableColumnsToAdditionalProfitDiscountRows(columns, preferredCurrency);
    const postProfitCostsRows = convertTableColumnsToPostProfitCostsRows(
        columns,
        preferredCurrency,
        isCalculationTemplateApplied,
    );
    const summaryRows = convertTableColumnsToSummaryCostRows(columns, preferredCurrency);
    const projectCostsRows = convertProjectCostsRows(
        columns,
        isProjectCostsSeparateFromManufacturingCost ? 'visible' : 'hidden',
        preferredCurrency,
        projectCostBreakdownConfig,
    );

    return [
        ...materialCostRows,
        ...(isCalculationWithoutManufacturing ? [] : manufacturingCostRows),
        ...otherCostRows,
        ...additionalProfitDiscountRows,
        ...postProfitCostsRows,
        ...summaryRows,
        ...projectCostsRows,
    ];
};
