import { CenteredLayout, NonIdealState, colorSystem } from '@luminovo/design-system';
import { ConstructionRounded } from '@mui/icons-material';
import { PageLayout } from '../../components/PageLayout';
import { UrlParams } from '../../utils/routes';

import { Trans } from '@lingui/macro';
import { isPresent } from '@luminovo/commons';
import { BackButton, Flexbox, Tab, Tabs, Text } from '@luminovo/design-system';
import { ManufacturerDTO, PartCategoryDTO } from '@luminovo/http-client';
import { Box, CircularProgress } from '@mui/material';
import React from 'react';
import { useHttpQuery } from '../../resources/http/useHttpQuery';
import { useManufacturers } from '../../resources/manufacturer/manufacturerHandler';
import { usePartCategories } from '../../resources/part/partHandler';
import { route } from '../../utils/routes';
import { ManufacturerLineCardTab } from './ManufacturerLineCardTab';

const ManufacturerInformation: React.FunctionComponent<{
    manufacturer: ManufacturerDTO;
}> = ({ manufacturer }) => {
    const { data: partCategories } = usePartCategories();
    const { data: manufacturerPreferences } = useHttpQuery(
        'GET /organization-settings/manufacturer-preferences',
        {},
        { select: (res) => res.items },
    );

    let preferredPartCategories: PartCategoryDTO[] = [];
    manufacturerPreferences?.forEach((preference) => {
        preference.manufacturers.forEach((m) => {
            if (m.id === manufacturer.id) {
                const partCategory = partCategories?.find((c) => c.id === preference.part_category_id);
                if (partCategory) {
                    preferredPartCategories.push(partCategory);
                }
            }
        });
    });

    return (
        <Flexbox flexDirection={'column'} gap={24}>
            <Flexbox flexDirection={'column'} gap={12}>
                <Flexbox justifyContent={'space-between'} alignItems={'center'}>
                    <Text variant={'h2'} color={colorSystem.neutral[8]}>
                        {manufacturer.name}
                    </Text>
                </Flexbox>

                <Flexbox gap={8} alignItems={'center'} flexWrap={'wrap'}></Flexbox>
                {manufacturer.alternative_names.length > 0 && (
                    <Flexbox flexDirection={'column'} gap={4}>
                        <Text variant="body-small-semibold" color={colorSystem.neutral[8]}>
                            <Trans>Alternative names</Trans>
                        </Text>
                        <Text variant="body-small" color={colorSystem.neutral[7]}>
                            {manufacturer.alternative_names.join(', ')}
                        </Text>
                    </Flexbox>
                )}
            </Flexbox>

            <Flexbox flexDirection={'column'} gap={12}>
                {preferredPartCategories.length > 0 && (
                    <Flexbox flexDirection={'column'} gap={4}>
                        <Text variant="body-small-semibold" color={colorSystem.neutral[8]}>
                            <Trans>Preferred part categories</Trans>
                        </Text>
                        <Text variant="body-small" color={colorSystem.neutral[7]}>
                            {preferredPartCategories.map((c) => c.name).join(', ')}
                        </Text>
                    </Flexbox>
                )}
            </Flexbox>
        </Flexbox>
    );
};

export function ManufacturerDetailsPage(props: UrlParams<'/manufacturer/:manufacturerId'>) {
    const [tabValue, setTabValue] = React.useState<'lineCard' | 'parts'>('lineCard');
    const { data: manufacturers } = useManufacturers();
    const manufacturer = manufacturers?.find((m) => m.id === props.pathParams.manufacturerId);

    if (!isPresent(manufacturer)) {
        return (
            <PageLayout style={{ background: colorSystem.neutral[1] }} layout="full-width">
                <CenteredLayout height={'50vh'}>
                    <CircularProgress />
                </CenteredLayout>
            </PageLayout>
        );
    }
    return (
        <PageLayout layout={'fragment'} removeHubspotPaddingFix bgcolor={colorSystem.neutral[1]}>
            <Box display="grid" gridTemplateColumns="auto 1fr" height={'100%'}>
                <Flexbox
                    flexDirection={'column'}
                    gap={24}
                    bgcolor={colorSystem.neutral[0]}
                    paddingX={'24px'}
                    paddingY={'24px'}
                    width={'400px'}
                    overflow={'auto'}
                >
                    <Flexbox gap={'8px'} alignItems={'center'}>
                        <BackButton href={route('/manufacturer')} variant="button" size={'small'} />
                    </Flexbox>

                    <ManufacturerInformation manufacturer={manufacturer} />
                </Flexbox>

                <Flexbox
                    flexDirection={'column'}
                    gap={'20px'}
                    padding="20px"
                    borderRadius="8px"
                    margin={'24px'}
                    style={{ background: colorSystem.neutral.white }}
                    flexGrow={1}
                >
                    <Tabs value={tabValue} size="large">
                        <Tab
                            value={'lineCard'}
                            label={<Trans>Suppliers</Trans>}
                            onClick={() => setTabValue('lineCard')}
                        />
                        <Tab value={'parts'} label={<Trans>Parts</Trans>} onClick={() => setTabValue('parts')} />
                    </Tabs>
                    {tabValue === 'lineCard' && (
                        <Box height={'100%'}>
                            <ManufacturerLineCardTab manufacturer={manufacturer} />
                        </Box>
                    )}
                    {tabValue === 'parts' && (
                        <CenteredLayout>
                            <NonIdealState
                                Icon={ConstructionRounded}
                                title={'Coming soon'}
                                description="We are working hard to bring you this page. Stay tuned!"
                            />
                        </CenteredLayout>
                    )}
                </Flexbox>
            </Box>
        </PageLayout>
    );
}
