import { StatusChip } from '@luminovo/design-system';
import { LifecycleEnum } from '@luminovo/http-client';
import { lifecycleEnumPublicTranslations } from '../resources/part/partFrontendTypes';
import { assertUnreachable } from '../utils/typingUtils';
import { transEnum } from './localization/TransEnum';

export const LifecycleChip: React.FunctionComponent<{
    lifecycle: LifecycleEnum;
}> = ({ lifecycle }) => {
    const label = transEnum(lifecycle, lifecycleEnumPublicTranslations);
    switch (lifecycle) {
        case LifecycleEnum.Acquired:
            return <StatusChip color="neutral" label={label} />;
        case LifecycleEnum.Active:
            return <StatusChip color="green" label={label} />;
        case LifecycleEnum.Aftermarket:
            return <StatusChip color="red" label={label} />;
        case LifecycleEnum.EndOfLife:
            return <StatusChip color="red" label={label} />;
        case LifecycleEnum.NotRecommendedForNewDesigns:
            return <StatusChip color="yellow" label={label} />;
        case LifecycleEnum.Obsolete:
            return <StatusChip color="red" label={label} />;
        case LifecycleEnum.PreRelease:
            return <StatusChip color="green" label={label} />;
        case LifecycleEnum.Unknown:
            return <StatusChip color="neutral" label={label} />;
        default:
            assertUnreachable(lifecycle);
    }
};
