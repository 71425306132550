import { typeSafeObjectKeys } from '@luminovo/commons';
import { MergedAttributes } from '../../framework/types';
import { PcbAttribute } from '../PcbAttribute';

export function inferDefaults(input: MergedAttributes<PcbAttribute>): MergedAttributes<PcbAttribute> {
    const merged: MergedAttributes<PcbAttribute> = { ...input };
    for (const key of typeSafeObjectKeys(input)) {
        const defaultValue = defaultSpec[key];
        const mergedValue = merged[key];
        if (mergedValue === undefined && defaultValue !== undefined) {
            // @ts-ignore
            merged[key] = defaultValue;
        }
    }
    return merged;
}

const defaultSpec: MergedAttributes<PcbAttribute> = {
    hardGold: { value: false, regions: [] },
    silkscreenColor: { value: 'white', regions: [] },
    silkscreenSide: { value: 'both', regions: [] },
    surfaceFinish: { value: 'enig', regions: [] },
    enigThickness: { value: 2, regions: [] },
    soldermaskColor: { value: 'green', regions: [] },
    soldermaskSide: { value: 'both', regions: [] },
    ipc600Class: { value: 'ipc2', regions: [] },
    eTest: { value: true, regions: [] },
    pressFit: { value: false, regions: [] },
    impedanceTested: { value: false, regions: [] },
    peelableMask: { value: 'none', regions: [] },
    captonTape: { value: 'none', regions: [] },
    itar: { value: false, regions: [] },
    carbonPrint: { value: false, regions: [] },
    edgeMetalization: { value: false, regions: [] },
    flammabilityRating: { value: 'v-0', regions: [] },
    ctiClass: { value: 'iiia', regions: [] },
    maxXOutsAllowed: { value: 0, regions: [] },
    halogenFree: { value: false, regions: [] },
    ulLayerStack: { value: false, regions: [] },
    ulMarkingType: { value: 'default-marking', regions: [] },
    layerstackType: { value: 'rigid', regions: [] },
    finalThickness: { value: 1.55, regions: [] },
    baseMaterial: { value: 'fr-4', regions: [] },
    outerCopperThickness: { value: 35, regions: [] },
    rohsCompilant: { value: true, regions: [] },
    tgValue: { value: 150, regions: [] },
    viaFilling: { value: 'none', regions: [] },
    chamfering: { value: 'none', regions: [] },
    zAxisMilling: { value: false, regions: [] },
};
