import { t } from '@lingui/macro';
import { SecondaryIconButton, Tooltip } from '@luminovo/design-system';
import { CloudDownload as Download } from '@mui/icons-material';
import { useHttpQuery } from '../../resources/http/useHttpQuery';

export function DownloadOfferAttachmentButton({ offerId }: { offerId: string }) {
    const { data: attachment } = useHttpQuery(
        'GET /offers/off-the-shelf/:id/attachment',
        {
            pathParams: { id: offerId },
        },
        {
            meta: { globalErrorHandler: false },
            select: (data) => data?.url,
        },
    );

    return (
        <Tooltip title={attachment ? t`Download PDF` : t`No attachments`}>
            <span>
                <SecondaryIconButton
                    size="medium"
                    disabled={!attachment}
                    onClick={() => window.open(attachment ?? '', '_blank')}
                >
                    <Download fontSize="small" />
                </SecondaryIconButton>
            </span>
        </Tooltip>
    );
}
