const getAddition = (type: 'exclusive' | 'inclusive'): number => {
    const t = type;
    switch (t) {
        case 'exclusive':
            return 0;
        case 'inclusive':
            return 1;
    }
    // @ts-expect-error
    throw new Error('unhandled case: ' + t);
};

/**
 * Calculates the number of days between two dates.
 * @param start The start date.
 * @param end The end date.
 * @param type The type of calculation to perform - inclusive means that the end date is included in the calculation, exclusive means it is not.
 * @returns The number of days between the two dates.
 */
export const calculateDaysBetweenDates = (start: Date, end: Date, type: 'exclusive' | 'inclusive'): number => {
    return Math.ceil((end.getTime() - start.getTime()) / (1000 * 60 * 60 * 24)) + getAddition(type);
};
